import { useState } from 'react';
import {
    Box,
    Button,
    Tooltip,
    Dialog,
    Typography,
    IconButton,
    DialogContent,
    DialogActions,
    CircularProgress,
} from '../ui';
import {
    DataGridPremium,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';
import { CloseIcon } from '../icons';
import * as XLSX from 'xlsx';
import { makeStyles } from 'tss-react/mui';
import axios from 'axios';
import moment from 'moment';
import { useMediaQuery } from '@mui/material';
export const DRAFT = 'DRAFT';
export const OPEN = 'OPEN';
export const ONDEMAND = 'ON-DEMAND';

const useStyles = makeStyles()((theme) => ({
    paper: {
        minWidth: '90vw',
        padding: 40,
    },
    // tableContainer: {
    //     height: 280,
    //     width: '100%',
    // },
    dialogAction: {
        display: 'flex',
    },
    dialogContent: {
        minHeight: 500,
    },
}));

export const ClientRequirementsBulkUploadLinkedIn = ({
    disabled,
    userDetails,
}) => {
    const { classes } = useStyles();

    const [rows, setRows] = useState([
        // {
        //     id: 0,
        //     title: 'Imported: Corporate Communication',
        //     description:
        //         'This course explores effective communication strategies within the corporate environment. Topics include written and verbal communication, interpersonal skills, and delivering presentations. Students will learn how to convey information clearly and professionally. In addition, they will develop the skills to adapt their communication style to different audiences and utilize effective communication tools and technologies. Strong emphasis will be placed on building and maintaining strong professional relationships to enhance collaboration and teamwork. Through interactive exercises and practical examples, students will gain confidence in their verbal and written communication abilities, enabling them to excel in their professional roles.',
        //     problemStatement:
        //         'Develop persuasive communication skills; Enhance interpersonal skills; Deliver effective presentations; Build strong professional relationships; Adapt communication style to different audiences; Utilize effective communication tools and technologies',
        //     topics: [
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: 'Written communication',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' Verbal communication',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' interpersonal skills',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' Presentation skills',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' professional relationships',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' communication style',
        //         },
        //     ],
        // },
        // {
        //     id: 1,
        //     title: 'Imported: Cross-functional Collaboration',
        //     description:
        //         'This course focuses on the principles and strategies for collaborating effectively with colleagues from different functional areas. Students will learn to overcome challenges, foster teamwork, and leverage diverse perspectives to achieve common goals. The course covers various techniques to improve communication and information sharing across departments and develop strategies for managing conflict and resolving disputes. Through case studies and group exercises, students will enhance their problem-solving skills and learn how to make informed decisions through collaborative efforts. The course also emphasizes the importance of building a culture of trust and cooperation among team members. By the end of the course, students will be equipped with the necessary skills to collaborate successfully with colleagues from different backgrounds and functional areas, leading to enhanced productivity and overall organizational success.',
        //     problemStatement:
        //         'Foster collaboration and teamwork; Overcome challenges in cross-functional collaboration; Leverage diverse perspectives to Enhance decision making; Improve communication and information sharing across departments; Develop strategies for managing conflict and resolving disputes; Enhance problem-solving skills through collaborative efforts',
        //     topics: [
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: 'teamwork',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' collaboration strategies',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' cross-functional collaboration',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' decision making',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' communication',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' conflict management',
        //         },
        //     ],
        // },
    ]);
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const [importing, setImporting] = useState(false);
    const isLargeScreen = useMediaQuery('(min-width:900px)');
    const [pinnedColumns, setPinnedColumns] = useState({
        left: isLargeScreen
            ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'title']
            : [],
    });
    const renderToolTipCell = ({ value }) => {
        return (
            <Tooltip placement="top-start" title={value}>
                <Typography variant="body2">{value}</Typography>
            </Tooltip>
        );
    };

    const renderArrayCell = (field) => ({ row }) => {
        let joined = '';
        if (field.includes('.')) {
            const [rowField, objectField] = field.split('.');
            joined = row[rowField]
                ?.map((item) => item[objectField])
                ?.join(', ');
        } else joined = row[field]?.join(', ');
        return (
            <Tooltip placement="top-start" title={joined}>
                <Typography variant="body2">{joined}</Typography>
            </Tooltip>
        );
    };

    const gridCols = [
        // {
        //     field: 'slNo',
        //     headerName: '#',
        //     width: 250,
        //     editable: false,
        // },
        {
            field: 'title',
            headerName: 'Course Title',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        {
            field: 'description',
            headerName: 'Course Description',
            width: 250,
            editable: false,
        },

        // {
        //     field: 'problemStatement',
        //     headerName: 'Learning Outcomes',
        //     width: 250,
        //     editable: false,
        // },

        {
            field: 'topics',
            headerName: 'Keywords/Topics',
            width: 250,
            editable: false,

            renderCell: renderArrayCell('topics.name'),
        },
        {
            field: 'tags',
            headerName: 'Tags',
            width: 250,
            editable: false,

            renderCell: renderArrayCell('tags'),
        },

        {
            field: 'clientId',
            headerName: 'Client Id',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        {
            field: 'domain',
            headerName: 'Domain',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        {
            field: 'hours',
            headerName: 'Duration(Hrs)',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        {
            field: 'expectedEndDate',
            headerName: 'Delivery Date',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        {
            field: 'template',
            headerName: 'Template',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        {
            field: 'instructorBudget',
            headerName: 'Terms',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        // {
        //     field: 'isOnline',
        //     headerName: 'Is Online',
        //     width: 250,
        //     editable: false,

        //     renderCell: renderToolTipCell,
        // },
        {
            field: 'contractNumber',
            headerName: 'Contract Number',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        /* {
            field: 'workOrders',
            headerName: 'Work Order',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        }, */
        // {
        //     field: 'minNumOfStudents',
        //     headerName: 'Number of students',
        //     width: 250,
        //     editable: false,

        //     renderCell: renderToolTipCell,
        // },

        // {
        //     field: 'percentHavingNoExperience',
        //     headerName: '% no experience',
        //     width: 250,
        //     editable: false,

        //     renderCell: renderToolTipCell,
        // },
        // {
        //     field: 'percentHavingZeroToOneExperience',
        //     headerName: '% having zero to one year',
        //     width: 250,
        //     editable: false,

        //     renderCell: renderToolTipCell,
        // },
        // {
        //     field: 'percentHavingOneToThreeExperience',
        //     headerName: '% having one to three year',
        //     width: 250,
        //     editable: false,

        //     renderCell: renderToolTipCell,
        // },
        // {
        //     field: 'totalBudget',
        //     headerName: 'Budget',
        //     width: 250,
        //     editable: false,

        //     renderCell: renderToolTipCell,
        // },
    ];

    function extractNumberFromString(str) {
        const regex = /^\d+/;
        const match = str.match(regex);
        return match ? parseInt(match[0]) : null;
    }

    const getData = (fileData = []) => {
        // console.log(fileData);
        const headerIndex = 0;
        const titleIndex = fileData[headerIndex].indexOf('Course Title');
        const descriptionIndex = fileData[headerIndex].indexOf('Description');
        // const problemStatementIndex = fileData[0].indexOf('Learning Outcomes');

        const keywordsIndex = fileData[headerIndex].indexOf('Keywords');

        const businessLineIndex = fileData[headerIndex].indexOf('Content Type');
        const tagsIndex = fileData[headerIndex].indexOf('Tags');
        // const listTypeIndex = fileData[headerIndex].indexOf('List Type');
        // const subDomainIndex = fileData[headerIndex].indexOf('Sub-Domain');
        // const recommentedContentTypeIndex = fileData[headerIndex].indexOf(
        //     'Recommended Content Type'
        // );

        const clientIndex = fileData[headerIndex].indexOf('Client');

        const dateIndex = fileData[headerIndex].indexOf('GOLD');

        const domainIndex = fileData[headerIndex].indexOf('Domain');

        const learningObjectivesIndex = fileData[headerIndex].indexOf(
            'Learning Objectives (LO1-4)'
        );
        const courseStructureIndex = fileData[headerIndex].indexOf(
            'Course Structure (Readings are "placeholders")'
        );
        const proofOfLearningIndex = fileData[headerIndex].indexOf(
            'Proof of Learning'
        );
        // const statusIndex = fileData[headerIndex].indexOf('Status  ↑');
        const durationIndex = fileData[headerIndex].indexOf('Duration');
        const formatIndex = fileData[headerIndex].indexOf('Format');
        const contractIndex = fileData[headerIndex].indexOf('Contract Number');

        // const workOrdersIndex = fileData[headerIndex].indexOf('Work Order');
        // const numOfStudentsIndex = fileData[headerIndex].indexOf(
        //     'Number of Students'
        // );
        // const expOfStudentsIndex = fileData[headerIndex].indexOf(
        //     'Experience of Students (None, 0-1, 1-3)'
        // );

        // const budgeIndex = fileData[headerIndex].indexOf('Budget');

        const templateIndex = fileData[headerIndex].indexOf('Template');

        const instructorBudgetIndex = fileData[headerIndex].indexOf('Terms');

        const read = [];
        fileData.slice(headerIndex + 1).forEach((r, index) => {
            // console.log(r);
            if (r.length > 1) {
                if (!r[titleIndex]) return;
                if (r[titleIndex] === 'INTERMEDIATE') return;
                if (r[titleIndex] === 'ADVANCED') return;

                const title = r[titleIndex];
                const description = r[descriptionIndex];
                const topics =
                    r[keywordsIndex]?.split(',')?.map((k) => ({
                        id: 99999,
                        term_id: k?.trim(),
                        name: k?.trim(),
                    })) || [];

                const learningObjectives = r[learningObjectivesIndex];
                const courseStructure = r[courseStructureIndex];

                const proofOfLearning = r[proofOfLearningIndex];
                // const contractNumber = r[contractIndex];

                const sampleOutline = [
                    {
                        alternativeTitle: title,
                        overview: `<h2><u>Course Description</u></h2>\n<p>${description}</p>\n\n<h2><u>Course Structure</u></h2>\n<p>${courseStructure}</p>\n\n<h2><u>Learning Objectives</u></h2>\n<p>${learningObjectives}</p>\n\n<h2><u>Proof of Learning</u></h2>\n<p>${proofOfLearning}</p>\n\n`,
                        quiz: '',
                        createdAt: Date.now(),
                        isManual: true,
                    },
                ];

                // const sampleOutline = ;
                const tags = [
                    'telco1',
                    // r[contractIndex],
                    // r[businessLineIndex],
                    // r[tagsIndex],
                    // r[listTypeIndex],
                    // r[subDomainIndex],
                    // r[recommentedContentTypeIndex],
                ];
                const clientId = '652d8668e2970d7740d7a301';

                const status = DRAFT; ///* r[statusIndex] === 'Pending' ? */ OPEN; /* :  */
                const domain = r[domainIndex];
                // const hours = extractNumberFromString(r[durationIndex]);
                // const expectedEndDate = r[dateIndex];

                // Convert the Excel numeric date to a JavaScript date
                const expectedEndDate = moment
                    .utc('1900-01-01')
                    .add(Math.floor(r[dateIndex] - 2), 'days')
                    .toISOString();

                //  moment
                //     .utc(r[dateIndex], 'DD/MMM/YYYY')
                //     .toISOString();

                const isOnline = /* r[formatIndex] === 'Online' */ true;

                // const workOrders = r[workOrdersIndex];

                const comamaDollarRegex = /,|\$/g;

                const template = r[templateIndex];
                const instructorBudget = r[instructorBudgetIndex];

                // console.log(r[numOfStudentsIndex]);
                // const minNumOfStudents =
                //     r[
                //         numOfStudentsIndex
                //     ]; /* ?.replace(
                //     comamaDollarRegex,
                //     ''
                // ) */

                // const [
                //     percentHavingNoExperience,
                //     percentHavingZeroToOneExperience,
                //     percentHavingOneToThreeExperience,
                // ] = r[expOfStudentsIndex]?.split(',');

                // const totalBudget = 4900;
                //     r[
                //         budgeIndex
                //     ]; /* ?.replace(
                //     comamaDollarRegex,
                //     ''
                // ) */

                const rObject = {
                    id: index,
                    title,
                    description,
                    topics,
                    tags,
                    // sampleOutline,
                    clientId,
                    status,
                    domain /* : 'Telecommunication' */,
                    hours: 1,
                    minutes: 15,
                    expectedEndDate,
                    isOnline,
                    contractNumber: '',
                    template,
                    instructorBudget /* : 'The advance is: USD1475 + 10% Royalties' */,
                    // workOrders,
                    // minNumOfStudents,
                    // percentHavingNoExperience: !isNaN(percentHavingNoExperience)
                    //     ? percentHavingNoExperience
                    //     : 0,
                    // percentHavingZeroToOneExperience: !isNaN(
                    //     percentHavingZeroToOneExperience
                    // )
                    //     ? percentHavingZeroToOneExperience
                    //     : 0,
                    // percentHavingOneToThreeExperience: !isNaN(
                    //     percentHavingOneToThreeExperience
                    // )
                    //     ? percentHavingOneToThreeExperience
                    //     : 0,
                    // totalBudget,
                    type: ONDEMAND,
                };
                read.push(rObject);
            }
        });
        console.log(read /* .slice(0, 2) */);

        return read /* .slice(0, 2) */;
    };

    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            const fileName = selectedFile.name;
            const extension = fileName.substr(fileName.lastIndexOf('.') + 1);

            if (extension === 'xlsx') {
                try {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        const data = event.target.result;
                        const readData = XLSX.read(data, { type: 'binary' });
                        const workSheetName =
                            /* 'Sheet1'; // */ readData.SheetNames[0];
                        const ws = readData.Sheets[workSheetName];
                        /* Convert array to json*/
                        const dataParse = XLSX.utils.sheet_to_json(ws, {
                            header: 1,
                        });
                        console.log(dataParse);
                        const requirements = getData(dataParse);
                        if (requirements.length) {
                            // console.log(requirements);
                            setRows(requirements /* .slice(0, 2) */);
                            setOpenPreviewDialog(true);
                        }
                    };
                    reader.readAsBinaryString(selectedFile);
                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log('Please use correct format');
            }
        }
    };

    const handleDialogClose = () => {
        setOpenPreviewDialog(false);
    };

    const importRequirements = async () => {
        try {
            setImporting(true);

            await axios.post(
                `/api/client-requirements/import?template=Short Form (1 hr)&userId=${userDetails._id}`,
                rows
            );

            setImporting(false);
            setOpenPreviewDialog(false);

            window.open(`/admin/opportunity-management`, '_self');
        } catch (e) {
            console.log(e);
            setImporting(false);
        }
    };

    return (
        <>
            <label for="ClientRequirementsBulkUploadLinkedIn">
                <Tooltip
                    title={
                        'Kindly choose an XLSX file that includes columns denoted as "Course Title", "Content Type", "Domain", "Key Words", "Duration", "Due Date", "Template", "Contract Number", "Terms", "Tags".'
                    }
                >
                    <Box style={{ cursor: 'pointer' }}>LinkedIn</Box>
                </Tooltip>
            </label>
            {!disabled && (
                <input
                    type="file"
                    id="ClientRequirementsBulkUploadLinkedIn"
                    hidden
                    onChange={handleFile}
                />
            )}

            <Box>
                <Dialog
                    onClose={handleDialogClose}
                    aria-labelledby="matching-instructor-data-modal"
                    open={openPreviewDialog}
                    fullWidth
                    classes={{ paper: classes.paper }}
                >
                    <Box display="flex">
                        <Typography
                            variant="h6"
                            style={{ marginRight: 'auto' }}
                        >
                            Preview
                            {/* : {rows.map((r) => r.title).join(', ')} */}
                        </Typography>

                        <IconButton
                            aria-label="close"
                            onClick={handleDialogClose}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <>
                        <DialogContent className={classes.dialogContent}>
                            <DataGridPremium
                                autoHeight
                                rows={rows}
                                columns={gridCols}
                                pageSizeOptions={[]}
                                pinnedColumns={pinnedColumns}
                                onPinnedColumnsChange={(
                                    updatedPinnedColumns
                                ) => {
                                    if (isLargeScreen) {
                                        setPinnedColumns(updatedPinnedColumns);
                                    } else {
                                        alert(
                                            'Pinning is not available on small screens.'
                                        );
                                    }
                                }}
                                hideFooter={true}
                                hideFooterSelectedRowCount={true}
                                // disableColumnMenu={true}
                                disableColumnFilter={true}
                                disableColumnSelector={true}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Box
                                className={classes.dialogAction}
                                style={{ width: '100%' }}
                            >
                                <Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleDialogClose}
                                    >
                                        Cancel
                                    </Button>
                                </Box>

                                <Box marginLeft="auto">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={importRequirements}
                                    >
                                        {importing ? (
                                            <CircularProgress size="1.9em" />
                                        ) : (
                                            <>Import</>
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                        </DialogActions>
                    </>
                </Dialog>
            </Box>
        </>
    );
};
