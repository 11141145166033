import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
    Close,
    Info,
    LocationOn,
    Mail,
    Link,
    LinkedIn,
} from '@mui/icons-material';

const useStyles = makeStyles()((theme) => {
    return {
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        infoGroup: {
            display: 'flex',
            gap: theme.spacing(0.5),
            marginBottom: theme.spacing(2),
        },
        flex: {
            display: 'flex',
        },
        itemsCenter: {
            alignItems: 'center',
        },
        justifyBetween: {
            justifyContent: 'space-between',
        },
        link: {
            color: 'blue',
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        list: {
            padding: 0,
            margin: 0,
            listStyleType: 'none',
        },
        listItem: {
            display: 'flex',
            gap: theme.spacing(0.5),
            '&::before': {
                content: '"»"',
                paddingRight: theme.spacing(0.5),
            },
        },
        icon: {
            marginTop: theme.spacing(0.5),
        },
    };
});

function ContactInfoDialog({ open, onClose, pipileadsData }) {
    const { classes } = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll={'paper'}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>
                <Box className={classes.header}>
                    <Typography variant="h5">Contact Info</Typography>
                    <IconButton size="small" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <Box className={classes.infoGroup}>
                    <LinkedIn className={classes.icon} />
                    <Box>
                        <Typography variant="h6">
                            {pipileadsData?.authorName}
                        </Typography>
                        <Box
                            className={`${classes.flex} ${classes.itemsCenter} ${classes.justifyBetween}`}
                            gridGap={5}
                        >
                            <Link fontSize="small" />{' '}
                            <a
                                href={pipileadsData?.linkedinURL}
                                target="_blank"
                                rel="noreferrer"
                                className={classes.link}
                            >
                                {pipileadsData?.linkedinURL}
                            </a>
                        </Box>
                    </Box>
                </Box>

                <Box className={classes.infoGroup}>
                    <Mail className={classes.icon} />
                    <Box>
                        <Typography variant="h6">Email(s)</Typography>
                        <ul className={classes.list}>
                            <li className={classes.listItem}>
                                <span>B2B Email:</span>
                                {pipileadsData?.b2b_email &&
                                pipileadsData?.b2b_email?.toLowerCase() !==
                                    'searching' ? (
                                    <span>{pipileadsData.b2b_email}</span>
                                ) : (
                                    <span>N/A</span>
                                )}
                            </li>
                            <li className={classes.listItem}>
                                <span>B2C Email: </span>
                                {pipileadsData?.b2c_email &&
                                pipileadsData?.b2c_email?.length > 0 ? (
                                    <span>
                                        {pipileadsData.b2c_email.join(', ')}
                                    </span>
                                ) : (
                                    <span>N/A</span>
                                )}
                            </li>
                        </ul>
                    </Box>
                </Box>

                <Box className={classes.infoGroup}>
                    <LocationOn className={classes.icon} />
                    <Box>
                        <Typography variant="h6">Address</Typography>
                        <ul className={classes.list}>
                            <li className={classes.listItem}>
                                <span>Address 1:</span>
                                {pipileadsData?.address_1 ? (
                                    <span>{pipileadsData.address_1}</span>
                                ) : (
                                    <span v-else>N/A</span>
                                )}
                            </li>
                            <li className={classes.listItem}>
                                <span>Address 2:</span>
                                {pipileadsData?.address_2 ? (
                                    <span>{pipileadsData.address_2}</span>
                                ) : (
                                    <span v-else>N/A</span>
                                )}
                            </li>
                            <li className={classes.listItem}>
                                <span>Locality:</span>
                                {pipileadsData?.locality ? (
                                    <span>{pipileadsData.locality}</span>
                                ) : (
                                    <span v-else>N/A</span>
                                )}
                            </li>
                            <li className={classes.listItem}>
                                <span>City:</span>
                                {pipileadsData?.city ? (
                                    <span>{pipileadsData.city}</span>
                                ) : (
                                    <span v-else>N/A</span>
                                )}
                            </li>
                            <li className={classes.listItem}>
                                <span>Postal Code:</span>
                                {pipileadsData?.postal_code ? (
                                    <span>{pipileadsData.postal_code}</span>
                                ) : (
                                    <span v-else>N/A</span>
                                )}
                            </li>
                            <li className={classes.listItem}>
                                <span>Geographic Area:</span>
                                {pipileadsData?.geographicarea ? (
                                    <span>{pipileadsData.geographicarea}</span>
                                ) : (
                                    <span v-else>N/A</span>
                                )}
                            </li>
                        </ul>
                    </Box>
                </Box>

                <Box className={classes.infoGroup}>
                    <Info className={classes.icon} />
                    <Box>
                        <Typography variant="h6">Other info</Typography>
                        <ul className={classes.list}>
                            <li className={classes.listItem}>
                                <span>Industry:</span>
                                {pipileadsData?.industry ? (
                                    <span>{pipileadsData.industry}</span>
                                ) : (
                                    <span v-else>N/A</span>
                                )}
                            </li>

                            <li className={classes.listItem}>
                                <span>Company Name:</span>
                                {pipileadsData?.company_name ? (
                                    <span>{pipileadsData.company_name}</span>
                                ) : (
                                    <span v-else>N/A</span>
                                )}
                            </li>

                            <li className={classes.listItem}>
                                <span>Company Type:</span>
                                {pipileadsData?.company_type ? (
                                    <span>{pipileadsData.company_type}</span>
                                ) : (
                                    <span v-else>N/A</span>
                                )}
                            </li>

                            <li className={classes.listItem}>
                                <span>Designation:</span>
                                {pipileadsData?.designation ? (
                                    <span>{pipileadsData.designation}</span>
                                ) : (
                                    <span v-else>N/A</span>
                                )}
                            </li>

                            <li className={classes.listItem}>
                                <span>Website:</span>
                                {pipileadsData?.website ? (
                                    <span>
                                        <a
                                            href={pipileadsData.website}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={classes.link}
                                        >
                                            {pipileadsData.website}
                                        </a>
                                    </span>
                                ) : (
                                    <span v-else>N/A</span>
                                )}
                            </li>
                        </ul>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>
    );
}

export default ContactInfoDialog;
