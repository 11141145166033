import { atom } from 'recoil';

export const searchQueryState = atom({
    key: 'searchQuery',
    default: '',
});

export const searchPageNumberState = atom({
    key: 'searchPageNumberState',
    default: 1,
});

export const browseCategoriesState = atom({
    key: 'browseCategoriesState',
    default: [],
});
