import { makeStyles } from 'tss-react/mui';
import { blue } from '@mui/material/colors';
import { CATEGORY_BANNER_URL } from '../img';
import { red } from '@mui/material/colors';
import image from '../img/assorted.webp';
// const baseStart = 2;
// const blurWhiteBg = {
//     backdropFilter: 'blur(4px)',
//     backgroundColor: 'rgba(250,250,250,0.5)',
// };

const blurBlackBg = {
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(20,20,20,0.8)',
    color: '#fafafa',
};

export const useStyles = makeStyles()((theme) => ({
    bannerContainer: {
        height: '75vh',
        marginTop: '-20px',
        width: '100%',
        position: 'absolute',
        zIndex: '-12',
    },
    h4Heading: {
        fontSize: 'min(3.5em, 3.5vw)',
        fontWeight: 700,
        [theme.breakpoints.only('xs')]: {
            fontSize: 'min(3.5em, 8vw)',
        },
    },
    actionButton: {
        fontSize: 'min(1.3em, 2.5vw)',
        marginTop: '35px',
        width: 'fit-content',
        [theme.breakpoints.only('xs')]: {
            marginBottom: '50px',
        },
    },
    banner: {
        position: 'relative',
        // backgroundColor: '#626262',
        backgroundImage: `url(${CATEGORY_BANNER_URL})`,
        backgroundPosition: '50% 80%',
        backgroundSize: 'cover',
        height: '100%',
        width: '100%',

        // backgroundBlendMode: 'multiply',
        // '&:before': {
        //     position: 'absolute',
        //     content: '""',
        //     top: 0,
        //     left: 0,
        //     width: '100%',
        //     height: '100%',
        //     // background:
        //     // 'linear-gradient(180deg, rgba(25,46,198,0) 0%, rgba(250,250,250,0.4) 80%, rgba(250,250,250,1) 100%)',
        //     // 'radial-gradient(circle, rgba(51,51,51,0) 0%, rgba(59,59,59,1) 100%)',
        //     // 'linear-gradient(135deg, rgba(51,51,51,0.4) 0%, rgba(59,59,59,1) 100%)',
        //     // '',
        // },
    },
    bannerContent: {
        display: 'flex',
        flexFlow: 'column',
    },
    heading: {
        position: 'relative',
        padding: '5px 10px',
        ...blurBlackBg,
        // backdropFilter: 'blur(2px)',
        // backgroundColor: 'rgba(250,250,250,0.5)',
    },
    backgroundImage: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '19%',
        height: '100%',
        backgroundImage: `url(${image})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: '0.8',
        zIndex: '-100',
        [theme.breakpoints.down('md')]: {
            display: 'none',
            width: '28%',
        },
    },
    sectionBackground: {
        position: 'relative',
        zIndex: '1',
        backgroundColor: '#e0e0e0',
        padding: '50px 0 75px',
        overflow: 'hidden',
    },
    followIcon: {
        display: 'inline-block',
        marginLeft: 20,
        transform: 'rotate(192deg) translateY(-30px)',
        filter: 'hue-rotate(53deg) drop-shadow(1px 1px 1px rgba(0,0,0,0.2))',
    },
    colorBlue: {
        color: `${theme.palette.common.blue} !important`,
    },
    secondHeading: {
        fontSize: 'min(3em, 6.6vw)',
    },
    sectionHeading: {
        fontSize: 'min(2.8em, 6.6vw)',
        color: '#333',
        fontWeight: 500,
        width: 'fit-content',
        position: 'relative',
        wordSpacing: '3px',
        zIndex: '1',
        margin: '45px auto 45px',
    },
    quotes: {
        position: 'absolute',
        top: '-80%',
        left: '-3%',
        transform: 'rotate(180deg)',
        [theme.breakpoints.only('lg')]: {
            top: '-95%',
            left: '-5%',
        },
        [theme.breakpoints.only('xl')]: {
            top: '-80%',
            left: '-7%',
        },
    },
    hideLines: {
        '&::after': {
            display: 'none',
        },
        '&::before': {
            display: 'none',
        },
    },
    subHeadingContainer: {
        textAlign: 'center',
        position: 'relative',
        padding: '5px',
        ...blurBlackBg,
    },
    subHeading: {
        // opacity: 0,
        opacity: 1,
        align: 'center',
        fontWeight: 400,
        fontSize: 'min(1.9em, 5vw)',
        // color: '#111',
        // animation: `$fadeIn ${
        //     baseStart / 2
        // }s cubic-bezier(0.87, 0.29, 0.2, 0.97) forwards`,
        // animationDelay: `${(baseStart + 1) / 2}s`,
    },
    subBlock: {
        position: 'absolute',
        zIndex: 1,
        width: '0%',
        height: '100%',
        top: 0,
        left: 0,
        // backgroundColor: theme.palette.common.lightBlue,
        backgroundColor: '#fafafa',
        // animation: `$entryBlock ${baseStart}s cubic-bezier(0.87, 0.29, 0.2, 0.97) forwards`,
        // animationDelay: `${baseStart / 2}s`,
    },
    headingText: {
        // opacity: 0,
        opacity: 1,
        fontWeight: 700,
        // color: '#111',
        fontSize: 'min(4.5em, 10vw)',
        textAlign: 'center',
        // animation: `$fadeIn ${
        //     baseStart / 2
        // }s cubic-bezier(0.87, 0.29, 0.2, 0.97) forwards`,
        // animationDelay: `${(baseStart + 1) / 2}s`,
    },
    block: {
        position: 'absolute',
        zIndex: 1,
        width: '0%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: theme.palette.secondary.main,
        // animationDelay: `${baseStart / 2}s`,
        // animation: `$entryBlock ${baseStart}s cubic-bezier(0.87, 0.29, 0.2, 0.97) forwards`,
    },
    contentSection: {
        color: '#fafafa',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // opacity: 0,
        opacity: 1,
        // animation: `$fadeIn ${
        //     baseStart / 2
        // }s cubic-bezier(0.87, 0.29, 0.2, 0.97) forwards`,
        // animationDelay: `${baseStart / 2}s`,
    },
    contentItem: {
        marginBottom: '13px',
    },
    trailItem: {
        padding: '5px 10px',
        margin: '24px 0 ',
        color: '#222',
        fontWeight: 400,
        textTransform: 'uppercase',
        ...blurBlackBg,
    },
    button: {
        fontSize: 'min(1.5em, 4vw)',
        marginBottom: '35px',
        [theme.breakpoints.only('xs')]: {
            marginBottom: '50px',
        },
    },
    '@keyframes entryBlock': {
        '0%': {
            width: '0%',
            left: '0',
        },
        '50%': {
            width: '100%',
            left: '0',
        },
        '100%': {
            width: '0%',
            left: '100%',
        },
    },
    '@keyframes fadeIn': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },

    logosHeading: {
        width: 'fit-content',
        fontWeight: 700,
        margin: '10px auto 30px',
        fontSize: 'min(1.5em, 6vw)',
        position: 'relative',
        '&::before': {
            position: 'absolute',
            content: '""',
            left: 0,
            bottom: '-3px',
            height: '3px',
            borderRadius: '5px',
            width: '100%',
            backgroundColor: theme.palette.secondary.main,
        },
    },
    topHeadingBlue: {
        color: theme.palette.common.blue,
        fontSize: 'min(4em, 12vw)',
        lineHeight: '35px',
        [theme.breakpoints.up('md')]: {
            fontSize: 'min(3.5em, 12vw)',
            marginTop: 100,
        },
    },
    headingBlue: {
        color: theme.palette.common.blue,
        fontSize: 'min(4em, 12vw)',
        [theme.breakpoints.up('md')]: {
            fontSize: 'min(3.5em, 12vw)',
        },
        lineHeight: '35px',
    },
    headingOragnge: {
        fontSize: 'min(2.5em, 9vw)',
        color: theme.palette.secondary.main,
    },
    subHeadingOrange: {
        color: theme.palette.secondary.main,
    },
    subHeadingBlue: {
        lineHeight: '1',
        marginTop: 50,
        fontSize: 'min(1.7em, 7vw)',
        color: theme.palette.common.blue,
    },
    blueText: {
        color: theme.palette.common.blue,
    },
    /* termsContainer: {
        marginBottom: 100,
    }, */
    actionBtn: {
        backgroundColor: blue[800],
        color: '#fafafa',
        '&:hover': {
            backgroundColor: blue[900],
        },
    },
    // VIdeo
    videoContainer: {
        minHeight: '400px',
        [theme.breakpoints.down('md')]: {
            minHeight: '300px',
        },
    },
    video: {
        /*  height: '51vw', */
        position: 'relative',
        cursor: 'pointer',
        [theme.breakpoints.up('md')]: {
            height: '450px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '550px',
        },
        [theme.breakpoints.up('xl')]: {
            height: '700px',
        },
        // '&:before': {
        //     position: 'absolute',
        //     content: '""',
        //     zIndex: 1,
        //     top: 0,
        //     left: 0,
        //     height: '100%',
        //     width: '100%',
        //     background:
        //         'linear-gradient(270deg, rgba(36,34,49,0) 0%, rgba(250,250,250,1) 98%)',
        // },
    },
    navigationTab: {
        margin: '0 auto',
        width: 'fit-content',
        [theme.breakpoints.only('xs')]: {
            width: '100%',
        },
    },
    // --------------Categories------------
    gridContainer: {
        display: 'grid',
        gridTemplateRows: '1fr 1fr 1fr',
        gridTemplateColumns: '1fr 1fr 1fr',
        [theme.breakpoints.only('sm')]: {
            height: '100vh',
            gridTemplateRows: 'auto',
            gridTemplateColumns: '1fr 1fr',
        },
        [theme.breakpoints.only('xs')]: {
            gridTemplateRows: 'auto',
            gridTemplateColumns: 'minmax(90vw, 1fr)',
            height: '150vh',
        },
        gridGap: '10px',
        height: '800px',
        width: '100%',
        margin: '0 auto',
        marginTop: '50px',
    },
    item: {
        display: 'flex',
        alignItems: 'flex-end',
        transition: 'all 0.2s ease-in',
        boxShadow: '0px 0px 0px rgba(0,0,0,0)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        zIndex: '0',
        backgroundColor: '#999',
        backgroundBlendMode: 'overlay',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        '&::before': {
            position: 'absolute',
            top: 0,
            left: 0,
            content: '""',
            backgroundColor: 'rgba(0,0,0,0.6)',
            width: '100%',
            height: '100%',
            transition: 'all 0.2s ease-in',
            transform: 'translateY(-100%)',
            backdropFilter: 'blur(4px)',
            zIndex: '1',
        },
        '&:nth-child(2)': {
            gridRow: 'span 2',
            [theme.breakpoints.down('md')]: {
                gridRow: 'span 2',
                gridColumn: 'span 1',
            },
            [theme.breakpoints.only('xs')]: {
                gridRow: 'span 1',
                gridColumn: 'span 1',
            },
        },
        '&:nth-child(6)': {
            gridColumn: 'span 2',
            [theme.breakpoints.down('md')]: {
                gridRow: 'span 1',
                gridColumn: 'span 1',
            },
        },
        '&:hover, &:focus': {
            zIndex: '5',
            transform: 'scale(1.02)',
            boxShadow: '0px 5px 2px rgba(0,0,0,0.4)',
            alignItems: 'center',
            justifyContent: 'center',
            transformOrigin: 'top',
            '& $catagoriesDomainName': {
                opacity: '0',
            },
            '& $catagoriesContentOnHover': {
                opacity: 1,
            },
            '&::before': {
                transform: 'translateY(0%)',
            },
        },
    },
    catagoriesDomainName: {
        fontSize: '1.7em',
        fontWeight: 600,
        width: 'fit-content',
        padding: '10px 20px',
        margin: '10px',
        transition: 'all 0.2s ease-in',
        textTransform: 'uppercase',
        ...blurBlackBg,
    },
    catagoriesContentName: {
        fontSize: '2em',
        fontWeight: 600,
        color: '#fff',
        margin: '0px auto ',
        cursor: 'pointer',
        textTransform: 'uppercase',
    },
    catagoriesContentOnHover: {
        cursor: 'pointer',
        opacity: 0,
        transition: 'all 0.2s ease-in',
        transitionDelay: '0.2s',
        zIndex: '100',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    // --------------Categories------------
    // --------------Card------------
    card: {
        // can give max width if we want
        cursor: 'pointer',
        display: 'flex',
        flexFlow: 'column',
        maxWidth: '100%',
        margin: '20px 10px',
        borderRadius: 10,
        backgroundColor: '#fafafa',
        transition: '0.2s all ease-out',
        position: 'relative',
        boxShadow: '0px 0px 10px 4px rgba(0,0,0,0.2)',
        '&:hover': {
            zIndex: '100',
            position: 'absolute',
            [theme.breakpoints.only('xs')]: {
                position: 'relative',
            },
            transformOrigin: 'center',
            transform: 'scale(1.05)',
            '& $cardBackdrop': {
                '&::before': {
                    backgroundColor: 'rgba(0,0,0,0.4)',
                },
            },
            '& $cardHeading': {
                whiteSpace: 'normal',
            },
            '& $description': {
                display: 'block',
            },
        },
    },
    cardImg: {
        width: '100%',
        minHeight: '150px',
        height: 'auto',
        margin: '0 auto',
        borderRadius: '10px 10px 0 0',
        transition: '0.2s all ease-out',
    },
    contentBox: {
        padding: '10px',
    },
    cardHeading: {
        fontSize: 'min(1.2em, 4.5vw)',
        fontWeight: 'bold',
        marginBottom: '10px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    subHeadCard: {
        color: '#9a9a9a',
        fontWeight: 500,
    },
    description: {
        display: 'none',
    },
    tags: {
        position: 'absolute',
        top: 16,
        left: 0,
        padding: '5px 10px',
        zIndex: '11',
        backgroundColor: red[400],
        color: '#fafafa',
        fontWeight: 600,
        transition: '0.2s all ease-out',
        clipPath: 'polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%)',
        boxShadow: '0px 10px 2px rgba(0,0,0,0.2)',
    },
    // --------------Card------------
    // FEATURE
    features: {
        margin: '20px 0',
        fontSize: 'min(1.7em, 7vw)',
        position: 'relative',
        '&:before': {
            position: 'absolute',
            content: '""',
            zIndex: 1,
            bottom: '-3px',
            left: 0,
            height: '3px',
            background:
                'linear-gradient(90deg, rgba(234,113,18,1) 0%, rgba(250,250,250,0) 100%)',
            borderRadius: '5px 0 5px 0',
            width: '100%',
        },
    },
    videos: {
        height: '450px',
        backgroundColor: theme.palette.common.lightBlue,
        position: 'relative',
    },

    logo: {
        boxSizing: 'border-box',
        height: '70px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },

    pushFooterDown: {
        height: 1500,
    },
    // instructor content
    avatar: {
        height: '120px',
        width: '120px',
        boxShadow: theme.shadows[20],
        marginBottom: '20px',
    },
    designation: {
        fontSize: '0.8em',
    },
    informationBullets: {
        fontSize: 'min(1.3em, 4vw)',
    },
    // testimony
    testimonyName: {
        color: theme.palette.common.lightBlue,
        marginBottom: '5px',
    },
    testimony: {
        textAlign: 'center',
        margin: '30px auto 0',
        width: 'min(260px, 75%)',
        fontSize: '1em',
        lineHeight: '1.5em',
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '-15px',
            left: '50%',
            transform: 'translateX(-50%)',
            height: '2px',
            width: '50%',
            zIndex: '-1',
            backgroundColor: theme.palette.common.lightBlue,
        },
    },

    cardContainer: {
        position: 'relative',
        display: 'grid',
        gap: '1.25rem',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    },
}));
