import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(0),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    medium: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
}));

const ProfilePicture = (props) => {
    const { classes } = useStyles();
    const { alt, src, size, name } = props;

    const profileSize = useMemo(() => {
        switch (size) {
            case 'SMALL':
                return classes.small;
            case 'MEDIUM':
                return classes.medium;
            case 'LARGE':
                return classes.large;
            default:
                return classes.small;
        }
    }, [classes.large, classes.medium, classes.small, size]);

    const profileName = useMemo(() => {
        return name?.length > 0 ? name.substr(0, 1) : 'N/A';
    }, [name]);

    return (
        <div className={classes.root}>
            {src && src?.length > 0 ? (
                <Avatar alt={alt} src={src} className={profileSize} />
            ) : (
                <Avatar
                    alt={alt}
                    className={`${profileSize} ${classes.orange}`}
                >
                    {profileName}
                </Avatar>
            )}
        </div>
    );
};

export default ProfilePicture;
