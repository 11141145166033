import React, { useState } from 'react';

import { ListSearchAndSelectSingle } from '../../ui';

import { useThirdPartyCourseSearch } from '../useThirdPartyCourseSearch';

export const ThirdPartyCourseSearch = ({
    title,
    subTitle,
    handleItemSelect,
}) => {
    const [selected, setSelected] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);

    const { results, hasMore, loading, error } = useThirdPartyCourseSearch(
        searchQuery,
        pageNumber,
        10
    );

    // const handleItemSelect = (item) => {
    //     console.log('handleItemSelect: ', item);
    // };

    return (
        <ListSearchAndSelectSingle
            heading={title}
            subHeading={subTitle}
            list={results}
            handleItemSelect={handleItemSelect}
            setSelectedList={setSelected}
            textField="title"
            subTextField="instructors"
            paginationMode="server"
            search={searchQuery}
            setSearch={setSearchQuery}
            setPageNumber={setPageNumber}
            loading={loading}
            hasMore={hasMore}
        />
    );
};
