import { Box, IconButton, PageLoader, Typography } from '../ui';

import { ClapsIcons } from '../icons';
import { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { UserDisplay } from './UserDisplay';
import { useStyles } from './styles';
import { deleteReplySelector } from './recoil';
import { useSetRecoilState } from 'recoil';
import { ReportBox } from './ReportBox';
import ReactHTMLParser from 'react-html-parser';
import { CommentMoreOptions } from './CommentMoreOptions';
import { CommentsInput } from './CommentsInput';
import Attachments from './CommentAttachment/Attachments';
import {
    REPLY,
    reset,
    setCommentId,
    setReplyId,
    setType,
} from './slices/commentAttachmentSlice';
import { useSelector, useDispatch } from 'react-redux';
import { AzureBlobStorage } from '../util/BlobStorage';

export const ReplyItem = ({
    reply,
    user,
    commentId,
    starweaverAdmin = false,
    blockAccess = () => console.log('block-access'),
    setComments = undefined,
    mentionables = [],
    tasks = [],
    teams = [],
    tasksOutputTemplate,
    showCommentAttachments = false,
    showAttachments = false,
}) => {
    const { classes } = useStyles();
    const [openReport, setOpenReport] = useState(false);
    const [usersUpVoted, setUsersUpVoted] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const deleteReplyFromState = useSetRecoilState(deleteReplySelector);
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const commentAttachments = useSelector((state) => state.commentAttachments);
    const dispatch = useDispatch();
    const [isDeleting, setIsDeleting] = useState(false);
    const [showEditor, setShowEditor] = useState(true);

    useEffect(() => {
        if (commentAttachments.closeEditor) {
            dispatch(reset());
            setShowEdit(false);
        }
    }, [commentAttachments.closeEditor, dispatch]);

    useEffect(() => {
        if (reply) {
            setUsersUpVoted(reply.users_up_votes || []);
        }
    }, [reply]);

    const liked = useMemo(() => {
        return usersUpVoted.some((id) => id === user?._id);
    }, [usersUpVoted, user]);

    const totalLikes = useMemo(() => {
        return usersUpVoted.length;
    }, [usersUpVoted]);

    const toggleReport = () => {
        setOpenReport((prevState) => !prevState);
    };

    const handleLike = async () => {
        if (!user) {
            blockAccess();
            return;
        }
        if (reply && user && !isProcessing && commentId) {
            setIsProcessing(true);
            if (liked) {
                try {
                    const {
                        data: { disliked },
                    } = await axios.post(
                        `/api/content/${reply._id}/reply/dislike`,
                        {}
                    );
                    console.log('disliked--> ' + disliked);
                } catch (error) {
                    console.log(error.message);
                }
                setUsersUpVoted((prevState) =>
                    prevState.filter((id) => id !== user._id)
                );
                setComments((prev) => {
                    return prev.map((comment) => {
                        if (comment._id === commentId) {
                            return {
                                ...comment,
                                replies: comment.replies.map((_r) => {
                                    if (_r._id === reply._id) {
                                        return {
                                            ..._r,
                                            users_up_votes: _r.users_up_votes.filter(
                                                (id) => id !== user._id
                                            ),
                                        };
                                    } else {
                                        return { ..._r };
                                    }
                                }),
                            };
                        } else {
                            return { ...comment };
                        }
                    });
                });
                // dislike
            } else {
                try {
                    const { data } = await axios.post(
                        `/api/content/${reply._id}/reply/like`,
                        {}
                    );
                    console.log('liked--> ' + data.liked);
                } catch (error) {
                    console.log(error.message);
                }
                setUsersUpVoted((prevState) => [...prevState, user._id]);
                setComments((prev) => {
                    return prev.map((comment) => {
                        if (comment._id === commentId) {
                            return {
                                ...comment,
                                replies: comment.replies.map((_r) => {
                                    if (_r._id === reply._id) {
                                        return {
                                            ..._r,
                                            users_up_votes: [
                                                ..._r.users_up_votes,
                                                user._id,
                                            ],
                                        };
                                    } else {
                                        return { ..._r };
                                    }
                                }),
                            };
                        } else {
                            return { ...comment };
                        }
                    });
                });
                // like
            }
            setIsProcessing(false);
            console.log('like-reply');
        } else {
            console.log('error');
        }
    };

    const deleteReplyAttachments = async (reply) => {
        const blobStorage = new AzureBlobStorage();
        const containerName = 'comments';
        const { fileNames, size } = await blobStorage.listFilesFromFolder(
            containerName,
            `${commentId}/replies/${reply._id}/`
        );
        if (size === 0) return;
        const promisses = new Array(size);
        for (const file of fileNames) {
            promisses.push(
                blobStorage.deleteFile(
                    file.actual,
                    containerName,
                    `${commentId}/replies/${reply._id}`
                )
            );
        }
        await Promise.allSettled(promisses);
    };

    const deleteAllAttachments = async (reply) => {
        try {
            await deleteReplyAttachments(reply);
        } catch (e) {
            console.log(e);
        }
    };

    const deleteReply = async () => {
        if (starweaverAdmin) {
            try {
                setIsDeleting(true);
                if (reply?.hasFiles === true) {
                    await deleteAllAttachments(reply);
                }

                const {
                    data: { deleted },
                } = await axios.post(
                    `/api/content/${reply._id}/reply/delete`,
                    {}
                );
                // deleteReplyFromState({
                //     commentId: commentId,
                //     replyId: reply._id,
                // });
                if (setComments !== undefined) {
                    setComments((prev) => {
                        return prev.map((comment) => {
                            if (commentId === comment._id) {
                                return {
                                    ...comment,
                                    replies: comment.replies.filter(
                                        (r) => r._id !== reply._id
                                    ),
                                };
                            } else {
                                return { ...comment };
                            }
                        });
                    });
                }
                console.log('deleted--> ' + deleted);
            } catch (error) {
                console.log(error.message);
            } finally {
                setIsDeleting(false);
            }
        }
    };

    const onEditOtpClick = async () => {
        setShowEdit(true);
    };

    const reportComment = async ({ reason, reportText }) => {
        if (!user) {
            blockAccess();
            return;
        }
        try {
            const {
                data: { reported },
            } = await axios.post(`/api/content/${reply._id}/reply/report/`, {
                reason,
                reportText,
                userId: user._id,
            });
            console.log('reported--> ' + reported);
            deleteReplyFromState({
                commentId: commentId,
                replyId: reply._id,
            });
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleSaveEditedReply = async ({
        comment: reply_text,
        hasFiles = false,
    }) => {
        const payload = {
            commentId,
            replyId: reply._id,
            reply_text,
            hasFiles,
        };
        try {
            setLoading(true);
            const { status } = await axios.patch(
                '/api/content/edit-reply',
                payload
            );
            if (status === 200) {
                if (commentAttachments.isFile) {
                    dispatch(setCommentId(commentId));
                    dispatch(setType(REPLY));
                    dispatch(setReplyId(reply._id));
                }
                setComments((comments) => {
                    return comments.map((comment) => {
                        if (comment._id === commentId) {
                            return {
                                ...comment,
                                replies: comment.replies.map((reply) => {
                                    if (reply._id === payload.replyId) {
                                        return {
                                            ...reply,
                                            reply_text,
                                            isEdited: true,
                                            hasFiles,
                                        };
                                    } else {
                                        return reply;
                                    }
                                }),
                            };
                        } else {
                            return comment;
                        }
                    });
                });
                if (!commentAttachments.isFile) {
                    setShowEdit(false);
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const onCancelEdit = () => {
        setShowEdit(false);
    };

    return (
        <Box mb={1} pl={1} display="flex" flexDirection="column">
            <Box mb={1}>
                <Box display="flex" alignItems="flex-start">
                    <UserDisplay response={reply} userId={reply.user_id} />

                    <Box ml="auto">
                        <CommentMoreOptions
                            toggleOpenReport={toggleReport}
                            deleteComment={deleteReply}
                            starweaverAdmin={starweaverAdmin}
                            blockAccess={blockAccess}
                            user={user}
                            isCurrentUser={reply.user_id === user?._id}
                            isReviewOwner={user?.isReviewOwner}
                            editComment={onEditOtpClick}
                        />
                    </Box>
                </Box>
                {!showEdit && (
                    <Box mb={1} ml={5}>
                        <Typography variant="body2">
                            {ReactHTMLParser(reply.reply_text)}
                        </Typography>
                    </Box>
                )}
                {showEdit && (
                    <Box mb={1}>
                        <CommentsInput
                            user={user}
                            blockAccess={blockAccess}
                            small={true}
                            buttonText="Edit"
                            label="Your Reply"
                            placeholder={`Edit reply`}
                            finalFunction={handleSaveEditedReply}
                            mentionables={mentionables}
                            tasks={tasks}
                            // showCkEditor
                            showEditor={showEditor}
                            setShowEditor={setShowEditor}
                            teams={teams}
                            tasksOutputTemplate={tasksOutputTemplate}
                            handleCloseEdit={onCancelEdit}
                            loading={loading}
                            existingCommentText={reply.reply_text}
                            showCommentAttachments={showCommentAttachments}
                            hideCourseTagging={user?.is_instructor}
                        />
                    </Box>
                )}

                {showAttachments && reply?.hasFiles === true && (
                    <Box mb={1} ml={5}>
                        <Attachments
                            type={REPLY}
                            replyId={reply._id}
                            commentId={commentId}
                        />
                    </Box>
                )}

                {/* reply action */}
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box display="flex" alignItems="center">
                        <IconButton
                            onClick={handleLike}
                            className={classes.action}
                            size="small"
                        >
                            {liked ? (
                                <ClapsIcons
                                    color="secondary"
                                    fontSize="small"
                                />
                            ) : (
                                <ClapsIcons fontSize="small" />
                            )}
                        </IconButton>
                        {totalLikes > 0 && (
                            <Typography variant="body2">
                                {totalLikes}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box>
                <ReportBox
                    reportResponse={reportComment}
                    openReport={openReport}
                />
            </Box>
            {isDeleting && <PageLoader open={isDeleting} />}
        </Box>
    );
};
