import React, { useCallback, useState, useMemo } from 'react';
import { Box } from '../../../ui';
import ClientRequirements from '../../ClientRequirements';
import { makeStyles } from 'tss-react/mui';
import {
    Button,
    ButtonBase,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { CloseIcon } from '../../../icons';
import * as XLSX from 'xlsx';
import moment from 'moment';

const useStyles = makeStyles()((theme) => ({
    root: {},
    tabWrapper: {
        display: 'flex',
    },
    tabContainer: {
        marginBottom: '0.5rem',
    },
    tab: {
        fontSize: '12px',
        fontWeight: 600,
        padding: '10px 12px',
        textTransform: 'uppercase',
        color: '#ea7112',
        [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
        },
    },
    dialogPaper: {
        maxHeight: '85vh',
    },
    actionBtn: {
        width: '100%',
        marginBottom: '0.5rem',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    flexContainer: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    exportBtn: {
        [theme.breakpoints.down('md')]: {
            paddingBlock: theme.spacing(0.25),
        },
    },
}));

function InstructorProjectDialog({
    email,
    open,
    handleClose,
    requirements = [],
}) {
    const { classes } = useStyles();
    const [loadedRequirements, setLoadedRequirements] = useState([]);

    const printFormattedType = (typeName) => {
        let words = typeName.split(/[-\s]/);

        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            let firstLetter = word.slice(0, 1).toUpperCase();
            let restOfLetters = word.slice(1, word.length).toLowerCase();

            words[i] = firstLetter + restOfLetters;
        }

        return words.join(' ');
    };

    /* const getApplicantsNames = (applicants) => {
        let valueFormatter = 'N/A',
            cellValue = 'N/A';
        if (!applicants || applicants?.length === 0)
            return { valueFormatter: 'N/A', cellValue: 'N/A' };
        const joinedApplicantNames = applicants
            .map(
                (applicant) =>
                    applicant.user_firstName + ' ' + applicant?.user_lastName ??
                    ''
            )
            ?.join(', ');
        if (joinedApplicantNames?.length > 18) {
            valueFormatter = joinedApplicantNames;
            cellValue = joinedApplicantNames.slice(0, 18) + '...';
        } else {
            valueFormatter = cellValue = joinedApplicantNames;
        }
        return { valueFormatter, cellValue };
    }; */

    const echoSavedTags = (row) => {
        if (row.tags && row.tags.length > 0) {
            return row.tags.join(', ');
        }

        return 'N/A';
    };

    /* const echoTags = (params) => {
        const { row } = params;
        const { customTopics = [], topics = [] } = row;

        if (customTopics?.length > 0 && topics?.length === 0) {
            return customTopics.join(', ');
        } else if (customTopics?.length === 0 && topics?.length > 0) {
            return topics.map(({ name }) => name).join(', ');
        } else if (customTopics?.length === 0 && topics?.length === 0) {
            return 'N/A';
        } else {
            return topics
                .map(({ name }) => name)
                .concat(customTopics)
                .join(', ');
        }
    }; */
    const handleExport = () => {
        // DATA TO BE EXPORTED
        const allRequirements =
            loadedRequirements.length > 0 ? loadedRequirements : requirements;
        const data = allRequirements?.map((row) => {
            return {
                Title: row.title,
                '% Completed': row.percentageCompleted,
                /* Client: row?.clientId?.client_name
                    ? row.clientId.client_name
                    : 'N/A', */
                Type: row?.type
                    ? row.type === 'ON-AIR'
                        ? 'Live Stream'
                        : printFormattedType(row.type)
                    : 'N/A',
                Duration: `${row?.hours}hr(s) - ${row?.minutes}min(s)`,
                'Delivery Date': row?.expectedEndDate
                    ? moment(row.expectedEndDate).format('DD-MMM-YYYY')
                    : 'N/A',
                /* '# Matches': row?.numOfMatchedInstructors
                    ? row?.numOfMatchedInstructors
                    : 'N/A',
                '# Applicants': row?.interestedIntstructorIds?.length || 0,
                'Applicants Name': getApplicantsNames(row?.applicantNames)
                    .valueFormatter,
                'Lead Collaborators':
                    row?.collaborators?.length > 0
                        ? row.collaborators
                              .map((collaborator) => {
                                  return (
                                      collaborator.firstName +
                                      (collaborator?.lastName
                                          ? ' ' + collaborator.lastName
                                          : '')
                                  );
                              })
                              .join(', ')
                        : 'N/A',
                'Added Collaborators':
                    row?.addedCollaborators?.length > 0
                        ? row.addedCollaborators
                              .map((collaborator) => {
                                  return (
                                      collaborator.firstName +
                                      (collaborator?.lastName
                                          ? ' ' + collaborator.lastName
                                          : '')
                                  );
                              })
                              .join(', ')
                        : 'N/A',
                Identified:
                    row?.identifiedSmes?.length > 0
                        ? row.identifiedSmes
                              .map((sme) => {
                                  return (
                                      sme.firstName +
                                      (sme?.lastName ? ' ' + sme.lastName : '')
                                  );
                              })
                              .join(', ')
                        : 'N/A',
                Shortlisted:
                    row?.shortListedSmes?.length > 0
                        ? row.shortListedSmes
                              .map((sme) => {
                                  return (
                                      sme.firstName +
                                      (sme?.lastName ? ' ' + sme.lastName : '')
                                  );
                              })
                              .join(', ')
                        : 'N/A',
                Assigned:
                    row?.smes?.length > 0
                        ? row.smes
                              .map((sme) => {
                                  return (
                                      sme.firstName +
                                      (sme?.lastName ? ' ' + sme.lastName : '')
                                  );
                              })
                              .join(', ')
                        : 'N/A', */
                'Terms & Conditions': row?.instructorBudget
                    ? row?.instructorBudget
                    : 'N/A',
                'Budget Range': `$${row?.minBudget ? row?.minBudget : 0} to $${
                    row?.maxBudget ? row.maxBudget : 0
                }`,
                Status: row.status,
                Urgency: row?.urgency ? row.urgency : 'N/A',
                Domain: row?.domain ? row.domain : 'N/A',
                /* Topics: echoTags({ row }), */
                'Contract No.': row?.contractNumber
                    ? row.contractNumber
                    : 'N/A',
                'Work Orders':
                    row?.workOrders?.length > 0
                        ? row.workOrders.map((o) => o.workOrder).join('; ')
                        : 'N/A',
                Tags: echoSavedTags(row),
                'Created At': moment(row.createdAt).format('DD-MMM-YYYY'),
                'Updated At': moment(row.updatedAt).format('DD-MMM-YYYY'),
            };
        });

        // CREATE WORKSHEET
        const workSheet = XLSX.utils.json_to_sheet(data);

        // CREATE WORK-BOOK
        const workBook = XLSX.utils.book_new();

        // ADD BOOK TO THE SHEET
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Requirements');

        // CREATE NEW BUFFER
        // let buffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });

        // CREATE A NEW BINARY STRING
        XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

        // DOWNLOAD THE FILE
        XLSX.writeFile(workBook, 'Requirements.xlsx');
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="instructor-project-title"
            aria-describedby="instructor-project-description"
            fullWidth
            maxWidth="lg"
            classes={{ paper: classes.dialogPaper }}
        >
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                pr={2}
                pl={3}
                py={2}
            >
                <Typography variant="h5">Expert Management Projects</Typography>
                <IconButton onClick={handleClose} size="small">
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent dividers>
                <DialogContentText id="instructor-project-description">
                    <InstructorProjects
                        smeEmail={email}
                        handleExport={handleExport}
                        setLoadedRequirements={setLoadedRequirements}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ paddingRight: '1rem' }}></DialogActions>
        </Dialog>
    );
}

export const InstructorProjects = ({
    smeEmail,
    hideExport = false,
    handleExport = () => {},
    setLoadedRequirements = () => {},
}) => {
    const { classes } = useStyles();
    const [tabs, setTabs] = useState([
        {
            id: 2,
            tabName: 'in progress',
            value: 'IN PROGRESS',
            count: 0,
            isActive: true,
        },
        {
            id: 3,
            tabName: 'completed',
            value: 'COMPLETED',
            count: 0,
            isActive: false,
        },
    ]);
    const theme = useTheme();
    const isXSDevice = useMediaQuery(theme.breakpoints.down('md'));

    const getCount = useCallback((count) => {
        const { all, inProgress, completed } = count;
        setTabs((tabs) => {
            return tabs.map((tab) => {
                if (tab.id === 1) return { ...tab, count: all };
                else if (tab.id === 2) return { ...tab, count: inProgress };
                else return { ...tab, count: completed };
            });
        });
    }, []);

    const activeTabValue = useMemo(() => {
        return tabs.filter((tab) => tab.isActive)[0].value;
    }, [tabs]);

    return (
        <Box className={classes.root}>
            <Box
                marginBottom={isXSDevice ? 0 : 3}
                display="flex"
                flexDirection={'column'}
                alignItems="flex-start"
            >
                <div className={classes.flexContainer}>
                    <Box className={classes.tabWrapper}>
                        {tabs.map((tab) => {
                            return (
                                <Tab
                                    key={tab.id}
                                    tabId={tab.id}
                                    isActive={tab.isActive}
                                    tabName={tab.tabName}
                                    itemCount={tab?.count}
                                    displayName={tab?.dislayName}
                                    setTabs={setTabs}
                                />
                            );
                        })}
                    </Box>
                    {!hideExport && (
                        <Box className={classes.actionBtn}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleExport}
                                className={classes.exportBtn}
                                fullWidth
                            >
                                Export
                            </Button>
                        </Box>
                    )}
                </div>
                <ClientRequirements
                    getCounts={getCount}
                    smeEmail={smeEmail}
                    hideActions
                    activeTabValue={activeTabValue}
                    disableSummaryDrawer={false}
                    showPipelines={false}
                    isVisibleInModal={true}
                    // setLoadedRequirements={setLoadedRequirements}
                />
            </Box>
        </Box>
    );
};

const Tab = (props) => {
    const { tabName, isActive, itemCount, displayName, setTabs } = props;
    const { classes } = useStyles();

    const onTabClick = () => {
        setTabs((tabs) => {
            return tabs.map((tab) => {
                if (tab.tabName === tabName) {
                    return { ...tab, isActive: true };
                } else {
                    return { ...tab, isActive: false };
                }
            });
        });
    };

    return (
        <ButtonBase
            color="secondary"
            className={classes.tabContainer}
            style={{
                backgroundColor: isActive
                    ? 'rgba(235, 64, 52, 0.03)'
                    : 'transparent',
            }}
        >
            <Box
                onClick={onTabClick}
                className={classes.tab}
                style={{
                    color: isActive ? '#ea7112' : 'gray',
                    borderBottom: isActive
                        ? '3px solid #ea7112'
                        : '3px solid transparent',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.25rem',
                }}
            >
                <span>{displayName ? displayName : tabName}</span>
                {itemCount !== undefined && <span>({itemCount})</span>}
            </Box>
        </ButtonBase>
    );
};

export default InstructorProjectDialog;
