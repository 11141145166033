import { useState } from 'react';
import {
    Box,
    CustomSnackbar,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Typography,
    Button,
    FormControlLabel,
} from '../ui';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { astronaut_id } from '../plans/PlanDetails';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { makeStyles } from 'tss-react/mui';

export const SubscribeDialog = ({ onClose, open }) => {
    let navigate = useNavigate();
    const { user } = useCurrentUser();
    const { classes } = useStyles();
    const [check, setCheck] = useState(false);
    const [message, setMessage] = useState('');

    const closeDialog = () => {
        setCheck(false);
        onClose();
    };

    const onConfirm = async () => {
        try {
            const { data } = await axios.get(
                `/api/billing/${user._id}/end-trial/`
            );
            if (data.trialEnd) {
                // open a snackbar to refresh the page
                setMessage(
                    'Your plan has began, to see the latest changes please refresh!'
                );
            } else {
                console.log('send');
                navigate(
                    `/checkout?p_id=${astronaut_id}&t=1&coupon=VALENTINE2022`
                );
                // send to a page to create a new subscription with no trial
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <>
            <Dialog disableScrollLock open={open} onClose={closeDialog}>
                <DialogTitle
                    style={{
                        paddingBottom: '5px',
                    }}
                >
                    This content is only available to paid subscribers
                </DialogTitle>
                <DialogContent>
                    <Typography
                        style={{
                            color: '#909090',
                            fontWeight: '400',
                            fontSize: '1.2em',
                        }}
                    >
                        You are in a subscription trial period. To access this
                        content, you can waive your trial period right now.
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Box
                        display="flex"
                        flexDirection={'column'}
                        style={{ marginRight: 'auto' }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={check}
                                    onChange={() => setCheck(!check)}
                                />
                            }
                            label={
                                <Typography className={classes.conditions}>
                                    Yes! Convert my trial into a paid
                                    subscription{' '}
                                </Typography>
                            }
                        />
                        <Box ml={4} mt={-1}>
                            <a
                                href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/policies/`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Typography className={classes.terms}>
                                    (see Terms and Conditions)
                                </Typography>
                            </a>
                        </Box>
                    </Box>
                    <Box className={classes.actionsButtons} display={'flex'}>
                        <Box mr={1}>
                            <Button
                                onClick={closeDialog}
                                color="secondary"
                                variant="outlined"
                            >
                                CANCEL
                            </Button>
                        </Box>
                        <Box mr={1}>
                            <Button
                                onClick={onConfirm}
                                color="secondary"
                                variant="contained"
                                autoFocus
                                disabled={!check}
                            >
                                PROCEED
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>

            <CustomSnackbar
                message={message}
                setMessage={setMessage}
                type="success"
            />
        </>
    );
};

const useStyles = makeStyles()((theme) => ({
    dialogActions: {
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    actionsButtons: {
        [theme.breakpoints.down('md')]: {
            marginTop: 20,
        },
    },
    conditions: {
        fontSize: '1em',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.9em',
            '& > span': {
                marginTop: 8,
            },
        },
    },
    terms: {
        fontSize: '0.9em',
        [theme.breakpoints.down('md')]: {
            marginTop: 8,
        },
    },
}));
