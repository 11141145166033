import { astronaut_id, voyager_credit_id } from '../plans/PlanDetails';

export const offers = [
    {
        name: 'Cyber Monday',
        heading: 'TRY 1 MONTH FOR $1',
        subHeading: 'Unwrap 1000s of hours of education (Saving $140/year)',
        caption: '(Offer ends on Dec 10, 2021)',
        url: 'cyber-monday',
        endDate: '10/12/2021',
        dateFormat: 'DD/MM/YYYY',
        imageUrl:
            'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/popup-image%2Fpaul%20siegel%20(10).png?alt=media&token=a3dea791-d39d-4bd2-8ac6-aa62fd84f498',
        imageStyles: {},
        link: `/checkout?p_id=${astronaut_id}&t=0&coupon=CYBERMONDAY`,
        offerFor: 'Astronaut subscription',
        deprecated: true,
    },
    {
        name: 'Welcome 2022',
        heading: 'TRY 1 MONTH FOR $1',
        subHeading: 'Unwrap 1000s of hours of education (Saving $140/year)',
        caption: '(Offer ends on Jan 31, 2022)',
        url: 'welcome-2022',
        endDate: '31/01/2022',
        dateFormat: 'DD/MM/YYYY',
        imageUrl:
            'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/popup-image%2FChristmas%20popup%2Fpeople.png?alt=media&token=c7f071e3-27cb-4e27-8531-6cb285027e03',
        imageStyles: {},
        link: `/checkout?p_id=${astronaut_id}&t=1&coupon=WELCOME2022`,
        condition:
            '† Certifications and test prep programs are excluded during the $1 free trial.',
        offerFor: 'Astronaut subscription',
        deprecated: true,
        multiple: false,
    },
    {
        name: 'Valentine day 2022',
        heading: 'TRY 1 MONTH FOR $1',
        subHeading: 'Unwrap 1000s of hours of education (Saving $140/year)',
        caption: '(Offer ends on Feb 28, 2022)',
        url: 'valentine-2022',
        endDate: '28/02/2022',
        dateFormat: 'DD/MM/YYYY',
        imageUrl:
            'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/popup-image%2Frenew-val-offer.png?alt=media&token=5e626b67-305d-414d-8257-1b76c4a93ebf',
        imageStyles: {},
        link: `/checkout?p_id=${astronaut_id}&t=1&coupon=VALENTINE2022`,
        condition:
            '† Certifications and test prep programs are excluded during the $1 free trial.',
        offerFor: 'Astronaut subscription',
        deprecated: true,
        multiple: false,
    },
    {
        name: 'Banking Certification 2022',
        heading: 'GET CERTIFIED!!',
        subHeading: (
            <>
                10 deep modules of ~3 hours each <br />
                Leading industry experts and instructors,
            </>
        ),
        caption: '(Offer ends on Mar 15, 2022)',
        url: 'banking-cert2022',
        endDate: '15/03/2022',
        dateFormat: 'DD/MM/YYYY',
        imageUrl:
            'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/popup-image%2Ffbfs-offer.png?alt=media&token=b1d2d092-b8c7-4739-9cf1-72176b2d9b2a',
        imageStyles: {
            width: '100%',
            height: 'auto',
            margin: '15px auto',
        },
        link: `/credits/checkout?plan_id=${voyager_credit_id}&credits=300&coupon=BANKINGCERT2022`,
        condition:
            '† No free trial is offered, although students may register as Astronauts for 5 days at no cost.',
        offerFor: 'Voyager+ subscription',
        deprecated: true,
        multiple: false,
    },
    {
        name: 'Campus $1,000,000+',
        heading: '$1,000,000+ Campus Give-away!​',
        subHeading: 'Sign-up to students at no cost.​',
        caption: '',
        url: 'campus',
        endDate: '31/12/2021',
        dateFormat: 'DD/MM/YYYY',
        imageUrl:
            'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/popup-image%2Ffor%20campus.png?alt=media&token=dbcaf8de-dbb3-446b-a567-9285588b90bf',
        imageStyles: {
            width: 400,
            height: 'auto',
            marginBottom: '20px',
            boxShadow:
                '0px 5px 5px rgba(0,0,0,0.4), 0px 20px 10px rgba(0,0,0,0.2)',
        },
        link:
            '/for-campus/students/?utm_source=sw-colleges&utm_medium=web&utm_campaign=dec2021&utm_id=campus',
        offerFor: 'Astronaut and Commander subscriptions',
        deprecated: true,
    },
    {
        name: 'Student 200 credits ($160)',
        heading: 'Get 200 credits ($160) free annually​​',
        subHeading:
            'Top education. World-class instructors. No restrictions. No credit card.​',
        caption: '',
        url: 'students',
        endDate: '31/12/2021',
        dateFormat: 'DD/MM/YYYY',
        link:
            '/for-campus/students/?utm_source=sw-students&utm_medium=web&utm_campaign=dec2021&utm_id=campus',
        imageUrl:
            'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/popup-image%2FStudents.png?alt=media&token=200e7a26-6362-4ebd-9604-f426c1ae03ae',
        imageStyles: {
            width: 400,
            height: 'auto',
            marginBottom: '20px',
            boxShadow:
                '0px 5px 5px rgba(0,0,0,0.4), 0px 20px 10px rgba(0,0,0,0.2)',
        },
        offerFor: 'Astronaut and Commander subscriptions',
        deprecated: true,
    },
];
