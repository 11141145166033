import { creditsPlansState } from './recoil';
import { useRecoilState } from 'recoil';
import { selectedCreditPlanDetails } from '../plans/PlanDetails';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export const useGetAllCreditsPlan = (currentPlanId) => {
    const [allPlans, setAllPlans] = useRecoilState(creditsPlansState);
    const [selectedPlan, setSelectedPlan] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const getAllPlans = async () => {
            try {
                setIsLoading(true);
                const { data } = await axios.get('/api/billing/credit-plan');
                setAllPlans(data.productWithPrices);
            } catch (error) {
                setAllPlans([]);
                setIsLoading(false);
                console.log(error);
            }
        };
        if (!allPlans) {
            getAllPlans();
        }
    }, []);

    const addDefaultDiscount = useCallback((prices = []) => {
        if (prices.length > 0) {
            const basePrice = prices[0].amount;
            return prices.map((price) => {
                const discount = Math.trunc(
                    ((basePrice * price.credits_to_add - price.amount) * 100) /
                        (basePrice * price.credits_to_add)
                );
                return { ...price, discountOnPrice: discount };
            });
        } else {
            return prices;
        }
    }, []);

    useEffect(() => {
        if (allPlans) {
            const userSubscribedPlanId =
                selectedCreditPlanDetails[currentPlanId].planRequiredId;
            const newPlansPrices = allPlans.filter(
                (plan) => plan.product.valid_for === userSubscribedPlanId
            )[0].prices;
            const pricesWithDiscounts = addDefaultDiscount(newPlansPrices);
            setSelectedPlan(pricesWithDiscounts);
            setIsLoading(false);
        }
    }, [currentPlanId, allPlans, addDefaultDiscount]);

    return { selectedPlan, isLoading };
};
