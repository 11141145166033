import { Grid, TextField } from '../';

export const DateRangePicker = ({
    startDate,
    setStartDate = () => {},
    endDate,
    setEndDate = () => {},
}) => {
    const handleStartDate = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDate = (e) => {
        setEndDate(e.target.value);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    value={startDate}
                    variant="outlined"
                    color="secondary"
                    label="Start date"
                    type="date"
                    onChange={handleStartDate}
                    defaultValue={new Date()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    value={endDate}
                    variant="outlined"
                    color="secondary"
                    label="End date"
                    onChange={handleEndDate}
                    type="date"
                    defaultValue={new Date()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
        </Grid>
    );
};
