export const getScrollableParent = (node) => {
    if (!node) return null;

    const isScrollable = (element) => {
        const style = getComputedStyle(element);
        return (
            style.overflow === 'auto' ||
            style.overflow === 'scroll' ||
            style.overflowY === 'auto' ||
            style.overflowY === 'scroll' ||
            style.overflowX === 'auto' ||
            style.overflowX === 'scroll'
        );
    };

    let parent = node.parentNode;

    while (parent) {
        if (parent === document.body || parent === document.documentElement) {
            return window;
        }
        if (isScrollable(parent)) {
            return parent;
        }
        parent = parent.parentNode;
    }

    return window;
};
