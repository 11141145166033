import { Box, ButtonBase, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
    fetchRequirementById,
    setPercentageColClickedStatus,
    setTaskColClickedStatus,
} from '../../slices/requirementSummarySlice';
import CommentIcon from '@mui/icons-material/Comment';
import { resetExpertModalState } from '../../slices/expertProjectModalSlice';
import { useCallback } from 'react';

function OMCommentBtn({
    commentCount,
    row,
    isRead,
    commentsUserMetaDataLoading,
    updateCommentLastSeen,
}) {
    const dispatch = useDispatch();

    const handleCommentButtonClick = useCallback(async () => {
        dispatch(fetchRequirementById(row._id));
        dispatch(setTaskColClickedStatus(false));
        dispatch(setPercentageColClickedStatus(false));
        dispatch(resetExpertModalState());
        await updateCommentLastSeen(`om-${row._id}`);
    }, [dispatch, row, updateCommentLastSeen]);

    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            onClick={handleCommentButtonClick}
        >
            <ButtonBase>
                <CommentIcon
                    fontSize="small"
                    color={
                        commentsUserMetaDataLoading
                            ? 'primary'
                            : isRead
                            ? 'primary'
                            : 'secondary'
                    }
                />
                <Typography
                    variant="button"
                    sx={{ pl: 0.5 }}
                    color={
                        commentsUserMetaDataLoading
                            ? 'primary'
                            : isRead
                            ? 'primary'
                            : 'secondary'
                    }
                >
                    {commentCount}
                </Typography>
            </ButtonBase>
        </Box>
    );
}

export default OMCommentBtn;
