import React, { useEffect } from 'react';
import axios from 'axios';
import firebase from 'firebase/app';
import { useQueryParams } from '../util/useQueryParams';
import { useSetRecoilState } from 'recoil';
import { setUser } from '../my-account/Recoil/Selector';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../ui';

const SSOPage = () => {
    const { token, successPath, errorPath } = useQueryParams();
    const setRecoilUser = useSetRecoilState(setUser);
    let navigate = useNavigate();

    useEffect(() => {
        if (!token || !successPath /* || !errorPath */) {
            console.error('Missing token, successPath');
            return;
        }

        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `/api/sso?token=${token}&successPath=${successPath}&errorPath=${errorPath}`
                );
                console.log(response);
                setRecoilUser(response.data.user);
                await firebase
                    .auth()
                    .signInWithCustomToken(response.data.token);
                if (response.data.successPath) {
                    navigate(response.data.successPath);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                if (error.response?.data.errorPath)
                    navigate(error.response?.data.errorPath);
            }
        };

        fetchData();
    }, [token, successPath, errorPath, setRecoilUser, navigate]);

    return <Loading />;
};

export default SSOPage;
