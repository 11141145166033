import ImageIcon from '../img/image_icon.png';
import AudioIcon from '../img/mp3_icon.png';
import VideoIcon from '../img/video_icon.png';
import TxtIcon from '../img/txt_icon.png';
import PdfIcon from '../img/pdf_icon.png';
import DocIcon from '../img/document_icon.png';

export function getFileTypeByExt(extension) {
    if (!extension) throw new Error('extension is required');
    const img = {
        type: 'IMAGE',
        icon: ImageIcon,
        formats: [
            'png',
            'jpg',
            'gif',
            'jpeg',
            'webp',
            'avif',
            'bmp',
            'ico',
            'svg',
            'tif',
            'tiff',
        ],
    };

    const pdf = {
        type: 'PDF',
        icon: PdfIcon,
        formats: ['pdf'],
    };

    const doc = {
        type: 'DOCUMENT',
        icon: DocIcon,
        formats: [
            'doc',
            'docx',
            'odp',
            'ods',
            'odt',
            'ppt',
            'pptx',
            'xls',
            'xlsx',
            'csv',
        ],
    };

    const audio = {
        type: 'AUDIO',
        icon: AudioIcon,
        formats: [
            'mid',
            'midi',
            'mp3',
            'cda',
            'oga',
            'opus',
            'wav',
            'weba',
            '3gp',
            '3g2',
        ],
    };

    const video = {
        type: 'VIDEO',
        icon: VideoIcon,
        formats: ['avi', 'mp4', 'mpeg', 'ogv', 'ts', 'webm'],
    };

    if (img.formats.includes(extension))
        return { type: img.type, icon: img.icon };
    else if (pdf.formats.includes(extension))
        return { type: pdf.type, icon: pdf.icon };
    else if (doc.formats.includes(extension))
        return { type: doc.type, icon: doc.icon };
    else if (audio.formats.includes(extension))
        return { type: audio.type, icon: audio.icon };
    else if (video.formats.includes(extension))
        return { type: video.type, icon: video.icon };
    else return { type: 'RANDOM', icon: TxtIcon };
}
