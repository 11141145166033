import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    showSummary: false,
    isLoading: false,
    isError: false,
    isTaskColClicked: false,
    taskData: {},
    requirementData: {},
    isPercentageColClicked: false,
};

// fetch requirement by id
export const fetchRequirementById = createAsyncThunk(
    'fetchReqById',
    async (requirementId) => {
        const response = await axios.get(
            '/api/client-requirements/' + requirementId
        );
        return response.data;
    }
);

export const requirementSummarySlice = createSlice({
    name: 'requirementSummary',
    initialState,
    reducers: {
        displayRequirementSummary: (state, action) => {
            state.requirementData = action.payload;
            state.showSummary = true;
        },
        hideRequirementSummary: (state) => {
            state.requirementData = {};
            state.taskData = {};
            state.isLoading = false;
            state.isError = false;
            state.isTaskColClicked = false;
            state.isPercentageColClicked = false;
            state.showSummary = false;
        },
        setTaskColClickedStatus: (state, action) => {
            if (action.payload === false) state.taskData = {};
            state.isTaskColClicked = action.payload;
        },
        setPercentageColClickedStatus: (state, action) => {
            if (action.payload === false) state.taskData = {};
            state.isPercentageColClicked = action.payload;
        },
        addTaskData: (state, action) => {
            state.taskData = action.payload;
        },
        setLoadingStatus: (state, action) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRequirementById.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(fetchRequirementById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.requirementData = {
                requirementId: action.payload?._id,
                title: action.payload?.title,
                smes: action.payload?.smes ?? [],
                SMEengagements: action.payload?.SMEengagements ?? [],
                administrators: action.payload?.administrators ?? [],
                videoEditorsReviewers:
                    action.payload?.videoEditorsReviewers ?? [],
                instructionalDesigners:
                    action.payload?.instructionalDesigners ?? [],
                logisticsOperations: action.payload?.logisticsOperations ?? [],
                financeAdministration:
                    action.payload?.financeAdministration ?? [],
                talentScout: action.payload?.talentScout ?? [],
                videoProducer: action.payload?.videoProducer ?? [],
                qualityCheck: action.payload?.qualityCheck ?? [],
                status: action.payload?.status,
                expectedEndDate: action.payload?.expectedEndDate,
                clientId: action.payload?.clientId?._id,
                shortListedSmes: action.payload?.shortListedSmes ?? [],
                collaborators: action.payload?.collaborators ?? [],
                addedCollaborators: action.payload?.addedCollaborators ?? [],
                taskCategories: action.payload?.taskCategories ?? [],
                _id: action.payload?._id,
                id: action.payload?._id,
                templateId: action.payload?.templateId,
                templateName: action.payload?.templateName,
                contractNumber: action.payload?.contractNumber,
            };
            state.showSummary = true;
        });
        builder.addCase(fetchRequirementById.rejected, (state) => {
            state.isLoading = false;
            state.isError = false;
        });
    },
});

export const {
    displayRequirementSummary,
    hideRequirementSummary,
    setTaskColClickedStatus,
    addTaskData,
    setPercentageColClickedStatus,
    setLoadingStatus,
} = requirementSummarySlice.actions;

export default requirementSummarySlice.reducer;
