export const compareBy = (key) => (a, b) => {
    const A = a[key];
    const B = b[key];

    if (A > B) {
        return 1;
    }
    if (A < B) {
        return -1;
    }
    return 0;
};
