import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import File from './File';

export const FileViewContainer = ({
    pwd,
    fetchingFiles,
    fileData,
    containerName,
    setFileData,
    setDownloadProgress,
    isInstructor,
    forceHideDelBtn = false,
    showDetails = false,
    viewDetailsClick = undefined,
}) => {
    //  ----------------------- hooks: start ----------------------
    //  ----------------------- hooks: end ----------------------

    if (fetchingFiles) {
        return (
            <>
                <Box mb={2}>
                    <Skeleton
                        variant="rectangular"
                        animation={'wave'}
                        height={60}
                    />
                </Box>

                <Box mb={2}>
                    <Skeleton
                        variant="rectangular"
                        animation={'wave'}
                        height={60}
                    />
                </Box>

                <Box mb={2}>
                    <Skeleton
                        variant="rectangular"
                        animation={'wave'}
                        height={60}
                    />
                </Box>

                <Box mb={2}>
                    <Skeleton
                        variant="rectangular"
                        animation={'wave'}
                        height={60}
                    />
                </Box>
            </>
        );
    }

    return (
        <Box>
            {fileData.fileNames.map((file, idx) => {
                return (
                    <File
                        key={file.formattedName + idx}
                        containerName={containerName}
                        file={file}
                        showDetails={showDetails}
                        viewDetailsClick={viewDetailsClick}
                        forceHideDelBtn={forceHideDelBtn}
                        pwd={pwd}
                        isInstructor={isInstructor}
                        setDownloadProgress={setDownloadProgress}
                        setFileData={setFileData}
                    />
                );
            })}
        </Box>
    );
};
