import { Icon } from '../ui';
import rocket from './rocket.svg';

export const RocketIcon = ({ height = '24px', width = '24px' }) => {
    return (
        <Icon
            style={{
                height: height,
                width: width,
            }}
        >
            <img src={rocket} width={width} height={height} alt="rocket-icon" />
        </Icon>
    );
};
