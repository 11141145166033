import { useEffect, useState } from 'react';
import axios from 'axios';

export const useEnrolledPrivateOnDemand = (user) => {
    const [loading, setLoading] = useState(false);
    const [privateOnDemand, setPrivateOnDemand] = useState([]);

    useEffect(() => {
        console.log(user?.user_private_course_ids);
        const fetchPrivateCourses = async () => {
            if (
                !user ||
                !user.user_private_course_ids ||
                user.user_private_course_ids.length === 0
            ) {
                setPrivateOnDemand([]);
                return;
            }

            setLoading(true);

            try {
                // Use the same approach as useEnrolledOnDemand
                const thinkificCourseIds = user.user_private_course_ids.join(
                    ','
                );

                // Fetch enrolled courses from the courses API
                const courseResponse = await axios.get(`/api/courses`, {
                    params: {
                        thinkificCourseIds,
                        includeFeedback: '1',
                        visibility: 'PRIVATE',
                    },
                });

                let enrolledCourses = courseResponse.data.courses.map(
                    (course) => ({
                        ...course,
                    })
                );

                console.log(enrolledCourses);

                // Fetch course progress data from the enrollments API
                const thinkificUserId = user.user_thinkific_id || 0;

                if (enrolledCourses.length > 0) {
                    const progressResponse = await axios.get(
                        `/api/enrollments/thinkific-user`,
                        {
                            params: {
                                thinkificUserId,
                                thinkificCourseIds: enrolledCourses
                                    .map((course) => course.thinkificCourseId)
                                    .join(','),
                            },
                        }
                    );

                    const progressData = new Map(
                        progressResponse.data.map((item) => [
                            parseInt(item.course_id),
                            item,
                        ])
                    );

                    // Merge the progress data into the enrolledCourses array
                    enrolledCourses = enrolledCourses.map((course) => ({
                        ...course,
                        ...progressData.get(course.thinkificCourseId),
                    }));
                }

                setPrivateOnDemand(enrolledCourses);
            } catch (error) {
                console.error(
                    'Error fetching private courses or course progress:',
                    error
                );
                setPrivateOnDemand([]);
            } finally {
                setLoading(false);
            }
        };

        fetchPrivateCourses();
    }, [user]);

    return { privateOnDemand, loading };
};
