import { Grid, Typography } from '../ui';
import { makeStyles } from 'tss-react/mui';
import Countdown, { zeroPad } from 'react-countdown';
import { Link, Navigate } from 'react-router-dom';
import logo from '../starweaver-logo-v2.png';

const useStyles = makeStyles()((theme) => ({
    root: {
        backgroundColor: 'currentColor',
        backgroundImage: `url("https://www.mantralabsglobal.com/wp-content/themes/spiral-child/images/film_grain.png"), url("https://www.mantralabsglobal.com/wp-content/themes/spiral-child/images/error_404/white_grain.png")`,
    },
    pageDetails: {
        color: 'white',
        fontSize: '21px',
        lineHeight: '1',
        letterSpacing: '4px',
        marginBottom: '40px',
    },
    '@keyframes wdszoom0': {
        '100%': {
            transform: 'scale(0.9)',
        },
    },
    item: {
        animation: `$wdszoom0 5s linear 0s infinite alternate`,
        '-webkit-animation-delay': '2s',
        'animation-delay': '2s',
    },
}));

export const NotFound = () => {
    const { classes } = useStyles();
    const Completionist = () => <Navigate to="/" replace />;

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <Typography
                    component="p"
                    className={classes.pageDetails}
                    style={{ textAlign: 'right' }}
                >
                    {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                </Typography>
            );
        }
    };

    return (
        <div className={classes.root}>
            <img
                src={logo}
                alt="Starweaver"
                width="20%"
                style={{ marginLeft: '20px' }}
            />
            <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
                disableGutters={true}
                className={classes.pagePaper}
            >
                <Grid item xs={3}>
                    <Typography component="p" className={classes.pageDetails}>
                        Looks like you're lost in space!
                    </Typography>
                    <Typography component="p" className={classes.pageDetails}>
                        Let's get you back home in...
                    </Typography>
                    <Countdown
                        className={classes.pageDetails}
                        date={Date.now() + 5000}
                        renderer={renderer}
                    />
                    <Link to="/">
                        <Typography
                            component="p"
                            className={classes.pageDetails}
                            style={{ textDecoration: 'underline' }}
                        >
                            Home
                        </Typography>
                    </Link>
                </Grid>

                <Grid item xs={4}>
                    <img
                        src={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-content/uploads/2021/08/astro-munching-transparent.png`}
                        alt="404"
                        width="100%"
                        className={classes.item}
                    />
                </Grid>
            </Grid>
        </div>
    );
};
