import { Icon } from '../ui';
import arrow from './arrow.svg';

export const ArrowIcon = ({ height = '24px', width = '24px' }) => {
    return (
        <Icon
            style={{
                height: height,
                width: width,
            }}
        >
            <img src={arrow} width={width} height={height} alt="arrow-icon" />
        </Icon>
    );
};
