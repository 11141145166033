import { CheckCircle } from '@mui/icons-material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditIcon from '@mui/icons-material/Edit';
import {
    IconButton,
    InputAdornment,
    ListItem,
    ListItemText,
    TextField,
    Tooltip,
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { alertState, severity } from '../../../app/recoil';
import { CloseIcon } from '../../../icons';
import { ConfirmDialog } from '../../../ui';

const useStyles = makeStyles()((theme) => ({
    listItem: {
        transition: 'background-color 150ms ease-in-out',
        padding: theme.spacing(0.5, 2),
        '&:hover': {
            backgroundColor: '#e5e7eb',
        },
    },
    actions: {
        marginLeft: 'auto',
        flexShrink: 0,
    },
}));

function CategoryListItem({
    category,
    setIsLoading,
    fetchOptions,
    guidanceOptId,
}) {
    const { classes } = useStyles();
    const setAlert = useSetRecoilState(alertState);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [newCategory, setNewCategory] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    const showDeleteSuccessAlert = () => {
        setAlert({
            show: true,
            severity: severity.SUCCESS,
            message: 'Category deleted successfully',
        });
    };

    const createDeleteURL = () => {
        const baseURL = '/api/guidance/delete-category';
        const params = new URLSearchParams();
        params.append('_id', guidanceOptId);
        params.append('categoryName', category);
        return `${baseURL}?${params.toString()}`;
    };

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            const url = createDeleteURL();
            await axios.delete(url);
            showDeleteSuccessAlert();
            await fetchOptions();
        } catch (e) {
            if (e.response.status === 422) {
                setAlert({
                    show: true,
                    severity: severity.ERROR,
                    message: e.response.data.message,
                });
            } else {
                setAlert({
                    show: true,
                    severity: severity.ERROR,
                    message: "Something wen't worng",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseConfirm = () => {
        setShowConfirm(false);
    };

    const handleOnConfirmInterest = () => {
        handleDelete();
    };

    const handleShowEdit = () => {
        setNewCategory(category);
        setShowEdit(true);
    };

    const handleHideEdit = () => {
        setNewCategory('');
        setShowEdit(false);
    };

    const handleChangeCategory = (e) => {
        setNewCategory(e.target.value);
    };

    const handleSave = async () => {
        try {
            setIsSaving(true);
            const url = '/api/guidance/change-category-option';
            const payload = {
                newCategoryName: newCategory,
                guidanceOptId,
                oldCategoryName: category,
            };
            await axios.put(url, payload);
            await fetchOptions();
            setAlert({
                show: true,
                message: 'Successfully updated category name',
                severity: severity.SUCCESS,
            });
        } catch (e) {
            if (e?.response?.status === 422 || e?.response?.status === 400) {
                setAlert({
                    show: true,
                    message: e.response.data.message,
                    severity: severity.ERROR,
                });
            }
            console.log(e);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <>
            <ConfirmDialog
                onClose={handleCloseConfirm}
                open={showConfirm}
                onConfirm={handleOnConfirmInterest}
                title="Are you sure you want to delete?"
            />
            <ListItem
                disableGutters
                disablePadding
                className={classes.listItem}
            >
                {!showEdit ? (
                    <ListItemText>{category}</ListItemText>
                ) : (
                    <TextField
                        size="small"
                        variant="outlined"
                        color="secondary"
                        value={newCategory}
                        autoFocus={true}
                        focused={true}
                        onChange={handleChangeCategory}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        disabled={
                                            isSaving ||
                                            category.length ===
                                                newCategory.trim().length
                                        }
                                        onClick={handleSave}
                                    >
                                        <CheckCircle
                                            fontSize="small"
                                            color={
                                                category.length ===
                                                newCategory.trim().length
                                                    ? 'default'
                                                    : 'success'
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                    />
                )}
                <span className={classes.actions}>
                    {!showEdit ? (
                        <IconButton size="small">
                            <EditIcon
                                fontSize="small"
                                onClick={handleShowEdit}
                            />
                        </IconButton>
                    ) : (
                        <IconButton size="small">
                            <CloseIcon
                                fontSize="small"
                                onClick={handleHideEdit}
                            />
                        </IconButton>
                    )}

                    <IconButton size="small">
                        <Tooltip
                            placement="top"
                            title="Delete"
                            onClick={() => setShowConfirm(true)}
                        >
                            <DeleteRoundedIcon fontSize="small" />
                        </Tooltip>
                    </IconButton>
                </span>
            </ListItem>
        </>
    );
}

export default CategoryListItem;
