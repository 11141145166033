import { useCallback } from 'react';
import { Box, Chip, Typography } from '@mui/material';

const OPERATOR_SYMBOLS = {
    contains: 'HAS',
    equals: '=',
    endsWith: 'ENDS WITH',
    startsWith: 'STARTS WITH',
    is: 'IS',
    isNot: 'IS NOT',
    isAfter: 'IS AFTER',
    isOnAfter: 'IS ON AFTER',
    isBefore: 'IS BEFORE',
    isOnBefore: 'IS ON BEFORE',
};

export const DisplayAllFilters = ({
    filters = [],
    removeFilter = () => console.log('remove-filters-fun'),
}) => {
    const label = useCallback((label) => {
        if (label === 'On trial') {
            return 'Users';
        } else {
            return label;
        }
    }, []);

    const operator = useCallback((label, operator) => {
        if (label === 'On trial' && operator === 'IS NOT') {
            return 'is not on';
        } else if (label === 'On trial' && operator === 'IS') {
            return 'is on';
        } else {
            return operator?.toLowerCase();
        }
    }, []);

    const filterValue = useCallback((label, value) => {
        console.log('value: ', value, 'label: ', label);
        if (label === 'On trial' && value === 'trialing') {
            return 'trial';
        } else {
            return value;
        }
    }, []);

    return filters.length ? (
        <Box my={1}>
            <Box display={'flex'}>
                {filters.map((filter, index) => (
                    <Box mr={1} key={index}>
                        <Chip
                            variant="outlined"
                            color="secondary"
                            label={
                                <Typography variant="caption">
                                    {/* <b>{filter.label}</b>{' '} */}
                                    <b>{label(filter.label)}</b>{' '}
                                    {/* {OPERATOR_SYMBOLS[`${filter.operator}`]}{' '} */}
                                    {operator(
                                        filter.label,
                                        OPERATOR_SYMBOLS[`${filter.operator}`]
                                    )}{' '}
                                    {/* <b>{filter.value}</b> */}
                                    <b>
                                        {filterValue(
                                            filter.label,
                                            filter.value
                                        )}
                                    </b>
                                </Typography>
                            }
                            onDelete={() => removeFilter(filter.name)}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    ) : (
        ''
    );
};
