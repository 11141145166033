import { Box, Grid } from '../ui';
import { TestimonyItem } from './TestimonyItem';
import { useShowOnViewPortIntersection } from '../util';

export const Testimonies = () => {
    const [
        showTestimonies,
        testimoniesContainerRef,
    ] = useShowOnViewPortIntersection();

    return (
        <Box mt={6}>
            <Grid container ref={testimoniesContainerRef}>
                {showTestimonies &&
                    testimonies.map(({ img, testimony, designation, name }) => (
                        <Grid item xs={12} sm={6} md={4}>
                            <TestimonyItem
                                name={name}
                                testimony={testimony}
                                designation={designation}
                                img={img}
                            />
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};

const testimonies = [
    {
        img:
            'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Testimonial%20images%2Flayne.jpg?alt=media&token=aa5cb73e-0f20-4247-aa23-f729705ba482',
        testimony:
            'There is a big distinction between Starweaver and the other online platforms.  Live instructors and mentors streaming all the time aligned to our team’s training needs.',
        designation: 'Learning & Development, Global Technology Company',
        name: 'Layne',
    },
    {
        img:
            'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Testimonial%20images%2Fjim.jpg?alt=media&token=782daeea-089c-4e1c-8dad-20643c8910d9',
        testimony:
            'With the Starweaver profiler our teams create individualized leaning plans that dynamically adjust as they build skills, knowledge and confidence.',
        designation: 'Data Science Team, European Financial Institution ',
        name: 'Jim',
    },
    {
        img:
            'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Testimonial%20images%2Fsam.jpg?alt=media&token=44766830-51fa-48d3-a754-936d1b9df88e',
        testimony:
            'I’ve learned an enormous amount in a short time simply by participating in live sessions. I can always refer back to the recordings. So, Starweaver provides the best of both worlds: live and on demand.',
        designation: 'Recent Graduate, Canada',
        name: 'Sam',
    },
];
