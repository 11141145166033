import { makeStyles } from 'tss-react/mui';
import Blob from '../img/Blob.svg';
import { blue } from '@mui/material/colors';

export const useStyles = makeStyles()((theme) => ({
    // USED IN --> SignInInfo,Payments, Personal Info and Notifications
    container: {
        width: 'min(1000px, 95vw)',
        margin: '0 auto 50px',
    },
    // USED IN --> SignInInfo,
    edit: {
        minWidth: '50px',
        borderRadius: '50%',
        height: '50px',
    },
    // USER ID --> Billing Details
    userId: {
        display: 'inline-block',
        marginLeft: '20px',
        padding: '5px 30px',
        borderRadius: '2px',
        border: '1px solid rgba(0,0,0,0.1)',
        width: 'fit-content',
    },
    // credits balance
    balanceIcon: {
        backgroundColor: blue[700],
        padding: '5px 10px',
        borderRadius: '4px 0 0 4px',
    },
    balanceAmount: {
        backgroundColor: '#c5c5c5',
        padding: '8px 10px',
        alignSelf: 'stretch',
        borderRadius: '0px 4px 4px 0',
    },
    // USED IN --> SignInInfo,Payments, Personal Info, and Notifications
    iconBtn: {
        margin: '-3px 10px 0',
        padding: '3px 0 0',
        color: theme.palette.common.blue,
    },

    // USED IN --> SignInInfo,
    avatarIcon: {
        width: 'fit-content',
        margin: '0 auto',
    },

    // USED IN --> SignInInfo,
    clickable: {
        cursor: 'pointer',
        transition: '0.2s all ease-in-out',
        transformOrigin: 'left',
        '&:hover': {
            color: '#222',
            transform: 'scale(1.1)',
        },
    },

    // USED IN --> PersonalInfo, SignInInfo
    avatar: {
        margin: '0 auto',
        width: 200,
        height: 200,
        boxShadow: '0px 12px 15px 3px rgba(0,0,0,0.5)',
    },

    avatarContainerContainer: {
        marginTop: 150,
    },

    avatarContainer: {
        position: 'relative',

        zIndex: 1,
        /* '&:before': {
            position: 'absolute',
            content: '""',
            top: '-39%',
            left: '-40%',
            width: '175%',
            height: '175%',
            transform: 'rotate(315deg)',
            backgroundImage: `url(${Blob})`,
            backgroundSize: 'cover',
            zIndex: '-1',
        }, */
    },

    avatarLoading: {
        margin: '0 auto',
        width: 200,
        height: 200,
        // backgroundColor: theme.palette.common.blue,
        borderRadius: '50%',
        display: 'grid',
        placeItems: 'center',
    },

    // USED IN --> PersonalInfo, SignInInfo
    avatarLetter: {
        fontSize: 'min(4.5em,7vw)',
        backgroundColor: blue[500],
    },

    // USED IN --> SignInInfo,Payments, Personal Info and Notifications
    heading: {
        fontSize: '2.5em',
        fontWeight: '700',
        textAlign: 'center',
    },

    // USED IN --> SignInInfo,Payments, Personal Info and Notifications
    subHeading: {
        fontSize: '1.5em',
        textAlign: 'center',
        margin: '20px 0',
    },

    // USED IN --> SignInInfo,Payments, Personal Info
    item: {
        display: 'flex',
        flexFlow: 'row',
        /* height: '50px', */
        [theme.breakpoints.down('sm')]: {
            flexFlow: 'column',
            height: 'auto',
        },
        borderRadius: '3px',
        margin: '10px 0',
        transition: '0.2s all ease-in-out',
        alignItems: 'center',
        padding: '10px',
    },

    link: {
        margin: '10px 0',
        fontSize: '0.9em',
        textTransform: 'uppercase',
        fontWeight: 500,
        letterSpacing: '0.8px',
        cursor: 'pointer',
        '&:hover': {
            color: '#4267B2',
            textDecoration: 'underline',
        },
    },

    // USED IN -->SignInInfo,Payments, Personal Info
    itemLable: {
        fontSize: 'min(1.3em, 6.5vw)',
        fontWeight: '700',
        flexBasis: '30%',
        [theme.breakpoints.down('sm')]: {
            flexBasis: 'auto',
            alignSelf: 'flex-start',
        },
    },

    // USED IN --> SignInInfo,Payments, Personal Info
    itemValue: {
        flexBasis: '60%',
        fontSize: 'min(1.3em, 6.5vw)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: '10px',
            flexBasis: 'auto',
            alignSelf: 'flex-start',
        },
    },
    phoneInp: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    section: {
        margin: '50px 0',
    },
    sectionHeading: {
        fontSize: 'min(1.4em, 6.5vw)',
        fontWeight: '700',
        color: theme.palette.common.blue,
        textAlign: 'center',
    },
    // USED IN --> PersonalInfo, SignInInfo
    disabled: {
        display: 'flex',
        flexFlow: 'row',
        height: '50px',
        [theme.breakpoints.down('sm')]: {
            flexFlow: 'column',
            height: 'auto',
        },
        borderRadius: '3px',
        margin: '5px 0',
        transition: '0.2s all ease-in-out',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.2)',
        padding: '10px',
    },

    // RADIO GROUP
    radio: {
        display: 'flex',
        flexFlow: 'row',
    },

    // USED IN --> SignInInfo,Payments, Personal Info and Notifications
    details: {
        margin: '25px auto',
        padding: '10px',
        width: '95%',
        border: 'solid 0.5px rgba(0,0,0,0.2)',
        borderRadius: '2px',
        // boxShadow:'0px 7px 5px rgba(0,0,0,0.2)',
    },

    // USED IN --> Notifications,
    headingContainer: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
    },

    my: {
        margin: '20px 0',
    },
    note: {
        fontSize: '0.8em',
        color: '#b6b6b6',
    },
    // DATA PRIVACY BTN
    middleBtn: {
        margin: '20px auto',
        width: 'fit-content',
    },
    customBtn: {
        backgroundColor: '#455EBD',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#3A54BA',
        },
    },

    // LOGOUT BTN
    logOutBtn: {
        float: 'right',
        marginRight: '20px',
        zIndex: '100',
    },
    // FLOATING ACTION BUTTON
    fab: {
        margin: '10px 0',
    },
    // ALERT MESSAGE
    alert: {
        position: 'fixed',
        top: '100px',
        left: '50%',
        transform: 'translate(-50%)',
        width: 'min(80vw, 600px)',
        opacity: '0.9',
    },

    // CARD DETAILS
    // card: {
    //     display: 'flex',
    //     flexFlow: 'column',
    //     maxWidth: '350px',
    //     minHeight: '550px',
    //     padding: '10px 20px',
    //     margin: '20px auto',
    //     borderRadius: 5,
    //     backgroundColor: '#fafafa',
    //     transition: '0.2s all ease-out',
    //     border: '1px solid rgba(0,0,0,0)',
    //     position: 'relative',
    //     '&:hover': {
    //         transform: 'scale(1.05)',
    //         border: '1px solid rgba(0,0,0,0.1)',
    //         '& $cardImg': {
    //             boxShadow: '0px 8px 5px 1px rgba(0,0,0,0.4)',
    //             transform: 'rotate(-2deg)',
    //         },
    //         '& $cardBackdrop': {
    //             '&::before': {
    //                 backgroundColor: 'rgba(0,0,0,0.4)',
    //             },
    //         },
    //     },
    // },
    card: {
        display: 'flex',
        flexFlow: 'column',
        maxWidth: '350px',
        minHeight: '500px',
        padding: '10px 20px',
        margin: '0 auto',
        cursor: 'pointer',
        borderRadius: 5,
        backgroundColor: '#fafafa',
        transition: '0.2s all ease-out',
        border: '1px solid rgba(0,0,0,0)',
        position: 'relative',
        '&:hover': {
            transform: 'scale(1.05)',
            border: '1px solid rgba(0,0,0,0.1)',
            '& $cardImg': {
                boxShadow: '0px 8px 5px 1px rgba(0,0,0,0.4)',
                transform: 'rotate(-2deg)',
            },
            '& $cardBackdrop': {
                '&::before': {
                    backgroundColor: 'rgba(0,0,0,0.4)',
                },
            },
        },
    },
    cardBackdrop: {
        position: 'relative',
        borderRadius: 5,
        '&:before': {
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            transition: 'all 0.2s ease-in',
            backgroundColor: 'rgba(0,0,0,0)',
        },
    },
    cardImg: {
        height: '170px',
        width: '100%',
        borderRadius: 5,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: '0.2s all ease-out',
        boxShadow: '0px 0px 10px 1px rgba(0,0,0,0)',
        overflow: 'hidden',
    },
    changeside: {
        color: '#333',
    },
    // TIME IN ONAIR
    time: {
        fontSize: '1em',
        fontWeight: 500,
        color: '#858585',
        opacity: '1',
    },
    date: {
        marginRight: 10,
        fontSize: '1em',
    },
    infoContainer: {
        display: 'flex',
        flexFlow: 'row',
        // justifyContent: 'center',
        alignItems: 'center',
    },
    cardHeading: {
        margin: '20px 0 0px',
        fontSize: 'min(1.4em, 5vw)',
        fontWeight: 'bold',
        paddingLeft: 16,
    },
    instructor: {
        width: 'fit-content',
        margin: '10px 0',
    },
    sub: {
        margin: '10px 0',
    },
    load: {
        height: '250px',
        display: 'grid',
        marginTop: '6rem',
        placeItems: 'left',
    },
    cardActions: {
        display: 'flex',
        flexFlow: 'row',
        marginTop: '20px',
        display: 'block',
        textAlign: 'center',
        paddingBottom: 20,
    },
    cardBtn: {
        marginRight: '20px',
    },
    // cardContent: {
    //     display: 'flex',
    //     flexFlow: 'row',
    //     width: '100%',
    //     margin: '25px 0',
    //     minHeight: '200px',
    //     [theme.breakpoints.down('xs')]: {
    //         flexFlow: 'column',
    //     },
    //     // CHANGE TO COLUMN MEDIA QUERY
    // },
    // cardImg: {
    //     width: '300px',
    //     backgroundColor: '#333',
    //     margin: '0px 10px 10px 0',
    //     backgroundPosition: 'center',
    //     backgroundSize: 'cover',
    //     flexBasis: '30%',
    //     [theme.breakpoints.down('xs')]: {
    //         width: '90%',
    //         height: '250px',
    //         flexBasis: 'auto',
    //         margin: '20px',
    //     },
    // },
    // cardDetails: {
    //     display: 'flex',
    //     flexBasis: '70%',
    //     flexFlow: 'column',
    //     justifyContent: 'center',
    //     minHeight: '200px',
    // },
    // cardSubDetails: {
    //     display: 'flex',
    //     flexFlow: 'row',
    //     justifyContents: 'center',
    //     alignItems: 'center',
    // },
    // cardItem: {
    //     marginRight: '10px',
    //     color: '#808080',
    // },
    // cardTitle: {
    //     fontSize: 'min(2em,6vw)',
    //     fontWeight: 700,
    // },
    // actions: {
    //     marginTop: '20px',
    //     alignSelf: 'flex-end',
    // },
    // cardBtn: {
    //     marginRight: '10px',
    // },

    // BILLING GRID
    valueContainer: {
        display: 'flex',
        flexFlow: 'row',
        alignItem: 'center',
    },
    column: {
        flexBasis: '25%',
        textAlign: 'center',
    },
    upgrade: {
        marginLeft: '10px',
        borderRadius: '20px',
    },
    subscriptionHeadingsContain: {
        background:
            'linear-gradient(190deg, rgba(15,50,126,1) 0%, rgba(35,93,236,1) 100%)',
        padding: '10px 0',
        boxShadow: '0px 0px 10px 2px rgba(0,0,0,0.4)',
        borderRadius: '4px',
    },
    subscriptionHeading: {
        fontSize: 'min(1.4em,3.5vw)',
        color: 'white',
        fontWeight: 800,
    },
    subscriptionValues: {
        fontSize: '1.2em',
        fontWeight: 600,
    },
    loading: {
        display: 'grid',
        placeItems: 'center',
        height: '100vh',
    },
    unSub: {
        margin: '10px 0',
        fontSize: '0.9em',
    },
    dialogTitleContain: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
    },
    dialogContent: {
        padding: '20px',
    },
    progress: {
        margin: ' 20px 0',
        display: 'grid',
        placeItems: 'center',
    },

    // CREDIT TABLE
    deducted: {
        background: 'rgba(238,68,68,0.1)',
    },
    added: {
        background: 'rgba(94,238,68,0.1)',
    },
    blalanceConatinerTop: {
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    pointsContainer: {
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
    },
    points: {
        marginLeft: '20px',
        padding: '10px',
        width: 'fit-content',
        borderRadius: '50%',
        display: 'grid',
        fontWeight: 600,
        cursor: 'pointer',
        placeItems: 'center',
        transition: '0.2s all ease-in',
        border: `1px solid ${theme.palette.secondary.main}`,
        boxShadow: ` 0px 0px 0px 3px ${theme.palette.background.default}, 0px 0px 0px 5px ${theme.palette.secondary.main}`,
        '&:hover': {
            boxShadow: ` 0px 0px 0px 2px ${theme.palette.background.default}, 0px 0px 0px 5px ${theme.palette.secondary.main}`,
            transform: 'scale(1.05)',
        },
    },
    // SignInInputs

    otpVerificationContainer: {
        width: 500,
    },
    otpEnableBtn: {
        backgroundColor: 'rgba(0,0,0,0.75)',
        color: 'rgba(255,255,255,1)',
    },

    // Explainer videos styles

    videoBox: {
        height: 260,
        width: 500,
    },
    mainBoxWrap: {
        position: 'relative',
        minHeight: 500,
        [theme.breakpoints.down('lg')]: {
            height: 'auto',
            minHeight: 'auto',
        },
    },
    mainBox: {
        width: '90%',
        borderRadius: '8px',
        marginLeft: '20px',
        marginRight: '10px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 13px 2px #AAA8A8',
        marginTop: '10px',
        position: 'relative',
        '&:hover': {
            //position: 'absolute',
            zIndex: 1000,
            transition: 'all 0.3s ease-out',
            transform: 'scale(1.05)',
            '& $desc': {
                visibility: 'visible',
                display: 'block',
                backgroundColor: '#ffffff',
            },
        },
    },
    desc: {
        display: 'none',
        marginTop: 0,
        fontSize: '16px',
    },
    root: {
        backgroundColor: '#ddd',
        // backgroundPosition: 'center',
        backgroundSize: '100% 100%',
        // backgroundRepeat: 'no-repeat',
        borderRadius: '6px 6px 0 0px',
        minHeight: '175px',
        width: '100%',
    },
    contentBox: {
        width: '100%',
        margin: '0 auto',
        transition: 'all 0.3s ease-in',
    },

    controlBtn: {
        position: 'absolute',
        top: '50%',
        color: '#333',
    },
    right: {
        right: '-30px',
    },
    left: {
        left: '-20px',
    },
    sub: {
        margin: '10px 0',
    },
    load: {
        height: '450px',
        display: 'grid',
        placeItems: 'center',
    },
    img: {
        position: 'relative',
        height: '300px',
        width: '350px',
        boxShadow: '0px 5px 10px 1px rgba(0,0,0,0.4)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginBottom: '10px',
    },
    // TOP PICKS COURSE COMPONENT
    link: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        float: 'right',
        cursor: 'pointer',
        color: '#333',
        margin: '-20px 0 0 0',
    },
    topPicksCourse: {
        position: 'relative',
        // overflow: 'hidden',
        '&:nth-child(even)': {
            marginTop: 25,
        },
    },
    picksBg: {
        position: 'absolute',
        background:
            'linear-gradient(90deg, rgba(234,113,18,0.7175245098039216) 0%, rgba(234,113,18,0.896796218487395) 54%, rgba(234,113,18,0.4766281512605042) 74%)',
        height: '105%',
        width: '85%',
        zIndex: '-10',
        clipPath: 'polygon(15% 1%, 100% 0%, 85% 100%, 0% 100%)',
        [theme.breakpoints.down('md')]: {
            clipPath: 'none',
            width: '200%',
        },
    },
    picksBgCourses: {
        top: 0,
        left: '-20%',
    },
    picksBgOnAir: {
        background: '#e3e3e3',
        right: '-20%',
    },
    sectionHeading: {
        fontSize: 'min(2em,8vw)',
        color: '#fafafa',
        fontWeight: 400,
        cursor: 'default',
    },
    cardsConatiner: {
        position: 'relative',
        // margin: '40px 0',
        cursor: 'pointer',
    },
    card: {
        display: 'flex',
        flexFlow: 'column',
        maxWidth: '350px',
        minHeight: '500px',
        padding: '10px 20px',
        margin: '0 auto',
        cursor: 'pointer',
        borderRadius: 5,
        backgroundColor: '#fafafa',
        transition: '0.2s all ease-out',
        border: '1px solid rgba(0,0,0,0)',
        position: 'relative',
        '&:hover': {
            transform: 'scale(1.05)',
            border: '1px solid rgba(0,0,0,0.1)',
            '& $cardImg': {
                boxShadow: '0px 8px 5px 1px rgba(0,0,0,0.4)',
                transform: 'rotate(-2deg)',
            },
            '& $cardBackdrop': {
                '&::before': {
                    backgroundColor: 'rgba(0,0,0,0.4)',
                },
            },
        },
    },
    cardActions: {
        display: 'flex',
        flexFlow: 'row',
        marginTop: '20px',
        textAlign: 'center',
        display: 'block',
        paddingBottom: 20,
    },
    cardBtn: {
        marginRight: '20px',
        width: '100%',
        marginRight: 10,
        paddingBottom: 5,
    },
    cardImg: {
        width: '100%',
        borderRadius: 5,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: '0.2s all ease-out',

        boxShadow: '0px 0px 10px 1px rgba(0,0,0,0)',
        overflow: 'hidden',
    },
    browseLink: {
        width: 115,
        display: 'block',
        margin: 'auto',
    },
    // end trial styles
    dialogActions: {
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    actionsButtons: {
        [theme.breakpoints.down('md')]: {
            marginTop: 20,
        },
    },
    conditions: {
        fontSize: '1em',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.9em',
            '& > span': {
                marginTop: 8,
            },
        },
    },
    terms: {
        fontSize: '0.9em',
        [theme.breakpoints.down('md')]: {
            marginTop: 8,
        },
    },
    deleteBtn: {
        backgroundColor: theme.palette.error.main,
    },
}));
