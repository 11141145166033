import { makeStyles } from 'tss-react/mui';
import road from '../img/road.svg';
// import curve from '../img/curvedBg.svg';
import curve from '../img/topBg.svg';

const positionAbsoluteBoilerPlate = {
    position: 'absolute',
    content: '""',
    width: '100%',
    height: '100%',
};

export const useStyles = makeStyles()((theme) => ({
    bannerContainer: {
        minHeight: '700px',
        width: '100%',
        marginTop: '-20px',
        paddingTop: '0',
        [theme.breakpoints.only('md')]: {
            minHeight: '500px',
        },
        [theme.breakpoints.only('sm')]: {
            minHeight: '300px',
        },
        [theme.breakpoints.only('xs')]: {
            minHeight: '400px',
        },
    },
    colorBlue: {
        color: `${theme.palette.common.blue} !important`,
    },
    colorLightBlue: {
        color: `${theme.palette.common.lightBlue} !important`,
    },
    containerMargin: {
        marginBottom: '280px',
    },
    sideImage: {
        backgroundImage: `url(${curve})`,
        backgroundSize: 'cover',
        position: 'absolute',
        backgroundRepeat: 'no-repeat',
        // curve topBg
        filter: 'drop-shadow(2px 4px 6px rgba(0,0,0,0.4)) ',
        width: '100%',
        height: '100%',
        top: '0%',
        left: '0%',
        zIndex: '-1',
        backgroundPosition: 'center',
        // curve curveBg
        // filter: 'drop-shadow(2px 4px 6px rgba(0,0,0,0.4)) blur(3px)',
        // width: '120vw',
        // height: '135%',
        // top: '-48%',
        // left: '-14%',
    },
    sideImageXL: {
        width: '100%',
        height: '235%',
        left: '-0%',
        top: '-94%',
    },
    heading: {
        fontSize: 'min(3.5em, 3.5vw)',
        fontWeight: 700,
        [theme.breakpoints.only('xs')]: {
            fontSize: 'min(3.5em, 8vw)',
        },
    },
    subHeading: {
        fontSize: 'min(1.5em, 2vw)',
        marginTop: '20px',
        width: 'min(80%, 490px)',
        [theme.breakpoints.only('xs')]: {
            fontSize: 'min(1.5em, 4vw)',
        },
    },
    bannerImageContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
        [theme.breakpoints.only('xs')]: {
            display: 'none',
        },
        // backgroundColor: '#c5c5c5',ß
        // overflow: 'hidden',
    },
    bannerContent: {
        justifyContent: 'center',
        height: '100%',
    },
    road: {
        ...positionAbsoluteBoilerPlate,
        backgroundImage: `url(${road})`,
        backgroundRepeat: 'no-repeat',
        top: 0,
        right: 0,
        height: '100%',
        zIndex: '-2',
    },
    actionButton: {
        fontSize: 'min(1.3em, 2.5vw)',
        marginTop: '35px',

        width: 'fit-content',
        [theme.breakpoints.only('xs')]: {
            marginBottom: '20px',
        },
    },
    tooltip: {
        '&:hover': {
            '&$marker1': {
                transform: 'scale(1.2)',
                animation:
                    '$bounce 2s cubic-bezier(0.280, 0.840, 0.420, 1) infinite',
            },
        },
    },
    marker1: {
        ...positionAbsoluteBoilerPlate,
        height: '81px',
        width: '50px',
        backgroundRepeat: 'no-repeat',
        filter: 'drop-shadow(0px 15px 6px black)',
        zIndex: '0',
    },
    animateOnEntry: {
        animation: '$bounce 2s cubic-bezier(0.280, 0.840, 0.420, 1)',
        '&:nth-of-type(1)': {
            animationDelay: '7.5s',
        },
        '&:nth-of-type(2)': {
            animationDelay: '6s',
        },
        '&:nth-of-type(3)': {
            animationDelay: '4.5s',
        },
        '&:nth-of-type(4)': {
            animationDelay: '3s',
        },
        '&:nth-of-type(5)': {
            animationDelay: '1.5s',
        },
    },
    '@keyframes bounce': {
        '0%': {
            transform: 'scale(1,1) translateY(0)',
        },
        '10%': {
            transform: 'scale(1.1,0.9) translateY(0)',
        },
        '30%': {
            transform: 'scale(0.9,1.1) translateY(-15px)',
        },
        '50%': {
            transform: 'scale(1.05,0.95) translateY(0)',
        },
        '57%': {
            transform: 'scale(1,1) translateY(-2px)',
        },
        '64%': {
            transform: 'scale(1,1) translateY(0)',
        },
        '100%': {
            transform: 'scale(1,1) translateY(0)',
        },
    },
}));
