import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/app';
import {
    Alert,
    Box,
    Button,
    Dialog,
    Grid,
    Link,
    TextField,
    Typography,
} from '../ui';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { ForgotPasswordDialog } from './ForgotPasswordDialog';
import download from './assets/download.png';
import { makeStyles } from 'tss-react/mui';
//import { loginModalAtom } from './Recoil/state';
import axios from 'axios';
import { useUser } from './useUser';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { userState } from '../my-account/Recoil/State';

const useStyles = makeStyles()((theme) => ({
    modalContent: {
        backgroundColor: 'white',
        /* border: '2px solid #000',
        boxShadow: theme.shadows[5], */
        padding: theme.spacing(2, 4, 3),
    },
    headingText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    dialogZIndex: {
        zIndex: theme.zIndex.drawer + 1,
    },
    paper: {
        /* marginTop: theme.spacing(4), */
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        /*  maxWidth: '600px', */
        margin: 'auto',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    loginWithLinkedin: {
        margin: theme.spacing(1, 0, 2),
        fontSize: 'min(1em, 3vw)',
        backgroundColor: '#2867B2',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#2867B2',
        },
    },
    loginWithGoogle: {
        fontSize: 'min(1em, 3vw)',
        margin: theme.spacing(1, 0, 2),
        '&:hover': {
            backgroundColor: '#176BBE',
        },
    },
}));

const baseUrl =
    !process.env.REACT_APP_ENVIRONMENT ||
    process.env.REACT_APP_ENVIRONMENT === 'development'
        ? 'http://localhost:8090'
        : process.env.REACT_APP_NODE_DOMAIN;

export const LoginModal = ({
    open,
    destination,
    loginMessage,
    title,
    onRequestClose = () => {},
    onAuthSuccess = () => {},
    signUpLink = '/signup',
}) => {
    const { classes } = useStyles();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otherAuthData, setOtherAuthData] = useState(null);
    const [error, setError] = useState('');
    const [
        isForgotPasswordDialogOpen,
        setIsForgotPasswordDialogOpen,
    ] = useState(false);
    const currentUser = useRecoilValue(userState);
    const resetCurrentRecoilUser = useResetRecoilState(userState);
    const { user } = useUser();
    let navigate = useNavigate();

    useEffect(() => {
        if (user) {
            if (otherAuthData === null) {
                //navigate(('/'));
            } else {
                axios
                    .post('/api/users', {
                        user_email: otherAuthData.email,
                        user_firstName: otherAuthData.displayName.split(' ')[0],
                        user_lastName: otherAuthData.displayName.substr(
                            otherAuthData.displayName.indexOf(' ')
                        ),
                        display_name: otherAuthData.displayName,
                        user_phone: otherAuthData.phoneNumber,
                        is_verified: true,
                        isDisabled: false,
                    })
                    .then((res) => {
                        navigate('/');
                        console.log(res.message);
                    })
                    .catch((err) => console.log(err));
            }
        }
    }, [user, navigate]);

    const afterLogin = () => {
        if (destination) {
            navigate(destination);
        }

        if (currentUser) {
            resetCurrentRecoilUser();
        }

        onAuthSuccess();
        onRequestClose();
    };

    const handleSignIn = async () => {
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password);
            afterLogin();
        } catch (e) {
            setError(e.message);
        }
    };

    const handleSignInWithGoogle = async (e) => {
        e.preventDefault();
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase
            .auth()
            .signInWithPopup(provider)
            .then((res) => setOtherAuthData(res.user))
            .catch((err) => console.log(err));
        afterLogin();
    };

    return (
        <>
            <Dialog
                className={classes.dialogZIndex}
                disableScrollLock
                open={open}
                onClose={onRequestClose}
            >
                <Box component="main" className={classes.modalContent}>
                    <Box className={classes.paper}>
                        <Typography
                            className={classes.headingText}
                            color="secondary"
                            style={{ paddingTop: '10px' }}
                            variant="h4"
                        >
                            {title ?? 'Join our vibrant online community!'}
                        </Typography>

                        <Typography
                            className={classes.headingText}
                            color="secondary"
                            style={{ paddingTop: '10px' }}
                            variant="h5"
                        >
                            Hands-on and experiential education.
                        </Typography>

                        {/* <Typography
                                className={classes.headingText}
                                color="secondary"
                                style={{ paddingTop: '10px' }}
                                variant="h5"
                            >
                                Delivered by top practitioners.
                                <br /> Learn and connect with others.
                            </Typography> */}
                        {error && (
                            <Box p={2}>
                                <Alert severity="error">{error}</Alert>
                            </Box>
                        )}
                        <form
                            className={classes.form}
                            noValidate
                            onSubmit={handleSignIn}
                        >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                color="secondary"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                color="secondary"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.submit}
                                onClick={handleSignIn}
                            >
                                Log In
                            </Button>
                            {/* <OtherAuth classes={classes} /> */}
                            <Button
                                onClick={handleSignInWithGoogle}
                                type="submit"
                                fullWidth
                                variant="outlined"
                                // color="primary"
                                // className={classes.loginWithGoogle}
                            >
                                <img
                                    src={download}
                                    width="28px"
                                    height="28px"
                                    alt="Google icon"
                                />
                                <span style={{ paddingLeft: '10px' }}>
                                    Log in with google
                                </span>
                            </Button>
                            <Button
                                href={`${baseUrl}/api/auth/linkedin/login`}
                                startIcon={<LinkedInIcon color="primary" />}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.loginWithLinkedin}
                            >
                                Log in with Linkedin
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link
                                        href="#"
                                        variant="body2"
                                        color="secondary"
                                        onClick={() => {
                                            setIsForgotPasswordDialogOpen(true);
                                            onRequestClose();
                                        }}
                                    >
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link
                                        /* href="/couponsignup" */
                                        href={signUpLink}
                                        variant="secondary"
                                        color="secondary"
                                    >
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Dialog>
            <ForgotPasswordDialog
                open={isForgotPasswordDialogOpen}
                setOpen={setIsForgotPasswordDialogOpen}
            />
        </>
    );
};
