import { useState, useEffect } from 'react';
import axios from 'axios';

import { useRecoilState } from 'recoil';

import { allTADsState } from '../recoil/state';

export const useTADs = () => {
    const [admins, setAdmins] = useRecoilState(allTADsState);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAdmins = async () => {
            try {
                const response = await axios.get('/api/admin/tad/all');
                setAdmins(response.data);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        if (!admins.length) fetchAdmins();
    }, []);

    return { admins, loading, error };
};
