import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    starweaver: 0,
    myConstellations: 0,
};

export const tabSlice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        updateTabCount: (state, action) => {
            state = {
                ...state,
                [action.payload.tabName]: action.payload.count,
            };
            return state;
        },
    },
});

export const { updateTabCount } = tabSlice.actions;
export default tabSlice.reducer;
