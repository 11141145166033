import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const inactive = '#ccc';
const active = '#ea7112';
const starSize = '25px';

const useStyles = makeStyles()((theme) => ({
    process: {
        position: 'fixed',
        zIndex: '10000',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.7)',
        display: 'grid',
        placeItems: 'center',
    },
    loadContainer: {
        color: '$fff',
        maxWidth: 300,
    },
    starsContainer: {
        height: '75px',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
    },
    star: {
        backgroundColor: theme.palette.secondary.main,
        width: starSize,
        height: starSize,
        clipPath:
            'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
        animation: '$starAnimate 2.4s ease-in infinite',
    },
    '@keyframes starAnimate': {
        '0%': {
            backgroundColor: inactive,
            opacity: '0.5',
        },
        '30%': {
            backgroundColor: active,
            opacity: 1,
        },
        '100%': {
            backgroundColor: inactive,
            opacity: '0.5',
        },
    },
}));

export const StarweaverLoader = ({ loadingText = '' }) => {
    const { classes } = useStyles();
    return (
        <Box className={classes.process}>
            <Box className={classes.loadContainer}>
                <Box className={classes.starsContainer}>
                    {[20, 10, 0, 10, 20].map((val, index) => (
                        <Box
                            className={classes.star}
                            style={{
                                marginTop: val,
                                animationDelay: `${index * 0.4}s`,
                            }}
                        />
                    ))}
                </Box>
                <Typography align="center" color="primary" variant="h6">
                    {loadingText}
                </Typography>
            </Box>
        </Box>
    );
};
