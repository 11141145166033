export const makeCsvAndDownload = (fileName, data) => {
    // HERE WE ARE MAKING THE CSV FORMAT FILE BY OUR SELF
    let CSV = '';
    // HERE WE CREATE THE LABELS FOR THE CSV
    let labels = '';
    for (let index in data[0]) {
        //Now convert each value to string and comma-seprated
        labels += index + ',';
    }
    labels = labels.slice(0, -1);
    CSV += labels + '\r\n';

    // I AM USING THIS ONE CAUSE IT'S TOO DYNAMIC IT WILL CREATE A CSV FOR ALL THE FILES
    for (let i = 0; i < data.length; i++) {
        let row = '';
        // HERE WE LOOP THROUGH THE INDEX AND ADD THEM TO THE ROW
        for (let index in data[i]) {
            row += '"' + data[i][index] + '",';
        }
        // THIS REMOVE THE COMMA AT THE END
        row.slice(0, row.length - 1);

        // TEHN WE ADD IT TO THE CSV FILE WE ARE MAKING
        CSV += row + '\r\n';
    }

    // URI IS FOR THE CSV FILE // IT NEEDS TO BE LIKE THIS
    let uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    // THIS IS THE DOWNLOADING PROCESS WHICH WE DO
    // WE CAN DO THIS TO DOWNLOAD ANYTHING
    let link = document.createElement('a');
    link.href = uri;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
