import { useRef } from 'react';
import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    useTheme,
    List,
    ListItem,
    IconButton,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DeleteForever, DragIndicator, Edit } from '@mui/icons-material';

const useStyles = makeStyles()((theme) => ({
    secondStep: {
        '& .form-group label': {
            fontSize: '1.1rem',
        },
    },
    addBtn: {
        textAlign: 'right',
    },
    categoryList: {
        marginTop: theme.spacing(1),
        height: '280px',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: theme.spacing(2, 1),
        backgroundColor: theme.palette.grey['100'],
    },
    categoryItem: {
        fontSize: '1rem',
        padding: theme.spacing(1.2, 1),
        boxShadow: theme.shadows['1'],
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
    },
    dragIcon: {
        cursor: 'move',
    },
}));

function SecondStep({
    taskCategories,
    newCategoryName,
    setNewCategoryName,
    onAddCategory,
    onCategoryDelete,
    onCategoryEdit,
    categoryToBeEdited,
    onCategoryUpdate,
    setTaskCategories,
}) {
    const { classes } = useStyles();
    const theme = useTheme();
    const isXSDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const draggingItem = useRef(null);
    const dragOverItem = useRef();

    const handleChange = (e) => {
        setNewCategoryName(e.target.value);
    };

    const handleAdd = () => {
        onAddCategory();
    };

    const handleDragStart = (e, pos) => {
        draggingItem.current = pos;
    };

    const handleDragEnter = (e, position) => {
        dragOverItem.current = position;
        const listCopy = [...taskCategories];
        const draggingItemContent = listCopy[draggingItem.current];
        listCopy.splice(draggingItem.current, 1);
        listCopy.splice(dragOverItem.current, 0, draggingItemContent);
        draggingItem.current = dragOverItem.current;
        dragOverItem.current = null;
        setTaskCategories(listCopy);
    };

    return (
        <Box className={classes.secondStep}>
            <div className="form-group">
                <label htmlFor="categoryName">Create new category</label>
                <TextField
                    id="categoryName"
                    name="categoryName"
                    type="text"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    value={newCategoryName}
                    onChange={handleChange}
                    size={isXSDevice ? 'small' : 'medium'}
                />

                <Box display="flex" justifyContent={'flex-end'} mt={1}>
                    {categoryToBeEdited === null ? (
                        <Button
                            variant="contained"
                            color="secondary"
                            size={isXSDevice ? 'small' : 'medium'}
                            className={classes.addBtn}
                            disabled={newCategoryName.trim().length === 0}
                            onClick={handleAdd}
                        >
                            ADD
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="secondary"
                            size={isXSDevice ? 'small' : 'medium'}
                            className={classes.addBtn}
                            disabled={newCategoryName.trim().length === 0}
                            onClick={() => onCategoryUpdate()}
                        >
                            UPDATE
                        </Button>
                    )}
                </Box>
            </div>

            <List className={classes.categoryList}>
                {taskCategories.map((category, idx) => {
                    return (
                        <ListItem
                            key={category.categoryName + '-' + idx}
                            className={classes.categoryItem}
                            onDragStart={(e) => handleDragStart(e, idx)}
                            onDragOver={(e) => e.preventDefault()}
                            onDragEnter={(e) => handleDragEnter(e, idx)}
                            draggable
                        >
                            <DragIndicator className={classes.dragIcon} />
                            <Typography variant="body1">
                                {category.categoryName}
                            </Typography>

                            <Box marginLeft={'auto'}>
                                <IconButton
                                    size="small"
                                    onClick={() => onCategoryDelete(idx)}
                                >
                                    <DeleteForever
                                        fontSize={
                                            isXSDevice ? 'small' : 'medium'
                                        }
                                    />
                                </IconButton>

                                <IconButton
                                    size="small"
                                    onClick={() => onCategoryEdit(idx)}
                                >
                                    <Edit
                                        fontSize={
                                            isXSDevice ? 'small' : 'medium'
                                        }
                                    />
                                </IconButton>
                            </Box>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
}

export default SecondStep;
