import * as React from 'react';
import Helmet from 'react-helmet';

export const HelmetWrapper = ({
    route,
    canonicalPath,
    openGraphImage,
    title,
}) => {
    return (
        <Helmet>
            {title ? <title>{title}</title> : <title>{route?.title}</title>}
            {canonicalPath && (
                <link
                    rel="canonical"
                    href={`http://go.starweaver.com${canonicalPath}`}
                />
            )}
            {openGraphImage && (
                <meta property="og:image" content={openGraphImage} />
            )}
        </Helmet>
    );
};
