import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import firebase from 'firebase/app';
import { capitalize } from './capitalize';
import { shuffle } from './shuffle';

export const createServerResourceSlice = ({
    name,
    url,
    defaultValue = null,
    requiresAuth,
    shuffleData = false,
}) => {
    const resourceSlice = createSlice({
        name,
        initialState: {
            isLoading: false,
            data: defaultValue,
            error: null,
        },
        reducers: {
            [`load${capitalize(name)}Start`]: (state) =>
                (state = {
                    ...state,
                    error: null,
                    isLoading: true,
                }),
            [`load${capitalize(name)}Success`]: (state, { payload }) =>
                (state = {
                    ...state,
                    data: payload,
                    error: null,
                    isLoading: false,
                }),
            [`load${capitalize(name)}Failure`]: (state, { payload }) =>
                (state = {
                    ...state,
                    error: payload,
                    isLoading: false,
                }),
        },
    });

    return {
        reducer: resourceSlice.reducer,
        ...resourceSlice.actions,
        [`load${capitalize(name)}`]: () => async (dispatch) => {
            const startAction =
                resourceSlice.actions[`load${capitalize(name)}Start`];
            dispatch(startAction());
            try {
                let response;

                if (requiresAuth) {
                    const user = firebase.auth().currentUser;
                    const authtoken = await user.getIdToken();
                    response = await axios.get(`/api${url}`, {
                        headers: { authtoken },
                    });
                } else {
                    response = await axios.get(`/api${url}`, {
                        headers: { 'Accept-Encoding': 'gzip' },
                    });
                }

                const successAction =
                    resourceSlice.actions[`load${capitalize(name)}Success`];

                dispatch(
                    successAction(
                        shuffleData ? shuffle(response.data) : response.data
                    )
                );
            } catch (e) {
                console.log(e);
                const failureAction =
                    resourceSlice.actions[`load${capitalize(name)}Failure`];
                dispatch(failureAction(e));
            }
        },
        [`get${capitalize(name)}`]: (state) => state[name].data,
        [`get${capitalize(name)}IsLoading`]: (state) => state[name].isLoading,
        [`get${capitalize(name)}Error`]: (state) => state[name].error,
    };
};
