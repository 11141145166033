import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Fuse from 'fuse.js';
import {
    Box,
    Card,
    Chip,
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Radio,
    TextField,
    Typography,
} from '..';

const useStyles = makeStyles()((theme) => ({
    root: { padding: 20, marginBottom: 20, marginTop: 20 },
    heading: {
        marginBottom: 20,
    },
    search: {
        marginBottom: 20,
    },

    list: {
        maxHeight: 300,
        overflow: 'auto',
    },

    chipsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: 10,
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

export const ListSearchAndSelectOne = ({
    list,
    heading,
    selectedListItem,
    handleItemSelect,
}) => {
    const { classes } = useStyles();

    const [searchQuery, setSearchQuery] = useState('');

    const [searchFunc, setSearchFunc] = useState(() => () => []);
    useEffect(() => {
        console.log('Setting up fuse.');

        const fuse = new Fuse(list, { keys: ['name', 'title'] });
        setSearchFunc(() => {
            return (query) => {
                return query !== ''
                    ? fuse.search(query).map(({ item }) => item)
                    : list;
            };
        });
    }, [list]);

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <Box className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6" className={classes.heading}>
                        {heading}
                    </Typography>{' '}
                    <TextField
                        label="Search"
                        variant="outlined"
                        color="secondary"
                        value={searchQuery}
                        onChange={handleSearchQueryChange}
                        fullWidth
                        className={classes.search}
                    />
                    <Card component="div" className={classes.list}>
                        {searchFunc(searchQuery).map((item, index) => (
                            <ListItem
                                button
                                key={index}
                                onClick={() => {
                                    handleItemSelect(item);
                                }}
                                style={{
                                    cursor:
                                        selectedListItem?.id === item.id
                                            ? 'default'
                                            : 'pointer',
                                }}
                            >
                                <ListItemText
                                    primary={item.name || item.title}
                                    primaryTypographyProps={{
                                        style: {
                                            color:
                                                selectedListItem?.id === item.id
                                                    ? 'LightGray'
                                                    : 'Black',
                                        },
                                    }}
                                />
                            </ListItem>
                        ))}
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};
