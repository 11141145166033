import { useRef, useState, useEffect, useMemo } from 'react';
import { Box, LinearProgress } from '@mui/material';
import DropBoxHeader from './DropBoxHeader';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../app/recoil';
import SelectedFiles from './SelectedFiles';
import { useDispatch, useSelector } from 'react-redux';
import {
    reset,
    setCloseEditor,
    setIsFile,
    COMMENT,
    setLoadAttachments,
} from '../slices/commentAttachmentSlice';
import { AzureBlobStorage } from '../../util/BlobStorage';
import { LinearProgressWithLabel } from '../../ui/CustomUI';
import useStyles from './styles';

function DropBox() {
    const setAlertState = useSetRecoilState(alertState);
    const attachmentInputRef = useRef(null);
    const [files, setFiles] = useState(null);
    const [glow, setGlow] = useState(false);
    const commentAttachments = useSelector((state) => state.commentAttachments);
    const [totalFilesUploaded, setTotalFilesUploaded] = useState(0);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (files?.length === 0) {
            setFiles(null);
        }
    }, [files?.length]);

    useEffect(() => {
        if (
            attachmentInputRef.current &&
            (files === null || files?.length === 0)
        ) {
            attachmentInputRef.current.value = '';
            dispatch(reset());
        }
    }, [dispatch, files]);

    useEffect(() => {
        if (
            commentAttachments.isFile &&
            commentAttachments.commentId &&
            files &&
            files?.length > 0
        ) {
            setIsUploading(true);
            const blobStorage = new AzureBlobStorage();
            const containerName = 'comments';
            const folderPath =
                commentAttachments.type === COMMENT
                    ? commentAttachments.commentId
                    : `${commentAttachments.commentId}/replies/${commentAttachments.replyId}`;

            blobStorage
                .uploadFiles(
                    files,
                    containerName,
                    setTotalFilesUploaded,
                    setCurrentProgress,
                    folderPath
                )
                .then(() => {
                    dispatch(
                        setLoadAttachments({
                            commentId: commentAttachments.commentId,
                            loading: true,
                        })
                    );
                    dispatch(setCloseEditor(true));
                    setIsUploading(false);
                })
                .catch((e) => {
                    console.error(e);
                    setIsUploading(false);
                });
        }
    }, [
        commentAttachments.commentId,
        commentAttachments.isFile,
        commentAttachments.replyId,
        commentAttachments.type,
        dispatch,
        files,
    ]);

    const overallProgress = useMemo(() => {
        const cellWidth = files?.length ? 100 / files.length : 0;
        if (totalFilesUploaded === files?.length) return 100;
        const totalProgress =
            totalFilesUploaded * cellWidth +
            (currentProgress / 100) * cellWidth;
        return totalProgress;
    }, [currentProgress, files?.length, totalFilesUploaded]);

    const handleSelectClick = (e) => {
        e.stopPropagation();
        if (attachmentInputRef.current) {
            attachmentInputRef.current.click();
        }
    };

    const storeFileInState = (files) => {
        setFiles(Array.from(files));
    };

    const isInvalidMimeType = (files) => {
        const validFileTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/csv',
            'text/plain',
            'image/jpeg',
            'image/webp',
            'image/png',
            'video/mp4',
            'video/mpeg',
            'application/vnd.rar',
            'application/zip',
            'application/zip',
            'application/x-zip-compressed',
        ];

        return Array.from(files).some(
            (file) => !validFileTypes.includes(file.type)
        );
    };

    const showInvalidFileAlert = (message) => {
        setAlertState({
            show: true,
            message,
            severity: severity.WARNING,
        });
    };

    const isInvalidFileLength = (files) => {
        return Array.from(files).length > 5;
    };

    const isInvalidFileSize = (files) => {
        const validFileSize = 30 * 1000 * 1000;
        const totalFileSize = Array.from(files).reduce(
            (prevState, currValue) => {
                return prevState + currValue.size;
            },
            0
        );
        return totalFileSize > validFileSize;
    };

    const storeFiles = (files) => {
        if (isInvalidFileLength(files)) {
            showInvalidFileAlert('Can add up to 5 files');
            setFiles(null);
            return;
        }

        if (isInvalidFileSize(files)) {
            showInvalidFileAlert('Maximun 30MB is allowed');
            setFiles(null);
            return;
        }

        if (isInvalidMimeType(files)) {
            showInvalidFileAlert('Invalid file type');
            setFiles(null);
            return;
        }

        storeFileInState(files);
        dispatch(setIsFile(true));
    };

    const handleFileSelected = (e) => {
        const files = e.target.files;
        if (!files || files.length === 0) return;
        storeFiles(files);
    };

    const handleRemoveSelectedFile = (event, index) => {
        event.stopPropagation();
        if (!files) return;
        setFiles((prevFiles) => {
            return prevFiles.filter((_, i) => index !== i);
        });
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (!files || files?.length === 0) return;
        storeFiles(files);
        setGlow(false);
    };

    const handleDragOver = (e) => {
        setGlow(true);
        e.preventDefault();
    };

    return (
        <Box
            sx={{
                backgroundColor: glow ? '#ffedd5' : '#f8f8f8',
                cursor: 'pointer',
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={handleSelectClick}
        >
            <DropBoxHeader handleSelectClick={handleSelectClick} />
            <input
                ref={attachmentInputRef}
                type="file"
                name="attachments"
                onChange={handleFileSelected}
                hidden
                multiple
            />

            {isUploading && <LinearProgressWithLabel value={overallProgress} />}

            {files !== null && files?.length > 0 && (
                <SelectedFiles
                    files={files}
                    handleRemoveSelectedFile={handleRemoveSelectedFile}
                />
            )}
        </Box>
    );
}

export default DropBox;
