import { makeStyles } from 'tss-react/mui';
import { Typography, Icon, Box, Link as MuiLink } from '../ui';

export const Cards = ({ CardIcon, title, body, link }) => {
    const { classes } = useStyles();
    return (
        <MuiLink href={link ? link : '#'}>
            <Box className={classes.cardContainer}>
                <Box className={classes.icon}>
                    <Icon color="secondary">{<CardIcon />}</Icon>
                </Box>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.body}>{body}</Typography>
            </Box>
        </MuiLink>
    );
};

const useStyles = makeStyles()((theme) => ({
    cardContainer: {
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px 10px',
        borderRadius: '5px',
        transition: 'all 0.2s ease-out',
        padding: '20px',
        color: 'black',
        '&:hover': {
            boxShadow: '0px 2px 10px 0.5px rgba(0,0,0,0.2)',
            transform: 'scale(1.02)',
        },
    },
    icon: {
        width: '50px',
        height: '50px',
        display: 'grid',
        placeItems: 'center',
        boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
        borderRadius: '50%',
    },
    title: {
        fontSize: '1.3em',
        fontWeight: 700,
        textAlign: 'center',
        margin: '20px 0',
    },
    body: {
        fontSize: '1em',
        margin: '0 0 10px',
        textAlign: 'center',
    },
}));
