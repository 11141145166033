import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment-timezone';
import {
    Box,
    InputAdornment,
    MultipleSelectChip,
    SingleSelect,
    TextField,
    Typography,
} from '../ui';
import { makeStyles } from 'tss-react/mui';
import withStyles from '@mui/styles/withStyles';
import { Grid, Slider } from '@mui/material';

let timeoutId = null;

export const ALPHA = 'ALPHA';
export const BETA = 'BETA';
export const GOLD = 'GOLD';

export const urgencyOptions = [
    { label: 'Critical/Immediate', value: 'IMMEDIATE' },
    { label: 'Important/Soon', value: 'SOON' },
    { label: 'Planning-in-Progress', value: 'PLANNING' },
];

const useStyles = makeStyles()((theme) => ({
    text: { marginBottom: 40 },
    sliderContainer: {
        width: '100%',
    },
}));

const PrettoSlider = withStyles({
    root: {
        color: '#ea7112',
        height: 6,
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        // marginTop: -8,
        // marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 6,
        borderRadius: 4,
    },
    rail: {
        height: 6,
        borderRadius: 4,
    },
})(Slider);

export const ClientRequirementsCreateFormDelivery = ({
    deliveryDate,
    setDeliveryDate,
    endDate,
    setEndDate,
    selectedTimeZones,
    setSelectedTimeZones,
    minBudget,
    setMinBudget,
    targetBudget,
    setTargetBudget,
    maxBudget,
    setMaxBudget,
    isInstructor,
    selectedUrgency,
    setSelectedUrgency,
    validationErrors,
    setValidationErrors,
    instructorBudget,
    setInstructorBudget,
    isAdmin,
    release,
    setRelease,
    selectedOnlineOrOffline,
    setSelectedOnlineOrOffline,
    minNumOfStudents,
    setMinNumOfStudents,
    maxNumOfStudents,
    setMaxNumOfStudents,
}) => {
    const { classes } = useStyles();
    const deliveryRangeOptions = [
        { label: 'Next week', value: 'next-week' },
        { label: 'This month', value: 'this-month' },
        { label: 'Next Month', value: 'next-month' },
        { label: 'This quarter', value: 'this-quarter' },
        { label: 'Next quarter', value: 'next-quarter' },
        { label: 'Next 2 quarter', value: 'next-2-quarter' },
        { label: 'Custom', value: 'custom' },
    ];

    const releaseOptions = [
        { label: ALPHA, value: ALPHA },
        { label: BETA, value: BETA },
        { label: GOLD, value: GOLD },
    ];

    const onlineOptions = [
        { label: 'In person', value: 'OFFLINE' },
        { label: 'Online', value: 'ONLINE' },
        // { label: 'On Air', value: 'ON_AIR' },
        // { label: 'On Demand', value: 'ON_DEMAND' },
    ];

    const [selectedDeliveryRange, setSelectedDeliveryRange] = useState(
        'custom'
    );
    const [sliderValue, setSliderValue] = useState([0, 5000]);

    useEffect(() => {
        switch (selectedDeliveryRange) {
            case 'next-week':
                setDeliveryDate(
                    moment
                        .utc()
                        .add(1, 'weeks')
                        .startOf('isoWeek')
                        .toISOString()
                );
                setEndDate(
                    // moment
                    //     .utc()
                    //     .add(1, 'weeks')
                    //     .startOf('isoWeek')
                    //     .add(6, 'd')
                    //     .toISOString()

                    moment
                        .utc()
                        .add(1, 'weeks')
                        .endOf('isoWeek')
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

                        .toISOString()
                );
                break;
            case 'this-month':
                setDeliveryDate(
                    moment
                        .utc()

                        .startOf('month')
                        .toISOString()
                );
                setEndDate(
                    moment
                        .utc()
                        .endOf('month')
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .toISOString()
                );
                break;
            case 'next-month':
                setDeliveryDate(
                    moment.utc().add(1, 'M').startOf('month').toISOString()
                );
                setEndDate(
                    moment
                        .utc()
                        .add(1, 'M')
                        .endOf('month')
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .toISOString()
                );
                break;
            case 'next-quarter':
                setDeliveryDate(
                    moment.utc().add(1, 'Q').startOf('quarter').toISOString()
                );
                setEndDate(
                    moment
                        .utc()
                        .add(1, 'Q')
                        .endOf('quarter')
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .toISOString()
                );
                break;
            case 'next-2-quarter':
                setDeliveryDate(
                    moment.utc().add(1, 'Q').startOf('quarter').toISOString()
                );
                setEndDate(
                    moment
                        .utc()
                        .add(2, 'Q')
                        .endOf('quarter')
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .toISOString()
                );
                break;
            case 'this-quarter':
                setDeliveryDate(moment.utc().startOf('quarter').toISOString());
                setEndDate(
                    moment
                        .utc()
                        .endOf('quarter')
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .toISOString()
                );
                break;
            default:
        }
    }, [selectedDeliveryRange, setDeliveryDate, setEndDate]);

    useEffect(() => {
        let min = minBudget && minBudget !== 0 ? minBudget : 0;
        let max = maxBudget && maxBudget !== 0 ? maxBudget : 5000;
        setSliderValue([min, max]);
    }, [minBudget, maxBudget]);

    const validation = useCallback(
        (key) => {
            const error = validationErrors.filter((err) => {
                return err?.param === key;
            });

            if (error.length > 0) {
                return { isError: true, message: error[0]?.message };
            } else {
                return { isError: false, message: '' };
            }
        },
        [validationErrors]
    );

    const removeErrorFromList = (key) => {
        if (validation(key).isError) {
            setValidationErrors((prev) => {
                return prev.filter((p) => p.param !== key);
            });
        }
    };

    const onChangeDeliveryTime = (e, timeZone) => {
        let newTime;

        newTime = moment.utc(e.target.value, moment.HTML5_FMT.DATE);

        if (moment.utc(endDate).isBefore(newTime))
            setEndDate(
                moment
                    .utc(newTime) /* .add(1, 'd') */
                    .toISOString()
            );

        setDeliveryDate(newTime.toISOString());
        removeErrorFromList('deliveryDate');
        setSelectedDeliveryRange('custom');
    };

    const onChangeEndTime = (e, timeZone) => {
        let newTime;

        newTime = moment.utc(e.target.value, moment.HTML5_FMT.DATE);

        if (moment.utc(deliveryDate).isAfter(newTime))
            setDeliveryDate(
                moment
                    .utc(newTime) /* .subtract(1, 'd') */
                    .toISOString()
            );
        setEndDate(newTime.toISOString());
        removeErrorFromList('endDate');
        setSelectedDeliveryRange('custom');
    };

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
        if (timeoutId) {
            window.clearTimeout(timeoutId);
        }
        timeoutId = window.setTimeout(() => {
            let min = newValue[0];
            let max = newValue[1];
            setMinBudget(min);
            setMaxBudget(max);
        }, 800);
    };

    const handleInstructorBudgetChange = (e) => {
        removeErrorFromList('instructorBudget');
        setInstructorBudget(e.target.value);
    };
    return (
        <Box>
            {/* <Typography
                    variant="h6"
                    style={{ marginRight: 20, marginLeft: 20 }}
                >
                    {' '}
                    —{' '}
                </Typography> */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} md={4}>
                    <Typography variant="h6">
                        <sup>*</sup>Format
                    </Typography>
                    <SingleSelect
                        // label="Format"
                        options={onlineOptions}
                        selectedValue={selectedOnlineOrOffline}
                        setSelectedValue={setSelectedOnlineOrOffline}
                        required
                        canNotBeNone
                        isError={validation('selectedOnlineOrOffline').isError}
                        helperText={
                            validation('selectedOnlineOrOffline').message
                        }
                        resetError={{
                            handler: removeErrorFromList,
                            key: 'selectedOnlineOrOffline',
                        }}
                        fullWidth={true}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography variant="h6">
                        <sup>*</sup>Number of students
                    </Typography>

                    <Box display="flex" alignItems="center" gap={2}>
                        {/* <TextField
                            value={minNumOfStudents}
                            type="number"
                            onChange={(e) => {
                                setMinNumOfStudents(e.target.value);
                            }}
                            variant="outlined"
                            required
                            label="from"
                            color="secondary"
                            InputProps={{
                                inputProps: { min: 0 },
                            }}
                        /> */}

                        {/* <Typography variant="h6"> — </Typography> */}

                        <TextField
                            value={maxNumOfStudents}
                            type="number"
                            onChange={(e) => {
                                setMaxNumOfStudents(e.target.value);
                            }}
                            variant="outlined"
                            required
                            // label="to"
                            color="secondary"
                            InputProps={{
                                inputProps: { min: 0 },
                            }}
                            fullWidth
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography variant="h6">
                        <sup>*</sup>Timing
                    </Typography>
                    <SingleSelect
                        // label="Urgency"
                        options={urgencyOptions}
                        selectedValue={selectedUrgency}
                        setSelectedValue={setSelectedUrgency}
                        required
                        canNotBeNone
                        isError={validation('selectedUrgency').isError}
                        helperText={validation('selectedUrgency').message}
                        resetError={{
                            handler: removeErrorFromList,
                            key: 'selectedUrgency',
                        }}
                        fullWidth={true}
                    />
                </Grid>
            </Grid>

            <Box mb={2}>
                <Typography variant="h6">
                    Expected deliver date range
                </Typography>

                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} md={4}>
                        <SingleSelect
                            // label="Length"
                            options={deliveryRangeOptions}
                            selectedValue={selectedDeliveryRange}
                            setSelectedValue={setSelectedDeliveryRange}
                            // required
                            canNotBeNone
                            fullWidth={true}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            onChange={onChangeDeliveryTime}
                            label="from"
                            type="date"
                            value={moment
                                .utc(deliveryDate)
                                .format(moment.HTML5_FMT.DATE)}
                            variant="outlined"
                            required
                            color="secondary"
                            error={validation('deliveryDate').isError}
                            helperText={validation('deliveryDate').message}
                            fullWidth={true}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            onChange={onChangeEndTime}
                            label="to"
                            type="date"
                            value={moment
                                .utc(endDate)
                                .format(moment.HTML5_FMT.DATE)}
                            variant="outlined"
                            required
                            color="secondary"
                            error={validation('endDate').isError}
                            helperText={validation('endDate').message}
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            </Box>
            {/* <Box marginBottom={6}>
                <Typography variant="h6">Release</Typography>
                <SingleSelect
                    label="Release"
                    options={releaseOptions}
                    selectedValue={release}
                    setSelectedValue={setRelease}
                    required
                    canNotBeNone
                    style={{ marginTop: '1rem', marginBottom: '1.5rem' }}
                    isError={validation('release').isError}
                    helperText={validation('release').message}
                    resetError={{
                        handler: removeErrorFromList,
                        key: 'release',
                    }}
                />
            </Box> */}

            <Box mb={2}>
                <Typography variant="h6">Time Zone</Typography>
                <MultipleSelectChip
                    options={[
                        'Americas',
                        'Asia Pacific',
                        'Europe, Middle East & Africa',
                    ]}
                    selection={selectedTimeZones}
                    setSelection={setSelectedTimeZones}
                    fullWidth={true}
                />
            </Box>

            {!isInstructor && (
                <Box>
                    <Typography variant="h6">Budget</Typography>

                    <Box className={classes.sliderContainer}>
                        <PrettoSlider
                            valueLabelDisplay="auto"
                            aria-label="range slider"
                            value={sliderValue}
                            onChange={handleSliderChange}
                            min={0}
                            max={25000}
                            step={500}
                        />
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="from"
                                value={minBudget}
                                type="number"
                                onChange={(e) => {
                                    setMinBudget(e.target.value);
                                }}
                                variant="outlined"
                                required
                                color="secondary"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                label="target"
                                value={targetBudget}
                                type="number"
                                onChange={(e) => {
                                    setTargetBudget(e.target.value);
                                }}
                                onBlur={() => {
                                    const input = parseInt(targetBudget);
                                    const newBudget =
                                        input < minBudget
                                            ? minBudget
                                            : input > maxBudget
                                            ? maxBudget
                                            : input;

                                    setTargetBudget(newBudget);
                                }}
                                variant="outlined"
                                // required
                                color="secondary"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                disabled={!minBudget || !maxBudget}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                label="to"
                                value={maxBudget}
                                type="number"
                                onChange={(e) => {
                                    setMaxBudget(e.target.value);
                                }}
                                variant="outlined"
                                required
                                color="secondary"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    {isAdmin && (
                        <Box mt={2}>
                            <Typography variant="h6" gutterBottom>
                                {/* Instructor Budget: */}
                                Terms & Conditions:
                            </Typography>
                            <TextField
                                name="instructorBudget"
                                multiline
                                id="instructorBudget"
                                variant="outlined"
                                color="secondary"
                                onChange={handleInstructorBudgetChange}
                                value={instructorBudget}
                                error={validation('instructorBudget').isError}
                                helperText={
                                    validation('instructorBudget').message
                                }
                                fullWidth
                                rows={10}
                            />
                        </Box>
                    )}
                </Box>
            )}

            {/* <Box marginBottom={6} display="flex" alignItems="center">
                <Typography variant="h6" style={{ marginRight: 20 }}>
                    Amount of time available to teach (hours/per week):​{' '}
                </Typography>

                <TextField
                    // value={maxNumOfStudents}
                    type="number"
                    // onChange={(e) => {
                    //     setMaxNumOfStudents(e.target.value);
                    // }}
                    variant="outlined"
                    required
                    color="secondary"
                />
            </Box>
            
            <Box marginBottom={6} display="flex" alignItems="center">
                <Typography variant="h6" style={{ marginRight: 20 }}>
                    Want course recordings available afterwards?​{' '}
                </Typography>
                <Switch />
            </Box> */}
        </Box>
    );
};
