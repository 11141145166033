import moment from 'moment';
import React, {
    useState,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from 'react';
import {
    // Avatar,
    Backdrop,
    Box,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    LinearProgress,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Slide,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
    ArrowDropDownCircle,
    CheckCircle,
    DragIndicator,
    MoreVert,
} from '@mui/icons-material';
import { BootstrapTooltip, ConfirmDialog } from '../ui/CustomUI';
import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../app/recoil';
import { CreateNewTaskModal } from './CreateNewTaskModal';
/* import { skipWeekend } from '../util'; */
import { UpdateTemplateModal } from './UpdateTemplateModal';
import CustomTemplateModal from './components/CustomTemplateModal';
// import { AvatarGroup } from '@mui/material';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import TimelineCapsule from './components/OMLogistics/TimelineCapsule';
import EditTaskDialog from './components/OMLogistics/EditTaskDialog';
import GridOnIcon from '@mui/icons-material/GridOn';
// import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { roleList, stringToColour } from '../util';
import LogisticCalendarView from './components/OMLogistics/LogisticCalendarView';
import RefreshIcon from '@mui/icons-material/Refresh';
import ResetTimelineModal from './components/OMLogistics/ResetTimelineModal';
import TaskAssignedTo from './components/OMLogistics/TaskAssignedTo';
import useFetchTaskCategoriesByUserAssigned from './hooks/useFetchTaskCategoriesByUserAssigned';
// import PersonIcon from '@mui/icons-material/Person';
import useExportLogisticsData from './hooks/useExportLogisticsData';
import useFetchTaskCategoriesByRole from './hooks/useFetchTaskCategoriesByRole';
import useFetchTaskCategoriesByCronology from './hooks/useFetchTaskCategoriesByCronology';
import { PageLoader } from '../ui';
import { useSelector } from 'react-redux';
// import { roleList } from '../util';
import EmptyImage from '../img/empty.svg';

let timeoutID = null;

// const IN_PROGRESS = 'IN PROGRESS';
const COMPLETED = 'COMPLETED';
// const DONE = 'DONE';
const ACTIVATE = 'Activate';
const DEACTIVATE = 'Deactivate';
const DELETE_TASK = 'Delete';
const SHOW_TO_INSTRUCTOR = 'Show to instructor';
const MARK_AS_CHECKPOINT = 'Mark as checkpoint';
const REMOVE_CHECKPOINT = 'Remove checkpoint';
const errorCodes = [400, 401, 403, 404, 422];
const E_LEARNING_PRODUCTION = 'E-LEARNING PRODUCTION';
const GREEN = '#3dc970';
const DARK_GREEN = '#16a34a';
// const RED = '#fb7185';
const RED = '#ef4444';
const BLUE = '#818cf8';
const ORANGE = '#FFC75A';
const GRAY = 'gray';
const LIGHT_GRAY = '#808080c4';
const TEXT_CLR = 'rgba(0, 0, 0, 0.87)';
const DEFAULT_CATEGORY_HEADER_BG_CLR = '#333';
const CUSTOM_TASK = 'Custom Task';
const UPDATE_CATEGORY = 'Configure Template';
const UPDATE_TEMPLATE = 'Change Template';
const RESET_TIMELINE = 'Reset Release & Timeline ';
const ALL = 'ALL';
const EDIT_TASK = 'Edit Task';
const SIMPLIFIED = 'Simplified';
const DETAILED = 'List';
const CALENDAR = 'Calendar';
const ASSIGNED_USER = 'Assigned';
const ROLE = 'Role';
const EXPORT = 'Export';
const CATEGORY = 'Category';
const CHRONOLOGICAL = 'Chronological';
/* const ADMINISTRATIVE = 'ADMINISTRATIVE';
const PRE_PRODUCTION_AND_PLANNING = 'PRE-PRODUCTION & PLANNING';
const LIVE_TRAINING_PRODUCTION = 'LIVE TRAINING PRODUCTION';
const PUBLICATION_TO_LMS_FOR_ELEARNING_ON_DEMAND_SELF_PACED =
    'PUBLICATION TO LMS FOR ELEARNING';
const THIRD_PARTY_DISTRIBUTION = 'THIRD PARTY DISTRIBUTION';
const MARKETING_RELATED = 'MARKETING RELATED'; */

const urgencies = [
    {
        value: 'isUndue',
        label: 'Is undue',
    },
    {
        value: 'isDone',
        label: 'Is done',
    },
    {
        value: 'isOverdue',
        label: 'Is overdue',
    },
    {
        value: 'oneDayRemaining',
        label: '1 day remaining',
    },
    {
        value: 'threeOrLessDaysRemaining',
        label: '3 days or less remaining',
    },
    {
        value: 'sevenOrLessDaysRemaining',
        label: '7 days or less remaining',
    },
];

const useStyles = makeStyles()((theme) => ({
    logisticsContainer: {
        marginTop: '1rem',
    },
    deliveryDate: {
        fontSize: '1.5rem',
        fontWeight: 500,
    },
    categoryWrapper: {
        // border: '2px solid red',
    },
    categoryHeader: {
        borderRadius: '0.2rem',
        border: '1px solid #BABABA',
        // background: '#333',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        padding: '2px 18px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    activateCategoryBtn: {
        marginLeft: 'auto',
    },
    arrowIcon: {
        color: 'white',
    },
    categoryName: {
        color: '#FFF',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    taskWrapper: {
        border: '1px solid #BABABA',
        background: '#F1F1F1',
        padding: '2px 8px',
        margin: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    checkpointWrapper: {
        border: '1px solid #BABABA',
        background: '#F1F1F1',
        padding: '10px 12px',
        margin: '10px',
        display: 'flex',
        alignItems: 'center',
        poistion: 'relative',
    },
    disableTaskOverlay: {
        border: '2px solid red',
        height: '100%',
        width: '100%',
        position: 'absolute',
    },
    taskNum: {
        fontSize: '1.1rem',
        flexBasis: '2%',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    taskName: {
        fontSize: '1.1rem',
        flexBasis: '25%',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    titletaskNum: {
        flexBasis: '5%',
    },
    assignedTo: {
        fontSize: '1.1rem',
        flexBasis: '15%',
        flexShrink: 0,
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    numOfDays: {
        fontSize: '1.1rem',
        flexBasis: '10%',
        paddingLeft: '1%',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    datePicker: {
        paddingLeft: '1%',
    },
    taskActions: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
    checkpointNum: {
        flexBasis: '5%',
    },
    checkpointName: {
        fontSize: '1.1rem',
        fontWeight: 600,
        flexBasis: '60%',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    titles: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 22px 10px 35px',
        minWidth: '1024px',
    },
    title: {
        fontSize: '1.1rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    doneText: {
        fontSize: '1.1rem',
        paddingLeft: '2%',
        fontWeight: '500',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    pinnedTasks: {
        '& .info-text': {
            padding: '0.5rem 0rem 1rem',
            margin: 0,
        },
        '& .star': {
            color: 'red',
            fontWeight: 'bolder',
        },
    },
    logisticSummary: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2%',
        '& .task': {
            fontSize: '18px',
            '& .task-name': {
                fontWeight: 500,
            },
        },
    },
    actionContainer: {
        textAlign: 'right',
        marginBottom: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '10px',
    },
    checkpointContent: {
        width: '400px',
        [theme.breakpoints.down('sm')]: {
            width: '250px',
        },
    },
    colTitle: {
        flexBasis: '52%',
    },
    filterInput: {
        maxWidth: 150,
        [theme.breakpoints.up('sm')]: {
            maxWidth: 'unset',
        },
    },
    pl2: {
        paddingLeft: theme.spacing(2),
    },
    tableWrapper: {
        overflowY: 'hidden',
        overflowX: 'auto',
    },
    table: {
        borderCollapse: 'collapse',
        textAlign: 'center',
        '& thead': {
            backgroundColor: 'green',
            position: 'sticky',
        },
        '& td, & th': {
            padding: theme.spacing(1),
            minWidth: '2rem',
        },
        '& thead th': {
            color: '#fff',
            backgroundColor: '#1b4965',
            fontSize: '1.05rem',
            fontWeight: '400',
        },
        '& tbody tr': {
            backgroundColor: '#fff',
            fontSize: '1rem',
            borderBottom: '2px solid #e7e7e7',
        },
    },
    timelineCol: {
        width: '25%',
        [theme.breakpoints.down('md')]: {
            minWidth: '11.5625px',
        },
    },
    noDataContainer: {
        display: 'grid',
        placeItems: 'center',
    },
}));

// const ColumnTitle = ({ readOnly }) => {
//     const { classes } = useStyles();
//     const [titles] = useState([
//         {
//             id: 1,
//             title: '#',
//             className: `${classes.title} ${classes.titletaskNum}`,
//         },
//         {
//             id: 2,
//             title: 'Task',
//             className: `${classes.title} ${classes.taskName} ${classes.colTitle} ${classes.pl2}`,
//         },
//         {
//             id: 6,
//             title: 'Assigned to',
//             className: `${classes.title} ${classes.assignedTo}`,
//         },
//         {
//             id: 7,
//             title: 'Duration',
//             className: `${classes.title} ${classes.taskName} ${classes.colTitle}`,
//         },
//         {
//             id: 8,
//             title: 'Start Date',
//             className: `${classes.title} ${classes.taskName} ${classes.colTitle}`,
//         },
//         {
//             id: 3,
//             title: 'Metric',
//             className: `${classes.title} ${classes.numOfDays}`,
//         },
//         {
//             id: 4,
//             title: 'Done/Due',
//             className: `${classes.title} ${classes.datePicker}`,
//         },
//         {
//             id: 9,
//             title: 'Cost',
//             className: `${classes.title} ${classes.datePicker}`,
//         },
//         {
//             id: 5,
//             title: 'Status',
//             className: `${classes.title} ${classes.taskActions}`,
//         },
//     ]);

//     const computedTitles = useMemo(() => {
//         if (readOnly) {
//             return titles.filter((title) => title.id !== 5);
//         }

//         return titles;
//     }, [readOnly, titles]);

//     const getMarginLeft = useCallback(
//         (id) => {
//             if (id === 4 && readOnly === true) {
//                 return 'auto';
//             }

//             return 'no-style';
//         },
//         [readOnly]
//     );

//     return (
//         <Card elevation={1} className={classes.titles}>
//             {/* {titles.map((item) => { */}
//             {computedTitles.map((item) => {
//                 return (
//                     <Typography
//                         key={`title-${item.id}`}
//                         className={item.className}
//                         style={{
//                             marginLeft: getMarginLeft(item.id),
//                         }}
//                     >
//                         {item.title}
//                     </Typography>
//                 );
//             })}
//         </Card>
//     );
// };

/*const LogisticsSummary = ({ requirement }) => {
    const { classes } = useStyles();

    const pinnedTasks = useMemo(() => {
        const tasks = [];

        for (const category of requirement?.taskCategories) {
            for (const task of category.tasks) {
                if (task?.pinToTop === true) {
                    tasks.push({
                        name: task.name,
                        numOfDaysBeforeDelivery: skipWeekend(
                            moment(requirement?.expectedEndDate).subtract(
                                task.numOfDaysBeforeDelivery,
                                'days'
                            )
                        ).format('DD-MMM-YYYY'),
                    });
                }
            }
        }

        return tasks;
    }, [requirement?.expectedEndDate, requirement?.taskCategories]);

    return (
        <Box className={classes.pinnedTasks}>
            <Box className={classes.logisticSummary}>
                {pinnedTasks.length > 0 &&
                    pinnedTasks.map((task) => {
                        return (
                            <Typography key={task.name} className="task">
                                <span className="task-name">{task.name}</span>:{' '}
                                {task.numOfDaysBeforeDelivery}
                                <sup className="star">*</sup>
                            </Typography>
                        );
                    })}
            </Box>
            <p className="info-text">
                (<span className="star">*</span>) Counts only weekdays
            </p>
        </Box>
    );
};*/

const CategoryFilter = ({
    selectedCategory,
    setSelectedCategory,
    categories,
}) => {
    const { classes } = useStyles();

    const filters = useMemo(() => {
        const maxLength = 30;
        let data = categories
            .filter((category) => category.isActive === true)
            .map((category) => {
                return {
                    value: category.categoryName,
                    name:
                        category.categoryName.length >= maxLength
                            ? category.categoryName.slice(0, maxLength) + '...'
                            : category.categoryName,
                    isDisabled: category.isHidden,
                };
            });

        return [{ value: ALL, name: ALL, isDisabled: false }, ...data];
    }, [categories]);

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const handleChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    return (
        <Paper elevation={0}>
            <FormControl
                variant="outlined"
                color="secondary"
                className={classes.filterInput}
                size="small"
            >
                <Select
                    labelId="categoryFilter"
                    id="categoryFilter"
                    value={selectedCategory}
                    onChange={handleChange}
                >
                    {filters.map((item) => (
                        <MenuItem
                            value={item.value}
                            key={item.name}
                            disabled={item.isDisabled}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Paper>
    );
};

const GroupFilter = ({
    showAssignedUserView,
    setShowAssignedUserView,
    setSelectedCategory,
    showRoleView,
    setShowRoleView,
    setShowCalendarView,
    showCalendarView,
    setShowChronologyView,
    showChronologyView,
    setSelectedUrgency,
    isInstructor,
}) => {
    const { classes } = useStyles();

    const selectedGroup = useMemo(() => {
        if (showAssignedUserView) {
            return ASSIGNED_USER;
        }

        if (showRoleView) {
            return ROLE;
        }

        if (showChronologyView) {
            return CHRONOLOGICAL;
        }

        if (showCalendarView) {
            return CALENDAR;
        }

        return CATEGORY;
    }, [
        showAssignedUserView,
        showCalendarView,
        showChronologyView,
        showRoleView,
    ]);

    const handleChange = (e) => {
        const value = e.target.value;
        setSelectedCategory(ALL);
        setSelectedUrgency('isOverdue');

        if (value === ASSIGNED_USER) {
            setShowAssignedUserView(true);
            setShowRoleView(false);
            setShowCalendarView(false);
            setShowChronologyView(false);
        } else if (value === ROLE) {
            setShowAssignedUserView(false);
            setShowRoleView(true);
            setShowCalendarView(false);
            setShowChronologyView(false);
        } else if (value === CALENDAR) {
            setShowAssignedUserView(false);
            setShowRoleView(false);
            setShowCalendarView(true);
            setShowChronologyView(false);
        } else if (value === CHRONOLOGICAL) {
            setShowAssignedUserView(false);
            setShowRoleView(false);
            setShowCalendarView(false);
            setShowChronologyView(true);
        } else {
            setShowRoleView(false);
            setShowAssignedUserView(false);
            setShowCalendarView(false);
            setShowChronologyView(false);
        }
    };

    return (
        <Paper elevation={0}>
            <FormControl
                variant="outlined"
                color="secondary"
                className={classes.filterInput}
                size="small"
            >
                <Select
                    labelId="categoryFilter"
                    id="categoryFilter"
                    value={selectedGroup}
                    onChange={handleChange}
                >
                    <MenuItem value={CATEGORY}>{CATEGORY}</MenuItem>
                    <MenuItem value={CHRONOLOGICAL}>{CHRONOLOGICAL}</MenuItem>
                    <MenuItem value={ASSIGNED_USER}>{ASSIGNED_USER}</MenuItem>
                    <MenuItem value={ROLE}>{ROLE}</MenuItem>
                    {/* <MenuItem value={CALENDAR}>{CALENDAR}</MenuItem> */}
                </Select>
            </FormControl>
        </Paper>
    );
};

const UrgencyFilter = ({
    selectedUrgency,
    setSelectedUrgency,
    urgencies = [],
}) => {
    const { classes } = useStyles();
    const handleChange = (e) => {
        setSelectedUrgency(e.target.value);
    };
    return (
        <Paper elevation={0} sx={{ marginRight: 'auto' }}>
            <FormControl
                variant="outlined"
                color="secondary"
                className={classes.filterInput}
                size="small"
            >
                <Select
                    labelId="urgency-filter"
                    id="urgency"
                    value={selectedUrgency}
                    onChange={handleChange}
                >
                    {urgencies.map((urgency) => (
                        <MenuItem key={urgency.value} value={urgency.value}>
                            {urgency.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Paper>
    );
};

const Actions = ({
    categories,
    releaseDate,
    requirementId,
    loadRequirementData,
    setRequirement,
    selectedCategory,
    setSelectedCategory,
    setShowSimplifiedView,
    readOnly,
    setShowCalendarView,
    updateRequirement,
    setShowAssignedUserView,
    showAssignedUserView,
    getAssignedUserGroupData,
    fetchLogisticsByRole,
    handleExport,
    showRoleView,
    setShowRoleView,
    showCalendarView,
    setShowChronologyView,
    showChronologyView,
    fetchLogisticsByChronology,
    urgencies,
    setSelectedUrgency,
    selectedUrgency,
    isInDrawer,
    isInstructor,
    existingTemplateId,
}) => {
    const { classes } = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openTemplateModal, setOpenTemplateModal] = useState(false);
    const [openCustomTemplateModal, setOpenCustomTemplateModal] = useState(
        false
    );
    const [openResetTimelineModal, setOpenResetTimelineModal] = useState(false);
    const [viewAnchorEl, setViewAnchorEl] = useState(null);

    // ----------- HOOKS: START ----------------
    const showUrgencyFilter = useMemo(() => {
        return showAssignedUserView || showRoleView || showChronologyView;
    }, [showAssignedUserView, showChronologyView, showRoleView]);
    // ----------- HOOKS: END ----------------

    const onActionBtnClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const onMenuItemClick = (item) => {
        switch (item) {
            case CUSTOM_TASK:
                setOpenModal(true);
                break;
            case UPDATE_CATEGORY:
                setOpenTemplateModal(true);
                break;
            case UPDATE_TEMPLATE:
                setOpenCustomTemplateModal(true);
                break;
            case RESET_TIMELINE:
                setOpenResetTimelineModal(true);
                break;
            case EXPORT:
                handleExport();
                break;
            default:
                break;
        }

        handleMenuClose();
    };

    const handleViewMenuClick = (event) => {
        setViewAnchorEl(event.currentTarget);
    };

    const handleViewMenuClose = () => {
        setViewAnchorEl(null);
    };

    const onViewClick = (view) => {
        setSelectedCategory(ALL);
        switch (view) {
            case SIMPLIFIED:
                setShowSimplifiedView(true);
                setShowCalendarView(false);
                setShowAssignedUserView(false);
                handleViewMenuClose();
                break;
            case DETAILED:
                if (isInstructor) {
                    setShowChronologyView(false);
                    setShowAssignedUserView(false);
                    setShowRoleView(false);
                } else {
                    if (showAssignedUserView) {
                        setShowAssignedUserView(true);
                    } else {
                        setShowAssignedUserView(false);
                    }
                    if (showRoleView) {
                        setShowRoleView(true);
                    } else {
                        setShowRoleView(false);
                    }
                    if (showChronologyView || isInDrawer) {
                        setShowChronologyView(true);
                    } else {
                        setShowChronologyView(false);
                    }
                }
                setShowSimplifiedView(false);
                setShowCalendarView(false);
                handleViewMenuClose();
                break;
            case CALENDAR:
                setShowSimplifiedView(false);
                setShowCalendarView(true);
                setShowAssignedUserView(false);
                setShowRoleView(false);
                setShowChronologyView(false);
                handleViewMenuClose();
                break;
            default:
                setShowSimplifiedView(true);
                setShowCalendarView(false);
                setShowAssignedUserView(false);
                handleViewMenuClose();
                break;
        }
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    /* const onViewToggle = (e) => {
        setShowSimplifiedView(e.target.checked);
    }; */

    return (
        <Box className={classes.actionContainer}>
            {/* Custom Task modal */}
            {openModal && (
                <CreateNewTaskModal
                    categories={categories}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    releaseDate={releaseDate}
                    requirementId={requirementId}
                    loadRequirementData={loadRequirementData}
                />
            )}

            {openTemplateModal && (
                <UpdateTemplateModal
                    open={openTemplateModal}
                    setOpen={setOpenTemplateModal}
                    categories={categories}
                    requirementId={requirementId}
                    loadRequirementData={loadRequirementData}
                    setRequirement={setRequirement}
                />
            )}

            {openCustomTemplateModal && (
                <CustomTemplateModal
                    open={openCustomTemplateModal}
                    setOpen={setOpenCustomTemplateModal}
                    requirementId={requirementId}
                    loadRequirementData={loadRequirementData}
                    existingTemplateId={existingTemplateId}
                />
            )}

            {/* Reset timeline modal */}
            {openResetTimelineModal && (
                <ResetTimelineModal
                    requirementId={requirementId}
                    loadRequirementData={loadRequirementData}
                    open={openResetTimelineModal}
                    setOpen={setOpenResetTimelineModal}
                    updateRequirement={updateRequirement}
                    showAssignedUserView={showAssignedUserView}
                    getAssignedUserGroupData={getAssignedUserGroupData}
                    fetchLogisticsByRole={fetchLogisticsByRole}
                    showRoleView={showRoleView}
                    showChronologyView={showChronologyView}
                    fetchLogisticsByChronology={fetchLogisticsByChronology}
                    filterBy={selectedUrgency}
                />
            )}

            {showCalendarView === false && (
                <GroupFilter
                    showAssignedUserView={showAssignedUserView}
                    setShowAssignedUserView={setShowAssignedUserView}
                    setSelectedCategory={setSelectedCategory}
                    showRoleView={showRoleView}
                    setShowRoleView={setShowRoleView}
                    setShowCalendarView={setShowCalendarView}
                    showCalendarView={showCalendarView}
                    setShowChronologyView={setShowChronologyView}
                    showChronologyView={showChronologyView}
                    setSelectedUrgency={setSelectedUrgency}
                    isInstructor={isInstructor}
                />
            )}

            {/* Category Filter */}
            {/* {!showAssignedUserView && ( */}
            {!showCalendarView && !showChronologyView && (
                <CategoryFilter
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    categories={categories}
                />
            )}
            {/* )} */}

            {showUrgencyFilter && (
                <UrgencyFilter
                    selectedUrgency={selectedUrgency}
                    setSelectedUrgency={setSelectedUrgency}
                    urgencies={urgencies}
                />
            )}

            <Button
                variant="outlined"
                color="secondary"
                style={{ marginLeft: 'auto' }}
                aria-label="view button"
                aria-controls="view-menu"
                aria-haspopup="true"
                onClick={handleViewMenuClick}
                startIcon={<GridOnIcon />}
            >
                Views
            </Button>

            {/* DIFFERENT VIEW MANU */}
            <Menu
                id="view-menu"
                anchorEl={viewAnchorEl}
                keepMounted
                open={Boolean(viewAnchorEl)}
                onClose={handleViewMenuClose}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {/* <MenuItem
                    onClick={() => {
                        onViewClick(SIMPLIFIED);
                    }}
                >
                    <ListItemIcon>
                        <ViewCompactIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">{SIMPLIFIED}</Typography>
                </MenuItem> */}
                <MenuItem
                    onClick={() => {
                        onViewClick(DETAILED);
                    }}
                >
                    <ListItemIcon>
                        <ViewComfyIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">{DETAILED}</Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onViewClick(CALENDAR);
                    }}
                >
                    <ListItemIcon>
                        <EventNoteIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">{CALENDAR}</Typography>
                </MenuItem>
            </Menu>
            {/* DIFFERENT VIEW MANU */}

            {readOnly === false && !isInDrawer && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onActionBtnClick}
                    aria-controls="logistic-action-menu"
                    aria-haspopup="true"
                    aria-label="action button"
                >
                    Actions
                </Button>
            )}

            {/* Menu */}
            <Menu
                id="logistic-action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem onClick={() => onMenuItemClick(EXPORT)}>
                    {EXPORT}
                </MenuItem>
                <MenuItem
                    onClick={() => onMenuItemClick(CUSTOM_TASK)}
                    disabled={
                        showAssignedUserView ||
                        showRoleView ||
                        showChronologyView
                    }
                >
                    {CUSTOM_TASK}
                </MenuItem>
                <MenuItem
                    onClick={() => onMenuItemClick(UPDATE_CATEGORY)}
                    disabled={
                        showAssignedUserView ||
                        showRoleView ||
                        showChronologyView
                    }
                >
                    {UPDATE_CATEGORY}
                </MenuItem>
                <MenuItem
                    onClick={() => onMenuItemClick(UPDATE_TEMPLATE)}
                    disabled={
                        showAssignedUserView ||
                        showRoleView ||
                        showChronologyView
                    }
                >
                    {UPDATE_TEMPLATE}
                </MenuItem>
                <MenuItem onClick={() => onMenuItemClick(RESET_TIMELINE)}>
                    {RESET_TIMELINE}
                </MenuItem>
            </Menu>
        </Box>
    );
};

export const OMLogisticSection = ({
    requirement,
    readOnly = false,
    setRequirement,
    loadRequirementData,
    isInstructor = false,
    starweaverAdmin,
    updateRequirement,
    allCollaborators,
    isInDrawer = false,
}) => {
    const { classes } = useStyles();
    const requirementSummary = useSelector((state) => state.requirementSummary);
    const setAlert = useSetRecoilState(alertState);
    const [filterBy, setFilterBy] = useState('isOverdue');
    const [showLogisticsSummary, setShowLogisticsSummary] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState(ALL);
    const [showSimplifiedView, setShowSimplifiedView] = useState(false);
    const [showCalendarView, setShowCalendarView] = useState(false);
    const [loadingCalendar, setLoadingCalendar] = useState(false);
    const [calendarData, setCalendarData] = useState([]);
    const [showAssignedUserView, setShowAssignedUserView] = useState(false);
    const {
        loading: assignedUserGroupDataLoading,
        taskCategories: categoriesByUsersAssigned,
        fetchData: getAssignedUserGroupData,
    } = useFetchTaskCategoriesByUserAssigned();
    const {
        downloadLogisticsData,
        loading: exportLoading,
    } = useExportLogisticsData();
    const [showRoleView, setShowRoleView] = useState(false);
    const {
        loading: fetchingLogisticsByRole,
        taskCategories: taskCategoriesByRole,
        fetchData: fetchLogisticsByRole,
    } = useFetchTaskCategoriesByRole();
    const {
        loading: fetchingLogisticsByChronology,
        fetchTaskCategoriesByChronology,
        taskCategories: taskCategoriesByChronology,
    } = useFetchTaskCategoriesByCronology();
    const [showChronologyView, setShowChronologyView] = useState(false);

    const getCalendarData = useCallback(async () => {
        try {
            const id = requirement._id;
            const baseURL = `/api/client-requirements/logistics-calendar-data`;
            const params = new URLSearchParams();
            params.append('id', id);
            const url = `${baseURL}?${params.toString()}`;
            setLoadingCalendar(true);
            const { data } = await axios.get(url);
            const { calendarData } = data;
            setCalendarData(() => {
                return Object.entries(calendarData.tasks).map(
                    ([taskName, taskInfo]) => {
                        return {
                            title: taskName,
                            start: moment(taskInfo.startDate).toDate(),
                            end: moment(taskInfo.endDate).toDate(),
                            backgroundColor: stringToColour(taskName),
                        };
                    }
                );
            });
            setLoadingCalendar(false);
        } catch (e) {
            const message = e?.response?.data?.message ?? e?.message;
            setAlert({ message, showF: true, severity: severity.ERROR });
            setLoadingCalendar(false);
        }
    }, [requirement._id, setAlert]);

    useEffect(() => {
        if (isInDrawer) {
            setShowChronologyView(true);
        }
    }, [isInDrawer]);

    useEffect(() => {
        if (showCalendarView && !showSimplifiedView) {
            getCalendarData();
        }
    }, [getCalendarData, showCalendarView, showSimplifiedView]);

    useEffect(() => {
        if (showAssignedUserView && !showRoleView) {
            getAssignedUserGroupData(requirement._id, filterBy);
        }
    }, [
        getAssignedUserGroupData,
        requirement._id,
        showAssignedUserView,
        showRoleView,
        filterBy,
    ]);

    useEffect(() => {
        if (showRoleView && !showAssignedUserView) {
            fetchLogisticsByRole(requirement._id, filterBy);
        }
    }, [
        fetchLogisticsByRole,
        getAssignedUserGroupData,
        requirement._id,
        showAssignedUserView,
        showRoleView,
        filterBy,
    ]);

    useEffect(() => {
        if (
            showChronologyView &&
            !showAssignedUserView &&
            !showCalendarView &&
            !showRoleView
        ) {
            fetchTaskCategoriesByChronology(requirement._id, filterBy);
        }
    }, [
        fetchTaskCategoriesByChronology,
        requirement._id,
        showAssignedUserView,
        showCalendarView,
        showChronologyView,
        showRoleView,
        filterBy,
    ]);

    const updateLogisticsSummaryVisibility = useCallback((category) => {
        // check if categoryName "E-LEARNING PRODUCTION" is in active
        if (category.categoryName === E_LEARNING_PRODUCTION) {
            if (category.isActive) {
                setShowLogisticsSummary(true);
            } else {
                setShowLogisticsSummary(false);
            }
        }
    }, []);

    const taskCategories = useMemo(() => {
        let taskCategories = requirement?.taskCategories;

        if (showSimplifiedView) {
            taskCategories = taskCategories.map((category) => {
                return {
                    ...category,
                    tasks: category.tasks.filter(
                        (task) => task?.isSimplified === true
                    ),
                };
            });
        }

        if (showAssignedUserView) {
            const categories = [...categoriesByUsersAssigned];
            const unassignedIdx = categories.findIndex(
                (category) =>
                    category.categoryName.toLowerCase() === 'unassigned'
            );

            if (unassignedIdx !== -1) {
                const unassigned = categories[unassignedIdx];
                categories.splice(unassignedIdx, 1);
                categories.push(unassigned);
            }

            taskCategories = categories;
        }

        if (showRoleView) {
            const categories = [...taskCategoriesByRole];
            const unassignedIdx = categories.findIndex(
                (category) =>
                    category.categoryName.toLowerCase() === 'unassigned'
            );

            if (unassignedIdx !== -1) {
                const unassigned = categories[unassignedIdx];
                categories.splice(unassignedIdx, 1);
                categories.push(unassigned);
            }

            taskCategories = categories;
        }

        if (showChronologyView) {
            taskCategories = taskCategoriesByChronology;
        }

        if (selectedCategory !== ALL) {
            taskCategories = taskCategories.filter(
                (category) => category.categoryName === selectedCategory
            );
        }

        const categories = !isInstructor
            ? [...taskCategories].filter(
                  (category) => category.tasks?.length > 0
              )
            : [
                  ...taskCategories?.map((category) => {
                      return {
                          ...category,
                          tasks: category.tasks.filter(
                              (task) => task?.isVisibleToInstructor !== false
                          ),
                      };
                  }),
              ].filter((category) => category.tasks?.length > 0);

        let taskNum = 0;

        /* for (const category of categories) {
            updateLogisticsSummaryVisibility(category);

            for (let task of category.tasks) {
                if (isInstructor && task?.isVisibleToInstructor === false)
                    continue;
                task.taskNum = taskNum;
                taskNum++;
            }
        } */

        return categories.map((category) => {
            updateLogisticsSummaryVisibility(category);
            return {
                ...category,
                tasks: category.tasks.map((task) => {
                    if (isInstructor && task?.isVisibleToInstructor === false) {
                        return { ...task };
                    } else {
                        ++taskNum;
                        return {
                            ...task,
                            taskNum,
                        };
                    }
                }),
            };
        });

        // return categories;
    }, [
        requirement?.taskCategories,
        showSimplifiedView,
        showAssignedUserView,
        showRoleView,
        showChronologyView,
        selectedCategory,
        isInstructor,
        categoriesByUsersAssigned,
        taskCategoriesByRole,
        updateLogisticsSummaryVisibility,
        taskCategoriesByChronology,
    ]);

    const showCategoryStatusBtn = useMemo(() => {
        if (
            showAssignedUserView ||
            showRoleView ||
            showCalendarView ||
            showChronologyView
        )
            return false;
        return true;
    }, [
        showAssignedUserView,
        showCalendarView,
        showChronologyView,
        showRoleView,
    ]);

    const showAssignedTo = useMemo(() => {
        if (
            isInstructor ||
            showAssignedUserView ||
            showRoleView ||
            showChronologyView ||
            showCalendarView
        )
            return false;
        return true;
    }, [
        isInstructor,
        showAssignedUserView,
        showCalendarView,
        showChronologyView,
        showRoleView,
    ]);

    const handleRefreshCalendar = () => {
        getCalendarData();
    };

    const getCategories = () => {
        if (showAssignedUserView) {
            return categoriesByUsersAssigned;
        }

        if (showRoleView) {
            return taskCategoriesByRole;
        }

        if (showChronologyView) {
            return taskCategoriesByChronology;
        }

        return requirement.taskCategories;
    };

    const handleExport = async () => {
        const payload = {
            categories: getCategories(),
            requirementId: requirement._id,
        };

        const blob = await downloadLogisticsData(payload);

        if (!blob) return;

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${requirement.title}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    return (
        <Box className={classes.logisticsContainer}>
            {!isInDrawer && (
                <ReleaseDate expectedEndDate={requirement?.expectedEndDate} />
            )}

            <Box mb={1}>
                <Typography variant="body1">
                    {requirement?.templateName ?? 'Template not selected'}
                </Typography>
            </Box>

            {/* {showLogisticsSummary && (
                <LogisticsSummary requirement={requirement} />
            )} */}
            {/* <Typography style={{ marginBottom: '1rem' }}>
                (<span style={{ color: 'red' }}>*</span>) Counts only weekdays
            </Typography> */}

            {/* Actions */}
            <Actions
                categories={
                    showAssignedUserView
                        ? categoriesByUsersAssigned
                        : showRoleView
                        ? taskCategoriesByRole
                        : showChronologyView
                        ? taskCategoriesByChronology
                        : requirement?.taskCategories
                }
                releaseDate={requirement?.expectedEndDate}
                requirementId={requirement?._id}
                loadRequirementData={loadRequirementData}
                setRequirement={setRequirement}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setShowSimplifiedView={setShowSimplifiedView}
                readOnly={readOnly}
                setShowCalendarView={setShowCalendarView}
                updateRequirement={updateRequirement}
                setShowAssignedUserView={setShowAssignedUserView}
                showAssignedUserView={showAssignedUserView}
                getAssignedUserGroupData={getAssignedUserGroupData}
                fetchLogisticsByRole={fetchLogisticsByRole}
                handleExport={handleExport}
                showRoleView={showRoleView}
                setShowRoleView={setShowRoleView}
                showCalendarView={showCalendarView}
                setShowChronologyView={setShowChronologyView}
                showChronologyView={showChronologyView}
                fetchLogisticsByChronology={fetchTaskCategoriesByChronology}
                urgencies={urgencies}
                setSelectedUrgency={setFilterBy}
                selectedUrgency={filterBy}
                isInDrawer={isInDrawer}
                isInstructor={isInstructor}
                existingTemplateId={requirement?.templateId ?? null}
            />

            {loadingCalendar && <LinearProgress color="secondary" />}

            {assignedUserGroupDataLoading && (
                <PageLoader open={assignedUserGroupDataLoading} />
            )}

            {fetchingLogisticsByRole && (
                <PageLoader open={fetchingLogisticsByRole} />
            )}

            {fetchingLogisticsByChronology && (
                <PageLoader open={fetchingLogisticsByChronology} />
            )}

            {exportLoading && <PageLoader open={exportLoading} />}

            {!showCalendarView && (
                <Box className={classes.categoryWrapper}>
                    {taskCategories.length > 0 ? (
                        <>
                            {taskCategories
                                ?.filter((category) => !category.isHidden)
                                ?.map((category) => {
                                    return (
                                        <Category
                                            key={category._id}
                                            category={category}
                                            setRequirement={setRequirement}
                                            loadRequirementData={
                                                loadRequirementData
                                            }
                                            readOnly={readOnly}
                                            isInstructor={isInstructor}
                                            starweaverAdmin={starweaverAdmin}
                                            requirement={requirement}
                                            showSimplifiedView={
                                                showSimplifiedView
                                            }
                                            allCollaborators={allCollaborators}
                                            showCategoryStatusBtn={
                                                showCategoryStatusBtn
                                            }
                                            showAssignedTo={showAssignedTo}
                                            showAssignedUserView={
                                                showAssignedUserView
                                            }
                                            getAssignedUserGroupData={
                                                getAssignedUserGroupData
                                            }
                                            fetchLogisticsByRole={
                                                fetchLogisticsByRole
                                            }
                                            showRoleView={showRoleView}
                                            showChronologyView={
                                                showChronologyView
                                            }
                                            fetchLogisticsByChronology={
                                                fetchTaskCategoriesByChronology
                                            }
                                            isInDrawer={isInDrawer}
                                            filterBy={filterBy}
                                        />
                                    );
                                })}
                        </>
                    ) : (
                        <NoData />
                    )}
                </Box>
            )}

            {showCalendarView && (
                <Paper elevation={2}>
                    <Box p={2}>
                        <Box
                            display={'flex'}
                            justifyContent={'flex-end'}
                            pr={1}
                        >
                            <Tooltip placement="top-start" title="Refresh">
                                <IconButton
                                    size="small"
                                    onClick={handleRefreshCalendar}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <LogisticCalendarView calendarViewData={calendarData} />
                    </Box>
                </Paper>
            )}
        </Box>
    );
};

const Category = ({
    category,
    setRequirement,
    loadRequirementData,
    readOnly,
    isInstructor,
    starweaverAdmin,
    requirement,
    showSimplifiedView,
    allCollaborators,
    showCategoryStatusBtn,
    showAssignedTo,
    showAssignedUserView,
    getAssignedUserGroupData,
    fetchLogisticsByRole,
    showRoleView,
    showChronologyView,
    fetchLogisticsByChronology,
    isInDrawer,
    filterBy,
}) => {
    const { classes } = useStyles();
    const [showTask, setShowTask] = useState(true);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [transitionDuration, setTransitionDuration] = useState(100);
    const cardRef = useRef(null);
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);
    const setAlert = useSetRecoilState(alertState);

    const updateTransitionDuration = useCallback((height) => {
        if (height >= 0 && height <= 350) {
            setTransitionDuration(100);
        } else if (height > 350 && height <= 500) {
            setTransitionDuration(150);
        } else if (height > 500 && height <= 700) {
            setTransitionDuration(200);
        } else if (height > 700 && height <= 900) {
            setTransitionDuration(250);
        } else {
            setTransitionDuration(300);
        }
    }, []);

    useEffect(() => {
        if (!category?.isActive) return setShowTask(false);

        const isAllTasksCompleted = category?.tasks?.every(
            (task) => task.status === COMPLETED
        );

        setShowTask(() => {
            if (isAllTasksCompleted) {
                return false;
            } else {
                return true;
            }
        });
    }, [category?.tasks, category?.isActive]);

    useEffect(() => {
        let willUnmount = false;

        if (willUnmount || !cardRef.current) return;

        function onResize() {
            const height = cardRef.current?.scrollHeight ?? 1600;
            updateTransitionDuration(height);
            setScrollHeight(height);
        }

        window.addEventListener('resize', onResize);

        return () => {
            willUnmount = true;
            window.removeEventListener('resize', onResize);
        };
    }, [updateTransitionDuration]);

    useEffect(() => {
        if (!cardRef.current) return;
        const height = cardRef.current?.scrollHeight ?? 1600;
        updateTransitionDuration(height);
        setScrollHeight(height);
    }, [updateTransitionDuration, category.tasks]);

    const isDraggable = useMemo(() => {
        if (showSimplifiedView || !showAssignedTo || isInDrawer) return false;
        if (starweaverAdmin) return true;
        return false;
    }, [showAssignedTo, showSimplifiedView, starweaverAdmin, isInDrawer]);

    const handleSort = async () => {
        const taskCategories = [...requirement.taskCategories];
        const currentCategoryIdx = taskCategories.findIndex(
            (taskCategory) => taskCategory._id === category._id
        );
        const currentCategory = taskCategories[currentCategoryIdx];
        const { tasks } = currentCategory;
        //remove and save the dragged task in a separate variable
        const draggedTask = tasks.splice(dragItem.current, 1)[0];
        tasks.splice(dragOverItem.current, 0, draggedTask);
        dragItem.current = null;
        dragOverItem.current = null;
        taskCategories[currentCategoryIdx].tasks = tasks;

        try {
            const url = '/api/client-requirements/reorder-tasks';

            const payload = {
                requirementId: requirement._id,
                taskCategories,
            };

            const { status } = await axios.post(url, payload);

            if (status === 200 && !isInDrawer) {
                setRequirement((prev) => {
                    return {
                        ...prev,
                        taskCategories,
                    };
                });
            } else {
                throw new Error("Something wen't worng");
            }
        } catch (e) {
            const message = e?.response?.data?.message ?? e?.message;
            setAlert({ show: true, severity: severity.ERROR, message });
        }
    };

    return (
        <Box className={classes.category}>
            <CategoryHeader
                categoryName={category.categoryName}
                showTask={showTask}
                setShowTask={setShowTask}
                isActive={category.isActive}
                categoryId={category._id}
                setRequirement={setRequirement}
                requirementId={requirement._id}
                tasks={category.tasks}
                readOnly={readOnly}
                showSimplifiedView={showSimplifiedView}
                showCategoryStatusBtn={showCategoryStatusBtn}
                isInDrawer={isInDrawer}
                loadRequirementData={loadRequirementData}
            />

            <Card
                elevation={3}
                ref={cardRef}
                style={{
                    maxHeight: showTask ? `${scrollHeight}px` : '0px',
                    transition: `max-height ${transitionDuration}ms linear`,
                }}
                className={classes.tableWrapper}
            >
                <table className={classes.table} style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: '5%',
                                    textAlign:
                                        !starweaverAdmin || showSimplifiedView
                                            ? 'left'
                                            : 'center',
                                }}
                            >
                                #
                            </th>
                            <th style={{ width: '20%', textAlign: 'left' }}>
                                Task
                            </th>
                            {!isInstructor &&
                                !showAssignedUserView &&
                                !isInDrawer && (
                                    <th style={{ width: '150px' }}>
                                        Assigned To
                                    </th>
                                )}

                            {!isInstructor &&
                                !showAssignedUserView &&
                                !isInDrawer && (
                                    <th style={{ width: '150px' }}>Role</th>
                                )}

                            <th className={classes.timelineCol}>Timeline</th>

                            <th style={{ textAlign: 'center' }}>Done/Due</th>

                            {starweaverAdmin && !readOnly && <th>Status</th>}
                            {starweaverAdmin && !isInDrawer && !readOnly && (
                                <th></th>
                            )}
                        </tr>
                    </thead>
                    <tbody className={classes.tBody}>
                        {category.tasks.map((task, idx) => {
                            return (
                                <React.Fragment key={task._id}>
                                    <tr
                                        draggable={isDraggable}
                                        onDragStart={(e) => {
                                            dragItem.current = idx;
                                        }}
                                        onDragEnter={(e) => {
                                            e.preventDefault();
                                            dragOverItem.current = idx;
                                        }}
                                        onDragEnd={handleSort}
                                        onDragOver={(e) => {
                                            e.dataTransfer.dropEffect = 'move';
                                            e.preventDefault();
                                        }}
                                    >
                                        <Task
                                            task={task}
                                            taskId={task._id}
                                            setRequirement={setRequirement}
                                            categoryId={category._id}
                                            loadRequirementData={
                                                loadRequirementData
                                            }
                                            readOnly={readOnly}
                                            isDraggable={isDraggable}
                                            requirement={requirement}
                                            isInstructor={isInstructor}
                                            starweaverAdmin={starweaverAdmin}
                                            allCollaborators={allCollaborators}
                                            showAssignedTo={showAssignedTo}
                                            showAssignedUserView={
                                                showAssignedUserView
                                            }
                                            getAssignedUserGroupData={
                                                getAssignedUserGroupData
                                            }
                                            fetchLogisticsByRole={
                                                fetchLogisticsByRole
                                            }
                                            showRoleView={showRoleView}
                                            showChronologyView={
                                                showChronologyView
                                            }
                                            fetchLogisticsByChronology={
                                                fetchLogisticsByChronology
                                            }
                                            isInDrawer={isInDrawer}
                                            filterBy={filterBy}
                                        />
                                    </tr>
                                    {task?.checkpointName && !isInstructor && (
                                        <Checkpoint
                                            task={task}
                                            tasks={category.tasks}
                                            releaseDate={
                                                requirement?.expectedEndDate
                                            }
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </Card>
        </Box>
    );
};

const CategoryHeader = ({
    categoryName,
    showTask,
    setShowTask,
    isActive,
    setRequirement,
    categoryId,
    requirementId,
    tasks,
    readOnly,
    showSimplifiedView,
    showCategoryStatusBtn,
    isInDrawer,
    loadRequirementData,
}) => {
    const { classes } = useStyles();
    const setAlert = useSetRecoilState(alertState);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isActive) {
            setShowTask(true);
        } else {
            setShowTask(false);
        }
    }, [isActive, setShowTask]);

    const headerBgClr = useMemo(() => {
        if (showSimplifiedView) return DEFAULT_CATEGORY_HEADER_BG_CLR;

        const allTasksCompleted = tasks.every(
            (task) => task.status === COMPLETED
        );

        if (allTasksCompleted) {
            // setShowTask(false);
            return GREEN;
        } else {
            return DEFAULT_CATEGORY_HEADER_BG_CLR;
        }
    }, [showSimplifiedView, tasks]);

    const handleShowTask = () => {
        setShowTask((prev) => !prev);
    };

    const handleCategoryActive = async () => {
        try {
            const payload = {
                categoryId,
                requirementId,
            };

            setLoading(true);

            let endPoint = isActive
                ? '/api/client-requirements/deactivate-category'
                : '/api/client-requirements/activate-category';

            await axios.post(endPoint, payload);
            if (!isInDrawer) {
                setRequirement((prevReq) => {
                    return {
                        ...prevReq,
                        taskCategories: prevReq?.taskCategories?.map(
                            (category) => {
                                if (category._id === categoryId) {
                                    return {
                                        ...category,
                                        isActive: !category.isActive,
                                    };
                                } else {
                                    return { ...category };
                                }
                            }
                        ),
                    };
                });
            }
            if (isInDrawer) {
                await loadRequirementData(requirementId);
            }
        } catch (e) {
            if (errorCodes.includes(e?.response?.status)) {
                setAlert({
                    show: true,
                    message: e?.response?.data?.message,
                    severity: severity.ERROR,
                });
            } else {
                setAlert({
                    show: true,
                    message: e.message,
                    severity: severity.ERROR,
                });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            className={classes.categoryHeader}
            style={{
                backgroundColor: isActive
                    ? headerBgClr
                    : DEFAULT_CATEGORY_HEADER_BG_CLR,
            }}
        >
            <Typography
                className={classes.categoryName}
                sx={{ fontSize: isInDrawer ? '1rem' : '1.2rem' }}
            >
                {categoryName}
            </Typography>

            {showCategoryStatusBtn && (
                <BootstrapTooltip
                    title={isActive ? 'Active' : 'Inactive'}
                    placement="top"
                >
                    {!loading ? (
                        <IconButton
                            className={classes.activateCategoryBtn}
                            onClick={handleCategoryActive}
                            disabled={readOnly}
                            size="large"
                        >
                            <CheckCircle
                                style={{ color: isActive ? DARK_GREEN : GRAY }}
                            />
                        </IconButton>
                    ) : (
                        <Box pr={1} style={{ marginLeft: 'auto' }}>
                            <CircularProgress color="secondary" size={22} />
                        </Box>
                    )}
                </BootstrapTooltip>
            )}

            {isActive ? (
                <BootstrapTooltip
                    title={showTask ? 'Collapse' : 'Expand'}
                    placement="top"
                >
                    <IconButton
                        className={classes.arrowBtn}
                        onClick={handleShowTask}
                        disabled={!isActive}
                        style={{
                            transform: showTask
                                ? 'rotate(-180deg)'
                                : 'rotate(0deg)',
                            transition: 'all 150ms linear',
                        }}
                        size="large"
                    >
                        <ArrowDropDownCircle className={classes.arrowIcon} />
                    </IconButton>
                </BootstrapTooltip>
            ) : (
                <IconButton
                    className={classes.arrowBtn}
                    disabled={!isActive}
                    style={{
                        transform: showTask
                            ? 'rotate(-180deg)'
                            : 'rotate(0deg)',
                        transition: 'all 150ms linear',
                    }}
                    size="large"
                >
                    <ArrowDropDownCircle className={classes.arrowIcon} />
                </IconButton>
            )}
        </Box>
    );
};

const Task = ({
    task,
    taskId,
    setRequirement,
    categoryId,
    loadRequirementData,
    readOnly,
    requirement,
    isDraggable,
    isInstructor,
    starweaverAdmin,
    allCollaborators,
    showAssignedTo,
    showAssignedUserView,
    getAssignedUserGroupData,
    fetchLogisticsByRole,
    showRoleView,
    showChronologyView,
    fetchLogisticsByChronology,
    isInDrawer,
    filterBy,
}) => {
    const { classes } = useStyles();
    const [metricDate, setMetricDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [isCompleted, setIsCompleted] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const setAlert = useSetRecoilState(alertState);
    const [statusUpdating, setStatusUpdating] = useState(false);
    const [daysUpdating, setDaysUpdating] = useState(false);
    const [isGrabbing, setIsGrabbing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [loadingAssignedTo, setLoadingAssignedTo] = useState(false);

    useEffect(() => {
        if (!requirement?.expectedEndDate) return;

        // ----------- calculation for metric date ------------
        const endDate = moment(requirement?.expectedEndDate);
        const daysIncludingWeekends = endDate.subtract(
            task.numOfDaysBeforeDelivery,
            'days'
        );
        /* const daysExcludingWeekend = skipWeekend(daysIncludingWeekends); */
        const daysExcludingWeekend = daysIncludingWeekends;
        setMetricDate(daysExcludingWeekend.format('YYYY-MM-DD'));
        // ----------- calculation for metric date ------------

        // ----------- calculation for start date ----------
        const duration = task?.duration ? task.duration : 0;
        const startDaysIncludingWeekends = daysIncludingWeekends.subtract(
            Math.abs(duration),
            'days'
        );
        /* const startDaysExcludingWeekends = skipWeekend(
            startDaysIncludingWeekends
        ); */
        const startDaysExcludingWeekends = startDaysIncludingWeekends;
        setStartDate(startDaysExcludingWeekends.format('YYYY-MM-DD'));
        // ----------- calculation for start date ----------
    }, [
        requirement?.expectedEndDate,
        task?.duration,
        task.numOfDaysBeforeDelivery,
    ]);

    useEffect(() => {
        const status = task?.status;

        if (status === COMPLETED) {
            setIsCompleted(true);
        } else {
            setIsCompleted(false);
        }
    }, [task?.status]);

    useEffect(() => {
        if (task?.roles?.length > 0) {
            const selectedRoles = roleList.filter((role) =>
                task.roles.includes(role.field)
            );

            if (selectedRoles.length) {
                setSelectedRoles(selectedRoles);
            }
        }
    }, [task?.roles]);

    const isOverDue = useMemo(() => {
        const currentDate = moment();
        const givenDate = moment(metricDate);
        return currentDate.isAfter(givenDate);
    }, [metricDate]);

    const isDone = useMemo(() => {
        if (isCompleted) return true;
        return false;
    }, [isCompleted]);

    const metricDateColor = useMemo(() => {
        if (isDone) return GREEN;
        if (isOverDue) return RED;
        return BLUE;
    }, [isDone, isOverDue]);

    const isReadOnly = useMemo(() => {
        if (!task?.isActive) return true;
        return false;
    }, [task?.isActive]);

    const getAssignedTooltip = useCallback((users) => {
        return users
            .map((user) => user?.firstName + ' ' + user?.lastName || '')
            .join(', ');
    }, []);

    const onCheckboxClick = async (e) => {
        try {
            const checked = e.target.checked;

            const payload = {
                taskId,
                requirementId: requirement._id,
                status: checked ? COMPLETED : null,
            };

            setStatusUpdating(true);

            await axios.post(
                '/api/client-requirements/set-task-status',
                payload
            );

            setIsCompleted(checked);

            /* setRequirement((prevReq) => {
                return {
                    ...prevReq,
                    taskCategories: prevReq?.taskCategories?.map((category) => {
                        return {
                            ...category,
                            tasks: category.tasks.map((task) => {
                                if (task._id === taskId) {
                                    return {
                                        ...task,
                                        status: checked ? COMPLETED : null,
                                    };
                                } else {
                                    return { ...task };
                                }
                            }),
                        };
                    }),
                };
            }); */
            await loadRequirementData(requirement._id);
            // if the current view is assigned user view
            if (showAssignedUserView && getAssignedUserGroupData) {
                await getAssignedUserGroupData(requirement._id);
            }

            if (showRoleView && fetchLogisticsByRole) {
                await fetchLogisticsByRole(requirement._id);
            }

            if (showChronologyView && fetchLogisticsByChronology) {
                await fetchLogisticsByChronology(requirement._id, filterBy);
            }
        } catch (e) {
            if (errorCodes.includes(e?.response?.status)) {
                setAlert({
                    show: true,
                    message: e?.response?.data?.message,
                    severity: severity.ERROR,
                });
            } else {
                setAlert({
                    show: true,
                    message: e.message,
                    severity: severity.ERROR,
                });
            }
        } finally {
            setStatusUpdating(false);
        }
    };

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const updateDueDate = async (e) => {
        const updatedNumOfDays = moment(requirement?.expectedEndDate).diff(
            moment(e.target.value),
            'days'
        );

        try {
            setIsLoading(true);
            const payload = {
                taskId,
                requirementId: requirement._id,
                days: updatedNumOfDays,
            };

            await axios.post(
                '/api/client-requirements/update-task-days',
                payload
            );

            if (!isInDrawer) {
                setRequirement((prevReq) => {
                    return {
                        ...prevReq,
                        taskCategories: prevReq?.taskCategories?.map(
                            (category) => {
                                return {
                                    ...category,
                                    tasks: category.tasks.map((task) => {
                                        if (task._id === taskId) {
                                            return {
                                                ...task,
                                                numOfDaysBeforeDelivery: updatedNumOfDays,
                                            };
                                        } else {
                                            return { ...task };
                                        }
                                    }),
                                };
                            }
                        ),
                    };
                });
            }

            if (isInDrawer) {
                await loadRequirementData(requirement._id);
            }

            setMetricDate(moment(e.target.value).format('YYYY-MM-DD'));

            if (showAssignedUserView && getAssignedUserGroupData) {
                await getAssignedUserGroupData(requirement._id, filterBy);
            }

            if (showRoleView && fetchLogisticsByRole) {
                await fetchLogisticsByRole(requirement._id, filterBy);
            }

            if (showChronologyView && fetchLogisticsByChronology) {
                await fetchLogisticsByChronology(requirement._id, filterBy);
            }
        } catch (e) {
            if (errorCodes.includes(e?.response?.status)) {
                setAlert({
                    show: true,
                    message: e?.response?.data?.message,
                    severity: severity.ERROR,
                });
            } else {
                setAlert({
                    show: true,
                    message: e.message,
                    severity: severity.ERROR,
                });
            }
        } finally {
            setDaysUpdating(false);
            setIsLoading(false);
        }
    };

    const debouncedUpdateDueDate = (e) => {
        const event = {
            target: { name: e.target.name, value: e.target.value },
        };
        if (timeoutID) {
            clearTimeout(timeoutID);
        }
        timeoutID = setTimeout(() => {
            updateDueDate(event).finally(() => (timeoutID = null));
        }, 500);
    };

    const handleChangeTaskRole = async (e) => {
        try {
            const updatedRoles = e.target.value;
            setSelectedRoles(updatedRoles);
            const payload = {
                taskId,
                categoryId,
                requirementId: requirement._id,
                roles: updatedRoles.map((role) => role.field),
            };
            setLoadingAssignedTo(true);
            await axios.post(
                '/api/client-requirements/update-task-roles',
                payload
            );
        } catch (e) {
            console.log(e);
        } finally {
            await loadRequirementData(requirement._id);
            setLoadingAssignedTo(false);
        }
    };

    return (
        <React.Fragment>
            <td>
                <Box
                    p={0}
                    m={0}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    {isDraggable && (
                        <IconButton size="small">
                            <DragIndicator
                                color="default"
                                onMouseDown={() => setIsGrabbing(true)}
                                onMouseUp={() => setIsGrabbing(false)}
                                onMouseOut={() => setIsGrabbing(false)}
                                style={{
                                    cursor: isGrabbing ? 'grabbing' : 'grab',
                                }}
                            />
                        </IconButton>
                    )}
                    {task?.taskNum}
                </Box>
            </td>
            <td
                style={{
                    textAlign: 'left',
                    width: '30%',
                }}
            >
                <Tooltip title={task?.typeOfTask} placement="top-start">
                    <Typography
                        style={{
                            color: task.isActive ? TEXT_CLR : LIGHT_GRAY,
                            textDecoration: task.isActive
                                ? 'none'
                                : 'line-through',
                        }}
                    >
                        {task.name}{' '}
                        {task?.pinToTop && (
                            <sup style={{ color: 'red' }}>*</sup>
                        )}
                    </Typography>
                </Tooltip>
            </td>
            {!isInstructor && !showAssignedUserView && !isInDrawer && (
                <td>
                    {loadingAssignedTo ? (
                        <CircularProgress size={25} color="secondary" />
                    ) : (
                        <TaskAssignedTo
                            task={task}
                            taskId={task._id}
                            setRequirement={setRequirement}
                            requirementId={requirement._id}
                            categoryId={categoryId}
                            loadRequirementData={loadRequirementData}
                            allCollaborators={allCollaborators}
                            disabled={
                                showRoleView ||
                                showAssignedUserView ||
                                showChronologyView ||
                                isInDrawer
                            }
                        />
                    )}
                </td>
            )}

            {!isInstructor && !showAssignedUserView && !isInDrawer && (
                <td>
                    <FormControl
                        sx={{ width: 180 }}
                        size="small"
                        disabled={loadingAssignedTo}
                    >
                        <Select
                            labelId="role-select"
                            id="role-select"
                            multiple
                            value={selectedRoles}
                            onChange={handleChangeTaskRole}
                            input={<OutlinedInput />}
                            renderValue={(selected) =>
                                selected.map((s) => s.label).join(', ')
                            }
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}
                            disabled={loadingAssignedTo}
                        >
                            {[
                                ...roleList,
                                {
                                    _id: '675177522ff785245b2ef952',
                                    label: 'Added Collaborators',
                                    field: 'addedCollaborators',
                                },
                            ].map((role) => (
                                <MenuItem
                                    key={role.field}
                                    value={role}
                                    disabled={loadingAssignedTo}
                                >
                                    <Checkbox
                                        checked={
                                            selectedRoles
                                                .map((r) => r.field)
                                                .indexOf(role.field) > -1
                                        }
                                        disabled={loadingAssignedTo}
                                    />
                                    <ListItemText primary={role.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </td>
            )}

            <td>
                {task.isActive && (
                    <TimelineCapsule
                        startDate={startDate}
                        endDate={metricDate}
                        duration={task?.duration ? task.duration : 0}
                        color={isReadOnly ? GRAY : metricDateColor}
                        isDone={isCompleted}
                        isOverDue={isOverDue}
                        expectedEndDate={requirement?.expectedEndDate}
                        taskId={taskId}
                        requirementId={requirement._id}
                        isTaskActive={task?.isActive}
                        setRequirement={setRequirement}
                        starweaverAdmin={starweaverAdmin && !readOnly}
                        showAssignedUserView={showAssignedUserView}
                        getAssignedUserGroupData={getAssignedUserGroupData}
                        showRoleView={showRoleView}
                        fetchLogisticsByRole={fetchLogisticsByRole}
                        showChronologyView={showChronologyView}
                        fetchLogisticsByChronology={fetchLogisticsByChronology}
                        filterBy={filterBy}
                        isInDrawer={isInDrawer}
                        loadRequirementData={loadRequirementData}
                    />
                )}
            </td>

            <td>
                {isCompleted === false && task.isActive && (
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '180px',
                            marginInline: 'auto',
                        }}
                    >
                        <TextField
                            id="doneStatus"
                            name="date"
                            type={'date'}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            value={metricDate}
                            // onChange={updateDueDate}
                            onChange={debouncedUpdateDueDate}
                            fullWidth
                            InputProps={{
                                style: {
                                    color: isReadOnly ? GRAY : metricDateColor,
                                },
                                readOnly: readOnly,
                                disabled:
                                    readOnly === true ? false : isReadOnly,
                            }}
                        />
                        {isLoading && (
                            <LinearProgress
                                color="secondary"
                                sx={{ width: '100%' }}
                            />
                        )}
                    </Box>
                )}
                {isCompleted && (
                    <Box>
                        <Typography
                            className={`${classes.doneText}`}
                            style={{
                                color: task.isActive ? GREEN : LIGHT_GRAY,
                            }}
                        >
                            Done
                        </Typography>
                        {task?.completedAt && (
                            <Typography variant="caption">
                                Completed:{' '}
                                {moment(task.completedAt).format('DD-MMM-YYYY')}
                            </Typography>
                        )}
                    </Box>
                )}
            </td>
            {starweaverAdmin && !readOnly && (
                <td>
                    <BootstrapTooltip
                        title={isCompleted ? 'Completed' : 'Incomplete'}
                        placement="top"
                    >
                        {!statusUpdating ? (
                            <Checkbox
                                checked={
                                    task.status === COMPLETED ? true : false
                                }
                                onChange={onCheckboxClick}
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                                disabled={isReadOnly || readOnly}
                            />
                        ) : (
                            <Box py={0} pl={0} style={{ paddingRight: '10px' }}>
                                <CircularProgress color="secondary" size={22} />
                            </Box>
                        )}
                    </BootstrapTooltip>
                </td>
            )}
            {starweaverAdmin && !isInDrawer && !readOnly && (
                <td>
                    <TaskMenu
                        anchorEl={anchorEl}
                        handleClose={handleCloseMenu}
                        taskId={task._id}
                        setRequirement={setRequirement}
                        requirementId={requirement._id}
                        categoryId={categoryId}
                        loadRequirementData={loadRequirementData}
                        isVisibleToInstructor={
                            task?.isVisibleToInstructor === false ? false : true
                        }
                        isCheckpoint={task?.checkpointName ? true : false}
                        task={task}
                        expectedEndDate={requirement?.expectedEndDate}
                        showAssignedUserView={showAssignedUserView}
                        getAssignedUserGroupData={getAssignedUserGroupData}
                        fetchLogisticsByRole={fetchLogisticsByRole}
                        showRoleView={showRoleView}
                        showChronologyView={showChronologyView}
                        fetchLogisticsByChronology={fetchLogisticsByChronology}
                        isInDrawer={isInDrawer}
                        filterBy={filterBy}
                    />

                    <IconButton
                        aria-controls="task-menu"
                        aria-haspopup="true"
                        onClick={handleOpenMenu}
                        size="large"
                    >
                        <MoreVert />
                    </IconButton>
                </td>
            )}
        </React.Fragment>
    );
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TaskMenu = ({
    anchorEl,
    handleClose,
    taskId,
    setRequirement,
    requirementId,
    categoryId,
    loadRequirementData,
    isVisibleToInstructor,
    isCheckpoint,
    task,
    expectedEndDate,
    showAssignedUserView,
    getAssignedUserGroupData,
    fetchLogisticsByRole,
    showRoleView,
    showChronologyView,
    fetchLogisticsByChronology,
    isInDrawer,
    filterBy,
}) => {
    const { classes } = useStyles();
    const setAlert = useSetRecoilState(alertState);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [confirmChecked, setConfirmChecked] = useState(false);
    const [newCheckpointName, setNewCheckpointName] = useState('');
    const [showEditTaskDialog, setShowEditTaskDialog] = useState(false);

    const changeActivationStatus = async (type) => {
        const endPoint =
            type === ACTIVATE
                ? '/api/client-requirements/activate-task'
                : '/api/client-requirements/deactivate-task';

        const payload = {
            taskId,
            requirementId,
        };

        try {
            await axios.post(endPoint, payload);

            //update state
            if (!isInDrawer) {
                setRequirement((prevReq) => {
                    return {
                        ...prevReq,
                        taskCategories: prevReq?.taskCategories?.map(
                            (category) => {
                                return {
                                    ...category,
                                    tasks: category.tasks.map((task) => {
                                        if (task._id === taskId) {
                                            return {
                                                ...task,
                                                isActive:
                                                    type === ACTIVATE
                                                        ? true
                                                        : false,
                                            };
                                        } else {
                                            return { ...task };
                                        }
                                    }),
                                };
                            }
                        ),
                    };
                });
            }

            if (showAssignedUserView && getAssignedUserGroupData) {
                await getAssignedUserGroupData(requirementId, filterBy);
            }

            if (showRoleView && fetchLogisticsByRole) {
                await fetchLogisticsByRole(requirementId, filterBy);
            }

            if (showChronologyView && fetchLogisticsByChronology) {
                await fetchLogisticsByChronology(requirementId, filterBy);
            }
        } catch (e) {
            if (errorCodes.includes(e?.response?.status)) {
                setAlert({
                    show: true,
                    message: e?.response?.data?.message,
                    severity: severity.ERROR,
                });
            } else {
                setAlert({
                    show: true,
                    message: e.message,
                    severity: severity.ERROR,
                });
            }
        } finally {
            //close the menu
            handleClose();
        }
    };

    const deleteTask = async () => {
        try {
            const baseURL = '/api/client-requirements/delete-task';
            const params = new URLSearchParams();
            params.append('requirementId', requirementId);
            params.append('categoryId', categoryId);
            params.append('taskId', taskId);
            const url = `${baseURL}?${params.toString()}`;
            const { data } = await axios.delete(url);
            setDeleting(true);
            await loadRequirementData(requirementId);
            setAlert({
                show: true,
                severity: severity.SUCCESS,
                message: data?.message,
            });
        } catch (e) {
            const message = e?.response?.data?.message ?? e?.message;
            setAlert({ show: true, severity: severity.ERROR, message });
        } finally {
            setDeleting(false);
            setConfirmOpen(false);
        }
    };

    const onDeleteClick = () => {
        setConfirmOpen(true);
    };

    const handleInstructorVisiblility = async (e) => {
        try {
            const checked = e.target.checked;

            const url =
                '/api/client-requirements/update-instructor-task-visibility';
            const payload = {
                taskId,
                requirementId,
                isVisible: checked,
            };

            const { data, status } = await axios.post(url, payload);

            if (status !== 200) throw new Error("Something wen't worng");

            const { message } = data;

            await loadRequirementData(requirementId);

            if (showAssignedUserView && getAssignedUserGroupData) {
                getAssignedUserGroupData(requirementId, filterBy);
            }

            if (showRoleView && fetchLogisticsByRole) {
                await fetchLogisticsByRole(requirementId, filterBy);
            }

            if (showChronologyView && fetchLogisticsByChronology) {
                await fetchLogisticsByChronology(requirementId, filterBy);
            }
            setAlert({ show: true, severity: severity.SUCCESS, message });
        } catch (e) {
            const message = e?.response?.data?.message ?? e?.message;
            setAlert({ show: true, severity: severity.ERROR, message });
        }
    };

    const onMenuItemClick = async (type) => {
        switch (type) {
            case ACTIVATE:
                changeActivationStatus(ACTIVATE);
                break;
            case DEACTIVATE:
                changeActivationStatus(DEACTIVATE);
                break;
            case DELETE_TASK:
                onDeleteClick();
                break;
            case SHOW_TO_INSTRUCTOR:
                handleInstructorVisiblility();
                break;
            default:
                break;
        }
    };

    const toggleCheckpoint = async (checked) => {
        try {
            const payload = {
                taskId,
                requirementId,
                isCheckpoint: checked,
                newCheckpointName: checked ? newCheckpointName : null,
            };

            const url = '/api/client-requirements/toggle-checkpoint';

            const { status, data } = await axios.post(url, payload);

            if (status === 200) {
                const { message } = data;
                if (!isInDrawer) {
                    setRequirement((requirement) => {
                        return {
                            ...requirement,
                            taskCategories: requirement.taskCategories.map(
                                (category) => {
                                    return {
                                        ...category,
                                        tasks: category.tasks.map((task) => {
                                            if (task._id === taskId) {
                                                return {
                                                    ...task,
                                                    checkpointName:
                                                        payload.newCheckpointName,
                                                };
                                            } else {
                                                return {
                                                    ...task,
                                                };
                                            }
                                        }),
                                    };
                                }
                            ),
                        };
                    });
                }

                if (showAssignedUserView && getAssignedUserGroupData) {
                    await getAssignedUserGroupData(requirementId, filterBy);
                }

                if (showRoleView && fetchLogisticsByRole) {
                    await fetchLogisticsByRole(requirementId, filterBy);
                }

                if (showChronologyView && fetchLogisticsByChronology) {
                    await fetchLogisticsByChronology(requirementId, filterBy);
                }

                setAlert({ show: true, severity: severity.SUCCESS, message });
            }
        } catch (e) {
            const message = e?.response?.data?.message ?? e?.message;
            setAlert({ show: true, severity: severity.ERROR, message });
        } finally {
            setConfirmChecked(false);
            setNewCheckpointName('');
        }
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const handleToggleCheckpoint = (e) => {
        const checked = e.target.checked;

        handleClose();

        if (checked) return setConfirmChecked(true);

        toggleCheckpoint(checked);
    };

    const onEditTask = () => {
        setShowEditTaskDialog(true);
        handleClose();
    };

    return (
        <>
            <ConfirmDialog
                onCancle={() => setConfirmOpen(false)}
                onConfirm={() => deleteTask()}
                open={confirmOpen}
                title="Are you sure you want to delete this task?"
                isLoading={deleting}
            />

            {showEditTaskDialog && (
                <EditTaskDialog
                    requirementId={requirementId}
                    task={task}
                    setShowEditTaskDialog={setShowEditTaskDialog}
                    showEditTaskDialog={showEditTaskDialog}
                    releaseDate={expectedEndDate}
                    categoryId={categoryId}
                    loadRequirementData={loadRequirementData}
                />
            )}

            <Dialog
                open={confirmChecked}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setConfirmChecked(false)}
                aria-labelledby="alert checkpoint toggle"
                aria-describedby="alert checkpoint toggle desc"
            >
                <DialogTitle id="alert checkpoint toggle"></DialogTitle>
                <DialogContent>
                    <Box className={classes.checkpointContent}>
                        <TextField
                            id="newCheckpointName"
                            name="newCheckpointName"
                            type="text"
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            label="New checkpoint name"
                            value={newCheckpointName}
                            onChange={(e) =>
                                setNewCheckpointName(e.target.value)
                            }
                            required
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmChecked(false)}
                        color="secondary"
                    >
                        Cancel
                    </Button>

                    <Button
                        onClick={() => toggleCheckpoint(true)}
                        color="secondary"
                        disabled={newCheckpointName.trim().length === 0}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Menu
                id="task-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem onClick={() => onMenuItemClick(ACTIVATE)}>
                    {ACTIVATE}
                </MenuItem>
                <MenuItem onClick={() => onMenuItemClick(DEACTIVATE)}>
                    {DEACTIVATE}
                </MenuItem>
                {!showAssignedUserView &&
                    !showRoleView &&
                    !showChronologyView && (
                        <MenuItem onClick={() => onMenuItemClick(DELETE_TASK)}>
                            {DELETE_TASK}
                        </MenuItem>
                    )}
                <MenuItem>
                    {SHOW_TO_INSTRUCTOR}
                    <Switch
                        checked={isVisibleToInstructor}
                        onChange={handleInstructorVisiblility}
                        color="secondary"
                        inputProps={{ 'aria-label': 'isVisibleToInstructor' }}
                        size="small"
                    />
                </MenuItem>

                <MenuItem>
                    {isCheckpoint ? REMOVE_CHECKPOINT : MARK_AS_CHECKPOINT}
                    <Switch
                        checked={isCheckpoint}
                        onChange={handleToggleCheckpoint}
                        color="secondary"
                        inputProps={{ 'aria-label': 'isCheckpoint' }}
                        size="small"
                    />
                </MenuItem>

                {!showAssignedUserView &&
                    !showRoleView &&
                    !showChronologyView && (
                        <MenuItem onClick={onEditTask}>{EDIT_TASK}</MenuItem>
                    )}
            </Menu>
        </>
    );
};

const Checkpoint = ({ task, tasks, releaseDate }) => {
    const { classes } = useStyles();

    const isCheckpointComplete = useMemo(() => {
        const currentTask = task;

        const foundTaskIdx = tasks.findIndex(
            (task) => task._id === currentTask._id
        );

        const completedTasks = tasks.slice(0, foundTaskIdx + 1);

        return completedTasks.every((task) => task.status === COMPLETED);
    }, [task, tasks]);

    const isAllOverDue = useMemo(() => {
        const currentTask = task;

        const foundTaskIdx = tasks.findIndex(
            (task) => task._id === currentTask._id
        );

        const tasksForCheckpoints = tasks.slice(0, foundTaskIdx + 1);

        for (const task of tasksForCheckpoints) {
            const currentDate = moment();

            const metricDate = moment(releaseDate).subtract(
                task.numOfDaysBeforeDelivery,
                'days'
            );

            if (!currentDate.isAfter(metricDate)) {
                return false;
            }
        }

        return true;
    }, [releaseDate, task, tasks]);

    const isAllNotOverDue = useMemo(() => {
        const currentTask = task;

        const foundTaskIdx = tasks.findIndex(
            (task) => task._id === currentTask._id
        );

        const tasksForCheckpoints = tasks.slice(0, foundTaskIdx + 1);

        for (const task of tasksForCheckpoints) {
            const currentDate = moment();

            const metricDate = moment(releaseDate).subtract(
                task.numOfDaysBeforeDelivery,
                'days'
            );

            if (currentDate.isAfter(metricDate)) {
                return false;
            }
        }

        return true;
    }, [releaseDate, task, tasks]);

    const checkpointBGColor = useMemo(() => {
        if (isCheckpointComplete) return GREEN;
        if (isAllOverDue) return RED;
        if (isAllNotOverDue) return BLUE;
        return ORANGE;
    }, [isAllNotOverDue, isAllOverDue, isCheckpointComplete]);

    return (
        <tr style={{ backgroundColor: checkpointBGColor, textAlign: 'left' }}>
            <td colSpan={8} style={{ fontWeight: '500' }}>
                <Box
                    display={'flex'}
                    alignItems={'flex-end'}
                    px={'1rem'}
                    style={{ gap: '1%' }}
                >
                    <GolfCourseIcon /> {task.checkpointName}
                </Box>
            </td>
        </tr>
    );
};

const ReleaseDate = ({ expectedEndDate }) => {
    const { classes } = useStyles();

    return (
        <Typography className={classes.deliveryDate}>
            Release Date:{' '}
            {expectedEndDate
                ? moment(expectedEndDate).format('DD-MMM-YYYY')
                : 'N/A'}
        </Typography>
    );
};

function NoData() {
    const { classes } = useStyles();
    return (
        <Box className={classes.noDataContainer}>
            <img src={EmptyImage} alt="no data" height="200" width="200" />
            <Typography variant="body1">No tasks present</Typography>
        </Box>
    );
}
