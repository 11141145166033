import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import {
    Box,
    Button,
    FormControlLabel,
    ListSearchAndSelect,
    TextField,
    Typography,
    Checkbox,
    Container,
    Loading,
    CircularProgress,
} from '../ui';
import { alertState, severity } from '../app/recoil';
import { TagsSearchAndSelect } from '../on-air/TagsSearchAndSelect';
import { TagsSearchAndSelect2 } from '../on-air/TagsSearchAndSelect2';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from 'tss-react/mui';
import { searchedData } from '../instructor-onboarding/searchedData';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import { ProfileMenu } from './ProfileMenu';
import { useInstructorTags } from './useInstructorTags';
import { updateExpertise } from '../instructor-onboarding/slices/instructorOnboarding/instructorExpertiseSlice';
import { capitalizeFirstLetter } from '../util';
import {
    instructorTopicsState,
    instructorSelectedTopicsState,
    instructorAddedSearchTermsState,
    instructorRolesState,
} from './recoil';
import PersonaSearchAndSelect from '../persona/PersonaSearchAndSelect';
import { useInstructorProfile } from './useInstructorProfile';

const useStyles = makeStyles()((theme) => ({
    dashBoardHeading: {
        width: '600px',
        margin: '40px auto',
    },

    backBtnContainer: {
        width: 150,
        marginLeft: '20%',
        fontSize: '5px',
    },
    leftRightMainContainer: {
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            display: 'block',
        },
    },
    leftMainContainer: {
        width: 300,
        [theme.breakpoints.down('lg')]: {
            width: 400,
            margin: '20px auto',
        },
    },
    rightMainContainer: {
        width: 800,
        [theme.breakpoints.down('lg')]: {
            width: 500,
            margin: '20px auto',
        },
    },
    subject: {
        width: '100%',
        marginTop: '40px',
        border: '1px solid #CECDCD',
        padding: '20px',
        borderRadius: '4px',
    },
    display: {
        width: '100%',
        marginTop: '40px',
        border: '1px solid #CECDCD',
        padding: '20px',
        borderRadius: '4px',
    },

    searchContainer: {
        display: 'flex',
        marginBottom: 30,
    },
    addButton: {
        height: 56,
        marginLeft: 20,
    },
}));

export const ProfileExperties = ({
    setComp,
    setOnboardingData,
    userDetails,
    // user,
    isAdmin,
    onBack,
    afterSave,
    saveBtnText,
    showPersona = false,
}) => {
    const { classes } = useStyles();
    let navigate = useNavigate();
    const status_1 = useRef(false);
    const setAlert = useSetRecoilState(alertState);
    const setAlertState = useSetRecoilState(alertState);
    const [edit, setEdit] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const dispatch = useDispatch();
    const [selectedTopics, setSelectedTopics] = useState([]);
    const { selectedPersonas, setSelectedPersonas } = useInstructorProfile(
        userDetails
    );
    /* instructorTopicsState */

    // const [savedSelectedTopics, setSavedSelectedTopics] = useRecoilState(
    //     instructorSelectedTopicsState
    // );
    const [savedAddedSearchTerms, setSavedAddedSearchTerms] = useRecoilState(
        instructorAddedSearchTermsState
    );

    const [savedRoles, setSavedRoles] = useRecoilState(instructorRolesState);

    // const [disp, setDisplaySub] = useState(savedTopics);
    // console.log(disp);

    // const [selectedTopics, setSelectedTopics] = useState(savedSelectedTopics);

    // const {
    //     // isLoading,
    //     tags: selectedTopics,
    //     setTags: setSelectedTopics,
    // } = useInstructorTags(userDetails);
    const [addedSearchTerms, setAddedSearchTerms] = useState(
        // savedAddedSearchTerms
        []
    );

    // const [fieldData, setFieldData] = useState('');
    const [addMore, setAddmore] = useState('');
    const [roles, setRoles] = useState(savedRoles);
    const [skillErr, setSkillErr] = useState({ error: false, message: '' });
    // const [suggestedData, setSuggestedData] = useState([]);

    // useEffect(() => {
    //     handleChange();
    // }, []);

    // console.log(roles);

    useEffect(() => {
        setSkillErr({ error: false, message: '' });
    }, [selectedTopics.length]);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const res = await axios.get(
                    `/api/instructors/profile${
                        isAdmin ? `?e=${userDetails.user_email}` : ''
                    }`
                );

                const topics =
                    res.data.instructor_details?.topics?.map(
                        ({ name, id }) => ({
                            _id: id,
                            id,
                            term_id: id,
                            name,
                        })
                    ) || [];

                setSelectedTopics(topics);
                const payload = { expertise: topics };
                dispatch(updateExpertise(payload));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        })();
    }, [dispatch, isAdmin, userDetails.user_email]);

    const handleChange = (event) => {
        if (event.target.checked === true) {
            setRoles([...roles, event.target.value]);
        } else {
            setRoles(roles.filter((item) => item !== event.target.value));
        }
    };

    // const addMoreHandler = (e) => {
    //     setAddmore(e.target.value);
    // };

    // const removeSub = (z) => {
    //     setDisplaySub(disp.filter((b) => b !== z));
    // };

    /* --- VALIDATE INPUT --- */
    const validateInput = () => {
        const errors = [];
        const topicCount = selectedTopics.length + addedSearchTerms.length;

        if (topicCount < 10) {
            errors.push({ message: 'Please select at least 10 skills.' });
        }

        if (selectedPersonas.length === 0) {
            errors.push({ message: 'Select at least one Persona.' });
        }

        return { isError: errors.length > 0, errors };
    };

    const save = async () => {
        try {
            const { errors, isError } = validateInput();

            if (isError) {
                const { message } = errors[0];
                setSkillErr({
                    error: true,
                    message,
                });
                setAlert({ show: true, message, severity: severity.WARNING });
                return;
            }

            setSaving(true);

            const payload = {
                topics: [
                    ...selectedTopics.map(({ name, term_id }) => ({
                        name: capitalizeFirstLetter(name),
                        id: term_id,
                    })),
                    ...addedSearchTerms.map((name) => ({
                        name: capitalizeFirstLetter(name),
                        id: name,
                    })),
                ],
                roles,
                e: isAdmin ? userDetails.user_email : null,
            };

            await axios.post('/api/job-roles/set-instructor-job-roles', {
                userId: userDetails._id,
                jobRoleIds: selectedPersonas.map((persona) => persona._id),
            });
            const res = await axios.put(`/api/instructors/profile`, payload);
            const dispatchPayload = { expertise: payload.topics };
            dispatch(updateExpertise(dispatchPayload));
            setSavedRoles(roles);
            setSaving(false);

            if (afterSave) afterSave();

            if (res.status === 200 || res.status === 201) {
                setAlertState({
                    show: true,
                    severity: severity.SUCCESS,
                    message: 'Services & Expertise saved successfully',
                });
            }
        } catch (e) {
            setAlertState({
                show: true,
                severity: severity.ERROR,
                message: 'Something went wrong',
            });
        } finally {
            setSaving(false);
        }
    };

    // const searchSuggestHandler = (e) => {
    //     const searchedValue = e.target.value;
    //     setFieldData(e.target.value);

    //     if (searchedValue.length >= 1) {
    //         const filteredData = searchedData.filter((x) => {
    //             return x.name
    //                 .toLowerCase()
    //                 .includes(searchedValue.toLowerCase());
    //         });
    //         setSuggestedData([...filteredData]);
    //     } else {
    //         setSuggestedData([]);
    //     }
    // };

    // const onAdd = () => {
    //     setDisplaySub((prev) => [...prev, fieldData]);
    //     setSuggestedData([]);
    //     setFieldData('');
    // };

    // const onAddFromDropDownHandler = (myVal) => {
    //     console.log(myVal);
    //     if (!disp.map((m) => m).includes(myVal.name)) {
    //         setDisplaySub((prev) => [...prev, myVal.name]);
    //         setSuggestedData([]);
    //         setFieldData('');
    //     } else {
    //         setSuggestedData([]);
    //         setFieldData('');
    //     }
    // };

    // const handleTopicSelect = (item) => {
    //     //console.log(item);
    //     setSelectedTopics((prevList) => {
    //         if (prevList.findIndex((elem) => elem.id === item.id) === -1) {
    //             return [item, ...prevList];
    //         } else {
    //             return prevList;
    //         }
    //     });
    // };

    // const handleTopicDelete = (itemToDelete) => {
    //     setSelectedTopics((items) =>
    //         items.filter((item) => item.id !== itemToDelete.id)
    //     );
    // };

    const enableEdit = () => {
        setEdit(true);
    };

    const cancleEdit = () => {
        // setEdit(false);
        // setDisplaySub(savedTopics);
        // setSelectedTopics(savedSelectedTopics);
        // setAddedSearchTerms(savedAddedSearchTerms);
        setRoles(savedRoles);
    };

    if (isLoading) return <Loading />;
    return (
        <>
            {/* <Box>
                <FormControlLabel
                    checked={roles.includes('Teaching') ? true : false}
                    control={
                        <Checkbox
                            onChange={handleChange}
                            name="teaching"
                            disabled={!edit}
                        />
                    }
                    label="Teaching"
                    value="Teaching"
                />
                <FormControlLabel
                    checked={roles.includes('Consulting') ? true : false}
                    control={
                        <Checkbox
                            name="consulting"
                            onChange={handleChange}
                            disabled={!edit}
                        />
                    }
                    label="Consulting"
                    value="Consulting"
                />
                <FormControlLabel
                    checked={roles.includes('Mentoring') ? true : false}
                    control={
                        <Checkbox
                            name="Mentoring"
                            onChange={handleChange}
                            disabled={!edit}
                        />
                    }
                    label="Mentoring"
                    value="Mentoring"
                />
                <FormControlLabel
                    checked={roles.includes('Coaching') ? true : false}
                    control={
                        <Checkbox
                            name="coaching"
                            onChange={handleChange}
                            disabled={!edit}
                        />
                    }
                    label="Coaching"
                    value="Coaching"
                />
            </Box> */}

            {/* <TagsSearchAndSelect
                title="Expertise"
                subTitle="Select 10 to 20 skills to help our algorithms match you with opportunities"
                selectedTags={selectedTopics}
                setSelectedTags={setSelectedTopics}
                addedSearchTerms={addedSearchTerms}
                setAddedSearchTerms={setAddedSearchTerms}
                skillErr={skillErr}
                setSkillErr={setSkillErr}
            /> */}
            <TagsSearchAndSelect2
                title="Expertise"
                subTitle="Select 10 to 20 skills to help our algorithms match you with opportunities"
                selectedTags={selectedTopics}
                setSelectedTags={setSelectedTopics}
                addedSearchTerms={addedSearchTerms}
                setAddedSearchTerms={setAddedSearchTerms}
                skillErr={skillErr}
                setSkillErr={setSkillErr}
            />

            {/* <ListSearchAndSelect
                heading="Subject, Category, Topic"
                list={searchedData}
                selectedList={selectedTopics}
                handleItemSelect={handleTopicSelect}
                handleItemDelete={handleTopicDelete}
                canAddSearchTerm
                addedSearchTerms={addedSearchTerms}
                setAddedSearchTerms={setAddedSearchTerms}
            /> */}
            {/* <Box
                style={{
                    position: 'relative',
                    marginTop: '20px',
                }}
            >
                <Box className={classes.searchContainer}>
                    <TextField
                        id="outlined-basic"
                        label="Search Here"
                        variant="outlined"
                        color="secondary"
                        value={fieldData}
                        onChange={searchSuggestHandler}
                        fullWidth
                        disabled={!edit}
                    />
                    <Button
                        onClick={onAdd}
                        variant="contained"
                        color="primary"
                        className={classes.addButton}
                        disabled={fieldData == '' || !edit}
                    >
                        <>Add</>
                    </Button>
                </Box>

                <Box
                    style={{
                        position: 'absolute',
                        top: 57,
                        zIndex: 200,
                        maxHeight: 200,
                        width: 200,
                        overflow: 'auto',
                        // boxShadow: '0px 0px 6px #888888',

                        paddingBottom: 0,
                        paddingTop: 0,
                        boxSizing: 'border-box',
                    }}
                >
                    <List
                        style={{
                            backgroundColor: '#ffffff',
                            paddingBottom: 0,
                            paddingTop: 0,
                        }}
                    >
                        {suggestedData.map((val, index) => {
                            return (
                                <ListItem
                                    onClick={() => {
                                        onAddFromDropDownHandler(val);
                                    }}
                                    key={val.id}
                                    button
                                >
                                    <ListItemText primary={val.name} />
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Box> */}
            {/* <Box>
                <Box className={classes.display}>
                    <Typography
                        variant="h4"
                        style={{ color: '#C4C4C4', fontSize: '20px' }}
                    >
                        Subject, Category, Topic
                    </Typography>
                    <Typography
                        variant="body2"
                        style={{
                            marginTop: '10px',
                            color: '#C4C4C4',
                            marginBottom: '20px',
                        }}
                    >
                        Type keywords to identify the subjects, categories and
                        topics for your expertise.
                    </Typography>

                    {disp.map((myVal) => {
                        return (
                            <Button
                                style={{
                                    margin: '5px 10px',
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeSub(myVal);
                                }}
                                key={myVal}
                                variant="contained"
                                color="secondary"
                                startIcon={<ClearIcon />}
                                disabled={!edit}
                            >
                                {myVal}
                            </Button>
                        );
                    })}
                </Box>
            </Box> */}
            {showPersona && (
                <Box mt={4}>
                    <PersonaSearchAndSelect
                        selectedPersonas={selectedPersonas}
                        setSelectedPersonas={setSelectedPersonas}
                    />
                </Box>
            )}
            <Box display="flex" mt={4}>
                {onBack && (
                    <Button
                        onClick={onBack}
                        variant="contained"
                        color="primary"
                    >
                        Back
                    </Button>
                )}

                <Box style={{ marginLeft: 'auto' }}>
                    <Button
                        onClick={() => {
                            if (edit) save();
                            else enableEdit();
                        }}
                        variant="contained"
                        color="secondary"
                        disabled={saving}
                    >
                        {saving ? (
                            <CircularProgress size="1.9em" />
                        ) : edit ? (
                            <>{saveBtnText || 'Save'}</>
                        ) : (
                            <>Edit</>
                        )}
                    </Button>
                </Box>
            </Box>
        </>
    );
};
