import { Hero } from '../ui/CustomUI';
import { Divider, Box, Button } from '../ui';
import { Heading } from './Heading';
import { Features } from './Features';
import { Navigation } from './Navigation';
import { makeStyles } from 'tss-react/mui';
import {
    ORGANISATION_PAGE_IMAGE_ONE_URL,
    ORGANISATION_PAGE_IMAGE_TWO_URL,
} from '../img';
import { Information } from './Information';
import {
    features,
    pros,
    heroValue,
    headingOne,
    training,
    headingThree,
    headingFour,
} from './CardsContent';

import { meetTheGurusRoute as route } from '../seo/routeMetaData';
import { HelmetWrapper } from '../seo';

export const ForOrganisation = () => {
    const { classes } = useStyles();

    return (
        <div>
            <HelmetWrapper route={route} />
            <Hero heroValue={heroValue} />
            <div className={classes.container}>
                <Box className={classes.btnGroup}>
                    <Navigation />
                    <Button
                        className={classes.btn}
                        color="secondary"
                        variant="contained"
                    >
                        Free Trial
                    </Button>
                </Box>
                <Information
                    title={headingOne.title}
                    subtitle={headingOne.subtitle}
                    body={headingOne.body}
                    img={ORGANISATION_PAGE_IMAGE_ONE_URL}
                />
                <Divider />
                <Heading
                    title={training.title}
                    subtitle={training.subtitle}
                    body={training.body}
                />
                <Divider />
                <Features cards={features} />
                <Divider />
                <Heading
                    title={headingThree.title}
                    subtitle={headingThree.subtitle}
                    body={headingThree.body}
                />
                <Features cards={pros} />
                <Divider />
                <Information
                    title={headingFour.title}
                    subtitle={headingFour.subtitle}
                    body={headingFour.body}
                    isButtonRequired={false}
                    img={ORGANISATION_PAGE_IMAGE_TWO_URL}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles()((theme) => ({
    container: {
        width: '95%',
        margin: '0 auto',
    },
    btnGroup: {
        width: 'fit-content',
        margin: '30px auto',
        display: 'flex',
        flexFlow: 'row no-wrap',
    },
    btn: {
        margin: '0 20px',
    },
}));
