import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
    // CircularProgress,
    FormControlLabel,
    IconButton,
} from '../ui';
import { DragIndicatorIcon, AddIcon, CloseIcon } from '../icons';

import { ClientRequirementsInstructorDetailItem } from './ClientRequirementsInstructorDetailItem';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {
        // minWidth: '50vw',
    },
    content: {
        padding: 50,
    },
    itemContainer: {
        marginBottom: 20,
    },
    closeBtn: {
        position: 'absolute',
        top: '10px',
        right: '20px',
    },
}));

export const ClientRequirementCreateFormSampleOutlineInstructors = ({
    open,
    onClose,
    instructors,
}) => {
    const { classes } = useStyles();
    // function handleClose() {
    //     setOpen(false);
    // }

    // const [jobRoles, setJobRoles] = useState([]);
    // const [isLoading, setIsLoading] = useState(true);
    // useEffect(() => {
    //     (async () => {
    //         try {
    //             setIsLoading(true);

    //             const res = await axios.get(
    //                 `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/instructor?domain=data-science`
    //             );

    //             setJobRoles(res.data?.slice(0, 5) || []);
    //             setIsLoading(false);
    //         } catch (error) {}
    //     })();
    // }, []);

    return (
        <Dialog
            disableScrollLock
            open={open}
            onClose={onClose}
            className={classes.root}
            fullWidth={true}
        >
            <DialogTitle>Instructors</DialogTitle>
            <IconButton
                className={classes.closeBtn}
                aria-label="close"
                onClick={onClose}
                size="large"
            >
                <CloseIcon />
            </IconButton>
            <DialogContent className={classes.content}>
                {instructors?.map(
                    ({ title, thumbnail, slug, description }, idx) => (
                        <Box className={classes.itemContainer}>
                            {' '}
                            <ClientRequirementsInstructorDetailItem
                                slug={slug}
                                thumbnail={thumbnail}
                                title={title}
                                description={description}
                                // backgroundImg={backgroundImg}
                                horizontalLayout={true}
                            />
                        </Box>
                    )
                )}
            </DialogContent>

            {/* <DialogActions>
        {checkbox && (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={check}
                        onChange={() => setCheck(!check)}
                    />
                }
                label="Check to continue"
            />
        )}
        <Button onClick={onClose} color="secondary" variant="outlined">
            Cancel
        </Button>
        <Button
            onClick={onConfirm}
            color="secondary"
            variant="contained"
            disabled={checkbox ? check : false}
            autoFocus
        >
            Confirm
        </Button>
    </DialogActions> */}
        </Dialog>
    );
};
