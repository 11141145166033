const axios = require('axios');

export const getSSOAnalyticsURL = async (successPath, errorPath) => {
    try {
        const response = await axios.get(`/api/sso/analytics`, {
            params: {
                successPath,
                errorPath,
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error making the API call:', error);
        throw error;
    }
};
