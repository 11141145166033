import { Grid, Button } from '../ui';
import { Heading } from './Heading';
import { makeStyles } from 'tss-react/mui';

export const Information = ({
    title,
    subtitle,
    body,
    isButtonRequired = true,
    img,
}) => {
    const { classes } = useStyles();

    return (
        <Grid
            container
            alignItems="center"
            className={classes.container}
            spacing={2}
        >
            <Grid item container xs={12} md={5}>
                <Grid item xs={12}>
                    <Heading title={title} subtitle={subtitle} body={body} />
                </Grid>
                {isButtonRequired && (
                    <Grid item xs={12}>
                        <Button
                            className={classes.btn}
                            variant="outlined"
                            color="secondary"
                        >
                            Get a Demo
                        </Button>
                        <Button
                            className={classes.btn}
                            variant="outlined"
                            color="secondary"
                        >
                            Watch Video
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} md={7}>
                <img className={classes.img} src={img} alt="Description" />
            </Grid>
        </Grid>
    );
};
const useStyles = makeStyles()((theme) => ({
    container: {
        margin: '50px auto',
    },
    btn: {
        margin: '20px 40px 20px 0',
    },
    img: {
        width: '100%',
    },
}));
