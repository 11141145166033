import { useEffect, useRef } from 'react';

export const useEffectSkipFirst = (callback, dependencyArray) => {
    const isFirstRunRef = useRef(true);
    useEffect(() => {
        if (isFirstRunRef.current) {
            isFirstRunRef.current = false;
            return;
        }
        callback();
    }, dependencyArray);
};
