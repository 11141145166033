import { useEffect, useMemo, useState } from 'react';
import { Box, Container, Grid, HeadingSection, NavigationTab } from '../ui';
import { useStyles } from './styles';
import { SpeedIcon } from '../icons';
import { useParams } from 'react-router-dom';
import { BackFloatButton } from '../my-account/BackFloatButton';

import { Content } from './Content';
// import { Banner } from './Banner.js';

import { useCurrentUser } from '../my-account/useCurrentUser';

import { useGetSaved } from './useGetSaved';

import { useGetTopCourses } from './useGetTopCourses';
import { useEnrolledOnDemand } from './useEnrolledOnDemand';
import { useEnrolledPrivateOnDemand } from './useEnrolledPrivateOnDemand';

import { dashboardRoute as route } from '../seo/routeMetaData';
import { HelmetWrapper } from '../seo';
import { useGetSavedRsvps } from '../dashboard';

import axios from 'axios';
import Leaderboard from '../my-account/Leaderboard';
import NotificationsPage from '../notifications/NotificationsPage';
import { Button, Dialog } from '@mui/material';
import { StardustSection } from '../my-account/StardustSection';
import { UserAvatar } from '../my-account/UserAvatar';
import { useRecoilValue } from 'recoil';
import { userState } from '../my-account/Recoil/State';
import { AdminContainer } from '../admin/AdminContainer';

export const DashboardPage = ({ socket, isInstructorRoute, isAdminRoute }) => {
    const { user, isLoading: isUserLoading, setUser } = useCurrentUser();
    const _user = useRecoilValue(userState);
    const [name, setName] = useState('');
    const { section /* , subSection */ } = useParams();
    // const { privateCourse } = useGetPrivateClasses(user);
    const { savedCourses } = useGetSaved(user);
    // GET THE ON-DEMAND COURSES
    const tags = 'react'; //TAGS CAN BE CHANGED LATER
    const topCourses = useGetTopCourses({ user, tags });
    const onDemandHeading = (
        <>
            Here are some top picks for you{' '}
            <strong>{user && user.user_firstName}</strong>
        </>
    );

    const {
        enrolledOnDemand,
        loading: isEnrolledCoursesLoading,
    } = useEnrolledOnDemand(user);

    const {
        privateOnDemand,
        // loading: isPrivateCoursesLoading,
    } = useEnrolledPrivateOnDemand(user);

    const userOnAir = useGetSavedRsvps(user);
    const { classes } = useStyles();

    var [courseHours, setCourseHours] = useState(() => new Map());
    var [courseMin, setCourseMin] = useState(() => new Map());
    var [domain, setDomain] = useState(() => new Map());
    const [showStardust, setShowStardust] = useState(false);

    const userEmailDomain = user ? user.user_email.split('@').pop() : null;

    const getCourseDomainDetail = async () => {
        // const res = await axios.get(
        //     `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/catalogue/lengths?nowprocket=1`
        // );

        const res = await axios.get(`/api/courses?thinkific=1&type=ON-DEMAND`);
        setCourseHours(
            new Map(
                res.data.courses?.map((key) => [
                    key.thinkificCourseId,
                    key.hours,
                ])
            )
        );
        setCourseMin(
            new Map(
                res.data.courses?.map((key) => [
                    key.thinkificCourseId,
                    key.minutes,
                ])
            )
        );
        setDomain(
            new Map(
                res.data.courses?.map((key) => [
                    key.thinkificCourseId,
                    key.domain,
                ])
            )
        );
    };

    useEffect(() => {
        getCourseDomainDetail();
    }, []);

    useEffect(() => {
        if (!_user) return;

        setName(
            _user.user_firstName ? _user.user_firstName : _user.display_name
        );
    }, [_user]);

    const tabValues = useMemo(() => {
        return [
            {
                name: `Content`,
                slug: 'content',
                component: courseMin && courseHours && (
                    <Content
                        // section={subSection}
                        user={user}
                        savedCourses={savedCourses}
                        enrolledOnDemand={enrolledOnDemand}
                        privateOnDemand={privateOnDemand}
                        userOnAir={userOnAir}
                        socket={socket}
                    />
                ),
                noContainer: true,
                //hideTab: user?.user_current_plan !== astronaut_id,
            },

            {
                name: 'Leaderboards',
                slug: 'leaderboard',
                component: <Leaderboard />,
                //hideTab: true,
            },

            {
                name: 'Notifications',
                slug: 'notifications',
                component: (
                    <NotificationsPage
                        isInstructorRoute={isInstructorRoute}
                        isAdminRoute={isAdminRoute}
                    />
                ),
                //hideTab: true,
            },

            // {
            //     name: 'PRIVATE COURSES',
            //     slug: 'private-courses',
            //     component: (
            //         <SavedOnDemand
            //             user={user}
            //             savedOnDemand={[...privateCourse]}
            //             showViewAll={false}
            //         />
            //     ),
            //     hideTab: !Object.keys(
            //         privateEmailDomainsAndBundleNames
            //     ).includes(userEmailDomain),
            // },
        ];
    }, [
        courseHours,
        courseMin,
        domain,
        enrolledOnDemand,
        isEnrolledCoursesLoading,
        isUserLoading,
        // privateCourse,
        privateOnDemand,
        savedCourses,
        setUser,
        user,
        userEmailDomain,
        userOnAir,
    ]);

    const initialTabValue = section
        ? tabValues.findIndex(({ slug }) => slug === section)
        : 0;
    // VALUES USED FOR NAVIGATION
    const [tabValue, setTabValue] = useState(initialTabValue);

    useEffect(() => {
        console.log('setTabValue');
        setTabValue(
            section ? tabValues.findIndex(({ slug }) => slug === section) : 0
        );
    }, [section]);

    const handleOnSave = () => {
        console.log(1);
    };

    // STADUST BUTTON CLICK HANDLER
    const handleStardustBtnClick = () => {
        setShowStardust(true);
    };

    //STARDUST MODAL CLOSE HANDLER
    const onClose = () => {
        setShowStardust(false);
    };

    return (
        <AdminContainer>
            <HelmetWrapper route={route} />

            <Dialog open={showStardust} onClose={onClose}>
                <StardustSection
                    onClose={onClose}
                    name={name}
                    avatar={<UserAvatar user={_user} name={name} />}
                    setTabValue={setTabValue}
                />
            </Dialog>

            {user && (
                <Box>
                    {/* <BackFloatButton /> */}
                    <HeadingSection
                        heading="My Dashboard"
                        subHeading={
                            'Track your recommendations, activities, progress and achievements'
                        }
                        iconComponent={<SpeedIcon fontSize="large" />}
                        marginBottomLess={true}
                    />
                    <Grid container>
                        {/* STARDUST BUTTON */}
                        <Grid container item xs={12} justifyContent="center">
                            <Button
                                color="secondary"
                                onClick={handleStardustBtnClick}
                                style={{
                                    paddingInline: '1rem',
                                }}
                            >
                                stardust
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Box mb={3} className={classes.centerTabs}>
                                <NavigationTab
                                    collections={tabValues}
                                    value={tabValue}
                                    setValue={setTabValue}
                                    basePath="/profile/dashboard"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            {tabValues[tabValue].noContainer ? (
                                tabValues[tabValue].component
                            ) : (
                                <Container maxWidth="lg">
                                    {tabValues[tabValue].component}
                                </Container>
                            )}
                        </Grid>
                    </Grid>
                    {/* DISPLAY ON DEMAND COURSE */}
                    {/* <Grid container className={classes.topPicksCourse}>
                        <Box
                            className={`${classes.picksBg} ${classes.picksBgCourses}`}
                        />
                        {initialTabValue === 0 ? (
                            <Grid item xs={12}>
                                <Container maxWidth="lg">
                                    <TopPicks
                                        data={topCourses}
                                        user={user}
                                        heading={onDemandHeading}
                                        handleOnSave={handleOnSave}
                                    />
                                </Container>
                            </Grid>
                        ) : (
                            ''
                        )}
                    </Grid> */}
                    {/* DISPLAY ON AIR COURSE */}
                    <Grid container className={classes.topPicksCourse}>
                        <Box
                            className={`${classes.picksBgOnAir} ${classes.picksBg}`}
                        />
                        {/* <Grid item xs={12}>
                            <Container maxWidth="lg">
                                <OnAirCarousel
                                    data={onAirRecommend}
                                    user={user}
                                    linkPath="/on-air"
                                />
                            </Container>
                        </Grid> */}
                    </Grid>
                </Box>
            )}
        </AdminContainer>
    );
};
