import { useCallback, useEffect, useRef } from 'react';
import firebase from 'firebase/app';
import axios from 'axios';

export const useLoginLayer2 = () => {
    const isUserRef = useRef(false);

    const loginLayer2 = useCallback(() => {
        axios
            .get('/api/auth/login2', { withCredentials: true })
            .catch(console.error);
    }, []);

    const logoutLayer2 = useCallback(() => {
        axios
            .get('/api/auth/logout2', { withCredentials: true })
            .catch(console.error);
    }, []);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user && isUserRef.current === false) {
                isUserRef.current = true;
                loginLayer2();
            } else if (!user && isUserRef.current === true) {
                isUserRef.current = false;
                logoutLayer2();
            }
        });

        return unsubscribe;
    }, [loginLayer2, logoutLayer2]);
};
