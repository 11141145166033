import React from 'react';
import { Backdrop, CircularProgress } from '..';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export const BackdropLoad = ({ isLoading }) => {
    const { classes } = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={isLoading || false}>
            <CircularProgress color="primary" />
        </Backdrop>
    );
};
