import { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import hcVariablePie from 'highcharts/modules/variable-pie';
import hcAccess from 'highcharts/modules/accessibility';

if (typeof Highcharts === 'object') {
    hcVariablePie(Highcharts);
    hcAccess(Highcharts);
}

export const VariablePieChart = (props) => {
    const {
        enableLegend = true,
        title,
        subtitle,
        seriesName,
        chartData,
        pointFormat,
        fontSize = '20px',
    } = props;

    const memoizedChartOpt = useMemo(() => {
        return {
            chart: {
                type: 'variablepie',
            },
            legend: { enabled: enableLegend },
            title: {
                text: title,
                align: 'center',
                style: {
                    fontSize,
                    textTransform: 'capitalize',
                },
            },
            subtitle: {
                text: subtitle,
                align: 'center',
            },
            tooltip: {
                pointFormat,
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    minPointSize: 70,
                    innerSize: '30%',
                    zMin: 0,
                    name: seriesName,
                    showInLegend: true,
                    data: chartData,
                },
            ],
        };
    }, [
        chartData,
        enableLegend,
        fontSize,
        pointFormat,
        seriesName,
        subtitle,
        title,
    ]);

    return (
        <HighchartsReact highcharts={Highcharts} options={memoizedChartOpt} />
    );
};
