import { useGetAllLiveLabs } from './useGetAllLiveLabs';
import { getUserLiveLabs, setUserLiveLabs } from './Recoil/Selector';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';

export const useGetSavedLiveLabs = (user) => {
    const liveLabs = useGetAllLiveLabs();
    const savedLiveLabs = useRecoilValue(getUserLiveLabs);
    const setSavedLiveLabs = useSetRecoilState(setUserLiveLabs);

    useEffect(() => {
        if (liveLabs && user) {
            const savedOnes = [];
            user.user_rsvps_liveLabs.forEach((lab) => {
                for (let i = 0; i < liveLabs.length; i++) {
                    if (
                        lab.slug === liveLabs[i].name &&
                        lab.endTime === liveLabs[i].endDate.date
                    ) {
                        savedOnes.push(liveLabs[i]);
                        break;
                    }
                }
            });
            setSavedLiveLabs(savedOnes);
        }
    }, [liveLabs, user]);

    return savedLiveLabs;
};
