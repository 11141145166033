import { BlobServiceClient } from '@azure/storage-blob';
import ImageIcon from '../img/image_icon.png';
import AudioIcon from '../img/mp3_icon.png';
import VideoIcon from '../img/video_icon.png';
import TxtIcon from '../img/txt_icon.png';
import PdfIcon from '../img/pdf_icon.png';
import DocIcon from '../img/document_icon.png';

export class AzureBlobStorage {
    blobSasURL = null;
    isInstructor = undefined;
    client = undefined;
    isAdmin = undefined;
    predefinedDirName = undefined;
    constructor(
        isInstructor = undefined,
        client = undefined,
        isAdmin = undefined,
        predefinedDirName = undefined
    ) {
        this.blobSasURL =
            process.env.REACT_APP_ENVIRONMENT === 'production'
                ? process.env.REACT_APP_BLOB_SAS_URL_PROD
                : process.env.REACT_APP_BLOB_SAS_URL_DEV;
        this.isAdmin = isAdmin;
        this.isInstructor = isInstructor;
        this.client = client;
        this.predefinedDirName = predefinedDirName;
    }
    generateContainerName(id) {
        /*const nameArr = [];
        const splittedTitle = title.split(' ');
        for (let i = 0; i < splittedTitle.length; i++) {
            let temp = splittedTitle[i];
            temp = temp.toLowerCase();
            temp = temp.replace(/[^a-zA-Z0-9]/gi, '');
            if (temp.length > 0) nameArr.push(temp);
        }
        let joinedName = '';
        if (nameArr.length > 3) joinedName = nameArr.slice(0, 3).join('-');
        else joinedName = nameArr.join('-');
        return joinedName + '-' + id;*/
        if (!id) throw new Error("id can't left empty");
        return id;
    }
    generateContainerNameForDashboard(id) {
        if (!id) throw new Error("id can't left empty");
        return `db-${id}`;
    }
    generateContainerNameForInstructorProfile(instructorId, prefix = 'ins') {
        if (!instructorId) throw new Error("instructor can't left empty");
        return `${prefix}-${instructorId}`;
    }
    generateContainerNameForSampleOutlines(requirementId) {
        if (!requirementId) throw new Error('Requirement id is required');
        return `out-${requirementId}`;
    }
    generateContainerNameForDashboardAdministration(requirementId) {
        if (!requirementId) throw new Error("id can't left empty");

        // here ad means administration tab in OM dashboard
        return `ad-${requirementId}`;
    }
    generateDirectory(instructorName = undefined) {
        if (this.predefinedDirName && this.predefinedDirName?.length > 0) {
            return `${this.predefinedDirName}/`;
        } else if (this.isInstructorUser() && instructorName?.length > 0) {
            return `${instructorName}/`;
        } else if (this.isCADUser()) {
            return 'CAD/';
        } else if (this.isTADUser()) {
            return 'TAD/';
        } else {
            return '';
        }
    }
    isInstructorUser() {
        if (this.isInstructor && !this.isAdmin && !this.client) return true;
        else return false;
    }
    isTADUser() {
        if (this.isAdmin && !this.isInstructor) return true;
        else return false;
    }
    isCADUser() {
        if (
            !this.isAdmin &&
            !this.isInstructor &&
            this.client &&
            Object.keys(this.client).length > 0
        )
            return true;
        else return false;
    }
    getContainerClient(containerName) {
        if (!this.blobSasURL) throw new Error('SasUrl missing');
        if (!containerName || containerName.length === 0)
            throw new Error('Invalid container name');
        const blobServiceClient = new BlobServiceClient(this.blobSasURL);
        const containerClient = blobServiceClient.getContainerClient(
            containerName
        );
        return containerClient;
    }
    async createContainer(containerName) {
        try {
            const containerClient = this.getContainerClient(containerName);
            const isContainerExist = await containerClient.exists();
            if (isContainerExist) return { message: 'Container already exist' };
            await containerClient.create();
            return { message: 'Container created successfully' };
        } catch (error) {
            throw new Error(error);
        }
    }
    async deleteContainerIfExists(containerName) {
        try {
            const containerClient = this.getContainerClient(containerName);
            const isContainerExist = await containerClient.exists();
            if (!isContainerExist)
                return { message: "Container doesn't exist" };
            await containerClient.delete();
            return { message: 'Container deleted successfully' };
        } catch (error) {
            throw new Error(error);
        }
    }
    getFileType(extension) {
        if (!extension) throw new Error('extension is required');
        const img = {
            type: 'IMAGE',
            icon: ImageIcon,
            formats: [
                'png',
                'jpg',
                'gif',
                'jpeg',
                'webp',
                'avif',
                'bmp',
                'ico',
                'svg',
                'tif',
                'tiff',
            ],
        };

        const pdf = {
            type: 'PDF',
            icon: PdfIcon,
            formats: ['pdf'],
        };

        const doc = {
            type: 'DOCUMENT',
            icon: DocIcon,
            formats: [
                'doc',
                'docx',
                'odp',
                'ods',
                'odt',
                'ppt',
                'pptx',
                'xls',
                'xlsx',
                'csv',
            ],
        };

        const audio = {
            type: 'AUDIO',
            icon: AudioIcon,
            formats: [
                'mid',
                'midi',
                'mp3',
                'cda',
                'oga',
                'opus',
                'wav',
                'weba',
                '3gp',
                '3g2',
            ],
        };

        const video = {
            type: 'VIDEO',
            icon: VideoIcon,
            formats: ['avi', 'mp4', 'mpeg', 'ogv', 'ts', 'webm'],
        };

        if (img.formats.includes(extension))
            return { type: img.type, icon: img.icon };
        else if (pdf.formats.includes(extension))
            return { type: pdf.type, icon: pdf.icon };
        else if (doc.formats.includes(extension))
            return { type: doc.type, icon: doc.icon };
        else if (audio.formats.includes(extension))
            return { type: audio.type, icon: audio.icon };
        else if (video.formats.includes(extension))
            return { type: video.type, icon: video.icon };
        else return { type: 'RANDOM', icon: TxtIcon };
    }
    async listFiles(containerName) {
        let fileList = {
            size: 0,
            fileNames: [],
        };
        const containerClient = this.getContainerClient(containerName);
        let iter = containerClient.listBlobsFlat();
        let blobItem = await iter.next();
        while (!blobItem.done) {
            fileList.size += 1;
            const splitted = blobItem.value.name.split('/');
            const formattedName = splitted[splitted.length - 1];
            const ext = blobItem.value.name.split('.').pop();
            fileList.fileNames.push({
                actual: blobItem.value.name,
                formatted: formattedName,
                extension: ext,
                ...this.getFileType(ext),
            });
            blobItem = await iter.next();
        }
        return fileList;
    }
    // add an ending '/' in folder name
    async listFilesFromFolder(containerName, folderName) {
        let fileList = {
            size: 0,
            fileNames: [],
        };
        const containerClient = this.getContainerClient(containerName);
        const isContainerExist = await containerClient.exists();
        if (!isContainerExist) {
            throw new Error('no such container exist');
        }
        let iter = containerClient.listBlobsFlat();
        let blobItem = await iter.next();
        while (!blobItem.done) {
            const blobName = blobItem.value.name;
            // console.log(blobName);

            const splitted = blobName.split('/');
            const formattedFileName = splitted[splitted.length - 1];
            const fullFileName = folderName + formattedFileName;
            if (blobName === fullFileName) {
                const splittedFormattedFileName = formattedFileName.split('.');
                const ext =
                    splittedFormattedFileName[
                        splittedFormattedFileName.length - 1
                    ];

                fileList.size += 1;
                fileList.fileNames.push({
                    actual: blobName,
                    formatted: formattedFileName,
                    extension: ext,
                    ...this.getFileType(ext),
                });
            }
            blobItem = await iter.next();
        }
        return fileList;
    }
    async listFilesFromDir(containerName, dirName) {
        let fileList = {
            size: 0,
            fileNames: [],
        };
        const containerClient = this.getContainerClient(containerName);
        const isContainerExist = await containerClient.exists();
        if (!isContainerExist) {
            throw new Error('no such container exist');
        }
        let iter = containerClient.listBlobsFlat({ prefix: dirName });
        // .byPage({ maxPageSize: 5 });
        let blobItem = await iter.next();
        // console.log(blobItem);
        while (!blobItem.done) {
            const blobName = blobItem.value.name;
            console.log(blobName);

            const splitted = blobName.split('/');
            const formattedFileName = splitted[splitted.length - 1];
            const fullFileName = dirName + formattedFileName;
            // if (blobName === fullFileName) {
            const splittedFormattedFileName = formattedFileName.split('.');
            const ext =
                splittedFormattedFileName[splittedFormattedFileName.length - 1];

            fileList.size += 1;
            fileList.fileNames.push({
                actual: blobName,
                formatted: formattedFileName,
                extension: ext,
                ...this.getFileType(ext),
            });
            // }
            blobItem = await iter.next();
        }
        console.log(fileList);
        return fileList;
    }
    async uploadFiles(
        files,
        containerName,
        setTotalFilesUploaded,
        setCurrentProgress,
        userDefinedDir = undefined,
        userDefinedFileName = undefined,
        metadata = {},
        returnFileListInContainer = true
    ) {
        try {
            const containerClient = this.getContainerClient(containerName);
            const isContainerExist = await containerClient.exists();
            if (!isContainerExist) await this.createContainer(containerName);
            let filesUploaded = 0;
            const dir = userDefinedDir
                ? `${userDefinedDir}/`
                : this.generateDirectory();
            const uploadedFiles = [];

            for (const file of files) {
                const fileName = userDefinedFileName || file?.name;
                if (!fileName) throw new Error('File name not present');
                const blobName =
                    dir.length > 0 ? `${dir}${fileName}` : fileName;
                const blockBlobClient = containerClient.getBlockBlobClient(
                    blobName
                );

                // file extension
                const extension = fileName.split('.').pop();

                if (extension === 'pdf') {
                    // set content-type = application/pdf and content-disposition = inline to prevent it from downloading automatically while viewing
                    await blockBlobClient.uploadData(file, {
                        onProgress: (progressEvent) => {
                            const progress = Math.floor(
                                (progressEvent.loadedBytes / file.size) * 100
                            );
                            if (setCurrentProgress)
                                setCurrentProgress(progress);
                        },
                        blobHTTPHeaders: {
                            blobContentType: 'application/pdf',
                            blobContentDisposition: 'inline',
                        },
                        metadata,
                    });
                } else {
                    await blockBlobClient.uploadData(file, {
                        onProgress: (progressEvent) => {
                            const progress = Math.floor(
                                (progressEvent.loadedBytes / file.size) * 100
                            );
                            if (setCurrentProgress)
                                setCurrentProgress(progress);
                        },
                        metadata,
                    });
                }

                if (setTotalFilesUploaded)
                    setTotalFilesUploaded(filesUploaded + 1);
                filesUploaded += 1;

                uploadedFiles.push({
                    actual: blobName,
                    formatted: fileName,
                    extension,
                    url: blockBlobClient.url, // Add the URL of the uploaded file
                    ...this.getFileType(extension),
                });
            }

            if (returnFileListInContainer) {
                const fileList =
                    dir.length > 0
                        ? await this.listFilesFromFolder(containerName, dir)
                        : await this.listFiles(containerName);
                return fileList;
            } else {
                return { size: filesUploaded, fileNames: uploadedFiles };
            }
        } catch (e) {
            throw new Error(e);
        }
    }

    async getContentProperties(fileName, containerName) {
        try {
            const containerClient = this.getContainerClient(containerName);
            const isContainerExist = await containerClient.exists();
            if (!isContainerExist) throw new Error('Container does not exist');
            const blobClient = containerClient.getBlockBlobClient(fileName);
            const properties = await blobClient.getProperties();
            return properties;
        } catch (e) {
            throw new Error(e);
        }
    }
    async downloadFile(fileName, containerName, setDownloadProgress) {
        try {
            const containerClient = this.getContainerClient(containerName);
            const isContainerExist = await containerClient.exists();
            if (!isContainerExist) throw new Error('Container does not exist');
            const blockBlobClient = containerClient.getBlockBlobClient(
                fileName
            );
            const { contentLength } = await this.getContentProperties(
                fileName,
                containerName
            );
            const downloadRes = await blockBlobClient.download(0, undefined, {
                onProgress: (e) => {
                    const progress = Math.floor(
                        (e.loadedBytes / contentLength) * 100
                    );
                    if (setDownloadProgress) setDownloadProgress(progress);
                },
            });
            const body = await downloadRes.blobBody;
            const url = URL.createObjectURL(body);
            return url;
        } catch (e) {
            throw new Error(e);
        }
    }
    async deleteFile(fileName, containerName, userDefinedDir = undefined) {
        try {
            const containerClient = this.getContainerClient(containerName);
            const isContainerExist = await containerClient.exists();
            if (!isContainerExist) throw new Error('Container not present');
            await containerClient.deleteBlob(fileName);
            const dir = userDefinedDir
                ? `${userDefinedDir}/`
                : this.generateDirectory();
            const fileList =
                dir.length > 0
                    ? await this.listFilesFromFolder(containerName, dir)
                    : await this.listFiles(containerName);
            return fileList;
        } catch (e) {
            throw new Error(e);
        }
    }
    async getBlobURL(containerName, fileName) {
        try {
            const containerClient = this.getContainerClient(containerName);

            const isContainerExist = await containerClient.exists();

            if (!isContainerExist) throw new Error('Container not present');

            const { url } = containerClient.getBlobClient(fileName);

            return url;
        } catch (e) {
            throw new Error(e);
        }
    }
    async fetchBlobMetadata(containerName, blobName) {
        try {
            const containerClient = this.getContainerClient(containerName);

            const isContainerExist = await containerClient.exists();

            if (!isContainerExist) throw new Error('Container not present');

            const blockBlobClient = containerClient.getBlockBlobClient(
                blobName
            );

            // Fetch the blob properties
            const blobProperties = await blockBlobClient.getProperties();

            // Get the metadata from the properties
            const metadata = blobProperties.metadata;

            return metadata;
        } catch (error) {
            console.error('Error fetching blob metadata:', error);
            throw error;
        }
    }
    async getSVGText(containerName, blobName) {
        try {
            const containerClient = this.getContainerClient(containerName);
            const isContainerExist = await containerClient.exists();
            if (!isContainerExist) {
                throw new Error('Container not present');
            }
            const blobClient = containerClient.getBlobClient(blobName);
            const downloadRes = await blobClient.download();
            const body = await downloadRes.blobBody;
            const svgContent = await body.text();
            return svgContent;
        } catch (error) {
            throw new Error(error);
        }
    }
}
