export const capitalize = (string) => {
    if (string) {
        return `${string.slice(0, 1).toUpperCase()}${string
            .slice(1)
            .toLowerCase()}`;
    } else {
        return;
    }
};

export const capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string' || string.length === 0) {
        // Return the original string if it's not a string or is empty
        return string;
    }
    // Trim the string to remove leading and trailing whitespace and capitalize the first character
    return string.trim().charAt(0).toUpperCase() + string.trim().slice(1);
};
