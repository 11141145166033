import { Box, Skeleton } from '@mui/material';
import React from 'react';

function ListItemLoading({ type }) {
    return (
        <Box>
            {[1, 2, 3, 4, 5, 6].map((num) => {
                return (
                    <Skeleton
                        key={`${type}-${num}`}
                        width={'100%'}
                        height={30}
                        variant="rect"
                        sx={{ mt: (theme) => theme.spacing(2) }}
                    />
                );
            })}
        </Box>
    );
}

export default ListItemLoading;
