import React, { useEffect, useMemo, useRef } from 'react';
import { Box, Typography, TimerUI, TimerUI2 } from '..';
import { makeStyles } from 'tss-react/mui';
import { useTimer } from 'react-timer-hook';
// import { useTimer } from 'react-timer-hook';

export const Timer = ({
    expiryTimestamp,
    onExpire,
    secondsToExpireBefore,
    uiType,
}) => {
    const isExpiredRef = useRef(false);

    const {
        seconds,
        minutes,
        hours,
        days,
        // isRunning,
        // start,
        // pause,
        // resume,
        // restart,
    } = useTimer({
        expiryTimestamp,
        onExpire: () => {
            isExpiredRef.current = true;
            if (onExpire) onExpire();
        },
    });

    const totalSeconds = useMemo(
        () => seconds + minutes * 60 + hours * 60 * 60 + days * 60 * 60 * 24,
        [seconds, minutes, hours, days]
    );

    useEffect(() => {
        if (
            secondsToExpireBefore &&
            totalSeconds <= secondsToExpireBefore &&
            !!onExpire &&
            !isExpiredRef.current
        ) {
            isExpiredRef.current = true;
            onExpire();
        }
    }, [totalSeconds]);

    const uiTypes = [
        <TimerUI
            seconds={seconds}
            minutes={minutes}
            hours={hours}
            days={days}
        />,
        <TimerUI2
            seconds={seconds}
            minutes={minutes}
            hours={hours}
            days={days}
        />,
    ];

    return (
        <>
            {/* {totalSeconds} */}
            {uiType ? uiTypes[uiType] : uiTypes[0]}
        </>
    );
};
