import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    Typography,
    IconButton,
    Avatar,
    CircularProgress,
    Badge,
} from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../app/recoil';
import { UsersSearchAndSelect } from '../admin/UsersSearchAndSelect';
import { CloseIcon, EditIcon } from '../icons';
import axios from 'axios';
import { AzureBlobStorage } from '../util/BlobStorage';
import { TYPES } from '../my-account/utils';
import { randomAlphaNumericString } from '../util';

const useStyles = makeStyles()((theme) => ({
    dialog: {
        minWidth: '90vw',
        padding: 40,
        [theme.breakpoints.up('md')]: {
            minWidth: 1000,
        },
    },
    avatar: {
        width: 150,
        height: 150,
        [theme.breakpoints.down('sm')]: {
            width: 128,
            height: 128,
        },
    },
    edit: {
        height: 30,
        width: 30,
        minWidth: 30,
        borderRadius: '100vmax',
    },
    editIcon: {
        fontSize: '1rem',
    },
}));

const GroupChatManageModal = ({
    open,
    onClose,
    chatId = false,
    groupData = {},
}) => {
    const { classes } = useStyles();

    const [idToEdit, setIdToEdit] = useState(chatId);

    const [selectedImage, setSelectedImage] = useState(null);
    const [groupThumbnail, setGroupThumbnail] = useState(
        groupData.groupThumbnail || ''
    );
    const [groupName, setGroupName] = useState(groupData.groupName || '');
    const [selectedUsers, setSelectedUsers] = useState(groupData.userIds || []);
    const setAlert = useSetRecoilState(alertState);
    const [loading, setLoading] = useState(false);

    const handleThumbnailChange = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setGroupThumbnail(reader.result);
            reader.readAsDataURL(file);
        }
    };

    const uploadImg = async (selectedImg, containerName, dirName) => {
        if (
            selectedImg &&
            typeof selectedImg !== 'string' &&
            TYPES.includes(selectedImg.type)
        ) {
            const azureBlobStorage = new AzureBlobStorage();
            const uploadedFiles = await azureBlobStorage.uploadFiles(
                [selectedImg],
                containerName,
                null,
                null,
                dirName,
                randomAlphaNumericString(4) + selectedImg.name,
                {},
                false
            );

            if (uploadedFiles.fileNames.length > 0) {
                return uploadedFiles.fileNames[0].url;
            }
        }
        return null;
    };

    const handleSave = async () => {
        if (!groupName) {
            setAlert({
                message: 'Group name is required',
                show: true,
                severity: severity.ERROR,
            });
            return;
        }

        if (selectedUsers.length < 2) {
            setAlert({
                message: 'At least two users must be selected',
                show: true,
                severity: severity.ERROR,
            });
            return;
        }

        setLoading(true);

        try {
            let newThumbnailUrl = await uploadImg(
                selectedImage,
                'chats',
                'media/thumbnails'
            );

            const userIds = selectedUsers.map((user) => user._id);

            const payload = {
                groupName,
                userIds,
            };

            if (newThumbnailUrl) {
                payload.groupThumbnail = newThumbnailUrl;
            }

            if (idToEdit) {
                await axios.put(`/api/chats/group/${idToEdit}`, payload);
                setAlert({
                    message: 'Group chat updated successfully',
                    show: true,
                    severity: severity.SUCCESS,
                });
            } else {
                const response = await axios.post('/api/chats/group', payload);
                setIdToEdit(response.data.id);
                setAlert({
                    message: 'Group chat created successfully',
                    show: true,
                    severity: severity.SUCCESS,
                });
            }

            onClose();
        } catch (e) {
            if ([400, 401, 403, 404, 422].includes(e?.response?.status)) {
                setAlert({
                    message: e?.response?.data?.message,
                    severity: severity.ERROR,
                    show: true,
                });
            } else {
                setAlert({
                    message: e?.message,
                    severity: severity.ERROR,
                    show: true,
                });
            }
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            classes={{ paper: classes.dialog }}
        >
            <Box display="flex" alignItems="center" p={2}>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    {idToEdit ? 'Update Chat Group' : 'Create New Chat Group'}
                </Typography>
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent>
                <Badge
                    sx={{ boxShadow: 'none' }}
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={
                        <Button
                            variant="contained"
                            color="secondary"
                            component="label"
                            className={`${classes.edit}`}
                        >
                            <EditIcon className={classes.editIcon} />
                            <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(event) => {
                                    setSelectedImage(event.target.files[0]);
                                    handleThumbnailChange(
                                        event.target.files[0]
                                    );
                                }}
                            />
                        </Button>
                    }
                >
                    <Avatar
                        src={groupThumbnail}
                        alt="Group Thumbnail"
                        className={`${classes.avatar}`}
                        sx={{ boxShadow: 'none !important' }}
                    />
                </Badge>

                {/* Group Name */}
                <TextField
                    fullWidth
                    label="Group Name"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    margin="normal"
                />

                {/* Users Selection */}
                <Typography variant="subtitle1" gutterBottom>
                    Select Users
                </Typography>
                <UsersSearchAndSelect
                    selected={selectedUsers}
                    setSelected={setSelectedUsers}
                    searchInputSize="small"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={loading}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSave}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default GroupChatManageModal;
