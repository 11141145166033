import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    LinearProgress,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CloseIcon } from '../../../icons';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Autocomplete } from '@mui/material';
// import { skipWeekend } from '../../../util';
import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../../app/recoil';

let timeoutId = null;

const taskTypeMenuItems = [
    {
        id: 'SME-Sourcing',
        value: 'SME-Sourcing',
    },
    {
        id: 'Client Administration',
        value: 'Client Administration',
    },
    {
        id: 'Program Management - Planning',
        value: 'Program Management - Planning',
    },
    {
        id: 'Production - Elearning',
        value: 'Production - Elearning',
    },
    {
        id: 'Program Management - LMS',
        value: 'Program Management - LMS',
    },
    {
        id: 'Distribution',
        value: 'Distribution',
    },
    {
        id: 'Marketing',
        value: 'Marketing',
    },
    {
        id: 'Production - Live Content',
        value: 'Production - Live Content',
    },
];

const useStyles = makeStyles()((theme) => ({
    dialogContainer: {
        padding: '2%',
        height: 'fit-content',
        maxHeight: '92vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c4bfbf',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px #d6d6d6',
            borderRadius: '10px',
        },
    },
    dialogTitle: {
        textAlign: 'right',
        padding: theme.spacing(0.5),
    },
    stepperBtnGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '0.5rem',
    },
    groupWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
        },
    },
    rowReverse: {
        flexDirection: 'row-reverse',
    },
    inputGroup: {
        marginBottom: '0.5rem',
        flexBasis: '48%',
        '& label': {
            fontSize: '1rem',
            marginBottom: '0.25rem',
        },
        [theme.breakpoints.down('md')]: {
            flexBasis: '100%',
        },
    },
}));

export default function EditTaskDialog({
    task,
    requirementId,
    setShowEditTaskDialog,
    showEditTaskDialog,
    releaseDate,
    categoryId,
    loadRequirementData,
}) {
    const { classes } = useStyles();
    const [taskName, setTaskName] = useState('');
    const [validationErr, setValidationErr] = useState([]);
    const [taskType, setTaskType] = useState('');
    const [searchingUser, setSearchingUser] = useState(false);
    const [userOptions, setUserOptions] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [assignedTo, setAssignedTo] = useState([]);
    const [duration, setDuration] = useState(0);
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [numOfDaysBeforeDelivery, setNumOfDaysBeforeDelivery] = useState('');
    const [numOfDays, setNumOfDays] = useState(0);
    const [cost, setCost] = useState('');
    const [isCheckpointTask, setIsCheckpointTask] = useState(false);
    const [newCheckpointName, setNewCheckpointName] = useState('');
    const [updateingTask, setUpdateingTask] = useState(false);
    const setAlert = useSetRecoilState(alertState);

    useEffect(() => {
        if (timeoutId) window.clearTimeout(timeoutId);
        let cancel = () => {};

        timeoutId = setTimeout(() => {
            setSearchingUser(true);
            axios({
                method: 'GET',
                url: `/api/client-requirements/logistics-users`,
                params: {
                    searchValue,
                },
                cancelToken: new axios.CancelToken((c) => (cancel = c)),
            })
                .then((res) => {
                    setUserOptions(res.data?.users ?? []);
                    setSearchingUser(false);
                })
                .catch((e) => {
                    console.log(e);
                    setSearchingUser(false);
                });
        }, 1000);

        return () => {
            if (timeoutId) clearTimeout(timeoutId);
            cancel();
        };
    }, [searchValue]);

    useEffect(() => {
        if (task?.name) setTaskName(task.name);

        if (task?.typeOfTask) setTaskType(task.typeOfTask);

        const endDate = moment(releaseDate);
        const daysIncludingWeekends = endDate.subtract(
            task.numOfDaysBeforeDelivery,
            'days'
        );

        if (task?.numOfDaysBeforeDelivery !== undefined) {
            setNumOfDays(task.numOfDaysBeforeDelivery);
            // const daysExcludingWeekend = skipWeekend(daysIncludingWeekends);
            const daysExcludingWeekend = daysIncludingWeekends;
            setNumOfDaysBeforeDelivery(
                daysExcludingWeekend.format('YYYY-MM-DD')
            );
        }

        if (task?.duration !== undefined) {
            setDuration(task.duration);
            const duration = task?.duration ? task.duration : 0;
            const startDaysIncludingWeekends = daysIncludingWeekends.subtract(
                Math.abs(duration),
                'days'
            );
            /* const startDaysExcludingWeekends = skipWeekend(
                startDaysIncludingWeekends
            ); */
            const startDaysExcludingWeekends = startDaysIncludingWeekends;

            setStartDate(startDaysExcludingWeekends.format('YYYY-MM-DD'));
        }

        if (task?.cost) {
            setCost(task.cost);
        }

        if (task?.checkpointName) {
            setIsCheckpointTask(true);
            setNewCheckpointName(task.checkpointName);
        }

        if (task?.assignedTo?.length > 0) {
            setAssignedTo(task.assignedTo);
        }
    }, [releaseDate, task]);

    const formattedReleaseDate = useMemo(() => {
        if (releaseDate) {
            return moment(releaseDate).format('DD-MMM-YYYY');
        } else {
            return 'N/A';
        }
    }, [releaseDate]);

    const validationErrObj = useCallback(
        (param) => {
            const filteredError = validationErr.filter(
                (err) => err?.param === param
            );

            if (filteredError.length > 0) {
                return { isError: true, filteredError };
            } else {
                return { isError: false, filteredError };
            }
        },
        [validationErr]
    );

    const handleClose = () => {
        setShowEditTaskDialog(false);
    };

    const resetValidationError = (name) => {
        if (validationErr.length > 0) {
            setValidationErr((prev) => {
                return prev.filter((err) => err.param !== name);
            });
        }
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onTaskNameChange = (e) => {
        setTaskName(e.target.value);
        resetValidationError(e.target.name);
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onTaskTypeChange = (e) => {
        setTaskType(e.target.value);
        resetValidationError(e.target.name);
    };

    const onUserNameType = (e) => {
        setSearchValue(e.target.value);
    };

    const onUserSelected = (e, newVal) => {
        setAssignedTo(newVal);
    };

    const onDurationChange = (e) => {
        const value = e.target.value;
        setDuration(value);
        /* setStartDate(
            skipWeekend(
                moment(numOfDaysBeforeDelivery).subtract(value, 'days')
            ).format('YYYY-MM-DD')
        ); */
        setStartDate(
            moment(numOfDaysBeforeDelivery)
                .subtract(value, 'days')
                .format('YYYY-MM-DD')
        );
    };

    const onStartDateChange = (e) => {
        // const date = skipWeekend(moment(e.target.value));
        const date = moment(e.target.value);
        setStartDate(moment(date).format('YYYY-MM-DD'));
        const days = moment(numOfDaysBeforeDelivery).diff(date, 'days');
        setDuration(days);
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onNumOfDaysChange = (e) => {
        const days = e.target.value;
        setNumOfDays(days);
        // const date = skipWeekend(moment(releaseDate).subtract(days, 'days'));
        const date = moment(releaseDate).subtract(days, 'days');
        setNumOfDaysBeforeDelivery(moment(date).format('YYYY-MM-DD'));
        /* setStartDate(
            skipWeekend(moment(date).subtract(duration, 'days')).format(
                'YYYY-MM-DD'
            )
        ); */
        setStartDate(
            moment(date).subtract(duration, 'days').format('YYYY-MM-DD')
        );
    };

    const onCostChange = (e) => {
        setCost(e.target.value);
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onDateChange = (e) => {
        // const date = skipWeekend(moment(e.target.value));
        const date = moment(e.target.value);
        setNumOfDaysBeforeDelivery(moment(date).format('YYYY-MM-DD'));
        const days = moment(releaseDate).diff(date, 'days');
        setNumOfDays(days);
        setDuration(moment(date).diff(startDate, 'days'));
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onNewCheckpointNameChange = (e) => {
        setNewCheckpointName(e.target.value);
        resetValidationError(e.target.name);
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onCheckboxClick = (e) => {
        const checked = e.target.checked;
        setIsCheckpointTask(checked);
        if (checked === false && newCheckpointName.length > 0)
            setNewCheckpointName('');
    };

    const validateInputs = () => {
        const errors = [];
        if (taskName.trim().length === 0) {
            errors.push({
                message: 'Task name is required',
                param: 'taskName',
            });
        }

        if (taskType.trim().length === 0) {
            errors.push({
                message: 'Task type is required',
                param: 'taskType',
            });
        }

        if (numOfDaysBeforeDelivery.trim().length === 0) {
            errors.push({
                message: 'Metric date is required',
                param: 'numOfDaysBeforeDelivery',
            });
        }

        if (startDate.trim().length === 0) {
            errors.push({
                message: 'Start date is required',
                param: 'startDate',
            });
        }

        if (isCheckpointTask && newCheckpointName.trim().length === 0) {
            errors.push({
                message: 'New checkpoint name is required',
                param: 'newCheckpointName',
            });
        }

        return { isError: errors.length > 0, errors };
    };

    const handleUpdate = async () => {
        const { isError, errors } = validateInputs();

        if (isError) {
            return setAlert({
                show: true,
                severity: severity.ERROR,
                message: errors[0].message,
            });
        }

        try {
            const payload = {
                requirementId,
                categoryId,
                taskId: task._id,
                taskData: {
                    name: taskName,
                    typeOfTask: taskType,
                    isActive: true,
                    numOfDaysBeforeDelivery: numOfDays,
                    completedAt: null,
                    status: null,
                    checkpointName: isCheckpointTask ? newCheckpointName : null,
                    pinToTop: false,
                    // assignedTo: assignedTo.map((user) => ({
                    //     _id: user._id,
                    //     firstName: user?.firstName,
                    //     lastName: user?.lastName,
                    // })),
                    // assignedTo: assignedTo.map((user) => user._id),
                    duration,
                    cost: cost.trim().length === 0 ? null : cost,
                    isSimplified: task?.isSimplified ? true : false,
                },
            };

            setUpdateingTask(true);

            const { data } = await axios.post(
                '/api/client-requirements/edit-task',
                payload
            );

            await loadRequirementData(requirementId);
            setAlert({
                show: true,
                severity: severity.SUCCESS,
                message: data?.message,
            });
        } catch (e) {
            const message = e?.response?.data?.message ?? e?.message;
            setAlert({
                show: true,
                severity: severity.ERROR,
                message: message,
            });
        } finally {
            setUpdateingTask(false);
            setTaskName('');
            setTaskType('');
            setNumOfDays(0);
            setNumOfDaysBeforeDelivery('');
            setNewCheckpointName('');
            setIsCheckpointTask(false);
            setStartDate('');
            setDuration(0);
            setCost('');
            // setAssignedTo([]);
            handleClose();
        }
    };

    return (
        <div>
            <Dialog
                aria-labelledby="simple-dialog-title"
                open={showEditTaskDialog}
                maxWidth="sm"
                fullWidth
            >
                <div className={classes.dialogTitle}>
                    <IconButton size="small" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <div className={classes.dialogContainer}>
                    <DialogContent>
                        {/* Release date */}
                        <Box className={classes.inputGroup}>
                            <label htmlFor="releaseDate">Release date</label>
                            <TextField
                                id="releaseDate"
                                value={formattedReleaseDate}
                                type="date"
                                fullWidth
                                multiline
                                variant="outlined"
                                color="secondary"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                                error={!releaseDate}
                                helperText={
                                    !releaseDate
                                        ? 'Release date is required'
                                        : ''
                                }
                            />
                        </Box>

                        {/* New task name */}
                        <Box
                            className={`${classes.groupWrapper} ${classes.rowReverse}`}
                        >
                            <Box className={classes.inputGroup}>
                                <label htmlFor="taskName">New task name</label>
                                <TextField
                                    id="taskName"
                                    name="taskName"
                                    type="text"
                                    value={taskName}
                                    onChange={onTaskNameChange}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth={true}
                                    placeholder="Enter new task name"
                                    size="small"
                                    error={validationErrObj('taskName').isError}
                                    helperText={
                                        validationErrObj('taskName')
                                            .filteredError[0]?.message
                                    }
                                />
                            </Box>

                            <FormControl
                                size="small"
                                className={classes.inputGroup}
                            >
                                <label htmlFor="taskType" style={{ margin: 0 }}>
                                    Task Type
                                </label>
                                <Select
                                    labelId="taskType"
                                    id="taskType"
                                    value={taskType}
                                    variant="outlined"
                                    color="secondary"
                                    displayEmpty
                                    onChange={onTaskTypeChange}
                                    error={validationErrObj('taskType').isError}
                                    inputProps={{
                                        placeholder: 'test',
                                    }}
                                >
                                    {taskTypeMenuItems.map((item) => {
                                        return (
                                            <MenuItem
                                                key={item.id}
                                                value={item.value}
                                            >
                                                {item.value}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {validationErrObj('taskType').filteredError[0]
                                    ?.message?.length > 0 && (
                                    <FormHelperText>
                                        {
                                            validationErrObj('taskType')
                                                .filteredError[0]?.message
                                        }
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>

                        {/* Assigned to */}
                        {/*  <Box className={classes.inputGroup}>
                            <label htmlFor="assignedTo">Assigned to</label>
                            {searchingUser && (
                                <LinearProgress color="secondary" />
                            )}
                            <Autocomplete
                                options={userOptions}
                                getOptionLabel={(option) =>
                                    option?.firstName +
                                        ' ' +
                                        option?.lastName || ''
                                }
                                multiple
                                disableCloseOnSelect
                                id="assignedTo"
                                size="small"
                                onInputChange={onUserNameType}
                                onChange={onUserSelected}
                                value={assignedTo}
                                renderInput={(params) => (
                                    <TextField
                                        // id="users"
                                        {...params}
                                        name="users"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                    />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <Box {...props} display={'flex'}>
                                        <Checkbox
                                            icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                            }
                                            sx={{
                                                marginRight: 1,
                                                alignSelf: 'flex-start',
                                            }}
                                            checked={selected}
                                        />
                                        <Box>
                                            <Typography>
                                                {option?.firstName +
                                                    ' ' +
                                                    option?.lastName || ''}
                                            </Typography>
                                            <Typography variant="caption">
                                                {option?.email}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            />
                        </Box> */}

                        <Box className={classes.groupWrapper}>
                            {/* duration */}
                            <Box className={classes.inputGroup}>
                                <label htmlFor="numOfDays">Duration</label>
                                <TextField
                                    id="numOfDays"
                                    name="numOfDays"
                                    type="number"
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth={true}
                                    value={duration}
                                    onChange={onDurationChange}
                                    size="small"
                                />
                            </Box>

                            <Box className={classes.inputGroup}>
                                <label htmlFor="startDate">start date</label>
                                <TextField
                                    id="startDate"
                                    name="startDate"
                                    type="date"
                                    value={startDate}
                                    onChange={onStartDateChange}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Box>
                        </Box>

                        <Box className={classes.groupWrapper}>
                            {/* Num of days before delivery */}
                            <Box className={classes.inputGroup}>
                                <label htmlFor="numOfDays"># Days</label>
                                <TextField
                                    id="numOfDays"
                                    name="numOfDays"
                                    type="number"
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth={true}
                                    value={numOfDays}
                                    onChange={onNumOfDaysChange}
                                    size="small"
                                />
                            </Box>

                            <Box className={classes.inputGroup}>
                                <label htmlFor="numOfDaysBeforeDelivery">
                                    Metric date
                                </label>
                                <TextField
                                    id="numOfDaysBeforeDelivery"
                                    name="numOfDaysBeforeDelivery"
                                    type="date"
                                    value={numOfDaysBeforeDelivery}
                                    onChange={onDateChange}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth={true}
                                    size="small"
                                />
                            </Box>
                        </Box>

                        <Box className={classes.inputGroup}>
                            <label htmlFor="cost">Cost</label>
                            <TextField
                                id="cost"
                                name="cost"
                                value={cost}
                                onChange={onCostChange}
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                size="small"
                            />
                        </Box>

                        {isCheckpointTask && (
                            <Box className={classes.inputGroup}>
                                <label htmlFor="numOfDaysBeforeDelivery">
                                    New Checkpoint Name
                                </label>
                                <TextField
                                    id="newCheckpointName"
                                    name="newCheckpointName"
                                    type="text"
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth={true}
                                    size="small"
                                    value={newCheckpointName}
                                    onChange={onNewCheckpointNameChange}
                                    error={
                                        validationErrObj('newCheckpointName')
                                            .isError
                                    }
                                    helperText={
                                        validationErrObj('newCheckpointName')
                                            .filteredError[0]?.message
                                    }
                                />
                            </Box>
                        )}

                        {/* Checkbox */}
                        <Box className={classes.inputGroup}>
                            <FormControlLabel
                                label="Set this task as checkpoint task"
                                control={
                                    <Checkbox
                                        checked={isCheckpointTask}
                                        onChange={onCheckboxClick}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                        color="secondary"
                                        size="small"
                                    />
                                }
                            />
                        </Box>
                    </DialogContent>
                </div>

                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleUpdate}
                        disabled={updateingTask}
                    >
                        {updateingTask ? 'Please wait' : 'Update'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
