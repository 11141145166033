import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogActions } from '@mui/material';
import { UsersSearchAndSelect } from '../../admin/UsersSearchAndSelect';
import { Box, Typography } from '@mui/material';
import { CloseIcon } from '../../icons';
import { visibilities } from '../../util/visibility';

const useStyles = makeStyles()((theme) => ({
    button: {
        textTransform: 'none', // To prevent uppercase transformation of text
    },
    selectedIconButton: {
        marginLeft: theme.spacing(1),
    },
    dialog: {
        minWidth: '90vw',
        padding: 40,
        [theme.breakpoints.up('md')]: {
            minWidth: 1000,
        },
    },
}));

export const VisibilityButton = ({
    selectedVisbilityOption,
    setSelectedVisbilityOption,
    selectedUsers,
    setSelectedUsers,
    clientId,
    visibilityOptions = [
        {
            option: visibilities.EVERYONE.visibility,
            icon: visibilities.EVERYONE.icon,
        },
        {
            option: visibilities.STARWEAVER_TEAM.visibility,
            icon: visibilities.STARWEAVER_TEAM.icon,
        },
    ],
    onUsersDialogClose,
}) => {
    const { classes } = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    // const [selectedVisbilityOption, setSelectedVisbilityOption] = useState('Starweaver team');

    const initialIcon =
        visibilityOptions?.find(
            ({ option }) => option == selectedVisbilityOption
        )?.icon || visibilityOptions[0].icon;

    const [selectedIcon, setSelectedIcon] = useState(initialIcon);
    const [showUsersDialog, setShowUsersDialog] = useState(false);
    // const [selectedUsers, setSelectedUsers] = useState([]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOptionSelect = (option, icon) => {
        setSelectedVisbilityOption(option);
        setSelectedIcon(icon);
        handleClose();
        if (option === 'Selected users') {
            setShowUsersDialog(true);
        } else {
            setShowUsersDialog(false);
        }
    };

    const handleDialogClose = () => {
        setShowUsersDialog(false);
        if (onUsersDialogClose) onUsersDialogClose();
    };

    // const visibilityOptions = [
    //     { option: 'Starweaver team', icon: <VerifiedUserIcon /> },
    //     { option: 'Client', icon: <BusinessIcon /> },
    //     { option: 'Admins', icon: <GroupIcon /> },
    //     { option: 'Everyone', icon: <PublicIcon /> },
    //     { option: 'Selected users', icon: <PersonAddIcon /> },
    //     { option: 'Only me', icon: <LockIcon /> },
    // ];

    return (
        <div>
            <Button
                className={classes.button}
                aria-haspopup="true"
                onClick={handleClick}
                startIcon={selectedIcon}
            >
                {selectedVisbilityOption}
            </Button>
            {selectedVisbilityOption === 'Selected users' && (
                <IconButton
                    className={classes.selectedIconButton}
                    size="small"
                    aria-label={`Select users (${selectedUsers.length})`}
                    onClick={() => setShowUsersDialog(true)}
                >
                    <PersonAddIcon />
                    {selectedUsers.length > 0 && (
                        <Box
                            component="span"
                            ml={1}
                            fontSize={12}
                            fontWeight="bold"
                        >
                            {selectedUsers.length}
                        </Box>
                    )}
                </IconButton>
            )}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {visibilityOptions.map(({ option, icon }) => (
                    <MenuItem
                        key={option}
                        onClick={() => handleOptionSelect(option, icon)}
                    >
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText primary={option} />
                    </MenuItem>
                ))}
            </Popover>
            <Dialog
                open={showUsersDialog}
                onClose={handleDialogClose}
                classes={{ paper: classes.dialog }}
                fullWidth
            >
                <div className={classes.dialogContent}>
                    <Box display="flex">
                        <Typography
                            variant="h6"
                            style={{ marginRight: 'auto' }}
                        >
                            Select users
                        </Typography>

                        <IconButton
                            aria-label="close"
                            onClick={handleDialogClose}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <DialogContent>
                        <UsersSearchAndSelect
                            selected={selectedUsers}
                            setSelected={setSelectedUsers}
                            admin
                            instructor
                            clientId={clientId}
                            searchInputSize="small"
                        />
                        <DialogActions>
                            <Box className={classes.dialogAction}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ margin: 0 }}
                                    onClick={handleDialogClose}
                                >
                                    Select
                                </Button>
                            </Box>
                        </DialogActions>
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    );
};
