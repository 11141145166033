import { Box, Grid, Skeleton } from '../ui';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    rootStyle: {
        width: '85%',
        paddingLeft: '5px',
        height: '50px',
    },
    mainBoxWrap: {
        position: 'relative',
        minHeight: 200,
    },
    mainBox: {
        width: '100%',
        borderRadius: '8px',
        //marginLeft: '20px',
        //marginRight: '20px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 13px 2px #AAA8A8',
        marginTop: '10px',
        position: 'relative',
    },
    root: {
        backgroundColor: '#ddd',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        borderRadius: '6px',
        minHeight: '200px',
        width: '100%',
    },
    contentBox: {
        width: '100%',
        margin: '0 auto',
        padding: 16,
        transition: 'all 0.3s ease-in',
        minHeight: '70px',
    },
    btn: {
        position: 'absolute',
        top: '0px',
        left: '0px',
    },
}));

export const CoursesListItemSkeleton = ({ xs, md, lg }) => {
    const { classes } = useStyles();

    return (
        <Grid item xs={xs} md={md} lg={lg}>
            <Box className={classes.mainBoxWrap}>
                <Box className={classes.mainBox}>
                    <Skeleton
                        variant="rectangular"
                        className={classes.rootStyle}
                        height={200}
                    />

                    <Box component="div" className={classes.contentBox}>
                        <Skeleton
                            variant="text"
                            className={classes.rootStyle}
                        />

                        <Skeleton
                            variant="text"
                            className={classes.rootStyle}
                        />
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
};
