import { Box, Loading, Tooltip, Typography } from '../ui';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { orange } from '@mui/material/colors';
import Carousel from 'react-material-ui-carousel';

export const BannerSideContent = ({
    isSmall = false,
    isLoading = false,
    groupedCourses = [],
    notClickable,
}) => {
    let navigate = useNavigate();
    const { classes } = useStyles({ notClickable });

    const handleClick = (onAir) => {
        if (notClickable) return;

        if (onAir.isMTG) {
            navigate(`/on-air/meet-the-gurus/${onAir.name}`);
        } else {
            navigate(`/on-air/${onAir.name}`);
        }
    };

    return (
        <Box
            height="100%"
            // display={'flex'}
            // justifyContent={'center'}
            // alignItems={'center'}
        >
            {isLoading ? (
                <Loading height="100%" />
            ) : (
                <Carousel
                    animation="slide"
                    navButtonsAlwaysInvisible={true}
                    interval={20000}
                    autoPlay={notClickable ? false : true}
                    indicators={notClickable ? false : true}
                >
                    {groupedCourses.map((carouselCourses, i) => (
                        <Box
                            key={i}
                            style={{
                                // marginTop: 50,
                                display: 'grid',
                                gap: '10px',
                                gridTemplateColumns: isSmall
                                    ? '1fr'
                                    : '1fr 1fr',
                            }}
                            minHeight={isSmall ? 220 : 500}
                        >
                            {carouselCourses.map((item, index) => (
                                <Tooltip
                                    key={index}
                                    title={
                                        notClickable ? '' : 'Show live class'
                                    }
                                >
                                    <Box
                                        onClick={() => handleClick(item)}
                                        className={classes.container}
                                    >
                                        {item.isLive && (
                                            <Typography
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: '2',
                                                    top: '10px',
                                                    left: '10px',
                                                    padding: '4px 8px',
                                                    fontSize: 'min(2vw, 1em)',
                                                    fontWeight: 'bold',
                                                    borderRadius: 2,
                                                    backgroundColor:
                                                        orange[500],
                                                }}
                                            >
                                                STREAMING NOW
                                            </Typography>
                                        )}
                                        <Box
                                            width="100%"
                                            className={classes.overlay}
                                        >
                                            <img
                                                fetchpriority="high"
                                                src={item.thumbnail}
                                                alt={item.title}
                                                className={classes.img}
                                                width="731px"
                                                height="200px"
                                            />
                                        </Box>
                                        <Box className={classes.details}>
                                            <Typography
                                                style={{
                                                    fontSize: 'inherit',
                                                }}
                                            >
                                                Duration: {item.duration}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontSize: 'inherit',
                                                }}
                                            >
                                                {item.date} | {item.time} |{' '}
                                                {item.timeZone}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Tooltip>
                            ))}
                        </Box>
                    ))}
                </Carousel>
            )}
            {/* <Box
                style={{
                    marginTop: 50,
                    display: 'grid',
                    gap: '10px',
                    gridTemplateColumns: '1fr 1fr',
                }}
            >
                {isLoading ? (
                    <Loading height="100%" />
                ) : (
                    topClasses.map((item) => (
                        <Tooltip title={'Show live class'}>
                            <Box
                                onClick={() => handleClick(item)}
                                className={classes.container}
                            >
                                {item.isLive && (
                                    <Typography
                                        style={{
                                            position: 'absolute',
                                            zIndex: '2',
                                            top: '10px',
                                            left: '10px',
                                            padding: '4px 8px',
                                            fontSize: 'min(2vw, 1em)',
                                            fontWeight: 'bold',
                                            borderRadius: 2,
                                            backgroundColor: orange[500],
                                        }}
                                    >
                                        LIVE
                                    </Typography>
                                )}
                                <Box width="100%" className={classes.overlay}>
                                    <img
                                        src={item.thumbnail}
                                        alt={item.title}
                                        className={classes.img}
                                    />
                                </Box>
                                <Box className={classes.details}>
                                    <Typography
                                        style={{
                                            fontSize: 'inherit',
                                        }}
                                    >
                                        Duration: {item.duration}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontSize: 'inherit',
                                        }}
                                    >
                                        {item.date} | {item.time} |{' '}
                                        {item.timeZone}
                                    </Typography>
                                </Box>
                            </Box>
                        </Tooltip>
                    ))
                )}
            </Box> */}
        </Box>
    );
};

const useStyles = makeStyles()((theme) => ({
    img: {
        width: '100%',
        height: 'auto',
        zIndex: '-1',
        transition: '0.2s ease-out',
        boxShadow: '0px 1px 10px rgba(0,0,0,0.1)',
    },
    container: {
        cursor: 'pointer',
        height: 'fit-content',
        position: 'relative',
        '&:hover': {
            '& $img': {
                transform: 'scale(1.02)',
                boxShadow: '0px 0px 5px 3px rgba(0,0,0,0.4)',
            },
        },
    },
    // _container: ({ notClickable }) => {
    //     return {
    //         cursor: notClickable ? 'default' : 'pointer',
    //         height: 'fit-content',
    //         position: 'relative',
    //         '&:hover': notClickable
    //             ? { cursor: 'default' }
    //             : {
    //                   '& $img': {
    //                       transform: 'scale(1.02)',
    //                       boxShadow: '0px 0px 5px 3px rgba(0,0,0,0.4)',
    //                   },
    //               },
    //     };
    // },
    overlay: {
        position: 'relative',
        '&::after': {
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            width: '100%',
            height: '97%',
            zIndex: 1,
            backgroundColor: 'rgba(0,0,0,0.3)',
        },
    },
    details: {
        position: 'absolute',
        zIndex: 3,
        bottom: 10,
        left: 10,
        color: '#fafafa',
        fontWeight: 600,
        fontSize: 'min(3vw, 0.9em)',
    },
}));
