import moment from 'moment';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { alertState, severity } from '../../../app/recoil';
import { CloseIcon } from '../../../icons';
import TimelineResetReasonModal from '../OMDetails/TimelineResetReasonModal';

const useStyles = makeStyles()((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0.5rem 1rem 0.5rem 1.5rem',
    },
    title: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        padding: 0,
        margin: 0,
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
        },
    },
}));

const INPUT_DATE_FORMAT = 'YYYY-MM-DD';

const ResetTimelineModal = ({
    requirementId,
    loadRequirementData,
    open,
    setOpen,
    updateRequirement,
    showAssignedUserView,
    getAssignedUserGroupData,
    fetchLogisticsByRole,
    showRoleView,
    showChronologyView,
    fetchLogisticsByChronology,
    filterBy = '',
}) => {
    const { classes } = useStyles();
    const setAlert = useSetRecoilState(alertState);
    const [expectedEndDate, setExpectedEndDate] = useState('');
    const [resetingTimeline, setResetingTimeline] = useState(false);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reasonForChangeEndDate, setReasonForChangeEndDate] = useState('');

    useEffect(() => {
        let willUnmount = false;
        if (willUnmount) return;
        const currDate = moment(Date.now()).format(INPUT_DATE_FORMAT);
        setExpectedEndDate(currDate);
        return () => {
            willUnmount = true;
        };
    }, []);

    /**
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    const onReleaseDateChange = (event) => {
        const date = moment(event.target.value).format(INPUT_DATE_FORMAT);
        setExpectedEndDate(date);
    };

    const resetTimeline = () => {
        setShowReasonModal(true);
    };

    const updateExpectedEndDate = async () => {
        if (updateRequirement)
            await updateRequirement({
                _id: requirementId,
                id: requirementId,
                reasonForChangeEndDate,
                expectedEndDate: moment(expectedEndDate).toISOString(),
            });
    };

    const closeDialog = () => {
        if (resetingTimeline) return;
        setOpen(false);
    };

    const handleDateChangeConfirm = async () => {
        try {
            if (reasonForChangeEndDate.trim().length === 0) {
                return setAlert({
                    show: true,
                    message: "Reason can't left empty",
                    severity: severity.ERROR,
                });
            }

            setResetingTimeline(true);
            setShowReasonModal(false);
            await updateExpectedEndDate();
            await loadRequirementData(requirementId);
            if (showAssignedUserView && getAssignedUserGroupData) {
                await getAssignedUserGroupData(requirementId, filterBy);
            }

            if (showRoleView && fetchLogisticsByRole) {
                await fetchLogisticsByRole(requirementId, filterBy);
            }

            if (showChronologyView && fetchLogisticsByChronology) {
                await fetchLogisticsByChronology(requirementId, filterBy);
            }
        } catch (e) {
            setAlert({
                show: true,
                message: "Something wen't worng. Please try after some time.",
                severity: severity.ERROR,
            });
        } finally {
            setResetingTimeline(false);
            closeDialog();
        }
    };

    return (
        <div>
            {showReasonModal && (
                <TimelineResetReasonModal
                    open={showReasonModal}
                    expectedEndDate={expectedEndDate}
                    handleDateChangeConfirm={handleDateChangeConfirm}
                    handleReasonChange={(e) =>
                        setReasonForChangeEndDate(e.target.value)
                    }
                    reasonForChangeEndDate={reasonForChangeEndDate}
                    setOpen={setShowReasonModal}
                />
            )}

            <Dialog
                aria-labelledby="reset-timeline-dialog-box"
                open={open}
                fullWidth
                maxWidth="xs"
            >
                {/* Dialog Header */}
                <Box className={classes.header}>
                    <p className={classes.title} id="reset-timeline-dialog-box">
                        Reset Release & Timeline
                    </p>

                    <IconButton
                        size="small"
                        color="default"
                        onClick={closeDialog}
                        disabled={resetingTimeline}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <DialogContent>
                    <TextField
                        id="expectedEndDate"
                        name="expectedEndDate"
                        type="date"
                        helperText="Enter new release date"
                        variant="outlined"
                        color="secondary"
                        value={expectedEndDate}
                        onChange={onReleaseDateChange}
                        required
                        fullWidth
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        color="secondary"
                        variant="text"
                        onClick={resetTimeline}
                        disabled={resetingTimeline}
                    >
                        {resetingTimeline ? 'Please wait' : 'Reset'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ResetTimelineModal;
