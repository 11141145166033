import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

function useFetchReleaseStageOfReq({ requirementId }) {
    const [loading, setLoading] = useState(false);
    const [releaseStage, setReleaseStage] = useState('-');

    const fetchReleaseStage = useCallback(async (requirementId) => {
        try {
            setLoading(true);
            const baseURL = '/api/client-requirements/get-release-stage';
            const params = new URLSearchParams();
            params.append('requirementId', requirementId);
            const url = `${baseURL}?${params.toString()}`;
            const res = await axios.get(url);
            const { data } = res;
            setReleaseStage(data.releaseStage);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!requirementId) return;
        fetchReleaseStage(requirementId);
    }, [requirementId, fetchReleaseStage]);

    return { loading, releaseStage };
}

export default useFetchReleaseStageOfReq;
