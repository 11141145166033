import axios from 'axios';
import { useState } from 'react';

function useExportLogisticsData() {
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const downloadLogisticsData = async ({ categories, requirementId }) => {
        try {
            setLoading(true);
            const response = await axios.post(
                '/api/client-requirements/download-logistics-data',
                { categories, requirementId },
                {
                    responseType: 'blob',
                }
            );

            const blob = new Blob([response.data], { type: 'text/csv' });
            return blob;
        } catch (e) {
            console.log(e);
            setIsError(true);
            if (e?.response?.data?.status === 400) {
                setErrorText(e.response.data.message);
            } else {
                setErrorText(e.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return { loading, isError, downloadLogisticsData, errorText };
}

export default useExportLogisticsData;
