import { useState, useRef } from 'react';
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grid,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '../ui';

import { ArrowDropDownIcon } from '../icons';

export const LinkButton = ({ options }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleClick = () => {
        // push that to history
        console.info(`You clicked ${options[selectedIndex].name}`);
        console.log(1);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        // push that to history
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <div>
            <Grid container direction="column" alignItems="center">
                <Grid item xs={12}>
                    <ButtonGroup
                        variant="contained"
                        color="secondary"
                        ref={anchorRef}
                    >
                        <Button onClick={handleClick}>
                            {options[selectedIndex].name}
                        </Button>
                        <Button
                            color="primary"
                            size="small"
                            aria-controls={
                                open ? 'split-button-menu' : undefined
                            }
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon color="secondary" />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom'
                                            ? 'center top'
                                            : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener
                                        onClickAway={handleClose}
                                    >
                                        <MenuList id="split-button-menu">
                                            {options.map((option, index) => (
                                                <MenuItem
                                                    key={index}
                                                    selected={
                                                        index === selectedIndex
                                                    }
                                                    onClick={(event) =>
                                                        handleMenuItemClick(
                                                            event,
                                                            index
                                                        )
                                                    }
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>
        </div>
    );
};
