import React from 'react';
import {
    Box,
    Radio,
    RadioGroup,
    FormControl,
    // FormLabel,
    FormControlLabel,
    Typography,
} from '..';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    settings: {
        padding: '14px',
        border: '1px solid #c5c5c5',
        borderRadius: '2px',
    },
}));

export const RadioSelectCard = ({
    title,
    setValue,
    value,
    options,
    disabled,
}) => {
    const { classes } = useStyles();

    const handleChange = (value) => {
        setValue(value);
    };

    return (
        <Box className={classes.settings}>
            <Box>
                <Typography variant="h6">{title}</Typography>
            </Box>
            <FormControl
                variant="outlined"
                color="secondary"
                component="fieldset"
                style={{
                    padding: '16px',
                }}
            >
                <RadioGroup
                    value={value}
                    onChange={(e) => handleChange(e.target.value)}
                >
                    {options.map((option) => (
                        <FormControlLabel
                            key={option.name}
                            value={option.value}
                            style={{
                                marginTop: '10px',
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}
                            control={<Radio />}
                            label={
                                <Box
                                    marginTop={'4px'}
                                    display={'flex'}
                                    flexDirection="column"
                                >
                                    <Typography variant="h6">
                                        {option.name}
                                    </Typography>
                                    {option.description && (
                                        <Typography variant="subtitle1">
                                            {option.description}
                                        </Typography>
                                    )}
                                </Box>
                            }
                            disabled={disabled}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Box>
    );
};
