import { useState, useRef, useCallback } from 'react';

export const useShowOnViewPortIntersection = () => {
    const [show, setShow] = useState(false);
    const observer = useRef();
    const containerRef = useCallback(
        (node) => {
            if (observer.current && show) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0]?.isIntersecting) {
                    setShow(true);
                }
            });
            if (node) observer.current.observe(node);
        },
        [show]
    );

    return [show, containerRef];
};
