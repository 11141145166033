//import { coursesSlice } from '../courses/coursesSlice';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { useCurrentUser } from '../my-account/useCurrentUser';
import axios from 'axios';
import {
    /* getPurchasedCourseState,
    setPurchasedCourseState, */
    purchasedRetroCoursesState,
} from './Recoil/State';

/* const { getCourses } = coursesSlice;

const getAllCourse = () => (state) => {
    const courses = getCourses(state);
    return courses;
}; */

export const useGetPurchasedRetroCourses = () => {
    //const course = useSelector(getAllCourse());
    const { user } = useCurrentUser();
    const purchasedRetroCourses = useRecoilValue(purchasedRetroCoursesState);
    const setPurchasedRetroCourses = useSetRecoilState(
        purchasedRetroCoursesState
    );
    //const [purchasedJourneys, setPurchasedJourneys] = useState([]);

    useEffect(() => {
        //if (purchasedJourneys.length === 0) {
        const getPurchasedJourneysState = async () => {
            const {
                data: { products },
            } = await axios.get(
                `/api/users/${user._id}/purchased-retro-courses`
            );
            setPurchasedRetroCourses(products);
        };
        if (user) {
            getPurchasedJourneysState();
        }
        //}
    }, [user]);

    return { purchasedRetroCourses };
};
