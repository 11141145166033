import React, { useEffect } from 'react';
import { Loading } from '../ui';

export const SwitchDomain = ({ domain }) => {
    useEffect(() => {
        const newUrl =
            (domain || process.env.REACT_APP_DOMAIN) + window.location.pathname;
        window.location.replace(newUrl);
    }, [domain]);

    return <Loading />;
};
