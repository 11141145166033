import { Box, Typography } from '@mui/material';
import useStyles from './styles';

function DropBoxHeader({ handleSelectClick }) {
    const { classes } = useStyles();
    return (
        <Box className={classes.dropboxHeader}>
            <Typography variant="body2">
                Drop or{' '}
                <span
                    className={classes.dropboxHeader__selectText}
                    onClick={handleSelectClick}
                >
                    Select
                </span>{' '}
                files &nbsp;
                <span>(Add comment before uploading files)</span>
            </Typography>

            <Typography variant="body2">
                <span className={classes.dropboxHeader__noteText}>Note:</span>{' '}
                Can add up to 5 files
            </Typography>
        </Box>
    );
}

export default DropBoxHeader;
