import { atom } from 'recoil';

export const userState = atom({
    key: 'userState',
    default: null,
});

export const userIsAdminState = atom({
    key: 'userIsAdminState',
    default: false,
});

export const userIsStarweaverAdminState = atom({
    key: 'userIsStarweaverAdminState',
    default: false,
});

export const verifiedEmail = atom({
    key: 'verifiedEmail',
    default: false,
});

export const userCreditsPointsState = atom({
    key: 'userCreditsPointsState',
    default: false,
});

export const startdustState = atom({
    key: 'startdustState',
    default: {},
});
