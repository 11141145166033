import { createSlice } from '@reduxjs/toolkit';

export const instructorExpertiseSlice = createSlice({
    initialState: [],
    name: 'instructorExpertise',
    reducers: {
        updateExpertise: (state, action) => {
            const { payload } = action;
            const { expertise } = payload;
            if (!expertise) return [...state];
            return [...expertise];
        },
    },
});

export const { updateExpertise } = instructorExpertiseSlice.actions;
export default instructorExpertiseSlice.reducer;
