import { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';

export const useTeams = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [teams, setTeams] = useState([]);

    const loadTeams = async () => {
        setIsLoading(true);
        try {
            console.log('teams loading');
            const { data } = await axios.get('/api/admin/teams/');

            setTeams(data.teams);
        } catch (error) {
            setTeams([]);
            console.error(error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        loadTeams();
    }, []);

    return { isLoading, teams, loadTeams };
};
