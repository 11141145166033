import React, { useCallback } from 'react';

import { Box, Typography, Tooltip, Button } from '@mui/material';

export const ClientRequirementsSMECell = ({
    handleSMEClick,
    classes,
    param,
    starweaverAdmin,
    column,
}) => {
    const echoShortlistedSmes = useCallback((param, isSingle) => {
        if (isSingle) {
            const name = `${
                param.value[0]?.firstName || param.value[0]?.user_firstName
            } ${param.value[0]?.lastName || param.value[0]?.user_lastName}`;
            return {
                tooltip: name,
                value: name,
            };
        }

        const name = param.value?.map(
            (s) =>
                (s?.firstName || s?.user_firstName) +
                ' ' +
                (s?.lastName || s?.user_lastName || '')
        );

        return {
            tooltip: name.join(', '),
            value: name?.join(', ')?.substring(0, 18)?.concat('...'),
        };
    }, []);

    return (
        <Box
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // if (!row?.matchedInstructors?.length) return;
                // SetInstructorsToShow(row?.matchedInstructors || []);
                // SetIsInstructorsDialogOpened(true);
                handleSMEClick(e, param.row, column);
            }}
            className={classes.link}
        >
            <Typography
                style={{
                    width: '100%',
                    // paddingLeft: '2rem',
                }}
            >
                {param.value?.length ? (
                    param.value?.length > 1 ? (
                        // param.value
                        //     ?.map(
                        //         (s) =>
                        //             s?.firstName +
                        //                 ' ' +
                        //                 s?.lastName || ''
                        //     )
                        //     ?.join(', ')
                        //     ?.substring(0, 18)
                        //     ?.concat('...')
                        <Tooltip
                            title={echoShortlistedSmes(param, false).tooltip}
                            placement="top-start"
                        >
                            <span>
                                {echoShortlistedSmes(param, false).value}
                            </span>
                        </Tooltip>
                    ) : (
                        // `${param.value[0]?.firstName} ${param.value[0]?.lastName}`

                        <Tooltip
                            title={echoShortlistedSmes(param, true).tooltip}
                            placement="top-start"
                        >
                            <span>
                                {echoShortlistedSmes(param, true).value}
                            </span>
                        </Tooltip>
                    )
                ) : starweaverAdmin ? (
                    <Button>Add</Button>
                ) : (
                    <>-</>
                )}
            </Typography>
        </Box>
    );
};
