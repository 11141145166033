import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
    Actions,
    Loading,
    CircularProgress,
    IconButton,
    Typography,
} from '../ui';
import { CloseIcon } from '../icons';
import { SMEsSearchAndSelect2 } from './SMEsSearchAndSelect2';
import { SMEForm } from './SMEForm';
import { ConfirmDialog } from '../ui';
import { InviteUserForm } from '../admin/InviteUserForm';
import { PersonAddIcon } from '../icons';
// import {DataGridPremium, GRID_CHECKBOX_SELECTION_COL_DEF  } from '@mui/x-data-grid-premium';
// import { VariablePieChart } from '../custom-components/charts';
// import { async } from 'q';
// import { useIsAdmin } from './useIsAdmin';

import { fieldToHeader } from '../admin/ClientRequirements';

const useStyles = makeStyles()((theme) => ({
    paper: {
        minWidth: '90vw',
        padding: 40,
        [theme.breakpoints.up('md')]: {
            minWidth: 1000,
        },
    },
    // tableContainer: {
    //     height: 280,
    //     width: '100%',
    // },
    // dialogAction: {
    //     marginRight: 'auto',
    //     marginLeft: '1rem',
    //     paddingBottom: '0.5rem',
    // },
}));

const SMEModal2 = ({
    smeModal = { isOpen: true, smes: [] },
    setSmeModal,
    setAllRequirements,
    loadRequirementData = undefined,
    changeStatus,
    selectOnly,
    onSmesSelect,
    setIsBulkUpdateModalOpen = undefined,
    onSaveFn = undefined,
}) => {
    const { classes } = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    // const [showSMEForm, setShowSMEForm] = useState(false);
    // const [smeIdToEdit, setSMEIdToEdit] = useState(null);

    const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);

    const [
        openChangeStatusConfirmDialog,
        setOpenChangeStatusConfirmDialog,
    ] = useState(false);

    const [
        isLoadingChangeStatusConfirmDialog,
        setIsLoadinghangeStatusConfirmDialog,
    ] = useState(false);

    //DIALOG CLOSE HANDLER FUNCTION
    const handleDialogClose = () => {
        setSmeModal((prev) => {
            return {
                smes: [],
                isOpen: false,
                id: null,
                title: null,
                status: null,
            };
        });

        if (setIsBulkUpdateModalOpen) {
            setIsBulkUpdateModalOpen(true);
        }
    };

    const handleChangeStatusConfirmDialogClose = () => {
        setOpenChangeStatusConfirmDialog(false);
    };

    const handleChangeStatusConfirm = async () => {
        setIsLoadinghangeStatusConfirmDialog(true);
        await changeStatus('IN_PROGRESS', [smeModal.id]);

        setIsLoadinghangeStatusConfirmDialog(false);
        setOpenChangeStatusConfirmDialog(false);
        window.scroll(0, 100);
        handleDialogClose();
    };

    const handleChangeStatusCancle = () => {
        setOpenChangeStatusConfirmDialog(false);
        window.scroll(0, 100);
        handleDialogClose();
    };

    const handleInviteUser = () => {
        setIsInviteUserModalOpen(true);
    };

    const onInviteUser = (user) => {
        setSmeModal((prev) => {
            return {
                ...prev,
                smes: [
                    {
                        _id: user._id,
                        firstName: user.user_firstName,
                        lastName: user.user_lastName,
                        email: user.user_email,
                        linkedin: user.linkedin,
                        profilePic: user.profilePic,
                        inviteStatus: 'pending',
                    },

                    ...prev.smes,
                ],
            };
        });
    };

    const saveSMEs = async () => {
        if (selectOnly) {
            if (onSmesSelect) onSmesSelect(smeModal.smes, smeModal.column);
            handleDialogClose();

            // setSmeModal((prev) => {
            //     return {
            //         ...prev,
            //         isOpen: false,
            //     };
            // });
            return;
        }

        if (onSaveFn) {
            setIsSaving(true);
            await onSaveFn({
                id: smeModal.id,
                smeIds: smeModal.smes?.map(({ _id }) => _id),
            });
            setIsSaving(false);
            return;
        }

        try {
            setIsSaving(true);
            await axios.post('/api/smes/set2', {
                clientRequirementIds: [smeModal.id],
                smeIds: smeModal.smes?.map(({ _id }) => _id),
                assignedSMEIds: smeModal.assignedSMEs?.map(({ _id }) => _id),
                // setShortlisted: smeModal.showShortListedSmes,
                column: smeModal.column,
            });

            if (setAllRequirements) {
                setAllRequirements((prev) => {
                    const requirementsCopy = [...prev];
                    const requirementToEdit = requirementsCopy.find(
                        ({ _id }) => _id === smeModal.id
                    );
                    if (smeModal.column === 'shortListedSmes2') {
                        requirementToEdit.shortListedSmes2 = smeModal.smes;
                        // requirementToEdit.smes = smeModal.assignedSMEs;

                        const unassignedSMEs = smeModal.smes?.filter(
                            (ssme) =>
                                smeModal.assignedSMEs.findIndex(
                                    (asme) => asme._id === ssme._id
                                ) === -1
                        );

                        let newAssignedSMEs = [...requirementToEdit?.smes2];

                        newAssignedSMEs = newAssignedSMEs.filter(
                            (asme) =>
                                unassignedSMEs.findIndex(
                                    (usme) => asme._id === usme._id
                                ) === -1
                        );

                        smeModal.assignedSMEs.forEach((asme) => {
                            if (
                                newAssignedSMEs.findIndex(
                                    (nasme) => nasme._id === asme._id
                                ) === -1
                            )
                                newAssignedSMEs.push(asme);
                        });

                        requirementToEdit.smes2 = newAssignedSMEs;
                    } else requirementToEdit[smeModal.column] = smeModal.smes;
                    const requirementOthers = requirementsCopy.filter(
                        ({ _id }) => _id != smeModal.id
                    );

                    const ret = [requirementToEdit, ...requirementOthers];

                    return ret;
                });
            }

            if (loadRequirementData) {
                await loadRequirementData(smeModal.id);
            }

            setIsSaving(false);

            if (
                // smeModal.showShortListedSmes &&
                smeModal.column === 'shortListedSmes2' &&
                smeModal.smes.length &&
                smeModal.assignedSMEs?.length &&
                (smeModal.status === 'PENDING' || smeModal.status === 'DRAFT')
            ) {
                setOpenChangeStatusConfirmDialog(true);
            } else {
                window.scroll(0, 100);
                handleDialogClose();
            }
        } catch (error) {
            setIsSaving(false);
        }
    };

    const onClickSelected = (sme) => {
        // console.log(sme);
        // setSMEIdToEdit(sme._id);
        // setShowSMEForm(true);
    };

    return (
        <Box>
            <Dialog
                onClose={handleDialogClose}
                aria-labelledby="matching-instructor-data-modal"
                open={smeModal.isOpen}
                maxWidth="lg"
                classes={{ paper: classes.paper }}
                // disableAutoFocus
                // disableEnforceFocus
                // disableRestoreFocus
            >
                <Box display="flex">
                    {selectOnly && false ? (
                        <>
                            <Typography
                                variant="h6"
                                style={{ marginRight: 'auto' }}
                            >
                                Select Instructors
                            </Typography>
                        </>
                    ) : false ? (
                        <Typography
                            variant="h6"
                            style={{ marginRight: 'auto' }}
                        >
                            {/* Add Instructor */}
                        </Typography>
                    ) : (
                        <Typography
                            variant="h6"
                            style={{ marginRight: 'auto' }}
                        >
                            {fieldToHeader[smeModal.column]} for "
                            {smeModal.title}"
                        </Typography>
                    )}

                    <IconButton
                        aria-label="close"
                        onClick={handleDialogClose}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                {false ? (
                    <DialogContent>
                        {/*  <SMEForm
                            setShowSMEForm={setShowSMEForm}
                            setSmeModal={setSmeModal}
                            smeIdToEdit={smeIdToEdit}
                            setSMEIdToEdit={setSMEIdToEdit}
                        /> */}
                    </DialogContent>
                ) : (
                    <>
                        <DialogContent>
                            <Box display="flex" flexDirection="row-reverse">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleInviteUser}
                                    endIcon={<PersonAddIcon />}
                                >
                                    Invite user
                                </Button>
                            </Box>
                            <SMEsSearchAndSelect2
                                selected={smeModal.smes}
                                setSmeModal={setSmeModal}
                                onClickSelected={onClickSelected}
                                selectedListView="list"
                                selectedWithinSelected={smeModal.assignedSMEs}
                                showSelectionWithinSelection={
                                    smeModal.showShortListedSmes
                                }
                                column={smeModal.column}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Box className={classes.dialogAction}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ margin: 0 }}
                                    onClick={saveSMEs}
                                >
                                    {isSaving ? (
                                        <CircularProgress size="1.9em" />
                                    ) : selectOnly ? (
                                        <>Select</>
                                    ) : (
                                        <>Save</>
                                    )}
                                </Button>
                            </Box>
                        </DialogActions>
                    </>
                )}
            </Dialog>

            <ConfirmDialog
                onClose={handleChangeStatusConfirmDialogClose}
                open={openChangeStatusConfirmDialog}
                onConfirm={handleChangeStatusConfirm}
                title="Move to in progress?"
                onCancle={handleChangeStatusCancle}
                isLoading={isLoadingChangeStatusConfirmDialog}
                confirmLabel={'Yes'}
                cancleLabel={'No'}
            />

            <InviteUserForm
                open={isInviteUserModalOpen}
                setOpen={setIsInviteUserModalOpen}
                isCollaborator={[
                    'collaborators',
                    'addedCollaborators',
                ].includes(smeModal.column)}
                hideRoles
                onInviteUser={onInviteUser}
            />
        </Box>
    );
};

export default SMEModal2;
