import React from 'react';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import AddIcon from '../../icons/add.png';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        generateText: {
            fontSize: '1rem',
            marginRight: '0.5rem',
        },
    };
});

function GenerateButton({
    onClick,
    tooltipTitle,
    disabled,
    buttonText = 'Generate',
    loading,
}) {
    const classes = useStyles();

    return (
        <Tooltip placement="top-start" title={tooltipTitle}>
            <Button
                color="secondary"
                onClick={onClick}
                disabled={disabled || loading}
            >
                <span className={classes.generateText}>{buttonText}</span>
                {loading ? (
                    <CircularProgress
                        sx={{ marginLeft: (theme) => theme.spacing(0.5) }}
                        size={15}
                        color="secondary"
                    />
                ) : (
                    <img
                        src={AddIcon}
                        alt="generate-keywords"
                        loading="eager"
                        height={20}
                        width={20}
                    />
                )}
            </Button>
        </Tooltip>
    );
}

export { GenerateButton };
