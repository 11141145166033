import { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
// import { useRecoilState } from 'recoil';
// import { commentState } from './recoil';
import { useCurrentUser } from '../my-account/useCurrentUser';

export const useComments = (courseId) => {
    // const [comments, setComments] = useRecoilState(commentState);
    const [comments, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { user } = useCurrentUser();

    const fetchComments = useCallback(
        async (searchText = '') => {
            setIsLoading(true);
            try {
                console.log('Fetching comments...');
                let apiURL = `/api/content/${courseId}/comments`;

                if (searchText.trim().length > 0) {
                    const params = new URLSearchParams();
                    params.append('searchText', searchText);
                    apiURL = `${apiURL}?${params.toString()}`;
                }

                const {
                    data: { comments },
                } = await axios.get(apiURL);
                setComments(comments);
                setIsLoading(false);
                console.log('Fetched comments... +' + comments.length);
            } catch (error) {
                console.log(error.message);
                setIsLoading(false);
            }
        },
        [courseId, setComments]
    );

    useEffect(() => {
        if (courseId) {
            fetchComments();
        }
        // eslint-disable-next-line
    }, [courseId]);

    const commentsToShowUser = useMemo(() => {
        if (user) {
            if (comments?.length) {
                const filteredComments = [];
                comments.forEach((comment) => {
                    const canViewComment = !comment.reported_by?.some(
                        (report) => report.user_id === user._id
                    );
                    if (canViewComment) {
                        const { replies, ...rest } = comment;
                        const filteredReplies = replies.filter(
                            (reply) =>
                                !reply.reported_by?.some(
                                    (report) => report.user_id === user._id
                                )
                        );
                        filteredComments.push({
                            ...rest,
                            replies: filteredReplies,
                        });
                    }
                });
                return filteredComments;
            } else {
                return [];
            }
        } else {
            return comments;
        }
    }, [user, comments]);

    return {
        isLoading,
        comments: commentsToShowUser,
        updateComments: fetchComments,
        setComments,
    };
};
