export const clientRequirementCalenders = [
    {
        clientName: 'Coursera',
        clientId:
            process.env.REACT_APP_ENVIRONMENT === 'production'
                ? '6462297aecb0d896ae949f31'
                : '647f3b681de176891ca82501',
        batchNum: 1,
        link:
            'https://calendar.google.com/calendar/embed?src=7e4ec991d0a178028856f2dff1f0e1a8715ab13ce92533afd7aed96f09738afe%40group.calendar.google.com&ctz=America%2FLos_Angeles',
    },
    {
        clientName: 'Coursera',
        clientId:
            process.env.REACT_APP_ENVIRONMENT === 'production'
                ? '6462297aecb0d896ae949f31'
                : '647f3b681de176891ca82501',
        batchNum: 2,
        link:
            'https://calendar.google.com/calendar/embed?src=7db200bf45da151e852fd2a9d45d14aa749f029893306e4892b90b925a4b7d43%40group.calendar.google.com&ctz=America%2FLos_Angeles',
    },
    {
        clientName: 'HSBC',
        clientId:
            process.env.REACT_APP_ENVIRONMENT === 'production'
                ? '62d01f2a2a57c41310e141ff'
                : '62d01eed2b94f9128c5d4633',
        batchNum: 1,
        link:
            'https://calendar.google.com/calendar/embed?src=0d633b976b328e861d786a42ee21df0711f686743523ce7028f2f60cf4fc85ef%40group.calendar.google.com&ctz=America%2FLos_Angeles',
    },
];
