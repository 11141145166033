import { Box, Card, Typography, Tooltip, Skeleton } from '@mui/material';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import moneyImg from '../img/money.png';
import { makeStyles } from 'tss-react/mui';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const useStyles = makeStyles()((theme) => ({
    graphCard: {
        padding: theme.spacing(2),
        height: '100%',
        width: '100%',
    },
    tableContainer: {
        marginTop: theme.spacing(2),
    },
}));

const MarketMetricsRevenueTable = ({
    topicData,
    showMonthly = true,
    show25thPercentile = true,
    hideShadow = false,
    isLoading = false,
}) => {
    const { classes } = useStyles();

    return (
        <Card elevation={hideShadow ? 0 : 2} className={classes.graphCard}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img
                    src={moneyImg}
                    alt="Money icon"
                    style={{
                        width: '25px',
                        height: '25px',
                    }}
                />
                <Typography
                    variant="h6"
                    align="center"
                    fontWeight="bold"
                    marginBottom={1}
                    marginLeft={1}
                >
                    Revenue
                </Typography>
                <Tooltip
                    title={`In Beta. Current analysis is "stable state revenues for instructor when sold on Udemy" meaning when the course "gains traction". Based on the following Topic analysis: ${topicData.topic}`}
                >
                    <HelpOutlineIcon
                        sx={{
                            marginLeft: 0.5,
                            marginBottom: 0.5,
                            fontSize: '15px',
                        }}
                    />
                </Tooltip>
            </Box>
            {/* Monthly Revenue Table */}
            {showMonthly && (
                <Box className={classes.tableContainer}>
                    <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        marginBottom={1}
                    >
                        Monthly
                    </Typography>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Top 50th Percentile</TableCell>
                                <TableCell>Top 25th Percentile</TableCell>
                                <TableCell>Top 10th Percentile</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {/* <TableCell>
                                    ${topicData.revenue50Perc}
                                </TableCell>
                                <TableCell>
                                    ${topicData.revenue25Perc}
                                </TableCell>
                                <TableCell>
                                    ${topicData.revenue10Perc}
                                </TableCell> */}
                                <DataWithLoader
                                    isLoading={isLoading}
                                    data={topicData.revenue50Perc}
                                />
                                <DataWithLoader
                                    isLoading={isLoading}
                                    data={topicData.revenue25Perc}
                                />
                                <DataWithLoader
                                    isLoading={isLoading}
                                    data={topicData.revenue10Perc}
                                />
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            )}

            {/* Annual Revenue Table */}
            <Box className={classes.tableContainer}>
                <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    marginBottom={1}
                >
                    Annual
                </Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Top 50th Percentile</TableCell>
                            {show25thPercentile && (
                                <TableCell>Top 25th Percentile</TableCell>
                            )}
                            <TableCell>Top 10th Percentile</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {/* <TableCell>
                                ${topicData.revenueAnnual50Perc}
                            </TableCell>
                            {show25thPercentile && (
                                <TableCell>
                                    ${topicData.revenueAnnual25Perc}
                                </TableCell>
                            )}
                            <TableCell>
                                ${topicData.revenueAnnual10Perc}
                            </TableCell> */}
                            <DataWithLoader
                                isLoading={isLoading}
                                data={topicData.revenueAnnual50Perc}
                            />
                            {show25thPercentile && (
                                <DataWithLoader
                                    isLoading={isLoading}
                                    data={topicData.revenueAnnual25Perc}
                                />
                            )}
                            <DataWithLoader
                                isLoading={isLoading}
                                data={topicData.revenueAnnual10Perc}
                            />
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        </Card>
    );
};

const DataWithLoader = ({ isLoading, data }) => {
    return (
        <TableCell>
            {isLoading ? (
                <Skeleton variant="rectangular" width={100} />
            ) : (
                <>${data}</>
            )}
        </TableCell>
    );
};

export default MarketMetricsRevenueTable;
