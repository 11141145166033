import { useState } from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CloseIcon } from '../../../icons';
import { CommentsInput } from '../../../comments/CommentsInput';
import { useMemo } from 'react';
import { getUniqueObjectsByField } from '../../../util';
import { useTADs } from '../../hooks/useTADs';
import profilePicPlaceholder from '../../../img/profilePicPlaceholder.jpg';
import { useRecoilState } from 'recoil';
import { roleViewState } from '../../../navigation/recoil/roleView';

const useStyles = makeStyles()((theme) => {
    return {
        dialogHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    };
});

function BulkCommentDialog({ open, handleClose, user, onComment, smes }) {
    const { classes } = useStyles();
    const { admins: tads } = useTADs();
    const [roleView] = useRecoilState(roleViewState);

    const [showEditor, setShowEditor] = useState(false);

    const mentionables = useMemo(() => {
        const allAssociates = [...tads, ...smes];
        const uniqueAssociates = getUniqueObjectsByField(
            allAssociates,
            'email'
        );
        return (
            uniqueAssociates
                .filter(({ email }) => user?.user_email !== email)
                .map(
                    ({
                        _id,
                        firstName,
                        lastName,
                        email,
                        profilePicUrl,
                        profilePicUrlWP,
                    }) => ({
                        id: _id,
                        name: `${firstName} ${lastName}`,
                        _name: `${firstName} ${lastName}`,
                        email,
                        profilePicUrl:
                            profilePicUrl ||
                            profilePicUrlWP ||
                            profilePicPlaceholder,
                    })
                ) || []
        );
    }, [tads, smes, user?.user_email]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="bulk-comment"
            aria-describedby="bulk-comment-description"
            maxWidth={'sm'}
            scroll="paper"
            fullWidth
        >
            <DialogTitle id="bulk-comment">
                <Box className={classes.dialogHeader}>
                    <Typography variant="h6">Add comment</Typography>
                    <IconButton size="small" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <CommentsInput
                    user={user}
                    finalFunction={onComment}
                    placeholder={
                        user?.is_instructor &&
                        roleView.selectedView === 'instructor'
                            ? 'Discuss progress, share feedback, and collaborate. Use @ to tag people | Use / to tag tasks | Use ~ to tag a course'
                            : 'Discuss progress, share feedback, and collaborate. Use @ to tag people | Use # to tag teams | Use / to tag tasks | Use ~ to tag a course'
                    }
                    mentionables={mentionables}
                    // showCkEditor={false}
                    showEditor={showEditor}
                    setShowEditor={setShowEditor}
                    hideCourseTagging={user?.is_instructor}
                />
            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>
    );
}

export default BulkCommentDialog;
