import { useEffect } from 'react';
import { AzureBlobStorage } from '../util/BlobStorage';

const useFileUpload = (fileContainers, setFileContainers, chatId) => {
    useEffect(() => {
        if (fileContainers?.length > 0) {
            const blobStorage = new AzureBlobStorage();
            const containerName = 'chats';
            const folderPath = chatId;

            fileContainers.forEach(async (fileContainer) => {
                if (!fileContainer.isUploaded && !fileContainer.isUploading) {
                    // Set isUploading to true
                    setFileContainers((prev) =>
                        prev.map((fc) =>
                            fc.id === fileContainer.id
                                ? { ...fc, isUploading: true }
                                : fc
                        )
                    );

                    try {
                        await blobStorage.uploadFiles(
                            [fileContainer.file],
                            containerName,
                            null,
                            (progress) => {
                                setFileContainers((prev) =>
                                    prev.map((fc) =>
                                        fc.id === fileContainer.id
                                            ? {
                                                  ...fc,
                                                  percentageUploaded: progress,
                                              }
                                            : fc
                                    )
                                );
                            },
                            folderPath
                        );

                        // Set isUploaded to true and isUploading to false
                        setFileContainers((prev) =>
                            prev.map((fc) =>
                                fc.id === fileContainer.id
                                    ? {
                                          ...fc,
                                          isUploaded: true,
                                          isUploading: false,
                                      }
                                    : fc
                            )
                        );
                    } catch (e) {
                        console.error(e);
                        // Set isUploading to false
                        setFileContainers((prev) =>
                            prev.map((fc) =>
                                fc.id === fileContainer.id
                                    ? { ...fc, isUploading: false }
                                    : fc
                            )
                        );
                    }
                }
            });
        }
    }, [fileContainers, setFileContainers, chatId]);
};

export default useFileUpload;
