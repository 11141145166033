import { atom } from 'recoil';

export const allFeatureState = atom({
    key: 'allFeatureState',
    default: {},
});

export const globalLandingRef = atom({
    key: 'globalLandingRef',
    default: null,
});
