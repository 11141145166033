import { useState, useEffect } from 'react';
import axios from 'axios';

export const useThirdPartyCourseSearch = (
    query,
    pageNumber,
    resultsPerPage
) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [results, setResults] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [offset, setOffset] = useState('');

    useEffect(() => {
        setOffset('');
        setResults([]);
    }, [query]);

    useEffect(() => {
        let cancel = () => {};

        setLoading(true);
        setError(false);

        const fetchCourses = async () => {
            try {
                // const offset = (pageNumber - 1) * resultsPerPage;
                const filterByFormula = `OR(FIND(LOWER("${query}"), LOWER({Content Title (Editable)})), FIND(LOWER("${query}"), LOWER({Vendor Instructor Name (editable)})))`;

                const airtableURL = `${process.env.REACT_APP_AIRTABLE_API_DOMAIN}/v0/${process.env.REACT_APP_COURSERA_AIRTABLE_BASE_ID}/${process.env.REACT_APP_COURSERA_AIRTABLE_VIEW_ID}/listRecords`;
                const airtableHeaders = {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_COURSERA_AIRTABLE_TOKEN}`,
                        'Content-Type': 'application/json',
                    },
                };
                const airtableBody = {
                    filterByFormula,
                    fields: [
                        'Content Title (Editable)',
                        'Vendor Instructor Name (editable)',
                        'Courses Course Total Enrollments Count (from CPN/CIN Launched Data Import (Looker)) 2',
                        'Course Enrollments Average Star Rating (from CPN/CIN Launched Data Import (Looker)) 2',
                    ],
                    pageSize: resultsPerPage,
                    offset,
                    maxRecords: 1000,
                };

                const response = await axios.post(
                    airtableURL,
                    airtableBody,
                    airtableHeaders,
                    {
                        cancelToken: new axios.CancelToken((c) => (cancel = c)),
                    }
                );

                const records = response.data.records.map((record) => ({
                    id: record.id,
                    title: record.fields['Content Title (Editable)'],
                    instructors:
                        record.fields['Vendor Instructor Name (editable)'] ||
                        '',
                    enrollments:
                        record.fields[
                            'Courses Course Total Enrollments Count (from CPN/CIN Launched Data Import (Looker))'
                        ] ||
                        record.fields[
                            'Courses Course Total Enrollments Count (from CPN/CIN Launched Data Import (Looker)) 2'
                        ] ||
                        0,
                    rating:
                        record.fields[
                            'Course Enrollments Average Star Rating (from CPN/CIN Launched Data Import (Looker))'
                        ] ||
                        record.fields[
                            'Course Enrollments Average Star Rating (from CPN/CIN Launched Data Import (Looker)) 2'
                        ] ||
                        0,
                }));

                setResults((prevResults) => {
                    return [...prevResults, ...records];
                });
                setHasMore(records.length === resultsPerPage);
                setOffset(response.data.offset || '');
                setLoading(false);
            } catch (error) {
                if (axios.isCancel(error)) return;
                setError(true);
                setLoading(false);
            }
        };

        fetchCourses();

        return () => cancel();
    }, [query, pageNumber, resultsPerPage]);

    return { loading, error, results, hasMore };
};
