import {
    Avatar,
    Box,
    IconButton,
    Card,
    Typography,
    Checkbox,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Chip } from './Chip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import UserIcon from '../../img/user.png';
import { useMemo, useCallback, useState } from 'react';

const useStyles = makeStyles()((theme) => ({
    autocompleteContainer: {
        outline: '1px solid rgb(196,196,196)',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '0.25rem',
        paddingLeft: '0.6rem',
        paddingRight: '0.1rem',
        paddingTop: '0.25rem',
        paddingBottom: '0.5rem',
        '&:focus-within': {
            outlineWidth: '2px',
            outlineColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.up('sm')]: {
            paddingBlock: '0.5rem',
        },
    },
    containerSM: {
        paddingBlock: '0.20rem',
        borderRadius: '0.2rem',
    },
    selectedRows: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1%',
        flex: 1,
        marginBottom: '1%',
    },
    searchBoxContainer: {
        flexGrow: 1,
        minWidth: '2rem',
    },
    autoCompleteSearchBox: {
        outline: 'none',
        border: 'none',
        padding: '0.25rem',
        margin: 0,
        fontSize: '1rem',
        boxSizing: 'border-box',
        display: 'block',
        width: '100%',
        height: '100%',
    },
    smallSearchBox: {
        padding: '0.25rem',
    },
    autoCompleteIcons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchResults: {
        marginTop: theme.spacing(1),
        maxHeight: '200px',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    searchResultsDetached: {
        position: 'absolute',
        zIndex: 5,
        '& p': {
            fontSize: '0.875rem',
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        paddingInline: theme.spacing(2),
        gap: '1rem',
        marginBlock: theme.spacing(2),
    },
    resultSubTitle: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '20ch',
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '40ch',
        },
    },
}));

function SWAutoComplete({
    query,
    handleRemoveSelectedUser = () => console.log('remove users'),
    handleChangeQuery = () => console.log('change quer'),
    handleClear = () => console.log('clear'),
    rows = [],
    placeholder = 'Search here',
    selectedRows = [],
    setSelectedRows,
    setPage,
    showClearBtn = false,
    singleSelect = false,
    inputSize = 'default',
    label = '',
    detached = false,
}) {
    const [showResults, setShowResults] = useState(true);
    const { classes } = useStyles();

    const lastItemRef = useCallback(
        (node) => {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            if (setPage) {
                                setPage((prev) => ++prev);
                            }
                            observer.unobserve(node);
                        }
                    });
                },
                {
                    threshold: 1,
                }
            );
            if (node) {
                observer.observe(node);
            }
            return () => {
                if (node) {
                    observer.unobserve(node);
                }
            };
        },
        [setPage]
    );

    const handleDropdownClick = () => {
        setShowResults((prev) => !prev);
    };

    return (
        <Box>
            {label?.length > 0 && <label>{label}:</label>}

            <Box
                className={`${classes.autocompleteContainer} ${
                    inputSize === 'small' && classes.containerSM
                }`}
            >
                <Box className={classes.selectedRows}>
                    {selectedRows.map((row) => {
                        return (
                            <Chip
                                key={row._id}
                                imgURL={row?.imgURL}
                                title={row.title}
                                _id={row._id}
                                handleCancel={handleRemoveSelectedUser}
                            />
                        );
                    })}
                    <Box className={classes.searchBoxContainer}>
                        <input
                            type="text"
                            name="searchUserQuery"
                            className={`${classes.autoCompleteSearchBox} ${
                                inputSize === 'small' && classes.smallSearchBox
                            }`}
                            placeholder={placeholder}
                            value={query}
                            onChange={handleChangeQuery}
                        />
                    </Box>
                </Box>
                <Box className={classes.autoCompleteIcons}>
                    {showClearBtn && (
                        <IconButton size="small" onClick={handleClear}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    )}
                    <IconButton
                        size="small"
                        onClick={handleDropdownClick}
                        sx={{
                            transform: showResults
                                ? 'rotate(-180deg)'
                                : 'rotate(0deg)',
                            transition: 'all 150ms ease-in',
                        }}
                    >
                        <ArrowDropDownIcon />
                    </IconButton>
                </Box>
            </Box>

            {!detached && (
                <Card className={classes.searchResults}>
                    {showResults &&
                        rows.map((row, index) => (
                            <SearchRow
                                key={row._id}
                                row={row}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                lastItemRef={
                                    rows.length === index + 1
                                        ? lastItemRef
                                        : null
                                }
                                singleSelect={singleSelect}
                            />
                        ))}
                </Card>
            )}

            {detached && (
                <Box height={'230px'} position={'relative'}>
                    <Card
                        className={`${classes.searchResults} ${
                            detached && classes.searchResultsDetached
                        }`}
                    >
                        {showResults &&
                            rows.map((row, index) => (
                                <SearchRow
                                    key={row._id}
                                    row={row}
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    lastItemRef={
                                        rows.length === index + 1
                                            ? lastItemRef
                                            : null
                                    }
                                    singleSelect={singleSelect}
                                />
                            ))}
                    </Card>
                </Box>
            )}
        </Box>
    );
}

function SearchRow({
    row,
    selectedRows = [],
    setSelectedRows,
    lastItemRef,
    singleSelect,
}) {
    const { classes } = useStyles();

    const isChecked = useMemo(() => {
        const currId = row._id;
        return selectedRows.some((row) => row._id === currId);
    }, [row._id, selectedRows]);

    const handleCheckboxClick = (e) => {
        const checked = e.target.checked;
        if (checked) {
            setSelectedRows((prevRows) => {
                return [...prevRows, row];
            });
        } else {
            setSelectedRows((prevRows) => {
                return prevRows.filter((prevRow) => prevRow._id !== row._id);
            });
        }
    };

    return (
        <Box className={classes.row} ref={lastItemRef}>
            <Avatar
                alt={row.title}
                src={row?.imgURL?.length > 0 ? row.imgURL : UserIcon}
                sx={{ width: 32, height: 32 }}
            />

            <Box>
                <Typography variant="body1">{row.title}</Typography>
                <Typography
                    variant="caption"
                    className={classes.resultSubTitle}
                >
                    {row.subTitle}
                </Typography>
            </Box>

            {!singleSelect && (
                <Checkbox
                    sx={{ marginLeft: 'auto' }}
                    checked={isChecked}
                    onChange={handleCheckboxClick}
                />
            )}
        </Box>
    );
}

export { SWAutoComplete };
