const getPercentageCompletedText = ({
    percentageCompleted,
    notDueTasksCount,
    overDueTasksCount,
    totalTasks,
}) => {
    if (!notDueTasksCount) {
        return `${Math.floor(
            percentageCompleted
        )}% | ${overDueTasksCount} overdue / ${totalTasks}`;
    }

    return `${Math.floor(
        percentageCompleted
    )}% | ${notDueTasksCount} open + ${overDueTasksCount} overdue / ${totalTasks}`;
};

export { getPercentageCompletedText };
