import React, { useState } from 'react';
import axios from 'axios';
import {
    Box,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
    Actions,
    Loading,
    CircularProgress,
    IconButton,
    Typography,
} from '../ui';
import { CloseIcon } from '../icons';
import { SMEsSearchAndSelect } from './SMEsSearchAndSelect';
import { SMEForm } from './SMEForm';
import { ConfirmDialog } from '../ui';
import { fieldToHeader } from '../admin/ClientRequirements';
// import {DataGridPremium, GRID_CHECKBOX_SELECTION_COL_DEF  } from '@mui/x-data-grid-premium';
// import { VariablePieChart } from '../custom-components/charts';
// import { async } from 'q';
// import { useIsAdmin } from './useIsAdmin';

const useStyles = makeStyles()((theme) => ({
    paper: {
        minWidth: '90vw',
        padding: 40,
        [theme.breakpoints.up('md')]: {
            minWidth: 1000,
        },
    },
    // tableContainer: {
    //     height: 280,
    //     width: '100%',
    // },
    // dialogAction: {
    //     marginRight: 'auto',
    //     marginLeft: '1rem',
    //     paddingBottom: '0.5rem',
    // },
}));

const SMEModal = (props) => {
    const {
        smeModal = { isOpen: true, smes: [] },
        setSmeModal,
        setAllRequirements,
        loadRequirementData = undefined,
        changeStatus,
        selectOnly,
        onSmesSelect,
        fetchData = undefined,
    } = props;
    const theme = useTheme();
    const { classes } = useStyles();
    const isXSDevice = useMediaQuery(theme.breakpoints.down('md'));
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [showSMEForm, setShowSMEForm] = useState(false);
    const [smeIdToEdit, setSMEIdToEdit] = useState(null);

    const [
        openChangeStatusConfirmDialog,
        setOpenChangeStatusConfirmDialog,
    ] = useState(false);

    const [
        isLoadingChangeStatusConfirmDialog,
        setIsLoadinghangeStatusConfirmDialog,
    ] = useState(false);

    //DIALOG CLOSE HANDLER FUNCTION
    const handleDialogClose = () => {
        setSmeModal((prev) => {
            return {
                smes: [],
                isOpen: false,
                id: null,
                title: null,
                status: null,
            };
        });
    };

    const handleChangeStatusConfirmDialogClose = () => {
        setOpenChangeStatusConfirmDialog(false);
    };

    const handleChangeStatusConfirm = async () => {
        setIsLoadinghangeStatusConfirmDialog(true);
        await changeStatus('IN_PROGRESS', [smeModal.id]);

        setIsLoadinghangeStatusConfirmDialog(false);
        setOpenChangeStatusConfirmDialog(false);
        window.scroll(0, 100);
        handleDialogClose();
    };

    const handleChangeStatusCancle = () => {
        setOpenChangeStatusConfirmDialog(false);
        window.scroll(0, 100);
        handleDialogClose();
    };

    const saveSMEs = async () => {
        if (selectOnly) {
            if (onSmesSelect) onSmesSelect(smeModal.smes);
            handleDialogClose();

            return;
        }

        try {
            setIsSaving(true);
            await axios.post('/api/smes/set', {
                clientRequirementIds: [smeModal.id],
                smeIds: smeModal.smes?.map(({ _id }) => _id),
                smeField: smeModal.smeField,
                nextStageSMEIds: smeModal.nextStageSMEs?.map(({ _id }) => _id),
                nextStageSMEField: smeModal.nextStageSMEField,
            });

            if (setAllRequirements) {
                setAllRequirements((prev) => {
                    const requirementsCopy = [...prev];
                    const requirementToEdit = requirementsCopy.find(
                        ({ _id }) => _id === smeModal.id
                    );
                    if (smeModal.nextStageSMEField) {
                        requirementToEdit[smeModal.smeField] = smeModal.smes;

                        const nextStageSMEsToRemove = smeModal.smes?.filter(
                            (ssme) =>
                                smeModal.nextStageSMEs.findIndex(
                                    (asme) => asme._id === ssme._id
                                ) === -1
                        );

                        let newNextStageSMEs = [
                            ...requirementToEdit[smeModal.nextStageSMEField],
                        ];

                        newNextStageSMEs = newNextStageSMEs.filter(
                            (asme) =>
                                nextStageSMEsToRemove.findIndex(
                                    (usme) => asme._id === usme._id
                                ) === -1
                        );

                        smeModal.nextStageSMEs.forEach((asme) => {
                            if (
                                newNextStageSMEs.findIndex(
                                    (nasme) => nasme._id === asme._id
                                ) === -1
                            )
                                newNextStageSMEs.push(asme);
                        });

                        requirementToEdit[
                            smeModal.nextStageSMEField
                        ] = newNextStageSMEs;
                    } else requirementToEdit[smeModal.smeField] = smeModal.smes;

                    const requirementOthers = requirementsCopy.filter(
                        ({ _id }) => _id != smeModal.id
                    );

                    const ret = [requirementToEdit, ...requirementOthers];

                    return ret;
                });
            }

            if (loadRequirementData) {
                await loadRequirementData(smeModal.id);
            }

            setIsSaving(false);

            // if (
            //     smeModal.showShortListedSmes &&
            //     smeModal.smes.length &&
            //     smeModal.assignedSMEs?.length &&
            //     (smeModal.status === 'PENDING' || smeModal.status === 'DRAFT')
            // ) {
            //     setOpenChangeStatusConfirmDialog(true);
            // } else {
            window.scroll(0, 100);
            handleDialogClose();
            // }
            if (fetchData) {
                await fetchData();
            }
        } catch (error) {
            setIsSaving(false);
        }
    };

    const onClickSelected = (sme) => {
        setSMEIdToEdit(sme._id);
        setShowSMEForm(true);
    };

    return (
        <Box>
            <Dialog
                onClose={handleDialogClose}
                aria-labelledby="matching-instructor-data-modal"
                open={smeModal.isOpen}
                maxWidth="md"
                fullWidth
                fullScreen={isXSDevice}
                classes={{ paper: classes.paper }}
                // disableAutoFocus
                // disableEnforceFocus
                // disableRestoreFocus
            >
                <Box display="flex">
                    {selectOnly ? (
                        <>
                            <Typography
                                variant="h6"
                                style={{ marginRight: 'auto' }}
                            >
                                Select Instructors
                            </Typography>
                        </>
                    ) : showSMEForm ? (
                        <Typography
                            variant="h6"
                            style={{ marginRight: 'auto' }}
                        >
                            {/* Add Instructor */}
                        </Typography>
                    ) : (
                        <Typography
                            variant="h6"
                            style={{ marginRight: 'auto' }}
                        >
                            {fieldToHeader[smeModal.smeField]} for "
                            {smeModal.title}"
                        </Typography>
                    )}

                    <IconButton
                        aria-label="close"
                        onClick={handleDialogClose}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                {showSMEForm ? (
                    <DialogContent>
                        <SMEForm
                            setShowSMEForm={setShowSMEForm}
                            setSmeModal={setSmeModal}
                            smeIdToEdit={smeIdToEdit}
                            setSMEIdToEdit={setSMEIdToEdit}
                        />
                    </DialogContent>
                ) : (
                    <>
                        <DialogContent>
                            <Box display="flex" flexDirection="row-reverse">
                                <Button
                                    onClick={() => {
                                        setShowSMEForm(true);
                                        setSMEIdToEdit(null);
                                    }}
                                >
                                    Add New
                                </Button>
                            </Box>
                            <SMEsSearchAndSelect
                                selected={smeModal.smes}
                                setSmeModal={setSmeModal}
                                onClickSelected={onClickSelected}
                                selectedListView="list"
                                nextStageSMEsSelected={smeModal.nextStageSMEs}
                                nextStageSMEField={smeModal.nextStageSMEField}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Box className={classes.dialogAction}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ margin: 0 }}
                                    onClick={saveSMEs}
                                >
                                    {isSaving ? (
                                        <CircularProgress size="1.9em" />
                                    ) : selectOnly ? (
                                        <>Select</>
                                    ) : (
                                        <>Save</>
                                    )}
                                </Button>
                            </Box>
                        </DialogActions>
                    </>
                )}
            </Dialog>

            <ConfirmDialog
                onClose={handleChangeStatusConfirmDialogClose}
                open={openChangeStatusConfirmDialog}
                onConfirm={handleChangeStatusConfirm}
                title="Move to in progress?"
                onCancle={handleChangeStatusCancle}
                isLoading={isLoadingChangeStatusConfirmDialog}
                confirmLabel={'Yes'}
                cancleLabel={'No'}
            />
        </Box>
    );
};

export default SMEModal;
