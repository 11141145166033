import { useMemo, useState } from 'react';
import axios from 'axios';
import {
    PLANS_CARD_DETAILS,
    COMMANDER,
    VOYAGER_PLUS,
    astronaut_id,
} from '../plans/PlanDetails';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../my-account/useCurrentUser';
// import AccountBoxIcon from '@mui/icons-material/AccountBox';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { resetUser } from '../my-account/Recoil/Selector';
import { useSetRecoilState } from 'recoil';
import firebase from 'firebase/app';
import { makeStyles } from 'tss-react/mui';
import { RouteInfo } from '../seo';
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Tooltip,
    IconButton,
    Divider,
} from '../ui';

import { useInstructorProfile } from '../instructor-dashboard/useInstructorProfile';
import {
    // ArrowBack,
    // ArrowBackIosOutlined,
    // ArrowBackIosRounded,
    ChevronLeftRounded,
} from '@mui/icons-material';
import { roleViewState } from './recoil/roleView';

const useStyles = makeStyles()((theme) => ({
    // downContainer: {
    //     width: 340,
    //     transition: 'all 0.3s ease-in',
    // },
    dropDownListBox: {
        width: '100%',

        backgroundColor: '#ffffff',
        // border: '1px solid #CDD0D3',
        // boder
    },
    upgradeBox: {
        width: '100%',
        height: '450px',
    },
    dropDownUpgradeBox: {
        width: '100%',
        backgroundColor: '#ffffff',
        height: '300px',
        borderTop: '1px solid #CDD0D3',
        borderBottom: '1px solid #CDD0D3',
    },

    userProfilePic: {
        width: '20px',
        paddingLeft: '10px',
        cursor: 'pointer',
    },
    textColor: {
        cursor: 'pointer',
        fontSize: '16px',
        '&:hover': {
            color: '#0f7c90',
            cursor: 'pointer',
        },
    },
    textColorEmail: {
        // fontSize: '9px',
        fontSize: '14px',
    },
    textColorName: {
        // fontSize: '9px',
        fontSize: '14px',
        fontWeight: 'bold',
    },

    buttonContainer: {
        backgroundColor: '#ffffff',
    },
    '@keyframes myAnimationTwo': {
        from: { opacity: 0 },
        to: { opacity: 1 },
    },

    '@keyframes myAnimation': {
        from: { width: 0 },
        to: { width: 340 },
    },
    '@keyframes myAnimationOne': {
        from: { width: 340 },
        to: { width: 0 },
    },
    // dropContentBox: {
    //     width: 340,
    //     transition: 'all 0.3s ease-in',
    //     // display: 'flex',
    //     // flexDirection: 'row-reverse',
    //     position: 'absolute',
    //     top: '45px',
    //     right: '0px',
    //     animation: '$myAnimation 1s 1',
    // },
    pricingBtn: {
        marginTop: '30px',
        marginLeft: '30%',
    },
    bothContainer: {
        height: '300vh',

        backgroundColor: '#ffffff',
        width: '350px',
        animation: '$myAnimation 1s 1',
    },
    bothContainerClose: {
        width: '0px',
        position: 'relative',
        right: '-50px',

        animation: '$myAnimationOne 1s 1',
    },
    dropDownBoxContainedBtn: {
        position: 'absolute',
        top: '10px',
        zIndex: '10',
        right: '20px',
        // border: '1px solid black',
        animation: '$myAnimationTwo 5s 1',
    },
    btn: {
        animation: '$myAnimationTwo 5s 1',
    },
}));

function ProfileDropDownTwo({
    onToggleDrawer,
    dropDownBox,
    setDropDownBox,
    isInstructorView,
    isAdminView,
}) {
    const { user: currentUser } = useCurrentUser();
    const resetUserRecoil = useSetRecoilState(resetUser);
    const navigate = useNavigate();
    const { classes } = useStyles();
    const [instructorURLLoading, setIsInstructorURLLoading] = useState(false);
    const { profilePic } = useInstructorProfile(currentUser);
    const setRoleView = useSetRecoilState(roleViewState);

    const accountType = useMemo(() => {
        if (currentUser) {
            const plan = PLANS_CARD_DETAILS.filter(
                (item) => item.p_id === currentUser.user_current_plan
            );
            if (plan.length) {
                return plan[0].name;
            } else {
                return currentUser.plan_name;
            }
        } else {
            return 'Voyager';
        }
    }, [currentUser]);

    const adminItems = useMemo(() => {
        return [
            {
                label: 'Admin Dashboard',
                url: '/admin',
                isHidden: false,
            },
            {
                label: 'Profile',
                url: '/profile/information',
                isHidden: false,
            },
            {
                isDivider: true,
            },
            {
                label: 'Privacy',
                url: '/profile/security',
                isHidden: false,
            },
            {
                label: 'Notifications',
                url: '/admin/notifications',
                isHidden: false,
            },
            {
                label: 'Messages',
                url: '/messaging',
                isHidden: false,
            },
            {
                isDivider: true,
            },
            {
                label: 'Starweaver for Business',
                url: '/free-enterprise-trial',
                isHidden: false,
                isNewTab: true,
            },
            {
                label: 'Connect with us',
                url: '/admin/program-management',
                isHidden: false,
            },
            {
                label: "Support & FAQ's",
                url: 'https://support.starweaver.com/portal/en/home',
                isHidden: false,
                isNewTab: true,
            },
        ];
    }, []);

    const instructorItems = useMemo(() => {
        return [
            {
                label: 'Instructor Dashboard',
                url: '/profile/instructor-dashboard',
                isHidden: false,
            },
            {
                label: 'Profile',
                url: '/profile/information',
                isHidden: false,
            },
            {
                label: 'My Channel',
                url: '/profile/instructor-dashboard/public',
                isHidden: false,
            },
            {
                isDivider: true,
            },
            {
                label: 'Privacy',
                url: '/profile/security',
                isHidden: false,
            },
            {
                label: 'Notifications',
                url: '/profile/instructor-dashboard/notifications',
                isHidden: false,
            },
            {
                label: 'Messages',
                url: '/messaging',
                isHidden: false,
            },
            {
                isDivider: true,
            },
            {
                label: 'Learn How',
                url: '/profile/instructor-dashboard/learn-how/tutorials',
                isHidden: false,
            },
            {
                label: 'Connect with us',
                url: '/profile/instructor-dashboard/connect',
                isHidden: false,
            },
            {
                label: "Support & FAQ's",
                url: 'https://support.starweaver.com/portal/en/home',
                isHidden: false,
                isNewTab: true,
            },
        ];
    }, []);

    const studentItems = useMemo(() => {
        return [
            {
                label: 'Student Dashboard',
                url: '/profile/dashboard',
                isHidden: false,
            },
            {
                label: 'My Courses',
                url: '/profile/dashboard/content/my-courses',
                isHidden: false,
            },
            {
                label: 'Saved Courses',
                url: '/profile/dashboard/content/saved-register',
                isHidden: false,
            },
            {
                label: 'Recommendations',
                url: '/profile/dashboard/recommendations',
                isHidden: false,
            },
            {
                isDivider: true,
            },
            {
                label: 'Profile',
                url: '/profile/information',
                isHidden: false,
            },
            {
                label: 'Privacy',
                url: '/profile/security',
                isHidden: false,
            },
            {
                label: 'Notifications',
                url: '/profile/dashboard/notifications',
                isHidden: false,
            },
            {
                label: 'Messages',
                url: '/messaging',
                isHidden: false,
            },
            {
                isDivider: true,
            },
            {
                label: 'Subscriptions',
                url: '/profile/billing',
                isHidden: false,
            },
            {
                label: 'Purchase History',
                url: '',
                isHidden: false,
            },
            {
                isDivider: true,
            },
            {
                label: 'Starweaver for Business',
                url: '/free-enterprise-trial',
                isHidden: false,
                isNewTab: true,
            },
            {
                label: !currentUser?.is_instructor
                    ? 'Teach with Starweaver'
                    : 'Instructor Dashboard',
                url:
                    currentUser?.instructor_onboarding_status === 'Pending'
                        ? ''
                        : !currentUser?.is_instructor
                        ? '/instructor-onboarding'
                        : '/profile/instructor-dashboard',
                isHidden: false,
            },
            {
                label: "Support & FAQ's",
                url: 'https://support.starweaver.com/portal/en/home',
                isHidden: false,
                isNewTab: true,
            },
        ];
    }, [currentUser?.instructor_onboarding_status, currentUser?.is_instructor]);

    const items = useMemo(() => {
        if (isInstructorView) return instructorItems;
        else if (isAdminView) return adminItems;
        else return studentItems;
    }, [
        adminItems,
        instructorItems,
        isAdminView,
        isInstructorView,
        studentItems,
    ]);

    const isPublic = (pathname) => {
        const pathFirst = pathname.split('/')[1];
        return RouteInfo.some(
            (route) => route.path.split('/')[1] === pathFirst && route.isPublic
        );
    };

    const goToProfile = () => {
        navigate('/profile');

        onToggleDrawer();
    };
    const goToDashboard = () => {
        navigate('/profile/dashboard');
        onToggleDrawer();
    };
    const goToNotifications = () => {
        navigate('/profile/notifications');
        onToggleDrawer();
    };

    const goToPlanInfo = () => {
        navigate('/profile/billing');
        onToggleDrawer();
    };
    const goToPricing = () => {
        if (accountType === VOYAGER_PLUS) {
            navigate(`/checkout?p_id=${astronaut_id}&t=0`);
        } else {
            navigate('/pricing');
        }
        onToggleDrawer();
    };

    const goToUpdateProfilePhoto = () => {
        if (isInstructorView)
            navigate(
                '/profile/instructor-dashboard/channel-configuration/about-me'
            );
        else navigate('/profile/information');
        onToggleDrawer();
    };

    const goToKnowledge = () => {
        window.open('https://support.starweaver.com/portal/en/home', '_blank');
        onToggleDrawer();
    };

    const goToFirstTime = () => {
        navigate('/first-time');
        onToggleDrawer();
    };

    const goToBusiness = () => {
        window.open(`/free-enterprise-trial`, '_blank');
        onToggleDrawer();
    };

    const goToInstructorDashboard = () => {
        navigate('/profile/instructor-dashboard');
    };

    const goToInstructorProfile = () => {
        navigate(
            '/profile/instructor-dashboard/channel-configuration/about-me'
        );
    };
    const goToInstructorContent = () => {
        navigate('/profile/instructor-dashboard/content');
    };
    const goToInstructorNetwork = () => {
        navigate('/profile/instructor-dashboard/network');
    };
    const goToInstructorMessaging = () => {
        navigate('/profile/instructor-dashboard/messaging');
    };

    const goToInstructorReports = () => {
        navigate('/profile/instructor-dashboard/reports');
    };

    const goToInstructorPayouts = () => {
        navigate('/profile/instructor-dashboard/channel-configuration/payouts');
    };

    const goToInstructorContactStarweaver = () => {
        navigate('/profile/instructor-dashboard/program-manager');
    };

    const goToInstructorPublicProfile = async () => {
        try {
            if (!instructorURLLoading) {
                setIsInstructorURLLoading(true);

                // const res = await axios.get(
                //     `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/instructor/slug/${currentUser.user_email}`
                // );
                const res = await axios.get(
                    `/api/channels/slug/${currentUser.user_email}`
                );

                setIsInstructorURLLoading(false);
                //navigate((`/instructors/${res.data}`));

                const port = window.location.port;

                if (res.data)
                    window.open(
                        `${window.location.protocol}//${
                            window.location.hostname
                        }${port == 3000 ? ':' + port : ''}/instructors/${
                            res.data
                        }`,
                        '_blank'
                    );
            }
        } catch (error) {
            console.log(error);
        }
    };

    const goToAdminDashboard = () => {
        navigate('/admin');
    };

    const goToAdminUserManangement = () => {
        navigate('/admin/user-profiles');
    };
    const goToAdminReports = () => {
        navigate('/admin/reports');
    };
    const goToAdminMessagingSuite = () => {
        // navigate(('/admin/message-suite'));
        navigate('/admin/create/message/');
    };
    const goToAdminGeneralAdministration = () => {
        navigate('/admin/content/all');
    };
    const goToAdminProgramManagement = () => {
        navigate('/admin/program-management');
    };

    const handleLogOut = () => {
        firebase
            .auth()
            .signOut()
            .then((res) => {
                setRoleView({ selectedView: '' });
                const pathName = window.location.pathname;
                isPublic(pathName) ? navigate(pathName) : navigate('/login');
                resetUserRecoil();
            })
            .catch((err) => console.log(err));
    };

    const handleItemClick = (item) => {
        if (item?.url.length === 0) return;

        if (item?.isNewTab) {
            window.open(item.url, '_blank');
            setDropDownBox((prev) => !prev);
            onToggleDrawer();
            return;
        }

        setDropDownBox((prev) => !prev);
        navigate(item.url);
        onToggleDrawer();
    };

    return (
        <>
            {/* <Box> */}
            {/* {dropDownBox && currentUser ? (
            <Button
                onClick={() => {
                    setDropDownBox(!dropDownBox);
                }}
                style={{ backgroundColor: '#ffffff' }}
                // className={classes.btn}
                className={classes.dropDownBoxContainedBtn}
                // variant="contained"
                disableElevation
                disableRipple
                // endIcon={dropDownBox ? <ArrowForwardIosIcon /> : null}
            />
        ) : (
            <> </>
        )} */}

            {/* </Box> */}
            <Box
                className={
                    dropDownBox
                        ? classes.bothContainer
                        : classes.bothContainerClose
                }
            >
                {currentUser && (
                    <Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            bgcolor={'#f7f9fa'}
                            style={{ gap: '0.5rem', cursor: 'pointer' }}
                            padding="1rem 0.25rem"
                            marginY={2}
                            onClick={() => {
                                setDropDownBox(!dropDownBox);
                            }}
                        >
                            <IconButton size="large">
                                <ChevronLeftRounded fontSize="small" />
                            </IconButton>
                            <Typography>Menu</Typography>
                        </Box>
                        <Box className={classes.dropDownListBox}>
                            <List
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    margin: '0px',
                                    padding: '0px',
                                }}
                                component="nav"
                                aria-label="main mailbox folders"
                            >
                                {/* <ListItem
                                // onClick={goToProfile}
                                style={{
                                    padding: '10px 0px 10px 0px',
                                }}
                            >
                                <Tooltip
                                    title={
                                        currentUser?.user_url
                                            ? 'Update Photo'
                                            : 'Upload Photo'
                                    }
                                    placement="top"
                                >
                                    <ListItemIcon
                                        onClick={goToUpdateProfilePhoto}
                                        className={classes.userProfilePic}
                                    >
                                        {currentUser &&
                                        currentUser.user_url ? (
                                            <img
                                                src={
                                                    isInstructorView
                                                        ? profilePic
                                                        : currentUser.user_url
                                                }
                                                width="40px"
                                                height="40px"
                                                style={{
                                                    borderRadius: '50%',
                                                }}
                                                alt="current-user-pic"
                                            />
                                        ) : (
                                            <AccountBoxIcon
                                                style={{
                                                    padding: '0px',
                                                }}
                                            />
                                        )}
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={
                                        <Box>
                                            <Typography
                                                className={
                                                    classes.textColorName
                                                }
                                                variant="body2"
                                            >
                                                {currentUser?.user_firstName
                                                    ? currentUser?.user_firstName
                                                    : null}
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.textColorEmail
                                                }
                                                variant="body2"
                                            >
                                                {currentUser?.user_email}
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </ListItem> */}
                                <ListItem onClick={goToProfile}>
                                    {/* <ListItemIcon>
                                                    <AccountBoxIcon color="secondary" />
                                                </ListItemIcon> */}
                                    <ListItemText
                                        primary={
                                            <Typography
                                                className={classes.textColor}
                                                variant="body2"
                                            >
                                                My Account({accountType})
                                            </Typography>
                                        }
                                    />
                                </ListItem>

                                {items.map((item, idx) => {
                                    return (
                                        <>
                                            {!item.isDivider ? (
                                                <ListItem
                                                    key={item.label}
                                                    className={classes.listItem}
                                                    onClick={() =>
                                                        handleItemClick(item)
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                className={
                                                                    classes.textColor
                                                                }
                                                                variant="body1"
                                                            >
                                                                {item.label}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            ) : (
                                                <Divider
                                                    key={`divider-${idx}`}
                                                />
                                            )}
                                        </>
                                    );
                                })}

                                {/* {isInstructorView ? (
                                    <>
                                        <ListItem
                                            onClick={goToInstructorDashboard}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Instructor Dashboard
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem
                                            onClick={goToInstructorProfile}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Edit My Profile
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem
                                            onClick={goToInstructorContent}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Content Producer
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>

                                        <ListItem
                                            onClick={goToInstructorMessaging}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Messaging
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem
                                            onClick={goToInstructorReports}
                                            style={{
                                                borderBottom:
                                                    '1px solid #CDD0D3',
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Reports
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem
                                            onClick={
                                                goToInstructorPublicProfile
                                            }
                                            style={{
                                                borderBottom:
                                                    '1px solid #CDD0D3',
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        My Channel
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>

                                        <ListItem
                                            onClick={goToInstructorPayouts}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Payouts
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem
                                            onClick={
                                                goToInstructorContactStarweaver
                                            }
                                            style={{
                                                borderBottom:
                                                    '1px solid #CDD0D3',
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Contact Starweaver
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem
                                            onClick={goToBusiness}
                                            style={{
                                                borderBottom:
                                                    '1px solid #CDD0D3',
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Starweaver for Business
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </>
                                ) : isAdminView ? (
                                    <>
                                        <ListItem onClick={goToAdminDashboard}>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Administrator Dashboard
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem
                                            onClick={goToAdminUserManangement}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        User & Team Management
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem onClick={goToAdminReports}>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Reporting
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem
                                            onClick={goToAdminMessagingSuite}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Messaging Suite
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem
                                            onClick={
                                                goToAdminGeneralAdministration
                                            }
                                            style={{
                                                borderBottom:
                                                    '1px solid #CDD0D3',
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Content Management
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>

                                        <ListItem
                                            onClick={goToAdminProgramManagement}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Coordinate with
                                                        Starweaver
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>

                                        <ListItem
                                            style={{
                                                borderBottom:
                                                    '1px solid #CDD0D3',
                                            }}
                                            onClick={goToKnowledge}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Support & FAQs
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>

                                        <ListItem
                                            onClick={goToBusiness}
                                            style={{
                                                borderBottom:
                                                    '1px solid #CDD0D3',
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Starweaver for Business
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </>
                                ) : (
                                    <>
                                        <ListItem onClick={goToDashboard}>
                                            <ListItemIcon>
                                                    <AccountBoxIcon color="secondary" />
                                                </ListItemIcon> 
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        My Dashboard
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem onClick={goToNotifications}>
                                            <ListItemIcon>
                                                    <NotificationsActiveIcon color="secondary" />
                                                </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Notifications
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>

                                        <ListItem onClick={goTotraining}>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={
                                                        classes.textColor
                                                    }
                                                    variant="body2"
                                                >
                                                    Activity Tracking
                                                </Typography>
                                            }
                                        />
                                    </ListItem>

                                        <ListItem
                                            style={{
                                                borderBottom:
                                                    '1px solid #CDD0D3',
                                            }}
                                            onClick={goToPlanInfo}
                                        >
                                            <ListItemIcon>
                                                    <InfoIcon color="secondary" />
                                                </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Plan Info
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>

                                        <ListItem onClick={goToFirstTime}>
                                            <ListItemIcon>
                                                    <DirectionsWalkIcon color="secondary" />
                                                </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Walk-Thru
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>

                                        <ListItem
                                            style={{
                                                borderBottom:
                                                    '1px solid #CDD0D3',
                                            }}
                                            onClick={goToKnowledge}
                                        >
                                            <ListItemIcon>
                                                    <DirectionsWalkIcon color="secondary" />
                                                </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Knowledge Base
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>

                                        <ListItem onClick={goToBusiness}>
                                            <ListItemIcon>
                                                    <BusinessIcon color="secondary" />
                                                </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            classes.textColor
                                                        }
                                                        variant="body2"
                                                    >
                                                        Starweaver for Business
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </>
                                )} */}

                                <ListItem onClick={handleLogOut}>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                className={classes.textColor}
                                                variant="subtitle2"
                                            >
                                                Log out
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Box>

                        {!(
                            accountType === COMMANDER ||
                            currentUser.is_instructor
                        ) && (
                            <Box className={classes.upgradeBox}>
                                <Box
                                    className={classes.dropDownUpgradeEmptyBox}
                                    // onMouseOver={() => {
                                    //     setDropDownBox(false);
                                    // }}
                                ></Box>
                                <Box className={classes.dropDownUpgradeBox}>
                                    <Typography
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: 800,
                                            marginTop: '20px',
                                        }}
                                        variant="body2"
                                    >
                                        Upgrade your account
                                    </Typography>
                                    <Typography
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: 400,
                                            padding: '20px 10px 10px 10px',
                                        }}
                                        variant="body2"
                                    >
                                        Achieve more. Get connected to
                                        instructors, coaches and much more.
                                    </Typography>
                                    <Button
                                        className={classes.pricingBtn}
                                        onClick={goToPricing}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Upgrade Plan
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </>
    );
    {
        /* </Link> */
    }
    {
        /* {
                                // CHECK THE CURRENT USER PLAN
                                // DO WE HAVE TO SHOW IT ON THE BILLING PAGE TOO AND PRICING PLANS PAGE
                                true && (
                                    <Link to={plansRoute.path}>
                                        <Fab
                                            variant="extended"
                                            size="small"
                                            color="secondary"
                                            className={
                                                buttonShouldBounce
                                                    ? `${classes.upgrade} ${classes.bounce}`
                                                    : classes.upgrade
                                            }
                                        >
                                            <ArrowUpwardIcon />
                                            Upgrade Plan
                                        </Fab>
                                    </Link>
                                )
                            } */
    }
}

export default ProfileDropDownTwo;
