import {
    Box,
    Button,
    Card,
    Container,
    ListItemText,
    Menu,
    MenuItem,
    TablePagination,
    Typography,
    useMediaQuery,
} from '@mui/material';
import {
    DataGridPremium,
    GridToolbar,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';
import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { alertState, severity } from '../../../app/recoil';
import ActionContainer from './ActionContainer';
import AddGuidanceDialog from './AddGuidanceDialog';
import AddNewDialog from './AddNewDialog';
import ConfigureDialog from './ConfigureDialog';
import GuidanceDrawer from './GuidanceDrawer';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { LinearLoader } from '../../../util';
import GuidancePreviewTable from './GuidancePreviewTable';
import EmptyImg from '../../../img/empty.svg';
import { ConfirmDialog } from '../../../ui';
import { AzureBlobStorage } from '../../../util/BlobStorage';
import GuidanceDetailsDialog from './GuidanceDetailsDialog';
import GuidancePicker from './GuidancePicker';
import { DATAGRIDSX } from '../../../my-account/utils';

const status = {
    live: 'LIVE',
    draft: 'DRAFT',
};

const useStyles = makeStyles()((theme) => ({
    cardContainer: {
        padding: theme.spacing(2),
    },
    noGuidances: {
        display: 'grid',
        placeItems: 'center',
    },
}));

function OMDGuidance({
    logisticsTemplateId,
    hideCTA = false,
    isInstructor = false,
    hideDatagrid = false,
    hideDrawer = false,
    hideActions = false,
    showRedirectBtn = false,
    filterStatus = false,
}) {
    const { classes } = useStyles();
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const setAlert = useSetRecoilState(alertState);
    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState([]);
    const [selectedrows, setSelectedrows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showAddGuidanceDialog, setShowAddGuidanceDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [addNewDialog, setAddNewDialog] = useState({
        show: false,
        type: '',
    });
    const [showConfigureDialog, setShowConfigureDialog] = useState(false);
    const [guidanceOptId, setGuidanceOptId] = useState('');
    const [drawer, setDrawer] = useState({
        open: false,
        guidance: null,
    });
    const [filters, setFilters] = useState({
        columnField: '',
        operatorValue: '',
        value: '',
    });
    const [searchParams] = useSearchParams();
    const [confirmDeleteGuidance, setConfirmDeleteGuidance] = useState(false);
    const [showGuidanceDialog, setShowGuidanceDialog] = useState({
        guidance: null,
        open: false,
    });
    const navigate = useNavigate();
    const [showGuidancePicker, setShowGuidancePicker] = useState(false);
    const isLargeScreen = useMediaQuery('(min-width:900px)');
    const [pinnedColumns, setPinnedColumns] = useState({
        left: isLargeScreen
            ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'title']
            : [],
    });
    const fetchGuidance = useCallback(
        async (logisticsTemplateId, limit, page, all = undefined) => {
            try {
                setIsLoading(true);
                const baseURL = `/api/guidance/requirement-guidance`;
                const params = new URLSearchParams();
                if (searchParams.get('searchValue')) {
                    params.append(
                        'searchValue',
                        searchParams.get('searchValue')
                    );
                }
                if (logisticsTemplateId) {
                    params.append('logisticsTemplateId', logisticsTemplateId);
                }
                if (limit) params.append('limit', limit);
                if (page) params.append('page', page);
                if (filters.value) {
                    params.append('columnField', filters.columnField);
                    params.append('operatorValue', filters.operatorValue);
                    params.append('value', filters.value);
                }
                if (filterStatus === true) {
                    params.append('columnField', 'status');
                    params.append('operatorValue', 'contains');
                    params.append('value', 'live');
                }
                if (all) {
                    params.append('all', 1);
                }
                const url = `${baseURL}?${params.toString()}`;
                const { data } = await axios.get(url);
                setTotal(data.total);
                setRows(data.guidance);
            } catch (e) {
                if (e?.response?.status === 422) {
                    setAlert({
                        show: true,
                        message: e.response.data.message,
                        severity: severity.ERROR,
                    });
                }
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        },
        [
            filters.columnField,
            filters.operatorValue,
            filters.value,
            searchParams,
            setAlert,
            filterStatus,
        ]
    );

    useEffect(() => {
        fetchGuidance(logisticsTemplateId, limit, page);
    }, [fetchGuidance, limit, logisticsTemplateId, page]);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get('/api/guidance/guidance-option-id')
            .then(({ data: { _id } }) => setGuidanceOptId(_id))
            .catch(console.error)
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        if (drawer.open) {
            setDrawer((prev) => ({
                ...prev,
                guidance: rows.filter((g) => g._id === prev.guidance._id)[0],
            }));
        }
    }, [drawer.open, rows]);

    const handleClickTitle = useCallback(
        (row) => {
            if (!hideDrawer) {
                setDrawer({ open: true, guidance: row });
            } else {
                setShowGuidanceDialog({ open: true, guidance: row });
            }
        },
        [hideDrawer]
    );

    const columns = useMemo(() => {
        return [
            {
                field: 'mark',
                headerName: '',
                width: 10,
                sortable: false,
                display: 'flex',
                renderCell: (params) => {
                    if (params.row.priority === 'Critical') {
                        return (
                            <div
                                style={{
                                    backgroundColor: '#FF033E',
                                    height: '100%',
                                    width: '40%',
                                }}
                            />
                        );
                    }
                    return null;
                },
            },
            {
                field: 'title',
                headerName: 'Title',
                editable: false,
                width: 300,
                sortable: false,
                filterable: true,
                valueFormatter: (value) => {
                    return value;
                },
                display: 'flex',
                renderCell: ({ row, value }) => {
                    return (
                        <span
                            onClick={() => handleClickTitle(row)}
                            style={{ cursor: 'pointer' }}
                        >
                            {value}
                        </span>
                    );
                },
            },
            {
                field: 'category',
                headerName: 'Category',
                editable: false,
                width: 300,
                sortable: false,
                filterable: true,
                valueFormatter: (value) => {
                    return value;
                },
            },
            {
                field: 'purpose',
                headerName: 'Purpose',
                editable: false,
                width: 300,
                sortable: false,
                filterable: true,
                valueFormatter: (value) => {
                    return value;
                },
            },
            {
                field: 'isPublic',
                headerName: 'Public',
                editable: false,
                width: 150,
                sortable: false,
                filterable: false,
                valueFormatter: (value) => {
                    return value ? 'Yes' : 'No';
                },
            },
            {
                field: 'createdBy',
                headerName: 'Created By',
                editable: false,
                width: 200,
                sortable: false,
                filterable: false,
                valueFormatter: (value) => {
                    return value?.fullName;
                },
            },
            {
                field: 'status',
                headerName: 'Status',
                editable: false,
                width: 100,
                sortable: false,
                filterable: true,
                valueFormatter: (value) => {
                    return status[value];
                },
            },
            {
                field: 'updatedAt',
                headerName: 'Last Updated',
                editable: false,
                width: 200,
                sortable: false,
                filterable: false,
                valueFormatter: (value) => {
                    return moment(value).format('DD-MMM-YYYY');
                },
            },
            {
                field: 'createdAt',
                headerName: 'Created On',
                editable: false,
                width: 200,
                sortable: false,
                filterable: false,
                valueFormatter: (value) => {
                    return moment(value).format('DD-MMM-YYYY');
                },
            },
        ];
    }, [handleClickTitle]);

    const onActionClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleAddGuidance = () => {
        setShowAddGuidanceDialog(true);
        handleCloseMenu();
    };

    const handleUpdateGuidance = () => {
        setIsEdit(true);
        setShowAddGuidanceDialog(true);
        handleCloseMenu();
    };

    const handleCloseGuidanceDialog = () => {
        setShowAddGuidanceDialog(false);
        setIsEdit(false);
    };

    const handleAddCategories = () => {
        setAddNewDialog({ show: true, type: 'categories' });
        handleCloseMenu();
    };

    const handleAddPurposes = () => {
        setAddNewDialog({ show: true, type: 'purposes' });
        handleCloseMenu();
    };

    const handleCloseAddNewDialog = () => {
        setAddNewDialog({ show: false, type: '' });
    };

    const handleConfigure = () => {
        setShowConfigureDialog(true);
        handleCloseMenu();
    };

    const handleCloseDrawer = () => {
        setDrawer({ open: false, guidance: null });
    };

    const onFilterChange = (params) => {
        const filterModel = params;
        console.log('params: ', params);
        if (filterModel === undefined) return;
        const { items } = filterModel;
        if (items === undefined || items.length === 0) return;
        let { field: columnField, operator: operatorValue, value } = items[0];
        setPage(0);

        if (value === undefined) {
            return setFilters({
                columnField: '',
                operatorValue: '',
                value: '',
            });
        }

        setFilters({ columnField, operatorValue, value });
    };

    const handleDeleteGuidance = () => {
        setConfirmDeleteGuidance(true);
        handleCloseMenu();
    };

    const handleCloseConfirm = () => {
        setConfirmDeleteGuidance(false);
    };

    const handleOnConfirmDelete = async () => {
        try {
            setIsLoading(true);
            const params = new URLSearchParams();
            params.append('guidanceIds', selectedrows);
            const url = `/api/guidance/delete-guidances?${params.toString()}`;
            await axios.delete(url);
            await deleteAllFiles(selectedrows);
            await fetchGuidance(logisticsTemplateId);
            setAlert({
                show: true,
                message: 'Guidance deleted successfully',
                severity: severity.SUCCESS,
            });
        } catch (e) {
            if ([400, 404, 422].includes(e.response.status)) {
                setAlert({
                    show: true,
                    message: e.response.data.message,
                    severity: severity.ERROR,
                });
            }
            console.log(e);
        } finally {
            setIsLoading(false);
            handleCloseConfirm();
        }
    };

    const deleteAllFiles = async (guidanceIds) => {
        try {
            const blobStorage = new AzureBlobStorage();
            const fileListPromises = guidanceIds.map((id) =>
                blobStorage.listFilesFromDir('guidance', id)
            );
            const allFilesRes = await Promise.allSettled(fileListPromises);
            let fileNameSet = new Set();

            for (const fileRes of allFilesRes) {
                if (fileRes.status === 'fulfilled') {
                    const {
                        value: { fileNames, size },
                    } = fileRes;

                    if (size) {
                        fileNames.forEach((file) =>
                            fileNameSet.add(file.actual)
                        );
                    }
                }
            }

            const deletePromises = Array.from(fileNameSet).map((fileName) =>
                blobStorage.deleteFile(fileName, 'guidance')
            );
            const deleteRes = await Promise.allSettled(deletePromises);
            return deleteRes;
        } catch (e) {
            console.log(e);
        }
    };

    const handleCloseDetailsDialog = () => {
        setShowGuidanceDialog({ open: false, guidance: null });
    };

    const handleShowGuidancePicker = () => {
        setShowGuidancePicker(true);
    };

    const handleCloseGuidancePicker = () => {
        setShowGuidancePicker(false);
    };

    return (
        <Container maxWidth={'xl'} disableGutters>
            {drawer.open && (
                <GuidanceDrawer
                    open={drawer.open}
                    guidance={drawer.guidance}
                    handleCloseDrawer={handleCloseDrawer}
                    fetchGuidance={fetchGuidance}
                    logisticsTemplateId={logisticsTemplateId}
                    hideCTA={hideCTA}
                    isInstructor={isInstructor}
                />
            )}

            {showGuidanceDialog.open && (
                <GuidanceDetailsDialog
                    open={showGuidanceDialog.open}
                    handleClose={handleCloseDetailsDialog}
                    guidance={showGuidanceDialog.guidance}
                    fetchGuidance={fetchGuidance}
                    hideCTA={hideCTA}
                    isInstructor={isInstructor}
                    logisticsTemplateId={logisticsTemplateId}
                />
            )}

            {/* DELETE GUIDANCES CONFIRM MODAL */}
            <ConfirmDialog
                onClose={handleCloseConfirm}
                open={confirmDeleteGuidance}
                onConfirm={handleOnConfirmDelete}
                title="Are you sure you want to delete?"
                isLoading={isLoading}
            />

            {showGuidancePicker && (
                <GuidancePicker
                    open={showGuidancePicker}
                    handleClose={handleCloseGuidancePicker}
                    logisticsId={logisticsTemplateId}
                    fetchTemplateGuidance={fetchGuidance}
                    rows={rows}
                    total={total}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    setLimit={setLimit}
                    setTotal={setTotal}
                />
            )}

            <Box my={2}>
                {!hideCTA && !hideActions && (
                    <ActionContainer
                        handleAddGuidance={handleAddGuidance}
                        onActionClick={onActionClick}
                    />
                )}

                <Menu
                    id="action-menu"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                        'aria-labelledby': 'action-button',
                    }}
                >
                    <MenuItem
                        onClick={handleUpdateGuidance}
                        disabled={selectedrows.length === 0}
                    >
                        {/* <ListItemIcon></ListItemIcon> */}
                        <ListItemText>Update</ListItemText>
                    </MenuItem>

                    <MenuItem onClick={handleConfigure}>
                        <ListItemText>Configure</ListItemText>
                    </MenuItem>

                    <MenuItem
                        onClick={handleDeleteGuidance}
                        disabled={selectedrows.length === 0}
                    >
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                </Menu>

                {showAddGuidanceDialog && (
                    <AddGuidanceDialog
                        handleClose={handleCloseGuidanceDialog}
                        open={showAddGuidanceDialog}
                        fetchGuidance={fetchGuidance}
                        logisticsTemplateId={logisticsTemplateId}
                        selectedRows={selectedrows}
                        isEdit={isEdit}
                        rows={rows}
                    />
                )}

                {addNewDialog.show && (
                    <AddNewDialog
                        open={addNewDialog.show}
                        handleClose={handleCloseAddNewDialog}
                        type={addNewDialog.type}
                        setShowConfigureDialog={setShowConfigureDialog}
                        guidanceOptId={guidanceOptId}
                    />
                )}

                {showConfigureDialog && (
                    <ConfigureDialog
                        open={showConfigureDialog}
                        handleClose={() => setShowConfigureDialog(false)}
                        handleAddCategories={handleAddCategories}
                        handleAddPurposes={handleAddPurposes}
                        guidanceOptId={guidanceOptId}
                    />
                )}

                {!hideDatagrid ? (
                    <Card elevation={2} className={classes.cardContainer}>
                        <DataGridPremium
                            autoHeight
                            rows={rows}
                            columns={columns}
                            paginationModel={{ pageSize: limit, page: page }}
                            pageSizeOptions={[5, 10, 20, 50, 100]}
                            onPaginationModelChange={({ page, pageSize }) => {
                                setPage(page);
                                setLimit(pageSize);
                            }}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={(updatedPinnedColumns) => {
                                if (isLargeScreen) {
                                    setPinnedColumns(updatedPinnedColumns);
                                } else {
                                    alert(
                                        'Pinning is not available on small screens.'
                                    );
                                }
                            }}
                            checkboxSelection={!hideCTA}
                            onRowSelectionModelChange={(param) => {
                                setSelectedrows(param);
                            }}
                            disableRowSelectionOnClick={true}
                            hideFooterRowCount={true}
                            // disableColumnMenu={true}
                            disableColumnFilter={true}
                            rowCount={total}
                            pagination
                            paginationMode="server"
                            filterMode="server"
                            onFilterModelChange={onFilterChange}
                            loading={isLoading}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            sx={DATAGRIDSX}
                        />
                    </Card>
                ) : (
                    <Box>
                        {isLoading && <LinearLoader />}

                        <>
                            {rows.length > 0 ? (
                                <>
                                    {!hideCTA && (
                                        <Box mb={2} textAlign={'right'}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={
                                                    handleShowGuidancePicker
                                                }
                                            >
                                                Add guidance
                                            </Button>
                                        </Box>
                                    )}
                                    <GuidancePreviewTable
                                        hideCTA={hideCTA}
                                        handleClickTitle={handleClickTitle}
                                        logisticsTemplateId={
                                            logisticsTemplateId
                                        }
                                        rows={rows}
                                        setIsLoading={setIsLoading}
                                        setRows={setRows}
                                        isInstructor={isInstructor}
                                    />

                                    <TablePagination
                                        component="div"
                                        count={total}
                                        page={page}
                                        onPageChange={(e, newPage) => {
                                            setPage(newPage);
                                        }}
                                        rowsPerPage={limit}
                                        onRowsPerPageChange={(e) => {
                                            setLimit(
                                                parseInt(e.target.value, 10)
                                            );
                                        }}
                                    />
                                </>
                            ) : (
                                <Box className={classes.noGuidances}>
                                    <img
                                        src={EmptyImg}
                                        alt="no-guidances"
                                        width={200}
                                        height={200}
                                    />
                                    <Typography variant="body1">
                                        No guidance found for this template
                                    </Typography>

                                    {showRedirectBtn && (
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handleShowGuidancePicker}
                                            disabled={isLoading}
                                        >
                                            Add guidance
                                        </Button>
                                    )}
                                </Box>
                            )}
                        </>
                    </Box>
                )}
            </Box>
        </Container>
    );
}

export default OMDGuidance;
