import { useState, useEffect } from 'react';
import axios from 'axios';
import { debounce } from '../util/debounce';

export const useThirdPartyCourseData = (requirements) => {
    const [thirdPartyCourseData, setThirdPartyCourseData] = useState({});
    // console.log(requirements);
    useEffect(() => {
        const debouncedFetchData = debounce(async () => {
            if (requirements && requirements.length > 0) {
                try {
                    // Get titles and extCourseIds already present in thirdPartyCourseData
                    const existingTitles = Object.keys(
                        thirdPartyCourseData
                    ).filter((key) => thirdPartyCourseData[key]?.title);
                    const existingExtCourseIds = Object.keys(
                        thirdPartyCourseData
                    ).filter((key) => thirdPartyCourseData[key]?.id);

                    // Construct filterByFormula for titles
                    const titles = requirements
                        .filter(
                            (requirement) =>
                                requirement.clientId?.client_name ===
                                    'Coursera' &&
                                requirement.status === 'COMPLETED' &&
                                !existingTitles.includes(requirement.title)
                        )
                        .map((req) => req.title);

                    // Construct filterByFormula for extCourseIds
                    const extCourseIds = requirements
                        .filter(
                            (requirement) =>
                                requirement.clientId?.client_name ===
                                    'Coursera' &&
                                requirement.status === 'COMPLETED' &&
                                requirement.extCourseId &&
                                !existingExtCourseIds.includes(
                                    requirement.extCourseId
                                )
                        )
                        .map((req) => req.extCourseId);
                    // console.log(titles, extCourseIds);
                    if (titles.length === 0 && extCourseIds.length === 0)
                        return;

                    // console.log(extCourseIds);

                    const filterByFormula = `OR(${[
                        ...titles.map(
                            (title) => `{Content Title (Editable)}="${title}"`
                        ),
                        ...extCourseIds.map((id) => `RECORD_ID()="${id}"`),
                    ].join(',')})`;

                    // Airtable API URL and headers
                    const airtableURL = `${process.env.REACT_APP_AIRTABLE_API_DOMAIN}/v0/${process.env.REACT_APP_COURSERA_AIRTABLE_BASE_ID}/${process.env.REACT_APP_COURSERA_AIRTABLE_VIEW_ID}/listRecords`;
                    const airtableHeaders = {
                        headers: {
                            Authorization: `Bearer ${process.env.REACT_APP_COURSERA_AIRTABLE_TOKEN}`,
                            'Content-Type': 'application/json',
                        },
                    };
                    const airtableBody = {
                        filterByFormula,
                        fields: [
                            'Content Title (Editable)',
                            'Vendor Instructor Name (editable)',
                            'Courses Course Total Enrollments Count (from CPN/CIN Launched Data Import (Looker)) 2',
                            'Course Enrollments Average Star Rating (from CPN/CIN Launched Data Import (Looker)) 2',
                        ],
                    };

                    // Fetch data from Airtable
                    const airtableResponse = await axios.post(
                        airtableURL,
                        airtableBody,
                        airtableHeaders
                    );
                    const records = airtableResponse.data.records;

                    // Map records to an object with titles and extCourseIds as keys
                    const data = records.reduce((acc, record) => {
                        const title = record.fields['Content Title (Editable)'];
                        const id = record.id;
                        const courseData = {
                            id,
                            title,
                            instructors:
                                record.fields[
                                    'Vendor Instructor Name (editable)'
                                ] || '',
                            enrollments:
                                record.fields[
                                    'Courses Course Total Enrollments Count (from CPN/CIN Launched Data Import (Looker)) 2'
                                ] || 0,
                            rating:
                                record.fields[
                                    'Course Enrollments Average Star Rating (from CPN/CIN Launched Data Import (Looker)) 2'
                                ] || 0,
                        };
                        acc[title] = courseData;
                        acc[id] = courseData;
                        return acc;
                    }, {});

                    setThirdPartyCourseData((prevData) => ({
                        ...prevData,
                        ...data,
                    }));
                } catch (error) {
                    console.error('Error fetching Airtable data:', error);
                }
            }
        }, 500);

        debouncedFetchData();
    }, [requirements]);

    return thirdPartyCourseData;
};
