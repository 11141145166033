import { useRef, useEffect } from 'react';
import { Avatar, Box, Badge, Button, Tooltip, CircularProgress } from '..';
import { EditIcon } from '../../icons';
import { makeStyles } from 'tss-react/mui';
import { IMAGE_MIME_TYPES } from '../../util';
import { blue } from '@mui/material/colors';

const useStyles = makeStyles()((theme) => ({
    avatar: {
        margin: '0 auto',
        width: 200,
        height: 200,
        boxShadow:
            '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    },

    avatarContainerContainer: {
        marginTop: 150,
    },

    avatarContainer: {
        position: 'relative',

        zIndex: 1,
        /* '&:before': {
            position: 'absolute',
            content: '""',
            top: '-39%',
            left: '-40%',
            width: '175%',
            height: '175%',
            transform: 'rotate(315deg)',
            backgroundImage: `url(${Blob})`,
            backgroundSize: 'cover',
            zIndex: '-1',
        }, */
    },

    avatarLoading: {
        margin: '0 auto',
        width: 200,
        height: 200,
        // backgroundColor: theme.palette.common.blue,
        borderRadius: '50%',
        display: 'grid',
        placeItems: 'center',
    },

    // USED IN --> PersonalInfo, SignInInfo
    avatarLetter: {
        fontSize: 'min(4.5em,7vw)',
        backgroundColor: blue[500],
    },
    filesInput: {
        opacity: 0,
        position: 'absolute',
        zIndex: -1,
    },
    edit: {
        minWidth: '50px',
        borderRadius: '50%',
        height: '50px',
    },
}));

export const ProfileImgPicker = ({
    selectedImage,
    setSelectedImage,
    // setProfilePicErr,
    // profilePicErr,
    disabled,
    isSaving,
    onSelectImage,
    onContinue,
    onBack,
    saveBtnText,
}) => {
    const { classes } = useStyles();

    const drop = useRef(null);

    const handleSelect = (event) => {
        // if (profilePicErr.error) {
        //     setProfilePicErr({ error: false, message: '' });
        // }
        if (isSaving) return;
        const file = event.target.files[0];

        if (
            file &&
            typeof file !== 'string' &&
            IMAGE_MIME_TYPES.includes(file.type)
        ) {
            if (setSelectedImage) setSelectedImage(file);
            if (onSelectImage) onSelectImage(file);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isSaving) return;

        const files = [...e.dataTransfer.files];

        if (files && files.length) {
            const file = files[0];
            if (
                file &&
                typeof file !== 'string' &&
                IMAGE_MIME_TYPES.includes(file.type)
            ) {
                if (setSelectedImage) setSelectedImage(file);
                if (onSelectImage) onSelectImage(file);
            }
        }
    };

    useEffect(() => {
        drop.current.addEventListener('dragover', handleDragOver);
        drop.current.addEventListener('drop', handleDrop);

        return () => {
            if (!drop.current) return;
            drop.current.removeEventListener('dragover', handleDragOver);
            drop.current.removeEventListener('drop', handleDrop);
        };
    }, []);

    return (
        <>
            <Box display="flex" justifyContent="center">
                <Badge
                    className={classes.avatarContainer}
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    ref={drop}
                    badgeContent={
                        <Tooltip
                            title={
                                disabled
                                    ? ''
                                    : 'Only upload images with a transparent background. Recommended site to create transparency: https://www.remove.bg/'
                            }
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                component="label"
                                className={classes.edit}
                                disabled={disabled || isSaving}
                                size="small"
                            >
                                {isSaving ? (
                                    <CircularProgress size="1.9em" />
                                ) : (
                                    <>
                                        <>
                                            <EditIcon fontSize="small" />
                                            <input
                                                type="file"
                                                hidden
                                                onChange={handleSelect}
                                            />
                                        </>
                                    </>
                                )}
                            </Button>
                        </Tooltip>
                    }
                >
                    <Avatar
                        alt={'profile'}
                        src={
                            typeof selectedImage === 'string'
                                ? selectedImage
                                : selectedImage
                                ? URL.createObjectURL(selectedImage)
                                : ''
                        }
                        className={classes.avatar}
                    />
                </Badge>
            </Box>

            <Box display="flex" mt={4}>
                {onBack && (
                    <Button
                        onClick={onBack}
                        variant="contained"
                        color="primary"
                    >
                        Back
                    </Button>
                )}

                {onContinue && (
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        // disabled={isSaving}
                        onClick={onContinue}
                        style={{ marginLeft: 'auto' }}
                    >
                        {false ? (
                            <CircularProgress size="1.9em" />
                        ) : (
                            <>{saveBtnText || 'Save'}</>
                        )}
                    </Button>
                )}
            </Box>
        </>
    );
};
