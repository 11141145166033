import { makeStyles } from 'tss-react/mui';
import NoComment from '../../../img/no_comment.svg';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()((theme) => {
    return {
        emptyFeedContainer: {
            display: 'grid',
            placeItems: 'center',
        },
    };
});

function NoFeeds() {
    const { classes } = useStyles();
    return (
        <Box className={classes.emptyFeedContainer}>
            <img src={NoComment} alt="no feeds" height={200} width={200} />
            <Typography variant="h6" textAlign="center">
                No Feeds!
            </Typography>
        </Box>
    );
}

export default NoFeeds;
