import { initialState_type } from './state';
import { UseState, UseDispatch } from '../index';
import { vars } from '../index';

export const toggleArray = (payload, newState) => {
    const { btn, elem } = payload; // btn means the radio options, or subOptions, same thing.
    if (btn === 'Ratings' || btn === 'Level' || btn === 'Duration') {
        return [elem];
    }
    let radioArray = [...newState[btn]];
    let indx;

    let actioned = '';

    if (elem === 'All Categories' || elem === 'All Languages') {
        if (radioArray.includes(elem)) {
            radioArray = [];
        } else {
            radioArray = [elem];
        }
        return radioArray;
    } else {
        // if elem === "All Categories" or "All Languages", empty the array before continuing
        if (
            radioArray.includes('All Categories') ||
            radioArray.includes('All Languages')
        ) {
            radioArray = [];
        }

        if (radioArray.includes(elem)) {
            indx = radioArray.indexOf(elem);
            radioArray.splice(indx, 1);
            // actioned = 'deleted';
        } else {
            radioArray = [elem, ...radioArray];
            // actioned = 'added';
        }

        return radioArray;
    }
};

// mainly used by material-ui-radio to check if it should stay on or off.
export const exists_in_state_value = (state, btn, elem) => {
    // console.log({ state, btn, elem });
    if (state && btn && elem) {
        const grab_radio_from_state = state[btn];
        if (grab_radio_from_state === undefined) {
            console.log({ btn, elem });
        } else if (grab_radio_from_state.includes(elem)) {
            return true;
        } else return false;

        // const indx = grab_radio_from_state.indexOf(elem);
        // return indx > -1;
    } else console.log('Something wrong');
};
