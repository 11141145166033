import { useEffect, useMemo, useState } from 'react';
import { Box, Grid, NavigationTab } from '../ui';
import { useStyles } from './styles';
import { useParams } from 'react-router-dom';

import { SavedOnDemand } from './SavedOnDemand';

import { dashboardRoute as route } from '../seo/routeMetaData';
import { HelmetWrapper } from '../seo';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import Chat from '../chat';

export const Content = ({
    // section,
    socket,
    user,
    savedCourses,
    enrolledOnDemand,
    privateOnDemand,
    userOnAir,
}) => {
    const { subSection: section } = useParams();

    const theme = useTheme();
    const isLargeDevice = useMediaQuery(theme.breakpoints.up('sm'));

    const tabValues = useMemo(() => {
        return [
            {
                name: 'MY COURSES',
                slug: 'my-courses',
                component: (
                    <SavedOnDemand
                        user={user}
                        savedOnDemand={enrolledOnDemand || []}
                        showViewAll={false}
                    />
                ),
            },

            {
                name: 'PRIVATE COURSES',
                slug: 'private-course',
                hideTab: !privateOnDemand?.length,
                component: (
                    <SavedOnDemand
                        user={user}
                        savedOnDemand={privateOnDemand || []}
                        showViewAll={false}
                    />
                ),
            },
        ];
    }, [enrolledOnDemand, privateOnDemand, user]);

    const initialTabValue = section
        ? tabValues.findIndex(({ slug }) => slug === section)
        : 0;
    // VALUES USED FOR NAVIGATION
    const [value, setValue] = useState(initialTabValue);

    useEffect(() => {
        console.log('setValue');
        setValue(
            section ? tabValues.findIndex(({ slug }) => slug === section) : 0
        );
    }, [section, tabValues]);

    return (
        <>
            <HelmetWrapper route={route} />
            {user && (
                <Box
                    style={{
                        width: '90%',
                        margin: 'auto',
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        maxWidth={'xl'}
                        marginInline={'auto'}
                    >
                        <Grid item xs={12}>
                            <Box>
                                <NavigationTab
                                    collections={tabValues}
                                    value={value}
                                    setValue={setValue}
                                    basePath="/profile/dashboard/content"
                                    // vertical
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {tabValues[value].component}
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};
