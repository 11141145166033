import { Box, Typography, Grid, Button, Hidden } from '../ui';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '../icons';
import learnBg from '../img/tiago.png';
import { makeStyles } from 'tss-react/mui';
import { orange } from '@mui/material/colors';

export const Learning = () => {
    const { classes } = useStyles();

    return (
        <Grid container style={{ position: 'relative' }}>
            <Box className={classes.sectionTitle}>Learning</Box>
            <Grid item md={6} className={classes.bgImg} />
            <Grid item xs={12} md={6}>
                <Hidden mdDown>
                    <Typography
                        variant="h6"
                        className={classes.sectionHeading}
                        gutterBottom
                    >
                        Learn from our worldclass instructors
                    </Typography>
                </Hidden>
                <Box className={classes.sectionPart}>
                    <Box>
                        <Typography
                            variant="h4"
                            className={classes.subheading}
                            gutterBottom
                        >
                            On-Air
                        </Typography>
                        <Typography variant="subtitle1">
                            See our top instructors teaching classes live
                        </Typography>
                    </Box>
                    <Link to="/on-air">
                        <Button
                            disableElevation
                            className={classes.btn}
                            variant="contained"
                            color="secondary"
                            endIcon={<ChevronRightIcon />}
                        >
                            View
                        </Button>
                    </Link>
                </Box>
                <Box className={classes.sectionPart}>
                    <Box>
                        <Typography
                            variant="h4"
                            className={classes.subheading}
                            gutterBottom
                        >
                            On Demand
                        </Typography>
                        <Typography variant="subtitle1">
                            Get a look at our exclusive On Demand Courses
                        </Typography>
                    </Box>
                    <Link to="/category/all/courses">
                        <Button
                            disableElevation
                            className={classes.btn}
                            variant="contained"
                            color="secondary"
                            endIcon={<ChevronRightIcon />}
                        >
                            View
                        </Button>
                    </Link>
                </Box>
                <Box className={classes.sectionPart}>
                    <Box>
                        <Typography
                            variant="h4"
                            className={classes.subheading}
                            gutterBottom
                        >
                            Certifications
                        </Typography>
                        <Typography variant="subtitle1">
                            View all the certification course we provide
                        </Typography>
                    </Box>
                    <Link to="/certifications/all/courses">
                        <Button
                            disableElevation
                            className={classes.btn}
                            variant="contained"
                            color="secondary"
                            endIcon={<ChevronRightIcon />}
                        >
                            View
                        </Button>
                    </Link>
                </Box>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles()((theme) => ({
    bgImg: {
        clipPath: 'polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%)',
        height: 'min(400px, 50vw)',
        transform: 'scale(1.1) translateX(-20%)',
        [theme.breakpoints.only('md')]: {
            transform: 'scale(1.2) translateX(-20%) translateY(15px)',
        },
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundImage: `url(${learnBg})`,
        position: 'relative',
    },
    sectionTitle: {
        position: 'absolute',
        bottom: '50px',
        left: '27%',
        fontSize: '2em',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        zIndex: 200,
        fontWeight: 800,
        padding: '10px 30px',
        boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
        clipPath: 'polygon(12% 0%, 100% 0%, 88% 100%, 0% 100%)',
        [theme.breakpoints.down('md')]: {
            top: '-25px',
            height: 'fit-content',
            left: '10%',
        },
    },
    sectionPart: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexFlow: 'row',
        margin: '20px 0',
        [theme.breakpoints.down('md')]: {
            '&:nth-child(1)': {
                marginTop: '50px',
            },
        },
    },
    sectionHeading: {
        color: '#fff',
        fontWeight: 300,
        marginTop: '10px',
    },
    subheading: {
        color: '#eaeaea',
        position: 'relative',
        '&::before': {
            position: 'absolute',
            content: '""',
            left: 0,
            bottom: '-3px',
            height: '3px',
            borderRadius: '5px',
            width: '7%',
            backgroundColor: theme.palette.secondary.main,
        },
    },
    btn: {
        borderRadius: '3px',
        marginRight: '20px',
        '&:hover': {
            backgroundColor: orange[700],
        },
    },
}));
