import { createSlice } from '@reduxjs/toolkit';

export const analyticsSlice = createSlice({
    initialState: {
        isExists: {
            counterSummary: true,
            requirementBreakdown: true,
            customReport: true,
            responsibilitiesAndDeliverables: true,
            reqAccrossDomain: true,
            reqAccrossTypes: true,
        },
    },
    name: 'analytics',
    reducers: {
        updateIsExists: (state, action) => {
            const { payload } = action;

            return {
                ...state,
                isExists: {
                    counterSummary:
                        payload.counterSummary !== undefined
                            ? payload.counterSummary
                            : state.isExists.counterSummary,
                    requirementBreakdown:
                        payload.requirementBreakdown !== undefined
                            ? payload.requirementBreakdown
                            : state.isExists.requirementBreakdown,
                    customReport:
                        payload.customReport !== undefined
                            ? payload.customReport
                            : state.isExists.customReport,
                    responsibilitiesAndDeliverables:
                        payload.responsibilitiesAndDeliverables !== undefined
                            ? payload.responsibilitiesAndDeliverables
                            : state.isExists.responsibilitiesAndDeliverables,
                    reqAccrossDomain:
                        payload.reqAccrossDomain !== undefined
                            ? payload.reqAccrossDomain
                            : state.isExists.reqAccrossDomain,
                    reqAccrossTypes:
                        payload.reqAccrossTypes !== undefined
                            ? payload.reqAccrossTypes
                            : state.isExists.reqAccrossTypes,
                },
            };
        },
    },
});

export const { updateIsExists } = analyticsSlice.actions;
export default analyticsSlice.reducer;
