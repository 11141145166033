import { useEffect, useState, useRef, useMemo } from 'react';
import firebase from 'firebase/app';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MenuIcon } from '../icons';
import { InputBase } from '../ui';
import logo from '../starweaver-logo-v2.webp';
import { SearchIcon, CloseIcon } from '../icons';
import { Paper } from '../ui';
import ProfileDropDown from './ProfileDropDown';
import { Fade, Typography } from '@mui/material';
import { ViewButton } from './ViewButton';
import {
    AppBar,
    Badge,
    Box,
    Button,
    Hidden,
    Icon,
    IconButton,
    Toolbar,
    Tooltip,
} from '../ui';
import { WhyStarweaver } from './WhyStarweaver';
import { SearchBox } from '../search-box';
import {
    loginRoute,
    instructorSignUp,
    instructorLogin,
    dashboardRoute,
    instructorDashboard,
    adminRoute,
} from './route-definitions';
import { useUser } from '../auth';
import { useParams } from 'react-router-dom';
import { useSetRecoilState, useRecoilState } from 'recoil';
import {
    searchQueryState,
    searchPageNumberState,
} from '../search-and-browse/state';
import { useNavigate } from 'react-router-dom';
import { SignUpButtons } from './SignUpButtons';
import { useTheme } from '@mui/material/styles';
import { NotificationButton } from '../notifications';
// import { useRouteType } from './context';

import { DomainDropDown } from './DomainDropDown';
import { useChat } from '../chat/useChat';
import ChatIcon from '@mui/icons-material/Chat';
import NextInstructorInvitational from '../instructor-dashboard/NextInstructorInvitational';

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // backgroundColor: theme.palette.background.default,
        padding: '0px !important',
    },
    toolbar: {
        [theme.breakpoints.only('md')]: {
            padding: 5,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.only('md')]: {
            marginRight: 0,
        },
    },
    logoContainer: {
        width: 'fit-content',
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
    },
    title: {
        /* margin: '0px auto', */
        marginTop: 10,

        width: 'fit-content',
        [theme.breakpoints.up('md')]: {
            marginRight: '0px',
        },
        //alignSelf: 'flex-end',
    },
    upgrade: {
        marginLeft: '10px',
    },
    subBarContainer: {
        position: 'relative',
    },
    dropDownMenu: {
        position: 'absolute',
        width: '70%',
        margin: '0 auto',
        right: '10%',
        padding: '15px 20px 10px',
        zIndex: 1000,
        height: 'fit-content',
        backgroundColor: 'rgba(250,250,250,1)',
        animation: '$enter 0.2s ease-out forwards',
        boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
    },
    bounce: {
        animation: '$bounce 1s infinite',
        'animation-fill-mode': 'forwards',
        '&:hover': {
            animationPlayState: 'paused',
        },
    },
    '@keyframes bounce': {
        '0%': {
            transform: 'scale(1,1) translate(0px, 0px)',
        },

        '30%': {
            transform: 'scale(1,0.8) translate(0px, 5px)',
        },

        '75%': {
            transform: 'scale(1,1.1) translate(0px, -10px)',
        },

        '100%': {
            transform: 'scale(1,1) translate(0px, 0px)',
        },
    },
    logoStyleName: {
        height: '60px',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            // height: '40px',
            width: 'auto',
            display: 'inline-block',
        },
        [theme.breakpoints.down('md')]: {
            display: 'inline-block',
        },
    },
    logoStyleNameNot: {
        height: '48px',
        width: 'auto',
        [theme.breakpoints.down('md')]: {
            // height: '50px',
            width: 'auto',
            // display: 'block',
        },
        [theme.breakpoints.down('sm')]: {
            // height: '30px',
            width: 'auto',
        },
    },

    browseLinkContainer: {
        color: 'rgba(0,0,0,0.6)',
        marginRight: 20,
        '&:hover': {
            color: 'rgba(0,0,0,0.8)',
        },
    },
    browseLinkText: {
        fontSize: '0.8rem',
    },
    searchBoxContainer: {
        marginRight: 'auto',
        flexGrow: 1,
        flexShrink: 1,
        maxWidth: '35%',
    },
    leftIconBtn: {
        padding: 10,
        borderRadius: 0,
        background: '#3f566b',
        borderColor: '#3f566b',
        marginBottom: 2,
        marginTop: -1,
        '&:hover': {
            backgroundColor: '#3f566b',
        },
    },

    iconBtn: {
        padding: 10,
        borderRadius: 0,
        background: '#3f566b',
        borderColor: '#3f566b',
        marginBottom: 2,
        marginTop: -1,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#3f566b',
        },
    },
    boxRoot: {
        height: 35,
        borderRadius: 5,
    },
    boxRootSearchIcon: {
        right: 0,
        padding: 13,
        position: 'absolute',
        color: '#6c757d',
        marginTop: 5,
    },
    dropdownButton: {
        transition: 'all 150ms linear',
        '&:hover': {
            backgroundColor: '#EA7112',
            color: '#ffff',
        },
    },

    chatIconHidden: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            pointerEvents: 'none',
        },
    },
}));

export const NavBar = ({
    onDrawerToggle,
    isInstructorRoute,
    isAdminRoute,
    // routeRoles,
    routeRole,
    mongoUser,
    socket,
    allowedRoleViews = [],
}) => {
    const { user, isLoading } = useUser();
    const { classes } = useStyles();
    let navigate = useNavigate();
    const theme = useTheme();
    const buttonRef = useRef(null);
    const url = useParams();
    const smallScreen = useMediaQuery('(max-width:450px)');
    // const [openMenu, setOpenMenu] = useState(false);
    const [enableSearch, setenableSearch] = useState(false);
    const [query, setQuery] = useRecoilState(searchQueryState);
    const setPageNumber = useSetRecoilState(searchPageNumberState);
    const [isAdmin, setIsAdmin] = useState(false);
    // const { setValue } = useRouteType();

    // useEffect(() => {
    //     setValue({
    //         isAdmin: isAdminRoute,
    //         isInstructor: isInstructorRoute,
    //     });
    //     // eslint-disable-next-line
    // }, [isAdminRoute, isInstructorRoute]);

    useEffect(() => {
        (async () => {
            if (user && !isLoading) {
                const authtokenResult = await firebase
                    .auth()
                    .currentUser.getIdTokenResult();
                if (authtokenResult?.claims?.admin) setIsAdmin(true);
            }
        })();
    }, [user, isLoading]);

    const hideSearchBox = useMemo(() => {
        if (!user) return false;
        if (isInstructorRoute || isAdminRoute) return true;
        return false;
    }, [isAdminRoute, isInstructorRoute, user]);

    const isXSmallScreen = useMediaQuery(theme.breakpoints.up('xs'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const isMediumScreen = useMediaQuery('(min-width:1150px)');
    const isLargeScreen = useMediaQuery('(min-width:1300px)');
    const isXLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
    const isXXLargeScreen = useMediaQuery('(min-width:1900px)');

    const enableSearchView = () => {
        setQuery('');
        setenableSearch(true);
    };

    const { totalUnreadMessages, minimizeAll } = useChat(null, mongoUser);

    // const buttons = useMemo(() => {
    //     return [
    //         {
    //             name: 'Why starweaver',
    //             onClickHandler: () => {
    //                 setOpenMenu(!openMenu);
    //             },
    //             onMouseEnterHandler: () => {
    //                 setOpenMenu(true);
    //             },
    //             onMouseLeaveHandler: () => {
    //                 setOpenMenu(false);
    //             },
    //             ref: buttonRef,
    //             endIcon: <ExpandMoreIcon />,
    //         },
    //     ];
    // }, [openMenu]);
    // will use later to make that bottom menu anchor to the element
    // console.log(buttonRef.current.getBoundingClientRect());

    // const handleCloseMenu = () => {
    //     if (openMenu) {
    //         setOpenMenu(false);
    //     }
    // };

    const onSearchBoxChange = (e) => {
        setQuery(e.target.value);
        setPageNumber(1);
    };

    const goToSearchResultsPage = () => {
        setQuery(query);
        if (query !== '')
            navigate({
                pathname: '/search',
                /* search: `?q=${searchString}`, */
            });
    };

    const clickHandler = () => {
        goToSearchResultsPage();
    };

    const keyPressHandler = (e) => {
        if (e.key === 'Enter') {
            goToSearchResultsPage();
        }
    };

    return (
        <AppBar
            swid="nav-bar"
            position="fixed"
            className={classes.appBar}
            p={2}
        >
            <Toolbar
                style={{
                    position: 'relative',
                }}
                className={classes.toolbar}
            >
                <Hidden mdUp>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={onDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                </Hidden>

                {!user && (
                    <Hidden mdDown>
                        {/* <SignUpButtons
                            smallScreen={smallScreen}
                            instructorLogin={instructorLogin}
                            instructorSignUp={instructorSignUp}
                            loginRoute={loginRoute}
                        />
                        /> */}
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={onDrawerToggle}
                            className={classes.menuButton}
                            size="large"
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                )}

                <Box className={classes.logoContainer}>
                    <Box
                        className={classes.title}
                        display={enableSearch ? 'none' : 'block'}
                    >
                        <Link
                            to={
                                user
                                    ? isAdminRoute
                                        ? adminRoute.path
                                        : isInstructorRoute
                                        ? instructorDashboard.path
                                        : '/on-air'
                                    : '/'
                            }
                        >
                            <img
                                className={
                                    user
                                        ? classes.logoStyleName
                                        : classes.logoStyleNameNot
                                }
                                src={logo}
                                alt="Starweaver logo"
                                height="50px"
                                width="180px"
                            />
                        </Link>
                    </Box>
                </Box>

                <Hidden only={['md', 'lg', 'xl']}>
                    <Box
                        display={enableSearch ? 'block' : 'none'}
                        style={{ position: 'absolute', width: '95%' }}
                    >
                        <Paper variant="outlined" className={classes.boxRoot}>
                            <Box
                                display="flex"
                                justifyContent="flex-start"
                                className={classes.boxRoot}
                            >
                                <IconButton
                                    onClick={() => setenableSearch(false)}
                                    className={classes.leftIconBtn}
                                    aria-label="search"
                                    size="large"
                                >
                                    <CloseIcon style={{ color: '#fff' }} />
                                </IconButton>
                                <InputBase
                                    fullWidth
                                    onChange={onSearchBoxChange}
                                    onKeyDown={keyPressHandler}
                                    value={query}
                                    placeholder="Search courses, certifications, journeys, articles…"
                                    style={{ padding: 10 }}
                                />
                                <IconButton
                                    onClick={clickHandler}
                                    className={classes.iconBtn}
                                    aria-label="search"
                                    size="large"
                                >
                                    <SearchIcon style={{ color: '#fff' }} />
                                </IconButton>
                            </Box>
                        </Paper>
                    </Box>
                </Hidden>

                {hideSearchBox === false && (
                    <Box className={classes.searchBoxContainer}>
                        <Hidden mdDown>
                            <SearchBox
                                width={
                                    user
                                        ? 400
                                        : isXXLargeScreen
                                        ? 650
                                        : isXLargeScreen
                                        ? 450
                                        : isLargeScreen
                                        ? 350
                                        : isMediumScreen
                                        ? 200
                                        : 150
                                }
                            />
                        </Hidden>
                    </Box>
                )}

                {!user && !isLoading && (
                    <Hidden mdDown>
                        <Box display="flex" alignItems="center" ml={1}>
                            <Link to="/instructor-signup">
                                <Button
                                    color="secondary"
                                    className={`${classes.dropdownButton}`}
                                >
                                    Teach with us
                                </Button>
                            </Link>
                            <Box ml={1}>
                                {navButtons.map((button, i) => (
                                    <Button
                                        key={i}
                                        color="secondary"
                                        onClick={() => {
                                            window.open(button.path, '_self');
                                        }}
                                        style={{ marginLeft: 9 }}
                                        className={classes.dropdownButton}
                                    >
                                        {button.name}
                                    </Button>
                                ))}
                            </Box>
                        </Box>
                    </Hidden>
                )}

                {!user && !isLoading ? (
                    <Hidden mdDown>
                        <SignUpButtons
                            smallScreen={smallScreen}
                            instructorLogin={instructorLogin}
                            instructorSignUp={instructorSignUp}
                            loginRoute={loginRoute}
                        />
                    </Hidden>
                ) : (
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        marginLeft={'auto'}
                    >
                        <Hidden mdDown>
                            <>
                                {isInstructorRoute ? (
                                    <Box mr={1}>
                                        <NextInstructorInvitational
                                            userName={user.displayName}
                                            userEmail={user.email}
                                        />
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </>
                        </Hidden>
                        {mongoUser &&
                        (mongoUser.isAdmin || mongoUser.is_instructor)
                            ? !smallScreen && (
                                  <IconButton
                                      onClick={() => {
                                          minimizeAll();
                                          navigate('/messaging');
                                      }}
                                      size="large"
                                      className={
                                          smallScreen
                                              ? classes.chatIconHidden
                                              : undefined
                                      }
                                  >
                                      <Badge
                                          color="error"
                                          badgeContent={totalUnreadMessages}
                                      >
                                          <ChatIcon />
                                      </Badge>
                                  </IconButton>
                              )
                            : null}
                        <NotificationButton
                            isInstructorRoute={isInstructorRoute}
                            isAdminRoute={isAdminRoute}
                            socket={socket}
                        />
                        <Hidden mdDown>
                            <Box
                                display={'flex'}
                                alignItems="center"
                                mr={'230px'}
                            >
                                <ViewButton
                                    user={mongoUser}
                                    isAdmin={isAdmin}
                                    isAdminRoute={isAdminRoute}
                                    isInstructorRoute={isInstructorRoute}
                                    routeRole={routeRole}
                                    allowedRoleViews={allowedRoleViews}
                                />
                            </Box>
                            <ProfileDropDown
                                isInstructorView={isInstructorRoute}
                                isAdminView={isAdminRoute}
                            />
                        </Hidden>
                    </Box>
                )}
            </Toolbar>

            {/* <Box className={classes.subBarContainer} swid="sub-nav-bar">
                    {openMenu && (
                        <Fade in={openMenu}>
                            <Box
                                className={classes.dropDownMenu}
                                onMouseLeave={() => setOpenMenu(false)}
                            >
                               
                                <WhyStarweaver />
                            </Box>
                        </Fade>
                    )}
                </Box> */}
        </AppBar>
    );
};

const navButtons = [
    // {
    //     path: `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/techbytes-online-technology-blog`,
    //     name: 'Techbytes',
    // },
    // {
    //     path: '/on-air/meet-the-gurus',
    //     name: 'Meet the Gurus',
    // },
    // {
    //     path: '/for-campus/students',
    //     name: 'For campus',
    // },
    {
        // path: `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/free-enterprise-trial`,
        path: '/free-enterprise-trial',
        name: 'For business',
    },
];

export default NavBar;
