function isArrayPrefix(arr1, arr2) {
    if (arr1.length > arr2.length) {
        return false; // arr1 can't be a prefix if it's longer than arr2
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false; // If any element doesn't match, it's not a prefix
        }
    }

    return true; // All elements match, arr1 is a prefix of arr2
}

export { isArrayPrefix };
