import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useQueryParams } from '../util/useQueryParams';
import { convertArrayToObject, defaultColorArray } from '../util';
import { useRecoilState } from 'recoil';
import { chatStateAtom } from './recoil';

export const useChat = (socket, user, docked, isXLDevice, isLGDevice) => {
    // const [chats, setChats] = useState([]);

    // const [selectedChatIndex, setSelectedChatIndex] = useState(null);

    // const [isLoadingChats, setIsLoadingChats] = useState(false);

    const { c } = useQueryParams();

    const [chatState, setChatState] = useRecoilState(chatStateAtom);

    // console.log(chatState);

    const {
        chats,
        usersById,
        // selectedChatIndex,
        selectedChatId,
        openedChats,
        isLoadingChats,
        minimized,
        showChatUsersOnMobile,
    } = chatState;

    // console.log(selectedChatId);

    // console.log(
    //     chats.length
    //         ? chats[0].users?.map(({ lastMessageSeenAt }) => lastMessageSeenAt)
    //         : []
    // );

    // const [messageInput, setMessageInput] = useState('');

    // const messageInputEl = useRef(null);

    // const messagesEl = useRef(null);

    // console.log({ messagesEl, messageInputEl });

    // const [anchorEl, setAnchorEl] = useState(null);

    const userByIdUpdated = useMemo(() => {
        if (usersById && Object.values(usersById).length) {
            let newUserById = {};
            Object.values(usersById).forEach((data) => {
                const userId = `${data._id}`;
                const color =
                    defaultColorArray[
                        (userId.charCodeAt(0) +
                            userId.charCodeAt(userId.length - 1)) %
                            defaultColorArray.length
                    ];
                newUserById[userId] = { ...data, color };
            });
            return newUserById;
        } else {
            return {};
        }
    }, [usersById]);

    useEffect(() => {
        (async () => {
            try {
                if (!docked) return;
                if (user) {
                    //setIsLoadingChats(true);
                    setChatState((prev) => ({ ...prev, isLoadingChats: true }));

                    const resUsers = await axios.get(
                        `/api/chats/users?userId=${user._id}`
                    );

                    const resChats = await axios.get(
                        `/api/chats/user/${user._id}`
                    );

                    // let initialSelectedChatIndex = resChats.data.findIndex(
                    //     (chat) => chat._id == c
                    // );

                    let initialSelectedChatId = c
                        ? c
                        : resChats?.data?.length
                        ? resChats?.data[0]._id
                        : null;

                    // if (initialSelectedChatIndex === -1)
                    //     initialSelectedChatIndex = 0;

                    setChatState((prev) => ({
                        ...prev,
                        chats: resChats.data,
                        usersById: convertArrayToObject(resUsers.data, '_id'),
                        // selectedChatIndex:
                        //     resChats.data.length > 0
                        //         ? initialSelectedChatIndex
                        //         : null,
                        selectedChatId: initialSelectedChatId,
                        isLoadingChats: false,
                    }));
                    // scrollDown();

                    // // Select the element with the id "zsiqscript"
                    // var elems = document.getElementsByClassName(
                    //     'zsiq_floatmain'
                    // );
                    // // Check if the element exists
                    // if (elems.length) {
                    //     // Set the display property to "none"
                    //     elems[0].style.display = 'none';
                    // } else {
                    //     console.log("Element with id 'zsiqscript' not found.");
                    // }
                }
            } catch (err) {
                //navigate(('/404'));
                console.log(err);
            }
        })();
    }, [user?._id, docked]);

    const getChatUsers = async (chatId) => {
        const resUsers = await axios.get(`/api/chats/users?chatId=${chatId}`);

        const chatUsers = resUsers.data;

        // console.log(chatUsers);

        setChatState((prev) => ({
            ...prev,
            usersById: {
                ...prev.usersById,
                ...convertArrayToObject(chatUsers, '_id'),
            },
        }));
    };

    useEffect(() => {
        if (!docked) return;

        if (socket && user) {
            socket.on(`chat:message:${user._id}`, (msg) => {
                // console.log(msg);

                const chatId = msg.chatId;
                // if (chatId == selectedChatId) {
                //     scrollDown();
                // }
                // console.log(chatId);

                setChatState((prev) => {
                    const chatIndex = prev.chats.findIndex(
                        (chat) => chat._id == chatId
                    );

                    // if (chatIndex === prev.selectedChatIndex) scrollDown();
                    // console.log(chatIndex);
                    // return prev;

                    if (chatIndex !== -1)
                        return {
                            ...prev,
                            chats: [
                                {
                                    ...prev.chats[chatIndex],
                                    messages: [
                                        ...prev.chats[chatIndex].messages,
                                        msg.newMsg,
                                    ],
                                },
                                ...prev.chats.filter(
                                    (c) => prev.chats[chatIndex]._id != c._id
                                ),
                            ],
                            // selectedChatIndex:
                            //     chatIndex > prev.selectedChatIndex
                            //         ? prev.selectedChatIndex + 1
                            //         : chatIndex === prev.selectedChatIndex
                            //         ? 0
                            //         : prev.selectedChatIndex,
                        };
                    // TODO: load chat
                    else {
                        getChatUsers(chatId);
                        return {
                            ...prev,
                            chats: [
                                {
                                    _id: chatId,
                                    users: [
                                        {
                                            user: user._id,
                                            lastMessageSeenAt: null,
                                        },
                                        {
                                            user: msg.newMsg.from,
                                            lastMessageSeenAt: null,
                                        },
                                    ],
                                    messages: [msg.newMsg],
                                },

                                ...prev.chats,
                            ],
                            selectedChatId: prev.selectedChatId
                                ? prev.selectedChatId
                                : chatId,
                            // selectedChatIndex: prev.selectedChatIndex + 1,
                        };
                    }
                });
            });

            socket.on(`chat:seen-message:${user._id}`, (msg) => {
                // console.log('chat:seen-message', msg);
                setChatState((prev) => {
                    const chatIndex = prev.chats.findIndex(
                        (chat) => chat._id == msg.chatId
                    );

                    // console.log(chatIndex);

                    if (chatIndex !== -1) {
                        const updatedUsers = prev.chats[chatIndex].users.map(
                            (userInChat) => {
                                if (userInChat.user == msg.from) {
                                    return {
                                        ...userInChat,
                                        lastMessageSeenAt:
                                            msg.lastMessageSeenAt,
                                    };
                                }
                                return userInChat;
                            }
                        );

                        const updatedChat = {
                            ...prev.chats[chatIndex],
                            users: updatedUsers,
                        };

                        return {
                            ...prev,
                            chats: [
                                ...prev.chats.slice(0, chatIndex),
                                updatedChat,
                                ...prev.chats.slice(chatIndex + 1),
                            ],
                        };
                    }

                    return prev;
                });
            });

            socket.on(
                `chat:user-online:${user._id}`,
                ({ userId, isOnline /* socketIds  */ }) => {
                    // console.log(userId, socketId);

                    setChatState((prev) => {
                        const usersById = { ...prev.usersById };

                        if (usersById[userId])
                            usersById[userId] = {
                                ...usersById[userId],
                                // socket_ids: socketIds,
                                isOnline,
                            };

                        return {
                            ...prev,
                            usersById,
                        };
                    });
                }
            );

            return () => {
                socket.off(`chat:user-online:${user?._id}`);
                socket.off(`chat:message:${user?._id}`);
                socket.off(`chat:seen-message:${user?._id}`);
            };
        }
    }, [user?._id, socket, docked]);

    // console.log(socket?.id);

    const sendMessage = (
        messageInput,
        attachedFileNames,
        linkMetaData,
        chatId
    ) => {
        const message = messageInput.trim();
        if (message === '') return;

        const selectedChat = chats.find((chat) => chat._id == chatId);

        if (!selectedChat) return;

        if (!socket?.id) return;

        const messageData = {
            from: user._id,
            fromSocketId: socket?.id,
            // to: selectedChat.users
            //     .map(({ user }) => user)
            //     .filter((id) => id != user._id)[0],
            // to: chats[selectedChatIndex].users.filter(
            //     (id) => id != user._id
            // )[0],
            //  chatId: chats[selectedChatIndex]._id,
            chatId,

            body: message,
            linkMetaData: linkMetaData,
            attachedFileNames,
            sentAt: moment().toISOString(),
        };

        socket.emit('chat:message', messageData);

        // setSelectedChat((prev) => ({
        //     ...prev,
        //     messages: [...prev.messages, messageData],
        // }));

        // setChatState((prev) => {
        //     // const chatIndex = prev.selectedChatIndex;

        //     const chatIndex = prev.chats.findIndex(
        //         (chat) => chat._id == chatId
        //     );

        //     return {
        //         ...prev,
        //         chats: [
        //             {
        //                 ...prev.chats[chatIndex],
        //                 messages: [
        //                     ...prev.chats[chatIndex].messages,
        //                     messageData,
        //                 ],
        //             },
        //             ...prev.chats.filter(
        //                 (c) => prev.chats[chatIndex]._id != c._id
        //             ),
        //         ],
        //         // selectedChatIndex: 0,
        //     };
        // });

        // setChatState((prev) => ({
        //     ...prev,
        //     chats: prev.chats.map((chat) => {
        //         if (chat._id == messageData.chatId)
        //             return {
        //                 ...chat,
        //                 messages: [...chat.messages, messageData],
        //             };
        //         else return chat;
        //     }),
        // }));

        // setTimeout(() => {
        //     setMessageInput('');
        //     messageInputEl.current.focus();
        //     scrollDown();
        // }, 0);
    };

    const seenMessage = (chatId) => {
        // console.log(chatId);
        const selectedChat = chats.find((chat) => chat._id == chatId);

        if (!selectedChat) return;

        if (!socket?.id) return;

        const messageSeenData = {
            from: user._id,
            fromSocketId: socket?.id,
            to: selectedChat.users
                .map(({ user }) => user)
                .filter((id) => id != user._id)[0],
            chatId,
            lastMessageSeenAt: moment().toISOString(),
        };

        socket.emit('chat:seen-message', messageSeenData);
    };

    const selectChat = (chatId) => {
        setChatState((prev) => ({
            ...prev,
            // selectedChatIndex: index,
            selectedChatId: chatId,
            showChatUsersOnMobile: false,
        }));
        // scrollDown();
    };

    const setShowChatUsersOnMobile = (value) => {
        setChatState((prev) => ({
            ...prev,
            showChatUsersOnMobile: value,
        }));
    };

    const { numOfUnReadMessagesByChat, totalUnreadMessages } = useMemo(() => {
        if (!user || !chats)
            return { numOfUnReadMessagesByChat: {}, totalUnreadMessages: 0 };

        const unreadMessagesMap = {};
        let totalUnreadMessages = 0;

        chats.forEach((chat) => {
            const currentUserInChat = chat.users.find(
                (userInChat) =>
                    userInChat.user.toString() === user._id.toString()
            );

            if (!currentUserInChat) {
                // Current user not found in the chat users list
                unreadMessagesMap[chat._id] = 0;
                return;
            }

            let count = 0;

            for (let i = chat.messages.length - 1; i >= 0; i--) {
                const message = chat.messages[i];

                if (message.from.toString() === user._id.toString()) {
                    break;
                }

                if (
                    !message.sentAt ||
                    (currentUserInChat.lastMessageSeenAt &&
                        new Date(currentUserInChat.lastMessageSeenAt) >=
                            new Date(message.sentAt))
                ) {
                    break;
                } else {
                    count++;
                }
            }

            unreadMessagesMap[chat._id] = count;
            totalUnreadMessages += count;
        });

        return {
            numOfUnReadMessagesByChat: unreadMessagesMap,
            totalUnreadMessages,
        };
    }, [chats, user]);

    // console.log(numOfUnReadMessagesByChat);

    // const scrollDown = () => {
    //     setTimeout(() => {
    //         if (messagesEl.current) {
    //             messagesEl.current.scrollTop =
    //                 messagesEl?.current?.scrollHeight;
    //         }
    //     }, 50);
    // };

    // function
    // menu
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const getUserChat = async (userId, open) => {
        try {
            const res = await axios.post(`/api/chats`, {
                userId,
            });

            const chat = res.data;
            getChatUsers(chat._id);
            setChatState((prev) => {
                const chatExists = prev.chats.some((c) => c._id === chat._id);
                let updatedChats;

                if (chatExists) {
                    updatedChats = prev.chats.filter((c) => c._id !== chat._id);
                } else {
                    updatedChats = prev.chats;
                }

                return {
                    ...prev,
                    chats: [chat, ...updatedChats],
                    selectedChatId: chat._id,
                };
            });

            if (open) {
                openChat(chat._id);
            } else {
                selectChat(chat._id);
            }
        } catch (err) {
            console.error('Error fetching user chat:', err);
        }
    };
    const toggleMinimized = () => {
        setChatState((prev) => {
            return {
                ...prev,
                minimized: !prev.minimized,
            };
        });
    };

    const closeChat = (chatId) => {
        setChatState((prev) => {
            const updatedOpenedChats = prev.openedChats.filter(
                (chat) => chat.id !== chatId
            );
            return {
                ...prev,
                openedChats: updatedOpenedChats,
            };
        });
    };

    const toggleChatMinimized = (chatId) => {
        setChatState((prev) => {
            const updatedOpenedChats = prev.openedChats.map((chat) => {
                if (chat.id === chatId) {
                    return {
                        ...chat,
                        minimized: !chat.minimized,
                    };
                }
                return chat;
            });
            return {
                ...prev,
                openedChats: updatedOpenedChats,
            };
        });
    };

    const openChat = (chatId) => {
        setChatState((prev) => {
            // Check if the chat is already opened
            const isChatAlreadyOpened = prev.openedChats.some(
                (chat) => chat.id === chatId
            );

            let updatedOpenedChats = [...prev.openedChats];

            if (isChatAlreadyOpened) {
                // If the chat is already opened, set its minimized state to false
                updatedOpenedChats = updatedOpenedChats.map((chat) =>
                    chat.id === chatId ? { ...chat, minimized: false } : chat
                );
            } else {
                // If there are already two opened chats, close the first one
                const max_count = isXLDevice ? 3 : isLGDevice ? 2 : 1;
                if (updatedOpenedChats.length >= max_count) {
                    updatedOpenedChats.shift();
                }

                // Add the new chat to the opened chats array
                updatedOpenedChats.push({ id: chatId, minimized: false });
            }

            return {
                ...prev,
                openedChats: updatedOpenedChats,
            };
        });
    };

    const minimizeAll = () => {
        setChatState((prev) => {
            const updatedOpenedChats = prev.openedChats.map((chat) => ({
                ...chat,
                minimized: true,
            }));

            return {
                ...prev,
                openedChats: updatedOpenedChats,
                minimized: true,
            };
        });
    };

    return {
        chats,
        usersById,
        selectedChatId,
        openedChats,
        isLoadingChats,
        userByIdUpdated,
        sendMessage,
        seenMessage,
        selectChat,
        getUserChat,
        minimized,
        toggleMinimized,
        toggleChatMinimized,
        closeChat,
        openChat,
        totalUnreadMessages,
        numOfUnReadMessagesByChat,
        minimizeAll,
        showChatUsersOnMobile,
        setShowChatUsersOnMobile,
    };
};
