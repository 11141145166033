async function getStreamAnswer(prompt, temperature = 0.9, getter) {
    try {
        const baseUrl = process.env.REACT_APP_NODE_DOMAIN;
        const url = baseUrl + '/api/open-ai/answer-stream';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                prompt,
                temperature,
            }),
        });
        if (!response.ok || !response.body) throw response.statusText;
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let loopRunner = true;
        while (loopRunner) {
            const { value, done } = await reader.read();
            if (done) break;
            const decodedChunk = decoder.decode(value, { stream: true });
            getter(decodedChunk);
        }
    } catch (e) {
        throw new Error(e);
    }
}

export { getStreamAnswer };
