import { useState } from 'react';
import {
    Box,
    Button,
    Tooltip,
    Dialog,
    Typography,
    IconButton,
    DialogContent,
    DialogActions,
    CircularProgress,
} from '../ui';
import {
    DataGridPremium,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';
import { CloseIcon } from '../icons';
import * as XLSX from 'xlsx';
import { makeStyles } from 'tss-react/mui';
import axios from 'axios';
import moment from 'moment';
import { useMediaQuery } from '@mui/material';

export const DRAFT = 'DRAFT';
export const OPEN = 'OPEN';
export const ONDEMAND = 'ON-DEMAND';

const useStyles = makeStyles()((theme) => ({
    paper: {
        minWidth: '90vw',
        padding: 40,
    },
    // tableContainer: {
    //     height: 280,
    //     width: '100%',
    // },
    dialogAction: {
        display: 'flex',
    },
    dialogContent: {
        minHeight: 500,
    },
}));

export const ClientRequirementsBulkUpload = ({ disabled, userDetails }) => {
    const { classes } = useStyles();

    const [rows, setRows] = useState([
        // {
        //     id: 0,
        //     title: 'Imported: Corporate Communication',
        //     description:
        //         'This course explores effective communication strategies within the corporate environment. Topics include written and verbal communication, interpersonal skills, and delivering presentations. Students will learn how to convey information clearly and professionally. In addition, they will develop the skills to adapt their communication style to different audiences and utilize effective communication tools and technologies. Strong emphasis will be placed on building and maintaining strong professional relationships to enhance collaboration and teamwork. Through interactive exercises and practical examples, students will gain confidence in their verbal and written communication abilities, enabling them to excel in their professional roles.',
        //     problemStatement:
        //         'Develop persuasive communication skills; Enhance interpersonal skills; Deliver effective presentations; Build strong professional relationships; Adapt communication style to different audiences; Utilize effective communication tools and technologies',
        //     topics: [
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: 'Written communication',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' Verbal communication',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' interpersonal skills',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' Presentation skills',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' professional relationships',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' communication style',
        //         },
        //     ],
        // },
        // {
        //     id: 1,
        //     title: 'Imported: Cross-functional Collaboration',
        //     description:
        //         'This course focuses on the principles and strategies for collaborating effectively with colleagues from different functional areas. Students will learn to overcome challenges, foster teamwork, and leverage diverse perspectives to achieve common goals. The course covers various techniques to improve communication and information sharing across departments and develop strategies for managing conflict and resolving disputes. Through case studies and group exercises, students will enhance their problem-solving skills and learn how to make informed decisions through collaborative efforts. The course also emphasizes the importance of building a culture of trust and cooperation among team members. By the end of the course, students will be equipped with the necessary skills to collaborate successfully with colleagues from different backgrounds and functional areas, leading to enhanced productivity and overall organizational success.',
        //     problemStatement:
        //         'Foster collaboration and teamwork; Overcome challenges in cross-functional collaboration; Leverage diverse perspectives to Enhance decision making; Improve communication and information sharing across departments; Develop strategies for managing conflict and resolving disputes; Enhance problem-solving skills through collaborative efforts',
        //     topics: [
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: 'teamwork',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' collaboration strategies',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' cross-functional collaboration',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' decision making',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' communication',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' conflict management',
        //         },
        //     ],
        // },
    ]);
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const [importing, setImporting] = useState(false);
    const isLargeScreen = useMediaQuery('(min-width:900px)');
    const [pinnedColumns, setPinnedColumns] = useState({
        left: isLargeScreen
            ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'title']
            : [],
    });
    const renderToolTipCell = ({ value }) => {
        return (
            <Tooltip placement="top-start" title={value}>
                <Typography variant="body2">{value}</Typography>
            </Tooltip>
        );
    };

    const renderArrayCell = (field) => ({ row }) => {
        let joined = '';
        if (field.includes('.')) {
            const [rowField, objectField] = field.split('.');
            joined = row[rowField]
                ?.map((item) => item[objectField])
                ?.join(', ');
        } else joined = row[field]?.join(', ');
        return (
            <Tooltip placement="top-start" title={joined}>
                <Typography variant="body2">{joined}</Typography>
            </Tooltip>
        );
    };

    const gridCols = [
        // {
        //     field: 'slNo',
        //     headerName: '#',
        //     width: 250,
        //     editable: false,
        // },
        {
            field: 'title',
            headerName: 'Course Title',
            width: 250,
            editable: false,
            // valueFormatter: (value, row) => {
            //     return starweaverAdmin ? value : row._name;
            // },
            renderCell: renderToolTipCell,
        },
        // {
        //     field: 'description',
        //     headerName: 'Course Description',
        //     width: 250,
        //     editable: false,
        // },
        {
            field: 'outlineFormat',
            headerName: 'Outline Format',
            width: 100,
            editable: false,
        },
        {
            field: 'shortDescription',
            headerName: 'Short Description',
            width: 250,
            editable: false,
        },
        {
            field: 'toolsRequired',
            headerName: 'Tools Required',
            width: 250,
            editable: false,
        },
        {
            field: 'level',
            headerName: 'Level',
            width: 50,
            editable: false,
        },
        {
            field: 'problemStatement',
            headerName: 'Learning Objectives',
            width: 250,
            editable: false,
        },

        {
            field: 'topics',
            headerName: 'Keywords/Topics',
            width: 250,
            editable: false,
            // valueFormatter: (value, row) => {
            //     return value?.map?.((k) => k?.name) /* .join(', ') */;
            // },
            renderCell: renderArrayCell('topics.name'),
        },
        {
            field: 'tags',
            headerName: 'Tags',
            width: 250,
            editable: false,
            // valueFormatter: (value, row) => {
            //     return value?.map?.join(', ');
            // },
            renderCell: renderArrayCell('tags'),
        },

        // {
        //     field: 'clientId',
        //     headerName: 'Client Id',
        //     width: 250,
        //     editable: false,

        //     renderCell: renderToolTipCell,
        // },

        {
            field: 'client',
            headerName: 'Client',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        {
            field: 'domain',
            headerName: 'Domain',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        {
            field: 'hours',
            headerName: 'Duration(Hrs)',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        {
            field: 'expectedEndDate',
            headerName: 'Delivery Date',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        {
            field: 'template',
            headerName: 'Template',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        {
            field: 'instructorBudget',
            headerName: 'Terms',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        // {
        //     field: 'isOnline',
        //     headerName: 'Is Online',
        //     width: 250,
        //     editable: false,

        //     renderCell: renderToolTipCell,
        // },
        {
            field: 'contractNumber',
            headerName: 'Contract Number',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        },
        /* {
            field: 'workOrders',
            headerName: 'Work Order',
            width: 250,
            editable: false,

            renderCell: renderToolTipCell,
        }, */
        // {
        //     field: 'minNumOfStudents',
        //     headerName: 'Number of students',
        //     width: 250,
        //     editable: false,

        //     renderCell: renderToolTipCell,
        // },

        // {
        //     field: 'percentHavingNoExperience',
        //     headerName: '% no experience',
        //     width: 250,
        //     editable: false,

        //     renderCell: renderToolTipCell,
        // },
        // {
        //     field: 'percentHavingZeroToOneExperience',
        //     headerName: '% having zero to one year',
        //     width: 250,
        //     editable: false,

        //     renderCell: renderToolTipCell,
        // },
        // {
        //     field: 'percentHavingOneToThreeExperience',
        //     headerName: '% having one to three year',
        //     width: 250,
        //     editable: false,

        //     renderCell: renderToolTipCell,
        // },
        // {
        //     field: 'totalBudget',
        //     headerName: 'Budget',
        //     width: 250,
        //     editable: false,

        //     renderCell: renderToolTipCell,
        // },
    ];

    function extractNumberFromString(str) {
        const regex = /^\d+/;
        const match = str.match(regex);
        return match ? parseInt(match[0]) : null;
    }

    const extractHoursFromString = (hourString) => {
        if (!hourString || typeof hourString !== 'string') return 1; // Default to 1 hour if invalid

        // Clean up the string
        const cleaned = hourString.trim().toLowerCase();

        // Handle "5+ hours" format
        if (cleaned.includes('+')) {
            const match = cleaned.match(/(\d+)\+/);
            return match ? parseInt(match[1], 10) : 1;
        }

        // Handle "2-3 hours" format
        if (cleaned.includes('-')) {
            const match = cleaned.match(/(\d+)-(\d+)/);
            if (match && match[2]) {
                // Return the upper value of the range
                return parseInt(match[2], 10);
            }
        }

        // Handle simple "4 hours" format
        const match = cleaned.match(/(\d+)/);
        return match ? parseInt(match[1], 10) : 1;
    };

    const cleanTitle = (title) => {
        if (!title) return { title: '', level: '' };

        // Remove numbering pattern like "1. " or "2. " at the beginning of the title
        let cleanedTitle = title.replace(/^\d+\.\s*/, '');

        // Extract level pattern (Core/Intermediate/Advanced Course)
        let level = '';

        // Match level patterns
        const levelMatch = cleanedTitle.match(
            /^(Core|Intermediate|Advanced)\s+Course:\s*/i
        );

        if (levelMatch) {
            level = levelMatch[1]; // Captures "Core", "Intermediate", or "Advanced"
            // Remove the level part from the title
            cleanedTitle = cleanedTitle.replace(levelMatch[0], '');
        }

        return { title: cleanedTitle, level };
    };

    const getData = (fileData = []) => {
        console.log(fileData);
        const headerIndex = 2;
        const titleIndex = fileData[headerIndex].indexOf('Title');
        // const descriptionIndex = fileData[headerIndex].indexOf('Description');

        const shortDescriptionIndex = fileData[headerIndex].indexOf(
            'Description'
        );

        const toolsRequiredIndex = fileData[headerIndex].indexOf(
            'Tools Proposed'
        );

        const levelIndex = fileData[headerIndex].indexOf('Difficulty Level');

        const problemStatementIndex = fileData[headerIndex].indexOf(
            'Learning Objectives'
        );
        console.log(problemStatementIndex);
        const keywordsIndex = fileData[headerIndex].indexOf('Tags');

        // const businessLineIndex = fileData[headerIndex].indexOf('Content Type');
        const tagsIndex = fileData[headerIndex].indexOf('Tags');
        // const listTypeIndex = fileData[headerIndex].indexOf('List Type');
        // const subDomainIndex = fileData[headerIndex].indexOf('Sub-Domain');
        // const recommentedContentTypeIndex = fileData[headerIndex].indexOf(
        //     'Recommended Content Type'
        // );

        const clientIndex = fileData[headerIndex].indexOf('Client');

        const dateIndex = fileData[headerIndex].indexOf('Date');

        const domainIndex = fileData[headerIndex].indexOf('Domain ');

        const subDomainIndex = fileData[headerIndex].indexOf('Subdomain');
        // const statusIndex = fileData[headerIndex].indexOf('Status  ↑');
        const durationIndex = fileData[headerIndex].indexOf(
            'best_course_length'
        );
        // const formatIndex = fileData[headerIndex].indexOf('Format');
        const contractIndex = fileData[headerIndex].indexOf('Contract Number');

        // const workOrdersIndex = fileData[headerIndex].indexOf('Work Order');
        // const numOfStudentsIndex = fileData[headerIndex].indexOf(
        //     'Number of Students'
        // );
        // const expOfStudentsIndex = fileData[headerIndex].indexOf(
        //     'Experience of Students (None, 0-1, 1-3)'
        // );

        // const budgeIndex = fileData[headerIndex].indexOf('Budget');

        const templateIndex = fileData[headerIndex].indexOf(
            'Logistics Template'
        );

        const instructorBudgetIndex = fileData[headerIndex].indexOf(
            'Terms & Conditions'
        );

        const read = [];
        fileData.slice(headerIndex + 1).forEach((r, index) => {
            // console.log(r);
            if (r.length > 1) {
                if (!r[titleIndex]) return;

                const {
                    title: cleanedTitle,
                    level: extractedLevel,
                } = cleanTitle(r[titleIndex]);
                const title = cleanedTitle;
                // ...
                const level = extractedLevel || r[levelIndex];
                // const topics =
                //     r[keywordsIndex]?.split(',')?.map((k) => ({
                //         id: 99999,
                //         term_id: k?.trim(),
                //         name: k?.trim(),
                //     })) || [];

                const topics =
                    r[keywordsIndex]
                        ?.split(/;|,|\sand\s/)
                        ?.map((k) => k?.trim()) // Trim each item
                        ?.filter(Boolean) // Remove falsey values
                        ?.map((k) => ({
                            id: 99999,
                            term_id: k,
                            name: k,
                        })) || [];

                const contractNumber =
                    'SWO 2Q25 Cybersecurity' || r[contractIndex];

                const shortDescription = r[shortDescriptionIndex];
                const toolsRequired = r[toolsRequiredIndex];
                // const level = r[levelIndex];
                const problemStatement = r[problemStatementIndex];

                const tags = [
                    // r[contractIndex],
                    // r[businessLineIndex],
                    // r[tagsIndex],
                    // r[listTypeIndex],
                    // r[subDomainIndex],
                    // r[recommentedContentTypeIndex],
                ];
                // const clientId =
                //     r[clientIndex] === 'Coursera' || true
                //         ? process.env.REACT_APP_ENVIRONMENT === 'production' ||
                //           true
                //             ? '6462297aecb0d896ae949f31'
                //             : '647f3b681de176891ca82501'
                //         : null;

                const client = 'Starweaver' || r[clientIndex];

                const status = /* r[statusIndex] === 'Pending' ? OPEN : */ DRAFT;
                const domain = r[domainIndex]; /* + ', ' + r[subDomainIndex] */
                // const hours = extractNumberFromString(r[durationIndex]);
                // const expectedEndDate = r[dateIndex];

                // Convert the Excel numeric date to a JavaScript date
                // const expectedEndDate = moment
                //     .utc('1900-01-01')
                //     .add(Math.floor(r[dateIndex] - 2), 'days')
                //     .toISOString();

                const expectedEndDate = moment().add(3, 'month').toISOString();
                //  moment
                //     .utc(r[dateIndex], 'DD/MMM/YYYY')
                //     .toISOString();

                const isOnline = /* r[formatIndex] === 'Online' */ true;

                // const workOrders = r[workOrdersIndex];

                const comamaDollarRegex = /,|\$/g;

                const template =
                    'Starweaver Originals (Medium Form 4 hrs)' ||
                    r[templateIndex];
                const instructorBudget = r[instructorBudgetIndex];

                // console.log(r[numOfStudentsIndex]);
                // const minNumOfStudents =
                //     r[
                //         numOfStudentsIndex
                //     ]; /* ?.replace(
                //     comamaDollarRegex,
                //     ''
                // ) */

                // const [
                //     percentHavingNoExperience,
                //     percentHavingZeroToOneExperience,
                //     percentHavingOneToThreeExperience,
                // ] = r[expOfStudentsIndex]?.split(',');

                // const totalBudget = 4900;
                //     r[
                //         budgeIndex
                //     ]; /* ?.replace(
                //     comamaDollarRegex,
                //     ''
                // ) */

                const rObject = {
                    id: index,
                    title,
                    topics,
                    // outlineFormat: 'CS Batch 10',
                    shortDescription,
                    // toolsRequired,
                    level,
                    problemStatement,
                    // tags,
                    // clientId,
                    client,
                    status,
                    domain,
                    hours: extractHoursFromString(r[durationIndex]),
                    minutes: 0,
                    expectedEndDate: expectedEndDate,

                    isOnline,
                    contractNumber,
                    template,
                    // template,
                    // instructorBudget,
                    // workOrders,
                    // minNumOfStudents,
                    // percentHavingNoExperience: !isNaN(percentHavingNoExperience)
                    //     ? percentHavingNoExperience
                    //     : 0,
                    // percentHavingZeroToOneExperience: !isNaN(
                    //     percentHavingZeroToOneExperience
                    // )
                    //     ? percentHavingZeroToOneExperience
                    //     : 0,
                    // percentHavingOneToThreeExperience: !isNaN(
                    //     percentHavingOneToThreeExperience
                    // )
                    //     ? percentHavingOneToThreeExperience
                    //     : 0,
                    // totalBudget,
                    type: ONDEMAND,
                };
                read.push(rObject);
            }
        });
        console.log(read /* .slice(0, 2) */);

        // const filteredRead = read.reduce((acc, course) => {
        //     if (acc.length >= 2) return acc;

        //     if (
        //         course.client === 'Go1' &&
        //         !acc.some((c) => c.client === 'Go1')
        //     ) {
        //         acc.push(course);
        //     } else if (
        //         course.client === 'FutureLearn' &&
        //         !acc.some((c) => c.client === 'FutureLearn')
        //     ) {
        //         acc.push(course);
        //     }

        //     return acc;
        // }, []);

        // return filteredRead;

        return read /* .slice(0, 2) */;
    };

    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            const fileName = selectedFile.name;
            const extension = fileName.substr(fileName.lastIndexOf('.') + 1);

            if (extension === 'xlsx') {
                try {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        const data = event.target.result;
                        const readData = XLSX.read(data, { type: 'binary' });
                        const workSheetName = readData.SheetNames[0];
                        const ws = readData.Sheets[workSheetName];
                        /* Convert array to json*/
                        const dataParse = XLSX.utils.sheet_to_json(ws, {
                            header: 1,
                        });
                        console.log(dataParse);
                        const requirements = getData(dataParse);
                        if (requirements.length) {
                            // console.log(requirements);
                            setRows(requirements /* .slice(0, 2) */);
                            setOpenPreviewDialog(true);
                        }
                    };
                    reader.readAsBinaryString(selectedFile);
                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log('Please use correct format');
            }
        }
    };

    const handleDialogClose = () => {
        setOpenPreviewDialog(false);
    };

    const importRequirements = async () => {
        try {
            setImporting(true);

            await axios.post(
                `/api/client-requirements/import?userId=${userDetails._id}`,
                rows
            );

            setImporting(false);
            setOpenPreviewDialog(false);

            window.open(`/admin/opportunity-management`, '_self');
        } catch (e) {
            console.log(e);
            setImporting(false);
        }
    };

    return (
        <>
            <label for="ClientRequirementsBulkUpload">
                <Tooltip
                // title={
                //     'Kindly choose an XLSX file that includes columns denoted as "Course Title", "Content Type", "Domain", "Key Words", "Duration", "Due Date", "Template", "Contract Number", "Terms", "Tags".'
                // }
                >
                    <Box style={{ cursor: 'pointer' }}>Starweaver Orignals</Box>
                </Tooltip>
            </label>
            {!disabled && (
                <input
                    type="file"
                    id="ClientRequirementsBulkUpload"
                    hidden
                    onChange={handleFile}
                />
            )}

            <Box>
                <Dialog
                    onClose={handleDialogClose}
                    aria-labelledby="matching-instructor-data-modal"
                    open={openPreviewDialog}
                    fullWidth
                    classes={{ paper: classes.paper }}
                >
                    <Box display="flex">
                        <Typography
                            variant="h6"
                            style={{ marginRight: 'auto' }}
                        >
                            Preview
                            {/* : {rows.map((r) => r.title).join(', ')} */}
                        </Typography>

                        <IconButton
                            aria-label="close"
                            onClick={handleDialogClose}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <>
                        <DialogContent className={classes.dialogContent}>
                            <DataGridPremium
                                autoHeight
                                rows={rows}
                                columns={gridCols}
                                pageSizeOptions={[]}
                                pinnedColumns={pinnedColumns}
                                onPinnedColumnsChange={(
                                    updatedPinnedColumns
                                ) => {
                                    if (isLargeScreen) {
                                        setPinnedColumns(updatedPinnedColumns);
                                    } else {
                                        alert(
                                            'Pinning is not available on small screens.'
                                        );
                                    }
                                }}
                                hideFooter={true}
                                hideFooterSelectedRowCount={true}
                                // disableColumnMenu={true}
                                disableColumnFilter={true}
                                disableColumnSelector={true}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Box
                                className={classes.dialogAction}
                                style={{ width: '100%' }}
                            >
                                <Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleDialogClose}
                                    >
                                        Cancel
                                    </Button>
                                </Box>

                                <Box marginLeft="auto">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={importRequirements}
                                    >
                                        {importing ? (
                                            <CircularProgress size="1.9em" />
                                        ) : (
                                            <>Import</>
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                        </DialogActions>
                    </>
                </Dialog>
            </Box>
        </>
    );
};
