import React, { useState } from 'react';

import { ListSearchAndSelect } from '../ui';

import { useTags } from './useTags';

export const TagsSearchAndSelect = ({
    title,
    subTitle,
    selectedTags,
    setSelectedTags,
    addedSearchTerms,
    setAddedSearchTerms,
    skillErr,
    setSkillErr,
    resetError = undefined,
    readOnly = false,
    CustomComponent = null,
    isVertical = false,
    selectedContainerText = '',
}) => {
    const [tagsSearchQuery, setTagsSearchQuery] = useState('');
    const [tagsPageNumber, setTagsPageNumber] = useState(1);

    const {
        results: tags,
        hasMore: hasMoreTags,
        loading: tagsLoading,
        error,
    } = useTags(tagsSearchQuery, tagsPageNumber, 9);

    // const handleTagSelect = (item) => {
    //     //console.log(item);
    //     setSelectedTags((prevList) => {
    //         if (
    //             prevList.findIndex((elem) => elem.term_id === item.term_id) ===
    //             -1
    //         ) {
    //             return [item, ...prevList];
    //         } else {
    //             return prevList;
    //         }
    //     });
    // };

    // const handleTagDelete = (itemToDelete) => {
    //     console.log(itemToDelete);
    //     setSelectedTags((items) =>
    //         items.filter((item) => item.term_id !== itemToDelete.term_id)
    //     );
    // };

    return (
        <ListSearchAndSelect
            heading={title || 'Tags'}
            subHeading={subTitle}
            list={tags}
            selectedList={selectedTags}
            setSelectedList={setSelectedTags}
            // handleItemSelect={handleTagSelect}
            // handleItemDelete={(item) => {
            //     handleTagDelete(item);
            // }}
            idField="term_id"
            paginationMode="server"
            search={tagsSearchQuery}
            setSearch={setTagsSearchQuery}
            setPageNumber={setTagsPageNumber}
            loading={tagsLoading}
            hasMore={hasMoreTags}
            canAddSearchTerm
            addedSearchTerms={addedSearchTerms}
            setAddedSearchTerms={setAddedSearchTerms}
            skillErr={skillErr}
            setSkillErr={setSkillErr}
            resetError={resetError}
            readOnly={readOnly}
            CustomComponent={
                CustomComponent !== null ? <CustomComponent /> : null
            }
            isVertical={isVertical}
            selectedContainerText={selectedContainerText}
        />
    );
};
