import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FlareIcon from '@mui/icons-material/Flare';
import GridOnIcon from '@mui/icons-material/GridOn';
import FaceIcon from '@mui/icons-material/Face';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DnsIcon from '@mui/icons-material/Dns';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    menuItem: {
        cursor: 'pointer',
    },
}));

export const ProfileMenu = ({
    selectedProfileMenuNum,
    setSelectedProfileMenuNum,
}) => {
    const { classes } = useStyles();
    return (
        <>
            <List component="nav" aria-label="main mailbox folders">
                <ListItem
                    onClick={() => {
                        setSelectedProfileMenuNum(0);
                    }}
                    className={classes.menuItem}
                >
                    <ListItemIcon>
                        <FlareIcon
                            style={{
                                borderRadius: '50%',
                                color:
                                    selectedProfileMenuNum === 0
                                        ? 'black'
                                        : 'rgba(0, 0, 0, 0.54)',
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="About Me"
                        style={{
                            color:
                                selectedProfileMenuNum === 0
                                    ? 'black'
                                    : 'rgba(0, 0, 0, 0.87)',
                        }}
                    />
                </ListItem>
                <ListItem
                    onClick={() => {
                        setSelectedProfileMenuNum(1);
                    }}
                    className={classes.menuItem}
                >
                    <ListItemIcon>
                        <GridOnIcon
                            style={{
                                borderRadius: '50%',
                                color:
                                    selectedProfileMenuNum === 1
                                        ? 'black'
                                        : 'rgba(0, 0, 0, 0.54)',
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="Basic"
                        style={{
                            color:
                                selectedProfileMenuNum === 1
                                    ? 'black'
                                    : 'rgba(0, 0, 0, 0.87)',
                        }}
                    />
                </ListItem>
                {/* <ListItem
                    onClick={() => {
                        setSelectedProfileMenuNum(2);
                    }}
                    className={classes.menuItem}
                >
                    <ListItemIcon>
                        <AssessmentIcon
                            style={{
                                borderRadius: '50%',
                                color:
                                    selectedProfileMenuNum === 2
                                        ? 'black'
                                        : 'rgba(0, 0, 0, 0.54)',
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="Education"
                        style={{
                            color:
                                selectedProfileMenuNum === 2
                                    ? 'black'
                                    : 'rgba(0, 0, 0, 0.87)',
                        }}
                    />
                </ListItem> */}
                {/* <ListItem
                    onClick={() => {
                        setSelectedProfileMenuNum(3);
                    }}
                    className={classes.menuItem}
                >
                    <ListItemIcon>
                        <DnsIcon
                            style={{
                                borderRadius: '50%',
                                color:
                                    selectedProfileMenuNum === 3
                                        ? 'black'
                                        : 'rgba(0, 0, 0, 0.54)',
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="Work Experience"
                        style={{
                            color:
                                selectedProfileMenuNum === 3
                                    ? 'black'
                                    : 'rgba(0, 0, 0, 0.87)',
                        }}
                    />
                </ListItem> */}
                <ListItem
                    onClick={() => {
                        setSelectedProfileMenuNum(4);
                    }}
                    className={classes.menuItem}
                >
                    <ListItemIcon>
                        <FaceIcon
                            style={{
                                borderRadius: '50%',
                                color:
                                    selectedProfileMenuNum === 4
                                        ? 'black'
                                        : 'rgba(0, 0, 0, 0.54)',
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="Expertise"
                        style={{
                            color:
                                selectedProfileMenuNum === 4
                                    ? 'black'
                                    : 'rgba(0, 0, 0, 0.87)',
                        }}
                    />
                </ListItem>

                {/* <ListItem
                    onClick={() => {
                        setSelectedProfileMenuNum(5);
                    }}
                >
                    <ListItemIcon>
                        <HowToRegIcon
                            style={{
                                borderRadius: '50%',
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Teaching Experience" />
                </ListItem> */}
                {/* <ListItem
                    onClick={() => {
                        setSelectedProfileMenuNum(5);
                    }}
                    className={classes.menuItem}
                >
                    <ListItemIcon>
                        <HowToRegIcon
                            style={{
                                borderRadius: '50%',
                                color:
                                    selectedProfileMenuNum === 5
                                        ? 'black'
                                        : 'rgba(0, 0, 0, 0.54)',
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="Course(s) that I Teach"
                        style={{
                            color:
                                selectedProfileMenuNum === 5
                                    ? 'black'
                                    : 'rgba(0, 0, 0, 0.87)',
                        }}
                    />
                </ListItem>

                <ListItem
                    onClick={() => {
                        setSelectedProfileMenuNum(6);
                    }}
                    className={classes.menuItem}
                >
                    <ListItemIcon>
                        <DoubleArrowIcon
                            style={{
                                borderRadius: '50%',
                                color:
                                    selectedProfileMenuNum === 6
                                        ? 'black'
                                        : 'rgba(0, 0, 0, 0.54)',
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="Master Services Agreement"
                        style={{
                            color:
                                selectedProfileMenuNum === 6
                                    ? 'black'
                                    : 'rgba(0, 0, 0, 0.87)',
                        }}
                    />
                </ListItem> */}
                <ListItem
                    onClick={() => {
                        setSelectedProfileMenuNum(7);
                    }}
                    className={classes.menuItem}
                >
                    <ListItemIcon>
                        <EmojiPeopleIcon
                            style={{
                                borderRadius: '50%',
                                color:
                                    selectedProfileMenuNum === 7
                                        ? 'black'
                                        : 'rgba(0, 0, 0, 0.54)',
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="Services"
                        style={{
                            color:
                                selectedProfileMenuNum === 7
                                    ? 'black'
                                    : 'rgba(0, 0, 0, 0.87)',
                        }}
                    />
                </ListItem>
            </List>
        </>
    );
};

export default ProfileMenu;
