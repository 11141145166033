import { Navigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress, Loading } from '../ui';
import { useIsAdmin } from '../admin/useIsAdmin';

export const AdminRoute = ({ children }) => {
    const { pathname, search } = useLocation();

    const { isLoading, isAdmin, user, isUserLoading } = useIsAdmin();

    if (isLoading || isUserLoading) return <Loading />;

    if (!user)
        return (
            <Navigate
                to={`/login?dest=${encodeURI(pathname + search)}`}
                replace
            />
        );

    return isAdmin ? (
        <>
            {children}
            {/* <Box fontSize={50}>Admin</Box> */}
        </>
    ) : (
        <>
            <Navigate to="/login" replace />
            {/* <Box fontSize={50}>Login</Box> */}
        </>
    );
};

export default AdminRoute;
