import { atom, selector } from 'recoil';

export const isCreditCardRequiredState = atom({
    key: 'isCreditCardRequiredState',
    default: true,
});

export const creditsPlansState = atom({
    key: 'creditsPlansState',
    default: null,
});

export const firstNameState = atom({
    key: 'firstNameState',
    default: '',
});
export const lastNameState = atom({
    key: 'lastNameState',
    default: '',
});
export const dateOfBirthState = atom({
    key: 'dateOfBirthState',
    default: '',
});
export const emailState = atom({
    key: 'emailState',
    default: '',
});
export const genderState = atom({
    key: 'genderState',
    default: '',
});
export const passwordState = atom({
    key: 'passwordState',
    default: '',
});
export const confirmPasswordState = atom({
    key: 'confirmPasswordState',
    default: '',
});
export const phoneState = atom({
    key: 'phoneState',
    default: '',
});
export const countryState = atom({
    key: 'countryState',
    default: '',
});

export const redirectDestinationState = atom({
    key: 'redirectDestinationState',
    default: '',
});

export const trialEndPopUpOpenState = atom({
    key: 'trialEndPopUpOpenState',
    default: false,
});

export const freeCreditsPopUpOpenState = atom({
    key: 'freeCreditsPopUpOpenState',
    default: false,
});

export const offerPopUpPermanentClosedState = atom({
    key: 'offerPopUpPermanentClosedState',
    default: false,
});

export const offerTimeoutTimeState = atom({
    key: 'offerTimeoutTimeState',
    default: 5000,
});

export const saveRedirectDestination = selector({
    key: 'saveRedirectDestination',
    set: ({ set }, destination) => {
        set(redirectDestinationState, destination);
    },
});

export const getUserInfo = selector({
    key: 'getUserInfo',
    get: ({ get }) => {
        return {
            user_firstName: get(firstNameState),
            user_lastName: get(lastNameState),
            user_email: get(emailState),
            user_dob: get(dateOfBirthState),
            user_gender: get(genderState),
            password: get(passwordState),
            user_country: get(countryState),
            confirmPassword: get(confirmPasswordState),
        };
    },
});
