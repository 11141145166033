import { Box, Typography } from '../ui/index';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';

export function BusinessContainer() {
    const { classes } = useStyles();
    return (
        <Box className={classes.root}>
            <Typography className={classes.textHead} variant="subtitle1">
                FOR ORGANIZATIONS
            </Typography>
            <Typography className={classes.textStyle} variant="subtitle1">
                {/* <a
                    // href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/free-enterprise-trial`}
                    href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/support/`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Starweaver for Business
                </a> */}
                <Link to="/support">Starweaver for Business</Link>
            </Typography>
            <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    // href="https://go.starweaver.com/for-campus/students"
                    href="/for-campus/students"
                    // target="_blank"
                    rel="noreferrer"
                >
                    Starweaver for Campus
                </a>
            </Typography>
            <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    // href="https://go.starweaver.com/browse"
                    href="/browse"
                    //target="_blank"
                    rel="noreferrer"
                >
                    Catalogue{' '}
                </a>
            </Typography>
            {/* <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/free-enterprise-expert-marketplace/`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Expert Marketplace{' '}
                </a>
            </Typography> */}
            {/* <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/free-enterprise-trial-success-stories/`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Many Success Stories
                </a>
            </Typography> */}
            <Typography className={classes.textStyle} variant="subtitle1">
                <a href="/pricing" rel="noreferrer">
                    Pricing
                </a>
            </Typography>
            <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    // href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/company/private-class-enquiry/`}
                    // href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/support/`}
                    href="/support"
                    // target="_blank"
                    rel="noreferrer"
                >
                    Private Classes
                </a>
            </Typography>
        </Box>
    );
}
