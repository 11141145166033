import { Fab } from '../ui';
import { ArrowBackIcon } from '../icons';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';

export const BackFloatButton = () => {
    const goBack = () => {
        navigate('/profile');
    };
    let navigate = useNavigate();
    const { classes } = useStyles();
    return (
        <Fab
            variant="extended"
            size="small"
            color="secondary"
            className={classes.fab}
            onClick={goBack}
        >
            <ArrowBackIcon />
            {/* <AccountCircleIcon /> */}
            Back to Account
        </Fab>
    );
};
