import { Box, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { addTaskData } from '../../slices/requirementSummarySlice';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../../app/recoil';
import { useMemo } from 'react';

const COMPLETED = 'COMPLETED';

function RequirementSumamryTaskStatusField({ value, ...props }) {
    const dispatch = useDispatch();
    const requirementSummary = useSelector((state) => state.requirementSummary);
    const [isLoading, setIsLoading] = useState(false);
    const setAlert = useSetRecoilState(alertState);

    const tooltipText = useMemo(() => {
        if (requirementSummary.taskData?.isDone) return 'Completed';
        return 'Incomplete';
    }, [requirementSummary.taskData?.isDone]);

    const showAlert = () => {
        setAlert({
            show: true,
            message: 'Successfully updated task status',
            severity: severity.SUCCESS,
        });
    };

    const updateTaskStatus = async (checked) => {
        const payload = {
            taskId: requirementSummary.taskData._id,
            requirementId: requirementSummary.requirementData.requirementId,
            status: checked ? COMPLETED : null,
        };
        await axios.post('/api/client-requirements/set-task-status', payload);
        showAlert();
    };

    const updateTaskData = (checked) => {
        dispatch(
            addTaskData({
                ...requirementSummary.taskData,
                isDone: checked,
            })
        );
    };

    const handleChange = async (e) => {
        try {
            setIsLoading(true);
            const checked = e.target.checked;
            await updateTaskStatus(checked);
            updateTaskData(checked);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box display={'flex'} alignItems={'center'} {...props}>
            <Tooltip title={tooltipText}>
                <input
                    type="checkbox"
                    name="task status"
                    checked={value}
                    onChange={handleChange}
                    disabled={isLoading}
                    style={{ padding: 0, margin: 0 }}
                />
            </Tooltip>
        </Box>
    );
}

export default RequirementSumamryTaskStatusField;
