import React, { useState, useEffect, useMemo, Suspense } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import pathMatch from 'path-match';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useUser } from '../auth';
import * as routeDefinitions from './route-definitions';
// import * as routeDefinitions from './route-definitions/landingpage';
import ScrollToTop from '../app/ScrollToTop';
import { NavBar } from './NavBar';
import { SideNav } from './SideNav';
// import { PrivateRoute } from './PrivateRoute';
// import { AdminRoute } from './AdminRoute';
// import { PrivateInstructorRoute } from './PrivateInstructorRoute';
import { FooterMain } from '../footer/FooterMain';
import Chat from '../chat';
import { LoginModal } from '../auth';
import { PopUpMessage } from '../pop-up-message';
import { NotFound } from '../not-found';
import { CookiePopUp } from '../util/cookiePopUp';
import { MobileOtpVerificationDialog } from '../verify-mobile-number/MobileOtpVerificationDialog';
import { TrialEndDialog } from '../billing/TrialEndDialog';
// import { FreeCreditsDialog } from '../billing/FreeCreditsDialog';
// import { RouteTypeProvider } from './context';
import { PrivateInstructorRoute } from './PrivateInstructorRoute';
import AdminRoute from './AdminRoute';
import PrivateRoute from './PrivateRoute';
import { PrivateRoleRoute } from './PrivateRoleRoute';
import PublicRoute from './PublicRoute';
import { Loading } from '../ui';
import { SwitchDomain } from './SwitchDomain';
import { PrivateRouteV2 } from './PrivateRouteV2';
import { useRecoilState } from 'recoil';
import { roleViews, roleViewState } from './recoil/roleView';
import { useCurrentUser } from '../my-account/useCurrentUser';

// const NavBar = React.lazy(() => import('./NavBar'));
// const SideNav = React.lazy(() => import('./SideNav'));
// const PrivateRoute = React.lazy(() => import('./PrivateRoute'));
// const AdminRoute = React.lazy(() => import('./AdminRoute'));
// const PrivateInstructorRoute = React.lazy(() =>
//     import('./PrivateInstructorRoute')
// );
// const FooterMain = React.lazy(() => import('../footer/FooterMain'));

let routes = [...Object.values(routeDefinitions)];

let loginModalTimeoutID;
const LOGIN_MODAL_TIMEOUT_INTERVAL = 7 * 60 * 1000;

const route = pathMatch({
    sensitive: false,
    strict: false,
    end: false,
});

const noDistractionPaths = [
    '/on-air',
    '/login',
    '/signup',
    '/checkout',
    '/credits/checkout',
    '/checkout/canceled',
    '/instructor-login',
    '/instructor-signup',
    '/reset-password',
    '/set-password',
    '/verify-mobile',
    '/first-time',
    '/pricing',
    '/pricing/compare',
    '/pricing/teams/compare',

    '/profile',
    '/profile/security',
];

const noDistractionPaths2 = [
    '/on-air',
    '/login',
    '/signup',
    '/checkout',
    '/credits/checkout',
    '/checkout/canceled',
    '/instructor-login',
    '/instructor-signup',
    '/reset-password',
    '/set-password',

    '/first-time',
    '/pricing',
    '/pricing/compare',
    '/pricing/teams/compare',
    '/instructor-onboarding',
    '/profile/instructor-onboarding',
    '/profile',
    '/profile/security',
];
const shouldOpenLoginModalForPath = (pathname) =>
    !noDistractionPaths.some((path) => {
        const matches = route(path);
        return matches(pathname);
    });

const useStyles = makeStyles()((theme) => ({
    contentContainer: {
        marginTop: 90,
    },
    moreMargin: {
        // for bottom toolbar
        marginTop: 90,
        // [theme.breakpoints.up('md')]: {
        //     marginTop: 135,
        // },
    },
    lessTopMargin: {
        marginTop: 0,
    },
    spaceForSideNav: {
        [theme.breakpoints.up('md')]: {
            marginLeft: 76,
        },
    },
}));

export const AllRoutes = ({
    showDrawer,
    toggleDrawer,
    mongoUser,
    socket,
    userFirstVisit,
    setUserFirstVisit,
}) => {
    const { classes } = useStyles();
    let navigate = useNavigate();
    const [{ selectedView }] = useRecoilState(roleViewState);
    const { isLoading: isUserLoading, user } = useUser();
    const { user: currentUser } = useCurrentUser();
    const { pathname } = useLocation();
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    // useGoogleOneTapLogin({
    //     onError: (error) => console.log(error),
    //     onSuccess: (response) => console.log(response),
    //     googleAccountConfigs: {
    //         client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
    //         ux_mode: 'popup',
    //     },
    // });

    useEffect(() => {
        if (!user && !isUserLoading && shouldOpenLoginModalForPath(pathname)) {
            loginModalTimeoutID = setTimeout(() => {
                // window helps to get the current page while the pathname holds the value on which the user was when this function rendered
                if (!noDistractionPaths.includes(window.location.pathname)) {
                    setIsLoginModalOpen(true);
                }
            }, LOGIN_MODAL_TIMEOUT_INTERVAL);
        }

        return () => {
            clearTimeout(loginModalTimeoutID);
        };
    }, [user, isUserLoading]);

    // const hasVisited = () => {
    //     setUserFirstVisit(true);
    // };

    useEffect(() => {
        if (mongoUser) {
            if (
                mongoUser &&
                (mongoUser?.user_phone === '' ||
                    mongoUser?.user_phone == 0 ||
                    mongoUser?.user_phone === '+' ||
                    !mongoUser?.user_phone) &&
                // !mongoUser?.roles?.length &&
                //!mongoUser?.client_id &&
                !/china|hong/i.test(mongoUser?.user_country || '') &&
                !noDistractionPaths.includes(pathname)
                // &&
                // process.env.REACT_APP_ENVIRONMENT === 'production'
            )
                navigate(`/verify-mobile?dest=${pathname}`);
        }
    }, [mongoUser, pathname]);

    useEffect(() => {
        if (mongoUser) {
            if (
                mongoUser &&
                mongoUser?.user_phone !== '' &&
                mongoUser?.user_phone !== 0 &&
                mongoUser?.user_phone !== '+' &&
                mongoUser?.user_phone &&
                !noDistractionPaths2.includes(pathname) &&
                mongoUser?.signupPath === '/instructor-signup' &&
                !mongoUser?.is_instructor &&
                !mongoUser.instructor_onboarding_status

                // &&
                // process.env.REACT_APP_ENVIRONMENT === 'production'
            )
                navigate('/instructor-onboarding');
        }
    }, [mongoUser, pathname]);

    useEffect(() => {
        if (window.location.pathname === '/browse/retro-learning') {
            navigate('/404', { replace: true });
        }
    }, [navigate]);

    const isLoginPage = useMemo(() => {
        if (window.location.pathname === '/login') return true;
        else return false;
    }, [window.location.pathname]);

    return (
        <Routes>
            {routes.map((route, i) => {
                if (!route.Component) return;

                if (
                    window.location.hostname ===
                        process.env.REACT_APP_JOURNEYBUILDER_HOSTNAME &&
                    !route.isJourneyBuilderPath
                )
                    return (
                        <Route
                            key={`route-${i}`}
                            path="*"
                            element={<SwitchDomain />}
                        />
                    );
                /* if (
                    !route.path?.includes(':') &&
                    !route.path?.includes(',') &&
                    typeof route.path === 'string'
                )
                    console.log(route.path); */
                const Element = (
                    <>
                        <Box
                            className={`${
                                route.hideSideNav || !user
                                    ? null
                                    : classes.spaceForSideNav
                            } ${
                                user
                                    ? classes.contentContainer
                                    : classes.moreMargin
                            } ${
                                route.hideNavBar ? classes.lessTopMargin : null
                            }`}
                            style={{
                                margin: isLoginPage && 0,
                                padding: isLoginPage && 0,
                            }}
                        >
                            <ScrollToTop />
                            {route.hideNavBar ? null : (
                                <NavBar
                                    onDrawerToggle={toggleDrawer}
                                    isInstructorRoute={
                                        route?.allowedRoleViews?.length > 0
                                            ? selectedView ===
                                                  roleViews.INSTRUCTOR &&
                                              currentUser?.is_instructor
                                            : route.isInstructorRoute
                                    }
                                    isAdminRoute={
                                        route?.allowedRoleViews?.length > 0
                                            ? selectedView ===
                                                  roleViews.ADMIN &&
                                              currentUser?.isAdmin
                                            : route.admin
                                    }
                                    // routeRoles={route.roles}
                                    routeRole={
                                        route?.allowedRoleViews?.length > 0
                                            ? selectedView ===
                                                  roleViews.COLLABORATOR &&
                                              currentUser?.roles?.includes(
                                                  roleViews.COLLABORATOR
                                              )
                                            : route.role
                                    }
                                    mongoUser={mongoUser}
                                    socket={socket}
                                    allowedRoleViews={route?.allowedRoleViews}
                                />
                            )}

                            {/* {!user && !isUserLoading && (
                <BottomToolbar
                    onToggleDrawer={toggleDrawer}
                    spaceForSideNav={
                        route.hideSideNav || !user ? 0 : 76
                    }
                />
            )} */}
                            {route.hideSideNav ? null : (
                                <SideNav
                                    showDrawer={showDrawer}
                                    onToggleDrawer={toggleDrawer}
                                    user={user}
                                    isAdminRoute={
                                        route?.allowedRoleViews?.length > 0
                                            ? selectedView ===
                                                  roleViews.ADMIN &&
                                              currentUser?.isAdmin
                                            : route.admin
                                    }
                                    isInstructorRoute={
                                        route?.allowedRoleViews?.length > 0
                                            ? selectedView ===
                                                  roleViews.INSTRUCTOR &&
                                              currentUser?.is_instructor
                                            : route.isInstructorRoute
                                    }
                                    routeRole={
                                        route?.allowedRoleViews?.length > 0
                                            ? selectedView ===
                                                  roleViews.COLLABORATOR &&
                                              currentUser?.roles?.includes(
                                                  roleViews.COLLABORATOR
                                              )
                                            : route.role
                                    }
                                />
                            )}
                            <Suspense fallback={<Loading />}>
                                {route.Component && (
                                    <route.Component
                                        isAdminRoute={
                                            route?.allowedRoleViews?.length > 0
                                                ? selectedView ===
                                                      roleViews.ADMIN &&
                                                  currentUser?.isAdmin
                                                : route.admin
                                        }
                                        isInstructorRoute={
                                            route?.allowedRoleViews?.length > 0
                                                ? selectedView ===
                                                      roleViews.INSTRUCTOR &&
                                                  currentUser?.is_instructor
                                                : route.isInstructorRoute
                                        }
                                        routeRole={
                                            route?.allowedRoleViews?.length > 0
                                                ? selectedView ===
                                                      roleViews.COLLABORATOR &&
                                                  currentUser?.roles?.includes(
                                                      roleViews.COLLABORATOR
                                                  )
                                                : route.role
                                        }
                                        socket={socket}
                                    />
                                )}
                            </Suspense>

                            {route.hideFooter ? null : <FooterMain />}
                        </Box>
                        {mongoUser &&
                        (mongoUser?.isAdmin || mongoUser?.is_instructor) ? (
                            <Chat socket={socket} docked />
                        ) : null}
                        <LoginModal
                            open={isLoginModalOpen}
                            onRequestClose={() => {
                                setIsLoginModalOpen(false);
                            }}
                        />
                        <PopUpMessage />
                        <CookiePopUp />

                        <TrialEndDialog />
                        {/* <FreeCreditsDialog /> */}
                        <MobileOtpVerificationDialog />
                        {/* <OfferPopup
            hasVisited={hasVisited}
            hasVisitedFirstTime={userFirstVisit}
        /> */}
                        {/* <DialogOfferPopup
            hasVisitedFirstTime={userFirstVisit}
        /> */}
                    </>
                );

                let RouteType = PrivateRoute;
                if (route?.allowedRoleViews?.length > 0)
                    RouteType = PrivateRouteV2;
                else if (route.isPublic) RouteType = PublicRoute;
                else if (route.isInstructorRoute)
                    RouteType = PrivateInstructorRoute;
                else if (route.admin) RouteType = AdminRoute;
                else if (route.roles?.length) RouteType = PrivateRoleRoute;

                const path = route.path;

                if (typeof route.path === 'string')
                    return (
                        <Route
                            key={`string-type-${i}`}
                            path={path}
                            // exact={!route.isNotExact}
                            // routeRoles={route.roles}
                            // routeRole={route.role}
                            element={
                                <RouteType
                                    routeRole={
                                        route?.allowedRoleViews?.length > 0
                                            ? selectedView ===
                                                  roleViews.COLLABORATOR &&
                                              currentUser?.roles?.includes(
                                                  roleViews.COLLABORATOR
                                              )
                                            : route.role
                                    }
                                    roleViews={route?.allowedRoleViews}
                                >
                                    {Element}
                                </RouteType>
                            }
                        />
                    );
                else if (Array.isArray(path))
                    return (
                        <>
                            {path.map((p) => (
                                <Route
                                    key={p}
                                    path={p}
                                    element={
                                        <RouteType
                                            routeRole={
                                                route?.allowedRoleViews
                                                    ?.length > 0
                                                    ? selectedView ===
                                                          roleViews.COLLABORATOR &&
                                                      currentUser?.roles?.includes(
                                                          roleViews.COLLABORATOR
                                                      )
                                                    : route.role
                                            }
                                            roleViews={route?.allowedRoleViews}
                                        >
                                            {Element}
                                        </RouteType>
                                    }
                                />
                            ))}
                        </>
                    );
                else return;
            })}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};
