import React from 'react';
import { CircularProgress, Typography, Box } from '..';

export const CircularProgressWithLabel = (props) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    component="div"
                    color="textSecondary"
                    style={{
                        fontWeight: 'bold',
                        ...(props?.fontSize && { fontSize: props.fontSize }),
                    }}
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
};
