import { makeStyles } from 'tss-react/mui';

const positionAbsoluteBoilerPlate = {
    position: 'absolute',
    content: '""',
    width: '100%',
    height: '100%',
};

export const useStyles = makeStyles()((theme) => ({
    // input component
    container: {
        transition: 'all 0.2s ease-in-out',
    },
    textField: {
        transition: 'all 0.2s ease-in-out',
    },
    name: {
        // maxHeight: 0,
        // opacity: 0,
        marginBottom: 10,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
    },
    inputActions: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    action: {
        cursor: 'pointer',
        zIndex: 2,
    },
    animateNameIn: {
        transition: 'all 0.35s ease-in-out',
        animation: '$nameIn 0.5s ease-in-out forwards',
    },
    animateNameOut: {
        transition: 'all 0.35s ease-in-out',
        animation: '$nameOut 0.5s ease-in-out forwards',
    },
    animateActionsIn: {
        transition: 'all 0.35s ease-in-out',
        animation: '$actionIn 0.5s ease-in-out forwards',
    },
    animateActionsOut: {
        transition: 'all 0.35s ease-in-out',
        animation: '$actionOut 0.5s ease-in-out forwards',
    },
    replyToBox: {
        borderLeft: `4px solid ${theme.palette.primary.dark}`,
        backgroundColor: theme.palette.grey[200],
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        cursor: 'pointer',
    },
    // input component
    // reply Box
    replyButton: {
        position: 'relative',
        cursor: 'pointer',
        '&:before': {
            ...positionAbsoluteBoilerPlate,
            height: '2px',
            bottom: '-4px',
            left: 0,
            transform: 'scale(0,1)',
            backgroundColor: theme.palette.common.lightBlue,
            transformOrigin: 'left',
            transition: 'all 0.2s ease-in-out',
        },
        '&:hover': {
            '&:before': {
                transform: 'scale(1,1)',
            },
        },
    },
    replyBox: {
        paddingLeft: '30px',
        marginLeft: '12px',
        borderLeft: '3px solid #c5c5c5',
    },
    addReply: {
        maxHeight: 0,
        opacity: 0,
    },
    replies: {
        maxHeight: 0,
        opacity: 0,
    },
    openReplies: {
        transition: 'all 0.35s ease-in-out',
        animation: 'repliesIn 0.5s ease-in-out forwards',
        '@keyframes repliesIn': {
            '0%': {
                maxHeight: 0,
                opacity: 0,
                marginTop: 0,
            },
            '70%': {
                maxHeight: 1600,
                opacity: 0,
                marginTop: 5,
            },
            '100%': {
                maxHeight: 1600,
                opacity: 1,
                marginTop: 5,
                pointerEvents: 'auto',
            },
        },
    },
    closeReplies: {
        transition: 'all 0.35s ease-in-out',
        animation: 'repliesOut 0.5s ease-in-out forwards',
        '@keyframes repliesOut': {
            '0%': {
                maxHeight: 1600,
                opacity: 1,
                marginTop: 5,
            },
            '30%': {
                maxHeight: 1600,
                opacity: 0,
                marginTop: 5,
            },
            '100%': {
                maxHeight: 0,
                opacity: 0,
                marginTop: 0,
                pointerEvents: 'none',
            },
        },
    },
    pseduoTextBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // height: '2.5rem',
        borderRadius: '0.25rem',
        cursor: 'pointer',
        border: '1px solid #74777b',
        padding: '0.5rem',
        fontSize: '0.875rem',
        color: '#74777b',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
            padding: '0.875rem',
        },
    },
    // reply Box

    clientRequirementsActivity: {
        borderLeft: `4px solid ${theme.palette.primary.dark}`,
        borderRadius: theme.shape.borderRadius,
    },

    // ANIMATIONS
    '@keyframes nameIn': {
        '0%': {
            maxHeight: 0,
            opacity: 0,
            marginBottom: 0,
        },
        '70%': {
            maxHeight: 100,
            opacity: 0,
            marginBottom: 20,
        },
        '100%': {
            maxHeight: 100,
            opacity: 1,
            marginBottom: 20,
            pointerEvents: 'auto',
        },
    },
    '@keyframes actionIn': {
        '0%': {
            maxHeight: 0,
            opacity: 0,
            marginTop: 0,
        },
        '70%': {
            maxHeight: 100,
            opacity: 0,
            marginTop: 20,
        },
        '100%': {
            maxHeight: 100,
            opacity: 1,
            marginTop: 20,
            pointerEvents: 'auto',
        },
    },
    '@keyframes nameOut': {
        '0%': {
            maxHeight: 100,
            opacity: 1,
            marginBottom: 20,
        },
        '30%': {
            maxHeight: 100,
            opacity: 0,
            marginBottom: 20,
        },
        '100%': {
            maxHeight: 0,
            opacity: 0,
            marginBottom: 0,
            pointerEvents: 'none',
        },
    },
    '@keyframes actionOut': {
        '0%': {
            maxHeight: 100,
            opacity: 1,
            marginTop: 20,
        },
        '30%': {
            maxHeight: 100,
            opacity: 0,
            marginTop: 20,
        },
        '100%': {
            maxHeight: 0,
            opacity: 0,
            marginTop: 0,
            pointerEvents: 'none',
        },
    },
    commentTextWrapper: {
        '& a': {
            color: 'blue',
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        '& .marker': {
            backgroundColor: 'yellow',
        },
    },
    // ANIMATIONS
}));
