import { Box, Button } from '../ui';
import { Link } from 'react-router-dom';
import {
    loginRoute,
    instructorSignUp,
    instructorLogin,
} from './route-definitions';
import { makeStyles } from 'tss-react/mui';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
    },
    button: {
        [theme.breakpoints.down('md')]: {
            flexBasis: '50%',
            margin: '0 8px',
        },
    },
}));

export const SignUpButtons = ({
    smallScreen = false,
    onClickButton = () => console.log('def'),
}) => {
    const { classes } = useStyles();

    const { pathname } = useLocation();

    const onClick = () => {
        onClickButton();
    };

    const canNotBeDestinationPaths = [
        '/on-air',
        '/',
        '/login',
        '/signup',
        '/instructor-login',
        '/instructor-signup',
    ];

    return (
        <Box className={classes.container}>
            <Box ml={1} className={classes.button}>
                {pathname === '/instructor-signup' ||
                pathname === '/instructor-login' ? (
                    <Link to={instructorLogin.path}>
                        <Button
                            size={smallScreen ? 'small' : 'medium'}
                            color="secondary"
                            variant="contained"
                            fullWidth
                            onClick={onClick}
                        >
                            Instructor Log In
                        </Button>
                    </Link>
                ) : (
                    <Link
                        to={{
                            pathname: loginRoute.path,
                            search: canNotBeDestinationPaths.includes(pathname)
                                ? ''
                                : `?dest=${pathname}`,
                        }}
                    >
                        <Button
                            size={smallScreen ? 'small' : 'medium'}
                            color="secondary"
                            variant="outlined"
                            fullWidth
                            onClick={onClick}
                        >
                            Log In
                        </Button>
                    </Link>
                )}
            </Box>
            <Box ml={1} className={classes.button}>
                {pathname === '/instructor-signup' ||
                pathname === '/instructor-login' ? (
                    <Link
                        to={{
                            pathname: instructorSignUp.path,
                            search: canNotBeDestinationPaths.includes(pathname)
                                ? ''
                                : `?dest=${pathname}`,
                        }}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            size={smallScreen ? 'small' : 'medium'}
                            fullWidth
                            onClick={onClick}
                        >
                            Instructor Sign Up
                        </Button>
                    </Link>
                ) : (
                    <Link to="/pricing">
                        <Button
                            color="secondary"
                            variant="contained"
                            size={smallScreen ? 'small' : 'medium'}
                            fullWidth
                            onClick={onClick}
                        >
                            Get Started
                        </Button>
                    </Link>
                )}
            </Box>
        </Box>
    );
};
