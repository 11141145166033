import { useMemo } from 'react';
import Highcharts from 'highcharts';
import dataModule from 'highcharts/modules/data';
import drillDownModule from 'highcharts/modules/drilldown';
import exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
import hcAccess from 'highcharts/modules/accessibility';

if (typeof Highcharts === 'object') {
    dataModule(Highcharts);
    drillDownModule(Highcharts);
    exporting(Highcharts);
    hcAccess(Highcharts);
}

export const PieChartWithDrilldown = (props) => {
    const {
        enableLegend = true,
        title,
        subtitle,
        plotLabelFormat,
        tooltip,
        series,
        drilldown,
        setChartEvents = null,
    } = props;

    const memoizedChartOpt = useMemo(() => {
        return {
            chart: {
                type: 'pie',
                ...(setChartEvents && { events: { ...setChartEvents() } }),
            },
            legend: { enabled: enableLegend },
            title: {
                text: title,
                align: 'center',
            },
            subtitle: {
                text: subtitle,
                align: 'center',
            },
            accessibility: {
                announceNewData: {
                    enabled: true,
                },
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    borderRadius: 5,
                    dataLabels: {
                        enabled: true,
                        format: plotLabelFormat,
                    },
                },
            },
            tooltip,
            series,
            drilldown,
        };
    }, [
        drilldown,
        enableLegend,
        plotLabelFormat,
        series,
        setChartEvents,
        subtitle,
        title,
        tooltip,
    ]);

    return (
        <HighchartsReact highcharts={Highcharts} options={memoizedChartOpt} />
    );
};
