import {
    Box,
    Dialog,
    DialogContent,
    IconButton,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CloseIcon } from '../../../icons';

const useStyles = makeStyles()((theme) => ({
    title: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.125rem',
            lineHeight: '1.75rem',
        },
    },
}));

export default function VideoDialog({ title, videoURL, open, setOpen }) {
    const { classes } = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            aria-labelledby="tutorial-video-player"
            open={open}
            fullWidth
            maxWidth="md"
            PaperProps={{
                style: {
                    backgroundColor: 'black',
                },
            }}
        >
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                px={2}
                pl={3}
            >
                <Typography
                    variant="h6"
                    style={{ color: 'white' }}
                    className={classes.title}
                >
                    {title}
                </Typography>
                <IconButton onClick={handleClose} size="large">
                    <CloseIcon style={{ color: 'white' }} />
                </IconButton>
            </Box>

            <DialogContent>
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                    <iframe
                        src={videoURL}
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                        }}
                        title={title}
                    ></iframe>
                </div>
            </DialogContent>
        </Dialog>
    );
}
