import { Box, Typography } from '../ui';
import { useStyles } from './styles';

export const ShowDiscount = ({ heading, amountOff, percentOff }) => {
    const { classes } = useStyles();
    return (
        <Box className={classes.productInfoContainer}>
            <Typography className={classes.productInfoLabel}>
                <b>{heading}</b>
            </Typography>
            <Typography className={classes.productInfoValue}>
                <b>
                    {amountOff ? <>${amountOff / 100} </> : <>{percentOff}%</>}
                </b>
            </Typography>
        </Box>
    );
};
