import {
    Box,
    CustomSnackbar,
    CircularProgress,
    Dialog,
    DialogTitle,
    Button,
    Icon,
    Typography,
} from '../ui';
import { AccountBalanceWalletIcon } from '../icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './Styles';
import { purchasedCourseState } from '../dashboard/Recoil/State';
import { useGetUserCredits } from '../my-account/useGetUserCredits';
import { userCreditsPointsState } from '../my-account/Recoil/State';
import { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { VoyagerPlusEnrollDialog } from './VoyagerPlusEnrollDialog';

export const EnrollDialog = ({
    open,
    setOpen,
    course,
    user,
    courseCost,
    track = async () => console.log('tracking function'),
}) => {
    const { credits } = useGetUserCredits();
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const { classes } = useStyles();
    const [purchasedProducts, setPurchasedProducts] = useRecoilState(
        purchasedCourseState
    );
    const updateCredits = useSetRecoilState(userCreditsPointsState);

    const onClose = () => {
        setOpen(false);
    };

    const register = async () => {
        if (!user) {
            return;
        }
        if (credits < 10) {
            navigate('/pricing');
            return;
        }
        setLoading(true);
        try {
            const purchaseObject = {
                product_id: course.id,
                product_type: 'ON_DEMAND',
                product_name: course?.name ?? course?.title,
                courseCost,
            };
            const { data } = await axios.post(
                `/api/users/${user._id}/purchase-product`,
                purchaseObject
            );

            setPurchasedProducts([
                ...purchasedProducts,
                { ...course, showUnEnroll: false },
            ]);
            updateCredits(data.credits);
            setMessage(`You are enrolled for ${course?.name ?? course?.title}`);
            try {
                await track();
            } catch (error) {
                console.log('error-in-track');
            }
        } catch (error) {
            console.log(error);
        }
        onClose();
        setLoading(false);
    };
    return (
        course && (
            <Dialog
                fullWidth
                maxWidth={credits >= 10 ? 'sm' : 'md'}
                disableScrollLock
                // disableBackdropClick={loading}
                open={open}
                onClose={onClose}
            >
                <Box className={classes.dialog}>
                    <DialogTitle className={classes.dialogTitle}>
                        {course?.name ?? course?.title}
                    </DialogTitle>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box>
                            <Typography variant="subtitle2">
                                Credits needed: {courseCost}
                                {/*will change it to be dynamic */}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Typography variant="subtitle2">Balance</Typography>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                            >
                                <Box className={classes.balanceIcon} ml={1}>
                                    <Icon
                                        style={{
                                            color: '#fff',
                                        }}
                                    >
                                        <AccountBalanceWalletIcon user={user} />
                                    </Icon>
                                </Box>
                                <Box className={classes.balanceAmount}>
                                    <strong>{credits ? credits : 0}</strong>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {credits >= courseCost ? (
                        <Box mt={2}>
                            <Typography>
                                Registering for this program requires 10
                                credits. &nbsp;Do you want to register?
                            </Typography>
                            <Box display="flex" flexDirection="row" mt={2}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    disabled={loading}
                                    onClick={onClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    className={classes.marginLeft}
                                    variant="contained"
                                    onClick={register}
                                    color="secondary"
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <CircularProgress
                                            size="2em"
                                            color="secondary"
                                        />
                                    ) : (
                                        'Register'
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <Box>
                            <VoyagerPlusEnrollDialog
                                exactCreditNeeded={courseCost - credits}
                                user={user}
                            />
                        </Box>
                    )}
                </Box>
                <CustomSnackbar
                    type="success"
                    message={message}
                    setMessage={setMessage}
                />
            </Dialog>
        )
    );
};
