import axios from 'axios';
import { useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { alertState, severity } from '../../../app/recoil';
import { IconButton, Paper, Tooltip } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const useStyles = makeStyles()((theme) => ({
    previewTableContainser: {
        overflow: 'auto',
    },
    previewTable: {
        borderCollapse: 'collapse',
        width: '100%',
    },
    previewTable__header: {
        textAlign: 'left',
        borderRadius: theme.spacing(0.5),
        '& > th': {
            padding: theme.spacing(1.5),
            backgroundColor: 'rgb(27, 73, 101)',
            color: '#fff',
            fontSize: '1.05rem',
            fontWeight: 400,
        },
    },
    previewTable__row: {
        borderRadius: theme.spacing(0.5),
        backgroundColor: 'rgb(255, 255, 255)',
        fontSize: '1rem',
        '& > td': {
            padding: theme.spacing(1.5),
        },
    },
    guidanceTitle: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    guidanceIdx: {
        display: 'flex',
        alignItems: 'center',
    },
    previewTable__header_num: {
        // textAlign: 'center',
        marginLeft: theme.spacing(1),
    },
}));

function GuidancePreviewTable({
    rows,
    setIsLoading,
    setRows,
    logisticsTemplateId,
    handleClickTitle,
    isInstructor = false,
    hideCTA,
}) {
    const { classes } = useStyles();
    const [draggingItem, setDraggingItem] = useState(null);
    const [dragOverItem, setDragOverItem] = useState(null);
    const setAlert = useSetRecoilState(alertState);

    const stopDraggable = useMemo(() => {
        if (isInstructor || hideCTA) return true;
        return false;
    }, [hideCTA, isInstructor]);

    const handleDragStart = (position) => {
        setDraggingItem(position);
    };

    const handleDragEnter = (position) => {
        setDragOverItem(position);
    };

    const updateGuidanceOrder = async (updatedRows) => {
        try {
            await axios.put('/api/guidance/update-guidance-order', {
                guidances: updatedRows,
            });
            setAlert({
                show: true,
                message: 'Successfully reordered guidances',
                severity: severity.SUCCESS,
            });
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDragEnd = async () => {
        setIsLoading(true);
        const rowsCopy = [...rows];
        const draggingItemContent = rowsCopy[draggingItem];
        rowsCopy.splice(draggingItem, 1);
        rowsCopy.splice(dragOverItem, 0, draggingItemContent);
        setDraggingItem(null);
        setDragOverItem(null);
        setRows(rowsCopy);
        rowsCopy.forEach((row, index) => {
            row['order'] = {
                ...(row?.order ? row.order : {}),
                [logisticsTemplateId]: index,
            };
        });
        await updateGuidanceOrder(rowsCopy);
    };

    return (
        <Paper className={classes.previewTableContainser}>
            <table className={classes.previewTable}>
                <thead>
                    <tr className={classes.previewTable__header}>
                        <th>
                            <span
                                style={{
                                    paddingLeft: !isInstructor
                                        ? '0.875rem'
                                        : '0',
                                }}
                            >
                                #
                            </span>
                        </th>
                        <th>Title</th>
                        <th>Category</th>
                        <th>Purpose</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => {
                        return (
                            <tr
                                key={row.id}
                                className={classes.previewTable__row}
                                draggable={!stopDraggable}
                                onDragStart={() => handleDragStart(index)}
                                onDragEnter={() => handleDragEnter(index)}
                                onDragEnd={handleDragEnd}
                                style={{
                                    borderBottom:
                                        dragOverItem === index
                                            ? '2px solid green'
                                            : '2px solid rgb(231, 231, 231)',
                                }}
                            >
                                <td
                                    style={{
                                        borderLeft:
                                            row.priority === 'Critical'
                                                ? '8px solid #FF033E'
                                                : 'none',
                                    }}
                                >
                                    <span className={classes.guidanceIdx}>
                                        {!stopDraggable && (
                                            <span>
                                                <IconButton
                                                    size="small"
                                                    sx={{
                                                        cursor: 'grab',
                                                    }}
                                                >
                                                    <DragIndicatorIcon fontSize="small" />
                                                </IconButton>
                                            </span>
                                        )}
                                        <span>{index + 1}</span>
                                    </span>
                                </td>
                                <td>
                                    <Tooltip
                                        title={row.summary}
                                        arrow
                                        enterDelay={500}
                                        leaveDelay={200}
                                    >
                                        <span
                                            onClick={() =>
                                                handleClickTitle(row)
                                            }
                                            className={classes.guidanceTitle}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {row.title}
                                        </span>
                                    </Tooltip>
                                </td>
                                <td>{row.category}</td>
                                <td>{row.purpose}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Paper>
    );
}

export default GuidancePreviewTable;
