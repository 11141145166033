import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import firebase from 'firebase/app';
// removed in first draft
// import 'firebase/analytics';
import 'firebase/auth';
import CssBaseline from '@mui/material/CssBaseline';
// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import './index.css';
import { App, reportWebVitals, store } from './app';
import { RecoilRoot } from 'recoil';

import { theme } from './theme';

import { CacheProvider } from '@emotion/react';
import { TssCacheProvider } from 'tss-react';
import createCache from '@emotion/cache';

const firebaseConfig = {
    apiKey: 'AIzaSyCPFsIlUXIm_cNJWUypW_cH60-rXWbng40',
    authDomain: 'starweaver-group-website.firebaseapp.com',
    projectId: 'starweaver-group-website',
    storageBucket: 'starweaver-group-website.appspot.com',
    messagingSenderId: '26700420244',
    appId: '1:26700420244:web:b7d2d0ffb331c60fea1081',
    measurementId: 'G-JYRGW45CF5',
};

const firebaseConfigStage = {
    apiKey: 'AIzaSyCuUX7p8bgoTyxDvo2zRbGZ7sEgeBtUI4M',
    authDomain: 'starweaver-stage.firebaseapp.com',
    projectId: 'starweaver-stage',
    storageBucket: 'starweaver-stage.appspot.com',
    messagingSenderId: '928668020673',
    appId: '1:928668020673:web:b696c32310aaed45710940',
};

firebase.initializeApp(
    process.env.REACT_APP_ENVIRONMENT === 'production'
        ? firebaseConfig
        : firebaseConfigStage
);
// firebase.analytics();

const generateClassName = createGenerateClassName({
    disableGlobal: true,
    productionPrefix: 'star',
});

// if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
//     Sentry.init({
//         dsn:
//             'https://f8ba2cbdd35f4c7aa174dfd89df77bb4@o4504923980234752.ingest.sentry.io/4504923988426752',
//         integrations: [new BrowserTracing()],
//         tracesSampleRate: 1.0,
//     });
// }

const muiCache = createCache({
    key: 'mui',
    prepend: true,
});

const tssCache = createCache({
    key: 'tss',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RecoilRoot>
                <CssBaseline />
                <StylesProvider generateClassName={generateClassName}>
                    {/* <StyledEngineProvider injectFirst> */}

                    <ThemeProvider theme={theme}>
                        <CacheProvider value={muiCache}>
                            <TssCacheProvider value={tssCache}>
                                {/* <React.Suspense
                                    fallback={<div>Loading...</div>}
                                > */}
                                <App />
                                {/* </React.Suspense> */}
                            </TssCacheProvider>
                        </CacheProvider>
                    </ThemeProvider>

                    {/* </StyledEngineProvider> */}
                </StylesProvider>
            </RecoilRoot>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
