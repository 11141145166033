import React, { useMemo } from 'react';
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '..';

import { useNavigate } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: { border: `1px solid ${theme.palette.grey[300]}`, borderRadius: 2 },
}));

export const DataGridCustom = ({
    columns,
    rows,
    selectedRows,
    setSelectedRows,
}) => {
    const { classes } = useStyles();
    let navigate = useNavigate();

    const allChecked = useMemo(
        () => rows.every((row) => selectedRows?.has(row.id)),
        [rows, selectedRows]
    );
    const someChecked = useMemo(
        () => rows.some((row) => selectedRows?.has(row.id)),
        [rows, selectedRows]
    );

    const noneChecked = useMemo(
        () => rows.every((row) => !selectedRows?.has(row.id)),
        [rows, selectedRows]
    );

    const handleCheckAll = (event) => {
        if (event.target.checked)
            setSelectedRows((prev) => {
                let newMap = new Map(prev);
                rows.forEach((row) => {
                    newMap.set(row.id, row);
                });
                return newMap;
            });
        else
            setSelectedRows((prev) => {
                let newMap = new Map(prev);
                rows.forEach((row) => {
                    newMap.delete(row.id);
                });
                return newMap;
            });
    };

    const handleCheck = (event, row) => {
        if (event.target.checked)
            setSelectedRows((prev) => {
                let newMap = new Map(prev);
                newMap.set(row.id, row);
                return newMap;
            });
        else
            setSelectedRows((prev) => {
                let newMap = new Map(prev);
                newMap.delete(row.id);
                return newMap;
            });
    };

    return (
        <TableContainer className={classes.root}>
            <Table>
                <TableHead>
                    <TableRow>
                        {selectedRows && (
                            <TableCell style={{ padding: 5 }}>
                                <Checkbox
                                    checked={someChecked}
                                    indeterminate={!allChecked && !noneChecked}
                                    onChange={(event) => {
                                        handleCheckAll(event);
                                    }}
                                />
                            </TableCell>
                        )}

                        {columns.map(({ headerName }) => (
                            <TableCell>{headerName}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            {selectedRows && (
                                <TableCell style={{ padding: 5 }}>
                                    <Checkbox
                                        value={row.id}
                                        checked={selectedRows?.has(row.id)}
                                        onChange={(event) => {
                                            handleCheck(event, row);
                                        }}
                                    />
                                </TableCell>
                            )}

                            {columns.map(
                                ({ field, renderCell, valueFormatter }) => {
                                    if (valueFormatter)
                                        return (
                                            <TableCell>
                                                {valueFormatter({
                                                    id: row.id,
                                                    value: row[field],
                                                    row,
                                                })}
                                            </TableCell>
                                        );

                                    if (renderCell)
                                        return (
                                            <TableCell>
                                                {renderCell({
                                                    id: row.id,
                                                    value: row[field],
                                                    row,
                                                })}
                                            </TableCell>
                                        );

                                    return <TableCell>{row[field]}</TableCell>;
                                }
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
