import axios from 'axios';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { setAllLiveLabs, getAllLiveLabs } from './Recoil/Selector';
import { useEffect } from 'react';

export const useGetAllLiveLabs = () => {
    const liveLabs = useRecoilValue(getAllLiveLabs);
    const setLiveLabs = useSetRecoilState(setAllLiveLabs);

    // useEffect(() => {
    //     const getValue = async () => {
    //         if (liveLabs === null) {
    //             try {
    //                 const { data } = await axios.get(
    //                     `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/live-labs?nowprocket=1`
    //                 );
    //                 const courses = data.map((item) => {
    //                     let regex = /(<([^>]+)>)/gi;
    //                     item.description = item.description.replace(regex, '');
    //                     return item;
    //                 });
    //                 setLiveLabs(courses);
    //             } catch (error) {
    //                 setLiveLabs(null);
    //                 console.log(error);
    //             }
    //         }
    //     };
    //     getValue();
    // }, []);

    return liveLabs;
};
