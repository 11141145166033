import { Box, Typography } from '../ui/index';
import { useStyles } from './styles';

export function CommunityContainer() {
    const { classes } = useStyles();
    return (
        <Box className={classes.root}>
            <Typography className={classes.textHead} variant="subtitle1">
                CONTENT
            </Typography>
            <Typography className={classes.textStyle} variant="subtitle1">
                <a href="/category/all/courses" rel="noreferrer">
                    Courses
                </a>
            </Typography>
            <Typography className={classes.textStyle} variant="subtitle1">
                {/* <a href="/certifications/all/courses" rel="noreferrer"> */}
                <a href="/certifications/all/c" rel="noreferrer">
                    Certifications
                </a>
            </Typography>
            <Typography className={classes.textStyle} variant="subtitle1">
                <a href="/journeys/all/j" rel="noreferrer">
                    Journeys
                </a>
            </Typography>
            <Typography className={classes.textStyle} variant="subtitle1">
                <a href="/test-prep/all/tests" rel="noreferrer">
                    Test Prep
                </a>
            </Typography>
            {/* <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    href="https://support.starweaver.com/portal/en/community/recent"
                    rel="noreferrer"
                    target="_blank"
                >
                    Community
                </a>
            </Typography>
            <Typography className={classes.textStyle} variant="subtitle1">
                <a href="/browse/retro-learning" rel="noreferrer">
                    Retro Learning
                </a>
            </Typography>
            <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    href="https://support.starweaver.com/portal/en/community/recent"
                    rel="noreferrer"
                >
                    Community
                </a>
            </Typography> */}
            {/* <Typography className={classes.textStyle} variant="subtitle1">
                <a href="/live-labs/" rel="noreferrer">
                    LiveLabs
                </a>
            </Typography> */}

            <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    // href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/meet-the-gurus-events/`}
                    href="https://www.youtube.com/playlist?list=PLkEYqrDmnr7xxEX1frqgG0wZqgOuqVQbM"
                    rel="noreferrer"
                    target="_blank"
                >
                    Meet the Gurus
                </a>
            </Typography>
            <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    //href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/techbytes-online-technology-blog`}
                    href="/techbytes"
                    rel="noreferrer"
                    // target="_blank"
                >
                    Techbytes
                </a>
            </Typography>
            {/* <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/meet-the-gurus-podcast/`}
                    rel="noreferrer"
                    target="_blank"
                >
                    Podcasts
                </a>
            </Typography> */}
        </Box>
    );
}
