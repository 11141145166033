import { Box, Button, Container, Grid, Typography } from '../ui';
import { useStyles } from './newBannerStyles';
import { useCurrentLiveClasses } from './useCurrentLiveClasses';
import { BannerSideContent } from './BannerSideContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

const marketingParams =
    'utm_source=startabsolutelyfree&utm_medium=homepage&utm_campaign=saf';

export const NewBanner = ({ isSmall }) => {
    const { isLoading, groupedCourses } = useCurrentLiveClasses(isSmall);
    const fullScreen = useMediaQuery('(min-width:1858px)');
    const { classes } = useStyles();
    let navigate = useNavigate();

    const handleGetStarted = () => {
        navigate('/pricing');
    };

    return (
        <Box position="relative">
            <Container
                className={fullScreen ? classes.containerMargin : ''}
                maxWidth="xl"
            >
                <Box position={'relative'}>
                    <Grid className={classes.bannerContainer} container>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box
                                pl={3}
                                display="flex"
                                flexDirection="column"
                                className={classes.bannerContent}
                            >
                                <Box mb={2}>
                                    {/* <Typography
                                        variant="h3"
                                        className={`${classes.heading}  ${classes.colorLightBlue}`}
                                    >
                                        Become <u>a fintech expert</u> in
                                    </Typography> */}
                                    <Typography
                                        variant="h3"
                                        className={`${classes.heading}  ${classes.colorLightBlue}`}
                                    >
                                        Where technology goes to business!
                                    </Typography>
                                    {/* <Typography
                                        variant="h3"
                                        className={`${classes.heading}  ${classes.colorLightBlue}`}
                                    >
                                        for the digital revolution
                                    </Typography> */}
                                </Box>
                                {/* <Typography
                                    className={classes.subHeading}
                                    variant="h6"
                                    // added
                                    color="primary"
                                >
                                    Keeping you sharp and ahead of the pack on
                                    your road to a high demand and lucrative
                                    career.
                                </Typography> */}
                                <Typography
                                    className={`${classes.subHeading}  ${classes.colorLightBlue}`}
                                    variant="h6"
                                    // added
                                >
                                    Sharp technology requires sharp skills. We
                                    help you stay ahead of the pack.
                                </Typography>
                                {/* <Typography
                                    className={classes.subHeading}
                                    variant="h6"
                                    // added
                                    color="primary"
                                >
                                    We help you stay ahead of the pack.
                                </Typography> */}
                                <Button
                                    className={`${classes.actionButton}`}
                                    size="large"
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleGetStarted}
                                >
                                    GET STARTED
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box mt={20}>
                                <BannerSideContent
                                    isSmall={isSmall}
                                    isLoading={isLoading}
                                    groupedCourses={groupedCourses}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Box
                className={`${classes.sideImage} ${
                    fullScreen ? classes.sideImageXL : ''
                }`}
            />
        </Box>
    );
};
