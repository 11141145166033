import { Grid } from '../ui';
import { Cards } from './Cards';

export const Features = ({ cards }) => {
    return (
        <div>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                {cards.map((card, index) => {
                    return (
                        <Grid key={index} item xs={12} sm={5} md={4}>
                            <Cards
                                CardIcon={card.CardIcon}
                                title={card.title}
                                body={card.body}
                                link={card.linkTo}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};
