import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import React, { useMemo } from 'react';
import useFetchTopicByTopicId from '../../hooks/useFetchTopicByTopicId';
import MarketMetricsRevenueTable from '../../MarketMetricsRevenueTable';
import { useSWACompetingCourses } from '../useSWACompetingCourses';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        loadingBox: {
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
            padding: theme.spacing(0.5),
            borderRadius: theme.spacing(0.5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});

export default function MarketMetricTooltipInfo({ title, isDrawer = false }) {
    const { classes } = useStyles();
    const {
        topicIds,
        isLoading: isCourseDataFetching,
    } = useSWACompetingCourses(title);
    const {
        topicData,
        isLoading: isTopicDataFetching,
    } = useFetchTopicByTopicId({ topicIds });

    /**
     * Return the loading state
     */
    const isLoading = useMemo(() => {
        return isCourseDataFetching || isTopicDataFetching;
    }, [isCourseDataFetching, isTopicDataFetching]);

    if (!isDrawer && isLoading) {
        return (
            <Box className={classes.loadingBox}>
                <CircularProgress color="secondary" size={20} />
            </Box>
        );
    }

    return (
        <MarketMetricsRevenueTable
            topicData={topicData}
            showMonthly={isDrawer ? true : false}
            show25thPercentile={isDrawer ? true : false}
            hideShadow={isDrawer}
            isLoading={isLoading}
        />
    );
}
