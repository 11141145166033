import axios from 'axios';
import { useState, useCallback } from 'react';

function useFetchTaskCategoriesByUserAssigned() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [taskCategories, setTaskCategories] = useState([]);

    const fetchData = useCallback(async (requirementId, filterBy = '') => {
        try {
            if (!requirementId) return;
            setLoading(true);
            let url =
                '/api/client-requirements/task-categories-by-aggigned-users';
            const params = new URLSearchParams();
            params.append('requirementId', requirementId);
            if (filterBy) {
                params.append('filterBy', filterBy);
            }
            url = `${url}?${params.toString()}`;
            const { data } = await axios.get(url);
            const { taskCategories } = data;
            setTaskCategories(taskCategories);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e);
            setError(true);
        }
    }, []);

    /* useEffect(() => {
        fetchData();
    }, [fetchData]); */

    return { loading, error, taskCategories, fetchData };
}

export default useFetchTaskCategoriesByUserAssigned;
