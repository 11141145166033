import React, { useMemo } from 'react';
import { Button, Box, Typography, Avatar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ClapsIcons, CommentIcon } from '../icons';
import { Link, useNavigate } from 'react-router-dom';
import ReactHTMLParser from 'react-html-parser';
import AccessContainer from '../courses-detail-page/AccessContainer';

const useStyles = makeStyles()((theme) => ({
    cardContainer: {
        height: '100%',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            padding: '0 0 1.25rem 0',
        },
    },
    cardWrapper: {
        height: '100%',
        border: '2px solid #f3f4f6',
        borderRadius: '0.6rem',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        paddingBottom: '0.5rem',
        // cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            transition: 'all 150ms ease-in 50ms',
            '&:hover': {
                transform: 'scale(1.02)',
                boxShadow:
                    '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
            },

            '& img': {
                transition: 'all 150ms ease-in 50ms',
            },

            '&:hover img': {
                transform: 'scale(1.03)',
            },
        },
    },
    cardHeader: {
        maxHeight: 195,
        height: '100%',
        position: 'relative',
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    cardLabel: {
        position: 'absolute',
        zIndex: '1',
        top: '1rem',
        left: 0,
        // backgroundColor: 'rgb(241, 77, 104)',
        color: 'rgb(255, 255, 255)',
        fontSize: '0.75rem',
        borderRadius: '0.1875rem',
        padding: '0.3125rem 0.625rem',
        fontWeight: 600,
        clipPath: 'polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%)',
        boxShadow:
            '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    },
    cardBody: {
        padding: '0.5rem 1.2rem',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    cardTitle: {
        fontSize: '1.375rem',
        lineHeight: '2rem',
        margin: 0,
    },
    cardSubtitle: {
        fontSize: '0.875rem',
        color: 'rgb(99,99,99)',
        margin: 0,
        lineHeight: '1.5rem',
        marginTop: '0.5rem',
        fontWeight: 400,
    },
    cardStats: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        color: 'rgb(99,99,99)',
        marginTop: '0.5rem',
        lineHeight: '0.8125rem',
        fontWeight: 400,
        '& > *': {
            margin: 0,
            padding: 0,
        },
    },
    likeCounter: {
        display: 'flex',
        alignItems: 'center',
    },
    cardContent: {
        fontSize: '1rem',
        fontWeight: 300,
        lineHeight: '1.5rem',
        color: 'rgb(55, 63, 73)',
        margin: '1rem 0rem',
    },
    instructorName: {
        fontWeight: 600,
        marginTop: '0.5rem',
        color: 'rgb(99, 99 ,99)',
    },
    cardBtnGroup: {
        marginTop: 'auto',
        display: 'flex',
        gap: '1%',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '@media (min-width: 374px)': {
            gap: '2%',
        },
        '@media (min-width: 400px)': {
            gap: '1%',
        },
        '& > *': {
            width: '100%',
            '@media (min-width: 374px)': {
                width: '46%',
            },
            '@media (min-width: 400px)': {
                width: '48%',
            },
        },
        '& > div': {
            '& > div': {
                width: '100%',
                '& > div': {
                    width: '100%',
                },
            },
        },
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    instructorNameContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    },
}));

const SWCourseCards = (props) => {
    const {
        course,
        domain,
        parseHTML = null,
        _duration = null,
        showAccessBtn = false,
    } = props;
    const { classes } = useStyles();
    let navigate = useNavigate();

    const totalLikes = useMemo(() => {
        if (course) {
            const baseClaps = course?.baseClaps || 0;
            const currentLikes = course.likes?.length || 0;
            return Number(baseClaps) + Number(currentLikes);
        } else {
            return 0;
        }
    }, [course]);

    const getTimeFromHr = useMemo(() => {
        let hr = course?.hours;

        if (!hr) return '';

        hr = parseInt(hr);

        if (hr > 1) {
            return hr + ' hrs';
        }

        return hr + ' hr';
    }, [course?.hours]);

    const showComment = useMemo(() => {
        if (course?.noOfStudents > 0 || course?.rating || totalLikes > 0) {
            return true;
        }

        return false;
    }, [course?.noOfStudents, course?.rating, totalLikes]);

    const handleCourseClick = (e, course) => {
        e.stopPropagation();

        const path = course?.link
            ? course.link
            : `/courses/${course?.slug.replace('-thinkific', '')}`;

        navigate(path);
    };

    const getTagBgColor = (tag) => {
        tag = tag.toLowerCase();

        switch (tag) {
            case 'new':
                return '#3b82f6';
            case 'popular':
                return '#dc2626';
            case 'trending':
                return '#f97316';
            case 'highly-rated':
                return '#84cc16';
            default:
                return 'black';
        }
    };

    const getLimitedText = (text) => {
        if (text?.length > 250) {
            return text?.slice(0, 250) + '...';
        } else {
            return text;
        }
    };

    return (
        <article
            className={classes.cardContainer}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleCourseClick(e, course);
            }}
        >
            <div className={classes.cardWrapper}>
                {/* CARD HEADER */}
                <div className={classes.cardHeader}>
                    <img
                        src={
                            course?.image ||
                            course?.thumbnail ||
                            course?.card_image_url ||
                            'https://placehold.jp/500x200.png'
                        }
                        alt={course?.title}
                        height={200}
                        width={500}
                    />
                    {course?.courseTag && (
                        <span
                            className={classes.cardLabel}
                            style={{
                                backgroundColor: getTagBgColor(
                                    course?.courseTag
                                ),
                                margin: 0,
                            }}
                        >
                            {course?.courseTag}
                        </span>
                    )}
                </div>

                {/* CARD BODY */}
                <div className={classes.cardBody}>
                    <h2 className={classes.cardTitle}>
                        {course?.title || course?.name}
                    </h2>

                    <Box my={1}>
                        {course?.instructors ? (
                            course?.instructors?.map((instructor) => {
                                return (
                                    <Link
                                        key={instructor.email}
                                        className={
                                            classes.instructorNameContainer
                                        }
                                        to={`/channels/${instructor.instructorSlug}`}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Avatar
                                            alt={instructor.instructor}
                                            src={instructor.instructorPic}
                                            className={classes.small}
                                        />
                                        <Typography variant="body2">
                                            {instructor.instructor}
                                        </Typography>
                                    </Link>
                                );
                            })
                        ) : (
                            <span>
                                {course?.instructor || course?.instructor_names}
                            </span>
                        )}
                    </Box>
                    {/* <span className={classes.instructorName}>
                        {course?.instructors
                            ? course?.instructors
                                  ?.map((i) => i.instructor)
                                  ?.join(', ')
                            : course?.instructor || course?.instructor_names}
                    </span> */}

                    <p className={classes.cardSubtitle}>
                        {course?.domain && (
                            <span>{course?.domain || domain}&nbsp;&#124;</span>
                        )}

                        {(course?.level || course?.courseLevel) && (
                            <span style={{ textTransform: 'capitalize' }}>
                                &nbsp;
                                {course?.level || course?.courseLevel}
                                &nbsp;&#124;
                            </span>
                        )}

                        {(course?.time || getTimeFromHr || _duration) && (
                            <span>
                                &nbsp;
                                {course?.time || getTimeFromHr || _duration}
                            </span>
                        )}
                    </p>

                    <div className={classes.cardStats}>
                        {(course?.noOfStudents > 0 || course?.noOfStudents) && (
                            <span>{course?.noOfStudents} Students</span>
                        )}

                        {course?.rating && <span>{course?.rating} Liked</span>}

                        {totalLikes > 0 && (
                            <span className={classes.likeCounter}>
                                <ClapsIcons fontSize="small" />
                                <span>{totalLikes}</span>
                            </span>
                        )}

                        {showComment && (
                            <CommentIcon
                                fontSize="small"
                                style={{ color: 'gray' }}
                            />
                        )}
                    </div>

                    <p className={classes.cardContent}>
                        {parseHTML
                            ? getLimitedText(
                                  ReactHTMLParser(course?.description)
                              )
                            : getLimitedText(
                                  course?.shortDescription ||
                                      course?.description
                              )}
                    </p>

                    <Box className={classes.cardBtnGroup}>
                        {showAccessBtn && (
                            <AccessContainer
                                courseId={course.productId}
                                courseTag={course.courseTag}
                                credits={10}
                                domain={domain}
                                time={course.time}
                                course={course}
                                showAccessBtn={showAccessBtn}
                            />
                        )}

                        <Button
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            onClick={(e) => handleCourseClick(e, course)}
                            style={{ width: showAccessBtn ? '' : '100%' }}
                        >
                            COURSE DETAILS
                        </Button>
                    </Box>
                </div>
            </div>
        </article>
    );
};

export default SWCourseCards;
