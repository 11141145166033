import { useMemo } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import moment from 'moment';
import { DATE } from '../../../util/types';
import { COMMENTS } from '../../requirementConstants';
import { COLLABORATOR } from '../../../auth/roles';
import { roleList } from '../../../util';

const useStyles = makeStyles()((theme) => {
    return {
        link: {
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    };
});

function FeedText({ row, isInstructorView, selectedRole, OMDetailsPath }) {
    const { classes } = useStyles();

    const userName = useMemo(() => {
        const userName = row?.user?.firstName + ' ' + row?.user?.lastName ?? '';

        if (isInstructorView || selectedRole === COLLABORATOR)
            return <b>{userName}</b>;
        return (
            <b>
                <a
                    href={`/admin/user-profiles/active/${row?.user?._id}`}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                >
                    {userName}
                </a>
            </b>
        );
    }, [
        row?.user?.firstName,
        row?.user?.lastName,
        row?.user?._id,
        isInstructorView,
        selectedRole,
        classes.link,
    ]);

    const label = useMemo(() => {
        const { category, field, requirementId, label } = row || {};
        const baseUrl = `${OMDetailsPath}${requirementId}`;
        const categoryUrlMap = {
            'sample outlines': '/samples',
            administrative: '/administration',
        };
        const categoryUrl =
            categoryUrlMap[category?.toLowerCase()] ||
            `/${category?.toLowerCase()}`;
        let url = `${baseUrl}${categoryUrl}`;
        if (field === 'taskCategories.tasks.status') {
            url = `${baseUrl}/logistics`;
        }
        return (
            <a
                className={classes.link}
                href={url}
                target="_blank"
                rel="noreferrer"
                style={{ color: '#16a34a', fontWeight: '500' }}
            >
                {label}
            </a>
        );
    }, [OMDetailsPath, classes.link, row]);

    const value = useMemo(() => {
        if (row.label === 'Title') return '';

        if (row.type === DATE) {
            const value = moment(row.value).format('DD-MMM-YYYY');
            return value;
        }
        if (row.field === 'selectedPersonas') {
            const value = row.value?.length
                ? row.value?.map(({ title }) => title)?.join(', ')
                : '';
            return value;
        }
        if (
            [
                'smes',
                'shortListedSmes',
                'collaborators',
                'addedCollaborators',
                'identifiedSmes',
                ...roleList.map((role) => role.field),
            ].includes(row.field)
        ) {
            const value = row[row.field]
                ?.map(({ firstName, lastName }) => `${firstName} ${lastName}`)
                ?.join(', ');
            return value;
        }
        if (row.field === 'tags') {
            const value = row.tags?.join(', ') || '';
            return value;
        }
        if (row.field === 'topics') {
            const value = row.topics?.map((v) => v.name).join(', ');
            return value;
        }
        return row.value;
    }, [row]);

    const title = useMemo(() => {
        const url = `${OMDetailsPath}${row?.requirementId}${
            row?.field === COMMENTS ? `/${COMMENTS}` : ''
        }`;
        return (
            <a
                className={classes.link}
                href={url}
                target="_blank"
                rel="noreferrer"
                style={{ color: '#0e7490', fontWeight: '500' }}
            >
                {row?.requirement?.title}
            </a>
        );
    }, [
        OMDetailsPath,
        row?.requirementId,
        row?.field,
        row?.requirement?.title,
        classes.link,
    ]);

    if (row?.field === COMMENTS)
        return (
            <Typography variant="body1">
                {userName} has commented on {title}
            </Typography>
        );

    return (
        <Typography variant="body1">
            {userName} has updated {label}{' '}
            {value?.length > 0 && <span>to '{value}'</span>} in {title}
        </Typography>
    );
}

export default FeedText;
