import { Box, Grid, Icon, Typography, CircularProgress, Rating } from '../ui';
import { FormatQuoteOutlinedIcon } from '../icons';
import { useEffect, useState, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import blob from './assets/quoteBlob.svg';
import Fade from '@mui/material/Fade';
import axios from 'axios';

export const TopComments = () => {
    const [data, setData] = useState();
    const [dataList, setDataList] = useState(null);
    const currentIndex = useRef(0);
    const [loaded, setLoaded] = useState(false);
    const { classes } = useStyles();

    useEffect(() => {
        axios
            .get('/api/users/top-comments')
            .then((res) => {
                setDataList(res.data.comment);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (dataList) {
            const setNewData = () => {
                // CAHNGE THE DATA NUMBER FROM HERE
                if (currentIndex.current > 47) {
                    currentIndex.current = 0;
                }
                setData([
                    dataList[currentIndex.current++],
                    dataList[currentIndex.current++],
                    dataList[currentIndex.current++],
                ]);
                setLoaded(true);
                setTimeout(() => {
                    resetData();
                }, 5000);
            };
            const resetData = () => {
                setLoaded(false);
                setTimeout(() => {
                    setNewData();
                }, 500);
            };
            setNewData();
        }
    }, [dataList]);

    return (
        <Box className={classes.mainContainer}>
            {data ? (
                <>
                    <Grid container justifyContent="center" alignItems="center">
                        {data.map((item, index) => {
                            let delay = 0;
                            if (index > 0) {
                                delay = Math.trunc((500 / 3) * index);
                            }
                            return (
                                <Fade
                                    in={loaded}
                                    style={{
                                        transitionDelay: loaded
                                            ? `${delay}ms`
                                            : '0ms',
                                    }}
                                >
                                    <Grid key={index} item xs={12} sm={4}>
                                        <Box
                                            className={
                                                index % 2 === 0
                                                    ? `${classes.carouselItemContainer}`
                                                    : `${classes.secondary} ${classes.carouselItemContainer}`
                                            }
                                        >
                                            <Box
                                                className={
                                                    classes.iconContainer
                                                }
                                            >
                                                <Icon
                                                    className={classes.icon}
                                                    fontSize="large"
                                                >
                                                    <FormatQuoteOutlinedIcon
                                                        fontSize="large"
                                                        color="primary"
                                                    />
                                                </Icon>
                                            </Box>
                                            <Typography
                                                className={classes.heading}
                                            >
                                                {item.courseName}
                                            </Typography>
                                            <Box mb={3}>
                                                <Rating
                                                    value={item.rating}
                                                    readOnly
                                                />
                                            </Box>
                                            <Typography
                                                className={classes.subtitle}
                                            >
                                                {item.comment.length > 200 ? (
                                                    <>
                                                        {item.comment.substr(
                                                            0,
                                                            200
                                                        )}
                                                        ...
                                                    </>
                                                ) : (
                                                    <>{item.comment}</>
                                                )}
                                            </Typography>
                                            <Typography
                                                className={classes.name}
                                                variant="caption"
                                            >
                                                - {item.studentName}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Fade>
                            );
                        })}
                    </Grid>
                </>
            ) : (
                <Box className={classes.load}>
                    <CircularProgress color="secondary" />
                </Box>
            )}
        </Box>
    );
};

const useStyles = makeStyles()((theme) => ({
    mainContainer: {
        display: 'grid',
        placeItems: 'center',
        minHeight: '450px',
        marginTop: '40px',
    },
    carouselItemContainer: {
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        minHeight: '380px',
        width: '300px',
        padding: '50px 20px 10px',
        margin: '10px auto',
        background:
            'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(237,238,224,1) 100%)',
        position: 'relative',
        boxShadow: '0px 5px 15px 1px rgba(0,0,0,0.2)',
        borderRadius: '15px',
        transition: 'all 0.2s ease-out',
        '&:hover': {
            transform: 'scale(1.02)',
            zIndex: 10,
        },
    },
    secondary: {
        background: 'rgb(255,255,255)',
        background:
            'linear-gradient(225deg, rgba(255,255,255,1) 0%, rgba(207,229,234,1) 100%)',
    },
    heading: {
        fontSize: '1.3em',
        fontWeight: 700,
        alignSelf: 'flex-start',
        marginBottom: 20,
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            width: '50%',
            height: '4px',
            borderRadius: '10px',
            bottom: '-3px',
            backgroundColor: theme.palette.common.lightBlue,
        },
    },

    iconContainer: {
        position: 'absolute',
        transform: 'rotate(180deg)',
        top: 0,
        overflow: 'visible',
    },
    icon: {
        position: 'relative',
        overflow: 'visible',
        '&::before': {
            content: '""',
            transform: 'rotate(16deg)',
            position: 'absolute',
            width: '200%',
            height: '200%',
            zIndex: '-1',
            top: '-40%',
            left: '-36%',
            backgroundImage: `url(${blob})`,
        },
    },
    subtitle: {
        color: '#7b7b7b',
    },
    name: {
        margin: '20px 0',
        alignSelf: 'flex-end',
        fontWeight: 700,
    },
    load: {
        display: 'grid',
        placeItems: 'center',
        minHeight: '400px',
    },
}));
