import { Close } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogContent,
    FormControl,
    IconButton,
    MenuItem,
    Select,
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { alertState, severity } from '../../../app/recoil';
import { AddIcon, CloseIcon, SearchIcon } from '../../../icons';
import { roleList } from '../../../util';
import ClientOptions from './ClientOptions';
import LogisticTemplateOptions from './LogisticTemplateOptions';

const useStyles = makeStyles()((theme) => ({
    header: {
        padding: theme.spacing(2, 3, 1),
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: '1.125rem',
        lineHeight: '1.75rem;',
        fontWeight: 600,
        margin: 0,
        padding: 0,
    },
    closeBtn: {
        marginLeft: 'auto',
    },
    filterForm: {
        display: 'grid',
        gap: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    },
    filterValue: {
        gridColumnStart: 1,
        gridColumnEnd: -1,
    },
    formAction: {
        marginTop: '1rem',
        flexBasis: '100%',
        display: 'flex',
        gap: '0.5rem',
        '& > *:first-child': {
            marginRight: 'auto',
        },
        '& > *:nth-child(2)': {
            marginLeft: 'auto',
        },
    },
    input: {
        border: 0,
        outline: '1.5px solid #9ca3af',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        padding: '0.5rem',
        borderRadius: '0.25rem',
        width: '100%',
        '&:focus': {
            outline: '2px solid #f97316',
        },
    },
    fieldWrappwer: {
        display: 'grid',
        gap: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    connectivityContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const commonFilterOperators = [
    {
        name: 'Contains',
        value: 'contains',
    },
    {
        name: 'Does not contain',
        value: 'doesNotContain',
    },
    {
        name: 'Equals',
        value: 'equals',
    },
    {
        name: 'Starts with',
        value: 'startsWith',
    },
    {
        name: 'Ends with',
        value: 'endsWith',
    },
    {
        name: 'Is empty',
        value: 'isEmpty',
    },
    {
        name: 'Is not empty',
        value: 'isNotEmpty',
    },
];

const durationFilterOperators = [
    {
        name: 'Equals',
        value: 'equals',
    },
    {
        name: 'Greater than',
        value: 'greaterThan',
    },
    {
        name: 'Less than',
        value: 'lessThan',
    },
];

const dateFilterOperators = [
    {
        name: 'Is',
        value: 'is',
    },
    {
        name: 'Is not',
        value: 'isNot',
    },
    {
        name: 'Is after',
        value: 'isAfter',
    },
    {
        name: 'Is on after',
        value: 'isOnAfter',
    },
    {
        name: 'Is on before',
        value: 'isOnBefore',
    },
    {
        name: 'Is before',
        value: 'isBefore',
    },
];

const numericFields = [
    'totalRequirements',
    'duration',
    'interestedInstructors',
    'smeOpeningBal',
    'additionalAdv',
    'royaltyEarned',
    'smeEndingBal',
    'payableToSME',
    'paidToSME',
    'year',
    'projects',
    'totalProjects',
];

const listFields = [
    'tags',
    'smes',
    'applicantNames',
    'collaborators',
    'addedCollaborators',
    'contractNumbers',
    'requirement.collaborators',
    ...roleList.map((role) => role.field),
];

const dateFields = [
    'expectedEndDate',
    'lastActivity',
    'payoutDate',
    'signedIn',
    'current_plan_end_date',
    'last_accessed',
    'createdAt',
];
const selectionFields = [
    'clientIds',
    'client_name',
    'client',
    'channelStatus',
    'templateDetails.name',
];
const statusFields = [
    'status',
];
const statusOptions = [
    { value: 'LIVE', label: 'LIVE' },
    { value: 'DRAFT', label: 'DRAFT' },
]

const TableFilterModal = ({
    open,
    columns,
    setOpen = () => {},
    getFilteredData = () => {},
    currentFilters = { model: [], logicalConnectivity: 'and' },
}) => {
    const setAlert = useSetRecoilState(alertState);
    const { classes } = useStyles();
    const [filterModel, setFilterModel] = useState([
        { columnField: '', operatorValue: '', filterValue: '' },
    ]);
    const [logicalConnectivity, setLogicalConnectivity] = useState('and');

    useEffect(() => {
        if (currentFilters.model.length > 0) {
            setFilterModel(currentFilters.model);
            setLogicalConnectivity(currentFilters.logicalConnectivity);
        } else {
            setFilterModel([
                { columnField: '', operatorValue: '', filterValue: '' },
            ]);
            setLogicalConnectivity('and');
        }
    }, [currentFilters.logicalConnectivity, currentFilters.model]);

    const getFilterOperators = useCallback((field) => {
        if (numericFields.includes(field)) return durationFilterOperators;
        if (listFields.includes(field))
            return [
                commonFilterOperators[0],
                commonFilterOperators[1],
                commonFilterOperators[5],
            ];
        if (dateFields.includes(field)) return dateFilterOperators;
        if (selectionFields.includes(field) || statusFields.includes(field)) {
            return commonFilterOperators.filter(
                (filter) => filter.value === 'equals'
            );
        }

        return commonFilterOperators;
    }, []);

    const filterOptions = useMemo(() => {
        const initialValue = {
            columns: [],
            filters: [],
        };

        return columns.reduce((prev, curr) => {
            if (curr.hide === true || curr.filterable === false) return prev;
            return {
                columns: prev.columns.concat([
                    {
                        headerName: curr?.headerName ?? curr?.title,
                        field: curr?.field ?? curr?.fieldName,
                    },
                ]),
                filters: prev.filters.concat([
                    {
                        field: curr?.field ?? curr?.fieldName,
                        operators: getFilterOperators(
                            curr?.field ?? curr?.fieldName
                        ),
                    },
                ]),
            };
        }, initialValue);
    }, [columns, getFilterOperators]);

    const getInputType = useCallback((field) => {
        if (numericFields.includes(field)) return 'number';
        if (dateFields.includes(field)) return 'date';
        return 'text';
    }, []);

    const resetFilterModel = () => {
        setFilterModel([
            { columnField: '', operatorValue: '', filterValue: '' },
        ]);

        getFilteredData({ model: [], logicalConnectivity });
    };

    const handleClear = () => {
        resetFilterModel();
    };

    const handleClose = () => {
        // if (reset) {
        //     resetFilterModel();
        // }

        setOpen(false);
    };

    const validate = () => {
        const errors = [];
        if (
            filterModel.find(
                (item) =>
                    item.operatorValue !== 'isNotEmpty' &&
                    item.operatorValue !== 'isEmpty' &&
                    item.filterValue.trim().length === 0
            )
        ) {
            errors.push({
                message: "Filter value can't left emtpy",
                param: 'filterWriteValue',
            });
        }

        return { isError: errors.length > 0, errors };
    };

    /**
     *
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    const handleSearch = async (e) => {
        e.preventDefault();
        const { isError, errors } = validate();
        if (isError) {
            return setAlert({
                show: true,
                severity: severity.WARNING,
                message: errors[0].message,
            });
        }

        getFilteredData({ model: filterModel, logicalConnectivity });
        handleClose();
    };

    const handleAddFilter = () => {
        setFilterModel((filterModel) => {
            return [
                ...filterModel,
                { columnField: '', operatorValue: '', filterValue: '' },
            ];
        });
    };

    return (
        <div>
            <Dialog
                aria-labelledby="filter-dialog"
                open={open}
                maxWidth="sm"
                fullWidth
            >
                {/* Dialog header */}
                <div className={classes.header}>
                    {/* Title */}
                    <p className={classes.title}>Apply Filters</p>

                    {/* Close button */}
                    <div className={classes.closeBtn}>
                        <IconButton size="small" onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </div>
                </div>

                {/* Dialog content */}
                <DialogContent>
                    <form onSubmit={handleSearch} autoComplete="off">
                        {filterModel.map((_, filterIdx) => {
                            return (
                                <div
                                    key={filterIdx}
                                    className={classes.fieldWrappwer}
                                    style={{
                                        gridTemplateColumns:
                                            filterModel.length < 2
                                                ? '1fr'
                                                : '90px 1fr',
                                    }}
                                >
                                    {filterModel.length > 1 && (
                                        <LogicalConnectivity
                                            filterIdx={filterIdx}
                                            logicalConnectivity={
                                                logicalConnectivity
                                            }
                                            setLogicalConnectivity={
                                                setLogicalConnectivity
                                            }
                                            setFilterModel={setFilterModel}
                                        />
                                    )}
                                    <FilterInputs
                                        filterOptions={filterOptions}
                                        getInputType={getInputType}
                                        filterIdx={filterIdx}
                                        filterModel={filterModel}
                                        setFilterModel={setFilterModel}
                                    />
                                </div>
                            );
                        })}

                        <div className={classes.formAction}>
                            <Button
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleAddFilter}
                            >
                                Add Filter
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<CloseIcon />}
                                onClick={handleClear}
                            >
                                {filterModel.length < 2 ? 'Clear' : 'Clear All'}
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                startIcon={<SearchIcon />}
                            >
                                Search
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const FilterInputs = ({
    filterModel,
    setFilterModel,
    filterOptions,
    getInputType,
    filterIdx,
}) => {
    const { classes } = useStyles();

    const getPlaceholder = useCallback((field) => {
        if (field === 'duration') return 'Hr(s)';
        if (field === 'interestedInstructors') return '# Interests';
        return 'Filter value';
    }, []);

    const selectedColumn = useMemo(() => {
        return filterModel[filterIdx].columnField;
    }, [filterIdx, filterModel]);

    const selectedOperator = useMemo(() => {
        return filterModel[filterIdx].operatorValue;
    }, [filterIdx, filterModel]);

    const filterValue = useMemo(() => {
        return filterModel[filterIdx].filterValue;
    }, [filterIdx, filterModel]);

    const operators = useMemo(() => {
        const operatorData = filterOptions.filters.filter(
            (item) => item.field === selectedColumn
        );
        if (operatorData.length === 0) return {};
        const data = operatorData[0];
        return data;
    }, [filterOptions.filters, selectedColumn]);

    const showFilterValueInput = useMemo(() => {
        if (selectionFields.includes(selectedColumn) || statusFields.includes(selectedColumn)) {
            return false;
        }

        if (['isEmpty', 'isNotEmpty'].includes(selectedOperator)) return false;
        return true;
    }, [selectedColumn, selectedOperator]);

    const handleColSelect = (e) => {
        const updatedFilterModel = [...filterModel];
        updatedFilterModel[filterIdx].columnField = e.target.value;
        updatedFilterModel[filterIdx].operatorValue = '';
        updatedFilterModel[filterIdx].filterValue = '';
        setFilterModel(updatedFilterModel);
    };

    const handleOperatorSelect = (e) => {
        const updatedFilterModel = [...filterModel];
        updatedFilterModel[filterIdx].operatorValue = e.target.value;
        setFilterModel(updatedFilterModel);
    };

    const handleChangeFilterValue = (e) => {
        let type = e.target.type;
        let value =
            type === 'date'
                ? moment(e.target.value).format('YYYY-MM-DD')
                : e.target.value;
        const updatedFilterModel = [...filterModel];
        updatedFilterModel[filterIdx].filterValue = value;
        setFilterModel(updatedFilterModel);
    };

    return (
        <div className={classes.filterForm}>
            {/* Column selection */}
            <select
                name="column"
                id="column"
                aria-label="column name"
                onChange={handleColSelect}
                value={selectedColumn}
                className={classes.input}
                required
            >
                <option disabled selected value={''}>
                    -- Select a column --
                </option>
                {filterOptions.columns.map((col, idx) => (
                    <option key={`${col.field}-${idx}`} value={col.field}>
                        {col.headerName}
                    </option>
                ))}
            </select>

            {/* Operator selection */}
            <select
                name="operators"
                id="operators"
                aria-label="operator name"
                onChange={handleOperatorSelect}
                value={selectedOperator}
                className={classes.input}
                required
            >
                <option disabled selected value={''}>
                    -- Select an operator --
                </option>
                {operators?.operators?.map((operator, idx) => (
                    <option
                        key={`${operator.field}-${idx}`}
                        value={operator.value}
                    >
                        {operator.name}
                    </option>
                ))}
            </select>

            {showFilterValueInput && (
                <input
                    type={getInputType(selectedColumn)}
                    name="filterValue"
                    id="filterValue"
                    aria-label="filter value"
                    autoComplete="off"
                    value={filterValue}
                    onChange={handleChangeFilterValue}
                    placeholder={getPlaceholder(selectedColumn)}
                    className={`${classes.input} ${classes.filterValue}`}
                    required={!selectionFields.includes(selectedColumn) || !statusFields.includes(selectedColumn)}
                    step="any"
                    hidden={selectionFields.includes(selectedColumn) || statusFields.includes(selectedColumn)}
                />
            )}

            {/* --- CLIENT NAME DROPDOWN --- */}
            {(selectedColumn === 'client_name' ||
                selectedColumn === 'client' ||
                selectedColumn === 'clientIds') && (
                <ClientOptions
                    setSelectedClientName={(value) => {
                        const updatedFilterModel = [...filterModel];
                        updatedFilterModel[filterIdx].filterValue = value;
                        setFilterModel(updatedFilterModel);
                    }}
                    selectedClientName={filterValue}
                />
            )}

            {(selectedColumn === 'status') && (
                <select
                    placeholder="Select Status"
                    className={`${classes.input} ${classes.filterValue}`}
                    value={filterValue}
                    onChange={(e) => {
                        const updatedFilterModel = [...filterModel];
                        updatedFilterModel[filterIdx].filterValue =
                            e.target.value;
                        setFilterModel(updatedFilterModel);
                    }}
                    required
                >
                    {filterValue === '' && (
                        <option value="" disabled>
                            <em>--- Select Status ---</em>
                        </option>
                    )}

                    {statusOptions.map((status) => {
                        return (
                            <option value={status.value} key={status.value}>
                                {status.label}
                            </option>
                        );
                    })}
                </select>
            )}

            {/* --- TEMPLATE NAME DROPDOWN --- */}
            {selectedColumn === 'templateDetails.name' && (
                <LogisticTemplateOptions
                    setSelectedTemplateName={(value) => {
                        const updatedFilterModel = [...filterModel];
                        updatedFilterModel[filterIdx].filterValue = value;
                        setFilterModel(updatedFilterModel);
                    }}
                    selectedTemplateName={filterValue}
                />
            )}

            {selectedColumn === 'channelStatus' && (
                <select
                    className={`${classes.input} ${classes.filterValue}`}
                    value={filterValue}
                    onChange={(e) => {
                        const updatedFilterModel = [...filterModel];
                        updatedFilterModel[filterIdx].filterValue =
                            e.target.value;
                        setFilterModel(updatedFilterModel);
                    }}
                    required
                >
                    <option value="" disabled>
                        --- Select status ---
                    </option>
                    <option value="publish">Published</option>
                    <option value="draft">Draft</option>
                </select>
            )}
        </div>
    );
};

const LogicalConnectivity = ({
    filterIdx,
    setFilterModel,
    logicalConnectivity,
    setLogicalConnectivity,
}) => {
    const { classes } = useStyles();
    const handleRemoveInput = () => {
        setFilterModel((filterModel) => {
            return filterModel.filter((_, idx) => idx !== filterIdx);
        });
    };
    return (
        <div className={classes.connectivityContainer}>
            <IconButton size="small" onClick={handleRemoveInput}>
                <CloseIcon fontSize="small" />
            </IconButton>
            {filterIdx !== 0 && (
                <FormControl
                    size="small"
                    color="secondary"
                    variant="standard"
                    fullWidth
                    disabled={filterIdx > 1}
                >
                    <Select
                        value={logicalConnectivity}
                        onChange={(e) => setLogicalConnectivity(e.target.value)}
                    >
                        <MenuItem value={'and'}>And</MenuItem>
                        <MenuItem value={'or'}>Or</MenuItem>
                    </Select>
                </FormControl>
            )}
        </div>
    );
};

TableFilterModal.propTypes = {
    columns: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    getFilteredData: PropTypes.func.isRequired,
    currentFilters: PropTypes.shape({
        model: PropTypes.array.isRequired,
        logicalConnectivity: PropTypes.oneOf(['and', 'or']).isRequired,
    }).isRequired,
};

export default TableFilterModal;
