import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { LinkIcon } from '../../icons';
import ChatIcon from '@mui/icons-material/Chat';

const useStyles = makeStyles()((theme) => ({
    headingContainer: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        textAlign: 'center',
    },
    icon: {
        color: theme.palette.common.blue,
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
    },
}));

export const HeadingSection = ({
    heading = '',
    subHeading,
    marginBottomLess = false,
    iconComponent = <></>,
    extLink,
    hideExtLink = true,
    onChat,
}) => {
    const { classes } = useStyles();

    return (
        <Box mb={marginBottomLess ? 1 : 5}>
            <Box
                display="flex"
                alignItems={'center'}
                justifyContent="center"
                className={classes.headingContainer}
            >
                <Typography variant="h3" className={classes.heading}>
                    {heading}
                </Typography>
                <Box className={classes.icon}>{iconComponent}</Box>
                {extLink && (
                    <Box
                        onClick={() => {
                            window.open(extLink, '_blank');
                        }}
                        className={classes.icon}
                        style={{
                            cursor: 'pointer',
                            display: hideExtLink ? 'none' : 'inline-block',
                        }}
                    >
                        <LinkIcon />
                    </Box>
                )}

                {onChat && (
                    <Box
                        onClick={onChat}
                        className={classes.icon}
                        style={{
                            cursor: 'pointer',
                            // display: hideExtLink ? 'none' : 'inline-block',
                        }}
                    >
                        <ChatIcon />
                    </Box>
                )}
            </Box>
            {subHeading && (
                <Typography align="center" variant="h6" gutterBottom>
                    {subHeading}
                </Typography>
            )}
        </Box>
    );
};
