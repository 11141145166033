import axios from 'axios';

export const userTrack = async (activityName, trackingObject) => {
    if (!activityName || Object.keys(trackingObject).length < 0) {
        console.log('Fileds not valid, cannot send request');
        return;
    }
    try {
        const {
            data: { success },
        } = await axios.post(
            `/api/tracking/activity/${activityName}`,
            trackingObject
        );
        console.log(success + 'Post track');
    } catch (error) {
        console.log(error.message);
        console.log(activityName);
    }
};
