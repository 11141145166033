import { useState } from 'react';
import AddTutorials from './AddTutorials';
import NoTutorials from './NoTutorials';
import TutorialCard from '../../../instructor-dashboard/components/learnHow/Card';
import { Box, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../../app/recoil';
import axios from 'axios';

const useStyles = makeStyles()((theme) => ({
    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(2),
    },
    tutorials: {
        display: 'grid',
        gap: '1rem',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    },
}));

function GuidanceTutorials({
    tutorials = [],
    guidanceId,
    fetchGuidance,
    logisticsTemplateId,
    hideCTA,
}) {
    const { classes } = useStyles();
    const [showAddTutorials, setShowAddTutorials] = useState(false);
    const [selectedTutorial, setSelectedTutorial] = useState({});
    const [isDeleting, setIsDeleting] = useState(false);
    const setAlert = useSetRecoilState(alertState);

    const handleEditTutorial = (tutorial) => {
        setSelectedTutorial(tutorial);
        setShowAddTutorials(true);
    };

    const handleDeleteTutorial = async (tutorial) => {
        try {
            setIsDeleting(true);
            const searchParams = new URLSearchParams();
            searchParams.append('guidanceId', guidanceId);
            searchParams.append('tutorialId', tutorial._id);
            const url = `/api/guidance/delete-tutorial?${searchParams.toString()}`;
            await axios.delete(url);
            await fetchGuidance(logisticsTemplateId);
            setAlert({
                show: true,
                message: 'Tutorial deleted successfully',
                severity: severity.SUCCESS,
            });
        } catch (e) {
            if ([400, 404, 422].includes(e.response.status)) {
                setAlert({
                    show: true,
                    message: e.response.data.message,
                    severity: severity.ERROR,
                });
            }
            console.log(e);
        } finally {
            setIsDeleting(false);
        }
    };

    if (showAddTutorials) {
        return (
            <AddTutorials
                setShowAddTutorials={setShowAddTutorials}
                guidanceId={guidanceId}
                fetchGuidance={fetchGuidance}
                logisticsTemplateId={logisticsTemplateId}
                selectedTutorial={selectedTutorial}
                setSelectedTutorial={setSelectedTutorial}
            />
        );
    }

    if (tutorials.length === 0) {
        return (
            <NoTutorials
                setShowAddTutorials={setShowAddTutorials}
                hideCTA={hideCTA}
            />
        );
    }

    return (
        <div>
            {!hideCTA && (
                <Box className={classes.btnGroup}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={() => setShowAddTutorials(true)}
                    >
                        Add New
                    </Button>
                </Box>
            )}

            <Box className={classes.tutorials}>
                {tutorials.map((tutorial) => {
                    return (
                        <TutorialCard
                            description={tutorial.description}
                            id={tutorial._id}
                            imgURL={tutorial.imgURL}
                            tag={tutorial.tag}
                            title={tutorial.title}
                            videoURL={tutorial.videoURL}
                            maxLength={tutorial.maxLength}
                            key={tutorial._id}
                            hideShare={true}
                            showEditBtn={!hideCTA}
                            handleEdit={() => handleEditTutorial(tutorial)}
                            showDeleteBtn={!hideCTA}
                            handleDelete={() => handleDeleteTutorial(tutorial)}
                            isDeleting={isDeleting}
                        />
                    );
                })}
            </Box>
        </div>
    );
}

export default GuidanceTutorials;
