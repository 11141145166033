import { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import hcAccess from 'highcharts/modules/accessibility';
import exporting from 'highcharts/modules/exporting';

if (typeof Highcharts === 'object') {
    hcAccess(Highcharts);
    exporting(Highcharts);
}

export const StackedBar = ({
    titleText,
    xCategories,
    yTitleText,
    isLegendReversed,
    subtitleText,
    seriesData,
    tickInterval = 1,
    cursor = undefined,
    point = undefined,
}) => {
    const memoizedChartOpt = useMemo(() => {
        return {
            chart: {
                type: 'bar',
            },
            title: {
                text: titleText,
                align: 'center',
            },
            subtitle: {
                text: subtitleText,
                align: 'center',
            },
            xAxis: {
                categories: xCategories,
            },
            credits: {
                enabled: false,
            },
            yAxis: {
                min: 0,
                title: {
                    text: yTitleText,
                },
                tickInterval,
            },
            legend: {
                reversed: isLegendReversed,
            },
            plotOptions: {
                series: {
                    ...(cursor && { cursor }),
                    ...(point && { point }),
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
            series: seriesData,
        };
    }, [
        isLegendReversed,
        seriesData,
        subtitleText,
        tickInterval,
        titleText,
        xCategories,
        yTitleText,
        cursor,
        point,
    ]);

    return (
        <HighchartsReact
            containerProps={{
                style: {
                    height: '100%',
                    // maxHeight: '375px',
                    overflow: 'hidden',
                    width: '100%',
                    padding: '0px 5px',
                },
            }}
            highcharts={Highcharts}
            options={memoizedChartOpt}
        />
    );
};
