import axios from 'axios';

export const getInstructorsByTagsTG = async (
    tags = [],
    limit,
    searchValue = ''
) => {
    // const resWP = await axios.get(
    //     `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/instructor?minimal=1${
    //         tags.length !== 0 ? `&tags=${tags.join()}` : ''
    //     }`
    // );

    // const resTG = await axios.get(
    //     //{ data: [] };
    //     `https://teachinguideapi.azurewebsites.net/api/instructors-by-tags/${
    //         tags.length !== 0 ? tags.join('|') : ''
    //     }`
    // );

    // return [...resWP.data, ...resTG.data];

    // const res = await axios.get(
    //     //{ data: [] };
    //     `/api/instructors/by-tags?code=L048o-dtcgZd3pFjS0OqE1m0tX2s5vzzLb_Nxe-hk9P2AzFulJ9Ivw==&concepts=${JSON.stringify(
    //         tags
    //     )}&top_n=100&min_course_match=0.8&min_rating=4.0`
    // );
    // const res = await axios.get(
    //     //{ data: [] };
    //     `/api/instructors/by-tags?code=L048o-dtcgZd3pFjS0OqE1m0tX2s5vzzLb_Nxe-hk9P2AzFulJ9Ivw==&concepts=${JSON.stringify(
    //         tags
    //     )}&top_n=100`
    // );

    // console.log(res.data);
    // return res.data && typeof res.data === 'object' && res.data !== ''
    //     ? res.data
    //     : [];

    const res = await axios.get(
        `https://fncapp-semanticsearch2.azurewebsites.net/api/HttpInstructorMatch?code=L048o-dtcgZd3pFjS0OqE1m0tX2s5vzzLb_Nxe-hk9P2AzFulJ9Ivw==&concepts=${JSON.stringify(
            tags
        )}&top_n=${
            limit || 100
        }&min_course_match=0.7&min_rating=4.0&search_value=${searchValue}`
    );

    return res.data.instructors || [];
};
