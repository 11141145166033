import React, {useMemo} from 'react';
import { Box, Typography } from '../ui/index';
import starweaver from '../starweaver-logo-white.png';
import { makeStyles } from 'tss-react/mui';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import JourneyBuilderLogoLight from "../img/journey_builder_logo_white.svg"

const useStyles = makeStyles()((theme) => ({
    root: {
        color: '#999',
        paddingTop: 40,
    },
    address: {
        //marginLeft: 20,
    },
}));

export function HeaderContainer() {
    const { classes } = useStyles();
    
    const isJourneyBuilder = useMemo(() => {
        const currHostname = window.location.hostname;
        const journeyBuilderHostname = process.env.REACT_APP_JOURNEYBUILDER_HOSTNAME;
        return currHostname === journeyBuilderHostname; 
    },[])
    
    return (
        <Box className={classes.root}>
            <Box>
                <LazyLoadImage
                    src={isJourneyBuilder ? JourneyBuilderLogoLight : starweaver}
                    width="300px"
                    alt="starweaver logo"
                />
            </Box>
            {isJourneyBuilder && <Typography variant='body1' color={"secondary"}>powered by starweaver</Typography>}
            {/* <Typography  className={classes.address}>
                1122 E. Pike Street, Suite 1180, Seattle, Washington 98122 ||
                +1-415-483-2260 // +44 20 3289 3277
            </Typography> */}
        </Box>
    );
}
