import { Box, Typography } from '../ui/index';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    root: {
        color: '#999',
    },
}));

export function ContentContainer() {
    const { classes } = useStyles();
    return (
        <Box className={classes.root}>
            <Typography variant="subtitle1">
                Our trademarks include Starweaver®, Make genius happen™,
                Education you can bank on®, People are your most important
                assets!®, Body of Knowledge™, StarLabs™, LiveLabs™, Journeys™
            </Typography>
        </Box>
    );
}
