import React, { useCallback, useEffect, useMemo } from 'react';
import {
    Box,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Slide,
    Button,
    IconButton,
    useTheme,
    useMediaQuery,
    DialogTitle,
    DialogContentText,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Close, EditTwoTone } from '@mui/icons-material';
import FirstStep from './FirstStep';
import { useState } from 'react';
import SecondStep from './SecondStep';
import { ConfirmDialog } from '../../../ui';
import ThirdStep from './ThirdStep';
import AddTaskFrom from './AddTaskForm';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../../app/recoil';
import { useCurrentUser } from '../../../my-account/useCurrentUser';
import axios from 'axios';
import AllTemplates from './AllTemplates';

const LIVE = 'LIVE';
export const types = [
    { value: 'ON-AIR', name: 'ON AIR' },
    { value: 'LIVE-STREAM', name: 'LIVE STREAM' },
    { value: 'ON-DEMAND', name: 'ON DEMAND' },
    { value: 'LABS', name: 'LABS' },
    { value: 'ASSESSMENT', name: 'ASSESSMENT' },
    { value: 'GUIDED-PROJECTS', name: 'GUIDED PROJECTS' },
    { value: 'LONG-FORM', name: 'LONG FORM' },
];

const useStyles = makeStyles()((theme) => ({
    modalContainer: {
        padding: '4%',
        [theme.breakpoints.up('sm')]: {
            padding: '2%',
        },
    },
    dialogHeading: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    closeBtn: {
        marginLeft: 'auto',
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    stepCountTxt: {
        fontSize: '14px',
        fontWeight: '500',
        [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CustomTemplateModal({
    open,
    setOpen,
    requirementId = undefined,
    loadRequirementData = undefined,
    isRequirementCreating = false,
    existingTemplateId = null,
}) {
    const { classes } = useStyles();
    const { user } = useCurrentUser();
    const theme = useTheme();
    const isXSDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [currentStep, setCurrentStep] = useState(1);
    const [templateName, setTemplateName] = useState('');
    const [clients, setClients] = useState([]);
    const [status, setStatus] = useState(LIVE);
    const [taskCategories, setTaskCategories] = useState([]);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [cateogryToBeDeleted, setCategoryToBeDeleted] = useState(null);
    const [showCategoryDeleteDialog, setShowCategoryDeleteDialog] = useState(
        false
    );
    const [showTaskDeleteDialog, setShowTaskDeleteDialog] = useState(false);
    const [categoryToBeEdited, setCategoryToBeEdited] = useState(null);
    const [isAddTask, setIsAddTask] = useState(false);
    const [selectedCategoryIdx, setSelectedCategryIdx] = useState(0);
    const [newTask, setNewTask] = useState({
        name: '',
        typeOfTask: '',
        isActive: true,
        duration: 0,
        numOfDaysBeforeDelivery: 0,
        checkpointName: null,
        pinToTop: false,
        isVisibleToInstructor: false,
        isSimplified: false,
    });
    const setAlert = useSetRecoilState(alertState);
    const [taskToBeDeleted, setTaskToBeDeleted] = useState(null);
    const [isTaskEdit, setIsTaskEdit] = useState(false);
    const [taskToBeEdited, setTaskToBeEdited] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [showTemplates, setShowTemplates] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [isEditTemplate, setIsEditTemplate] = useState(false);
    const [templateToUpdate, setTemplateToUpdate] = useState(null);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);

    const fetchTemplates = useCallback(() => {
        const url = `/api/client-requirements/get-logistic-templates?page=${page}&limit=${limit}`;
        axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const { templates, total } = res.data;
                    if (templates.length === 0) setShowTemplates(false);
                    setTemplates(templates);
                    setTotalPages(Math.ceil(total / limit));
                }
            })
            .catch((e) => {
                const message = e?.data?.response?.message ?? e?.message;
                setAlert({ show: true, message, severity: severity.ERROR });
            });
    }, [setAlert, page, limit]);

    useEffect(() => {
        fetchTemplates();
    }, [fetchTemplates, page, limit]);

    /*--------- MEMO, SIDE EFFECTS, CALLBACKS: START ----------*/
    const fullName = useMemo(() => {
        if (!user) return '';

        return user?.user_firstName + ' ' + (user?.user_lastName || '');
    }, [user]);
    /*--------- MEMO, SIDE EFFECTS, CALLBACKS: END ----------*/

    const handleClose = (showConfirmation = true) => {
        if (!showConfirmation) return setOpen(false);

        // If all templates are showing then close directly
        const isConfirm = showTemplates
            ? true
            : window.confirm(
                  'Are you sure you want to close? All your unsaved data will be lost.'
              );

        if (isConfirm) setOpen(false);
    };

    const validateStep = () => {
        const errors = [];

        if (currentStep === 1) {
            if (templateName.trim().length === 0) {
                errors.push({ message: "Template name can't left empty" });
            }

            if (clients.length === 0) {
                errors.push({ message: 'Select clients' });
            }

            if (selectedTypes.length === 0) {
                errors.push({ message: 'Select types' });
            }

            if (hours === 0 && minutes === 0) {
                errors.push({ message: "Duration can't be zero" });
            }
        }

        return { isError: errors.length > 0, errors };
    };

    const handleNext = () => {
        const { isError, errors } = validateStep();

        if (isError) {
            return setAlert({
                message: errors[0].message,
                severity: severity.ERROR,
                show: true,
            });
        }

        setCurrentStep((prev) => prev + 1);
    };

    const resetNewTask = () => {
        setNewTask({
            name: '',
            typeOfTask: '',
            isActive: true,
            numOfDaysBeforeDelivery: 0,
            duration: 0,
            checkpointName: null,
            pinToTop: false,
            isVisibleToInstructor: false,
            isSimplified: false,
        });
    };

    const handleBack = () => {
        if (isAddTask) {
            setIsAddTask(false);
            resetNewTask();
        } else {
            setCurrentStep((prev) => {
                if (prev === 1) {
                    setShowTemplates(true);
                }

                if (prev === 3) {
                    setSelectedCategryIdx(0);
                }

                return prev - 1;
            });
        }
    };

    const onAddCategory = () => {
        const newCategoryObj = {
            categoryName: newCategoryName,
            tasks: [],
            isActive: true,
            isHidden: false,
        };
        setTaskCategories((prev) => {
            return [...prev, newCategoryObj];
        });
        setNewCategoryName('');
    };

    const onCategoryDelete = (idx) => {
        setCategoryToBeDeleted(idx);
        setShowCategoryDeleteDialog(true);
    };

    const onCategoryEdit = (idx) => {
        setCategoryToBeEdited(idx);
        setNewCategoryName(taskCategories[idx].categoryName);
    };

    const onCloseConfirmDialog = () => {
        setShowCategoryDeleteDialog(false);
        setCategoryToBeDeleted(null);
        setShowTaskDeleteDialog(false);
        setTaskToBeDeleted(null);
    };

    const onCategoryDeleteConfirmed = () => {
        if (cateogryToBeDeleted === null) return;

        setTaskCategories((categories) => {
            return categories.filter((_, idx) => idx !== cateogryToBeDeleted);
        });

        setCategoryToBeDeleted(null);
        setShowCategoryDeleteDialog(false);
    };

    const onCategoryUpdate = () => {
        setTaskCategories((categories) => {
            return categories.map((category, idx) => {
                if (idx === categoryToBeEdited) {
                    return {
                        ...category,
                        categoryName: newCategoryName,
                    };
                } else {
                    return category;
                }
            });
        });
        setCategoryToBeEdited(null);
        setNewCategoryName('');
    };

    const validateNewTask = () => {
        const errors = [];

        if (newTask.name.trim().length === 0) {
            errors.push({ message: 'New task name is required' });
        }

        if (newTask.typeOfTask.trim().length === 0) {
            errors.push({ message: 'Task type is required' });
        }

        if (
            newTask.numOfDaysBeforeDelivery === undefined ||
            newTask.numOfDaysBeforeDelivery === ''
        ) {
            errors.push({ message: '# Days is required' });
        }

        if (newTask.duration === undefined || newTask.duration === '') {
            errors.push({ message: 'Duration is required' });
        }

        if (
            newTask.checkpointName !== null &&
            typeof newTask.checkpointName === 'string' &&
            newTask.checkpointName.trim().length === 0
        ) {
            errors.push({ message: 'Checkpoint name is required' });
        }

        return { isError: errors.length > 0, errors };
    };

    const handleCreateNewTask = () => {
        const { isError, errors } = validateNewTask();

        if (isError) {
            return setAlert({
                message: errors[0].message,
                show: true,
                severity: severity.ERROR,
            });
        }

        setTaskCategories((categories) => {
            return categories.map((category, idx) => {
                if (idx === selectedCategoryIdx) {
                    return {
                        ...category,
                        tasks: [
                            ...category.tasks,
                            {
                                ...newTask,
                                numOfDaysBeforeDelivery: Number(
                                    newTask.numOfDaysBeforeDelivery
                                ),
                                duration: Number(newTask.duration),
                            },
                        ],
                    };
                } else {
                    return category;
                }
            });
        });

        setAlert({
            show: true,
            severity: severity.SUCCESS,
            message: 'Task added',
        });

        setIsAddTask(false);
        // setIsTaskEdit(false);

        resetNewTask();
    };

    const handleDeleteTask = (taskIdx) => {
        setTaskToBeDeleted(taskIdx);
        setShowTaskDeleteDialog(true);
    };

    const onTaskDeleteConfirmed = () => {
        setTaskCategories((categories, idx) => {
            return categories.map((category, idx) => {
                if (idx === selectedCategoryIdx) {
                    return {
                        ...category,
                        tasks: category.tasks.filter(
                            (task, tIdx) => tIdx !== taskToBeDeleted
                        ),
                    };
                } else {
                    return category;
                }
            });
        });

        setTaskToBeDeleted(null);
    };

    const handleEditTask = (taskIdx) => {
        setIsTaskEdit(true);
        setTaskToBeEdited(taskIdx);
        const taskData = taskCategories[selectedCategoryIdx].tasks[taskIdx];
        setNewTask(taskData);
        setIsAddTask(true);
    };

    const onTaskEdit = () => {
        const { isError, errors } = validateNewTask();

        if (isError) {
            return setAlert({
                show: true,
                message: errors[0].message,
                severity: severity.ERROR,
            });
        }

        setTaskCategories((categories) => {
            return categories.map((category, cIdx) => {
                if (cIdx === selectedCategoryIdx) {
                    return {
                        ...category,
                        tasks: category.tasks.map((task, tIdx) => {
                            if (tIdx === taskToBeEdited) {
                                return newTask;
                            } else {
                                return task;
                            }
                        }),
                    };
                } else {
                    return category;
                }
            });
        });

        setAlert({
            show: true,
            message: 'Task updated',
            severity: severity.SUCCESS,
        });

        setTaskToBeEdited(null);
        setIsTaskEdit(false);
        setIsAddTask(false);
        resetNewTask();
    };

    const validateTaskCategories = () => {
        const errors = [];

        for (const category of taskCategories) {
            if (category.tasks.length === 0)
                errors.push({
                    message: `No tasks present in '${category.categoryName}' category`,
                });
        }

        return { isError: errors.length > 0, errors };
    };

    const onFinalSave = async () => {
        const newTemplate = {
            name: templateName,
            clientIds: clients.map((client) => client._id),
            types: selectedTypes.map((type) => type.value),
            createdBy: user._id,
            status,
            taskCategories,
            hours: parseInt(hours),
            minutes: parseInt(minutes),
        };

        const { isError, errors } = validateTaskCategories();

        if (isError) {
            return setAlert({
                show: true,
                severity: severity.ERROR,
                message: errors[0].message,
            });
        }

        try {
            const api = '/api/client-requirements/create-logistic-template';
            setIsSaving(true);
            const { data, status } = await axios.post(api, newTemplate);
            if (status === 201) {
                setAlert({
                    show: true,
                    severity: severity.SUCCESS,
                    message: data.message,
                });
                // handleClose(false);
                fetchTemplates();
                setShowTemplates(true);
            } else {
                throw new Error("Something wen't wrong");
            }
        } catch (e) {
            const message = e?.response?.data?.message ?? e.message;
            setAlert({ show: true, severity: severity.ERROR, message });
        } finally {
            setIsSaving(false);
        }
    };

    const onFinalUpdate = async () => {
        const updatedTemplate = {
            _id: templateToUpdate,
            name: templateName,
            clientIds: clients.map((client) => client._id),
            types: selectedTypes.map((type) => type.value),
            createdBy: user._id,
            status,
            taskCategories,
            hours: parseInt(hours),
            minutes: parseInt(minutes),
        };

        const { isError, errors } = validateTaskCategories();

        if (isError) {
            return setAlert({
                show: true,
                severity: severity.ERROR,
                message: errors[0].message,
            });
        }

        try {
            const api = '/api/client-requirements/update-logistic-template';
            setIsSaving(true);
            const { data, status } = await axios.put(api, updatedTemplate);
            if (status === 200) {
                setAlert({
                    show: true,
                    severity: severity.SUCCESS,
                    message: data.message,
                });
                fetchTemplates();
                setShowTemplates(true);
            } else {
                throw new Error("Something wen't wrong");
            }
        } catch (e) {
            const message = e?.response?.data?.message ?? e.message;
            setAlert({ show: true, severity: severity.ERROR, message });
        } finally {
            setIsSaving(false);
            setTemplateToUpdate(false);
            setIsEditTemplate(false);
            resetNewTask();
            setTaskCategories([]);
            setTemplateName('');
            setClients([]);
            setSelectedTypes([]);
            setHours(0);
            setMinutes(0);
        }
    };

    return (
        <Box>
            {cateogryToBeDeleted !== null && (
                <ConfirmDialog
                    title="Are you sure you want to delete this cateogry?"
                    onClose={onCloseConfirmDialog}
                    onCancle={onCloseConfirmDialog}
                    open={showCategoryDeleteDialog}
                    onConfirm={onCategoryDeleteConfirmed}
                />
            )}

            {taskToBeDeleted !== null && (
                <ConfirmDialog
                    title="Are you sure you want to delete this task?"
                    onClose={onCloseConfirmDialog}
                    onCancle={onCloseConfirmDialog}
                    open={showTaskDeleteDialog}
                    onConfirm={onTaskDeleteConfirmed}
                />
            )}

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="update-custom-template-title"
                aria-describedby="update-custom-template-description"
                fullWidth
                maxWidth="md"
                scroll="paper"
            >
                <Box className={classes.modalContainer}>
                    <Box className={classes.dialogTitle}>
                        {!showTemplates ? (
                            <Typography
                                variant="body1"
                                className={classes.stepCountTxt}
                            >
                                {currentStep} / 3
                            </Typography>
                        ) : (
                            <Typography
                                variant="h6"
                                className={classes.dialogHeading}
                            >
                                Logistic templates
                            </Typography>
                        )}

                        <IconButton
                            size="small"
                            onClick={handleClose}
                            disabled={isSaving}
                            className={classes.closeBtn}
                        >
                            <Close
                                fontSize={isXSDevice ? 'small' : 'default'}
                            />
                        </IconButton>
                    </Box>

                    <DialogContent className={classes.content} dividers>
                        <DialogContentText id="update-custom-template-description">
                            {showTemplates ? (
                                <AllTemplates
                                    templates={templates}
                                    setShowTemplates={setShowTemplates}
                                    setCurrentStep={setCurrentStep}
                                    setEditTemplate={setIsEditTemplate}
                                    setClients={setClients}
                                    setTemplateName={setTemplateName}
                                    setStatus={setStatus}
                                    setTaskCategories={setTaskCategories}
                                    setTemplateToUpdate={setTemplateToUpdate}
                                    setSelectedTypes={setSelectedTypes}
                                    requirementId={requirementId}
                                    loadRequirementData={loadRequirementData}
                                    isRequirementCreating={
                                        isRequirementCreating
                                    }
                                    setOpen={setOpen}
                                    setHours={setHours}
                                    setMinutes={setMinutes}
                                    setTemplates={setTemplates}
                                    page={page}
                                    setPage={setPage}
                                    limit={limit}
                                    setLimit={setLimit}
                                    totalPages={totalPages}
                                    handleCloseTemplateSelection={handleClose}
                                    existingTemplateId={existingTemplateId}
                                />
                            ) : (
                                <React.Fragment>
                                    {currentStep === 1 && (
                                        <FirstStep
                                            clients={clients}
                                            setClients={setClients}
                                            setStatus={setStatus}
                                            setTemplateName={setTemplateName}
                                            status={status}
                                            templateName={templateName}
                                            fullName={fullName}
                                            types={types}
                                            selectedTypes={selectedTypes}
                                            setSelectedTypes={setSelectedTypes}
                                            hours={hours}
                                            setHours={setHours}
                                            minutes={minutes}
                                            setMinutes={setMinutes}
                                        />
                                    )}

                                    {currentStep === 2 && (
                                        <SecondStep
                                            taskCategories={taskCategories}
                                            newCategoryName={newCategoryName}
                                            setNewCategoryName={
                                                setNewCategoryName
                                            }
                                            onAddCategory={onAddCategory}
                                            onCategoryDelete={onCategoryDelete}
                                            onCategoryEdit={onCategoryEdit}
                                            categoryToBeEdited={
                                                categoryToBeEdited
                                            }
                                            onCategoryUpdate={onCategoryUpdate}
                                            setTaskCategories={
                                                setTaskCategories
                                            }
                                        />
                                    )}

                                    {currentStep === 3 && (
                                        <React.Fragment>
                                            {isAddTask === false ? (
                                                <ThirdStep
                                                    taskCategories={
                                                        taskCategories
                                                    }
                                                    setIsAddTask={setIsAddTask}
                                                    selectedCategoryIdx={
                                                        selectedCategoryIdx
                                                    }
                                                    setSelectedCategryIdx={
                                                        setSelectedCategryIdx
                                                    }
                                                    onDeleteTask={
                                                        handleDeleteTask
                                                    }
                                                    handleEditTask={
                                                        handleEditTask
                                                    }
                                                    isSaving={isSaving}
                                                    setIsTaskEdit={
                                                        setIsTaskEdit
                                                    }
                                                />
                                            ) : (
                                                <AddTaskFrom
                                                    taskCategories={
                                                        taskCategories
                                                    }
                                                    selectedCategoryIdx={
                                                        selectedCategoryIdx
                                                    }
                                                    newTask={newTask}
                                                    setNewTask={setNewTask}
                                                    isTaskEdit={isTaskEdit}
                                                />
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </DialogContentText>
                    </DialogContent>

                    {!showTemplates && (
                        <DialogActions className={classes.actions}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size={isXSDevice ? 'small' : 'medium'}
                                onClick={handleBack}
                                disabled={isSaving}
                            >
                                BACK
                            </Button>

                            {currentStep !== 3 ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size={isXSDevice ? 'small' : 'medium'}
                                    onClick={handleNext}
                                    disabled={
                                        currentStep === 2 &&
                                        taskCategories.length === 0
                                    }
                                >
                                    NEXT
                                </Button>
                            ) : (
                                <React.Fragment>
                                    {isAddTask ? (
                                        <React.Fragment>
                                            {isTaskEdit === false ? (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size={
                                                        isXSDevice
                                                            ? 'small'
                                                            : 'medium'
                                                    }
                                                    onClick={
                                                        handleCreateNewTask
                                                    }
                                                >
                                                    CREATE TASK
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size={
                                                        isXSDevice
                                                            ? 'small'
                                                            : 'medium'
                                                    }
                                                    onClick={onTaskEdit}
                                                >
                                                    UPDATE
                                                </Button>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {!isEditTemplate ? (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size={
                                                        isXSDevice
                                                            ? 'small'
                                                            : 'medium'
                                                    }
                                                    onClick={onFinalSave}
                                                    disabled={isSaving}
                                                >
                                                    {isSaving
                                                        ? 'PLEASE WAIT'
                                                        : 'SAVE'}
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size={
                                                        isXSDevice
                                                            ? 'small'
                                                            : 'medium'
                                                    }
                                                    onClick={onFinalUpdate}
                                                    disabled={isSaving}
                                                >
                                                    {isSaving
                                                        ? 'PLEASE WAIT'
                                                        : 'UPDATE'}
                                                </Button>
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </DialogActions>
                    )}
                </Box>
            </Dialog>
        </Box>
    );
}

export default CustomTemplateModal;
