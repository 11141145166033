import { useMemo } from 'react';
import Highcharts from 'highcharts';
import HCMore from 'highcharts/highcharts-more';
import HCSolid from 'highcharts/modules/solid-gauge';
import exporting from 'highcharts/modules/exporting';
import dataModule from 'highcharts/modules/export-data';
import hcAccess from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';

if (typeof Highcharts === 'object') {
    HCMore(Highcharts);
    dataModule(Highcharts);
    exporting(Highcharts);
    hcAccess(Highcharts);
    HCSolid(Highcharts);
}

export const SolidGauge = ({
    title,
    subtitle,
    min,
    max,
    data,
    seriesName,
    stops = [
        [0.1, '#DF5353'], // green
        [0.5, '#DDDF0D'], // yellow
        [0.9, '#55BF3B'], // red
    ],
}) => {
    const memoizedChartOpt = useMemo(() => {
        return {
            chart: {
                type: 'solidgauge',
            },
            legend: { enabled: true },
            title: {
                text: title,
                align: 'center',
            },
            subtitle: {
                text: subtitle,
                align: 'center',
            },
            exporting: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 0,
                        borderWidth: 0,
                        useHTML: true,
                    },
                },
            },
            tooltip: {
                enabled: true,
            },
            pane: {
                center: ['50%', '70%'],
                size: '100%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor ||
                        '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc',
                },
            },
            yAxis: {
                min,
                max,
                stops,
                lineWidth: 0,
                tickWidth: 0,
                minorTickInterval: null,
                tickAmount: 0,
                tickInterval: 0,
                labels: {
                    y: 16,
                },
            },
            series: [
                {
                    name: seriesName,
                    data: [data],
                    dataLabels: {
                        format:
                            '<div style="text-align:center">' +
                            '<span style="font-size:25px">{y}</span><br/>' +
                            '</div>',
                    },
                },
            ],
        };
    }, [data, max, min, seriesName, stops, subtitle, title]);

    return (
        <HighchartsReact highcharts={Highcharts} options={memoizedChartOpt} />
    );
};
