import React, { useEffect, useState, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import axios from 'axios';
import moment from 'moment';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    TextField,
    Typography,
    Container,
    CircularProgress,
    MenuItem,
    Tooltip,
} from '../ui';
import { CloseIcon } from '../icons';

import { MobileOtpVerification } from '../verify-mobile-number/MobileOtpVerification';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { ZoneData } from '../instructor-onboarding/ZoneData';
import { CountryData } from '../instructor-onboarding/CountryData';
import { useAllCompanies } from './useAllCompanies';
import { userPlanState } from './recoil';
import { alertState, severity } from '../app/recoil';
import { USER_PLAN_OPTIONS } from './ChangeUserPlan';

import { MANAGER, COLLABORATOR } from '../auth/roles';

//import { useCurrentUser } from '../my-account/useCurrentUser';

const useStyles = makeStyles()((theme) => ({
    paper: {
        minWidth: '90vw',
        padding: 40,
        [theme.breakpoints.up('md')]: {
            minWidth: 1000,
        },
    },
    dialogContent: { padding: 0 },
    inputContainer: {
        width: '100%',
        margin: 0,
    },
}));

export const InviteUserForm = ({
    open,
    setOpen,
    isCollaborator,
    hideRoles,
    onInviteUser,
}) => {
    let navigate = useNavigate();
    const { classes } = useStyles();

    const { companiesOption } = useAllCompanies();
    const setAlert = useSetRecoilState(alertState);
    // const [edit, setEdit] = useState(true);
    const [saving, setSaving] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [skype_id, setSkype] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [time_zone, setZone] = useState('');
    const [twitter, setTwitter] = useState('');
    const [linkedin, setLinkdin] = useState('');
    const [mobile_number, setMobileNumber] = useState('');
    const [email, setEmail] = useState('');
    const [notes, setNotes] = useState('');
    const [admin, setAdmin] = useState(false);
    const [instructor, setInstructor] = useState(false);
    const [manager, setManager] = useState(false);
    const [collaborator, setCollaborator] = useState(isCollaborator || false);

    const [clientId, setClientId] = useState(0);

    const [sendInvite, setSendInvite] = useState(false);

    const {
        // show: initialShow,
        accountType: initialAccountType,
        expirationDate: initialExpirationDate,
    } = useRecoilValue(userPlanState);

    const [accountType, setAccountType] = useState(
        initialAccountType || 'VOYAGER'
    );

    const [expirationDate, setExpirationDate] = useState(
        initialExpirationDate || new Date()
    );

    const [error, setError] = useState('');

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleMobileChange = (e) => {
        setMobileNumber(e.target.value);
    };

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
    };

    const handleChangeCountry = (event) => {
        if (event.target.value === '') {
            setCountry('');
        } else if (event.target.value === '-----------------------------') {
            setCountry('');
        } else {
            setCountry(event.target.value);
        }
    };

    const handleLinkedinChange = (e) => {
        setLinkdin(e.target.value);
    };

    const handleClientChange = (value, index) => {
        // handleUserChange(value, index, 'clientId');
        setClientId(value);
    };

    const handleSMEAdd = (item) => {};

    const handleSMEEditAdd = (item) => {};

    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log('submit');
        invite();
    };

    const handleDialogClose = (e) => {
        setOpen(false);
    };

    const handleChangeAdmin = (value) => {
        setAdmin(value);
    };

    const handleChangeInstructor = (value) => {
        setInstructor(value);
    };

    const handleChangeManager = (value) => {
        setManager(value);
    };

    const handleChangeCollaborator = (value) => {
        setCollaborator(value);
    };

    const handleChangeAccountType = (e) => {
        setAccountType(e.target.value);
    };

    const handleExpirationDateChange = (e) => {
        setExpirationDate(e.target.value);
    };

    const showExpirationDate = useMemo(() => {
        if (accountType === 'VOYAGER' || accountType === 'VOYAGER_PLUS') {
            return false;
        } else {
            return true;
        }
    }, [accountType]);

    const invite = async () => {
        try {
            if (
                firstName &&
                email &&
                // linkedin &&

                country
            ) {
                setSaving(true);

                const roles = [];

                if (manager) roles.push(MANAGER);
                if (collaborator) roles.push(COLLABORATOR);

                const res = await axios.post(`/api/admin/invite/user/`, {
                    firstName,
                    lastName,
                    email,
                    country,
                    mobile_number,
                    // linkedin,
                    clientId: clientId ? clientId : undefined,
                    isAdmin: admin,
                    isInstructor: instructor,
                    accountType,
                    expirationDate,
                    inviteRedirectRoute: admin
                        ? '/admin'
                        : instructor
                        ? '/profile/instructor-dashboard'
                        : collaborator
                        ? '/collaborator/opportunity-management'
                        : '/',
                    roles,
                    sendInvite,
                });

                if (onInviteUser) onInviteUser(res.data.user);

                setAlert({
                    message: 'Invite sent successfully.',
                    show: true,
                    severity: severity.SUCCESS,
                });

                setSaving(false);

                handleDialogClose();

                // setEdit(false);
            } else {
                setSaving(false);
                // console.log(linkedin, city, country, time_zone);
                setAlert({
                    message: 'Please fill all the required fields.',
                    show: true,
                    severity: severity.ERROR,
                });
            }
        } catch (e) {
            setSaving(false);
            console.log(e.response?.data?.message);
            if (e.response?.data?.message)
                setAlert({
                    message: e?.response?.data?.message,
                    show: true,
                    severity: severity.ERROR,
                });
        }
    };

    return (
        <Dialog
            onClose={handleDialogClose}
            open={open}
            maxWidth="lg"
            classes={{ paper: classes.paper }}
        >
            <Box display="flex" alignItems="center">
                <>
                    <Typography variant="h6" style={{ marginRight: 'auto' }}>
                        Invite user
                    </Typography>
                </>

                <IconButton
                    aria-label="close"
                    onClick={handleDialogClose}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <form onSubmit={handleFormSubmit}>
                <DialogContent className={classes.dialogContent}>
                    <Grid
                        container
                        spacing={2}
                        className={classes.inputContainer}
                    >
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={firstName}
                                onChange={handleFirstNameChange}
                                variant="outlined"
                                // margin="normal"
                                required
                                fullWidth
                                label="First Name (required)"
                                name="first name"
                                color="secondary"
                                helperText={error}
                                //disabled={user.user_firstName !== ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={lastName}
                                onChange={handleLastNameChange}
                                variant="outlined"
                                // margin="normal"
                                fullWidth
                                label="Last Name "
                                name="last name"
                                color="secondary"
                                helperText={error}
                                //disabled={user.user_lastName !== ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={email}
                                onChange={handleEmailChange}
                                variant="outlined"
                                // margin="normal"
                                required
                                fullWidth
                                label="Email (required)"
                                name="email"
                                color="secondary"
                                helperText={error}
                                //disabled={user.user_lastName !== ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={mobile_number}
                                onChange={handleMobileChange}
                                variant="outlined"
                                // margin="normal"
                                fullWidth
                                label="Mobile number"
                                name="mobile_number"
                                color="secondary"
                                helperText={error}
                                //disabled={user.user_lastName !== ''}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <TextField
                                value={linkedin}
                                onChange={handleLinkedinChange}
                                variant="outlined"
                                // margin="normal"
                                // value={linkedin}
                                // required
                                fullWidth
                                label="Linkedin Profile"
                                name="linkedin"
                                // autoComplete="email"
                                color="secondary"
                                helperText={error}
                                // disabled={!edit}
                            />
                        </Grid> */}

                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-select-currency-native"
                                select
                                required
                                label="Country (required)"
                                value={country}
                                onChange={handleChangeCountry}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Please select your country"
                                variant="outlined"
                                color="secondary"
                                // disabled={!edit}
                                fullWidth
                            >
                                {CountryData.map((option) => (
                                    <option
                                        key={option.code}
                                        value={option.name}
                                    >
                                        {option.name}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}></Grid>
                        {!hideRoles && (
                            <>
                                <Grid item xs={12} md={3}>
                                    <Box
                                        mr={2}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Checkbox
                                            checked={admin}
                                            onChange={(e) => {
                                                handleChangeAdmin(
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                        <Typography>Admin</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box
                                        mr={2}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Checkbox
                                            checked={instructor}
                                            onChange={(e) => {
                                                handleChangeInstructor(
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                        <Typography>Instructor</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box
                                        mr={2}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Checkbox
                                            checked={manager}
                                            onChange={(e) => {
                                                handleChangeManager(
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                        <Typography>Manager</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box
                                        mr={2}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Checkbox
                                            checked={collaborator}
                                            onChange={(e) => {
                                                handleChangeCollaborator(
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                        <Typography>Collaborator</Typography>
                                    </Box>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12} md={6}>
                            <TextField
                                value={clientId}
                                onChange={(e) =>
                                    handleClientChange(e.target.value)
                                }
                                select
                                variant="outlined"
                                // margin="normal"
                                // required
                                fullWidth
                                label="Client"
                                name="client"
                                color="secondary"
                            >
                                {companiesOption.map(({ _id, client_name }) => (
                                    <MenuItem key={_id} value={_id}>
                                        {client_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField
                                select
                                label="Account type"
                                value={accountType}
                                fullWidth
                                variant="outlined"
                                // margin="normal"
                                color="secondary"
                                inputProps={{
                                    MenuProps: {
                                        disableScrollLock: true,
                                    },
                                }}
                                onChange={handleChangeAccountType}
                            >
                                {USER_PLAN_OPTIONS.map(({ label, value }) => (
                                    <MenuItem value={value}>{label}</MenuItem>
                                ))}
                            </TextField>{' '}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            {showExpirationDate && (
                                <TextField
                                    fullWidth
                                    value={expirationDate}
                                    variant="outlined"
                                    // margin="normal"
                                    color="secondary"
                                    label="Expiration date"
                                    type="date"
                                    onChange={handleExpirationDateChange}
                                    defaultValue={new Date()}
                                    InputProps={{
                                        inputProps: {
                                            min: moment().format('YYYY-MM-DD'),
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box mr={2} display="flex" alignItems="center">
                                <Tooltip title="Unchecking this will create user only so you can send the invite later.">
                                    <Checkbox
                                        checked={sendInvite}
                                        onChange={(e) => {
                                            setSendInvite(e.target.checked);
                                        }}
                                    />
                                </Tooltip>

                                <Typography>Send email invite.</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Box width="100%" display="flex" mt={3}>
                        <Box>
                            <Button
                                onClick={handleDialogClose}
                                variant="contained"
                                // color="primary"
                            >
                                Cancel
                            </Button>
                        </Box>

                        <Box style={{ marginLeft: 'auto' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                disabled={saving}
                            >
                                {saving ? (
                                    <CircularProgress size="1.9em" />
                                ) : true ? (
                                    <>Invite</>
                                ) : (
                                    <>Edit</>
                                )}
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
};
