import { Tab, Tabs, Paper, Typography } from '../index';
import { makeStyles } from 'tss-react/mui';

export const VerticalNavigationTab = ({
    labels,
    value,
    setValue,
    tabComponent = 'span',
}) => {
    const { classes } = useStyles();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper elevation={0} className={classes.sidenav}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                color="secondary"
                textColor="secondary"
                className={classes.tabs}
                onChange={handleChange}
            >
                {labels.map((label) => (
                    <Tab
                        icon={label.icon && label.icon}
                        label={
                            <Typography component={tabComponent}>
                                {label.name}
                            </Typography>
                        }
                    />
                ))}
            </Tabs>
        </Paper>
    );
};

const useStyles = makeStyles()((theme) => ({
    sidenav: {
        margin: '36px 0',
        borderRadius: '5px',
        boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
    },
    tabs: {
        borderRadius: 5,
        '& .MuiTab-root': {
            maxWidth: 'none',
        },
        '& .MuiTab-wrapper': {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            '& > span': {
                textTransform: 'capitalize',
                width: 100,
            },
        },
    },
}));
