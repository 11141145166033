import { Box } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { fetchExpertById } from '../../../instructor/slices/expertSummarySlice';
import { domainOptions } from '../../ClientRequirementsBulkUpdateModal';
import useFetchReleaseStageOfReq from '../../hooks/useFetchReleaseStageOfReq';
import { setLoadingStatus } from '../../slices/requirementSummarySlice';
import RequirementSumamrySimpleField from './RequirementSumamrySimpleField';
import RequirementSummarySMEList from './RequirementSummarySMEList';
import RequirementSumamryTaskField from './RequirementSummaryTaskField';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../../app/recoil';

const useStyles = makeStyles()((theme) => {
    return {
        fieldContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: theme.spacing(1),
            flexDirection: 'column',
            overflowX: 'hidden',
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                rowGap: '0.5rem',
                columnGap: '1.5rem',
            },
        },
    };
});

function RequirementSummary({ isPipeline = false }) {
    const { classes } = useStyles();
    const requirementSummary = useSelector((state) => state.requirementSummary);
    const { loading, releaseStage } = useFetchReleaseStageOfReq({
        requirementId: requirementSummary.requirementData?.requirementId,
    });
    const dispatch = useDispatch();
    const setAlert = useSetRecoilState(alertState);

    /**
     * Extract formatted domain name
     */
    const domain = useMemo(() => {
        if (!isPipeline) {
            return '';
        }

        const foundDomain = domainOptions.filter(
            (option) =>
                option.value === requirementSummary.requirementData.domain
        );

        if (foundDomain.length > 0) {
            return foundDomain[0].label;
        }

        return requirementSummary.requirementData.domain;
    }, [isPipeline, requirementSummary.requirementData.domain]);

    const handleAssignedToClick = async (user) => {
        try {
            dispatch(setLoadingStatus(true));
            const response = await axios.get(
                `/api/users/user-id-by-email?email=${user.email}`
            );
            const userId = response.data.userId;
            dispatch(fetchExpertById(userId));
        } catch (e) {
            console.log('Error: ', e);
            setAlert({
                show: true,
                message: 'Expert not found',
                severity: severity.ERROR,
            });
        } finally {
            dispatch(setLoadingStatus(false));
        }
    };

    return (
        <>
            <Box mt={2} className={classes.fieldContainer}>
                {/* --- ASSIGNED SMES --- */}
                {!isPipeline && (
                    <RequirementSummarySMEList
                        label="Assigned to"
                        smes={requirementSummary.requirementData.smes}
                        isNameClickable={true}
                        handleUserClick={handleAssignedToClick}
                    />
                )}

                {/* --- ENGAGEMENT MANAGERS --- */}
                {!isPipeline && (
                    <RequirementSummarySMEList
                        label="EMs"
                        smes={requirementSummary.requirementData.SMEengagements}
                    />
                )}

                {/* --- INSTRUCTIONAL DESIGNERS --- */}
                {!isPipeline && (
                    <RequirementSummarySMEList
                        smes={
                            requirementSummary.requirementData
                                .instructionalDesigners
                        }
                        label="IDs"
                    />
                )}

                {/* --- Operations Managers --- */}
                {!isPipeline && (
                    <RequirementSummarySMEList
                        smes={
                            requirementSummary.requirementData
                                .logisticsOperations
                        }
                        label="Ops"
                    />
                )}
            </Box>
            <Box mt={2} className={classes.fieldContainer}>
                {/* --- STATUS --- */}
                <RequirementSumamrySimpleField
                    label={'Status'}
                    value={
                        isPipeline &&
                        requirementSummary.requirementData.status === 'OPEN'
                            ? 'PLANNING'
                            : requirementSummary.requirementData.status
                    }
                />
                {/* --- FORMAT --- */}
                {isPipeline && (
                    <RequirementSumamrySimpleField
                        label={'Format'}
                        value={requirementSummary.requirementData.outlineFormat
                            .replace(/-/g, ' ')
                            .toUpperCase()}
                    />
                )}
                {/* --- DOMAIN --- */}
                {isPipeline && (
                    <RequirementSumamrySimpleField
                        label={'Domain'}
                        value={domain.toUpperCase()}
                    />
                )}
                {/* --- DOMAIN --- */}
                {isPipeline && (
                    <RequirementSumamrySimpleField
                        label={'Duration'}
                        value={`${requirementSummary.requirementData.hours} hr(s) - ${requirementSummary.requirementData.minutes} min(s)`}
                    />
                )}
                {/* --- RELEASE --- */}
                {!isPipeline && (
                    <RequirementSumamrySimpleField
                        label={'Release'}
                        value={releaseStage}
                        isLoading={loading}
                    />
                )}
                {/* --- CONTRACT NUMBER --- */}
                {!isPipeline && (
                    <RequirementSumamrySimpleField
                        label={'Contract number'}
                        value={
                            requirementSummary.requirementData
                                ?.contractNumber ?? 'N/A'
                        }
                    />
                )}
                {/* --- EXPECTED END DATE --- */}
                {!isPipeline && (
                    <RequirementSumamrySimpleField
                        label={'Delivery Date'}
                        value={moment(
                            requirementSummary.requirementData.expectedEndDate
                        ).format('DD-MMM-YYYY')}
                    />
                )}
                {/* --- TASK NAME --- */}
                {requirementSummary.isTaskColClicked && (
                    <RequirementSumamrySimpleField
                        label={'Task'}
                        value={requirementSummary.taskData.name}
                    />
                )}
                {/* --- TASK METRIC DATE --- */}
                {requirementSummary.isTaskColClicked && (
                    <RequirementSumamryTaskField
                        label={'Done/Due'}
                        value={moment(
                            requirementSummary.taskData.metricDate
                        ).format('YYYY-MM-DD')}
                    />
                )}
            </Box>
        </>
    );
}

export default RequirementSummary;
