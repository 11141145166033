import { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    input: {
        border: 0,
        outline: '1.5px solid #9ca3af',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        padding: '0.5rem',
        borderRadius: '0.25rem',
        width: '100%',
        flexShrink: 0,
        flexGrow: 1,
        '&:focus': {
            outline: '2px solid #f97316',
        },
        gridColumnStart: 1,
        gridColumnEnd: -1,
    },
}));

export default function LogisticTemplateOptions({
    selectedTemplateName = '',
    setSelectedTemplateName = () => {},
}) {
    const { classes } = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [logisticsTemplateOptions, setLogisticsTemplateOptions] = useState(
        []
    );

    const handleChange = (e) => {
        setSelectedTemplateName(e.target.value);
    };

    useEffect(() => {
        (async function () {
            try {
                setIsLoading(true);
                const {
                    data: { templates },
                } = await axios.get(
                    '/api/client-requirements/get-logistic-templates?all=true'
                );
                setLogisticsTemplateOptions(
                    templates.reduce((prev, curr) => {
                        prev = prev.concat({
                            label: curr.name,
                            value: curr._id,
                        });
                        return prev;
                    }, [])
                );
            } catch (e) {
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <select
            placeholder="Select logistics template name"
            className={classes.input}
            value={isLoading ? "" : selectedTemplateName}
            onChange={handleChange}
            required
            disabled={isLoading}
        >
            {isLoading && (
                <option value="" disabled>
                    <em>--- Loading ---</em>
                </option>
            )}

            {!isLoading && selectedTemplateName === '' && (
                <option value="" disabled>
                    <em>--- Select logistics template name ---</em>
                </option>
            )}

            {logisticsTemplateOptions.map((option) => {
                return (
                    <option value={option.label} key={option.value}>
                        {option.label}
                    </option>
                );
            })}
        </select>
    );
}
