import { Box } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    container: {
        width: '80vw',
        maxWidth: '1200px',
        height: 'fit-content',
        maxHeight: '70vh',
        overflow: 'hidden',
        backgroundColor: 'transparent',
    },
    img: {
        width: '100%',
        maxWidth: '1200px',
        objectFit: 'contain',
    },
}));

export const ImageViewer = ({ url, alt }) => {
    const { classes } = useStyles();

    return (
        <Box className={classes.container}>
            <img
                className={classes.img}
                src={url}
                alt={alt}
                height={500}
                width={500}
            />
        </Box>
    );
};
