import { makeStyles } from 'tss-react/mui';
import { red, green } from '@mui/material/colors';

export const useStyles = makeStyles()((theme) => ({
    // Global
    delColor: {
        color: red[500],
    },
    delButton: {
        backgroundColor: red[400],
        color: '#fafafa',
        '&:hover': {
            backgroundColor: red[800],
        },
    },
    validColor: {
        color: green[500],
    },
    validButton: {
        backgroundColor: green[400],
        '&:hover': {
            backgroundColor: green[800],
        },
    },
    datagrid: {
        // '& .MuiDataGrid-renderingZone': {
        //     maxHeight: 'none !important',
        // },
        '& .MuiDataGrid-cell': {
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal',
        },
        // '& .MuiDataGrid-row': {
        //     maxHeight: 'none !important',
        // },
        '&.MuiDataGrid-viewport, .MuiDataGrid-row, .MuiDataGrid-renderingZone ': {
            maxHeight: 'fit-content !important',
        },
        // '&.MuiDataGrid-cell': {
        //     maxHeight: 'fit-content !important',
        //     overflow: 'auto',
        //     maxHeight: 'inherit',
        //     whiteSpace: 'initial!important',
        //     lineHeight: '16px!important',
        //     display: 'flex!important',
        //     alignItems: 'center',
        //     paddingTop: '10px!important',
        //     paddingBottom: '10px!important',
        //     '&>div': {
        //         //this is for renderCell component wrapped in div
        //         maxHeight: 'inherit',
        //         padding: '10px 0',
        //         width: '100%',
        //         whiteSpace: 'initial',
        //         lineHeight: '16px',
        //     },
        // },
    },
    // Global
    row: {
        display: 'flex',
        flexFlow: 'row',
        marginLeft: 20,
        alignItems: 'center',
        '&:nth-child(1)': {
            marginLeft: 0,
        },
    },
    loading: {
        display: 'grid',
        placeItems: 'center',
        height: '50vh',
    },
    table: {
        minWidth: 650,
    },
    // userDetailsAccordian
    detailItem: {
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
    },
    detailItemLabel: {
        flexBasis: '30%',
        fontWeight: 600,
    },
    detailItemValues: {
        padding: '10px',
        borderLeft: '2px solid rgba(50,50,50,0.5)',
        flexBasis: '70%',
    },
    courseName: {
        fontSize: '0.9em',
        paddingBottom: '5px',
        borderBottom: '1px solid rgba(50,50,50,0.1)',
    },
    // edit info dialog
    fieldContainer: {
        marginBottom: '20px',
        display: 'flex',
        alignItems: 'center',
    },
    // reset password dialog
    emailLabel: {
        width: '300px',
    },
    // control card
    controlContainer: {
        width: '100%',
        padding: '10px',
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
    },
    averageActiveUsersControlCard: {
        display: 'flex',
        flexFlow: 'column',
    },
    rightBorder: {
        position: 'relative',
        marginRight: '20px',
        '&:before': {
            position: 'absolute',
            content: '""',
            top: '50%',
            right: '-10px',
            height: '50%',
            width: '2.5px',
            transform: 'translateY(-50%)',
            backgroundColor: '#c5c5c5',
        },
    },
    dateControlCard: {
        display: 'flex',
        width: 'fit-content',
        marginLeft: 'auto',
        marginRight: '20px',
    },
    // details card reports
    card: {
        width: '90%',
    },
    cardHeading: {
        fontSize: '1.3em',
        fontWeight: 600,
    },
    cardSubHeading: {
        fontSize: '1em',
        fontWeight: 500,
    },
    cardBody: {
        fontSize: '0.8em',
        fontWeight: 400,
    },
    cardUsersList: {
        overflowY: 'auto',
        maxHeight: '350px',
        padding: '10px',
        position: 'relative',
    },
    exportIcon: {
        top: 0,
        right: 0,
    },
    // chart
    chartContainer: {
        padding: '10px 15px',
    },
    // add team container
    addTeamContainer: {
        width: 'min(90vw, 800px)',
    },
}));
