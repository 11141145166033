import axios from 'axios';
import { urlBase64ToUint8Array } from './urlBase64ToUint8Array';

export const subscribeUser = async () => {
    try {
        const registration = await navigator.serviceWorker.register(
            '/push-notifications-sw.js'
        );
        const existingSubscription = await registration.pushManager.getSubscription();

        if (!existingSubscription) {
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(
                    process.env.REACT_APP_PUBLIC_VAPID_KEY
                ),
            });

            await axios.post('/api/users/subscribe', {
                subscription,
            });
            console.log('User subscribed for push notifications');
        } else {
            console.log('User is already subscribed for push notifications');
        }
    } catch (error) {
        console.error('Failed to subscribe user: ', error);
    }
};
