import React from 'react';

import { useNavigate } from 'react-router-dom';

import { useChat } from './useChat';

import { Container, Grid, Loading } from '../ui';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { makeStyles } from 'tss-react/mui';
import { ChatIcon } from '../icons';
import { ChatUsers } from './ChatUsers';
import { ChatMessages } from './ChatMessages';
import { useMediaQuery, useTheme } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
    root: {
        // minHeight: 1000,
        // height: '60vh',
        // marginTop: 100,
        // backgroundColor: theme.palette.background.default,
        paddingInline: '20px',
        [theme.breakpoints.down('sm')]: {
            paddingInline: 0,
        },
    },
    main: {
        marginBlock: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    },
    messageContainer: {
        // backgroundColor: '#e9ecef',
        // borderRadius: '0 8px 8px 0',
        // [theme.breakpoints.down('lg')]: {
        //     borderRadius: '0 0 8px 8px',
        // },
        overflow: 'hidden',
        // padding: '10px',
    },
}));

export const Chat = ({ socket, docked }) => {
    const { classes } = useStyles();
    // let navigate = useNavigate();
    const theme = useTheme();
    const isXLDevice = useMediaQuery(theme.breakpoints.up('xl'));
    const isLGDevice = useMediaQuery(theme.breakpoints.up('lg'));
    const isSMDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const { user, isLoading: isUserLoading } = useCurrentUser();

    const {
        chats,
        // usersById,
        selectedChatId,
        openedChats,
        isLoadingChats,
        userByIdUpdated,
        sendMessage,
        seenMessage,
        selectChat,
        getUserChat,
        minimized,
        toggleMinimized,
        toggleChatMinimized,
        closeChat,
        openChat,
        totalUnreadMessages,
        numOfUnReadMessagesByChat,
        showChatUsersOnMobile,
        setShowChatUsersOnMobile,
    } = useChat(socket, user, docked, isXLDevice, isLGDevice);

    // if (!socket) return <Loading />;
    if (isLoadingChats || isUserLoading) return docked ? null : <Loading />;
    else if (!user) return docked ? null : <Loading />;

    return (
        <Container disableGutters className={classes.root} maxWidth="xl">
            {!docked && (
                <Grid container className={classes.main}>
                    <Grid item xs={12} sm={3} borderRight={'1px solid #d1d5db'}>
                        {!isSMDevice || showChatUsersOnMobile ? (
                            <ChatUsers
                                chats={chats}
                                // selectedChatIndex={selectedChatIndex}
                                selectedChatId={selectedChatId}
                                user={user}
                                selectChat={selectChat}
                                userByIdUpdated={userByIdUpdated}
                                getUserChat={getUserChat}
                                totalUnreadMessages={totalUnreadMessages}
                                numOfUnReadMessagesByChat={
                                    numOfUnReadMessagesByChat
                                }
                                height={'85vh'}
                            />
                        ) : null}
                    </Grid>
                    <Grid
                        className={classes.messageContainer}
                        item
                        xs={12}
                        sm={9}
                    >
                        {selectedChatId && (
                            <>
                                {!isSMDevice || !showChatUsersOnMobile ? (
                                    <ChatMessages
                                        // ref={{ messagesEl, messageInputEl }}
                                        chats={chats}
                                        // selectedChatIndex={selectedChatIndex}
                                        chatId={selectedChatId}
                                        user={user}
                                        userByIdUpdated={userByIdUpdated}
                                        sendMessage={sendMessage}
                                        seenMessage={seenMessage}
                                        numOfUnReadMessagesByChat={
                                            numOfUnReadMessagesByChat
                                        }
                                        height={'85vh'}
                                        // messageInput={messageInput}
                                        // setMessageInput={setMessageInput}
                                        setShowChatUsersOnMobile={
                                            setShowChatUsersOnMobile
                                        }
                                    />
                                ) : null}
                            </>
                        )}
                    </Grid>
                </Grid>
            )}

            {docked && !isSMDevice && (
                <>
                    <ChatUsers
                        chats={chats}
                        // selectedChatIndex={selectedChatIndex}
                        docked
                        minimized={minimized}
                        selectedChatId={selectedChatId}
                        openChat={openChat}
                        user={user}
                        toggleMinimized={toggleMinimized}
                        selectChat={selectChat}
                        userByIdUpdated={userByIdUpdated}
                        getUserChat={getUserChat}
                        totalUnreadMessages={totalUnreadMessages}
                        numOfUnReadMessagesByChat={numOfUnReadMessagesByChat}
                    />
                    {openedChats.map(({ id, minimized }, index) => (
                        <ChatMessages
                            key={id}
                            chats={chats}
                            docked
                            minimized={minimized}
                            toggleChatMinimized={toggleChatMinimized}
                            closeChat={closeChat}
                            chatId={id}
                            openedChatIndex={index}
                            user={user}
                            userByIdUpdated={userByIdUpdated}
                            sendMessage={sendMessage}
                            seenMessage={seenMessage}
                            numOfUnReadMessagesByChat={
                                numOfUnReadMessagesByChat
                            }
                        />
                    ))}
                </>
            )}
        </Container>
    );
};
