import { atom } from 'recoil';

// to add/remove user in a team
export const EDIT_OPTION_ADD = 'ADD';
export const EDIT_OPTION_REMOVE = 'REMOVE';
export const EDIT_OPTION_REMOVE_MANAGER = 'REMOVE_MANAGER';

export const usersState = atom({
    key: 'usersState',
    default: [],
});

export const new_usersState = atom({
    key: 'new_usersState',
    default: [],
});
export const allUserObjectState = atom({
    key: 'allUserObjectState',
    default: [],
});

export const userObjectState = atom({
    key: 'userObjectState',
    default: {},
});

export const allBundleState = atom({
    key: 'allBundleState',
    default: null,
});

export const userAllTeamsState = atom({
    key: 'userAllTeamsState',
    default: [],
});

export const editTeamState = atom({
    key: 'editTeamState',
    default: {
        editTeam: false,
        optionType: '',
        id: null,
    },
});

export const clientDetailsState = atom({
    key: 'clientDetailsState',
    default: {},
});

export const disabledUsersState = atom({
    key: 'disabledUsersState',
    default: [],
});

export const userProfilerAnswersState = atom({
    key: 'userProfilerAnswersState',
    default: [],
});

export const mostEnrolledCourseStateState = atom({
    key: 'mostEnrolledCourseStateState',
    default: '',
});

export const selectedUsersForReportState = atom({
    key: 'selectedUsersForReportState',
    default: [],
    // default: ['60e748bbbac6be31340b417c', '60eedfe746bf493538b33e7e'],
});

// for ech new editable info add a state
// edit user info states
export const userPlanState = atom({
    key: 'userPlanState',
    default: {
        show: false,
        accountType: 'VOYAGER',
        expirationDate: new Date(),
    },
});

export const userCreditsInfoState = atom({
    key: 'userCreditsInfoState',
    default: {
        show: false,
        creditsChangeType: 'ADD',
        amount: 0,
    },
});

export const userTeamState = atom({
    key: 'userTeamState',
    default: {
        show: false,
        teamId: '',
    },
});
// edit user info states

export const instructorsState = atom({
    key: 'instructorsState',
    default: [],
});

export const disabledInstructorsState = atom({
    key: 'disabledInstructorsState',
    default: [],
});

// content states
export const contentToAssignState = atom({
    key: 'contentToAssignState',
    default: [],
});

export const instructorToShowState = atom({
    key: 'instructorToShowState',
    default: {},
});

export const allTADsState = atom({
    key: 'allTADsState',
    default: [],
});

export const feedToReplyToState = atom({
    key: 'feedToReplyToState',
    default: null,
});
