import React from 'react';
import { Box, Grid, Skeleton, Typography } from '../ui';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    rootStyle: {
        width: '100%',
        fontSize: 'min(1.2em, 4.5vw)',
        fontWeight: 'bold',
        marginBottom: '10px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    mainBoxWrap: {
        position: 'relative',
        minHeight: 300,
        [theme.breakpoints.down('lg')]: {
            height: 'auto',
            minHeight: 'auto',
        },
    },
    mainBox: {
        width: '100%',
        borderRadius: '8px',
        //marginLeft: '20px',
        //marginRight: '20px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 13px 2px #AAA8A8',
        marginTop: '10px',
        position: 'relative',
        '&:hover': {
            position: 'absolute',
            zIndex: 1000,
            transition: 'all 0.3s ease-out',
            transform: 'scale(1.05)',
            '& $rootStyle': {
                whiteSpace: 'normal',
            },
            '& $desc': {
                visibility: 'visible',
                display: 'block',
                backgroundColor: '#ffffff',
            },
        },
    },
    desc: {
        display: 'none',
        padding: 8,
        fontSize: '16px',
    },
    root: {
        backgroundColor: '#ddd',
        // backgroundPosition: 'center',
        backgroundSize: '100% 100%',
        // backgroundRepeat: 'no-repeat',
        borderRadius: '6px 6px 0 0px',
        minHeight: '175px',
        width: '100%',
    },
    contentBox: {
        width: '100%',
        margin: '0 auto',
        padding: 16,
        transition: 'all 0.3s ease-in',
        minHeight: '30px',
    },
    btn: {
        position: 'absolute',
        top: '0px',
        right: '0px',
    },
}));

export const CoursesListItem = ({
    xs,
    md,
    lg,
    sm,
    onClickCourse,
    slug,
    thumbnail,
    title,
    instructorName,
    level,
    description,
    duration,
    prodId,
}) => {
    const { classes } = useStyles();

    return (
        <Grid
            style={{ cursor: 'pointer' }}
            item
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
        >
            <Box className={classes.mainBoxWrap}>
                <Box
                    onClick={() => onClickCourse(slug, title, prodId)}
                    className={classes.mainBox}
                >
                    <Box mx="auto">
                        <img
                            className={classes.root}
                            src={thumbnail}
                            alt="starweaver logo"
                        />
                    </Box>

                    <Box component="div" className={classes.contentBox}>
                        <>
                            <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.rootStyle}
                            >
                                {title}
                            </Typography>
                            {instructorName && level && (
                                <Typography
                                    style={{
                                        color: '#BCBCBC',
                                        fontWeight: 500,
                                    }}
                                    variant="body2"
                                >
                                    {' '}
                                    {instructorName} | {level} | {duration}
                                    {/*  |{' '}
                                    {courseDuration
                                        ? Math.floor(courseDuration)
                                        : null}{' '}
                                    mins */}
                                </Typography>
                            )}
                        </>

                        <p className={classes.desc}>{description}</p>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
};
