import { Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    title: {
        fontSize: '22px',
        fontWeight: 'bold',
    },
}));

export const SectionTitle = ({ title, styles, ...props }) => {
    const { classes } = useStyles();

    return (
        <Typography
            variant="h4"
            style={{ ...styles }}
            className={`${classes.title}`}
            {...props}
        >
            {title}
        </Typography>
    );
};
