import { Box, CircularProgress } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export const Loading = ({ height = '90vh', size = '2.5em' }) => {
    const { classes } = useStyles();
    return (
        <Box className={classes.loading} height={height}>
            <CircularProgress size={size} color="secondary" />
        </Box>
    );
};

const useStyles = makeStyles()((theme) => ({
    loading: {
        display: 'grid',
        placeItems: 'center',
    },
}));
