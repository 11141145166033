export const TECHBYTE_BACKGROUND_IMAGE = `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-content/uploads/2020/04/bill-gates-strip.v1-3200x1000.png`;
export const ORGANISATION_PAGE_IMAGE_ONE_URL = `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-content/uploads/2020/08/rectangle-2@2x.jpg`;
export const ORGANISATION_PAGE_IMAGE_TWO_URL = `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-content/uploads/2020/08/rectangle-1@2x.jpg`;
export const CATEGORY_AGILITY_URL =
    'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Category%20Images%202%2FAgility-Devops-min.png?alt=media&token=f88c9fa6-0912-4316-a9e1-b36460da0ad2';
export const CATEGORY_BUSINESS_URL =
    'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Category%20Images%202%2FBusiness-min.png?alt=media&token=5bd619b3-ed07-401a-a2a7-337a9e88b24e';
export const CATEGORY_CYBERSECURITY_URL =
    'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Category%20Images%202%2FCyberSecurity.png?alt=media&token=0407831f-1abb-422a-9e58-339f471a8e18';
export const CATEGORY_CLOUD_URL =
    'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Category%20Images%202%2FCloud-min.png?alt=media&token=9e2965f7-995f-4efa-a44d-13a480e50ec9';
export const CATEGORY_DATA_SCIENCE_URL =
    'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Category%20Images%202%2FData%20Science-min.png?alt=media&token=79f19d6a-1066-40c2-ab21-c4d5822212e7';
export const CATEGORY_FINANCE_URL =
    'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Category%20Images%202%2FFinance.png?alt=media&token=c3c20812-a935-450e-8a43-f2fc01d03bc2';
export const CATEGORY_FULL_STACK_URL =
    'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Category%20Images%202%2FFull%20stack-min.png?alt=media&token=8d433b5a-4fce-4c08-a69c-680bedbd382b';
export const CATEGORY_CERTIFICATION_BANNER_URL =
    'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Category%20Images%202%2Fcertification-min.png?alt=media&token=8c9e5e9b-bf83-4a2c-a54b-f7ca4a892aa6';
export const CATEGORY_TEST_PREP_BANNER_URL =
    'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Category%20Images%202%2Ftest-prep-min.png?alt=media&token=fc91c69f-493e-4da2-be6d-1093cd04e82c';
export const CATEGORY_BANNER_URL =
    'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Category%20Images%202%2FBanner.jpg?alt=media&token=fd1e86b2-5918-47f6-b86a-6b0553d54326';
export const CATEGORY_JOUNEY_BANNER_URL =
    'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Category%20Images%202%2Fjourney.png?alt=media&token=d0a24ae9-26ce-4d11-9bcc-dc10a2f2df89';
