import { Box, Typography } from '@mui/material';
import FileItem from './FileItem';
import useStyles from './styles';

function SelectedFiles({ files, handleRemoveSelectedFile }) {
    const { classes } = useStyles();

    return (
        <Box className={classes.selectedFiles}>
            <Typography variant="body1">Selected Files</Typography>
            <ul className={classes.selectedFiles__items}>
                {Array.from(files).map((file, index) => (
                    <FileItem
                        key={file.name}
                        file={file}
                        index={index}
                        handleRemoveSelectedFile={handleRemoveSelectedFile}
                    />
                ))}
            </ul>
        </Box>
    );
}

export default SelectedFiles;
