import { Box, Typography, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const useStyles = makeStyles()((theme) => ({
    card: {
        backgroundColor: '#fff',
        boxShadow:
            '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
        borderRadius: '0.25rem',
        padding: '10px 30px',
        textAlign: 'center',
        cursor: 'pointer',
        border: '2px solid #fff',
        transition: 'border 150ms ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Ensures equal spacing
        alignItems: 'center',
        minHeight: '260px', // Set a minimum height
        flexGrow: 1, // Ensures all cards stretch to the same height
        marginBottom: '1rem',
        [theme.breakpoints.up('md')]: {
            marginBottom: '0',
        },
        '&:hover': {
            border: '2px solid #d1d5db',
        },
    },
    card__imgContainer: {
        border: '2px solid #f8f8f8',
        maxWidth: '6rem',
        width: '100%',
        height: 'auto',
        aspectRatio: 1,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginInline: 'auto',
        overflow: 'hidden',
    },
    card__img: {
        width: '100%',
        height: 'auto',
        aspectRatio: 1,
        objectFit: 'cover',
    },
    card__name: {
        textTransform: 'capitalize',
        fontWeight: '500',
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        marginTop: '1rem',
    },
    card__role: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        minHeight: '48px', // Ensures roles take the same height
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    card__socials: {
        marginTop: '10px', // Pushes to the bottom
        display: 'flex',
        justifyContent: 'center',
        gap: '0.5rem',
    },
    infoText: {
        marginTop: '0.5rem',
        fontSize: '0.75rem',
        color: '#6b7280',
    },
}));

export const ManagersCard = ({
    name = '',
    imgUrl = '',
    designation = '',
    number = '',
    linkedIn = '',
    email = '',
    workHours = '',
}) => {
    const { classes } = useStyles();

    return (
        <Box className={classes.card}>
            <div className={classes.card__imgContainer}>
                <a
                    href={`https://wa.me/${number.replace(/[^0-9]/g, '')}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: 'block', height: '100%' }}
                >
                    <img
                        className={classes.card__img}
                        src={imgUrl}
                        alt={name}
                        loading="lazy"
                    />
                </a>
            </div>

            <Typography className={classes.card__name}>{name}</Typography>
            <Typography className={classes.card__role}>
                {designation}
            </Typography>

            <div className={classes.card__socials}>
                <a
                    href={`https://wa.me/${number.replace(/[^0-9]/g, '')}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <IconButton title={number}>
                        <WhatsAppIcon color="primary" />
                    </IconButton>
                </a>

                {linkedIn?.length > 0 && (
                    <a href={linkedIn} target="_blank" rel="noreferrer">
                        <IconButton title="LinkedIn">
                            <LinkedInIcon color="primary" />
                        </IconButton>
                    </a>
                )}

                <a href={`mailto:${email}`}>
                    <IconButton title={email}>
                        <MailIcon color="primary" />
                    </IconButton>
                </a>

                <IconButton title={workHours}>
                    <AccessTimeIcon color="primary" />
                </IconButton>
            </div>

            {/* <Typography className={classes.infoText}>
                To message me, click on my photo
            </Typography> */}
        </Box>
    );
};
