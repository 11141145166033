import { coursesSlice } from '../courses/coursesSlice';
import { useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { useCurrentUser } from '../my-account/useCurrentUser';
import axios from 'axios';
import {
    getPurchasedCourseState,
    setPurchasedCourseState,
} from './Recoil/Selector';
import { purchasedCourseFetchedForUserState } from './Recoil/State';

const { getCourses } = coursesSlice;

const getAllCourse = () => (state) => {
    // const courses = getCourses(state);
    // return courses;

    return [];
};

export const useGetPurchasedCourse = () => {
    const course = useSelector(getAllCourse());
    const { user } = useCurrentUser();
    const purchasedCourses = useRecoilValue(getPurchasedCourseState);
    const setPurchasedCourses = useSetRecoilState(setPurchasedCourseState);
    const [
        purchasedCourseFetchedForUser,
        setPurchasedCourseFetchedForUser,
    ] = useRecoilState(purchasedCourseFetchedForUserState);
    const [purchasedOnDemand, setPurchasedOnDemand] = useState([]);
    const [purchasedCertifications, setPurchasedCertifications] = useState([]);
    const fetching = useRef(false);

    useEffect(() => {
        let cancel = () => {};
        // if (purchasedCourses.length === 0) {
        const getPurchasedCourse = async () => {
            fetching.current = true;
            const {
                data: { products },
            } = await axios.get(
                `/api/users/${user._id}/purchased-on-demand?testquery3=test`,
                { cancelToken: new axios.CancelToken((c) => (cancel = c)) }
            );
            let purchasedItems = [];
            if (products.length > 0) {
                products.forEach((product) => {
                    for (let i = 0; i < course.length; i++) {
                        if (course[i].id == product.product_id) {
                            let purchasedObject = { ...course[i] };
                            purchasedObject.showUnEnroll = false;
                            purchasedItems.push(purchasedObject);
                            break;
                        }
                    }
                });
            }
            setPurchasedCourseFetchedForUser(user._id);
            setPurchasedCourses(purchasedItems);
            fetching.current = false;
        };
        if (
            !fetching.current &&
            user &&
            purchasedCourseFetchedForUser !== user._id
        ) {
            getPurchasedCourse();
        }
        // }
        return () => cancel();
    }, [user]);

    useEffect(() => {
        if (purchasedCourses) {
            const onDemand = [];
            const certification = [];
            purchasedCourses.forEach((course) => {
                if (course.collection_ids.includes(248139)) {
                    certification.push(course);
                } else {
                    onDemand.push(course);
                }
            });
            setPurchasedOnDemand(onDemand);
            setPurchasedCertifications(certification);
        }
    }, [purchasedCourses]);

    return { purchasedCourses, purchasedCertifications, purchasedOnDemand };
};
