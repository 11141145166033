import { Typography } from '../ui';
import { makeStyles } from 'tss-react/mui';

export const Heading = ({ title, subtitle, body }) => {
    const { classes } = useStyles();

    return (
        <div className={classes.container}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.subtitle}>{subtitle}</Typography>
            <Typography className={classes.body}>{body}</Typography>
        </div>
    );
};
const useStyles = makeStyles()((theme) => ({
    container: {
        margin: '50px 0',
    },
    title: {
        fontSize: 'min(2.5em, 9vw)',
        margin: '10px 0',
        fontWeight: 600,
    },
    subtitle: {
        margin: '10px 0',
        fontSize: 'min(1.5em, 6vw)',
        textTransform: 'capitalize',
        position: 'relative',
        '&::before': {
            position: 'absolute',
            content: '""',
            left: 0,
            bottom: '-3px',
            height: '3px',
            borderRadius: '5px',
            width: 'min(200px, 19vw)',
            backgroundColor: '#ea6f14',
        },
    },
    body: {
        margin: '10px 0',
        fontSize: 'min(1.1em, 5vw)',
        color: '#333',
    },
}));
