import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '../ui';
import { useState } from 'react';
import { useStyles } from './styles';

export const ReportBox = ({
    openReport,
    reportResponse = async () => console.log('default-report'),
}) => {
    const { classes } = useStyles();
    const [comment, setComment] = useState('');
    const [reason, setReason] = useState(radioGroupContent[0].label);

    const report = async () => {
        try {
            await reportResponse({ reason, reportText: comment });
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <Box
            className={`${classes.replies} ${
                openReport ? classes.openReplies : classes.closeReplies
            }`}
        >
            <Typography variant="h6">Report response</Typography>
            <Box ml={2}>
                <Box mt={2}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            defaultValue={reason}
                            onChange={(e) => setReason(e.target.value)}
                        >
                            {radioGroupContent.map(({ label }) => (
                                <FormControlLabel
                                    key={label}
                                    value={label}
                                    control={<Radio size="small" />}
                                    label={label}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box mt={2}>
                    <TextField
                        placeholder="Your comment on the report"
                        fullWidth
                        margin={'dense'}
                        multiline={true}
                        rows={3}
                        label="Report"
                        variant="outlined"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        color="secondary"
                    />
                </Box>
                <Box mt={2}>
                    <Button
                        onClick={report}
                        color="primary"
                        variant="contained"
                    >
                        Report
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

const radioGroupContent = [
    {
        label: 'Spam',
    },
    {
        label: 'Harassment',
    },
    {
        label: 'Rules Violation',
    },
];
