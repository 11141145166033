import { useEffect, useState, useCallback } from 'react';
import { Box, Modal, Fade, Backdrop, Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AzureBlobStorage } from '../util/BlobStorage';
import { ImageViewer } from './ImageViewer';
import { useMemo } from 'react';
import { VideoPlayer } from './VideoPlayer';

const useStyles = makeStyles()((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    previewModalPaper: {
        backgroundColor: 'transparent',
    },
}));

export const PreviewModal = ({
    showPreviewModal,
    setShowPreviewModal,
    currentFile,
    containerName,
}) => {
    const { classes } = useStyles();
    const [url, setURL] = useState('');

    // ------------- HOOK START --------------------
    const fetchBlobURL = useCallback(async () => {
        try {
            if (Object.keys(currentFile).length === 0 || !currentFile?.actual)
                return null;

            const azureBlobStorage = new AzureBlobStorage();
            const url = await azureBlobStorage.getBlobURL(
                containerName,
                currentFile.actual
            );

            return url;
        } catch (e) {
            return null;
        }
    }, [containerName, currentFile]);

    useEffect(() => {
        fetchBlobURL()
            .then((fetchedURL) => {
                setURL(fetchedURL);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [fetchBlobURL]);

    useEffect(() => {
        if (url.length === 0) return;
        if (currentFile?.type === 'PDF') {
            window.open(url, '_blank');
            setShowPreviewModal(false);
        }
    }, [currentFile.type, setShowPreviewModal, url]);

    const showImageViewer = useMemo(() => {
        if (
            Object.keys(currentFile).length > 0 &&
            currentFile.type === 'IMAGE' &&
            url.length > 0
        ) {
            return true;
        } else {
            return false;
        }
    }, [currentFile, url.length]);

    const showVideoPlayer = useMemo(() => {
        if (
            Object.keys(currentFile).length > 0 &&
            currentFile.type === 'VIDEO' &&
            url.length > 0
        ) {
            return true;
        } else {
            return false;
        }
    }, [currentFile, url.length]);

    // ------------- HOOK END --------------------

    const handleClose = () => {
        setURL('');
        setShowPreviewModal(false);
    };

    const handlePaperClick = () => {
        if (currentFile?.type !== 'IMAGE') return;
        handleClose();
    };

    return (
        <Box>
            <Modal
                open={showPreviewModal}
                className={classes.modal}
                // BackdropComponent={Backdrop}
                // BackdropProps={{ timeout: 200 }}
                aria-labelledby="preview-modal"
                aria-describedby="preview-modal-desc"
                closeAfterTransition
                onClose={handleClose}
            >
                <Fade in={showPreviewModal}>
                    <Paper
                        elevation={0}
                        className={classes.previewModalPaper}
                        onClick={handlePaperClick}
                    >
                        {showImageViewer && showPreviewModal && (
                            <ImageViewer url={url} alt={currentFile.actual} />
                        )}

                        {showVideoPlayer && showPreviewModal && (
                            <VideoPlayer
                                blobURL={url}
                                title={currentFile.formatted}
                            />
                        )}
                    </Paper>
                </Fade>
            </Modal>
        </Box>
    );
};
