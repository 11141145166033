function generateRandomMongoId(txt) {
    const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
    const machineId = Math.floor(Math.random() * 16777216).toString(16);
    const processId = Math.floor(Math.random() * 65536).toString(16);
    const counter = Math.floor(Math.random() * 16777216).toString(16);
    return txt
        ? txt + '-' + timestamp + machineId + processId + counter
        : timestamp + machineId + processId + counter;
}

export { generateRandomMongoId };
