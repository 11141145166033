import axios from 'axios';
import { useState, useCallback } from 'react';

function useFetchTaskCategoriesByCronology() {
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [taskCategories, setTaskCategories] = useState([]);

    const fetchTaskCategoriesByChronology = useCallback(
        async (requirementId, filterBy = '') => {
            try {
                let api = `/api/client-requirements/task-categories-by-chronology`;
                const params = new URLSearchParams();
                params.append('requirementId', requirementId);

                if (filterBy) {
                    params.append('filterBy', filterBy);
                }

                api = `${api}?${params.toString()}`;
                setLoading(true);
                const axiosRes = await axios.get(api);
                setTaskCategories(axiosRes.data.taskCategories);
            } catch (e) {
                setIsError(true);
                console.log(e);
            } finally {
                setLoading(false);
            }
        },
        []
    );

    return {
        loading,
        isError,
        fetchTaskCategoriesByChronology,
        taskCategories,
    };
}

export default useFetchTaskCategoriesByCronology;
