import { MenuItem } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { alertState, severity } from '../app/recoil';

const useStyles = makeStyles()((theme) => ({
    item: {
        display: 'flex',
        textAlign: 'left',
        flexDirection: 'column',
    },
    durationText: {
        fontSize: '0.75rem',
    },
}));

export default function RemindMeOption({
    handleRemind,
    commentId,
    handleClose,
}) {
    const { classes } = useStyles();
    const params = useParams();
    const { requirementData } = useSelector(
        (state) => state.requirementSummary
    );
    const [reminder, setReminder] = useState(null);
    const [loading, setLoading] = useState(false);
    const setAlert = useSetRecoilState(alertState);

    useEffect(() => {
        const requirementId =
            params?.requirementId || requirementData?.requirementId;

        if (!requirementId) return;

        const baseURL = '/api/content/get-comment-reminder';
        const searchParams = new URLSearchParams({
            commentId,
            requirementId,
        });
        const url = `${baseURL}?${searchParams.toString()}`;
        setLoading(true);
        axios
            .get(url)
            .then((res) => {
                setReminder(res.data.reminder);
            })
            .catch(console.error)
            .finally(() => {
                setLoading(false);
            });
    }, [commentId, params?.requirementId, requirementData?.requirementId]);

    const isActiveReminder = useMemo(() => {
        if (!reminder) return false;
        const { scheduledAt } = reminder;
        return moment(scheduledAt).isSameOrAfter(Date.now());
    }, [reminder]);

    const handleRemove = async () => {
        try {
            const requirementId =
                params?.requirementId || requirementData?.requirementId;
            if (!requirementId) {
                throw new Error('Requirement id is missing');
            }
            setLoading(true);
            const baseURL = '/api/content/remove-comment-reminder';
            const searchParams = new URLSearchParams({
                commentId,
                requirementId,
            });
            const url = `${baseURL}?${searchParams.toString()}`;
            await axios.delete(url);
            setAlert({
                show: true,
                message: 'Reminder removed successfully',
                severity: severity.SUCCESS,
            });
        } catch (e) {
            console.log(e);
        } finally {
            handleClose();
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <MenuItem disabled>
                <span style={{ width: '150px', display: 'block' }}>
                    Loading...
                </span>
            </MenuItem>
        );
    }

    if (!reminder || !isActiveReminder) {
        return <MenuItem onClick={handleRemind}>Remind Me</MenuItem>;
    }

    return (
        <MenuItem className={classes.item} onClick={handleRemove}>
            <span>Remove reminder</span>
            <span className={classes.durationText}>
                ({moment(reminder.scheduledAt).format('DD-MMM-YYYY hh:mm:ss')})
            </span>
        </MenuItem>
    );
}
