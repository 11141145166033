import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
//import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../auth/useUser';
import { makeStyles } from 'tss-react/mui';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Hero } from '../ui/CustomUI';
import CoursesList from './CoursesList';
import { PrivateCoursesList } from './PrivateCoursesList';
//import { CoursesPageTabPanel } from './CoursesPageTabPanel';
import { coursesSlice } from './coursesSlice';
import { getCoursesForCollection } from './getCoursesForCollection';
import { coursesRoute as route } from '../seo/routeMetaData';
import { HelmetWrapper } from '../seo';
import axios from 'axios';

const { getCoursesIsLoading } = coursesSlice;

const useStyles = makeStyles()((theme) => ({
    root: {
        // flexGrow: 1,
        backgroundColor: theme.palette.background.default,
    },
    appBar: {
        boxShadow: 'none',
    },
}));

const collectionNamesAndIds = {
    'Data Science': '275409',
    Cloud: '275408',
    'Full Stack': '297604',
    Cybersecurity: '505305',
    // Agility: '272140',
    // DevOps: '505306',
    'DevOps/Agility': '652452',
    'Full Stack': '297604',
    //Networking: '495816',
    //'UI/UX': '495724',
    Finance: '247494',
    //Business: '505308',
    Business: '652467',
};

//Keys should be in same order as collectionNamesAndIds
const collectionNamesAndSlugs = {
    'Data Science': 'data-science',
    Cloud: 'cloud',
    'Full Stack': 'full-stack',
    Cybersecurity: 'cybersecurity',
    // Agility: 'agility',
    // DevOps: 'devops',
    'DevOps/Agility': 'devops-agility',
    //Networking: 'networking',
    //'UI/UX': 'ui-ux',
    Finance: 'finance',
    Business: 'business',
};

export const privateEmailDomainsAndBundleNames = {
    'tcs.com': 'TCS',
    'thehartford.com': 'Hartford',
    'gep.com': 'GEP',
    'att.com': 'ATT',
    'cognizant.com': 'Cognizant',
};

const CoursesPage = () => {
    //const { domain } = useQueryParams();
    let navigate = useNavigate();
    const location = useLocation();
    //const [richPresssence, setRichPressence] = useState(true);

    const path = location.pathname;
    const pathLastPart = /\/([^\/]+)\/?$/.exec(path)[1];

    const initialTabSlug =
        pathLastPart === 'courses' ? 'data-science' : pathLastPart;

    let initialTabIndex = 0;

    if (initialTabSlug === 'coming-soon')
        initialTabIndex = Object.keys(collectionNamesAndIds).length;
    else if (initialTabSlug === 'private-classes')
        initialTabIndex = Object.keys(collectionNamesAndIds).length + 1;
    else
        initialTabIndex = Object.values(collectionNamesAndSlugs).findIndex(
            (slug) => slug === initialTabSlug
        );

    const { user, isLoading: isUserLoading } = useUser();
    const userEmailDomain =
        !isUserLoading && user ? user.email.split('@').pop() : null;
    const [currentTabIndex, setCurrentTabIndex] = useState(initialTabIndex);
    const [selectedCollectionId, setSelectedCollectionId] = useState(
        Object.values(collectionNamesAndIds)[initialTabIndex]
    );
    const { classes } = useStyles();
    const courses = useSelector(getCoursesForCollection(selectedCollectionId));
    const isLoadingAllCourses = useSelector(getCoursesIsLoading);

    //const [isLoading, setIsLoading] = useState(false);

    const [isHoursEnabled, setisHoursEnabled] = useState(true);

    var courseMinDetail = [{}];
    var [courseHours, setCourseHours] = useState(() => new Map());
    var [courseMin, setCourseMin] = useState(() => new Map());
    useEffect(() => {
        getCourseDomainDetail(
            Object.values(collectionNamesAndSlugs)[currentTabIndex]
        );
    }, []);

    useEffect(() => {
        if (courseHours.length != undefined) {
            setisHoursEnabled(false);
        }
    }, [isHoursEnabled]);

    // const handleSwitchChange = () => {
    //     //setRichPressence((prevState) => !prevState);
    //     if (currentTabIndex < Object.keys(collectionNamesAndIds).length)
    //         navigate((
    //             `/category/${
    //                 Object.values(collectionNamesAndSlugs)[currentTabIndex]
    //             }/courses`
    //         ));
    // };

    const getCourseDomainDetail = async (domainName) => {
        //console.log(courses);
        setisHoursEnabled(true);

        //setIsLoading(true);
        // const res = await axios.get(
        //     `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/catalogue/lengths?domain=${domainName}`
        // );

        const res = await axios.get(
            `/api/courses?domain=${domainName}&thinkific=1&type=ON-DEMAND`
        );

        setCourseHours(
            new Map(
                res.data.courses.map((key) => [
                    key.thinkificCourseId,
                    key.hours,
                ])
            )
        );
        setCourseMin(
            new Map(
                res.data.courses.map((key) => [
                    key.thinkificCourseId,
                    key.minutes,
                ])
            )
        );

        setisHoursEnabled(false);
        //setIsLoading(false);
    };

    const handleChange = (event, newValue) => {
        setCurrentTabIndex(newValue);
        if (newValue < Object.keys(collectionNamesAndIds).length) {
            const domain = Object.values(collectionNamesAndSlugs)[newValue];

            navigate(`/courses/${domain}`);

            setSelectedCollectionId(
                Object.values(collectionNamesAndIds)[newValue]
            );
            getCourseDomainDetail(
                Object.values(collectionNamesAndSlugs)[newValue]
            );
        } else navigate(`/courses/private-classes`);
    };

    //return <Navigate to="/category/all/courses"  replace />;
    return (
        <>
            <HelmetWrapper route={route} />
            <div className={classes.root}>
                <Hero heroValue={{ heading: 'On Demand' }} />
                <AppBar position="static" className={classes.appBar}>
                    <Box display="flex" alignItems="center">
                        {/* <Box mx={1}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={false}
                                    onChange={handleSwitchChange}
                                    name="Rich presence"
                                />
                            }
                            label={
                                <Box width="100px">
                                    <Typography variant="body2">
                                        Rich presence
                                    </Typography>
                                </Box>
                            }
                        />
                    </Box> */}

                        <Tabs
                            value={currentTabIndex}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {Object.keys(collectionNamesAndIds).map(
                                (name, index) => (
                                    <Tab
                                        key={index}
                                        label={name}
                                        swid={'course-tabs-' + name}
                                    />
                                )
                            )}

                            {/* Caution! Change here might require changes in conditional rendering below. */}
                            {/* <Tab
                            label="Coming Soon"
                            swid="course-tabs-Coming Soon"
                        /> */}
                            {user &&
                                Object.keys(
                                    privateEmailDomainsAndBundleNames
                                ).includes(userEmailDomain) && (
                                    <Tab
                                        label="Private Classes"
                                        swid="course-tabs-Private Courses"
                                    />
                                )}
                        </Tabs>
                    </Box>
                </AppBar>

                {
                    /* richPresssence */ false ? (
                        <>
                            {/* <RichPressence
                        currentTabIndex={currentTabIndex}
                        categoryName={
                            Object.values(collectionNamesAndSlugs)[
                                currentTabIndex
                            ]
                        }
                        setRichPressenceValue={setRichPressence}
                    /> */}
                        </>
                    ) : (
                        <>
                            <Box style={{ minHeight: 1000 }}>
                                {currentTabIndex <
                                Object.keys(collectionNamesAndIds).length ? (
                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent="flex-start"
                                    >
                                        {!isHoursEnabled &&
                                        courseHours &&
                                        courseMin ? (
                                            <CoursesList
                                                courses={courses}
                                                isLoading={
                                                    isLoadingAllCourses /* || isLoading */
                                                }
                                                hours={courseMinDetail}
                                                courseMin={courseMin}
                                                courseHours={courseHours}
                                            />
                                        ) : (
                                            ''
                                        )}
                                        {/* <ComingSoonList
                                    collectionNamesAndSlugs={
                                        collectionNamesAndSlugs
                                    }
                                    currentTabIndex={currentTabIndex}
                                /> */}
                                    </Grid>
                                ) : currentTabIndex ===
                                  Object.keys(collectionNamesAndIds).length ? (
                                    <PrivateCoursesList
                                        bundleName={
                                            privateEmailDomainsAndBundleNames[
                                                userEmailDomain
                                            ]
                                        }
                                    />
                                ) : null}
                            </Box>
                        </>
                    )
                }
            </div>
        </>
    );
};
export default CoursesPage;
