import axios from 'axios';
import { useState, useEffect, useCallback, useMemo } from 'react';

export const useLeaderboards = (clientId = '', userId) => {
    const [isLoading, setIsLoading] = useState(false);
    const [leaderboards, setLeaderboards] = useState([]);
    const [userRank, setUserRank] = useState(null);

    const fetchLeaderBoards = useCallback(async (client, user_id) => {
        setIsLoading(true);
        try {
            const { data } = await axios.get(
                `/api/stardust/leaderboards?client_id=${client}&user_id=${user_id}`
            );
            setLeaderboards(data.top);
            setUserRank(data.userDetails);
        } catch (error) {
            console.log(error.message);
            console.log('error-fetching leaderboards...');
        }
        setIsLoading(false);
    }, []);

    // to user externally ie. he is not in top 10
    const showUser = useMemo(() => {
        if (userRank) {
            return userRank.rank > 10 ? true : false;
        } else {
            return false;
        }
    }, [userRank]);

    useEffect(() => {
        if (clientId || userId) {
            fetchLeaderBoards(clientId, userId);
        }
    }, [fetchLeaderBoards, clientId, userId]);

    return { leaderboards, userRank, showUser, isLoading };
};
