import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    bar: {
        height: '4px',
        width: '100%',
        backgroundColor: '#ea711252',
        borderRadius: '100vmax',
        overflow: 'hidden',
        position: 'relative',

        '& > .bar__progress': {
            height: '100%',
            width: '10%',
            backgroundColor: '#EA7112',
            borderRadius: '2px',
            position: 'absolute',
            top: '0',
            left: '0%',
            animationName: 'slide',
            animationDuration: '2.5s',
            animationTimingFunction: 'linear',
            animationIterationCount: 'infinite',
            '@keyframes slide': {
                '50%': { width: '20%' },
                '100%': { left: '104%', width: '5%' },
            },
        },
    },
}));

export const LinearLoader = () => {
    const { classes } = useStyles();

    return (
        <div className={classes.bar}>
            <div className="bar__progress"></div>
        </div>
    );
};
