import { createSlice } from '@reduxjs/toolkit';

export const visibleColsSlice = createSlice({
    initialState: [
        'title',
        'percentageCompleted',
        'client_name',
        'type',
        'duration',
        'expectedEndDate',
        'share',
        'numOfMatchedInstructors',
        'interestedInstructors',
        'applicantNames',
        'collaborators',
        'addedCollaborators',
        'identifiedSmes',
        'shortListedSmes',
        'smes',
        'shortListedSmes2',
        'smes2',
        'interest',
        'instructorBudget',
        'minBudget',
        'status',
        'discussions',
        'urgency',
        'domain',
        'topics',
        'contractNumber',
        'workOrders',
        'createdAt',
        'completedOn',
        'updatedAt',
        'tags',
    ],
    name: 'visibleCols',
    reducers: {
        updateVisibleCols: (state, action) => {
            const { payload } = action;
            const { field, isVisible } = payload;

            if (field === '__check__') return state;

            if (isVisible === false && state.includes(field)) {
                const updatedState = state.filter((item) => item !== field);
                return [...updatedState];
            }

            if (isVisible === true && !state.includes(field)) {
                return [...state, field];
            }

            return state;
        },
    },
});

export const { updateVisibleCols } = visibleColsSlice.actions;
export default visibleColsSlice.reducer;
