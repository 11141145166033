import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showChat: false,
};

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        toggleChat: (state, action) => {
            state.showChat = action.payload;
        },
    },
});

export const { toggleChat } = chatSlice.actions;
export default chatSlice.reducer;
