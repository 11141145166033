import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Calendar, momentLocalizer } from 'react-big-calendar';

const useStyles = makeStyles()((theme) => ({
    calendarContainer: {
        height: '50rem',
        padding: theme.spacing(2),
    },
}));

const localizer = momentLocalizer(moment);

export default function LogisticCalendarView({ calendarViewData }) {
    const { classes } = useStyles();

    return (
        <Box className={classes.calendarContainer}>
            <Calendar
                localizer={localizer}
                events={calendarViewData}
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={(calendarViewData) => {
                    return {
                        style: {
                            backgroundColor: calendarViewData?.backgroundColor
                                ? calendarViewData?.backgroundColor
                                : '#3174ad',
                            mixBlendMode: 'difference',
                            fontWeight: '500',
                            paddingBlock: '0.25rem',
                        },
                    };
                }}
            />
        </Box>
    );
}
