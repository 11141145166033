export const CenteredContainer = ({ children, maxWidth }) => {
    return (
        <div
            style={{
                width: `min(${maxWidth || 1000}px, 95vw)`,
                margin: '-5px auto 50px',
                position: 'relative',
            }}
        >
            {children}
        </div>
    );
};
