import React, { useEffect, useState } from 'react';
import { Box, Typography, TimerDays, Button } from '../ui';
import moment from 'moment';
import { Link } from 'react-router-dom';

const NextInstructorInvitational = ({ userName, userEmail }) => {
    const [nextEvent, setNextEvent] = useState(null);

    useEffect(() => {
        const fetchNextEvent = async () => {
            try {
                const response = await fetch(
                    `${
                        process.env.REACT_APP_CALENDLY_API_DOMAIN
                    }/scheduled_events?organization=${
                        process.env.REACT_APP_CALENDLY_ORGANIZATION_URI
                    }&min_start_time=${moment
                        .utc()
                        .toISOString()}&status=active&count=10&sort=start_time:asc`,
                    {
                        headers: {
                            Authorization: `Bearer ${process.env.REACT_APP_CALENDLY_API_TOKEN}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                const data = await response.json();

                const instructorInvitationalEvent = data.collection.find(
                    (event) =>
                        event.event_type ===
                        process.env
                            .REACT_APP_CALENDLY_INSTRUCTOR_INVITATIONALS_URI
                );

                if (instructorInvitationalEvent) {
                    setNextEvent(instructorInvitationalEvent);
                }
            } catch (error) {
                console.error('Error fetching Calendly event:', error);
            }
        };

        fetchNextEvent();
    }, []);

    const isLive = (startTime, endTime) => {
        const now = moment();
        return now.isBetween(startTime, endTime);
    };

    const renderEventStatus = (event) => {
        const startTime = moment(event.start_time);
        const endTime = moment(event.end_time);

        if (isLive(startTime, endTime)) {
            return (
                <Box display={'flex'} alignItems={'center'}>
                    <Typography variant="caption" style={{ color: 'red' }}>
                        Live
                    </Typography>
                </Box>
            );
        } else if (startTime.isAfter()) {
            return (
                <Box>
                    <Box display={'flex'} alignItems={'center'}>
                        <Typography variant="caption">
                            Next Instructor Invitational starts in...
                        </Typography>
                        <TimerDays
                            expiryTimestamp={startTime.local().toDate()}
                            onExpire={() => console.log('Event expired')}
                        />
                        <Button
                            onClick={handleSchedule}
                            variant="contained"
                            color="primary"
                            style={{
                                marginLeft: 10,
                                marginBottom: 3,
                                marginTop: 3,
                            }}
                        >
                            Register
                        </Button>
                    </Box>
                </Box>
            );
        } else {
            return (
                <Box>
                    <Typography variant="caption">Event has ended</Typography>
                </Box>
            );
        }
    };

    const handleSchedule = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const baseUrl =
            'https://calendly.com/starweaver/instructors-invitationals';
        const encodedName = encodeURIComponent(userName);
        const encodedEmail = encodeURIComponent(userEmail);
        const prefillParams = `name=${encodedName}&email=${encodedEmail}`;
        const fullUrl = `${baseUrl}?${prefillParams}`;

        window.open(fullUrl, '_blank');
    };

    return (
        <Box padding="0px 10px 0px 10px" width="fit-content">
            {nextEvent ? (
                <Link
                    to="/profile/instructor-dashboard/connect/instructor-invitational"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                >
                    {renderEventStatus(nextEvent)}
                </Link>
            ) : (
                <></>
            )}
        </Box>
    );
};

export default NextInstructorInvitational;
