import { Box } from '@mui/material';
import pathMatch from 'path-match';
import { useEffect } from 'react';
import {
    Navigate,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useUser } from '../auth';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { PageLoader } from '../ui';
import { roleViews as allViews, roleViewState } from './recoil/roleView';

const route = pathMatch({
    sensitive: false,
    strict: false,
    end: false,
});

const ignorePaths = ['/login', '/signup'];
const shouldRedirectAfterAuth = (pathname) =>
    !ignorePaths.some((path) => {
        const matches = route(path);
        return matches(pathname);
    });

const homePaths = {
    student: '/on-air',
    admin: '/admin',
    instructor: '/profile/instructor-dashboard',
    // manager: '/opportunity-management',
    collaborator: '/collaborator/opportunity-management',
};

export const PrivateRouteV2 = ({ children, roleViews }) => {
    const { isLoading, user } = useUser();
    const {
        isLoading: currentUserLoading,
        user: currentUser,
    } = useCurrentUser();
    const location = useLocation();
    const { pathname, search } = location;
    const [roleView, setRoleView] = useRecoilState(roleViewState);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const role = searchParams.get('role');
        if (!role) return;
        if (Object.values(allViews).includes(role)) {
            setRoleView({ selectedView: role });
        }
    }, [searchParams, setRoleView]);

    useEffect(() => {
        if (!currentUser) return;
        const { selectedView } = roleView;

        if (!selectedView) {
            if (roleViews.includes(allViews.ADMIN) && currentUser.isAdmin) {
                setRoleView({ selectedView: allViews.ADMIN });
            } else if (
                roleViews.includes(allViews.INSTRUCTOR) &&
                currentUser.is_instructor
            ) {
                setRoleView({ selectedView: allViews.INSTRUCTOR });
            } else if (
                roleViews.includes(allViews.COLLABORATOR) &&
                currentUser?.roles?.includes(allViews.COLLABORATOR)
            ) {
                setRoleView({ selectedView: allViews.COLLABORATOR });
            } else {
                setRoleView({ selectedView: allViews.STUDENT });
            }
        } else {
            if (
                currentUser.isAdmin &&
                selectedView === allViews.ADMIN &&
                !roleViews.includes(allViews.ADMIN)
            ) {
                setRoleView({ selectedView: allViews.ADMIN });
                navigate(homePaths['admin']);
            } else if (
                currentUser.is_instructor &&
                selectedView === allViews.INSTRUCTOR &&
                !roleViews.includes(allViews.INSTRUCTOR)
            ) {
                setRoleView({ selectedView: allViews.INSTRUCTOR });
                navigate(homePaths['instructor']);
            } else if (
                currentUser?.roles?.includes(allViews.COLLABORATOR) &&
                selectedView === allViews.COLLABORATOR &&
                !roleViews.includes(allViews.COLLABORATOR)
            ) {
                setRoleView({ selectedView: allViews.COLLABORATOR });
                navigate(homePaths['collaborator']);
            }
        }
    }, [currentUser, navigate, roleView, roleViews, setRoleView]);

    return isLoading || currentUserLoading ? (
        <Box minHeight={'100vh'}>
            <PageLoader open={true} />
        </Box>
    ) : user ? (
        roleView.selectedView === '' ? (
            <Box minHeight={'100vh'}>
                <PageLoader open={true} />
            </Box>
        ) : (
            <>{children}</>
        )
    ) : shouldRedirectAfterAuth(pathname) ? (
        pathname === 'reset-password' ? (
            <Navigate to="reset-password" replace />
        ) : (
            <Navigate
                to={`/login?dest=${encodeURI(pathname + search)}`}
                replace
            />
        )
    ) : (
        <Navigate to="/login" replace />
    );
};
