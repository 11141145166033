import { Box, Typography } from '@mui/material';
import FileItem from './FileItem';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        selectedFiles: {
            padding: theme.spacing(1, 1.5),
            borderTop: '1px dashed',
            borderColor: theme.palette.grey['400'],
        },
        selectedFiles__items: {
            padding: 0,
            margin: 0,
        },
    };
});

function SelectedFiles({ fileContainers, handleRemoveSelectedFile }) {
    const { classes } = useStyles();

    return (
        <Box className={classes.selectedFiles}>
            <Typography variant="caption">Selected Files</Typography>
            <ul className={classes.selectedFiles__items}>
                {Array.from(fileContainers).map(
                    (
                        {
                            id,
                            file,
                            isUploaded,
                            isUploading,
                            percentageUploaded,
                        },
                        index
                    ) => (
                        <FileItem
                            key={id}
                            id={id}
                            file={file}
                            isUploaded={isUploaded}
                            isUploading={isUploading}
                            percentageUploaded={percentageUploaded}
                            index={index}
                            handleRemoveSelectedFile={handleRemoveSelectedFile}
                        />
                    )
                )}
            </ul>
        </Box>
    );
}

export default SelectedFiles;
