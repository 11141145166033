import React from 'react';
import {
    Box,
    Radio,
    RadioGroup,
    FormControl,
    // FormLabel,
    FormControlLabel,
    Typography,
} from '..';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    // settings: {
    //     padding: '14px',
    //     border: '1px solid #c5c5c5',
    //     borderRadius: '2px',
    // },
    radioGroup: {
        flexDirection: 'row',
    },
}));

export const RadioSelect = ({ title, setValue, value, options, disabled }) => {
    const { classes } = useStyles();

    const handleChange = (value) => {
        setValue(value);
    };

    return (
        <Box className={classes.settings}>
            <FormControl
                variant="outlined"
                color="secondary"
                component="fieldset"
                style={{
                    padding: '16px',
                }}
            >
                <RadioGroup
                    value={value}
                    onChange={(e) => handleChange(e.target.value)}
                    className={classes.radioGroup}
                >
                    {options.map((option) => (
                        <FormControlLabel
                            key={option.name}
                            value={option.value}
                            // style={{
                            //     marginTop: '10px',
                            //     display: 'flex',
                            //     alignItems: 'flex-start',
                            // }}

                            control={<Radio />}
                            label={
                                <Box
                                // marginTop={'4px'}
                                // display={'flex'}
                                // flexDirection="column"
                                >
                                    <Typography /* variant="p" */>
                                        {option.name}
                                    </Typography>
                                </Box>
                            }
                            disabled={disabled || option.disabled}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Box>
    );
};
