import React, { useEffect } from 'react';
import { getSSOAnalyticsURL } from './getSSOAnalyticsURL';
import { useQueryParams } from '../util/useQueryParams';
import { Loading } from '../ui';
import { useIsAdmin } from '../admin/useIsAdmin';
import AdminAccessRestricted from '../access-denied/AdminAccessRestricted';

const clientsWithAccess = ['Packt'];

const SSOAnalytics = () => {
    const { successPath, errorPath } = useQueryParams();

    const {
        isAdmin,
        starweaverAdmin,
        isCAD,
        isLoading,
        client,
        isClientLoading,
    } = useIsAdmin();

    // console.log(client);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // if (isCAD) {
                //     if (!clientsWithAccess.includes(client?.client_name))
                //         return;
                // }

                if (!successPath) {
                    throw new Error('Success URL not provided');
                }

                const data = await getSSOAnalyticsURL(
                    successPath,
                    errorPath || null
                );

                if (data && typeof data === 'string') {
                    // console.log('SSO successful.');
                    window.open(data, '_self');
                } else {
                    throw new Error('Invalid data received');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (!(isLoading || isClientLoading)) fetchData();
    }, [successPath, errorPath, isLoading, isClientLoading, isCAD, client]);

    if (isLoading || isClientLoading) return <Loading />;

    // if (isCAD && !clientsWithAccess.includes(client?.client_name))
    //     return <AdminAccessRestricted />;

    return <Loading />;
};

export default SSOAnalytics;
