import React from 'react';
import { Box, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    categoryName: {
        marginBottom: theme.spacing(0.5),
        marginTop: theme.spacing(2),
        fontWeight: '500',
        letterSpacing: '0.03rem',
        lineHeight: '24px',
    },
    table: {
        borderCollapse: 'collapse',
        width: '100%',
        '& td, & th': {
            border: '1px solid #dddddd',
            textAlign: 'left',
            padding: '8px',
        },
    },
    checkpointName: {
        backgroundColor: '#dddddd',
    },
}));

function PreviewTable({ category }) {
    const { classes } = useStyles();

    return (
        <Box>
            <Typography className={classes.categoryName}>
                {category.categoryName}
            </Typography>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th>Task Name</th>
                        <th>Task Type</th>
                        <th>Duration</th>
                        <th># Day(s)</th>
                    </tr>
                </thead>
                <tbody>
                    {category.tasks.map((task, tIdx) => {
                        return (
                            <React.Fragment key={task.name + '-' + tIdx}>
                                <tr>
                                    <td style={{ width: '45%' }}>
                                        {task.name}
                                    </td>
                                    <td style={{ width: '30%' }}>
                                        {task.typeOfTask}
                                    </td>
                                    <td>
                                        {task?.duration ? task.duration : 0}
                                    </td>
                                    <td>{task.numOfDaysBeforeDelivery}</td>
                                </tr>
                                {task?.checkpointName && (
                                    <tr className={classes.checkpointName}>
                                        <td colSpan={4}>
                                            {task.checkpointName}
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </Box>
    );
}

export default PreviewTable;
