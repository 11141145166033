//import { useState } from 'react';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import {
    searchQueryState,
    searchPageNumberState,
} from '../search-and-browse/state';
// import { getCoursesForCollection } from '../courses/getCoursesForCollection';
// import { useSelector } from 'react-redux';
import {
    // Autocomplete,
    Box,
    InputAdornment,
    IconButton,
    TextField,
    Typography,
} from '../ui';
import { SearchIcon, CloseIcon } from '../icons';
// import { ALL_COLLECTION_ID } from '../category-landing-page/collectionIds';
//import { searchOptionsSlice } from './searchOptionsSlice';

//const { getSearchOptions } = searchOptionsSlice;

const useStyles = makeStyles()((theme) => ({
    root: {
        padding: '2px 8px',
        display: 'flex',
        alignItems: 'center',
        /* width: '100%', */
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },

    divider: {
        height: 28,
        margin: 4,
    },
    popper: {
        width: 500,
    },
}));

export const SearchBox = ({
    /* value, onChange, */ onToggleDrawer = () => {},
    // width = '100%',
}) => {
    //const courses = useSelector(getCoursesForCollection(ALL_COLLECTION_ID));
    const { classes } = useStyles();
    let navigate = useNavigate();
    //const searchOptions = useSelector(getSearchOptions);

    //const [searchValue, setSearchValue] = useState('');
    const [query, setQuery] = useRecoilState(searchQueryState);
    const setPageNumber = useSetRecoilState(searchPageNumberState);

    //const setQuery = useSetRecoilState(searchQueryState);

    const onSearchBoxChange = (value) => {
        setQuery(value);
        setPageNumber(1);
    };

    const goToSearchResultsPage = () => {
        setQuery(query);
        onToggleDrawer();
        if (query !== '')
            navigate({
                pathname: '/search',
                search: `?q=${query}`,
            });
    };

    const clickHandler = () => {
        goToSearchResultsPage();
    };

    const keyPressHandler = (e) => {
        if (e.key === 'Enter') {
            goToSearchResultsPage();
        }
    };
    // const onOptionClick = (value) => {
    //     const { slug } = value;
    //     if (value && slug) {
    //         if (slug.includes('retro')) {
    //             navigate(`/retro-course/${slug}`);
    //         } else {
    //             navigate(`/courses/${slug}`);
    //         }
    //     } else {
    //         setQuery(value.name);
    //         setPageNumber(1);
    //     }
    // };

    const clearQuery = () => {
        setQuery('');
    };

    return (
        <Box p={1}>
            {/* <Autocomplete
                freeSolo
                fullWidth
                onChange={(e, option) => onOptionClick(option)}
                options={ []}
                onKeyDown={keyPressHandler}
                disableClearable
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                    <Typography>{option.name}</Typography>
                )}
                renderInput={(params) => ( */}
            <TextField
                onChange={(e) => onSearchBoxChange(e.target.value)}
                onKeyDown={keyPressHandler}
                variant="outlined"
                value={query}
                color="secondary"
                // {...params}
                placeholder="Search courses, certifications, journeys, articles…"
                InputProps={{
                    // ...params.InputProps,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={clearQuery}
                                aria-label="search"
                                size="small"
                                disabled={!query}
                            >
                                <CloseIcon />
                            </IconButton>

                            <IconButton
                                onClick={clickHandler}
                                aria-label="search"
                                size="large"
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                fullWidth
            />
            {/* )}
            /> */}
        </Box>
    );
};
