import React, { useState, useMemo, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import {
    Box,
    Button,
    Chip,
    Tooltip,
    Dialog,
    Typography,
    IconButton,
    DialogContent,
    DialogActions,
    CircularProgress,
    TextField,
    MenuItem,
    InputAdornment,
    Autocomplete,
    SingleSelect,
    GridContainer,
    ConfirmDialog,
} from '../ui';
import {
    DataGridPremium,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';
import { CloseIcon } from '../icons';
import * as XLSX from 'xlsx';
import { makeStyles } from 'tss-react/mui';
import axios from 'axios';
import moment from 'moment';
import { alertState, severity } from '../app/recoil';
// import { statusOptions as _statusOptions } from './RequirementDetails';
import {
    domainOptions as _domainOptions,
    roleList,
    roleToHeader,
} from '../util';
// import { typeOptions as _typeOptions } from './RequirementDetails';
import SMEModal from '../smes/SMEModal';
import SMEModal2 from '../smes/SMEModal2';
import { urgencyOptions as _urgencyOptions } from './ClientRequirementsCreateFormDelivery';
import {
    statusOptions as _statusOptions,
    typeOptions as _typeOptions,
} from './clientRequirementOptions';
import { ClientRequirementsSMEInput } from './ClientRequirementsSMEInput';
import { useAllCompanies } from './useAllCompanies';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TaskUserAssociation from './components/ClientRequirements/TaskUserAssociation';

export const domainOptions = [
    { label: 'none', value: null },
    ..._domainOptions,
];

const statusOptions = [{ label: 'none', value: null }, ..._statusOptions];

export const typeOptions = [{ label: 'none', value: null }, ..._typeOptions];

export const urgencyOptions = [
    { label: 'none', value: null },
    ..._urgencyOptions,
];

const useStyles = makeStyles()((theme) => ({
    paper: {
        // minWidth: '90vw',
        padding: 20,
        [theme.breakpoints.up('sm')]: {
            padding: 40,
        },
    },
    // tableContainer: {
    //     height: 280,
    //     width: '100%',
    // },
    dialogAction: {
        // display: 'flex',
        padding: 0,
    },
    // dialogContent: {
    //     // minHeight: 500,
    // },
    dialogContent: {
        padding: theme.spacing(2),
        '& .section-title': {
            fontSize: '17px',
            minWidth: '120px',
            '&.tags-input': {
                flexGrow: 1,
            },
        },
        '& .date-groups': {
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            // '& > *:first-child': {
            //     maxWidth: '100px',
            // },
            // '& > *:last-child': {
            //     maxWidth: '100px',
            // },
        },
        '& .input-group': {
            borderBottom: '1px dashed #bababa',
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            marginTop: '0.5rem',
            paddingBottom: '0.5rem',
            flex: 1,
            [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap',
            },
            '& > .section-title:nth-child(2)': {
                flex: 1,
                '& > *:first-child': {
                    width: '100%',
                },
            },
        },
        '& .assigned-group': {
            flexWrap: 'wrap',
            '& .section-title': {
                flexShrink: 0,
                // alignSelf: 'flex-start',
            },
            '& .assigned-smes': {
                flexGrow: 1,
            },
            '& .add-btn': {
                marginLeft: 'auto',
                flexShrink: 0,
            },
        },
        '& .group-container': {
            display: 'flex',
            justifyContent: 'space-between',
            gap: '2%',
            flexWrap: 'wrap',
        },
    },
    defaultSectionContainer: {
        marginTop: 20,
    },
}));

export const ClientRequirementsBulkUpdateModal = (props) => {
    const { classes } = useStyles();

    // const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedTaskOptions, setSelectedTaskOptions] = useState([]);
    const {
        bulkUpdateModalState = { isOpen: true, ids: [] },
        setBulkUpdateModalState,
        fetchData,
    } = props;
    const [selectedTaskUsers, setSelectedTaskUsers] = useState([]);
    const [expectedStartDate, setExpectedStartDate] = useState('');

    const [expectedEndDate, setExpectedEndDate] = useState('');

    const [status, setStatus] = useState(null);
    const [domain, setDomain] = useState(null);
    const [type, setType] = useState(null);
    const [minBudget, setMinBudget] = useState('');
    const [maxNumOfStudents, setMaxNumOfStudents] = useState(null);
    // console.log(minBudget);
    // console.log(typeof minBudget);
    // console.log(minBudget === '');
    const [maxBudget, setMaxBudget] = useState('');
    const [urgency, setUrgency] = useState(null);

    const [instructorBudget, setInstructorBudget] = useState('');

    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);

    const { companiesOption } = useAllCompanies(true);
    const [clientId, setClientId] = useState(null);

    const [shortListedSmes, setShortListedSmes] = useState(0);
    // const [collaborators, setCollaborators] = useState(0);
    const [addedCollaborators, setAddedCollaborators] = useState(0);
    const [tags, setTags] = useState([]);
    const [roles, setRoles] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [smeModal, setSmeModal] = useState({
        isOpen: false,
        smes: [],
        id: null,
        title: null,
    });
    const [smeModal2, setSmeModal2] = useState({
        isOpen: false,
        smes: [],
        id: null,
        title: null,
    });
    const [contractNumber, setContractNumber] = useState('');
    // const [workOrder, setWorkOrder] = useState('');

    const [totalExperiencePercent, setTotalExperiencePercent] = useState(0);
    const [percentHavingNoExperience, setPercentHavingNoExperience] = useState(
        null
    );
    const [
        percentHavingZeroToOneExperience,
        setPercentHavingZeroToOneExperience,
    ] = useState(null);
    const [
        percentHavingOneToThreeExperience,
        setPercentHavingOneToThreeExperience,
    ] = useState(null);

    const [
        percentHavingFourToSevenExperience,
        setPercentHavingFourToSevenExperience,
    ] = useState(null);
    const [
        percentHavingMoreThanSevenExperience,
        setPercentHavingMoreThanSevenExperience,
    ] = useState(null);

    const [
        isStudentExperienceExpanded,
        setIsStudentExperienceExpanded,
    ] = useState(false);

    const toggleExpand = () => {
        setIsStudentExperienceExpanded((prev) => !prev);
    };

    const readOnly = false;

    const setAlert = useSetRecoilState(alertState);

    const totalExp = useMemo(() => {
        const total =
            percentHavingNoExperience +
            percentHavingZeroToOneExperience +
            percentHavingOneToThreeExperience +
            percentHavingFourToSevenExperience +
            percentHavingMoreThanSevenExperience;

        setTotalExperiencePercent(total);

        return total;
    }, [
        percentHavingFourToSevenExperience,
        percentHavingMoreThanSevenExperience,
        percentHavingNoExperience,
        percentHavingOneToThreeExperience,
        percentHavingZeroToOneExperience,
    ]);

    const getPercentageOptions = useCallback(
        (totalPercentage, groupPrecentage) => {
            const size =
                11 - Math.floor((totalPercentage - groupPrecentage) / 10);

            return [...Array(size).keys()].map((i) => ({
                label: i * 10,
                value: i * 10,
            }));
        },
        []
    );

    const handleDialogClose = () => {
        setBulkUpdateModalState((prev) => {
            return {
                isOpen: false,
                ids: [],
            };
        });
    };

    const updateRequirements = async (payload) => {
        try {
            if (!payload.ids?.length)
                return setAlert({
                    show: true,
                    message: 'Requirements missing',
                    severity: severity.ERROR,
                });

            const { status } = await axios.put(
                `/api/client-requirements/bulk`,
                payload
            );
            if (status === 200) {
                setAlert({
                    show: true,
                    message: 'Updated successfully',
                    severity: severity.SUCCESS,
                });

                handleDialogClose();
                await fetchData();
            }
        } catch (e) {
            console.log('e: ', e);
            setAlert({
                show: true,
                message: 'Something went wrong',
                severity: severity.ERROR,
            });
        }
    };

    const onEndDateChange = async (e) => {
        const endDate = e.target.value;

        setExpectedEndDate(endDate);
    };

    const onStartDateChange = (e) => {
        const startDate = e.target.value;
        // const endDate = moment.utc(expectedEndDate);

        // if (moment.utc(startDate).isAfter(endDate)) {
        //     setAlert({
        //         show: true,
        //         severity: severity.ERROR,
        //         message: "Start date can't be after end date",
        //     });
        //     return;
        // }

        setExpectedStartDate(startDate);
    };

    const onUpdate = async () => {
        setLoading(true);
        let rolesBasedUsers = {};
        if (Object.keys(roles).length > 0) {
            for (const role in roles) {
                rolesBasedUsers[role] = roles[role].map((user) => user._id);
            }
        }

        await updateRequirements({
            ids: bulkUpdateModalState.ids,
            expectedStartDate:
                expectedStartDate && expectedStartDate !== ''
                    ? moment.utc(expectedStartDate).toISOString()
                    : undefined,
            expectedEndDate:
                expectedEndDate && expectedEndDate !== ''
                    ? moment.utc(expectedEndDate).toISOString()
                    : undefined,
            status,
            domain,
            type,
            minBudget:
                minBudget && minBudget !== '' ? Number(minBudget) : undefined,
            maxBudget:
                maxBudget && maxBudget !== '' ? Number(maxBudget) : undefined,
            shortListedSmes: shortListedSmes.length
                ? shortListedSmes.map(({ _id }) => _id)
                : undefined,
            /* collaborators: collaborators.length
                ? collaborators.map(({ _id }) => _id)
                : undefined, */
            addedCollaborators: addedCollaborators.length
                ? addedCollaborators.map(({ _id }) => _id)
                : undefined,
            urgency,
            maxNumOfStudents:
                maxNumOfStudents &&
                maxNumOfStudents != 0 &&
                maxNumOfStudents != ''
                    ? maxNumOfStudents
                    : undefined,
            instructorBudget:
                instructorBudget && instructorBudget != ''
                    ? instructorBudget
                    : undefined,
            minutes:
                minutes && minutes != 0 && minutes != '' ? minutes : undefined,
            hours: hours && hours != 0 && hours != '' ? hours : undefined,
            clientId,
            contractNumber:
                contractNumber && contractNumber.trim().length > 0
                    ? contractNumber
                    : undefined,
            /* workOrder:
                workOrder && workOrder.trim().length > 0
                    ? workOrder
                    : undefined, */
            tags,

            percentHavingFourToSevenExperience:
                percentHavingFourToSevenExperience !== null
                    ? percentHavingFourToSevenExperience
                    : undefined,
            percentHavingMoreThanSevenExperience:
                percentHavingMoreThanSevenExperience !== null
                    ? percentHavingMoreThanSevenExperience
                    : undefined,
            percentHavingNoExperience:
                percentHavingNoExperience !== null
                    ? percentHavingNoExperience
                    : undefined,
            percentHavingOneToThreeExperience:
                percentHavingOneToThreeExperience !== null
                    ? percentHavingOneToThreeExperience
                    : undefined,
            percentHavingZeroToOneExperience:
                percentHavingZeroToOneExperience !== null
                    ? percentHavingZeroToOneExperience
                    : undefined,
            ...(Object.keys(rolesBasedUsers).length > 0 ? rolesBasedUsers : {}),
            taskUsersIds: selectedTaskUsers.map((user) => user._id),
            selectedTasksNames: selectedTaskOptions.map((task) => task.value),
        });

        setLoading(false);
    };

    const onMinBudgetChange = (e) => {
        const min = e.target.value;
        setMinBudget(min);
    };

    const onMaxBudgetChange = (e) => {
        const max = e.target.value;
        setMaxBudget(max);
    };

    const onSmesSelect = (smes) => {
        setShortListedSmes(smes);
    };

    const handleSMEClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setSmeModal((prev) => {
            return {
                ...prev,
                isOpen: true,
            };
        });
    };

    const onSmesSelect2 = (smes, column) => {
        // if (column === 'collaborators') setCollaborators(smes);
        /* else  */ if (column === 'addedCollaborators')
            setAddedCollaborators(smes);
        else if (Object.keys(roleToHeader).includes(column)) {
            const _roles = { ...roles };
            _roles[column] = smes;
            setRoles(_roles);
        } else if (column === 'selectedTaskUsers') {
            setSelectedTaskUsers(smes);
        }
    };

    const handleSMEClick2 = (e, requirement, column) => {
        e.preventDefault();
        e.stopPropagation();

        setSmeModal2((prev) => ({
            ...prev,
            isOpen: true,
            column,
            ...(column === 'selectedTaskUsers' ? { title: 'Tasks' } : {}),
        }));
    };

    const onHourschange = (e) => {
        setHours(e.target.value);
    };

    const onMinChange = (e) => {
        setMinutes(e.target.value);
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onContractNumberChange = (e) => {
        setContractNumber(e.target.value);
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    /* const onWorkOrderChange = (e) => {
        setWorkOrder(e.target.value);
    }; */

    const onTagsChange = (e, newValue) => {
        setTags(newValue);
    };

    const handleClientChange = (value) => {
        setClientId(value);
    };

    const handleUpdateClick = () => {
        setShowConfirmModal(true);
    };

    const confirmUpdate = () => {
        setShowConfirmModal(false);
        onUpdate();
    };

    return (
        <>
            <ConfirmDialog
                onClose={() => setShowConfirmModal(false)}
                open={showConfirmModal}
                onConfirm={confirmUpdate}
                title={`You have selected ${bulkUpdateModalState.ids.length} requirements. Are you sure you want to update them?`}
                confirmLabel="Update"
            />
            <Dialog
                onClose={handleDialogClose}
                aria-labelledby="matching-instructor-data-modal"
                open={
                    (bulkUpdateModalState.isOpen || false) &&
                    !smeModal.isOpen &&
                    !smeModal2.isOpen
                }
                fullWidth
                maxWidth={'xl'}
                classes={{ paper: classes.paper }}
            >
                <Box display="flex" alignItems="center" mb={2}>
                    <Typography variant="h6" style={{ marginRight: 'auto' }}>
                        Update Selected Requirements
                        {` (${bulkUpdateModalState.ids?.length})`}
                        {/* : {rows.map((r) => r.title).join(', ')} */}
                    </Typography>

                    <IconButton
                        aria-label="close"
                        onClick={handleDialogClose}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <>
                    <DialogContent className={classes.dialogContent}>
                        <Box className="group-container">
                            <Box className="input-group">
                                <Typography className="section-title">
                                    Start Date:{' '}
                                </Typography>

                                <Typography className="section-title">
                                    <TextField
                                        type="date"
                                        id="startDate"
                                        name="startDate"
                                        value={expectedStartDate}
                                        color="secondary"
                                        variant="outlined"
                                        size="small"
                                        onChange={onStartDateChange}
                                        InputProps={{
                                            style: { minWidth: '130px' },
                                        }}
                                    />
                                </Typography>
                            </Box>

                            {/* <Box className="input-group">
                                <Typography className="section-title">
                                    Delivery Date:{' '}
                                </Typography>

                                <Typography className="section-title">
                                    <TextField
                                        type="date"
                                        id="endDate"
                                        name="endDate"
                                        color="secondary"
                                        value={expectedEndDate}
                                        variant="outlined"
                                        size="small"
                                        onChange={onEndDateChange}
                                        InputProps={{
                                            style: { minWidth: '130px' },
                                        }}
                                    />
                                </Typography>
                            </Box> */}
                        </Box>
                        <Box className="group-container">
                            <Box className="input-group">
                                <Typography className="section-title">
                                    Duration:{' '}
                                </Typography>

                                <Typography className="section-title date-groups">
                                    <TextField
                                        name="hours"
                                        id="hours"
                                        value={hours}
                                        type="number"
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    hr<small>(s)</small>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={onHourschange}
                                    />
                                    {'-'}
                                    <TextField
                                        name="minutes"
                                        id="minutes"
                                        value={minutes}
                                        type="number"
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    min<small>(s)</small>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={onMinChange}
                                    />
                                </Typography>
                            </Box>
                            <Box className="input-group">
                                <Typography className="section-title">
                                    Client:{' '}
                                </Typography>

                                <Typography className="section-title">
                                    <TextField
                                        value={clientId}
                                        onChange={(e) =>
                                            handleClientChange(e.target.value)
                                        }
                                        select
                                        variant="outlined"
                                        // margin="normal"

                                        name="client"
                                        color="secondary"
                                        size="small"
                                        InputProps={{
                                            style: { minWidth: '130px' },
                                        }}
                                    >
                                        {companiesOption.map(
                                            ({ _id, client_name }) => (
                                                <MenuItem key={_id} value={_id}>
                                                    {client_name}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="group-container">
                            <Box className="input-group">
                                <Typography className="section-title">
                                    Urgency:{' '}
                                </Typography>

                                <Typography className="section-title">
                                    <TextField
                                        id="urgency"
                                        select
                                        value={urgency}
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        onChange={(e) =>
                                            setUrgency(e.target.value)
                                        }
                                        InputProps={{
                                            style: { minWidth: '130px' },
                                        }}
                                    >
                                        {urgencyOptions.map((option, index) => {
                                            return (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                    style={
                                                        index == 0
                                                            ? {
                                                                  fontStyle:
                                                                      'italic',
                                                              }
                                                            : null
                                                    }
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Typography>
                            </Box>
                            <Box className="input-group">
                                <Typography className="section-title">
                                    Status:{' '}
                                </Typography>

                                <Typography className="section-title">
                                    <TextField
                                        id="status"
                                        select
                                        value={status}
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        onChange={(e) =>
                                            setStatus(e.target.value)
                                        }
                                        InputProps={{
                                            style: { minWidth: '130px' },
                                        }}
                                    >
                                        {statusOptions.map((option, index) => {
                                            return (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                    style={
                                                        index == 0
                                                            ? {
                                                                  fontStyle:
                                                                      'italic',
                                                              }
                                                            : null
                                                    }
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="group-container">
                            <Box className="input-group">
                                <Typography className="section-title">
                                    Domain:{' '}
                                </Typography>

                                <Typography className="section-title">
                                    <TextField
                                        id="domain"
                                        select
                                        value={domain}
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        onChange={(e) =>
                                            setDomain(e.target.value)
                                        }
                                        InputProps={{
                                            style: { minWidth: '130px' },
                                        }}
                                    >
                                        {domainOptions.map((option, index) => {
                                            return (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                    style={
                                                        index == 0
                                                            ? {
                                                                  fontStyle:
                                                                      'italic',
                                                              }
                                                            : null
                                                    }
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Typography>
                            </Box>
                            <Box className="input-group">
                                <Typography className="section-title">
                                    Type:{' '}
                                </Typography>

                                <Typography className="section-title">
                                    <TextField
                                        id="type"
                                        select
                                        value={type}
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        onChange={(e) =>
                                            setType(e.target.value)
                                        }
                                        InputProps={{
                                            style: { minWidth: '130px' },
                                        }}
                                    >
                                        {typeOptions.map((option, index) => {
                                            return (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                    style={
                                                        index == 0
                                                            ? {
                                                                  fontStyle:
                                                                      'italic',
                                                              }
                                                            : null
                                                    }
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="group-container">
                            <Box className="input-group">
                                <Typography className="section-title">
                                    Budget Range:{' '}
                                </Typography>

                                <Typography className="section-title date-groups">
                                    <TextField
                                        name="minBudget"
                                        id="minBudget"
                                        value={minBudget}
                                        type="number"
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            ),

                                            // style: { minWidth: '130px' },
                                        }}
                                        onChange={onMinBudgetChange}
                                    />{' '}
                                    <span>to</span>{' '}
                                    <TextField
                                        name="maxBudget"
                                        id="maxBudget"
                                        value={maxBudget}
                                        type="number"
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            ),

                                            // style: { minWidth: '130px' },
                                        }}
                                        onChange={onMaxBudgetChange}
                                    />
                                </Typography>
                            </Box>
                            <Box className="input-group">
                                <Typography className="section-title">
                                    No. of Students:{' '}
                                </Typography>

                                <Typography className="section-title">
                                    <TextField
                                        id="maxNumOfStudents"
                                        name="maxNumOfStudents"
                                        value={maxNumOfStudents}
                                        type="number"
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        // style={{ maxWidth: '100px' }}
                                        onChange={(e) =>
                                            setMaxNumOfStudents(e.target.value)
                                        }
                                        InputProps={{
                                            style: { minWidth: '130px' },
                                        }}
                                    />
                                </Typography>
                            </Box>
                        </Box>
                        <ClientRequirementsSMEInput
                            label="Shortlisted"
                            classes={classes}
                            smes={shortListedSmes}
                            handleSMEClick={handleSMEClick}
                            showAddBtn
                            showShortListedSmes={true}
                        />
                        <GridContainer>
                            {/* <ClientRequirementsSMEInput
                                label="Lead Collaborators"
                                classes={classes}
                                smes={collaborators}
                                handleSMEClick={handleSMEClick2}
                                showAddBtn
                                field="collaborators"
                                showReviewBtn={false}
                            /> */}
                            {roleList.map((role) => {
                                return (
                                    <ClientRequirementsSMEInput
                                        key={role.field}
                                        label={role.label}
                                        classes={classes}
                                        smes={roles[role.field] ?? 0}
                                        handleSMEClick={handleSMEClick2}
                                        showAddBtn
                                        field={role.field}
                                        showReviewBtn={false}
                                    />
                                );
                            })}
                            <ClientRequirementsSMEInput
                                label="Added Collaborators"
                                classes={classes}
                                smes={addedCollaborators}
                                handleSMEClick={handleSMEClick2}
                                showAddBtn
                                field="addedCollaborators"
                                showReviewBtn={false}
                            />
                        </GridContainer>
                        {/* <Box className="input-group assigned-group">
                        <Typography
                            variant="body1"
                            className="section-title"
                        >
                            Shortlisted:{' '}
                        </Typography>
                        {smeModal.smes?.length > 0 && (
                            <Box
                                className={`${classes.assignedChipContainer} assigned-smes`}
                            >
                                {smeModal.smes?.map((sme) => {
                                    return (
                                        <Chip
                                            key={sme?._id}
                                            style={{
                                                borderRadius: '5px',
                                                fontSize: '15px',
                                            }}
                                            label={
                                                sme?.firstName +
                                                ' ' +
                                                sme?.lastName
                                            }
                                        />
                                    );
                                })}
                            </Box>
                        )}

                        <Button
                            className="add-btn"
                            onClick={(e) => handleSMEClick(e, true)}
                        >
                            Add / Remove
                        </Button>
                    </Box> */}

                        <Box className="group-container">
                            {/* CONTRACT NUMBER: START */}
                            <Box className="input-group" mt={1}>
                                <Typography
                                    variant="body1"
                                    className="section-title"
                                >
                                    Contract Number:{' '}
                                </Typography>

                                {/* CONTRACT NUMBER INPUT FIELD: START */}
                                <Typography className="section-title">
                                    <TextField
                                        name="contractNumber"
                                        id="contractNumber"
                                        type="text"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        value={contractNumber}
                                        onChange={onContractNumberChange}
                                        // InputProps={{
                                        //     style: { maxWidth: '200px' },
                                        // }}
                                    />
                                </Typography>
                                {/* CONTRACT NUMBER INPUT FIELD: END */}
                            </Box>
                            {/* CONTRACT NUMBER: END*/}

                            {/* work order: START */}
                            {/* <Box className="input-group">
                        <Typography
                            variant="body1"
                            className="section-title"
                        >
                            Work Orders:{' '}
                        </Typography>

                        <Typography className="section-title">
                            <TextField
                                name="workOrder"
                                id="workOrder"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                size="small"
                                value={workOrder}
                                onChange={onWorkOrderChange}
                                InputProps={{
                                    style: { maxWidth: '200px' },
                                }}
                            />
                        </Typography>
                    </Box> */}
                            {/* work order: END*/}

                            <Box className="input-group">
                                <Typography className="section-title">
                                    Tags:{' '}
                                </Typography>

                                <Typography className="section-title tags-input">
                                    <Autocomplete
                                        clearIcon
                                        options={[]}
                                        freeSolo
                                        multiple
                                        size="small"
                                        value={tags}
                                        onChange={onTagsChange}
                                        renderTags={(value, props) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    label={option}
                                                    {...props({ index })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                id="tags"
                                                name="tags"
                                                placeholder="Write the tag and press enter to add"
                                                variant="outlined"
                                                color="secondary"
                                                size="small"
                                                {...params}
                                                // helperText="Press enter to add a tag"
                                            />
                                        )}
                                    />
                                </Typography>
                            </Box>
                        </Box>

                        <TaskUserAssociation
                            selectedRequirementIds={bulkUpdateModalState.ids}
                            selectedOptions={selectedTaskOptions}
                            setSelectedOptions={setSelectedTaskOptions}
                            styles={classes}
                            handleSMEClick2={handleSMEClick2}
                            selectedTaskUsers={selectedTaskUsers}
                        />

                        <Box className="input-group" style={{ marginTop: 20 }}>
                            <TextField
                                multiline
                                minRows={5}
                                value={instructorBudget}
                                onChange={(e) =>
                                    setInstructorBudget(e.target.value)
                                }
                                variant="outlined"
                                color="secondary"
                                label="Terms & Conditions"
                                fullWidth
                                // InputProps={{
                                //     readOnly: readOnly,
                                // }}
                            />
                        </Box>
                        <Box className={classes.defaultSectionContainer}>
                            <Typography
                                variant="h6"
                                onClick={toggleExpand}
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                Student experience
                                {isStudentExperienceExpanded ? (
                                    <ExpandLessIcon />
                                ) : (
                                    <ExpandMoreIcon />
                                )}
                            </Typography>
                            {isStudentExperienceExpanded && (
                                <Box className="group-container">
                                    <Box className="input-group">
                                        <SingleSelect
                                            label="% No experience"
                                            options={getPercentageOptions(
                                                totalExperiencePercent,
                                                percentHavingNoExperience
                                            )}
                                            selectedValue={
                                                percentHavingNoExperience
                                            }
                                            setSelectedValue={
                                                setPercentHavingNoExperience
                                            }
                                            canNotBeNone
                                            readOnly={readOnly}
                                        />
                                    </Box>

                                    <Box className="input-group">
                                        <SingleSelect
                                            label="% 0-1 years"
                                            options={getPercentageOptions(
                                                totalExperiencePercent,
                                                percentHavingZeroToOneExperience
                                            )}
                                            selectedValue={
                                                percentHavingZeroToOneExperience
                                            }
                                            setSelectedValue={
                                                setPercentHavingZeroToOneExperience
                                            }
                                            canNotBeNone
                                            readOnly={readOnly}
                                        />
                                    </Box>

                                    <Box className="input-group">
                                        <SingleSelect
                                            label="% 1-3 years"
                                            options={getPercentageOptions(
                                                totalExperiencePercent,
                                                percentHavingOneToThreeExperience
                                            )}
                                            selectedValue={
                                                percentHavingOneToThreeExperience
                                            }
                                            setSelectedValue={
                                                setPercentHavingOneToThreeExperience
                                            }
                                            canNotBeNone
                                            readOnly={readOnly}
                                        />
                                    </Box>

                                    <Box className="input-group">
                                        <SingleSelect
                                            label="% 4-7 years"
                                            options={getPercentageOptions(
                                                totalExperiencePercent,
                                                percentHavingFourToSevenExperience
                                            )}
                                            selectedValue={
                                                percentHavingFourToSevenExperience
                                            }
                                            setSelectedValue={
                                                setPercentHavingFourToSevenExperience
                                            }
                                            canNotBeNone
                                            readOnly={readOnly}
                                        />
                                    </Box>

                                    <Box className="input-group">
                                        <SingleSelect
                                            label="% > 7 years"
                                            options={getPercentageOptions(
                                                totalExperiencePercent,
                                                percentHavingMoreThanSevenExperience
                                            )}
                                            selectedValue={
                                                percentHavingMoreThanSevenExperience
                                            }
                                            setSelectedValue={
                                                setPercentHavingMoreThanSevenExperience
                                            }
                                            canNotBeNone
                                            readOnly={readOnly}
                                        />
                                    </Box>

                                    <Box className="input-group">
                                        <TextField
                                            value={totalExp}
                                            type="number"
                                            variant="outlined"
                                            label="% Total"
                                            color="secondary"
                                            InputProps={{ readOnly: true }}
                                            style={{ minWidth: '80px' }}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </DialogContent>
                    <DialogActions className={classes.dialogAction}>
                        <Box width="100%" display="flex" mt={3}>
                            <Box>
                                <Button
                                    variant="contained"
                                    // color="primary"
                                    onClick={handleDialogClose}
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box marginLeft="auto">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleUpdateClick}
                                >
                                    {loading ? (
                                        <CircularProgress size="1.9em" />
                                    ) : (
                                        <>Update</>
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </DialogActions>
                </>
            </Dialog>
            {smeModal.isOpen && (
                <SMEModal
                    smeModal={smeModal}
                    setSmeModal={setSmeModal}
                    selectOnly
                    onSmesSelect={onSmesSelect}
                />
            )}
            {smeModal2.isOpen && (
                <SMEModal2
                    smeModal={smeModal2}
                    setSmeModal={setSmeModal2}
                    selectOnly
                    onSmesSelect={onSmesSelect2}
                />
            )}
        </>
    );
};
