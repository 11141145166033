import { ButtonGroup, Button, Box, Hidden } from '../ui';
import { LinkButton } from './LinkButton';

export const Navigation = () => {
    const options = [
        {
            name: '"Aha Moment"',
        },
        {
            name: 'Platform',
        },
        {
            name: 'Courses And Activities',
        },
        {
            name: 'Many Success',
        },
        {
            name: 'Pricing Plans',
        },
    ];
    return (
        <Box>
            <Hidden mdDown>
                <ButtonGroup variant="contained" color="primary">
                    {options.map((item, index) => {
                        return <Button key={index}>{item.name}</Button>;
                    })}
                </ButtonGroup>
            </Hidden>
            <Hidden mdUp>
                <LinkButton options={options} />
            </Hidden>
        </Box>
    );
};
