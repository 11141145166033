import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import {
    Box,
    Button,
    Divider,
    Drawer,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Switch,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { alertState, severity } from '../../../app/recoil';
import { CloseIcon, ShareIcon } from '../../../icons';
import DrawerMultiSelect from '../../../ui/CustomUI/DrawerMultiSelect';
import RequirementSumamrySimpleField from '../ClientRequirements/RequirementSumamrySimpleField';
import GuidanceDuscussions from './GuidanceDuscussions';
import GuidanceTemplates from './GuidanceTemplates';
import GuidanceTutorials from './GuidanceTutorials';
import ShareModal from './ShareModal';
import { roleViewState } from '../../../navigation/recoil/roleView';

const LIVE = 'live';
const DRAFT = 'draft';

const useStyles = makeStyles()((theme) => ({
    boxContainer: {
        width: '100vw',
        paddingTop: theme.spacing(8),
        paddingInline: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: 'min(70vw, 850px)',
            paddingTop: theme.spacing(12),
            paddingInline: theme.spacing(2),
        },
    },
    drawer: {
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.grey['400'],
            borderRadius: '1rem',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.grey['300'],
        },
    },
    drawerHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    fieldContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1),
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            rowGap: '0.5rem',
            columnGap: '1.5rem',
        },
    },
    tabContainer: {
        borderBottom: '1px solid',
        borderColor: theme.palette.divider,
    },
    dropdown: {
        borderBottom: `1px dashed #e0e0e0`,
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(0),
        },
    },
    label: {
        fontWeight: 500,
    },
    select: {
        padding: theme.spacing(0.5),
        border: 'none',
        outline: `1px solid ${theme.palette.grey['400']}`,
        borderRadius: theme.spacing(0.25),
        '&:focus-within': {
            outline: `2px solid ${theme.palette.secondary.main}`,
        },
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
        },
    },
    templateSelect: {
        minWidth: 300,
    },
    templateSelectWrapper: {
        borderRadius: theme.spacing(0.25),
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5),
        '& > input': {
            outline: 'none',
            border: 'none',
            flexGrow: 1,
            padding: 0,
            margin: 0,
        },
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
        },
    },
    templateOptions: {
        position: 'absolute',
        top: 'calc(100% + 5px)',
        left: '0',
        backgroundColor: '#fff',
        zIndex: 10,
        height: '250px',
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            left: '-30%',
            width: '86vw',
        },
    },
}));

function GuidanceDrawer({
    open,
    guidance,
    handleCloseDrawer,
    fetchGuidance,
    logisticsTemplateId,
    hideCTA = false,
    isInstructor = false,
}) {
    const container =
        window !== undefined ? () => window.document.body : undefined;
    const theme = useTheme();
    const isSMDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const { classes } = useStyles();
    const [activeTabVal, setActiveTabVal] = useState(0);
    const [categories, setCategories] = useState([]);
    const [purposes, setPurposes] = useState([]);
    const [priority, setPriority] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const setAlert = useSetRecoilState(alertState);
    const [summary, setSummary] = useState('');
    const [showEdit, setShowEdit] = useState(false);
    const [guidanceTitle, setGuidanceTitle] = useState('');
    const [logisticsTemplateOptions, setLogisticsTemplateOptions] = useState(
        []
    );
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [showShareModal, setShowShareModal] = useState(false);
    const [roleView] = useRecoilState(roleViewState);

    useEffect(() => {
        if (guidance?.summary) {
            setSummary(guidance?.summary);
        }

        if (guidance?.title) {
            setGuidanceTitle(guidance?.title);
        }

        if (guidance?.logisticsTemplates) {
            setSelectedTypes(guidance?.logisticsTemplates);
        }

        if (guidance?.priority) {
            setPriority(guidance.priority);
        }
    }, [
        guidance?.logisticsTemplates,
        guidance?.summary,
        guidance?.title,
        guidance?.priority,
    ]);

    useEffect(() => {
        (async function () {
            try {
                setIsLoading(true);
                const {
                    data: {
                        allOptions: { categories, purposes },
                    },
                } = await axios.get('/api/guidance/all-guidance-options');
                const {
                    data: { templates },
                } = await axios.get(
                    '/api/client-requirements/get-logistic-templates?all=true'
                );
                setPurposes(purposes);
                setCategories(categories);
                setLogisticsTemplateOptions(
                    templates.reduce((prev, curr) => {
                        prev = prev.concat({
                            label: curr.name,
                            value: curr._id,
                        });
                        return prev;
                    }, [])
                );
            } catch (e) {
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    const selectedTypeText = useMemo(() => {
        return logisticsTemplateOptions
            .filter((opt) => selectedTypes.includes(opt.value))
            .map((opt) => opt.label)
            .join(', ');
    }, [logisticsTemplateOptions, selectedTypes]);

    const disableFields = useMemo(() => {
        if (roleView.selectedView === 'instructor') {
            return true;
        }

        return false;
    }, [roleView.selectedView]);

    const handleTabChange = (_, newValue) => {
        setActiveTabVal(newValue);
    };

    const updateGuidance = async (payload) => {
        return axios.patch('/api/guidance/bulk-update', payload);
    };

    const handleChange = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const payload = {
            guidanceIds: [guidance._id],
            [name]: value,
        };
        try {
            setIsLoading(true);
            await updateGuidance(payload);
            await fetchGuidance();
            if (name === 'status' && value === DRAFT) {
                handleCloseDrawer();
            }
            setAlert({
                show: true,
                message: `Successfully updated ${e.target.name}`,
            });
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeSummary = (e) => {
        setSummary(e.target.value);
    };

    const handleSave = async () => {
        try {
            if (summary.trim().length === 0) return;
            setIsLoading(true);
            const payload = {
                guidanceIds: [guidance._id],
                summary,
            };
            await updateGuidance(payload);
            await fetchGuidance();
            setAlert({
                show: true,
                message: `Successfully updated summary`,
            });
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleToggleEdit = () => {
        setShowEdit((prev) => !prev);
    };

    const handleChangeTitle = (e) => {
        setGuidanceTitle(e.target.value);
    };

    const handleUpdateTitle = async () => {
        try {
            if (guidanceTitle.trim().length === 0) return;
            setIsLoading(true);
            const payload = {
                guidanceIds: [guidance._id],
                title: guidanceTitle,
            };
            await updateGuidance(payload);
            await fetchGuidance();
            setAlert({
                show: true,
                message: `Successfully updated guidance title`,
            });
            handleToggleEdit();
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeTemplateOpt = async (opt) => {
        const templateIds = [...selectedTypes];
        const idx = templateIds.findIndex((t) => t === opt);
        if (idx === -1) templateIds.push(opt);
        else templateIds.splice(idx, 1);
        try {
            setIsLoading(true);
            const payload = {
                guidanceIds: [guidance._id],
                logisticsTemplates: templateIds,
            };
            await updateGuidance(payload);
            await fetchGuidance();
            setAlert({
                show: true,
                message: `Successfully updated templates`,
            });
        } catch (e) {
            console.log(e);
            if (e.response.status === 400 || e.response.status === 422) {
                setAlert({
                    show: true,
                    message: e.response.data.message,
                    severity: severity.ERROR,
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleShare = () => {
        setShowShareModal(true);
    };

    const handleToggleStatus = (e) => {
        handleChange({
            target: { name: 'status', value: e.target.checked ? LIVE : DRAFT },
        });
    };

    const handleTogglePublicStatus = (e) => {
        handleChange({
            target: {
                name: 'isPublic',
                value: e.target.checked,
            },
        });
    };

    return (
        <div>
            <Drawer
                container={container}
                variant="persistent"
                anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                ModalProps={{
                    keepMounted: true,
                }}
                classes={{
                    paper: classes.drawer,
                }}
                open={open}
                onClose={handleCloseDrawer}
            >
                <Box className={classes.boxContainer}>
                    <Box className={classes.drawerHeader}>
                        <IconButton size="small" onClick={handleCloseDrawer}>
                            <CloseIcon fontSize={'small'} />
                        </IconButton>
                    </Box>
                    <Divider />
                    <Box pt={2} display={'flex'} alignItems={'center'}>
                        {!showEdit && (
                            <Box className={classes.titleContainer}>
                                <Typography variant="h5">
                                    {guidance?.title}
                                </Typography>
                                {!disableFields && (
                                    <IconButton
                                        size="small"
                                        onClick={handleToggleEdit}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </Box>
                        )}

                        {showEdit && (
                            <Box
                                mb={2}
                                display="flex"
                                alignItems={'center'}
                                sx={{ gap: (theme) => theme.spacing(1) }}
                            >
                                <TextField
                                    size="small"
                                    name="guidanceTitle"
                                    id="guidancetitle"
                                    value={guidanceTitle}
                                    variant="outlined"
                                    color="secondary"
                                    onChange={handleChangeTitle}
                                    disabled={isLoading}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    disabled={
                                                        isLoading ||
                                                        guidance.title ===
                                                            guidanceTitle
                                                    }
                                                    onClick={handleUpdateTitle}
                                                >
                                                    <CheckCircleIcon
                                                        fontSize="small"
                                                        color={
                                                            isLoading ||
                                                            guidance.title ===
                                                                guidanceTitle
                                                                ? 'disabled'
                                                                : 'success'
                                                        }
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <IconButton
                                    size="small"
                                    onClick={handleToggleEdit}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        )}

                        {!disableFields && (
                            <Box ml={2}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="secondary"
                                            name="status"
                                            size="small"
                                            checked={guidance.status === LIVE}
                                            onChange={handleToggleStatus}
                                            disabled={isLoading}
                                        />
                                    }
                                    label="Live: "
                                    labelPlacement="start"
                                />
                            </Box>
                        )}

                        {!disableFields && (
                            <Box ml={2}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            name="isPublic"
                                            size="small"
                                            checked={guidance?.isPublic}
                                            onChange={handleTogglePublicStatus}
                                            disabled={isLoading}
                                        />
                                    }
                                    label="Public: "
                                    labelPlacement="start"
                                />
                            </Box>
                        )}

                        <Box ml="auto">
                            {showShareModal && (
                                <ShareModal
                                    open={showShareModal}
                                    handleClose={() => setShowShareModal(false)}
                                    guidanceId={guidance._id}
                                />
                            )}

                            <IconButton size="small" onClick={handleShare}>
                                <Tooltip title="Share" placement="top-start">
                                    <ShareIcon fontSize="small" />
                                </Tooltip>
                            </IconButton>
                        </Box>
                    </Box>

                    <Box mt={2} className={classes.fieldContainer}>
                        {disableFields ? (
                            <RequirementSumamrySimpleField
                                label={'Category'}
                                value={guidance.category ?? 'N/A'}
                            />
                        ) : (
                            <Box className={classes.dropdown}>
                                <label
                                    htmlFor="category"
                                    className={classes.label}
                                >
                                    Category:
                                </label>
                                <select
                                    name="category"
                                    id="category"
                                    value={guidance.category}
                                    className={classes.select}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                >
                                    {isLoading && (
                                        <option value="">loading...</option>
                                    )}
                                    {categories.map((category) => {
                                        return (
                                            <option value={category}>
                                                {category}
                                            </option>
                                        );
                                    })}
                                </select>
                            </Box>
                        )}

                        {disableFields ? (
                            <RequirementSumamrySimpleField
                                label={'Purpose'}
                                value={guidance.purpose ?? 'N/A'}
                            />
                        ) : (
                            <Box className={classes.dropdown}>
                                <label
                                    htmlFor="purpose"
                                    className={classes.label}
                                >
                                    Purpose:
                                </label>
                                <select
                                    name="purpose"
                                    id="purpose"
                                    value={guidance.purpose}
                                    className={classes.select}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                >
                                    {isLoading && (
                                        <option value="">loading...</option>
                                    )}
                                    {purposes.map((purpose) => {
                                        return (
                                            <option value={purpose}>
                                                {purpose}
                                            </option>
                                        );
                                    })}
                                </select>
                            </Box>
                        )}

                        {disableFields ? (
                            <RequirementSumamrySimpleField
                                label={'Priority'}
                                value={priority ?? 'N/A'}
                            />
                        ) : (
                            <Box className={classes.dropdown}>
                                <label
                                    htmlFor="priority"
                                    className={classes.label}
                                >
                                    Priority:
                                </label>
                                <select
                                    name="priority"
                                    id="priority"
                                    value={priority}
                                    className={classes.select}
                                    disabled={isLoading || disableFields}
                                    onChange={handleChange}
                                    style={{
                                        color:
                                            priority === 'Critical'
                                                ? 'red'
                                                : '',
                                    }}
                                >
                                    {isLoading && (
                                        <option value="">loading...</option>
                                    )}
                                    <option
                                        value="Nice to have"
                                        style={{ color: 'black' }}
                                    >
                                        Nice to have
                                    </option>
                                    <option
                                        value="Critical"
                                        style={{ color: 'red' }}
                                    >
                                        Critical
                                    </option>
                                </select>
                            </Box>
                        )}

                        {disableFields ? (
                            <RequirementSumamrySimpleField
                                label={'Status'}
                                value={guidance?.status ?? 'N/A'}
                                isComponent={true}
                                componentLoad={
                                    <span
                                        style={{ textTransform: 'capitalize' }}
                                    >
                                        {guidance?.status}
                                    </span>
                                }
                            />
                        ) : (
                            <Box className={classes.dropdown}>
                                <label
                                    htmlFor="status"
                                    className={classes.label}
                                >
                                    Status:
                                </label>
                                <select
                                    name="status"
                                    id="status"
                                    value={guidance.status}
                                    className={classes.select}
                                    disabled={isLoading || disableFields}
                                    onChange={handleChange}
                                >
                                    {isLoading && (
                                        <option value="">loading...</option>
                                    )}
                                    <option value={DRAFT}>DRAFT</option>
                                    <option value={LIVE}>LIVE</option>
                                </select>
                            </Box>
                        )}

                        {disableFields ? (
                            <RequirementSumamrySimpleField
                                label={'Logistics'}
                                value={selectedTypeText ?? 'N/A'}
                                isComponent={true}
                            />
                        ) : (
                            <Box className={classes.dropdown}>
                                <DrawerMultiSelect
                                    handleChangeOpt={handleChangeTemplateOpt}
                                    isLoading={isLoading}
                                    label={'Logistics'}
                                    options={logisticsTemplateOptions}
                                    selectedOptTxt={selectedTypeText}
                                    selectedOptions={selectedTypes}
                                    fitWidth={true}
                                    minWidth={isSMDevice ? 'auto' : '265px'}
                                />
                            </Box>
                        )}

                        <RequirementSumamrySimpleField
                            label={'Created by'}
                            value={guidance?.createdBy?.fullName ?? 'N/A'}
                        />

                        <RequirementSumamrySimpleField
                            label={'Last updated'}
                            value={moment(guidance.updatedAt).format(
                                'DD-MMM-YYYY'
                            )}
                        />

                        <RequirementSumamrySimpleField
                            label={'Created on'}
                            value={moment(guidance.createdAt).format(
                                'DD-MMM-YYYY'
                            )}
                        />
                    </Box>

                    <Box mt={2} className={classes.tabContainer}>
                        <Tabs
                            value={activeTabVal}
                            onChange={handleTabChange}
                            aria-label="guidance tabs"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab label="Summary" value={0} />
                            <Tab label="Tutorials" value={1} />
                            <Tab label="Documents" value={2} />
                            <Tab label="Discussion" value={3} />
                        </Tabs>
                    </Box>

                    <Box my={2} py={2} px={1}>
                        {activeTabVal === 0 && (
                            <Box>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    color="secondary"
                                    multiline
                                    maxRows={10}
                                    minRows={10}
                                    value={summary}
                                    onChange={handleChangeSummary}
                                    fullWidth
                                    InputProps={{ readOnly: disableFields }}
                                />
                                {!disableFields && (
                                    <Box mt={2} textAlign={'right'}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disabled={isLoading}
                                            onClick={handleSave}
                                        >
                                            {isLoading ? 'Please wait' : 'Save'}
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        )}

                        {activeTabVal === 1 && (
                            <GuidanceTutorials
                                tutorials={guidance.tutorials}
                                guidanceId={guidance._id}
                                fetchGuidance={fetchGuidance}
                                logisticsTemplateId={logisticsTemplateId}
                                hideCTA={hideCTA}
                            />
                        )}

                        {activeTabVal === 2 && (
                            <GuidanceTemplates
                                templates={guidance.templates}
                                guidanceId={guidance._id}
                                hideCTA={hideCTA}
                            />
                        )}

                        {activeTabVal === 3 && (
                            <GuidanceDuscussions
                                guidance={guidance}
                                isInstructor={isInstructor}
                            />
                        )}
                    </Box>
                </Box>
            </Drawer>
        </div>
    );
}

export default GuidanceDrawer;
