import { atom } from 'recoil';

// export const socketState = atom({
//     key: 'socketState',
//     default: {},
// });

export const severity = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
};

export const alertState = atom({
    key: 'alertState',
    default: {
        message: '',
        severity: 'success',
        show: false,
    },
});
