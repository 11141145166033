import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import {
    Checkbox,
    FormControl,
    InputBase,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
} from '../';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        // variant: 'outlined',
        // color: 'secondary',
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const CustomInput = withStyles((theme) => ({
    // root: {
    //     'label + &': {
    //         marginTop: theme.spacing(3),
    //     },
    // },
    input: {
        minWidth: 139,
        borderRadius: 2,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #c3c3c3',
        fontSize: 16,
        padding: '17.5px 31px 17.5px 13px',
        //transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
            border: `1px solid black`,
            borderRadius: 2,
            // borderRadius: 4,
            // borderColor: '#80bdff',
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
        '&:focus': {
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: 2,
            // borderRadius: 4,
            // borderColor: '#80bdff',
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

export const MultipleSelectCheckbox = ({
    label,
    options,
    selection, //can be an Array or a Map
    setSelection,
}) => {
    const handleChange = (event) => {
        if (selection instanceof Map)
            setSelection(new Map(event.target.value.map((key) => [key, true])));
        else setSelection(event.target.value);
    };

    // const handleOpen = () => {
    //     const body = document.querySelector('body');
    //     body.style.height = '100vh';
    //     body.style.overflowY = 'hidden';
    // };

    // const handleClose = () => {
    //     const body = document.querySelector('body');
    //     body.style.height = 'auto';
    //     body.style.overflowY = 'auto';
    // };

    return (
        <>
            <FormControl>
                {/* <InputLabel>Tag</InputLabel> */}
                <Select
                    multiple
                    value={
                        selection instanceof Map
                            ? [...selection.keys()]
                            : selection
                    }
                    onChange={handleChange}
                    input={
                        <CustomInput />
                        //<Input />
                    }
                    displayEmpty
                    renderValue={(selected) =>
                        label
                            ? label
                            : selection instanceof Map
                            ? [...selection.keys()].join(', ')
                            : selected.join(', ')
                    }
                    MenuProps={MenuProps}
                    variant="outlined"
                    // onOpen={handleOpen}
                    // onClose={handleClose}
                >
                    {options.map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox
                                checked={
                                    selection instanceof Map
                                        ? selection.has(name)
                                        : selection.indexOf(name) > -1
                                }
                            />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};
