import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    IconButton,
    useMediaQuery,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Actions, Loading } from '../ui';
import {
    DataGridPremium,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';
// import { VariablePieChart } from '../custom-components/charts';
// import { async } from 'q';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { useIsAdmin } from './useIsAdmin';
import { CloseIcon } from '../icons';
import { LinearLoader } from '../util';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchExpertById,
    setApplicantsModal,
} from '../instructor/slices/expertSummarySlice';

const useStyles = makeStyles()((theme) => ({
    // paper: {
    //     minWidth: '90vw',
    //     [theme.breakpoints.up('md')]: {
    //         minWidth: 1000,
    //     },
    // },
    tableContainer: {
        height: 280,
        width: '100%',
    },
    dialogAction: {
        marginRight: 'auto',
        marginLeft: '1rem',
        paddingBottom: '0.5rem',
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    synopsis: {
        cursor: 'pointer',
    },
    truncatedText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '0.875rem',
    },
    link: {
        fontWeight: 'bold',
        color: theme.palette.common.lightBlue,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const InstructorApplicantsModal = (props) => {
    const {
        applicantsInstructorModal = { isOpen: true, instructorIds: [] },
        setApplicantsInstructorModal,
        setAllRequirements,
        fetchData,
        forceFetchData = false,
    } = props;

    const { classes } = useStyles();

    const { /* starweaverAdmin, */ isLoading: isAdminLoading } = useIsAdmin();

    const [gridRows, setGridRows] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isShowMoreClicked /* , setShowMoreClick */] = useState(true);
    const dispatch = useDispatch();
    const expertSummary = useSelector((state) => state.expertSummary);
    const [selectedRow, setSelectedRow] = useState([]);
    const isLargeScreen = useMediaQuery('(min-width:900px)');
    const [pinnedColumns, setPinnedColumns] = useState({
        left: isLargeScreen
            ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'user_firstName']
            : [],
    });
    // HOOKS
    const memoizedRows = useMemo(() => {
        if (isShowMoreClicked) {
            return gridRows;
        } else {
            return gridRows.slice(0, 3);
        }
    }, [gridRows, isShowMoreClicked]);

    //DIALOG CLOSE HANDLER FUNCTION
    const handleDialogClose = useCallback(() => {
        const modalPayload = {
            instructorIds: [],
            isOpen: false,
            id: null,
        };

        if (expertSummary.applicantsModalData?.isOpen) {
            dispatch(setApplicantsModal(modalPayload));
        }

        setApplicantsInstructorModal((prev) => {
            return modalPayload;
        });
    }, [
        dispatch,
        expertSummary.applicantsModalData?.isOpen,
        setApplicantsInstructorModal,
    ]);

    useEffect(() => {
        (async () => {
            try {
                if (applicantsInstructorModal?.instructorIds?.length) {
                    setIsLoading(true);
                    // console.log(
                    //     `/api/client-requirements/applicants?instructorIds=${applicantsInstructorModal?.instructorIds.join(
                    //         ','
                    //     )}`
                    // );
                    let res = await axios.get(
                        `/api/client-requirements/applicants?instructorIds=${applicantsInstructorModal?.instructorIds.join(
                            ','
                        )}`
                    );

                    setGridRows(res.data);
                    setIsLoading(false);
                }
            } catch (error) {
                // console.log(error);
                handleDialogClose();
            }
        })();
    }, [applicantsInstructorModal?.instructorIds, handleDialogClose]);

    const handleShowSummary = (e, row) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(setApplicantsModal(applicantsInstructorModal));
        dispatch(fetchExpertById(row._id));
        handleDialogClose();
    };

    const gridCols = [
        // {
        //     field: 'slNo',
        //     headerName: '#',
        //     width: 150,
        //     editable: false,
        // },
        {
            field: 'user_firstName',
            headerName: 'Name',
            width: 200,
            editable: false,
            valueFormatter: (value, row) => {
                return `${row.user_firstName} ${row.user_lastName}`;
            },
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={1}
                        height={'100%'}
                    >
                        {`${row.user_firstName} ${row.user_lastName}`}
                        <IconButton
                            size="small"
                            onClick={(e) => handleShowSummary(e, row)}
                        >
                            <ContactPageIcon fontSize="small" color="primary" />
                        </IconButton>
                    </Box>
                );
            },
        },
        {
            field: 'synopsis',
            headerName: 'Synopsis',
            width: 300,
            editable: false,
            filterable: false,
            sortable: false,
            valueFormatter: (value) => {
                return value;
            },
            renderCell: ({ row }) => {
                const rating =
                    typeof row.expertRating === 'number'
                        ? row.expertRating.toFixed(1)
                        : 'N/A';
                return (
                    <Box
                        minWidth={280}
                        onClick={(e) => handleShowSummary(e, row)}
                        className={classes.synopsis}
                    >
                        <Box>
                            {row.inProgressCount} In Progress |{' '}
                            {row.completedCount} Completed
                        </Box>
                        <Box>
                            Location: {row.country} | Avg Rating: {rating}
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'user_email',
            headerName: 'Email',
            width: 200,
            editable: false,
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <a
                        href={`mailto:${row.user_email}`}
                        className={`${classes.truncatedText} ${classes.link}`}
                    >
                        {row.user_email}
                    </a>
                );
            },
        },

        {
            field: 'mobile_number',
            headerName: 'Mobile',
            width: 150,
            editable: false,
            display: 'flex',
        },
        {
            field: 'country',
            headerName: 'Country',
            width: 150,
            editable: false,
            display: 'flex',
        },

        {
            field: 'linkedin',
            headerName: 'LinkedIn',
            width: 250,
            editable: false,
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <a
                        href={row.linkedin}
                        target="_blank"
                        rel="noreferrer"
                        className={`${classes.truncatedText} ${classes.link}`}
                    >
                        {row.linkedin}
                    </a>
                );
            },
        },
    ];

    /* const handleShowMore = () => {
        setShowMoreClick((prev) => !prev);
    }; */

    const actions = [
        {
            name: 'Shortlist',
            onClick: async () => {
                if (selectedRow.length === 0) return;
                setIsLoading(true);

                let res = await axios.post(
                    `/api/smes/set-instructor-to-requirement`,
                    {
                        clientRequirementIds: [applicantsInstructorModal?.id],
                        instructorIds: selectedRow,
                    }
                );

                if (setAllRequirements) {
                    setAllRequirements((prev) => {
                        const requirementsCopy = [...prev];
                        const requirementToEdit = requirementsCopy.find(
                            ({ _id }) => _id == applicantsInstructorModal.id
                        );

                        if (requirementToEdit.shortListedSmes)
                            requirementToEdit.shortListedSmes = [
                                ...res.data,
                                ...requirementToEdit.shortListedSmes,
                            ];
                        else requirementToEdit.shortListedSmes = res.data;

                        const requirementOthers = requirementsCopy.filter(
                            ({ _id }) => _id != applicantsInstructorModal.id
                        );

                        const ret = [requirementToEdit, ...requirementOthers];

                        return ret;
                    });
                }
                if (forceFetchData) {
                    await fetchData();
                }
                handleDialogClose();
                setIsLoading(false);
                if (!forceFetchData) {
                    await fetchData();
                }
            },
        },
        {
            name: 'Remove',
            onClick: async () => {
                try {
                    setIsLoading(true);
                    const data = {
                        userIdList: selectedRow,
                        requirementId: applicantsInstructorModal?.id,
                    };
                    const url = '/api/client-requirements/remove-applicants';
                    await axios.post(url, data);

                    /* setAllRequirements((requirements) => {
                        return requirements.map((requirement) => {
                            if (requirement._id === data.requirementId) {
                                const updatedRequirement = { ...requirement };

                                updatedRequirement.interestedIntstructorIds = updatedRequirement?.interestedIntstructorIds?.filter(
                                    (id) => !data.userIdList.includes(id)
                                );

                                updatedRequirement.interests = updatedRequirement?.interests?.filter(
                                    (obj) =>
                                        !data.userIdList.includes(obj.userId)
                                );

                                return updatedRequirement;
                            } else {
                                return requirement;
                            }
                        });
                    }); */
                    if (forceFetchData) {
                        await fetchData();
                    }
                    setIsLoading(false);
                    handleDialogClose();
                } catch (e) {
                    console.error(e);
                    handleDialogClose();
                    setIsLoading(false);
                }
            },
        },
    ];

    return (
        // <Box>
        <Dialog
            // onClose={handleDialogClose}
            aria-labelledby="matching-instructor-data-modal"
            open={applicantsInstructorModal.isOpen}
            maxWidth="md"
            // classes={{ paper: classes.paper }}
            scroll="paper"
            fullWidth
        >
            <DialogTitle>
                <span className={classes.dialogTitle}>
                    <span>Applicants</span>
                    <IconButton onClick={handleDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </span>
            </DialogTitle>
            {/* {isLoading || isAdminLoading ? (
                <Loading height="400px" />
            ) : (
                <Box> */}
            {/* {chartData.length > 0 && (
                        <VariablePieChart
                            enableLegend={true}
                            title={'Matched Instructor'}
                            seriesName={'test series name'}
                            chartData={chartData}
                            pointFormat={'<b>Revenue: {point.y}%</b>'}
                        />
                    )} */}
            <DialogContent dividers>
                <Box display={'flex'} marginBottom={2}>
                    <Box marginLeft={'auto'}>
                        <Actions
                            actions={actions}
                            disabled={selectedRow.length === 0}
                        />
                    </Box>
                </Box>
                {(isLoading || isAdminLoading || expertSummary.isLoading) && (
                    <Box>
                        <LinearLoader />
                    </Box>
                )}
                <Box className={classes.tableContainer}>
                    <DataGridPremium
                        autoHeight
                        rows={memoizedRows}
                        columns={gridCols}
                        pageSizeOptions={[]}
                        hideFooter={true}
                        hideFooterSelectedRowCount={true}
                        // disableColumnMenu={true}
                        disableColumnFilter={true}
                        disableColumnSelector={true}
                        rowSelectionModel={selectedRow} //added line
                        onRowSelectionModelChange={(param) =>
                            setSelectedRow(param /* .selectionModel */)
                        }
                        pinnedColumns={pinnedColumns}
                        onPinnedColumnsChange={(updatedPinnedColumns) => {
                            if (isLargeScreen) {
                                setPinnedColumns(updatedPinnedColumns);
                            } else {
                                alert(
                                    'Pinning is not available on small screens.'
                                );
                            }
                        }}
                        checkboxSelection
                        getRowHeight={() => 'auto'}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                {/* <Box className={classes.dialogAction}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ margin: 0 }}
                                    onClick={handleShowMore}
                                >
                                    {isShowMoreClicked
                                        ? 'SHOW LESS'
                                        : 'SHOW MORE'}
                                </Button>
                            </Box> */}
            </DialogActions>
            {/* </Box>
            )} */}
        </Dialog>
        // </Box>
    );
};

export default InstructorApplicantsModal;

/* function instructorsCompare(a, b) {
    if (a.match < b.match) return 1;

    if (a.match > b.match) return -1;

    return 0;
}
 */
