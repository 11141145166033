import { useTheme } from '@emotion/react';
import StarIcon from '@mui/icons-material/Star';
import {
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import RequirementSumamrySimpleField from '../../admin/components/ClientRequirements/RequirementSumamrySimpleField';
import SummaryDrawerHeader from '../../admin/components/ClientRequirements/SummaryDrawerHeader';
import userImg from '../../img/user.png';
import { useCurrentUser } from '../../my-account/useCurrentUser';
import {
    setProjectsModalVisible,
    toggleShowSummary,
} from '../slices/expertSummarySlice';
import ExpertSummaryDrawerTabs from './ExpertSummaryDrawerTabs';
import { useIsAdmin } from '../../admin/useIsAdmin';
import { useChat } from '../../chat/useChat';
import { Visibility } from '@mui/icons-material';
import MessageIcon from '@mui/icons-material/Message';
import { setExpertModalState } from '../../admin/slices/expertProjectModalSlice';
import { toggleChat } from '../../chat/slices/chatSlice';
import { ChannelStatus } from '../../admin/ChannelStatus';
import { LinkIcon } from '../../icons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
    boxContainer: {
        width: '100vw',
        paddingTop: theme.spacing(8),
        paddingInline: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            width: 'min(70vw, 850px)',
            paddingTop: theme.spacing(12),
            paddingInline: theme.spacing(2),
        },
    },
    drawer: {
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.grey['400'],
            borderRadius: '1rem',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.grey['300'],
        },
    },
    imgWrapper: {
        width: '100%',
        maxWidth: '8rem',
        marginInline: 'auto',
        aspectRatio: 1,
        position: 'relative',
        '& > img': {
            width: '100%',
            height: '100%',
            display: 'block',
            objectFit: 'cover',
            borderRadius: '50%',
            cursor: 'pointer',
        },
        "& [data-testid='chat-button']": {
            position: 'absolute',
            top: '105%',
            left: '50%',
            transform: 'translateX(-50%)',
        },
    },
    summaryContainer: {
        display: 'grid',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '1fr 12rem',
        },
    },
    fieldContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1),
        flexDirection: 'column',
        overflowX: 'hidden',
        order: 2,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            rowGap: '0.05rem',
            columnGap: '1.5rem',
            order: 0,
        },
    },
    expertName: {
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left',
        },
    },
    emailLink: {
        cursor: 'default',
        "& [data-testid='requirement-summary-label']": {
            cursor: 'default',
        },
        "& [data-testid='requirement-summary-value']": {
            color: 'blue',
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    link: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export const metrics = {
    expertise: {
        label: 'Skill/Experience/Domain Knowledge',
        params: [
            'Superlative/Unmatched',
            'Expert/Highly Proficient',
            'Strong/Very Capable',
            'Above Average/Competent',
            'Proficient/Meets Expectations',
            'Average/Adequate',
            'Basic/Room for Improvement',
            'Limited/Significant Gaps',
            'Weak/Not Competent',
            'None/Lacks Basic Knowledge',
        ],
    },
    sweetheart: {
        label: 'Sweetheart/Flexibility/ Ease of Working',
        params: [
            'Superlative/Unmatched',
            'Delightful/Highly Cooperative',
            'Very Easy/Highly Flexible',
            'Pleasant/Easy to Work With',
            'Cooperative/Mostly Smooth',
            'Neutral/Average Interaction',
            'Inflexible/Some Frustrations',
            'Difficult/Inconsistent',
            'Combative/Hard to Manage',
            'Nightmare/Run Away/Terrible',
        ],
    },
    availability: {
        label: 'Availability to Work',
        params: [
            'Fully Available/Can Work Full-Time',
            'Nearly Full-Time/Highly Available',
            'Available for Significant Hours',
            'Consistently Available Part-Time',
            'Regularly Available/Moderate Hours',
            'Limited Availability/Somewhat Reliable',
            'Occasionally Available/Infrequent',
            'Rarely Available/Minimal Commitment',
            'Very Limited Availability/Unreliable',
            'Not Available at All/Unavailable',
        ],
    },
};

export default function ExpertSummaryDrawer({
    setRatingModal = () => console.log(),
    setApplicantsInstructorModal = () => console.log(),
}) {
    const container =
        window !== undefined ? () => window.document.body : undefined;
    const { classes } = useStyles();
    const theme = useTheme();
    const expertSummary = useSelector((state) => state.expertSummary);
    const dispatch = useDispatch();
    const [fetchingRatings, setFetchingRatings] = useState(false);
    const [ratings, setRatings] = useState({});
    const { user } = useCurrentUser();
    const { starweaverAdmin } = useIsAdmin();
    const { getUserChat } = useChat();
    const [fetchingProjects, setFetchingProjects] = useState(false);
    const [projectCount, setProjectCount] = useState({
        progress: 0,
        completed: 0,
    });
    const [userWPProfile, setUserWPProfile] = useState();
    const [fetchingExpertData, setFetchingExpertData] = useState(false);

    const fetchRatings = useCallback(async () => {
        try {
            setFetchingRatings(true);
            const { data } = await axios.get(
                `/api/instructor-rating/overall-instructor-rating?instructorId=${expertSummary.expertData._id}`
            );
            if (data.overallRating.length > 0) {
                setRatings(data.overallRating[0]);
            } else {
                setRatings({});
            }
        } catch (e) {
            console.log(e);
        } finally {
            setFetchingRatings(false);
        }
    }, [expertSummary.expertData._id]);

    useEffect(() => {
        fetchRatings();
    }, [fetchRatings]);

    useEffect(() => {
        const api = `/api/client-requirements?page=0&limit=10&userId=${user?._id}&smeEmail=${expertSummary.expertData?.user_email}&status=IN+PROGRESS`;
        setFetchingProjects(true);
        axios
            .get(api)
            .then((res) => {
                const { completedCount, progressCount } = res.data;
                setProjectCount({
                    completed: completedCount || 0,
                    progress: progressCount || 0,
                });
            })
            .catch(console.error)
            .finally(() => setFetchingProjects(false));
    }, [expertSummary.expertData?.user_email, user?._id]);

    const getChannelUrl = useCallback((slug) => {
        if (process.env.NODE_ENV === 'development') {
            return `http://localhost:3005/channels/${slug}`;
        }

        return `${process.env.REACT_APP_DO_DOMAIN}/channels/${slug}`;
    }, []);

    const expertName = useMemo(() => {
        return `${expertSummary.expertData.user_firstName} ${expertSummary.expertData.user_lastName}`;
    }, [
        expertSummary.expertData.user_firstName,
        expertSummary.expertData.user_lastName,
    ]);

    const expertDomains = useMemo(() => {
        if (expertSummary.expertData.domains?.length > 0) {
            return expertSummary.expertData.domains.join(', ');
        }
        return 'N/A';
    }, [expertSummary.expertData.domains]);

    const expertTags = useMemo(() => {
        const tags = expertSummary.expertData.tags || [];
        return tags.length === 0 ? 'N/A' : tags.join(', ');
    }, [expertSummary.expertData.tags]);

    const expertSMEEngagements = useMemo(() => {
        const users = expertSummary.expertData.SMEengagements || [];
        return users.length > 0
            ? users
                  .map((user) => user.firstName + ' ' + user.lastName)
                  .join(', ')
            : 'N/A';
    }, [expertSummary.expertData.SMEengagements]);

    const ratingText = useMemo(() => {
        const ratingObj = {
            ...Object.keys(metrics).reduce(
                (acc, key) => ({ ...acc, [key]: 'N/A' }),
                {}
            ),
        };

        Object.entries(ratings).forEach(([key, value]) => {
            if (key in ratingObj) {
                ratingObj[key] = value ? Number(value).toFixed(1) : 'N/A';
            }
        });

        return Object.entries(ratingObj)
            .map(
                ([key, value]) =>
                    `${key.charAt(0).toUpperCase()}${key.slice(1)}: ${value}`
            )
            .join(' | ');
    }, [ratings]);

    const showRateButton = useMemo(() => {
        const ems = expertSummary.expertData.SMEengagements || [];
        return (
            starweaverAdmin ||
            ems.filter((em) => em._id === user?._id).length > 0
        );
    }, [expertSummary.expertData.SMEengagements, starweaverAdmin, user?._id]);

    const showChannelDrillDown = useMemo(() => {
        return (
            expertSummary.expertData?.instructor_details?.channelStatus ===
                'publish' && expertSummary.expertData?.slug
        );
    }, [
        expertSummary.expertData?.instructor_details?.channelStatus,
        expertSummary.expertData?.slug,
    ]);

    // formatted linkedin url
    const formattedLinkedInURL = useMemo(() => {
        const { user_linkedInUrl: url } = expertSummary.expertData;
        if (!url) {
            return 'N/A';
        }
        if (url && url.startsWith('linkedin.com/')) {
            return `https://www.${url}`;
        }
        return url;
    }, [expertSummary.expertData]);

    useEffect(() => {
        (async function () {
            try {
                setFetchingExpertData(true);
                const [techbytesRes, jobRolesRes] = await Promise.all([
                    axios.get(
                        `/api/techbytes?instructorIds=${expertSummary.expertData._id}`
                    ),
                    axios.get(
                        `/api/job-roles?instructorIds=${expertSummary.expertData._id}`
                    ),
                ]);

                const relatedArticles = techbytesRes.data.articles;
                const relatedJobTitles = jobRolesRes.data.jobRoles;

                setUserWPProfile({
                    bannerPic:
                        expertSummary.expertData?.instructor_details
                            ?.channelBannerPic,
                    bio: expertSummary.expertData?.instructor_details?.intro,
                    email: expertSummary.expertData?.user_email,
                    id: expertSummary.expertData?.instructorPostId,
                    intro: expertSummary.expertData?.instructor_details?.intro,
                    name:
                        expertSummary.expertData?.user_firstName +
                        ' ' +
                        expertSummary.expertData?.user_lastName,
                    post_type: 'instructor',
                    profilePic: expertSummary.expertData?.profilePic,
                    profilePicNoBackground:
                        expertSummary.expertData?.profilePic,
                    relatedArticles,
                    relatedJobTitles,
                    slug: expertSummary.expertData?.slug,
                    status:
                        expertSummary.expertData?.instructor_details
                            ?.channelStatus || 'draft',
                    tagLine:
                        expertSummary.expertData?.instructor_details?.tag_line,
                });
            } catch (e) {
                console.log(e);
            } finally {
                setFetchingExpertData(false);
            }
        })();
    }, [expertSummary.expertData]);

    const handleClose = () => {
        dispatch(toggleShowSummary(false));
        if (expertSummary.applicantsModalData?.isOpen) {
            setApplicantsInstructorModal(expertSummary.applicantsModalData);
        }
        if (expertSummary.projectModal.isOpen) {
            dispatch(
                setProjectsModalVisible({
                    isOpen: false,
                    expertUserId: null,
                })
            );
        }
    };

    const handleOpenRatingModal = () => {
        setRatingModal(() => {
            return {
                open: true,
                expertUserId: expertSummary.expertData._id,
            };
        });
        dispatch(toggleShowSummary(false));
    };

    const handleChat = () => {
        try {
            dispatch(toggleChat(true));
            getUserChat(expertSummary.expertData._id, true);
        } catch (error) {
            console.log(error);
        }
    };

    // show projects
    const handleShowProjects = () => {
        // close the drawer
        dispatch(toggleShowSummary(false));

        // set project dialog
        dispatch(
            setExpertModalState({
                open: true,
                email: expertSummary.expertData.user_email,
                requirements: [],
            })
        );

        // set the project dialog
        dispatch(
            setProjectsModalVisible({
                isOpen: true,
                expertUserId: expertSummary.expertData._id,
            })
        );
    };
    return (
        <Drawer
            container={container}
            variant="persistent"
            anchor={theme.direction === 'rtl' ? 'left' : 'right'}
            open={expertSummary.showSummary}
            ModalProps={{
                keepMounted: true,
            }}
            classes={{
                paper: classes.drawer,
            }}
            onClose={handleClose}
        >
            <Box className={classes.boxContainer}>
                <SummaryDrawerHeader
                    handleClose={handleClose}
                    title="Expert Profile"
                />
                <Divider />
                <Box data-id="expert-name-container" pt={2}>
                    <Typography variant="h5" className={classes.expertName}>
                        <Link
                            to={`/admin/user-profiles/active/${expertSummary.expertData._id}`}
                            target="_blank"
                            className={classes.link}
                        >
                            {expertName}
                        </Link>
                        {/* {expertSummary.expertData.slug && (
                            <Tooltip title="View channel">
                                <IconButton
                                    component="a"
                                    href={getChannelUrl(
                                        expertSummary.expertData.slug
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ ml: 1, color: 'blue' }}
                                >
                                    <LinkIcon />
                                </IconButton>
                            </Tooltip>
                        )} */}
                    </Typography>
                </Box>
                <Box
                    data-id="symmary-container"
                    className={classes.summaryContainer}
                >
                    <Box className={classes.fieldContainer}>
                        <a
                            href={`mailto:${expertSummary.expertData.user_email}`}
                            className={classes.emailLink}
                        >
                            <RequirementSumamrySimpleField
                                label="Email"
                                value={expertSummary.expertData.user_email}
                            />
                        </a>
                        <RequirementSumamrySimpleField
                            label="Country"
                            value={
                                expertSummary.expertData.user_country || 'N/A'
                            }
                        />
                        <RequirementSumamrySimpleField
                            label="LinkedIn"
                            value={formattedLinkedInURL}
                            isLink={true}
                        />
                        <RequirementSumamrySimpleField
                            label="Domains"
                            value={expertDomains}
                        />
                        <RequirementSumamrySimpleField
                            label="Tags"
                            value={expertTags}
                        />
                        <RequirementSumamrySimpleField
                            label="EMs"
                            value={expertSMEEngagements}
                        />
                        <Box
                            display={'flex'}
                            items="center"
                            gap={2}
                            flexWrap="wrap"
                        >
                            <RequirementSumamrySimpleField
                                label="Channel Status"
                                isComponent={true}
                                isLoading={fetchingExpertData}
                                componentLoad={
                                    <ChannelStatus
                                        userProfile={expertSummary.expertData}
                                        userWPProfile={userWPProfile}
                                        hideProgressBar={true}
                                        showContext={
                                            expertSummary.expertData
                                                ?.instructor_details
                                                ?.channelStatus === 'publish'
                                                ? 'Published'
                                                : 'Draft'
                                        }
                                        channelStatus={
                                            expertSummary.expertData
                                                ?.instructor_details
                                                ?.channelStatus
                                        }
                                    />
                                }
                            />
                            {showChannelDrillDown && (
                                <IconButton
                                    data-testid="channel-link"
                                    size="small"
                                    component="a"
                                    href={
                                        getChannelUrl(
                                            expertSummary.expertData.slug
                                        ) + '/about-me'
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Visibility fontSize="small" />
                                </IconButton>
                            )}
                        </Box>
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={2}
                            flexWrap="wrap"
                        >
                            <RequirementSumamrySimpleField
                                label="Projects"
                                value={`${projectCount.progress} In Progress | ${projectCount.completed} Completed`}
                                isLoading={fetchingProjects}
                            />
                            <IconButton
                                size="small"
                                onClick={handleShowProjects}
                                disabled={fetchingProjects}
                            >
                                <Visibility fontSize="small" />
                            </IconButton>
                        </Box>
                        <Box
                            display={'flex'}
                            items="center"
                            gap={2}
                            flexWrap="wrap"
                        >
                            <RequirementSumamrySimpleField
                                label="Ratings"
                                isLoading={fetchingRatings}
                                value={ratingText}
                            />
                            {showRateButton && (
                                <Button
                                    startIcon={<StarIcon fontSize="small" />}
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    disabled={fetchingRatings}
                                    onClick={handleOpenRatingModal}
                                >
                                    Rate
                                </Button>
                            )}
                        </Box>
                    </Box>
                    <Box>
                        <div
                            className={classes.imgWrapper}
                            data-testid="profile"
                        >
                            <img
                                className={classes.profileImg}
                                src={
                                    expertSummary.expertData.profilePic ||
                                    expertSummary.expertData?.instructor_details
                                        ?.channelProfilePic ||
                                    expertSummary.expertData.user_url ||
                                    userImg
                                }
                                alt={expertSummary.expertData.user_firstName}
                                height={400}
                                width={400}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = userImg;
                                }}
                                onClick={handleChat}
                                data-testid="profile-image"
                            />
                            {user._id !== expertSummary.expertData._id && (
                                <IconButton
                                    size="small"
                                    data-testid="chat-button"
                                    color="primary"
                                    onClick={handleChat}
                                >
                                    <MessageIcon
                                        fontSize="small"
                                        color="primary"
                                    />
                                </IconButton>
                            )}
                        </div>
                    </Box>
                </Box>
                <Box data-id="tabs" mt={2}>
                    <ExpertSummaryDrawerTabs />
                </Box>
            </Box>
        </Drawer>
    );
}
