import { Backdrop, CircularProgress } from '@mui/material';

const PageLoader = ({ open }) => {
    return (
        <Backdrop
            sx={{
                color: (theme) => theme.palette.secondary.main,
                zIndex: (theme) => theme.zIndex.modal + 1,
            }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default PageLoader;
