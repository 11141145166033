import React, { useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../../app/recoil';
import moment from 'moment';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import axios from 'axios';
import { getBaseURL } from '../../../util';

function ShareModal({ open, handleClose, guidanceId }) {
    const [expiresOn, setExpiresOn] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const setAlert = useSetRecoilState(alertState);

    useEffect(() => {
        setExpiresOn(moment().add(30, 'days').format('YYYY-MM-DDTHH:mm'));
    }, []);

    const minDate = useMemo(() => {
        return moment().format('YYYY-MM-DDTHH:mm');
    }, []);

    const handleCloseModal = () => {
        if (isLoading) return;
        handleClose();
    };

    const handleCopyLink = async (_id) => {
        const url = getBaseURL(`/preview/guidance/${_id}`);
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(url);
        } else {
            document.execCommand('copy', true, url);
        }
        setAlert({
            show: true,
            message: 'Link copied',
            severity: severity.SUCCESS,
        });
    };

    const saveShareInfo = async () => {
        try {
            const payload = {
                guidanceId,
                expiresOn: moment(expiresOn).toISOString(),
            };
            const {
                data: { _id },
            } = await axios.post('/api/guidance/save-share-info', payload);
            handleCopyLink(_id);
        } catch (e) {
            console.log(e);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await saveShareInfo();
        // await handleCopyLink();
        setIsLoading(false);
        handleClose();
    };

    return (
        <Dialog
            onClose={handleCloseModal}
            open={open}
            fullWidth
            maxWidth={'xs'}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    <Box
                        display="flex"
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <Typography
                            sx={{ fontSize: '1.25rem', fontWeight: 500 }}
                        >
                            Share Guidance
                        </Typography>
                        <IconButton
                            size="small"
                            onClick={handleCloseModal}
                            disabled={isLoading}
                        >
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        type="datetime-local"
                        color="secondary"
                        value={expiresOn}
                        inputProps={{
                            min: minDate,
                        }}
                        onChange={(e) => setExpiresOn(e.target.value)}
                        helperText="Expiration date/time (Default = 30 days)"
                        fullWidth
                        required
                    />
                </DialogContent>
                <DialogActions sx={{ px: 3 }}>
                    <Button
                        disabled={isLoading}
                        type="submit"
                        variant="contained"
                        color="secondary"
                    >
                        {isLoading ? 'Please wait' : 'Save and copy'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default ShareModal;
