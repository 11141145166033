import React from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Autocomplete } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { useAllCompanies } from '../../useAllCompanies';
import { useMemo } from 'react';

const LIVE = 'LIVE';
const DRAFT = 'DRAFT';

const useStyles = makeStyles()((theme) => ({
    firstStep: {
        '& .form .form-group': {
            marginBottom: theme.spacing(1),
            '& > label': {
                fontSize: '1.1rem',
            },
        },
    },
}));

function FirstStep({
    templateName,
    setTemplateName,
    clients,
    setClients,
    status,
    setStatus,
    fullName,
    types,
    selectedTypes,
    setSelectedTypes,
    hours,
    setHours,
    minutes,
    setMinutes,
}) {
    const { classes } = useStyles();
    const theme = useTheme();
    const isXSDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const { companiesOption } = useAllCompanies();

    const clientsOptions = useMemo(() => {
        return companiesOption?.filter((company) => company._id !== '0');
    }, [companiesOption]);

    const onClientChange = (e, newValue) => {
        setClients(newValue);
    };

    const onTypesChange = (e, newValue) => {
        setSelectedTypes(newValue);
    };

    const onTemplateNameChange = (e) => {
        setTemplateName(e.target.value);
    };

    const onStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const onHourschange = (e) => {
        setHours(e.target.value);
    };

    const onMinChange = (e) => {
        setMinutes(e.target.value);
    };

    return (
        <Box className={classes.firstStep}>
            <form className="form">
                <div className="form-group">
                    <label htmlFor="templateName">Enter template name</label>
                    <TextField
                        id="templateName"
                        type="text"
                        name="templateName"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size={isXSDevice ? 'small' : 'medium'}
                        value={templateName}
                        onChange={onTemplateNameChange}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="clientNames">Select client(s)</label>
                    <Autocomplete
                        options={clientsOptions}
                        getOptionLabel={(option) => option?.client_name}
                        multiple
                        disableCloseOnSelect
                        id="clientNames"
                        size={isXSDevice ? 'small' : 'medium'}
                        value={clients}
                        onChange={onClientChange}
                        renderInput={(params) => (
                            <TextField
                                id="clients"
                                name="clients"
                                variant="outlined"
                                color="secondary"
                                size="small"
                                {...params}
                            />
                        )}
                        renderOption={(props, option, { selected }) => (
                            <Box {...props}>
                                <Checkbox
                                    icon={
                                        <CheckBoxOutlineBlank fontSize="small" />
                                    }
                                    checkedIcon={<CheckBox fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.client_name}
                            </Box>
                        )}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="types">Select type(s)</label>
                    <Autocomplete
                        options={types}
                        getOptionLabel={(option) => option.name}
                        multiple
                        disableCloseOnSelect
                        id="clientNames"
                        size={isXSDevice ? 'small' : 'medium'}
                        value={selectedTypes}
                        onChange={onTypesChange}
                        renderInput={(params) => (
                            <TextField
                                id="types"
                                name="types"
                                variant="outlined"
                                color="secondary"
                                size="small"
                                {...params}
                            />
                        )}
                        renderOption={(props, option, { selected }) => (
                            <Box {...props}>
                                <Checkbox
                                    icon={
                                        <CheckBoxOutlineBlank fontSize="small" />
                                    }
                                    checkedIcon={<CheckBox fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </Box>
                        )}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="createdBy">Created by</label>

                    <TextField
                        id="createdBy"
                        type="text"
                        name="createdBy"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size={isXSDevice ? 'small' : 'medium'}
                        value={fullName}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </div>

                <div className="form-group">
                    <FormControl
                        size={isXSDevice ? 'small' : 'medium'}
                        fullWidth
                    >
                        <label htmlFor="status">Status</label>
                        <Select
                            labelId="status"
                            id="status"
                            value={status}
                            onChange={onStatusChange}
                            fullWidth
                            variant="outlined"
                            color="secondary"
                        >
                            <MenuItem value={LIVE}>{LIVE}</MenuItem>
                            <MenuItem value={DRAFT}>{DRAFT}</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="form-group">
                    <FormControl
                        size={isXSDevice ? 'small' : 'medium'}
                        fullWidth
                    >
                        <label htmlFor="status">Duration: </label>
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            style={{ gap: '1rem' }}
                        >
                            <TextField
                                name="hours"
                                id="hours"
                                value={hours}
                                type="number"
                                // size="small"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            hr<small>(s)</small>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={onHourschange}
                            />
                            {'-'}
                            <TextField
                                name="minutes"
                                id="minutes"
                                value={minutes}
                                type="number"
                                // size="small"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            min<small>(s)</small>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={onMinChange}
                            />
                        </Box>
                    </FormControl>
                </div>
            </form>
        </Box>
    );
}

export default FirstStep;
