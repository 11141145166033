import { Box, Grid, Paper, Typography } from '@mui/material';

import * as React from 'react';
// import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rootBtn } from '../Helpers/Store';
import LastRow from './LastRow';
import axios from 'axios';
import { useCurrentUser } from '../../my-account/useCurrentUser';
import udemyLogo from './udemy-logo.png';

const CouponCard = ({
    card,
    setCheckdb_again,
    activated,
    setModalOpen,
    setPromptLogin,
    activateLink,
    setActivateLink,
}) => {
    const { user: userDetails } = useCurrentUser();
    // const state = useSelector((state: t.AllState) => state.coupons);
    // const dispatch = useDispatch();

    const daysLeft = React.useCallback(() => {
        const today = new Date().getTime();
        const endDate = new Date(card.EndTime).getTime();
        const daysRemaining = Math.ceil((endDate - today) / 86400000);
        return daysRemaining;
    }, [card.EndTime]);

    const diff_minutes = (e) => {
        var date_future = Date.parse(new Date());
        var date_now = Date.parse(e);

        var delta = Math.abs(date_future - date_now) / 1000;

        // calculate (and subtract) whole days
        var days = Math.floor(delta / 86400);
        delta -= days * 86400;

        // calculate (and subtract) whole hours
        var hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        console.log(hours);

        // calculate (and subtract) whole minutes
        var minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        console.log(minutes);

        // what's left is seconds
        var seconds = delta % 60; // in the

        let returnData;
        if (hours == 0) {
            returnData = minutes + ' minutes left';
        } else {
            if (hours > 1) {
                returnData = hours + ' hours left';
            } else {
                returnData = hours + ' hour left';
            }
        }
        return returnData;
    };

    const saveToDatabase = async () => {
        // first check if user has clicked maximum 3 cards withing 24 hrs. If yes, warn user they cant click anymore
        if (!userDetails) {
            setPromptLogin(true);
            return;
        }
        try {
            const { data, status } = await axios.post(
                '/api/udemy-coupon-codes/checkIf24hrsLimitExceeded',
                {
                    userID: userDetails._id,
                }
            );
            if (
                status === 200 &&
                data.total >= 3 &&
                (userDetails.user_subscription_id === '' ||
                    userDetails.plan_name === 'Voyager+' ||
                    userDetails.user_subscription_status === 'trialing')
            ) {
                setModalOpen(true); // pops open a modal - user is notified he/she cannot click more coupons today.
                return;
            } else {
                window.open(card.CouponLink, '_blank');
                const payload = {
                    userID: userDetails._id,
                    topic: card.Topic,
                    author: card.Author,
                    link: card.Link,
                    couponCode: card.CouponCode,
                    couponID: card.CouponId,
                    title: card.Title,
                };
                // console.log({ payload });
                const saved = await axios.post('/api/udemy-coupon-codes', {
                    ...payload,
                });
                if (saved) {
                    // console.log('saved');
                    setCheckdb_again(true);
                } else {
                    alert(
                        'Something seems to have gone wrong... please try refreshing this page'
                    );
                }
            }
        } catch (error) {
            console.log({ error });
        }
    };
    const linkWrapper = (actualCard) => {
        return (
            <a
                href={card.CouponLink}
                rel="noopener noreferrer"
                target={'_blank'}
            >
                {actualCard}
            </a>
        );
    };

    const actualCard = () => {
        return (
            <Paper
                style={{
                    height: '100%',
                    cursor: 'pointer',
                    padding: '0.7rem',
                    marginBottom: '0.7rem',
                    paddingBottom: '0px',
                    borderRadius: '9px',
                    backgroundColor: activated ? '#dce7f7' : '',
                    '-webkit-box-shadow': '5px 5px 20px rgb(3 16 32 / 5%)',
                    'box-shadow': '5px 5px 20px rgb(3 16 32 / 5%)',
                }}
                elevation={8}
                onClick={(e) => {
                    // if (activated) {
                    //     e.style.backgroundColor = '';
                    // } else {
                    //     e.style.backgroundColor = '#dce7f7';
                    // }
                    saveToDatabase();
                }}
            >
                <Grid
                    container
                    justifyContent="space-between"
                    style={{ gap: '0.3rem' }}
                    direction="column"
                >
                    {/* 1 of 3 ROWS */}
                    <Grid
                        xs={12}
                        item
                        container
                        // style={{ border: '2px solid red' }}
                    >
                        <Grid
                            xs={5}
                            item
                            style={{
                                backgroundColor: '#EB5757',
                                color: 'white',
                                borderRadius: '4px',
                                // padding: '0.3rem',
                            }}
                        >
                            <Typography
                                style={{ fontSize: '1.2rem' }}
                                align="center"
                            >
                                - {card.DiscountP} %
                            </Typography>
                            {/* <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                            </div> */}
                        </Grid>
                        <Grid xs={7}>
                            {/* <div style={{ alignSelf: 'center' }}> */}
                            <Typography
                                style={{
                                    fontSize: '1.1rem',
                                    // backgroundColor: 'lightblue',
                                    // border: '2px solid blue',
                                    color: '#EB5757',
                                    fontWeight: 'bold',
                                }}
                                align="right"
                                onClick={() => {
                                    console.log({ daysLeft: daysLeft() });
                                }}
                            >
                                {daysLeft() == 0
                                    ? diff_minutes(card.EndTime)
                                    : daysLeft() +
                                      ' day' +
                                      (daysLeft() > 1 ? 's' : '') +
                                      ' left'}
                            </Typography>
                            {/* </div> */}
                        </Grid>
                    </Grid>
                    {/* 2 of 3 ROWS */}
                    <Grid xs={12} item>
                        <Box
                            style={{
                                height: '9rem',
                                // width: '8rem',
                                // border: '2px solid green',
                                padding: '2rem 1rem',
                                // overflow: 'hidden',
                            }}
                        >
                            <img
                                height="100%"
                                width="100%"
                                src={udemyLogo}
                                alt="udemy-logo"
                            />
                        </Box>
                    </Grid>
                    {/* 3 of 3 ROWS */}
                    <Grid xs={12} item>
                        <LastRow card={card} />
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    return (
        <>
            {/* {activateLink && userDetails
                ? linkWrapper(actualCard())
                : actualCard()} */}
            {actualCard()}
        </>
    );
};

export default CouponCard;
