import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { allFeatureState } from './recoil';
import axios from 'axios';

export const useAllFeaturedCourses = () => {
    const [tabsState, setTabsState] = useRecoilState(allFeatureState);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get('/api/courses/featured');
                setTabsState(data);
            } catch (error) {
                console.error('Error fetching featured courses:', error);
            }
        };

        if (Object.values(tabsState).length) {
            setIsLoading(false);
        } else {
            fetchData();
            setIsLoading(false);
        }
    }, []);

    return { isLoading, tabsState };
};
