import React from 'react';
import { ArrowUpwardIcon, ArrowDownwardIcon } from '../../icons';
import { Typography } from '@mui/material';

export const VariationIndicatorArrow = ({ variation }) => {
    return (
        <>
            {variation ? (
                variation > 0 ? (
                    <>
                        <ArrowUpwardIcon fontSize="small" color="success" />
                        <Typography variant="caption">{variation}</Typography>
                    </>
                ) : (
                    <>
                        <ArrowDownwardIcon fontSize="small" color="error" />
                        <Typography variant="caption">
                            {Math.abs(variation)}
                        </Typography>
                    </>
                )
            ) : null}
        </>
    );
};
