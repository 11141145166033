import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../../app/recoil';
import { CloseIcon } from '../../../icons';

const useStyles = makeStyles()((theme) => ({
    dialogContent: {
        padding: theme.spacing(2),
        position: 'relative',
    },
    dialogActions: {
        textAlign: 'right',
    },
    readOnlyOverlay: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        inset: 0,
        backgroundColor: 'transparent',
        cursor: 'not-allowed',
    },
}));

export default function DateRangeModal({
    open,
    handleClose,
    timelineStartDate,
    timelineEndDate,
    expectedEndDate,
    taskId,
    requirementId,
    setRequirement,
    starweaverAdmin,
    showAssignedUserView = undefined,
    getAssignedUserGroupData = undefined,
    showRoleView = undefined,
    fetchLogisticsByRole = undefined,
    showChronologyView = undefined,
    fetchLogisticsByChronology = undefined,
    filterBy = '',
    isInDrawer = false,
    loadRequirementData = undefined,
}) {
    const { classes } = useStyles();
    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const setAlert = useSetRecoilState(alertState);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setDateRange((prev) => {
            return {
                ...prev,
                startDate: new Date(timelineStartDate),
                endDate: new Date(timelineEndDate),
            };
        });
    }, [timelineEndDate, timelineStartDate]);

    const handleChange = (ranges) => {
        const { startDate, endDate } = ranges?.selection;
        if (!startDate || !endDate) return;
        setDateRange((prev) => {
            return {
                ...prev,
                startDate,
                endDate,
            };
        });
    };

    const handleSave = async () => {
        let updatedNumOfDaysEnd = moment(expectedEndDate).diff(
            moment(dateRange.endDate),
            'days'
        );

        let updatedNumOfDaysStart = moment(dateRange.endDate).diff(
            moment(dateRange.startDate),
            'days'
        );

        try {
            setSaving(true);

            await axios.post('/api/client-requirements/update-task-days', {
                taskId,
                requirementId,
                days: updatedNumOfDaysEnd,
            });

            await axios.post('/api/client-requirements/update-task-duration', {
                taskId,
                requirementId,
                days: updatedNumOfDaysStart,
            });

            if (setRequirement) {
                setRequirement((prevReq) => {
                    return {
                        ...prevReq,
                        taskCategories: prevReq?.taskCategories?.map(
                            (category) => {
                                return {
                                    ...category,
                                    tasks: category.tasks.map((task) => {
                                        if (task._id === taskId) {
                                            return {
                                                ...task,
                                                numOfDaysBeforeDelivery: updatedNumOfDaysEnd,
                                                duration: updatedNumOfDaysStart,
                                            };
                                        } else {
                                            return { ...task };
                                        }
                                    }),
                                };
                            }
                        ),
                    };
                });
            }

            if (showAssignedUserView && getAssignedUserGroupData) {
                await getAssignedUserGroupData(requirementId, filterBy);
            }

            if (showRoleView && fetchLogisticsByRole) {
                await fetchLogisticsByRole(requirementId, filterBy);
            }

            if (showChronologyView && fetchLogisticsByChronology) {
                await fetchLogisticsByChronology(requirementId, filterBy);
            }

            if (isInDrawer && loadRequirementData) {
                await loadRequirementData(requirementId);
            }

            setAlert({
                show: true,
                severity: severity.SUCCESS,
                message: 'Date updated',
            });
            handleClose();
            // setMetricDate(moment(e.target.value).format('YYYY-MM-DD'));
        } catch (e) {
            const message = e?.response?.data?.message ?? e?.message;
            setAlert({
                show: true,
                message,
                severity: severity.ERROR,
            });
        } finally {
            setSaving(false);
        }
    };

    return (
        <Dialog
            aria-labelledby="date-range-picker"
            open={open}
            scroll="paper"
            onClose={() => handleClose()}
        >
            <DialogTitle sx={{ paddingBlock: '0.5rem' }}>
                <Box textAlign={'right'}>
                    <IconButton onClick={() => handleClose()} size="small">
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent className={classes.dialogContent} dividers>
                <DateRangePicker
                    ranges={[dateRange]}
                    rangeColors={['#818cf8']}
                    onChange={handleChange}
                />
                {!starweaverAdmin && (
                    <div className={classes.readOnlyOverlay}></div>
                )}
            </DialogContent>
            {starweaverAdmin && (
                <DialogActions className={classes.dialogActions}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={handleSave}
                        disabled={saving}
                    >
                        {saving ? 'Please wait' : 'Save'}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}
