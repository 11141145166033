import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Divider,
    Typography,
    Radio,
    Checkbox,
} from '@mui/material';
import { useStyles } from './Helpers/hooks';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { UseState, UseDispatch, vars, t, f } from './index';
import { useSelector, useDispatch } from 'react-redux';
import { rootBtn, clearApiString } from './Helpers/Store';
import { Helmet } from 'react-helmet';
import { couponRoute as route } from '../seo/routeMetaData';

import axios from 'axios';

// import CouponCard from './Main/CouponCard';
import CouponLogic from './Main/CouponLogic';

const Main = () => {
    const state = useSelector((state) => state.coupons);
    const dispatch = useDispatch();

    const c = useStyles(); // c for class
    const [hoveredUpon, setHoveredUpon] = useState('');

    // ---------------------------code block start ------------------------- fetching subcategoryId(s)------------------
    // subcategory Id(s) are to be fetched from an external api
    // then filter that array to find out the desired categoryId
    const [apiList, setApiList] = useState(null); // will contain subcategoryId and its names.
    // AXIOS CALL
    const fetchSubCategoryIds = async () => {
        const { data, status } = await axios.get(
            'https://teachinguide.azure-api.net/subcategory-list'
        );
        if (status === 200) {
            setApiList(data); // storing fetched data into self state.
        } else alert('Something went wrong... please refresh this page');
    };

    // AUTO FETCHING DATA - only if 'subCategoryId' is null,
    useEffect(() => {
        if (!apiList) {
            fetchSubCategoryIds();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiList]);
    // ---------------------------code block end------------------------------------------------------------------------

    const grab_subOptions = (buttonName) => {
        // 2nd layer of options inside root options
        switch (buttonName) {
            case 'Discount':
                return vars.discounts;
            case 'Categories':
                return vars.categories;
            case 'Level':
                return vars.levels;
            case 'Ratings':
                return vars.ratings;
            case 'Sub Categories':
                if (apiList) {
                    // console.log({cate})

                    let arr = [];
                    state.Categories.forEach((cat, i) => {
                        apiList.forEach((list) => {
                            if (list.Category === cat) {
                                arr.push(list.Subcategory);
                            }
                        });
                    });
                    return arr;
                    // let filtered_apiList: ApiList = [];
                    // apiList.forEach((list, i) => {
                    //     console.log({ list, state });
                    //     if (state.Categories.includes(list.Category)) {
                    //         console.log(list.Category);
                    //         filtered_apiList.push(list);
                    //     } else {
                    //         console.log('Not able to push');
                    //     }
                    // });
                    // console.log({ filtered_apiList });
                    // const subCategories = filtered_apiList.map((list, i) => {
                    //     return list.Subcategory;
                    // });
                    // console.log({ subCategories });
                    // return subCategories;
                } else return vars.subCategories;
            case 'Language':
                return vars.languages;
            default:
                return vars.levels;
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{route.name}</title>
                <link
                    rel="canonical"
                    href={`http://go.starweaver.com${route.name}`}
                />
            </Helmet>

            <Box
                className={`${c.fillScreen}`}
                style={{
                    boxSizing: 'border-box',
                    overflow: 'hidden',
                    height: 'auto',
                }}
            >
                {state.apiString}
                <Grid xs={12} container>
                    {/* Left side */}
                    <Grid
                        xs={12}
                        md={2}
                        item
                        style={{
                            padding: '1.5rem 0 0',
                        }}
                    >
                        {/* column wrapper */}
                        <Grid
                            // direction="column"
                            container
                            xs={12}
                            style={{ gap: '0.7rem' }}
                            // spacing={4}
                        >
                            {vars.rootBtns.map((mainOption, i) => {
                                return (
                                    // mapped item inside left column
                                    <Grid
                                        container
                                        item
                                        key={i}
                                        xs={12}
                                        style={{
                                            padding: '0 0 0 1rem',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Grid item xs={10}>
                                            <Typography
                                                variant="h6"
                                                onClick={(e) => {
                                                    if (
                                                        mainOption ===
                                                            'Sub Categories' ||
                                                        mainOption ===
                                                            'Categories' ||
                                                        mainOption ===
                                                            'Language'
                                                    ) {
                                                        hoveredUpon ===
                                                        mainOption
                                                            ? setHoveredUpon('')
                                                            : setHoveredUpon(
                                                                  mainOption
                                                              );
                                                    }
                                                }}
                                                // color={
                                                //     // mainOption !== 'Sub Categories' && mainOption !== 'Categories'
                                                // }
                                                color={
                                                    mainOption !==
                                                        'Sub Categories' &&
                                                    mainOption !==
                                                        'Categories' &&
                                                    mainOption !== 'Language' &&
                                                    mainOption === hoveredUpon
                                                        ? 'secondary'
                                                        : 'inherit'
                                                }
                                            >
                                                {mainOption}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            xs={2}
                                            item
                                            container
                                            // className={`${c.slowReveal}`}
                                            style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {mainOption === 'Categories' ||
                                            mainOption === 'Language' ||
                                            mainOption === 'Sub Categories' ? (
                                                hoveredUpon === mainOption ? (
                                                    <KeyboardArrowDownIcon />
                                                ) : (
                                                    <KeyboardArrowUpIcon />
                                                )
                                            ) : null}
                                        </Grid>

                                        {/* expand div */}
                                        <Box
                                            style={{
                                                // padding: '0.2rem',
                                                width: '100%',
                                                maxHeight:
                                                    mainOption ===
                                                        'Categories' ||
                                                    mainOption === 'Language' ||
                                                    mainOption ===
                                                        'Sub Categories'
                                                        ? hoveredUpon ===
                                                          mainOption
                                                            ? '40rem'
                                                            : '0'
                                                        : '30rem',
                                                overflow:
                                                    mainOption === hoveredUpon
                                                        ? 'auto'
                                                        : 'hidden',
                                                transition: 'all 0.5s',
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    // padding: '1rem 0',
                                                    overflow: 'auto',
                                                    height: 'auto',
                                                    width: '100%',
                                                }}
                                            >
                                                {/* depending upon the "mainOption" grab the array of subOptions "vars".
                                            Then map over that arry, to display the subOptions when user hovers/clicks on any of the main options */}
                                                {apiList &&
                                                    grab_subOptions(
                                                        mainOption
                                                    ).map((subOption, j) => {
                                                        return (
                                                            <Box
                                                                key={`${i}+${j}`}
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                }}
                                                                // clicking this div should tell the state which button has been clicked by user and how to toggle it
                                                                onClick={() => {
                                                                    const toggleThisRadio = {
                                                                        btn: mainOption,
                                                                        elem: subOption,
                                                                    };
                                                                    dispatch(
                                                                        rootBtn(
                                                                            toggleThisRadio
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                <Box>
                                                                    {mainOption ===
                                                                        'Level' ||
                                                                    mainOption ===
                                                                        'Ratings' ||
                                                                    mainOption ===
                                                                        'Duration' ? (
                                                                        <Radio
                                                                            checked={f.exists_in_state_value(
                                                                                state,
                                                                                mainOption,
                                                                                subOption
                                                                            )}
                                                                        />
                                                                    ) : (
                                                                        <Checkbox
                                                                            checked={f.exists_in_state_value(
                                                                                state,
                                                                                mainOption,
                                                                                subOption
                                                                            )}
                                                                        />
                                                                    )}
                                                                </Box>
                                                                <Box
                                                                    style={{
                                                                        alignSelf:
                                                                            'center',
                                                                        cursor:
                                                                            'pointer',
                                                                    }}
                                                                >
                                                                    {subOption}
                                                                </Box>
                                                            </Box>
                                                        );
                                                    })}
                                            </Box>
                                        </Box>
                                        <Grid item xs={12}>
                                            <Box
                                                my={1}
                                                style={{
                                                    paddingRight: '16px',
                                                    marginTop: '15px',
                                                }}
                                            >
                                                <Divider />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                    {/* Right Side */}
                    <Grid
                        item
                        container
                        md={10}
                        xs={12}
                        style={{
                            backgroundColor: 'rgb(216, 229, 232)',
                        }}
                    >
                        <div
                            style={{
                                overflow: 'auto',
                                width: '100%',
                                height: 'auto',
                                padding: '2rem 2rem 3rem',
                            }}
                        >
                            {apiList && <CouponLogic apiList={apiList} />}
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Main;
