import { coursesSlice } from '../courses/coursesSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { getSavedCourse, setSavedCourse } from './Recoil/Selector';
import axios from 'axios';

const { getCourses } = coursesSlice;

const getAllCourse = () => (state) => {
    // const courses = getCourses(state);
    // return courses;

    return [];
};

export const useGetSaved = (user) => {
    const course = useSelector(getAllCourse());
    const savedCourses = useRecoilValue(getSavedCourse);
    const setSavedCourses = useSetRecoilState(setSavedCourse);
    useEffect(() => {
        if (user) {
            if (user.saved_courses && user.saved_courses.length === 0) {
                setSavedCourses([]);
            } else {
                if (user.saved_courses?.length !== savedCourses.length) {
                    let foundCourse = [];

                    user.saved_courses?.forEach((id) => {
                        for (let i = 0; i < course.length; i++) {
                            if (course[i].id === id) {
                                let newItem = { ...course[i] };
                                newItem.saved = true;
                                foundCourse.push(newItem);
                                break;
                            }
                        }
                    });

                    axios
                        .get(
                            // `${
                            //     process.env.REACT_APP_SW_WORDPRESS_DOMAIN
                            // }/wp-json/headless/catalogue/dashboard?nowprocket=1&id=${foundCourse
                            //     .map(({ productable_id }) => productable_id)
                            //     .join()}`

                            `/api/courses?thinkificCourseIds=${foundCourse
                                .map(({ productable_id }) => productable_id)
                                .join()}`
                        )
                        .then((resExtrasWordPress) => {
                            const extrasWP = new Map(
                                resExtrasWordPress.data?.courses?.map((c) => [
                                    parseInt(c.thinkificCourseId),
                                    c,
                                ])
                            );

                            foundCourse = foundCourse.map((course) => ({
                                ...course,
                                ...extrasWP.get(course.productable_id),
                            }));

                            setSavedCourses(foundCourse);
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                }
            }
        }
    }, [user]);

    return { savedCourses };
};
