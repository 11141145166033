import React, { useCallback } from 'react';
import Fuse from 'fuse.js';

export const useFuse = (items, keys) => {
    const fuseSearch = useCallback(
        (query) => {
            // console.log('Setting up fuse.');
            const fuse = new Fuse(items, {
                keys: keys || ['title', 'name'],
                includeScore: true,
            });

            return query !== ''
                ? fuse
                      .search(query)
                      .filter(({ score }) => score < 0.7)
                      .map(({ item }) => item)
                : items;
        },
        [items]
    );

    return { fuseSearch };
};
