import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {
    DescriptionOutlinedIcon,
    ExpandMoreIcon,
    VideocamOutlinedIcon,
} from '../icons';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Grid,
    Typography,
} from '../ui';

const useStyles = makeStyles()((theme) => ({
    chapterTitle: {
        fontSize: '2rem',
        fontWeight: 'bold',
        marginLeft: 50,
        color: theme.palette.grey[700],
    },
    chapterNumber: {
        fontSize: '4rem',
        fontWeight: 'bold',
        color: theme.palette.grey[700],
        marginLeft: 20,
    },
    lessonTitle: { lineHeight: '1.8rem' },
    lessonTitles: {
        margin: '0px 80px',
        borderTop: `1px solid ${theme.palette.grey[400]}`,
    },
    lessonIcon: {
        fill: '#ffa147',
    },
    freePreviewContainer: {
        marginLeft: 'auto',
    },
    freePreview: {
        color: 'white',
        backgroundColor: '#ffa147',
        width: 110,
        borderRadius: 4,
    },
    freePreviewText: {
        fontSize: '0.9rem',
        fontWeight: 'bold',
        textAlign: 'center',
    },
}));

export const CourseChapterAccordion = ({ title, lessons, chapterNumber }) => {
    const { classes } = useStyles();
    return (
        <Accordion className={classes.accordian}>
            <AccordionSummary
                expandIcon={
                    <ExpandMoreIcon
                        fontSize="large"
                        style={{ fill: 'black' }}
                    />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordianSummary}
            >
                <Grid container direction="row" alignItems="center">
                    <Grid item>
                        <Typography className={classes.chapterNumber}>
                            {chapterNumber}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.chapterTitle}>
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.lessonTitles}>
                <Grid container direction="column" spacing={1}>
                    {lessons.map(
                        ({ title, freePreview, videoContent }, index) => (
                            <Grid item key={index}>
                                <Grid
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    spacing={1}
                                >
                                    <Grid item>
                                        {videoContent ? (
                                            <VideocamOutlinedIcon
                                                className={classes.lessonIcon}
                                            />
                                        ) : (
                                            <DescriptionOutlinedIcon
                                                className={classes.lessonIcon}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            className={classes.lessonTitle}
                                        >
                                            {title}
                                        </Typography>
                                    </Grid>
                                    {freePreview && (
                                        <Grid
                                            item
                                            className={
                                                classes.freePreviewContainer
                                            }
                                        >
                                            <Box
                                                className={classes.freePreview}
                                            >
                                                <Typography
                                                    className={
                                                        classes.freePreviewText
                                                    }
                                                >
                                                    FREE PREVIEW
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        )
                    )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
