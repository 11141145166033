import {
    Modal,
    Fade,
    Typography,
    List,
    ListItem,
    Card,
    Backdrop,
    Checkbox,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import axios from 'axios';
import { alertState, severity } from '../app/recoil';
import { useSetRecoilState } from 'recoil';
import { useState } from 'react';

const useStyles = makeStyles()((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    categories: {
        paddingTop: '1rem',
    },
    categoryWrapper: {
        marginBottom: '0.8rem',
    },
    category: {
        fontSize: '18px',
        padding: '0.8rem',
    },
}));

export const UpdateTemplateModal = ({
    open,
    setOpen,
    categories,
    requirementId,
    loadRequirementData,
    setRequirement,
}) => {
    const { classes } = useStyles();
    const setAlert = useSetRecoilState(alertState);
    const [loading, setLoading] = useState(false);

    // -------- Hooks: start ------------
    // -------- Hooks: end------------

    const handleClose = () => {
        setOpen(false);
    };

    const handleChecked = async (e, categoryId) => {
        const isHidden = e.target.checked;
        try {
            const url =
                isHidden === false
                    ? '/api/client-requirements/hide-category'
                    : '/api/client-requirements/unhide-category';

            const payload = {
                requirementId,
                categoryId,
            };

            setLoading(true);

            await axios.post(url, payload);

            setRequirement((requirement) => {
                return {
                    ...requirement,
                    taskCategories: requirement.taskCategories.map(
                        (category) => {
                            if (category._id === categoryId) {
                                return {
                                    ...category,
                                    isHidden: isHidden === false ? true : false,
                                };
                            } else {
                                return { ...category };
                            }
                        }
                    ),
                };
            });
        } catch (e) {
            const message = e?.response?.data?.message;
            setAlert({ show: true, severity: severity.ERROR, message });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            aria-labelledby="update-template"
            aria-describedby="update-template"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            // BackdropComponent={Backdrop}
            // BackdropProps={{
            //     timeout: 500,
            // }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Typography variant="h5" className={classes.headerTitle}>
                        Categories
                    </Typography>
                    <List className={classes.categories}>
                        {categories?.map((category, idx) => {
                            return (
                                <Card
                                    key={category._id}
                                    className={classes.categoryWrapper}
                                    elevation={2}
                                >
                                    <ListItem className={classes.category}>
                                        {/* checkbox */}
                                        <Checkbox
                                            checked={!category.isHidden}
                                            onChange={(e) =>
                                                handleChecked(e, category._id)
                                            }
                                            disabled={idx === 0 || idx === 1}
                                        />
                                        <Typography>
                                            {category.categoryName}
                                        </Typography>
                                    </ListItem>
                                </Card>
                            );
                        })}
                    </List>
                </div>
            </Fade>
        </Modal>
    );
};
