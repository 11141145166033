import { useCallback, useEffect, useRef, useState } from 'react';
import NoTemplates from './NoTemplates';
import { Add } from '@mui/icons-material';
import { Box, Button, LinearProgress } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { alertState, severity } from '../../../app/recoil';
import { AzureBlobStorage } from '../../../util/BlobStorage';
import { FileViewContainer } from '../../FileViewContainer';

const useStyles = makeStyles()((theme) => ({
    addBtnContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(2),
    },
}));

function GuidanceTemplates({ guidanceId, hideCTA = false }) {
    const { classes } = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState({
        fileNames: [],
        size: 0,
    });
    const [currentProgress, setCurrentProgress] = useState(0);
    const attachmentInputRef = useRef(null);
    const setAlert = useSetRecoilState(alertState);
    const [totalFilesUploaded, setTotalFilesUploaded] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    const fetchTemplates = useCallback(async (guidanceId) => {
        try {
            setIsLoading(true);
            const blobStorage = new AzureBlobStorage();
            const containerName = 'guidance';
            const folderName = `${guidanceId}/templates/`;
            const { fileNames, size } = await blobStorage.listFilesFromFolder(
                containerName,
                folderName
            );
            setFiles({ fileNames, size });
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (guidanceId) {
            fetchTemplates(guidanceId);
        }
    }, [fetchTemplates, guidanceId]);

    const handleAddTemplates = () => {
        if (attachmentInputRef.current) {
            attachmentInputRef.current.click();
        }
    };

    const showInvalidFileAlert = (message) => {
        setAlert({
            show: true,
            message,
            severity: severity.WARNING,
        });
    };

    const isInvalidMimeType = (files) => {
        const validFileTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/csv',
            'text/plain',
            'image/jpeg',
            'image/webp',
            'image/png',
            'video/mp4',
            'video/mpeg',
            'application/vnd.rar',
            'application/zip',
            'application/zip',
            'application/x-zip-compressed',
        ];

        return Array.from(files).some(
            (file) => !validFileTypes.includes(file.type)
        );
    };

    const isInvalidFileLength = (files) => {
        return Array.from(files).length > 5;
    };

    const isInvalidFileSize = (files) => {
        const validFileSize = 30 * 1000 * 1000;
        const totalFileSize = Array.from(files).reduce(
            (prevState, currValue) => {
                return prevState + currValue.size;
            },
            0
        );
        return totalFileSize > validFileSize;
    };

    const uploadFiles = async (files) => {
        try {
            setIsUploading(true);
            const blobStorage = new AzureBlobStorage();
            const containerName = 'guidance';
            const folderPath = `${guidanceId}/templates`;
            const { fileNames, size } = await blobStorage.uploadFiles(
                files,
                containerName,
                setTotalFilesUploaded,
                setCurrentProgress,
                folderPath
            );
            setFiles({ fileNames, size });
        } catch (e) {
            console.error(e);
        } finally {
            setIsUploading(false);
        }
    };

    const handleFileSelected = (e) => {
        const files = e.target.files;
        if (!files || files.length === 0) return;

        if (isInvalidFileLength(files)) {
            showInvalidFileAlert('Can add up to 5 files');
            return;
        }

        if (isInvalidFileSize(files)) {
            showInvalidFileAlert('Maximun 30MB is allowed');
            return;
        }

        if (isInvalidMimeType(files)) {
            showInvalidFileAlert('Invalid file type');
            return;
        }

        setTotalFiles(Array.from(files).length);
        uploadFiles(files);
    };

    return (
        <div>
            <input
                type="file"
                ref={attachmentInputRef}
                multiple
                hidden
                name="templates"
                onChange={handleFileSelected}
            />

            {!isLoading && files.fileNames.length === 0 ? (
                <>
                    {isUploading && (
                        <Box className={classes.progressContainer}>
                            <LinearProgress
                                color="secondary"
                                variant="determinate"
                                value={currentProgress}
                            />
                            <span>{totalFilesUploaded}</span> /{' '}
                            <span>{totalFiles}</span>
                        </Box>
                    )}
                    <NoTemplates
                        handleAddTemplates={handleAddTemplates}
                        isLoading={isUploading}
                        hideCTA={hideCTA}
                        message='No documents found'
                        buttonText='Upload'
                    />
                </>
            ) : (
                <>
                    {!hideCTA && (
                        <Box className={classes.addBtnContainer}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<Add />}
                                onClick={handleAddTemplates}
                                disabled={isLoading || isUploading}
                            >
                                Add
                            </Button>
                        </Box>
                    )}

                    {isUploading && (
                        <Box className={classes.progressContainer}>
                            <LinearProgress
                                color="secondary"
                                variant="determinate"
                                value={currentProgress}
                            />
                            <span>{totalFilesUploaded}</span> /{' '}
                            <span>{totalFiles}</span>
                        </Box>
                    )}

                    <FileViewContainer
                        fetchingFiles={isLoading}
                        fileData={files}
                        pwd={[`${guidanceId}/templates`]}
                        containerName={'guidance'}
                        setFileData={setFiles}
                        setDownloadProgress={setCurrentProgress}
                        showDetails={false}
                        forceHideDelBtn={hideCTA}
                    />
                </>
            )}
        </div>
    );
}

export default GuidanceTemplates;
