export function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

export function debounce2(func, delay) {
    let timeoutID;
    return function (...args) {
        let context = this;
        if (timeoutID) clearTimeout(timeoutID);
        timeoutID = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
}
