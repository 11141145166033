import React from 'react';
import { Box, Grid } from '../ui';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
    },
    urlMetadataCard: {
        bottom: '0px',
        position: 'relative',
        display: 'flex',
        border: '1px solid #ddd',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        maxWidth: '600px',
        minHeight: '135px',
        backgroundColor: '#fff',
        margin: '0px',
        transition: 'transform 0.2s',
    },
    metaDataContent: {
        textAlign: 'left',
        padding: '0px 15px',
        fontSize: '13px',
    },
    metaDataImage: {
        minHeight: '135px',
        width: '100%',
        objectFit: 'cover',
    },
    metaDataDescription: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
        height: '42px',
    },
}));

const ChatLinkMetaDataPreview = ({ linkMetaData }) => {
    const { classes } = useStyles();

    if (!linkMetaData) return null;

    return (
        <Box className={classes.urlMetadataCard}>
            {linkMetaData.image && (
                <Grid item xs={4}>
                    <img
                        className={classes.metaDataImage}
                        src={linkMetaData.image}
                        alt="{linkMetaData.title}"
                    />
                </Grid>
            )}
            <Grid item xs={8}>
                <div className={classes.metaDataContent}>
                    <h3>{linkMetaData.title}</h3>
                    <p className={classes.metaDataDescription}>
                        {linkMetaData.description}
                    </p>
                    {linkMetaData.domain && (
                        <small>{linkMetaData.domain}</small>
                    )}
                </div>
            </Grid>
        </Box>
    );
};

export default ChatLinkMetaDataPreview;
