export const STRING = 'string';
export const OBJECT = 'object';
export const ARRAY = 'array';
export const DATE = 'date';
export const NUMBER = 'number';
export const BOOLEAN = 'boolean';
export const TIMESTAMP_PRESET = 'timestampPreset';
export const NEXT_TIMESTAMP_PRESET = 'nextTimestampPreset';
export const TASK = 'task';
export const REQUIREMENT_PERCENTAGE_COMPLETED =
    'requirementPercentageCompleted';
