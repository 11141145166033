import { selector } from 'recoil';
import {
    allOnAirState,
    userOnAirState,
    userLiveLabsState,
    topOnAirCourseState,
    liveLabsState,
    savedOnDemandState,
    savedCertificationsState,
    privateCourseState,
    purchasedCourseState,
    savedCourseState,
} from './State';

// GET USER ON AIRs
export const getOnAir = selector({
    key: 'getOnAir',
    get: ({ get }) => {
        const state = get(userOnAirState);
        return state;
    },
});

export const setOnAirValues = selector({
    key: 'setOnAirValues',
    set: ({ set }, newValues) => {
        set(userOnAirState, newValues);
    },
});

// GET USER ON LIVELABS
export const getUserLiveLabs = selector({
    key: 'getUserLiveLabs',
    get: ({ get }) => {
        const state = get(userLiveLabsState);
        return state;
    },
});

export const setUserLiveLabs = selector({
    key: 'setUserLiveLabsValues',
    set: ({ set }, newValues) => {
        set(userLiveLabsState, newValues);
    },
});

// ALL ON AIR STATE TOP 12
export const getAllOnAir = selector({
    key: 'getAllOnAir',
    get: ({ get }) => {
        const state = get(topOnAirCourseState);
        return state;
    },
});

export const setAllOnAirValues = selector({
    key: 'setAllOnAirValues',
    set: ({ set }, newValues) => {
        set(topOnAirCourseState, newValues);
    },
});

// ALL ON AIR TOTAL
export const getAllOnAirState = selector({
    key: 'getAllOnAirState',
    get: ({ get }) => {
        const state = get(allOnAirState);
        return state;
    },
});

export const setAllOnAirState = selector({
    key: 'setAllOnAirState',
    set: ({ set }, newValues) => {
        set(allOnAirState, newValues);
    },
});

export const getSavedCourse = selector({
    key: 'getSavedCourse',
    get: ({ get }) => {
        const state = get(savedCourseState);
        return state;
    },
});

export const setSavedCourse = selector({
    key: 'setSavedCourse',
    set: ({ set }, newValues) => {
        set(savedCourseState, newValues);
    },
});

// ALL LIVE LABS STATE
export const getAllLiveLabs = selector({
    key: 'getAllLiveLabs',
    get: ({ get }) => {
        const state = get(liveLabsState);
        return state;
    },
});

export const setAllLiveLabs = selector({
    key: 'setAllLiveLabs',
    set: ({ set }, newValues) => {
        set(liveLabsState, newValues);
    },
});

// SAVED ON DEMAND STATE
export const getAllSavedOnDemand = selector({
    key: 'getAllSavedOnDemand',
    get: ({ get }) => {
        const state = get(savedOnDemandState);
        return state;
    },
});

export const setAllSavedOnDemand = selector({
    key: 'setAllSavedOnDemand',
    set: ({ set }, newValues) => {
        set(savedOnDemandState, newValues);
    },
});

// SAVED CERTIFICATIONS
export const getSavedCertifications = selector({
    key: 'getSavedCertifications',
    get: ({ get }) => {
        const state = get(savedCertificationsState);
        return state;
    },
});

export const setSavedCertifications = selector({
    key: 'setSavedCertifications',
    set: ({ set }, newValues) => {
        set(savedCertificationsState, newValues);
    },
});

// PRIVATE CLASSES
export const getPrivateCourseState = selector({
    key: 'getPrivateCourseState',
    get: ({ get }) => {
        const state = get(privateCourseState);
        return state;
    },
});

export const setPrivateCourseState = selector({
    key: 'setPrivateCourseState',
    set: ({ set }, newValues) => {
        set(privateCourseState, newValues);
    },
});

// PURCHASED COURSES
export const getPurchasedCourseState = selector({
    key: 'getPurchasedCourseState',
    get: ({ get }) => {
        const state = get(purchasedCourseState);
        return state;
    },
});

export const setPurchasedCourseState = selector({
    key: 'setPurchasedCourseState',
    set: ({ set }, newValues) => {
        set(purchasedCourseState, newValues);
    },
});
