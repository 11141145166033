import React from 'react';
import { Avatar, Box, Typography } from '../ui';
import { useStyles } from './styles';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

export const TestimonyItem = ({ name, testimony, designation, img }) => {
    const { classes } = useStyles();
    return (
        <Box alignItems="center" display="flex" maxWidth="400px">
            <Box display="flex" flexDirection="column" mr={2}>
                <React.Suspense fallback={<div>Loading...</div>}>
                    <LazyLoadComponent
                        threshold={1}
                        useIntersectionObserver={true}
                    >
                        <Avatar src={img} className={classes.avatar} />
                    </LazyLoadComponent>
                </React.Suspense>

                <Typography
                    align="center"
                    variant="h6"
                    className={classes.testimonyName}
                >
                    {name}
                </Typography>
                <Box mx="auto">
                    <Typography
                        className={classes.designation}
                        align="center"
                        variant="subtitle2"
                    >
                        {designation}
                    </Typography>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography variant="body2" className={classes.testimony}>
                    <q>{testimony}</q>
                </Typography>
            </Box>
        </Box>
    );
};
