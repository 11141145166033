import { useMemo } from 'react';
import { CommentSection } from '../../../comments/CommentSection';
import { useTADs } from '../../hooks/useTADs';
import { getUniqueObjectsByField } from '../../../util';
import { useCurrentUser } from '../../../my-account/useCurrentUser';
import profilePicPlaceholder from '../../../img/profilePicPlaceholder.jpg';
import { visibilities } from '../../../util/visibility';
import { useIsAdmin } from '../../useIsAdmin';

function GuidanceDuscussions({ guidance, isInstructor }) {
    const { admins: tads } = useTADs();
    const { user: userDetails } = useCurrentUser();
    const { starweaverAdmin } = useIsAdmin();

    const mentionables = useMemo(() => {
        const allAssociates = [...tads];

        const uniqueAssociates = getUniqueObjectsByField(
            allAssociates,
            'email'
        );

        return (
            uniqueAssociates
                .filter(({ email }) => userDetails?.user_email !== email)
                .map(
                    ({
                        _id,
                        firstName,
                        lastName,
                        email,
                        profilePicUrl,
                        profilePicUrlWP,
                    }) => ({
                        id: _id,
                        name: `${firstName} ${lastName}`,
                        _name: `${firstName} ${lastName}`,
                        email,
                        profilePicUrl:
                            profilePicUrl ||
                            profilePicUrlWP ||
                            profilePicPlaceholder,
                    })
                ) || []
        );
    }, [userDetails, tads]);

    const visibilityOptions = useMemo(() => {
        return isInstructor
            ? [
                  {
                      option: visibilities.STARWEAVER_TEAM.visibility,
                      icon: visibilities.STARWEAVER_TEAM.icon,
                  },
              ]
            : [
                  {
                      option: visibilities.STARWEAVER_TEAM_AND_SMES.visibility,
                      icon: visibilities.STARWEAVER_TEAM_AND_SMES.icon,
                  },
                  {
                      option: visibilities.STARWEAVER_TEAM.visibility,
                      icon: visibilities.STARWEAVER_TEAM.icon,
                  },
                  {
                      option: visibilities.ADMINS.visibility,
                      icon: visibilities.ADMINS.icon,
                  },
                  ...(starweaverAdmin
                      ? [
                            {
                                option: visibilities.EVERYONE.visibility,
                                icon: visibilities.EVERYONE.icon,
                            },
                            {
                                option: visibilities.SELECTED_USERS.visibility,
                                icon: visibilities.SELECTED_USERS.icon,
                            },
                            {
                                option: visibilities.ONLY_ME.visibility,
                                icon: visibilities.ONLY_ME.icon,
                            },
                        ]
                      : []),
              ];
    }, [isInstructor, starweaverAdmin]);

    return (
        <div>
            <CommentSection
                courseId={`guidance-${guidance._id}`}
                courseSlug={''}
                courseName={guidance.title}
                mentionables={mentionables}
                tasks={[]}
                teams={[]}
                visibilityOptions={visibilityOptions}
                defaultVisibility={
                    isInstructor
                        ? visibilities.STARWEAVER_TEAM.visibility
                        : visibilities.STARWEAVER_TEAM_AND_SMES.visibility
                }
                showCommentAttachments={false}
                showAttachments={false}
            />
        </div>
    );
}

export default GuidanceDuscussions;
