import React, { useState } from 'react';
import { Box, Button, Typography, Menu, MenuItem } from '..';
import { LibraryBooksIcon } from '../../icons';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: { display: 'inline' },
}));

export const Actions = ({ actions, disabled }) => {
    const { classes } = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <Box className={classes.root}>
            <Button
                disableElevation
                color="primary"
                variant="contained"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                }}
                startIcon={<LibraryBooksIcon />}
            >
                Actions
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                disableScrollLock
                open={Boolean(anchorEl)}
                onClose={() => {
                    setAnchorEl(null);
                }}
            >
                {actions.map(({ name, onClick }, index) => (
                    <MenuItem key={index} onClick={onClick} disabled={disabled}>
                        {name}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};
