import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

function useFetchTopicByTopicId({ topicIds }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [topicData, setTopicData] = useState({});
    const [currIdx, setCurrIdx] = useState(0);

    const fetchTopicData = useCallback(
        async (topicIds) => {
            try {
                if (currIdx > topicIds.length - 1) {
                    setTopicData({});
                    return;
                }
                const topicId = topicIds[currIdx];
                if (!topicId) {
                    setTopicData({});
                    return;
                }
                setIsLoading(true);
                const url = `${process.env.REACT_APP_TG_API_DOMAIN}/get-topic-graph-data/${topicId}`;
                const { data, status } = await axios.get(url);
                if (status === 200) {
                    setTopicData(data);
                }
            } catch (e) {
                console.log(e);
                setCurrIdx((prev) => prev + 1);
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        },
        [currIdx]
    );

    useEffect(() => {
        if (topicIds?.length > 0) {
            fetchTopicData(topicIds);
        }
    }, [fetchTopicData, topicIds]);

    return { isLoading, isError, topicData };
}

export default useFetchTopicByTopicId;
