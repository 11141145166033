import { makeStyles } from 'tss-react/mui';
import { blue, green } from '@mui/material/colors';

export const useStyles = makeStyles()((theme) => ({
    marginStyle: {
        marginLeft: '50px',
    },
    coursePreviewContainer: {
        [theme.breakpoints.only('xs')]: {
            width: '85%',
            margin: '0 auto',
        },
    },
    coursePreviewVideo: {
        height: '500px',
        [theme.breakpoints.only('md')]: {
            height: '450px',
        },
        [theme.breakpoints.only('sm')]: {
            height: '300px',
        },
        [theme.breakpoints.only('xs')]: {
            height: '250px',
        },
    },
    saveButtonStyle: {
        position: 'absolute',
        zIndex: 1000,
        top: '-50px',
    },
    coursePreviewImage: {
        height: 'auto',
    },
    LeftSide: {
        width: '90%',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginBottom: '10px',
    },
    RightSide: {
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '150px',
    },
    Headline: {
        fontSize: '32px',
        fontFamily: 'Droid Serif',
        lineHeight: 1.5,
        fontWeight: 400,
    },
    Name: {
        lineHeight: 2,
    },
    VidoBox: {
        width: '95%',
        height: 500,
        border: '1px solid black',
        marginTop: '20px',
        cursor: 'pointer',
    },
    Button1: {
        /* backgroundColor: '#333232', */
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    LiveClassText: {
        textAlign: 'center',
        color: '#337ab7',
        lineHeight: 1.3,
        paddingTop: 25,
        fontWeight: 700,
        cursor: 'pointer',
    },
    LernFromExpertsText: {
        fontFamily: 'Lato, Sans-Serif',
        textAlign: 'center',
        lineHeight: 2,
        fontSize: 18,
        paddingTop: 25,
        fontWeight: 700,
    },
    ButtonCollectionGroupBox: {
        width: '90%',
        display: 'flex',
        flexGrow: 1,
        marginTop: '20px',

        justifyContent: 'space-evenly',
        [theme.breakpoints.down('md')]: {
            margin: '10px auto',
            // width: '400px',
            justifyContent: 'space-around',
        },
    },
    Button2: {
        // width: 120,
        marginLeft: '20px',
        backgroundColor: theme.palette.secondary.main,
        color: '#ffffff',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#333232',
        },
        [theme.breakpoints.down('md')]: {
            width: '130px',
        },
    },
    PriceText: {
        fontSize: '13px',
        marginRight: '3px',
        textAlign: 'center',
        fontWeight: 600,
    },
    IconsBox: {
        display: 'flex',
        flexFlow: 'row',
        minHeight: '70px',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.only('xs')]: {
            alignItems: 'flex-start',
            flexFlow: 'column-reverse',
        },
    },
    styleContainer: {
        [theme.breakpoints.down('md')]: {
            width: '100px',
        },
    },
    showResp: {
        [theme.breakpoints.down('lg')]: {
            display: 'block',
            marginBottom: 30,
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    hideResp: {
        [theme.breakpoints.down('xl')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    toTopContainer: {
        position: 'fixed',
        bottom: '85px',
        right: '10px',
        zIndex: 100,
    },
    recommendedStyle: {
        marginBottom: '3px',
        fontSize: '14px',
        color: '#a4a4a4',
        textAlign: 'center',
    },
    firstSectionStudents: {
        margin: '0 auto',
        flexBasis: '50%',
    },
    secondSectionStudents: {
        margin: '0 auto',
        flexBasis: '50%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    rightChatContainer: {
        marginTop: '35px',
        boxShadow: '0px 0px 8px 3px rgba(0, 0, 255, .2)',
    },
    rightChatBox: {
        minWidth: '340px',
        height: '320px',
        width: '100%',
    },
    chapterList: {
        /* width: '95%', */
        /* margin: 'auto', */
        marginBottom: 50,
    },
    subtitleAccessContainer: {
        marginTop: '5px',
        color: theme.palette.common.black,
        /* fontWeight: '600', */
        fontSize: '1.1em',
        textAlign: 'center',
    },
    btnDate: {
        marginTop: '5px',
        color: theme.palette.common.white,

        fontSize: '12px',
        textAlign: 'center',
        textTransform: 'capitalize',
    },
    enrollFreeTxt: {
        cursor: 'pointer',
        color: theme.palette.common.white,
        fontWeight: '600',
        width: 250,
        fontSize: '1em',
        textTransform: 'uppercase',
        padding: '7px 30px',
        borderRadius: 5,
        background: '#ea7112',
        textAlign: 'center',
    },
    enrollSubscribe: {
        alignItems: 'center',
        margin: '0 auto',
    },
    notSubscribedContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    getClass: {
        /* width: '165px', */
        alignItems: 'center',
    },
    subscribe: {
        /* maxWidth: '205px', */
        alignItems: 'center',
    },
    // ENROLL DIALOG STYLES
    dialog: {
        padding: '20px',
        display: 'flex',
        flexFlow: 'column',
    },
    balanceIcon: {
        backgroundColor: blue[700],
        padding: '5px 10px',
        borderRadius: '4px 0 0 4px',
    },
    balanceAmount: {
        backgroundColor: '#c5c5c5',
        padding: '8px 10px',
        alignSelf: 'stretch',
        borderRadius: '0px 4px 4px 0',
    },
    row: {
        alignSelf: 'flex-end',
        display: 'flex',
        flexFlow: 'row',
        margin: '20px 0',
    },
    dialogTitle: {
        padding: '5px 0',
    },
    marginLeft: {
        marginLeft: 20,
    },
    // credit price in enroll
    creditPriceContainer: {
        // border: '2px solid rgba(0,0,0,0.4)',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        cursor: 'pointer',
        transition: 'all .2s ease-out',
        '& > button': {
            padding: '5px',
            borderRadius: '0px 5px 5px 0px',
            borderLeft: 'none',
        },
    },
    credits: {
        wordSpacing: '5px',
        transition: 'all .2s ease-out',
        width: '240px',
        fontSize: '0.9em',
        maxHeight: 46,
        padding: '5px 20px',
        paddingRight: '20px',
        borderRadius: '5px 0px 0px 5px',
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        border: `1px solid ${theme.palette.secondary.main}`,
        '& > span:last-child': {
            fontSize: '0.9em',
        },
    },
    creditPriceActive: {
        '& > $credits': {
            border: `1px solid ${theme.palette.secondary.main}`,
            borderColor: green[700],
            backgroundColor: green[500],
            color: '#fafafa',
        },
    },
    // summary
    productSummary: {
        padding: '10px 20px',
        marginTop: '10px',
        minHeight: '90%',
        borderRadius: '10px',
        backgroundColor: 'rgba(0,0,0,0.1)',
    },
    productFeature: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '10px 0',
        '&:last-child': {
            marginBottom: '20px',
        },
    },
    feature: {
        marginLeft: 10,
    },
    iconFeature: {
        color: green[800],
    },
    productInfoContainer: {
        display: 'flex',
        flexFlow: 'row',
        marginBottom: '5px',
    },
    productInfoLabel: {
        flexBasis: '65%',
    },
    strike: {
        textDecoration: 'line-through',
        color: '#999',
    },
    final: {
        fontSize: '1.2em',
        fontWeight: 900,
        margin: '10px 0',
        color: `${blue[900]} !important`,
    },
    productInfoValue: {
        flexBasis: '35%',
        marginRight: '20px',
        textAlign: 'end',
        color: '#555',
    },
    watchTrailerView: {
        position: 'absolute',
        color: '#000',
        zIndex: '100',
        padding: '0',
        marginTop: '-3.5rem',
        marginLeft: '11px',
        width: '180px',
    },
    watchTrailerBtn: {
        padding: '2px',
        width: '100%',
        textAlign: 'center',
        borderRadius: '2px',
        fontWeight: 'bold',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '15px',
        paddingLeft: '0px',
        background: 'yellow',
    },
    // credits checkout
    customCredit: {
        height: '40px',
        width: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '4px',
        borderRadius: '0px 5px 5px 0px',
        border: `1px solid ${theme.palette.secondary.main}`,
    },
    activeCredit: {
        borderColor: green[700],
        backgroundColor: green[500],
        color: '#fafafa',
    },
}));
