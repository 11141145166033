import React, { useMemo } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ClapsIcons, CloseIcon, CommentIcon } from '../icons';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InstructorDetails from '../dashboard/components/InstructorDetails';

const useStyles = makeStyles()((theme) => ({
    cardContainer: {
        // border: '2px solid red',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            padding: '0 0 1.25rem 0',
        },
    },
    cardWrapper: {
        height: '100%',
        border: '2px solid #f3f4f6',
        borderRadius: '0.6rem',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        paddingBottom: '0.5rem',
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            transition: 'all 150ms ease-in 50ms',
            '&:hover': {
                transform: 'scale(1.02)',
                boxShadow:
                    '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
            },

            '& img': {
                transition: 'all 150ms ease-in 50ms',
            },

            '&:hover img': {
                transform: 'scale(1.03)',
            },
        },
    },
    cardHeader: {
        maxHeight: 220,
        height: '100%',
        position: 'relative',
        '& > img': {
            width: '100%',
            height: '100%',
            // aspectRatio: '16 / 9',
            objectFit: 'cover',
        },
    },
    cardLabel: {
        position: 'absolute',
        zIndex: '1',
        top: '1rem',
        left: 0,
        // backgroundColor: 'rgb(241, 77, 104)',
        color: 'rgb(255, 255, 255)',
        fontSize: '0.75rem',
        borderRadius: '0.1875rem',
        padding: '0.3125rem 0.625rem',
        fontWeight: 600,
        clipPath: 'polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%)',
        boxShadow:
            '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    },
    cardBody: {
        padding: '0.5rem 1.5rem',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    cardTitle: {
        fontSize: '1.375rem',
        lineHeight: '2rem',
        margin: 0,
    },
    instructorName: {
        fontWeight: 600,
        marginTop: '0.5rem',
        color: 'rgb(99, 99 ,99)',
    },
    cardSubtitle: {
        fontSize: '0.875rem',
        color: 'rgb(99,99,99)',
        margin: 0,
        lineHeight: '1.5rem',
        marginTop: '0.5rem',
        fontWeight: 400,
    },
    cardStats: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        color: 'rgb(99,99,99)',
        marginTop: '1rem',
        lineHeight: '0.8125rem',
        fontWeight: 400,
        '& > *': {
            margin: 0,
            padding: 0,
        },
    },
    likeCounter: {
        display: 'flex',
        alignItems: 'center',
    },
    cardContent: {
        fontSize: '1rem',
        fontWeight: 300,
        lineHeight: '1.5rem',
        color: 'rgb(55, 63, 73)',
        margin: '1rem 0rem',
    },
    closeButton: {
        position: 'absolute',
        right: '0.5rem',
        top: '0.5rem',
        zIndex: '1',
    },
    buttonGroups: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 'auto',
        gap: '1.25rem',
        '& > *': {
            flex: 1,
            margin: 0,
        },
    },
    cardActions: {
        padding: '0.5rem 1.5rem',
        color: 'rgb(55, 65, 75)',
        fontWeight: '400',
        borderBottom: '2px solid #EA7112',
        marginBottom: '0.5rem',
        '& > *': {
            margin: 0,
        },

        '& > *:last-child': {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '0.5rem',
        },
    },
}));

const SWRecomendationCard = (props) => {
    const {
        item,
        id,
        imgSrc,
        courseName,
        instructorName,
        duration,
        content,
        setRecommendedCoursesIDToDismiss,
        showEnrollBtn,
        handleViewBtnClick,
        handleEnrollClick,
        showCardActions,
        day,
        date,
        closeStatus,
        showCloseBtn,
        showRemoveBtn,
        handleRemove,
        isDashboard = false,
        instructors = [],
    } = props;
    const { classes } = useStyles();

    const showHrPipeSymbol = useMemo(() => {
        if (
            (item?.level || item?.courseLevel) &&
            duration &&
            duration.split('|').length > 1
        ) {
            return '|';
        }

        return '';
    }, [duration, item?.courseLevel, item?.level]);

    const getDomain = useMemo(() => {
        if (!duration) return '';
        const domain = duration.split('|');
        if (domain.length === 0) return '';
        return domain[0];
    }, [duration]);

    const getDuration = useMemo(() => {
        if (!duration) return '';
        const time = duration.split('|');
        if (time.length === 0 || time.length === 1) return '';
        return time[1];
    }, [duration]);

    const getLevel = useMemo(() => {
        const level = item?.level || item?.courseLevel;
        if (level) {
            return level;
        } else {
            return '';
        }
    }, [item?.courseLevel, item?.level]);

    const getLimitedText = (text) => {
        if (text?.length > 250) {
            return text?.slice(0, 250) + '...';
        } else {
            return text;
        }
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setRecommendedCoursesIDToDismiss(id);
    };

    return (
        <article
            className={classes.cardContainer}
            style={{ padding: isDashboard ? 0 : '1rem' }}
        >
            <div
                className={classes.cardWrapper}
                onClick={(e) => {
                    e.stopPropagation();
                    handleViewBtnClick(item);
                }}
            >
                {showCardActions === true && (
                    <div className={classes.cardActions}>
                        <p style={{ textTransform: 'uppercase' }}>{day}</p>
                        <p>
                            <span>{date}</span> <span>{closeStatus}</span>
                        </p>
                    </div>
                )}
                {/* CARD HEADER */}
                <div className={classes.cardHeader}>
                    <img
                        src={imgSrc}
                        alt={courseName}
                        height={200}
                        width={500}
                    />
                    {showCloseBtn === true && (
                        <Tooltip title="Close?" placement="left-start">
                            <IconButton
                                aria-label="close"
                                size="small"
                                className={classes.closeButton}
                                onClick={handleClose}
                            >
                                <CloseIcon
                                    fontSize="small"
                                    style={{ color: 'black' }}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>

                {/* CARD BODY */}
                <div className={classes.cardBody}>
                    <h2 className={classes.cardTitle}>{courseName}</h2>

                    {!isDashboard && instructorName && (
                        <span className={classes.instructorName}>
                            {instructorName}
                        </span>
                    )}

                    <p className={classes.cardSubtitle}>
                        {/* DOMAIN NAME */}
                        {getDomain.length > 0 && (
                            <span>{getDomain} &#124;&nbsp;</span>
                        )}

                        {/* COURSE LEVEL */}
                        {getLevel.length > 0 && <span>{getLevel}&nbsp;</span>}

                        {showHrPipeSymbol && <span>&#124;</span>}

                        {/* COURSE DURATION */}
                        <span>{getDuration}</span>
                    </p>

                    <p className={classes.cardContent}>
                        {getLimitedText(content)}
                    </p>

                    {/* INSTRUCTOR DETAILS */}
                    {isDashboard && instructors?.length > 0 && (
                        <InstructorDetails instructors={instructors} />
                    )}

                    <div className={classes.buttonGroups}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleViewBtnClick(item);
                            }}
                        >
                            VIEW
                        </Button>
                        {showEnrollBtn && (
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleEnrollClick(item);
                                }}
                            >
                                ENROLL
                            </Button>
                        )}

                        {!showEnrollBtn && showRemoveBtn === true && (
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemove(item);
                                }}
                            >
                                REMOVE
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </article>
    );
};

export default SWRecomendationCard;
