import { Box, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    actionContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
    },
}));

function ActionContainer({ handleAddGuidance, onActionClick }) {
    const { classes } = useStyles();

    return (
        <Box mb={2} className={classes.actionContainer}>
            <Button
                variant="outlined"
                color="secondary"
                onClick={handleAddGuidance}
            >
                CREATE NEW
            </Button>
            <Button variant="contained" onClick={onActionClick}>
                ACTIONS
            </Button>
        </Box>
    );
}

export default ActionContainer;
