import React, { useState } from 'react';

import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '..';

export const SingleSelect = ({
    label,
    options,
    selectedValue,
    setSelectedValue,
    style,
    required,
    canNotBeNone,
    isError = false,
    helperText = '',
    resetError = undefined,
    readOnly = false,
    fullWidth,
}) => {
    return (
        <FormControl
            variant="outlined"
            color="secondary"
            style={{ ...style, minWidth: 200 }}
            required={required || false}
            fullWidth={fullWidth ? fullWidth : false}
            error={!!isError}
        >
            {label && (
                <InputLabel style={{ backgroundColor: '#FAFAFA' }}>
                    {label}
                </InputLabel>
            )}
            <Select
                value={selectedValue}
                onChange={(e) => {
                    if (resetError) {
                        resetError.handler(resetError.key);
                    }
                    setSelectedValue(e.target.value);
                }}
                error={isError}
                readOnly={readOnly}
                sx={{ height: '100%' }}
                fullWidth
            >
                {canNotBeNone ? null : (
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                )}

                {options.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};
