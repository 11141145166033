import { useMemo, useState } from 'react';
import { AdminContainer } from '../admin/AdminContainer';
// import { useStardustChartUser } from '../admin/reports/useStardustChartUser';
// import { useStardustReportDetails } from '../admin/reports/useStardustReportDetails';
import { useIsAdmin } from '../admin/useIsAdmin';
import { Box, Loading /* Paper */ } from '../ui';
import { LeaderboardSection } from './LeaderboardSection';
import { useCurrentUser } from './useCurrentUser';

const TAB_VALUES = [
    {
        name: 'Overall',
        value: 'overall',
    },
    { name: 'Company', value: 'company' },
];

const Leaderboard = () => {
    const { isAdmin, client, isClientLoading } = useIsAdmin();
    const { user } = useCurrentUser();
    const [value /* setValue */] = useState(0);
    // const { allUserReports } = useStardustReportDetails({ noReport: true });
    // const { chart } = useStardustChartUser({ data: allUserReports, isAdmin });

    const collections = useMemo(() => {
        if (client._id) {
            return TAB_VALUES.filter((tab) => tab.value !== 'overall');
        } else {
            return TAB_VALUES.filter((tab) => tab.value !== 'company');
        }
    }, [client]);

    const componentToRender = useMemo(() => {
        const collectionValue = collections.filter(
            (tabsValue, index) => index === value
        )[0].value;

        switch (collectionValue) {
            case 'overall':
                return (
                    <LeaderboardSection isAdmin={isAdmin} userId={user?._id} />
                );
            case 'company':
                return (
                    <LeaderboardSection
                        isAdmin={isAdmin}
                        userId={user?._id}
                        clientId={client?._id}
                    />
                );
            default:
                <LeaderboardSection isAdmin={isAdmin} userId={user?._id} />;
        }
    }, [collections, value, user, client, isAdmin]);

    if (isClientLoading) return <Loading />;

    return (
        <AdminContainer maxWidth="xl">
            <Box mt={2}>
                {/* <Box mt={2}>
                    <NavigationTab
                        collections={collections}
                        value={value}
                        setValue={setValue}
                    />
                </Box> */}
                {/* <Box my={2}>
                    <Paper elevation={5}>
                        <Box mt={2} p={2}>
                            {chart}
                        </Box>
                    </Paper>
                </Box> */}
                <Box mt={2}>{componentToRender}</Box>
            </Box>
        </AdminContainer>
    );
};

export default Leaderboard;
