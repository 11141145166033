import React from 'react';
import { Grid } from '../ui';
import { useStyles } from './styles';
import {
    topKnownLogos as knownLogoArray,
    // topFiveLogos as logoArray,
} from '../auth/logos';

export const LazyTopFiveLogoArray = () => {
    const { classes } = useStyles();
    const [target, setTarget] = React.useState(false);

    const observer = React.useRef(
        new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    console.log(entries[0].isIntersecting);
                    setTarget(true);
                    entries[0].target.style.minHeight = 0;
                    entries[0].target.style.minWidth = 0;
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.1,
            }
        )
    );
    React.useEffect(() => {
        setTimeout(function () {
            if (document) {
                let placeholderArrayLogo = document.querySelector(
                    '.placeholderArrayLogo'
                );

                if (placeholderArrayLogo)
                    placeholderArrayLogo.style.display = 'none';
            }
        }, 1000);

        const node = document.querySelector('#targetforArrayLogo');
        if (observer.current && node !== null) {
            observer.current.observe(node);
        }
    });

    return (
        <React.Fragment>
            <div
                className="placeholderArrayLogo"
                style={{
                    minHeight: '100px',
                    minWidth: '200px',
                    background: 'rgba(255,0,0,0)',
                }}
            />

            <div
                id="targetforArrayLogo"
                style={{
                    minHeight: '50px',
                    minWidth: '200px',
                    background: 'rgba(0,0,0,0)',
                }}
            />

            {target &&
                knownLogoArray.map((item, index) => (
                    <Grid
                        key={index}
                        item
                        xs={4}
                        md={2}
                        style={{ backgroundImage: `url(${item})` }}
                        className={classes.logo}
                    />
                ))}
        </React.Fragment>
    );
};
