import { makeStyles } from 'tss-react/mui';
import { useCurrentUser } from '../../../my-account/useCurrentUser';
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { roleViewState } from '../../../navigation/recoil/roleView';

const useStyles = makeStyles()((theme) => ({
    pseduoTextBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // height: '2.5rem',
        borderRadius: '0.25rem',
        cursor: 'pointer',
        border: '1px solid #74777b',
        padding: '0.5rem',
        fontSize: '0.875rem',
        color: '#74777b',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
            padding: '0.875rem',
        },
    },
}));

function DummyInput({ handleToggleEditor, handleShowCkEditor }) {
    const { classes } = useStyles();
    const { user } = useCurrentUser();
    const [roleView] = useRecoilState(roleViewState);

    const placeholder = useMemo(() => {
        if (user?.is_instructor && roleView.selectedView === 'instructor') {
            return 'Discuss progress, share feedback, and collaborate. Use @ to tag people | Use / to tag tasks | Use ~ to tag a course';
        }

        return 'Discuss progress, share feedback, and collaborate. Use @ to tag people | Use # to tag teams | Use / to tag tasks | Use ~ to tag a course';
    }, [roleView.selectedView, user?.is_instructor]);

    const handleClick = () => {
        handleToggleEditor();
        handleShowCkEditor();
    };

    return (
        <div className={classes.pseduoTextBox} onClick={handleClick}>
            {placeholder}
        </div>
    );
}

export default DummyInput;
