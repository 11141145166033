import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
    Grid,
    Container,
    Typography,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
} from '../ui';
// import { authorSignupRoute as route } from '../seo/routeMetaData';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles()((theme) => ({
    root: {
        marginTop: '7em',
    },
    button: {
        height: 45,
        width: 200,
        fontSize: '1rem',
        marginTop: '1em',
    },
}));

export const AuthorSignUpPage = () => {
    const { classes } = useStyles();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [email, setEmail] = useState('');
    const [emailHelper, setEmailHelper] = useState('');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordHelper, setConfirmPasswordHelper] = useState('');

    const [checkBoxes, setCheckBoxes] = useState({
        courses: false,
        assessments: false,
        projects: false,
        labs: false,
    });
    const { courses, assessments, projects, labs } = checkBoxes;

    const onChangeEmail = (event) => {
        setEmail(event.target.value);
        setEmailHelper('');
    };

    const onBlurEmail = () => {
        let valid;

        valid = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            email
        );

        if (!valid && email !== '') {
            setEmailHelper('Invalid email');
        } else {
            setEmailHelper('');
        }
    };

    const onChangeConfirmPassword = (event) => {
        setConfirmPassword(event.target.value);
        setConfirmPasswordHelper('');
    };

    const onBlurConfirmPassword = () => {
        if (password !== confirmPassword && confirmPassword !== '') {
            setConfirmPasswordHelper('Passwords do not match');
        } else {
            setConfirmPasswordHelper('');
        }
    };

    const handleCheckBoxesChange = (event) => {
        setCheckBoxes({
            ...checkBoxes,
            [event.target.name]: event.target.checked,
        });
    };

    const onContinue = () => {
        console.log({
            firstName,
            lastName,
            email,
            courses,
            assessments,
            projects,
            labs,
            password,
        });
    };

    return (
        <>
            <Container maxWidth="sm" className={classes.root}>
                <form>
                    <Grid
                        container
                        direction="column"
                        spacing={2}
                        alignItems="stretch"
                    >
                        <Grid item>
                            <Typography
                                variant="h3"
                                component="h2"
                                gutterBottom
                                align="center"
                            >
                                JOIN OUR COMMUNITY
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        id="firstName"
                                        label="FIRST NAME"
                                        variant="filled"
                                        fullWidth
                                        value={firstName}
                                        onChange={(event) =>
                                            setFirstName(event.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="lastName"
                                        label="LAST NAME"
                                        variant="filled"
                                        fullWidth
                                        value={lastName}
                                        onChange={(event) =>
                                            setLastName(event.target.value)
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="email"
                                label="EMAIL ADDRESS"
                                error={emailHelper.length !== 0}
                                helperText={emailHelper}
                                variant="filled"
                                fullWidth
                                value={email}
                                onChange={onChangeEmail}
                                onBlur={onBlurEmail}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        id="password"
                                        label="Password"
                                        type="password"
                                        autoComplete="current-password"
                                        variant="filled"
                                        fullWidth
                                        value={password}
                                        onChange={(event) =>
                                            setPassword(event.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="confirm-password"
                                        label="Confirm Password"
                                        error={
                                            confirmPasswordHelper.length !== 0
                                        }
                                        helperText={confirmPasswordHelper}
                                        type="password"
                                        autoComplete="current-password"
                                        variant="filled"
                                        fullWidth
                                        value={confirmPassword}
                                        onChange={onChangeConfirmPassword}
                                        onBlur={onBlurConfirmPassword}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Typography variant="BUTTON TEXT" gutterBottom>
                                I WOULD BE INTERESTED IN CREATING:{' '}
                                {'(CHECK ALL THAT APPLY)'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <FormControlLabel
                                        value="end"
                                        control={
                                            <Checkbox
                                                color="grey"
                                                checked={courses}
                                                onChange={
                                                    handleCheckBoxesChange
                                                }
                                                name="courses"
                                            />
                                        }
                                        label="Courses"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        value="end"
                                        control={
                                            <Checkbox
                                                color="grey"
                                                checked={assessments}
                                                onChange={
                                                    handleCheckBoxesChange
                                                }
                                                name="assessments"
                                            />
                                        }
                                        label="Assessments"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        value="end"
                                        control={
                                            <Checkbox
                                                color="grey"
                                                checked={projects}
                                                onChange={
                                                    handleCheckBoxesChange
                                                }
                                                name="projects"
                                            />
                                        }
                                        label="Projects"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        value="end"
                                        control={
                                            <Checkbox
                                                color="grey"
                                                checked={labs}
                                                onChange={
                                                    handleCheckBoxesChange
                                                }
                                                name="labs"
                                            />
                                        }
                                        label="Labs"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="BUTTON TEXT" gutterBottom>
                                WHO WILL BENEFIT FROM THE CONTENT I CREATE?:{' '}
                                {`(TOPICS WE'RE CURRENTLY RECRUITING FOR)`}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <FormControl
                                variant="filled"
                                /* className={classes.formControl} */
                            >
                                <InputLabel htmlFor="filled-age-native-simple">
                                    Topic
                                </InputLabel>
                                <Select native>
                                    <option aria-label="None" value="" />
                                    <option value={0}>Cloud Computing</option>
                                    <option value={1}>Business</option>
                                    <option value={2}>Finance</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                onClick={onContinue}
                                className={classes.button}
                            >
                                CONTINUE
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </>
    );
};
