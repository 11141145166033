import { createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            main: '#0f327e',
        },
        secondary: {
            main: '#ea7112',
        },
        // primary: {
        //     main: '#f1bf7d',
        // },
        // secondary: {
        //     main: '#1ebbf0',
        // },
        common: {
            blue: '#0f327e',
            green: '#12a212',
            lightOrange: '#ea6f14',
            lightBlue: blue[700],
        },
    },
    typography: {
        /* fontFamily: [
            'Roboto',
            'Helvetica',
            'Arial',
            'Josefin Sans',
            'sans-serif',
            'Minion Pro,serif',
            'Droid Serif',
            'Nunito',
        ].join(','), */
        allVariants: {
            fontFamily: 'var(--font-family)', // this line make sure that it uses the font family mentioned in index.css
        },
        h3: {
            fontSize: '2rem',
            '@media (min-width: 600px)': {
                fontSize: '2.8rem',
            },
        },
    },

    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    backgroundColor: '#fff',
                },
            },
        },
    },

    // breakpoints: {
    //     values: {
    //         xs: 0,
    //         sm: 600,
    //         md: 900,
    //         lg: 1200,
    //         xl: 1536,
    //     },
    // },
    // overrides: {
    //     MuiSwitch: {
    //         colorPrimary: {
    //             color: blue[700],
    //             '&$checked': {
    //                 color: blue[700],
    //             },
    //             '&$checked + $track': {
    //                 backgroundColor: blue[100],
    //             },
    //         },
    //     },
    //     MuiCheckbox: {
    //         colorPrimary: {
    //             color: '#ea7112',
    //             '&$checked': {
    //                 color: '#ea7112',
    //             },
    //         },
    //     },
    //     MuiButton: {
    //         root: {
    //             fontWeight: 600,
    //         },
    //         textPrimary: {
    //             color: '#333 !important',
    //         },
    //         sizeSmall: {
    //             fontSize: '0.8em',
    //             padding: '3px 7px',
    //         },
    //         containedPrimary: {
    //             backgroundColor: blue[700],
    //             color: '#fafafa',
    //             '&:hover': {
    //                 backgroundColor: blue[900],
    //             },
    //         },
    //     },
    // },
    // shape: {
    //     borderRadius: 2,
    // },
});
