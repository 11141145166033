import { coursesSlice } from '../courses/coursesSlice';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
const { getCourses } = coursesSlice;

const getAllCourse = (tags) => (state) => {
    // const courses = getCourses(state);
    // return courses.slice(0, 12);

    return [];
};

export const useGetTopCourses = ({ user, tags }) => {
    const course = useSelector(getAllCourse(tags));
    const [topCourses, setTopCourses] = useState([]);
    useEffect(() => {
        if (user) {
            let savedArray = [];
            course.forEach((item) => {
                let newItem = { ...item };
                if (user.user_save.includes(item.id)) {
                    newItem.saved = true;
                } else {
                    newItem.saved = false;
                }
                savedArray.push(newItem);
            });
            setTopCourses(savedArray);
        }
    }, [user]);

    return topCourses;
};
