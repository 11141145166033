import { TablePagination } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { LinearLoader } from '../../../util';
import FeedCard from './FeedCard';
import NoFeeds from './NoFeeds';
// import { COLLABORATOR } from '../../../auth/roles';

const useStyles = makeStyles()((theme) => {
    return {
        paginationContainer: {
            marginTop: theme.spacing(2),
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
        },
    };
});

function Feeds({
    rows,
    rowCount,
    page,
    limit,
    setPage,
    setLimit,
    loading,
    userDetails,
    setRows,
    isInstructorView,
    selectedRole,
    teams,
}) {
    const { classes } = useStyles();

    const handleChangePage = (event, newPage) => {
        window.scrollTo(0, 0);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setLimit(parseInt(event.target.value, 10));
        window.scrollTo(0, 0);
    };
    // console.log(rows);
    return (
        <div>
            {loading && <LinearLoader />}

            {rows?.map((row) => {
                return (
                    <FeedCard
                        key={row._id}
                        row={row}
                        userDetails={userDetails}
                        setRows={setRows}
                        isInstructorView={isInstructorView}
                        selectedRole={selectedRole}
                        teams={teams}
                    />
                );
            })}

            {/* {rows?.length === 0 && <Box><Typography>No Feeds!</Typography><Box/>} */}

            {!loading && rows?.length === 0 && <NoFeeds />}

            {!loading && rows?.length > 0 && (
                <div className={classes.paginationContainer}>
                    <TablePagination
                        component="div"
                        count={rowCount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={limit}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    />
                </div>
            )}
        </div>
    );
}

export default Feeds;
