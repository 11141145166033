import React, { useState } from 'react';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListSearchAndSelect,
    Typography,
} from '../ui';

import { useSMEsSearch } from './useSMEsSearch';
import { alertState, severity } from '../app/recoil';
import axios from 'axios';
import { useSetRecoilState } from 'recoil';

export const SMEsSearchAndSelect = ({
    title,
    subTitle,
    selected,

    setSmeModal,
    onClickSelected,
    selectedListView,
    nextStageSMEsSelected,
    nextStageSMEField,
    // addedSearchTerms,
    // setAddedSearchTerms,
    // skillErr,
    // setSkillErr,
    // resetError = undefined,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);

    const [openConfirm, setOpenConfirm] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const setAlert = useSetRecoilState(alertState);
    const [deleting, setDeleting] = useState(false);

    const { results, hasMore, loading, error } = useSMEsSearch(
        searchQuery,
        pageNumber,
        5
    );

    const handleSelect = (item) => {
        //console.log(item);
        setSmeModal((prev) => {
            if (prev.smes.findIndex((elem) => elem._id === item._id) === -1) {
                return { ...prev, smes: [item, ...prev.smes] };
            } else {
                return prev;
            }
        });
    };

    const handleDelete = (itemToDelete) => {
        setSmeModal((prev) => ({
            ...prev,
            smes: prev.smes.filter((item) => item._id !== itemToDelete._id),
        }));

        if (nextStageSMEsSelected)
            setSmeModal((prev) => {
                return {
                    ...prev,
                    nextStageSMEs: prev.nextStageSMEs?.filter(
                        (s) => s._id !== itemToDelete._id
                    ),
                };
            });
    };

    const handleSelectWithinSelect = (item) => {
        //console.log(item);
        setSmeModal((prev) => {
            if (
                prev.nextStageSMEs.findIndex(
                    (elem) => elem._id === item._id
                ) === -1
            ) {
                return {
                    ...prev,
                    nextStageSMEs: [item, ...prev.nextStageSMEs],
                };
            } else {
                return {
                    ...prev,
                    nextStageSMEs: prev.nextStageSMEs?.filter(
                        (s) => s._id !== item._id
                    ),
                };
            }
        });
    };

    const getDisplayText = (item) => `${item.firstName} ${item.lastName}`;

    const handleRemoveItem = async (itemToDelete) => {
        try {
            setDeleting(true);
            const res = await axios.delete(`/api/smes/${itemToDelete._id}`);

            if (res.status === 200) {
                setSmeModal((prev) => ({
                    ...prev,
                    smes: prev.smes.filter(
                        (item) => item._id !== itemToDelete._id
                    ),
                }));
                if (nextStageSMEsSelected) {
                    setSmeModal((prev) => ({
                        ...prev,
                        nextStageSMEs: prev.nextStageSMEs?.filter(
                            (s) => s._id !== itemToDelete._id
                        ),
                    }));
                }
                setAlert({
                    show: true,
                    message: res.data.message,
                    severity: severity.SUCCESS,
                });
                setSearchQuery('');
                setPageNumber(1);
                setDeleting(false);
                setOpenConfirm(false);
            }
        } catch (e) {
            setDeleting(false);
            console.log(e.response?.data?.message);
            if (e.response?.data?.message) alert(e.response?.data?.message);
        }
    };

    return (
        <>
            {/* <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
                <DialogTitle>Confirm Removal</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to remove{' '}
                        <strong>
                            {`${selectedItem?.firstName} ${selectedItem?.lastName}` ||
                                'this item'}
                        </strong>
                        ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenConfirm(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleRemoveItem(selectedItem);
                        }}
                        color="secondary"
                        variant="contained"
                    >
                        {deleting ? (
                            <CircularProgress size="1.9em" />
                        ) : (
                            <>Remove</>
                        )}
                    </Button>
                </DialogActions>
            </Dialog> */}

            <ListSearchAndSelect
                heading={title}
                subHeading={subTitle}
                list={results}
                selectedList={selected}
                handleItemSelect={handleSelect}
                handleItemDelete={handleDelete}
                idField="_id"
                subTextField="email"
                paginationMode="server"
                search={searchQuery}
                setSearch={setSearchQuery}
                setPageNumber={setPageNumber}
                loading={loading}
                hasMore={hasMore}
                getDisplayText={getDisplayText}
                onClickSelected={onClickSelected}
                selectedListView={selectedListView}
                selectedWithinSelected={nextStageSMEsSelected}
                showSelectionWithinSelection={nextStageSMEField}
                handleSelectWithinSelect={handleSelectWithinSelect}
                selectedWithinSelectedTooltip={`Check to ${nextStageActions[nextStageSMEField]} this instructor to the requirement.`}
                profilePicField="profilePicUrl"
                // showProfilePic={true}
                // isRemoveItem={true}
                // setOpenConfirm={setOpenConfirm}
                // setSelectedItem={setSelectedItem}
            />
        </>
    );
};

const nextStageActions = {
    shortListedSmes: 'shortlist',
    smes: 'assign',
};
