import axios from 'axios';
import moment from 'moment-timezone';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { alertState, severity } from '../app/recoil';
import {
    Box,
    Button,
    ConfirmDialog,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    MenuItem,
    PageLoader,
    // Loading,
    SelectCards,
    TextField,
    Typography,
    WithStickyHeader,
} from '../ui';
// import styled from 'styled-components';
import {
    BuildIcon,
    CloseIcon,
    ContactsIcon,
    EditIcon,
    FilterListIcon,
    IconButton,
    LaptopChromebookIcon,
    LibraryBooksIcon,
    LiveTvIcon,
    NoteIcon,
    ShareIcon,
    SupervisedUserCircleIcon,
} from '../icons';
import CircularArrowProgressIcon from '../img/circular_arrow_loading.png';
import CircularCheckIcon from '../img/ok_check_green.png';
// import { CustomGridToolBar } from './CustomGridToolBar';
import {
    Android,
    ArrowRightRounded,
    FileCopy,
    MenuBook,
    Timeline,
    Visibility,
} from '@mui/icons-material';
import {
    Backdrop,
    CircularProgress,
    FormControlLabel,
    Paper,
    Rating,
    Switch,
    Tooltip,
    useMediaQuery,
} from '@mui/material';

import {
    DataGridPremium,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid-premium';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { domainOptions, getBaseURL, roleToHeader } from '../util';
import { ClientRequirementCreateFormSampleOutlineInstructors } from './ClientRequirementCreateFormSampleOutlineInstructors';
import { ClientRequirementsBulkUpdateModal } from './ClientRequirementsBulkUpdateModal';
import { ClientRequirementsBulkUpload } from './ClientRequirementsBulkUpload';
import { ClientRequirementsSMECell } from './ClientRequirementsSMECell';
import InstructorApplicantsModal from './InstructorApplicantsModal';
import InstructorMatchingModal from './InstructorMatchingModal';
import { useIsAdmin } from './useIsAdmin';
// import AdminAttachement from './AdminAttachement';
import * as XLSX from 'xlsx';
import SMEModal from '../smes/SMEModal';
import SMEModal2 from '../smes/SMEModal2';
import { ShareModal } from '../ui/CustomUI';
import { clientRequirementCalenders } from './clientRequirementCalenders';
import OutlineDialog from './OutlineDialog';
// import { selectedRoleState } from '../navigation/state';
// import { MANAGER, COLLABORATOR } from '../auth/roles';
import { useDispatch, useSelector } from 'react-redux';
import starweaverLogo from '../img/favicon-16x16.png';
import starweaverAnalyticsLogo from '../img/tg_blue_logo.png';
import { roleList } from '../util';
import { useQueryParams } from '../util/useQueryParams';
import { ClientRequirementsBulkUploadLinkedIn } from './ClientRequirementsBulkUploadLinkedIn';
import OMCommentBtn from './components/ClientRequirements/OMCommentBtn';
import PercentageCompleteProgress from './components/ClientRequirements/PercentageCompleteProgress';
import RequirementSummaryDrawer from './components/ClientRequirements/RequirementSummaryDrawer';
import BulkCommentDialog from './components/OpportunityManagement/BulkCommentDialog';
import ContactInfoDialog from './components/OpportunityManagement/ContactInfoDialog';
import TableFilterModal from './components/OpportunityManagement/TableFilterModal';
import useCommentsUserMetaData from './hooks/useCommentsUserMetaData';
import { hideRequirementSummary } from './slices/requirementSummarySlice';
import { storeChoosenTemplate } from './slices/templateSlice';
import { useThirdPartyCourseData } from './useThirdPartyCourseData';
// import { useThirdPartyCourseSearch } from './useThirdPartyCourseSearch';
import HtmlTooltip from '../custom-components/HTMLTooltip';
import ExpertSummaryDrawer from '../instructor/components/ExpertSummaryDrawer';
import RatingDialog from '../instructor/components/RatingDialog';
import { resetStore as expertSummaryResetStore } from '../instructor/slices/expertSummarySlice';
import { roleViewState } from '../navigation/recoil/roleView';
import MarketMetricTooltipInfo from './components/ClientRequirements/MarketMetricTooltipInfo';
import ThirdPartyCourseSearchDialog from './components/ThirdPartyCourseSearchDialog';
import { DATAGRIDSX } from '../my-account/utils';

export const fieldToHeader = {
    // collaborators: 'Lead Collaborators',
    addedCollaborators: 'Added Collaborators',
    // shortListedSmes2: 'Shortlisted',
    // smes2: 'Assigned',
    identifiedSmes: 'Identified',
    shortListedSmes: 'Shortlisted',
    smes: 'Assigned',
    ...roleToHeader,
};

const nextStageSMEField = {
    identifiedSmes: 'shortListedSmes',
    shortListedSmes: 'smes',
};

let filterTimeoutId = null;

const useStyles = makeStyles()((theme) => ({
    root: { width: '100%' },
    controls: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
        gap: '1rem',
        flexDirection: 'column',
        '& > *': {
            margin: 'unset',
            width: '100%',
            '& > *': {
                width: '100%',
            },
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            '& > *': {
                width: 'unset',
                '& > *': {
                    width: 'unset !important',
                },
            },
        },
    },
    clearFilterBtn: {
        marginTop: '1rem',
        [theme.breakpoints.up('sm')]: {
            marginTop: '0rem',
            marginLeft: '1rem',
        },
    },
    actionsBtn: { marginLeft: 'auto' },

    titleContainer: {
        position: 'relative',
        width: '100%',
    },
    title: {
        fontSize: '0.875rem',
        cursor: 'pointer !important',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    titleLink: {
        fontWeight: 'bold',
        color: theme.palette.common.lightBlue,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    edit: {
        // position: 'absolute',
        color: theme.palette.common.lightBlue,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    clone: {
        position: 'absolute',
        color: theme.palette.common.lightBlue,
        cursor: 'pointer',
        left: 25,
    },
    copyURL: {
        cursor: 'pointer',
        position: 'relative',
        top: 7,
        left: 3,
    },
    streamNow: {
        fontWeight: 'bold',
        color: theme.palette.common.lightBlue,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    instructortitle: {
        minWidth: '10rem',
        cursor: 'pointer',

        '& > *:first-child': {
            textDecoration: 'none',
        },

        '& > .view-pdf': {
            color: 'blue',
            cursor: 'pointer',
            fontSize: '0.4rem',
        },

        '& > .view-pdf:hover': {
            textDecoration: 'underline',
        },
    },
    submenuContainer: {
        position: 'relative',
        '& > .submenu': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        '& > .submenu-items': {
            border: '2px solid red',
        },
    },
    menuContainer: {
        backgroundColor: 'white',
        boxShadow: '0px 0px 8px 1px gray',
        borderRadius: '0.25rem',
        minWidth: '150px',
        zIndex: '5',
        position: 'absolute',
        top: '105%',
        right: '0',
        '& > ul': {
            listStyleType: 'none',
            padding: '0',
            margin: '0',
            fontSize: '1.05rem',
            fontWeight: '400',
            lineHeight: '1.8em',
        },
        '& > ul .menu-item': {
            padding: '0.25rem 0.5rem',
            cursor: 'pointer',
            borderRadius: '0.25rem',
            textWrap: 'nowrap',
        },
        '& > ul .menu-item:hover': {
            backgroundColor: '#f0f0f0',
        },
        '& > ul .menu-item.disabled': {
            color: 'gray',
            pointerEvents: 'none',
        },
        '& > ul > li.submenu-container': {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        '& > ul > li.submenu-container > .submenu': {
            position: 'absolute',
            top: '85%',
            right: '0%',
            backgroundColor: 'white',
            zIndex: '1',
            padding: '0',
            margin: '0',
            listStyleType: 'none',
            width: 'max-content',
            boxShadow: '0px 0px 8px 1px gray',
            borderRadius: '0.25rem',
            [theme.breakpoints.up('sm')]: {
                right: '100%',
                top: '0',
            },
        },
        '& > ul > li.submenu-container > .submenu.hidden': {
            display: 'none',
        },
        '& > ul > li.submenu-container > .submenu.visible': {
            display: 'block',
        },
    },

    link: {
        fontWeight: 'bold',
        color: theme.palette.common.lightBlue,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    titleStatusContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.3rem',
    },
    linkTitle: {
        cursor: 'pointer',
        maxWidth: '21.875rem',
        '&:hover': {
            textDecoration: 'underline',
            color: '#1976d2',
        },
        '& .preview-text': {
            fontSize: '0.8rem',
            color: theme.palette.info.main,
        },
    },
    circularIcon: {
        animation: 'rotate 5s linear infinite',
        '@keyframes rotate': {
            '0%': {
                transform: 'rotate(-360deg)',
            },
            '100%': {
                transform: 'rotate(360deg)',
            },
        },
    },
    actionButtonContainer: {
        fontSize: '0.875rem',
        position: 'relative',
    },
    interestedReq: {
        backgroundColor: '#f7fee7',
    },
    white: {
        backgroundColor: '#fff',
    },
    // pinnedColumn: ({ dataGridScrollX }) => ({
    //     position: 'sticky !important',
    //     position: 'absolute !important',
    //     border: '1px solid red',
    //     backgroundColor: 'white',
    //     left: dataGridScrollX,
    //     zIndex: 2,
    // }),
    truncatedText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '0.875rem',
    },
    loadingBox: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        padding: theme.spacing(0.5),
        borderRadius: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: theme.shadows['1'],
    },
    titleCommentWrapper: {
        display: 'grid',
        gridTemplateColumns: '2fr 4rem',
        width: '100%',
        gap: theme.spacing(1),
    },
    titleTooltip: {
        justifySelf: 'flex-start',
        width: '100%',
    },
}));

/* THIS COMPONENT IS USED TO SHOW A CONFIRMATION DIALOG FOR DELETING CLIENT REQUIREMENT */
const DeleteConfirmation = (props) => {
    const {
        setShowDeleteConfirmationDialog,
        selectedRow,
        // setRows,
        setAlert,
        handleClose,
        showDeleteConfirmationDialog,
        fetchData,
    } = props;

    const handleDelete = async () => {
        try {
            if (selectedRow?.length === 0) return;

            setShowDeleteConfirmationDialog(false);
            handleClose();

            const res = await axios.post(
                '/api/client-requirements/delete-requirements',
                {
                    ids: selectedRow,
                }
            );

            if (res.status === 200) {
                // setRows((rows) =>
                //     rows.filter((row) => !res.data.ids.includes(row.id))
                // );
                await fetchData();

                setAlert({
                    show: true,
                    message: res.data.message,
                    severity: severity.SUCCESS,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleDialogClose = () => {
        setShowDeleteConfirmationDialog(false);
    };

    const handleCancel = () => {
        setShowDeleteConfirmationDialog(false);
    };

    return (
        <Box>
            <Dialog
                onClose={handleDialogClose}
                aria-labelledby="delete-confirmation-dialog"
                open={showDeleteConfirmationDialog}
            >
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the requirement?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDelete}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

/* INSTRUCTOR TITLE */
const InstructorTitle = ({
    cellValues,
    onTitleClick,
    /* handlePreview, */
    setOutlineContent,
    setOpenOutline,
}) => {
    const { classes } = useStyles();

    return (
        <Box className={classes.linkTitle}>
            <Tooltip title={cellValues.value} placement="top-start">
                <Typography
                    className={`${classes.title} ${classes.truncatedText}`}
                    onClick={() => onTitleClick(cellValues.row._id)}
                    style={{ color: '#2196f3' }}
                >
                    {cellValues.value}
                </Typography>
            </Tooltip>

            {/* {cellValues.row.sampleOutline?.length && (
                <Typography
                    className="preview-text"
                    onClick={() => {
                        setOutlineContent(
                            cellValues.row.sampleOutline[0]?.overview
                        );
                        setOpenOutline(true);
                    }}
                >
                    Preview
                </Typography>
            )} */}
        </Box>
    );
};

const ClientRequirements = ({
    isInstructor,
    getCounts = undefined,
    smeEmail,
    hideActions,
    selectedRole,
    passedClientId,
    activeTabValue = undefined,
    setDisableTabs = undefined,
    disableSummaryDrawer = false,
    isPipeline = false,
    showPipelines = false,
    setShowPipelines = () => console.info('setShowPipelines'),
    isMySite = false,
    smeId = undefined,
    _id = undefined,
    socket,
    isVisibleInModal = false,
    setLoadedRequirements = undefined,
}) => {
    const [roleView] = useRecoilState(roleViewState);
    // const [dataGridScrollX, setDataGridScrollX] = useState(0);
    const { classes } = useStyles(/* { dataGridScrollX } */);
    let navigate = useNavigate();
    const { client, isAdmin, starweaverAdmin, isCAD } = useIsAdmin();
    // const selectedRole = useRecoilValue(selectedRoleState);
    const visibleCols = useSelector((state) => state.visibleCols);
    const requirementSummary = useSelector((state) => state.requirementSummary);
    const expertSummary = useSelector((state) => state.expertSummary);
    const [ratingModal, setRatingModal] = useState({
        open: false,
        expertUserId: '',
    });
    const dispatch = useDispatch();
    const [previewData, setPreviewData] = useState({});
    const createOnAirRequirementPath = isPipeline
        ? '/profile/instructor-dashboard/pipelines/create/access-denied'
        : isInstructor
        ? '/profile/instructor-dashboard/network/clients/on-air'
        : '/admin/client-requirements/create/on-air';
    const setAlert = useSetRecoilState(alertState);
    const [addInterestBody, setAddInterestBody] = useState({
        userId: '',
        isInterested: false,
        requirementId: '',
    });
    const [showMenu, setShowMenu] = useState(false);
    const [showUpdateMenu, setShowUpdateMenu] = useState(false);
    const [showCalendarMenu, setShowCalendarMenu] = useState(false);
    const [showImportMenu, setShowImportMenu] = useState(false);
    const [showExportMenu, setShowExportMenu] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [downloadingOutlines, setDownloadingOutlines] = useState(false);
    const [showCommentDialog, setShowCommentDialog] = useState(false);
    const [bulkCommentPayload, setBulkCommentPayload] = useState({
        courseDataList: [],
        smes: [],
    });
    const [pipileadsData, setPipileadsData] = useState(null);
    const [showContactInfoDialog, setShowContactInfoDialog] = useState(false);
    const [openLinkShareModal, setOpenLinkShareModal] = useState(false);
    const hideImportMenu = () => {
        setShowImportMenu(false);
    };
    const [searchParams] = useSearchParams();
    const unHideImportMenu = () => {
        setShowImportMenu(true);
    };

    const hideExportMenu = () => {
        setShowExportMenu(false);
    };

    const unHideExportMenu = () => {
        setShowExportMenu(true);
    };
    const [showConfirm, setShowConfirm] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const { user: userDetails, isLoading: isUserLoading } = useCurrentUser();
    const [counts, setCounts] = useState({
        draftOrPending: 0,
        progress: 0,
        completed: 0,
        onlyDraft: 0,
    });
    const [rows, setRows] = useState([]);
    // console.log(rows);
    const [allRequirements, setAllRequirements] = useState([]);
    const [filters, setFilters] = useState({
        columnField: '',
        operatorValue: '',
        value: '',
    });
    const [filterModel, setFilterModel] = useState({
        model: [],
        logicalConnectivity: 'and',
    });
    const [selectedRow, setSelectedRow] = useState([]);
    /* const {
        // searchValue,
        // uid,
        // role,
        // dateRange,
        // month,
        // name,
        // year,
        // taskName,
        // c,
        // o,
        // v,
    } = useQueryParams(); */

    // const [onMouseEnterRowId, setOnMouseEnterRowId] = useState(null);

    // const [pageSize, setPageSize] = useState(10);

    const [experimantal, setExperimantal] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [marketMetricsTooltip, setMarketMetricsTooltip] = useState({
        show: false,
        id: null,
    });
    const [onConfirm, setOnConfirm] = useState(null);
    const [matchingInstructorModal, setMatchingInstructorModal] = useState({
        isOpen: false,
        tags: [],
        id: null,
        title: '',
        createdBy: '',
        createdAt: '',
    });
    const [applicantsInstructorModal, setApplicantsInstructorModal] = useState({
        isOpen: false,
        instructorIds: [],
        id: null,
    });
    const [smeModal, setSmeModal] = useState({
        isOpen: false,
        smes: [],
        id: null,
        title: null,
        status: null,
    });
    const [smeModal2, setSmeModal2] = useState({
        isOpen: false,
        smes: [],
        id: null,
        title: null,
        status: null,
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const onConfirmDialogClose = () => {
        setOnConfirm(null);
    };
    const menuRef = useRef(null);
    const [showCreateOptions, setShowCreateOptions] = useState(false);

    const [
        showDeleteConfirmationDialog,
        setShowDeleteConfirmationDialog,
    ] = useState(false);

    const [isInstructorsDialogOpened, SetIsInstructorsDialogOpened] = useState(
        false
    );
    const location = useLocation();
    const [instructorsToShow] = useState([]);
    const [openShareModal, setOpenShareModal] = useState(false);

    const [bulkUpdateModalState, setBulkUpdateModalState] = useState({
        isOpen: false,
        ids: [],
    });
    const [shareModalTitle, setShareModalTitle] = useState('');
    const [shareModalId, setShareModalId] = useState(null);

    const [outlineContent, setOutlineContent] = useState('');
    const [openOutline, setOpenOutline] = useState(false);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        // preview: isInstructor ? true : false,
        preview: false,
        share: false,
        // percentageCompleted: isInstructor ? false : true,
        percentageCompleted: isPipeline ? false : true,
        numOfMatchedInstructors: isInstructor ? false : true,
        interestedInstructors: isInstructor || showPipelines ? false : true,
        applicantNames: isInstructor || showPipelines ? false : true,
        identifiedSmes: isInstructor || showPipelines ? false : true,
        shortListedSmes: isInstructor || showPipelines ? false : true,
        smes: isInstructor ? false : true,
        // collaborators: isInstructor ? false : true,
        addedCollaborators: true,
        contractNumber: isInstructor ? false : true,
        workOrders: isInstructor ? false : true,
        shortListedSmes2: false,
        smes2: false,
        interest: isInstructor && !isPipeline ? true : false,
        instructorBudget: isInstructor && !isPipeline ? true : false,
        status: false,
        completedOn: false,
        client_name: isInstructor ? false : true,
        minBudget: isInstructor ? false : true,
        urgency: isInstructor ? false : true,
        tags: isInstructor ? false : true,
        ...roleList.reduce((prev, curr) => {
            prev[curr.field] = isInstructor ? false : true;
            return prev;
        }, {}),
        // marketMetrics: isInstructor ? false : true,
        marketMetrics: true,
        expectedEndDate: !isPipeline,
        topics: !isPipeline,
        ext_instructors: !isPipeline,
        ext_enrollments: !isPipeline,
        ext_rating: !isPipeline,
        outlineFormat: isPipeline,
    });

    const isLargeScreen = useMediaQuery('(min-width:900px)');
    const [pinnedColumns, setPinnedColumns] = useState({
        left: isLargeScreen
            ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'title']
            : [],
    });

    // const handlePinnedColumnsChange = useCallback((updatedPinnedColumns) => {
    //     setPinnedColumns(updatedPinnedColumns);
    // }, []);

    const [
        requirementToLinkWithThirdPartyCourse,
        setRequirementToLinkWithThirdPartyCourse,
    ] = useState(null);

    const handleThirdPartyCourseSelect = async (item) => {
        const requirementToLinkId = requirementToLinkWithThirdPartyCourse;

        setRequirementToLinkWithThirdPartyCourse(null);

        try {
            if (requirementToLinkId) {
                const res = await axios.put(`/api/client-requirements`, {
                    id: requirementToLinkId,
                    extCourseId: item.id,
                });
            }
            setRows((row) => {
                return row.map((r) => {
                    if (r._id === requirementToLinkId) {
                        return {
                            ...r,
                            extCourseId: item.id,
                        };
                    } else {
                        return { ...r };
                    }
                });
            });
        } catch (error) {
            console.log(error);
        }
    };

    // const {
    //     fetchCommentCountOfReqIds,
    //     requirementComments,
    // } = useFetchCommentCountOfReqIds();
    const {
        commentsReadStatus,
        loading: commentsUserMetaDataLoading,
        updateCommentLastSeen,
        commentsCount,
    } = useCommentsUserMetaData(allRequirements.map((req) => `om-${req._id}`));

    const onCloseInstructorsDialog = () => {
        SetIsInstructorsDialogOpened(false);
    };

    const showPageLoader = useMemo(() => {
        return (
            requirementSummary.isLoading ||
            (!expertSummary.showSummary && expertSummary.isLoading)
        );
    }, [
        expertSummary.isLoading,
        requirementSummary.isLoading,
        expertSummary.showSummary,
    ]);

    const statusDisplay = useMemo(() => {
        return {
            DRAFT: 'Draft',
            // had to change in the back-end other wise we can have pending on back-end means open on front-end
            OPEN: 'Open',
            ARCHIVE: 'Archive',
            ['IN PROGRESS']: 'In Progress',
            COMPLETED: 'Completed',
        };
    }, []);

    const showFilterBtn = useMemo(() => {
        if (isMySite) return false;
        return true;
    }, [isMySite]);

    const showCreateNewBtn = useMemo(() => {
        if (isMySite) return false;
        if (isPipeline) return true;
        if (isInstructor) return false;
        return true;
    }, [isInstructor, isMySite, isPipeline]);

    const showActionBtn = useMemo(() => {
        if (isMySite) return false;
        if (isPipeline) return true;
        if (isInstructor) return false;
        return true;
    }, [isInstructor, isPipeline, isMySite]);

    const showDeleteOption = useMemo(() => {
        if (isPipeline) return true;
        if (isInstructor) return false;
        return true;
    }, [isInstructor, isPipeline]);

    const showCloneOption = useMemo(() => {
        if (isPipeline) return true;
        if (isInstructor) return false;
        return true;
    }, [isInstructor, isPipeline]);

    const showCheckboxSelection = useMemo(() => {
        if (isPipeline) return true;
        if (isInstructor) return false;
        if (hideActions) return false;
        return true;
    }, [hideActions, isInstructor, isPipeline]);

    const urgencyDisplay = useMemo(() => {
        return {
            IMMEDIATE: 'Immediate',
            SOON: 'Soon',
            PLANNING: 'Planning',
        };
    }, []);

    // useEffect(() => {
    //     const onAltShiftE = (e) => {
    //         if ((e.key == 'e' || e.key == 'E') && e.shiftKey && e.altKey) {
    //             setExperimantal((prev) => !prev);
    //         }
    //     };
    //     window.addEventListener('keydown', onAltShiftE);
    //     return () => {
    //         window.removeEventListener('keydown', onAltShiftE);
    //     };
    // }, []);

    useEffect(() => {
        dispatch(expertSummaryResetStore());
    }, [dispatch]);

    useEffect(() => {
        setPage(0);
        setLimit(10);
    }, [location.pathname]);

    const fetchData = useCallback(
        async (showLoading = true) => {
            try {
                if (userDetails) {
                    if (showLoading) setIsLoading(true);
                    dispatch(hideRequirementSummary());
                    const baseURL = '/api/client-requirements';
                    const params = new URLSearchParams();
                    params.append('page', page);
                    params.append('limit', limit);
                    params.append('userId', userDetails._id);
                    if (filterModel.model.length > 0) {
                        params.append(
                            'filterModel',
                            JSON.stringify(filterModel.model)
                        );
                        params.append(
                            'logicalConnectivity',
                            filterModel.logicalConnectivity
                        );
                    }

                    if (
                        searchParams.get('filterModel') &&
                        searchParams.get('logicalConnectivity')
                    ) {
                        params.append(
                            'filterModel',
                            searchParams.get('filterModel')
                        );
                        params.append(
                            'logicalConnectivity',
                            searchParams.get('logicalConnectivity')
                        );
                    }

                    if (userDetails?.client_id || passedClientId) {
                        params.append(
                            'clientId',
                            userDetails?.client_id ?? passedClientId
                        );
                    }
                    if (isInstructor) {
                        params.append('isInstructor', true);
                    }
                    if (selectedRole) {
                        params.append('selectedRole', selectedRole);
                    }
                    if (smeEmail) {
                        params.append('smeEmail', smeEmail);
                    }
                    if (
                        !isVisibleInModal &&
                        location.pathname.includes('in-progress')
                    ) {
                        params.append('status', 'IN PROGRESS');
                    }
                    if (
                        !isVisibleInModal &&
                        location.pathname.includes('completed')
                    ) {
                        params.append('status', 'COMPLETED');
                    }
                    if (activeTabValue) {
                        params.append('status', activeTabValue);
                    }
                    if (
                        !isVisibleInModal &&
                        (starweaverAdmin || isCAD) &&
                        location.pathname.includes('draft')
                    ) {
                        params.append('status', 'DRAFT');
                    }
                    if (filters.value) {
                        const stringifiedFilters = JSON.stringify(filters);
                        params.append('filter', stringifiedFilters);
                    }
                    if (
                        !isVisibleInModal &&
                        searchParams.get('searchValue')?.trim()?.length > 0
                    ) {
                        params.append(
                            'searchValue',
                            searchParams.get('searchValue')
                        );
                    }
                    if (
                        searchParams.get('uid') &&
                        searchParams.get('role') &&
                        searchParams.get('dateRange')
                    ) {
                        params.append('uid', searchParams.get('uid'));
                        params.append('role', searchParams.get('role'));
                        params.append(
                            'dateRange',
                            searchParams.get('dateRange')
                        );
                    }

                    if (
                        !isVisibleInModal &&
                        searchParams.get('month') &&
                        searchParams.get('name') &&
                        searchParams.get('year') &&
                        searchParams.get('taskName')
                    ) {
                        params.append('month', searchParams.get('month'));
                        params.append('name', searchParams.get('name'));
                        params.append('year', searchParams.get('year'));
                        params.append('taskName', searchParams.get('taskName'));
                    }

                    if (
                        !isVisibleInModal &&
                        searchParams.get('startDate') &&
                        searchParams.get('endDate') &&
                        searchParams.get('taskName') &&
                        searchParams.get('name')
                    ) {
                        params.append(
                            'startDate',
                            searchParams.get('startDate')
                        );
                        params.append('endDate', searchParams.get('endDate'));
                        params.append('taskName', searchParams.get('taskName'));
                        params.append('name', searchParams.get('name'));
                    }

                    if (
                        !isVisibleInModal &&
                        searchParams.get('c') &&
                        searchParams.get('o') &&
                        searchParams.get('v')
                    ) {
                        const stringifiedFilters = JSON.stringify({
                            columnField: searchParams.get('c'),
                            operatorValue: searchParams.get('o'),
                            value: searchParams.get('v'),
                        });
                        params.append('filter', stringifiedFilters);
                    }

                    if (isPipeline || showPipelines) {
                        params.append('isPipeline', true);
                    }

                    if (showPipelines) {
                        params.append('ignoreUserPipelines', true);
                    }

                    if (isMySite) {
                        params.append('isMySite', true);
                    }

                    if (smeId) {
                        params.append('smeId', smeId);
                    }

                    if (_id) {
                        params.append('_id', _id);
                    }

                    const url = `${baseURL}?${params.toString()}`;
                    const res = await axios.get(url);
                    let rowData = res.data.clientRequirements;

                    // if (rowData?.length > 0) {
                    //     await fetchCommentCountOfReqIds(rowData);
                    // }

                    getCounts({
                        all: res.data.draftOrPendingCount,
                        inProgress: res.data.progressCount,
                        completed: res.data.completedCount,
                        draft: res.data?.onlyDraftCount,
                    });

                    setCounts({
                        draftOrPending: res.data.draftOrPendingCount,
                        progress: res.data.progressCount,
                        completed: res.data.completedCount,
                        onlyDraft: res.data?.onlyDraftCount || 0,
                    });

                    const totalCounter =
                        res.data.draftOrPendingCount +
                            res.data.progressCount +
                            res.data.completedCount +
                            res.data?.onlyDraftCount || 0;

                    if (
                        totalCounter === 0 &&
                        filters.value.length === 0 &&
                        searchParams.get('searchValue')?.length === 0
                    ) {
                        setShowCreateOptions(true);
                    } else {
                        setShowCreateOptions(false);
                    }

                    if (isInstructor) {
                        const interestedVal = (interests) => {
                            const foundObj = interests?.find(
                                (obj) => obj.userId === userDetails?._id
                            );
                            return foundObj ? foundObj.isInterested : '';
                        };
                        rowData = rowData.map((data) => {
                            return {
                                ...data,
                                isInterested: interestedVal(
                                    data?.interests ?? []
                                ),
                            };
                        });
                    }
                    setRows(rowData);
                    setAllRequirements(rowData);
                    if (isVisibleInModal && setLoadedRequirements) {
                        setLoadedRequirements(rowData);
                    }
                    setIsLoading(false);
                }
            } catch (error) {
                console.log(error);
            }
        },
        [
            userDetails,
            dispatch,
            page,
            limit,
            filterModel.model,
            filterModel.logicalConnectivity,
            passedClientId,
            isInstructor,
            selectedRole,
            smeEmail,
            isVisibleInModal,
            location.pathname,
            activeTabValue,
            starweaverAdmin,
            isCAD,
            filters,
            isPipeline,
            showPipelines,
            isMySite,
            smeId,
            _id,
            getCounts,
            setLoadedRequirements,
            searchParams,
        ]
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (!isInstructor) {
            if (showPipelines) {
                setColumnVisibilityModel((prev) => {
                    return {
                        ...prev,
                        interestedInstructors: false,
                        applicantNames: false,
                        identifiedSmes: false,
                        shortListedSmes: false,
                    };
                });
            } else if (
                location.pathname.includes('progress') ||
                location.pathname.includes('completed')
            ) {
                setColumnVisibilityModel((prev) => {
                    return {
                        ...prev,
                        numOfMatchedInstructors: false,
                        interestedInstructors: false,
                        applicantNames: false,
                        identifiedSmes: false,
                        shortListedSmes: false,
                        minBudget: false,
                        // instructionalDesigners: true,
                        // logisticsOperations: true,
                    };
                });
            } else {
                setColumnVisibilityModel((prev) => {
                    return {
                        ...prev,
                        numOfMatchedInstructors: true,
                        interestedInstructors: true,
                        applicantNames: true,
                        identifiedSmes: true,
                        shortListedSmes: true,
                        minBudget: true,
                        // instructionalDesigners: false,
                        // logisticsOperations: false,
                    };
                });
            }
        } else {
            if (location.pathname.includes('progress')) {
                setColumnVisibilityModel((prev) => {
                    return {
                        ...prev,
                        preview: false,
                    };
                });
            } else {
                setColumnVisibilityModel((prev) => {
                    return {
                        ...prev,
                        // preview: true,
                        preview: false,
                    };
                });
            }
        }
    }, [location.pathname, isInstructor, showPipelines]);

    useEffect(() => {
        if (setDisableTabs) {
            setDisableTabs(isLoading);
        }
    }, [isLoading, setDisableTabs]);

    const thirdPartyCourseData = useThirdPartyCourseData(rows);
    // console.log(thirdPartyCourseData);

    // const { loading, error, results, hasMore } = useThirdPartyCourseSearch(
    //     '',
    //     1,
    //     10
    // );
    // console.log(results);

    // Function to check for elements and attach the scroll event
    // function attachScrollEvent() {
    //     const clientRequirementsContainer = document.querySelector(
    //         '.clientRequirementsDataGridContainer'
    //     );
    //     const dataGridWindow = clientRequirementsContainer?.querySelector(
    //         '.MuiDataGrid-window'
    //     );

    //     if (clientRequirementsContainer && dataGridWindow) {
    //         dataGridWindow.addEventListener('scroll', function (event) {
    //             console.log('Horizontal scrolling detected!');
    //             console.log('Scroll X position:', event.target.scrollLeft);
    //             setDataGridScrollX(event.target.scrollLeft);
    //             // Your horizontal scroll event handling logic here
    //         });
    //     } else {
    //         // Elements not found, recheck in 100ms
    //         setTimeout(attachScrollEvent, 100);
    //     }
    // }

    // useEffect(() => {
    //     attachScrollEvent();
    // }, []);

    const showUpdateMenuForClient = useMemo(() => {
        if (!starweaverAdmin && !isInstructor) return false;
        return true;
    }, [isInstructor, starweaverAdmin]);

    useEffect(() => {
        if (!socket) return;

        rows.forEach((row) => {
            socket.on(
                `client-requirements:${row._id}:overview:generating`,
                () => {
                    setRows((prevRows) =>
                        prevRows.map((r) => {
                            if (r._id === row._id) {
                                return {
                                    ...r,
                                    isOutlinesGenerating: true,
                                };
                            }
                            return r;
                        })
                    );
                }
            );

            socket.on(
                `client-requirements:${row._id}:overview:generated`,
                (data) => {
                    setRows((prevRows) =>
                        prevRows.map((r) => {
                            if (r._id === row._id) {
                                return {
                                    ...r,
                                    isOutlinesGenerating: false,
                                    sampleOutline: [
                                        {
                                            ...r.sampleOutline[0],
                                            overview: data.overview,
                                        },
                                        ...(r.sampleOutline.slice(1) || []),
                                    ],
                                };
                            }
                            return r;
                        })
                    );
                }
            );

            socket.on(
                `client-requirements:${row._id}:overview:error`,
                (data) => {
                    setRows((prevRows) =>
                        prevRows.map((r) => {
                            if (r._id === row._id) {
                                return {
                                    ...r,
                                    isOutlinesGenerating: false,
                                };
                            }
                            return r;
                        })
                    );

                    setAlert({
                        show: true,
                        message: data.error || 'Error generating outline',
                        severity: severity.ERROR,
                    });
                }
            );
        });

        // Cleanup socket listeners on unmount
        return () => {
            rows.forEach((row) => {
                socket.off(
                    `client-requirements:${row._id}:overview:generating`
                );
                socket.off(`client-requirements:${row._id}:overview:generated`);
                socket.off(`client-requirements:${row._id}:overview:error`);
            });
        };
    }, [rows, setAlert]);

    /* const showProgressMenuOpt = useMemo(() => {
        const paths = [
            '/admin/opportunity-management/requirements',
            '/admin/opportunity-management',
            '/admin/opportunity-management/completed',
        ];
        if (paths.includes(location.pathname)) {
            return true;
        } else {
            return false;
        }
    }, [location.pathname]); */

    /* const showRequirementMenuOpt = useMemo(() => {
        const paths = [
            '/admin/opportunity-management/completed',
            '/admin/opportunity-management/in-progress',
        ];
        if (paths.includes(location.pathname)) {
            return true;
        } else {
            return false;
        }
    }, [location.pathname]); */

    /* const showCompletedMenuOpt = useMemo(() => {
        const paths = [
            '/admin/opportunity-management/in-progress',
            '/admin/opportunity-management/requirements',
            '/admin/opportunity-management',
        ];
        if (paths.includes(location.pathname)) {
            return true;
        } else {
            return false;
        }
    }, [location.pathname]); */

    /* const onFilterChange = React.useCallback((filterModel) => {
        if (!filterModel || !filterModel?.items.length) {
            return removeFilters();
        }

        const { columnField, operatorValue, value } = filterModel?.items[0];
        if (!value) {
            return removeFilters();
        }
        if (filterTimeoutId) {
            window.clearTimeout(filterTimeoutId);
        }
        filterTimeoutId = window.setTimeout(() => {
            setPage(0);
            setFilters({ columnField, operatorValue, value });
        }, [2000]);
    }, []); */

    /* const hideAttachmentCol = useMemo(() => {
        if (location.pathname.includes('in-progress') && isInstructor)
            return false;
        // if (isInstructor) return false;
        return true;
    }, [isInstructor, location.pathname]); */

    const hideColByPath = useCallback(
        (checkForInstructor) => {
            const pathName = location.pathname;
            if (checkForInstructor && isInstructor) return true;
            else if (
                pathName.includes('in-progress') ||
                pathName.includes('completed')
            )
                return true;
            else return false;
        },
        [isInstructor, location.pathname]
    );

    const hideShortlistedCol = useMemo(() => {
        if (!starweaverAdmin) return true;

        return false;
    }, [starweaverAdmin]);

    const hideApplicantsCol = useMemo(() => {
        if (!starweaverAdmin) return true;

        const pathName = location.pathname;

        if (pathName.includes('in-progress') || pathName.includes('completed'))
            return true;

        return false;
    }, [location.pathname, starweaverAdmin]);

    /* const assignedColPointerEvent = useMemo(() => {
        if (isInstructor) return 'none';

        if (client && Object.keys(client).length > 1) return 'none';

        if (location.pathname.includes('completed')) return 'none';

        return 'auto';
    }, [client, isInstructor, location.pathname]); */

    const hideUrgency = useMemo(() => {
        const pathName = location.pathname;
        return (
            pathName.includes('in-progress') || pathName.includes('completed')
        );
    }, [location.pathname]);

    const tadPreivewOnly = useMemo(() => {
        if (isInstructor) return true;

        if (starweaverAdmin || (client && Object.keys(client).length > 0)) {
            return true;
        }

        return false;
    }, [client, isInstructor, starweaverAdmin]);

    const rowCount = useMemo(() => {
        const pathName = location.pathname;
        if (
            pathName.includes('in-progress') ||
            activeTabValue === 'IN PROGRESS'
        ) {
            return counts.progress;
        } else if (
            pathName.includes('completed') ||
            activeTabValue === 'COMPLETED'
        ) {
            return counts.completed;
        } else if (pathName.includes('draft') || activeTabValue === 'DRAFT') {
            return counts.onlyDraft;
        } else {
            return counts.draftOrPending;
        }
    }, [
        counts.completed,
        counts.draftOrPending,
        counts.onlyDraft,
        counts.progress,
        location.pathname,
        activeTabValue,
    ]);

    const hideInterestColumn = useMemo(() => {
        if (!isInstructor || isPipeline) {
            setColumnVisibilityModel((prev) => {
                return {
                    ...prev,
                    interest: false,
                };
            });
            return true;
        } else if (
            location.pathname.includes('progress') ||
            location.pathname.includes('completed')
        ) {
            setColumnVisibilityModel((prev) => {
                return {
                    ...prev,
                    interest: false,
                };
            });
            return true;
        } else {
            setColumnVisibilityModel((prev) => {
                return {
                    ...prev,
                    interest: true,
                };
            });
            return false;
        }
    }, [isInstructor, isPipeline, location.pathname]);

    const hideAssignedCol = useMemo(() => {
        if (isInstructor) return true;
        // if (location.pathname.includes('in-progress')) return false;
        // if (location.pathname.includes('completed')) return false;
        return false;
    }, [isInstructor]);

    /**
     * @param {string} typeName
     * @returns string
     */
    const printFormattedType = useCallback((typeName) => {
        let words = typeName.split(/[-\s]/);

        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            let firstLetter = word.slice(0, 1).toUpperCase();
            let restOfLetters = word.slice(1, word.length).toLowerCase();

            words[i] = firstLetter + restOfLetters;
        }

        return words.join(' ');
    }, []);

    const echoContractNumber = useCallback((row) => {
        if (row?.contractNumber) {
            return { text: row.contractNumber, showTooltip: true };
        }

        return { text: 'N/A', showTooltip: false };
    }, []);

    /* const echoWorkOrder = useCallback((row) => {
        if (row?.workOrder) {
            return { text: row.workOrder, showTooltip: true };
        }

        return { text: 'N/A', showTooltip: false };
    }, []); */

    const echoWorkOrders = useCallback((row) => {
        if (row?.workOrders?.length > 0) {
            return {
                text: row.workOrders.map((order) => order.workOrder).join('; '),
                showTooltip: true,
            };
        }

        return { text: 'N/A', showTooltip: false };
    }, []);

    const echoSavedTags = useCallback((row) => {
        if (row.tags && row.tags.length > 0) {
            return row.tags.join(', ');
        }

        return 'N/A';
    }, []);

    const hideContractNumber = useMemo(() => {
        if (isInstructor) return true;

        return false;
    }, [isInstructor]);

    const hideWorkOrders = useMemo(() => {
        if (isInstructor) return true;

        return false;
    }, [isInstructor]);

    const echoTags = useCallback((row) => {
        // const { row } = params;
        const { customTopics = [], topics = [] } = row;

        if (customTopics?.length > 0 && topics?.length === 0) {
            return customTopics.join(', ');
        } else if (customTopics?.length === 0 && topics?.length > 0) {
            return topics.map(({ name }) => name).join(', ');
        } else if (customTopics?.length === 0 && topics?.length === 0) {
            return 'N/A';
        } else {
            return topics
                .map(({ name }) => name)
                .concat(customTopics)
                .join(', ');
        }
    }, []);

    const getTitleTooltip = useCallback(
        ({ row }) => {
            const {
                smes,
                title,
                clientId: clientData,
                contractNumber,
                workOrders,
            } = row;
            if (!location.pathname.includes('in-progress')) return title;
            if (!smes || smes?.length === 0) return title;
            const smeFullNames = smes.map((sme) => {
                return sme?.firstName + ' ' + (sme?.lastName || '');
            });
            const joinedSmeNames = smeFullNames.join(', ');
            let tooltip = title + ' | ' + joinedSmeNames;
            if (clientData?.client_name)
                tooltip += ' | ' + clientData?.client_name;
            if (contractNumber) tooltip += ' | ' + contractNumber;
            if (workOrders?.length > 0)
                tooltip +=
                    ' | ' + workOrders?.map((o) => o.workOrder).join('; ');
            return tooltip;
        },
        [location.pathname]
    );

    /* const hideCollaborators = useMemo(() => {
        if (isInstructor) return true;
        return false;
    }, [isInstructor]); */

    const getPah = useCallback(
        (type) => {
            if (type === 'ON-DEMAND') {
                return '/admin/client-requirements/create/on-demand';
            } else if (type === 'GUIDED-PROJECTS') {
                return '/admin/client-requirements/create/guided-projects';
            } else if (type === 'LONG-FORM') {
                return '/admin/client-requirements/create/long-form';
            } /* else if (type === 'LABS') {
                return '';
            } else if (type === 'ASSESSMENT') {
                return '';
            } */ else {
                return createOnAirRequirementPath;
            }
        },
        [createOnAirRequirementPath]
    );

    /**
     *
     * @param {number} x time in number
     * @param {string} time time string
     * @returns boolean
     */
    /* const hasXminutesPassed = (x, time) => {
        const providedTime = moment(time);
        const xMinAgo = moment().subtract(x, 'minutes');

        if (providedTime.isBefore(xMinAgo)) {
            return true;
        } else {
            return false;
        }
    }; */

    /* HANDLER INTEREST */
    const handleInterested = useCallback(
        async (e, requirementId) => {
            try {
                const isInterested = e.target.value;

                const userId = userDetails?._id;

                setAddInterestBody({ isInterested, requirementId, userId });

                if (isInterested === false) {
                    setShowConfirm(true);
                    return;
                }
                setIsLoading(true);
                const { data, status } = await axios.post(
                    '/api/client-requirements/interests',
                    {
                        userId,
                        isInterested,
                        requirementId,
                        usertime: new Date(Date.now()),
                    }
                );

                if (status === 200) {
                    setAlert({
                        show: true,
                        message: data.message,
                        severity: severity.SUCCESS,
                    });

                    await fetchData(false);
                    // setRows((row) => {
                    //     return row.map((r) => {
                    //         if (r._id === requirementId) {
                    //             return {
                    //                 ...r,
                    //                 isInterested,
                    //                 interestedIntstructorIds: isInterested
                    //                     ? [
                    //                           ...r?.interestedIntstructorIds,
                    //                           userDetails?._id,
                    //                       ]
                    //                     : r?.interestedIntstructorIds,
                    //             };
                    //         } else {
                    //             return { ...r };
                    //         }
                    //     });
                    // });
                }
            } catch (e) {
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        },
        [fetchData, setAlert, userDetails?._id]
    );

    // HANDLER FUNCTION ON CLICK OF COUNT OF # OF MATCH
    const handleMatchCountClick = (e, row) => {
        e.preventDefault();
        e.stopPropagation();
        // console.log(row);
        // console.log(!row?.topics?.length);
        if (!row?.topics?.length && !row?.customTopics?.length) {
            alert('Please add subjects covered in the requirement.');
            return;
        }
        // SetInstructorsToShow(row?.matchedInstructors || []);
        // SetIsInstructorsDialogOpened(true);
        setMatchingInstructorModal({
            isOpen: true,
            tags: [
                ...(row?.topics || []).map(({ name }) => name),
                ...(row?.customTopics || []),
            ],
            // matchedInstructors2: row.matchedInstructors2,
            id: row.id,
            numOfMatchedOnboardedInstructors:
                row?.numOfMatchedOnboardedInstructors,
            numOfMatchedInstructors: row?.numOfMatchedInstructors,
            createdAt: row?.createdAt,
            createdBy:
                row?.createdBy[0]?.user_firstName +
                    ' ' +
                    row?.createdBy[0]?.user_lastName ?? '',
            title: row?.title,
        });
    };

    // HANDLER FUNCTION ON CLICK OF COUNT OF # OF MATCH
    const handleApplicantsCountClick = (e, row) => {
        e.preventDefault();
        e.stopPropagation();
        // return;
        // console.log(row);
        // console.log(!row?.topics?.length);
        // console.log(row?.interestedIntstructorIds);
        if (!row?.interestedIntstructorIds?.length) return;
        // SetInstructorsToShow(row?.matchedInstructors || []);
        // SetIsInstructorsDialogOpened(true);
        setApplicantsInstructorModal({
            isOpen: true,
            instructorIds: row?.interestedIntstructorIds || [],
            id: row.id,
        });
    };

    const handleSMEClick = useCallback(
        (e, row, smeField) => {
            e.preventDefault();
            e.stopPropagation();

            if (!starweaverAdmin) return;

            setSmeModal({
                isOpen: true,
                smes: row?.[smeField] || [],
                smeField,
                nextStageSMEs: row?.[nextStageSMEField[smeField]],
                nextStageSMEField: nextStageSMEField[smeField],
                id: row.id,
                title: row.title,
                status: row.status,
            });
        },
        [starweaverAdmin]
    );

    const handleSMEClick2 = (e, row, column) => {
        e.preventDefault();
        e.stopPropagation();

        // if (!starweaverAdmin) return;

        setSmeModal2({
            isOpen: true,
            smes: row[column] || [],
            assignedSMEs: row?.smes2,
            id: row.id,
            title: row.title,
            column,
            status: row.status,
        });
    };

    const goToDetailsPage = useCallback(
        (id) => {
            if (isPipeline) {
                const pathname = `/profile/instructor-dashboard/pipelines/details/${id}/outline`;
                return window.open(pathname, '_blank');
            }

            if (isInstructor) {
                const pathname = `/profile/instructor-dashboard/opportunity-management/details/${id}`;
                return window.open(pathname, '_blank');
            }

            if (selectedRole) {
                const pathname = `/${selectedRole}/opportunity-management/details/${id}`;
                return window.open(pathname, '_blank');
            }

            const pathname = `/admin/opportunity-management/details/${id}`;
            return window.open(pathname, '_blank');
        },
        [isInstructor, selectedRole, isPipeline]
    );

    const goToMetricsPage = useCallback(
        (id) => {
            const pathname = isPipeline
                ? `/profile/instructor-dashboard/pipelines/details/${id}/market-metrics`
                : `/admin/opportunity-management/details/${id}/market-metrics`;
            return window.open(pathname, '_blank');
        },
        [isPipeline]
    );

    const onTitleClick = useCallback(
        (id) => {
            goToDetailsPage(id);
        },
        [goToDetailsPage]
    );

    const calenders = useMemo(() => {
        if (isInstructor) return [];
        else if (starweaverAdmin)
            return clientRequirementCalenders.map(
                ({ clientName, batchNum, link }) => ({
                    label: `${clientName} - Batch ${batchNum}`,
                    link,
                })
            );
        else if (userDetails?.client_id ?? passedClientId)
            return clientRequirementCalenders
                .filter(
                    ({ clientId }) =>
                        clientId === userDetails?.client_id ||
                        clientId === passedClientId
                )
                .map(({ batchNum, link }) => ({
                    label: `Batch ${batchNum}`,
                    link,
                }));
        else return [];
    }, [isInstructor, starweaverAdmin, userDetails?.client_id, passedClientId]);

    const isColHidden = useCallback(
        (field) => {
            if (visibleCols.includes(field)) {
                return false;
            }

            return true;
        },
        [visibleCols]
    );

    const getApplicantsNames = useCallback((applicants) => {
        let valueFormatter = 'N/A',
            cellValue = 'N/A';
        if (!applicants || applicants?.length === 0)
            return { valueFormatter: 'N/A', cellValue: 'N/A' };
        const joinedApplicantNames = applicants
            .map(
                (applicant) =>
                    applicant.user_firstName + ' ' + applicant?.user_lastName ??
                    ''
            )
            ?.join(', ');
        if (joinedApplicantNames?.length > 18) {
            valueFormatter = joinedApplicantNames;
            cellValue = joinedApplicantNames.slice(0, 18) + '...';
        } else {
            valueFormatter = cellValue = joinedApplicantNames;
        }
        return { valueFormatter, cellValue };
    }, []);

    /* const showSummaryDrawer = useMemo(() => {
        if (isInstructor) {
            if (
                location.pathname.includes('progress') ||
                location.pathname.includes('completed')
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }, [isInstructor, location.pathname]); */

    const getCreatedByCol = useCallback(
        (headerName) => {
            return {
                field: 'createdBy',
                headerName,
                filterable: false,
                valueFormatter: (value) => {
                    if (value?.length > 0) {
                        return (
                            value[0].user_firstName +
                            ' ' +
                            value[0].user_lastName
                        );
                    }

                    return 'N/A';
                },
                renderCell: ({ row }) => {
                    const { createdBy = [] } = row;
                    let names = createdBy
                        .map(
                            ({ user_firstName, user_lastName = '' }) =>
                                `${user_firstName} ${user_lastName}`
                        )
                        .join(', ');
                    if (!names) {
                        names = 'N/A';
                    }
                    return (
                        <Box
                            width="100%"
                            height="100%"
                            className={classes.truncatedText}
                            display="flex"
                            alignItems={'center'}
                        >
                            <Tooltip title={names} placement="top-start">
                                {names}
                            </Tooltip>
                        </Box>
                    );
                },
                width: 180,
                hide: isColHidden('createdBy'),
            };
        },
        [isColHidden]
    );

    const showPipelineSwitch = useMemo(() => {
        if (isMySite) return false;
        if (roleView.selectedView === 'admin') return true;
        return false;
    }, [isMySite, roleView.selectedView]);

    const columns = [
        //{ field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'interest',
            headerName: 'Interest',
            filterable: false,
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <TextField
                            id="standard-select-currency"
                            select
                            label="Select"
                            value={row.isInterested}
                            onChange={(e) => {
                                handleInterested(e, row._id);
                            }}
                            style={{ minWidth: 100 }}
                            variant="outlined"
                            name="interest"
                            size="small"
                        >
                            <MenuItem value={true}>Interested</MenuItem>
                            <MenuItem value={false}>Not Interested</MenuItem>
                        </TextField>
                    </Box>
                );
            },
            disableColumnMenu: true,
            sortable: false,
            width: 170,
            hide: hideInterestColumn ? true : isColHidden('interest'),
        },
        {
            field: 'title',
            headerName: 'Title',
            width: 450,
            hide: isColHidden('title'),
            valueFormatter: (value) => {
                return value ? value : 'N/A';
            },
            display: 'flex',
            renderCell: (cellValues) => {
                const { row /* , field */ } = cellValues;
                const { sampleOutline, status, createdAt } = row;
                const count = commentsCount[`om-${row._id}`];
                const isRead = commentsReadStatus[`om-${row._id}`];

                return (
                    <Box className={classes.titleContainer}>
                        <Box className={classes.titleCommentWrapper}>
                            {/* {!isInstructor ? ( */}
                            <Tooltip
                                placement="top-start"
                                title={getTitleTooltip(cellValues)}
                                className={`${classes.titleTooltip} ${classes.truncatedText}`}
                            >
                                <Typography
                                    onClick={() => onTitleClick(row._id)}
                                    className={
                                        status !== 'DRAFT'
                                            ? classes.titleLink
                                            : ''
                                    }
                                >
                                    {cellValues.value}
                                </Typography>
                            </Tooltip>
                            {/* ) : ( */}
                            {/* <InstructorTitle
                                    cellValues={cellValues}
                                    onTitleClick={onTitleClick}
                                    // handlePreview={handlePreview}
                                    setOutlineContent={setOutlineContent}
                                    setOpenOutline={setOpenOutline}
                                /> */}
                            {/* )} */}
                            {/* {showSummaryDrawer && ( */}
                            {!disableSummaryDrawer && (
                                <OMCommentBtn
                                    commentCount={count}
                                    row={row}
                                    isRead={isRead}
                                    commentsUserMetaDataLoading={
                                        commentsUserMetaDataLoading
                                    }
                                    updateCommentLastSeen={
                                        updateCommentLastSeen
                                    }
                                />
                            )}
                            {/* )} */}
                        </Box>
                        <Box
                            display="flex"
                            alignItems={'center'}
                            style={{ gap: '0.5rem' }}
                        >
                            {tadPreivewOnly &&
                                row?.status !== 'DRAFT' &&
                                row.sampleOutline?.length != 0 && (
                                    <Box
                                        className={classes.titleStatusContainer}
                                    >
                                        <Typography
                                            variant="caption"
                                            className={classes.edit}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                // handlePreview(row);
                                                setOutlineContent(
                                                    row.sampleOutline[0]
                                                        ?.overview
                                                );
                                                setOpenOutline(true);
                                            }}
                                        >
                                            Preview
                                        </Typography>
                                    </Box>
                                )}
                            <Box className={classes.titleStatusContainer}>
                                {!isInstructor && row?.status === 'DRAFT' && (
                                    <Typography
                                        variant="caption"
                                        className={classes.edit}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            navigate(
                                                // `${createOnAirRequirementPath}?id=${cellValues.row.id}`
                                                `${getPah(
                                                    cellValues.row.type
                                                )}?id=${cellValues.row.id}`
                                            );
                                        }}
                                    >
                                        Edit ({row.status})
                                    </Typography>
                                )}
                                {/* {!hasXminutesPassed(20, createdAt) &&
                                            status === 'OPEN' &&
                                            sampleOutline?.length === 0 && ( */}
                                {row?.isOutlinesGenerating && (
                                    <Tooltip title="Generating outlines">
                                        <img
                                            src={CircularArrowProgressIcon}
                                            height="13px"
                                            width="13px"
                                            alt="circlar loading"
                                            className={classes.circularIcon}
                                        />
                                    </Tooltip>
                                )}

                                {/* {sampleOutline?.length > 0 && ( */}
                                {!row?.isOutlinesGenerating &&
                                    sampleOutline?.length > 0 && (
                                        <Tooltip title="Outline generated">
                                            <img
                                                src={CircularCheckIcon}
                                                height="13px"
                                                width="13px"
                                                alt="check icon"
                                            />
                                        </Tooltip>
                                    )}
                            </Box>
                        </Box>
                    </Box>
                );
            },
            headerClassName: classes.pinnedColumn,
            cellClassName: classes.pinnedColumn,
        },
        {
            field: 'preview',
            headerName: 'Preview',
            description: 'Preview Samples',
            width: 100,
            filterable: false,
            sortable: false,
            hide: starweaverAdmin || isInstructor,
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ textAlign: 'center' }}>
                        {row.sampleOutline?.length ? (
                            <Tooltip placement="top-start" title="View Samples">
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setOutlineContent(
                                            row.sampleOutline[0]?.overview
                                        );
                                        setOpenOutline(true);
                                    }}
                                >
                                    <Visibility />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <span>N/A</span>
                        )}
                    </Box>
                );
            },
        },
        {
            field: 'percentageCompleted',
            headerName: '% Completed',
            // headerAlign: 'center',
            width: 220,
            filterable: false,
            hide: isColHidden('percentageCompleted'),
            valueFormatter: (value) => {
                return Math.floor(value);
            },
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <PercentageCompleteProgress
                        row={row}
                        loading={isLoading}
                        disableDrawer={disableSummaryDrawer}
                    />
                );
            },
        },
        ...(showPipelines ? [getCreatedByCol('Instructor')] : []),
        {
            field: 'client_name',
            headerName: 'Client',
            width: 120,
            valueFormatter: (value, row) => {
                return row?.clientId?.client_name
                    ? row.clientId.client_name
                    : 'Starweaver';
            },
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <Box>
                        {row?.clientId?.client_name
                            ? row.clientId.client_name
                            : 'Starweaver'}
                    </Box>
                );
            },
            sortable: false,
            // hide:
            //     !starweaverAdmin &&
            //     (isInstructor || Object.keys(client).length > 0),
            hide: !starweaverAdmin ? true : isColHidden('client_name'),
            filterable: !isPipeline,
        },
        ...(activeTabValue === 'COMPLETED' ||
        location.pathname.includes('completed')
            ? [
                  {
                      field: 'ext_instructors',
                      headerName: 'Instructors',
                      width: 200,
                      // hide: hideExtColByPath
                      //     ? true
                      //     : isColHidden('interestedInstructors'),
                      hide: true,

                      display: 'flex',
                      renderCell: ({ row }) => {
                          const instructors =
                              thirdPartyCourseData[row.title]?.instructors ||
                              thirdPartyCourseData[row.extCourseId]
                                  ?.instructors ||
                              '';

                          if (
                              !instructors &&
                              row.clientId?.client_name === 'Coursera'
                          )
                              return (
                                  <Box
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                          setRequirementToLinkWithThirdPartyCourse(
                                              row._id
                                          );
                                      }}
                                  >
                                      ?
                                  </Box>
                              );

                          return (
                              <Box
                                  style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                  }}
                              >
                                  <Box>{instructors}</Box>
                                  {row.clientId?.client_name === 'Coursera' && (
                                      <IconButton
                                          size="small"
                                          style={{ marginLeft: '8px' }}
                                          onClick={() => {
                                              setRequirementToLinkWithThirdPartyCourse(
                                                  row._id
                                              );
                                          }}
                                      >
                                          {instructors ? (
                                              <EditIcon />
                                          ) : (
                                              <Box></Box>
                                          )}
                                      </IconButton>
                                  )}
                              </Box>
                          );
                      },
                  },
                  {
                      field: 'ext_enrollments',
                      headerName: 'Enrollments',
                      width: 150,
                      // hide: hideExtColByPath
                      //     ? true
                      //     : isColHidden('interestedInstructors'),

                      display: 'flex',
                      renderCell: ({ row }) => {
                          const enrollments =
                              thirdPartyCourseData[row.title]?.enrollments ||
                              thirdPartyCourseData[row.extCourseId]
                                  ?.enrollments ||
                              '';

                          return <Box>{enrollments}</Box>;
                      },
                  },
                  {
                      field: 'ext_rating',
                      headerName: 'Rating',
                      width: 150,
                      display: 'flex',
                      filterable: false,
                      sortable: false,
                      renderCell: ({ row }) => {
                          const rating =
                              thirdPartyCourseData[row.title]?.rating ||
                              thirdPartyCourseData[row.extCourseId]?.rating ||
                              0;
                          const displayRating = rating
                              ? Number(rating).toFixed(2)
                              : 0;
                          return (
                              <Tooltip title={displayRating}>
                                  <Box>
                                      {rating ? (
                                          <Rating
                                              value={displayRating}
                                              readOnly
                                              precision={0.25}
                                          />
                                      ) : (
                                          <></>
                                      )}
                                  </Box>
                              </Tooltip>
                          );
                      },
                  },
              ]
            : []),
        {
            field: 'instructorShare',
            headerName: 'Share',
            width: 140,
            filterable: false,
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <IconButton
                        onClick={async () => {
                            const url = getBaseURL(
                                `/profile/instructor-dashboard/opportunity-management/details/${row.id}`
                            );

                            try {
                                if ('clipboard' in navigator) {
                                    await navigator.clipboard.writeText(url);
                                } else {
                                    document.execCommand('copy', true, url);
                                }
                                setAlert({
                                    show: true,
                                    message: 'URL Copied successfully',
                                    severity: severity.SUCCESS,
                                });
                            } catch (e) {
                                console.log(e);
                            }
                        }}
                        size="large"
                    >
                        <ShareIcon />
                    </IconButton>
                );
            },
            hide: starweaverAdmin && !isInstructor,
            sortable: false,
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 120,
            hide: isColHidden('type'),
            valueFormatter: (value) => {
                return value
                    ? value === 'ON-AIR'
                        ? 'Live Stream'
                        : printFormattedType(value)
                    : 'N/A';
            },
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <Box>
                        {row?.type
                            ? row.type === 'ON-AIR'
                                ? 'Live Stream'
                                : printFormattedType(row.type)
                            : 'N/A'}
                    </Box>
                );
            },
            sortable: false,
        },
        {
            field: 'duration',
            headerName: 'Duration',
            width: 150,
            filterable: true,
            sortable: false,
            hide: isColHidden('duration'),
            valueFormatter: (value, row) => {
                return `${row?.hours} hr(s) - ${row?.minutes} min(s)`;
            },
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <Typography variant="body2">{`${row?.hours} hr(s) - ${row?.minutes} min(s)`}</Typography>
                );
            },
        },
        {
            field: 'expectedEndDate',
            headerName: 'Delivery Date',
            width: 130,
            hide: isColHidden('expectedEndDate'),
            filterable: !isPipeline,
            valueFormatter: (value) => {
                return value ? moment(value).format('DD-MMM-YYYY') : 'N/A';
            },
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <Box>
                        {row?.expectedEndDate
                            ? moment(row.expectedEndDate).format('DD-MMM-YYYY')
                            : 'N/A'}
                    </Box>
                );
            },
        },
        {
            field: 'outlineFormat',
            headerName: 'Format',
            width: 120,
            hide: isColHidden('outlineFormat'),
            filterable: false,
            sortable: false,
            display: 'flex',
            valueFormatter: (value) => {
                return value?.replace(/-/g, ' ')?.toUpperCase() || 'N/A';
            },
        },
        {
            field: 'marketMetrics',
            headerName: 'Market Metrics',
            width: 130,
            hide: isColHidden('marketMetrics'),
            filterable: false,
            sortable: false,
            display: 'flex',
            renderCell: ({ row }) => {
                const openMarketMetrics = () => {
                    goToMetricsPage(row._id);
                };
                return (
                    <div
                        onMouseEnter={() =>
                            setMarketMetricsTooltip({ show: true, id: row._id })
                        }
                        onMouseLeave={() =>
                            setMarketMetricsTooltip({ show: false, id: null })
                        }
                    >
                        <HtmlTooltip
                            placement="top"
                            title={
                                <React.Fragment>
                                    {marketMetricsTooltip.show &&
                                    marketMetricsTooltip.id === row._id ? (
                                        <MarketMetricTooltipInfo
                                            title={row.title}
                                        />
                                    ) : (
                                        <Box className={classes.loadingBox}>
                                            <CircularProgress
                                                color="secondary"
                                                size={20}
                                            />
                                        </Box>
                                    )}
                                </React.Fragment>
                            }
                        >
                            <IconButton onClick={openMarketMetrics}>
                                <Timeline />
                            </IconButton>
                        </HtmlTooltip>
                    </div>
                );
            },
        },
        {
            field: 'share',
            headerName: 'Share',
            width: 140,
            filterable: false,
            display: 'flex',
            renderCell: ({ row }) => {
                /* const mailToLink = `mailto:someone@example.com?subject=Opportunity Management&body=Hi,%0D%0AI have created a content requirement and will appreciate your feedback. Please follow the link below and instructions on the site to sign-up or login.%0D%0AURL: ${
                        window.location.origin
                    }/admin/client-requirements/create/on-air?id=${
                        row.id
                    }%0D%0ATitle: ${row.title}%0D%0AType: ${
                        row.type
                    }%0D%0ADelivery: ${moment(row?.expectedStartDate).format(
                        'MMM Do YYYY'
                    )}%0D%0AFor more information, visit: https://support.starweaver.com/portal/en/kb/articles/opportunity-management`; */

                const tooltip = row?.managers?.length
                    ? row?.managers
                          .map(({ email, firstName, lastName }) =>
                              firstName
                                  ? `${firstName}${
                                        lastName ? ` ${lastName}` : ''
                                    }`
                                  : email
                          )
                          .join(', ')
                    : '';

                return (
                    // <a href={mailToLink}>
                    // </a>
                    <Tooltip
                        title={tooltip ? 'Shared with: ' + tooltip : ''}
                        placement="bottom"
                    >
                        <IconButton
                            onClick={() => {
                                setShareModalTitle(row.title);
                                setShareModalId(row.id);
                                setOpenShareModal(true);
                            }}
                            size="large"
                        >
                            <ShareIcon />
                        </IconButton>
                    </Tooltip>
                );
            },
            hide: true,
            sortable: false,
        },
        {
            field: 'numOfMatchedInstructors',
            headerName: '# Matches',
            width: 100,
            sortable: true,
            description: 'Total number of interested instructors',
            hide: hideColByPath(true)
                ? true
                : isColHidden('numOfMatchedInstructors'),
            filterable: false,
            valueFormatter: (value) => {
                return value || 'N/A';
            },
            display: 'flex',
            renderCell: (param) => {
                const { row } = param;
                return (
                    <Box
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            // if (!row?.matchedInstructors?.length) return;
                            // SetInstructorsToShow(row?.matchedInstructors || []);
                            // SetIsInstructorsDialogOpened(true);
                            if (starweaverAdmin) handleMatchCountClick(e, row);
                        }}
                    >
                        {isCAD ? (
                            <Tooltip
                                placement="top"
                                title="Access restricted. To gain access, please contact your Program Manager"
                            >
                                <Typography
                                    style={{
                                        width: '100%',
                                        paddingLeft: '2rem',
                                    }}
                                >
                                    {row?.numOfMatchedInstructors || '?'}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Box
                                style={{
                                    width: '100%',
                                    // paddingLeft: '2rem',
                                    cursor: 'pointer',
                                }}
                                display="flex"
                                alignItems="center"
                            >
                                {row?.numOfMatchedOnboardedInstructors ||
                                row?.numOfMatchedInstructors ? (
                                    <>
                                        {row?.numOfMatchedOnboardedInstructors ? (
                                            <>
                                                <Typography marginRight="3px">
                                                    {
                                                        row?.numOfMatchedOnboardedInstructors
                                                    }
                                                </Typography>
                                                <img
                                                    src={starweaverLogo}
                                                    alt="Starweaver"
                                                />
                                            </>
                                        ) : null}

                                        {row?.numOfMatchedOnboardedInstructors &&
                                        row?.numOfMatchedInstructors ? (
                                            <>
                                                <Box marginLeft={1}></Box>{' '}
                                            </>
                                        ) : (
                                            <></>
                                        )}

                                        {row?.numOfMatchedInstructors ? (
                                            <>
                                                <Typography marginRight="3px">
                                                    {
                                                        row?.numOfMatchedInstructors
                                                    }
                                                </Typography>
                                                <img
                                                    src={
                                                        starweaverAnalyticsLogo
                                                    }
                                                    alt="Starweaver"
                                                    height="16px"
                                                />
                                            </>
                                        ) : null}
                                    </>
                                ) : (
                                    <Typography marginLeft={1} marginRight={1}>
                                        ?
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </Box>
                );
            },
        },
        // {
        //     field: 'matchedInstructors',
        //     headerName: '# of Matches',
        //     width: 180,
        //     sortable: true,
        //     description: 'Total number of interested instructors',
        //     hide: isInstructor,
        //     display : "flex", renderCell: (param) => {
        //         const { row } = param;
        //         return (
        //             <Box
        //                 onClick={(e) => {
        //                     e.preventDefault();
        //                     e.stopPropagation();
        //                     if (!row?.matchedInstructors?.length) return;
        //                     SetInstructorsToShow(row?.matchedInstructors || []);
        //                     SetIsInstructorsDialogOpened(true);
        //                 }}
        //                 // onClick={(e) => {
        //                 //     handleMatchCountClick(e, row);
        //                 // }}
        //             >
        //                 <Typography
        //                     style={{ width: '100%', paddingLeft: '2rem' }}
        //                 >
        //                     {row?.matchedInstructors?.length || 0}
        //                 </Typography>
        //             </Box>
        //         );
        //     },
        // },
        {
            field: 'interestedInstructors',
            headerName: '# Applicants',
            width: 120,
            sortable: true,
            description: 'Total number of interested instructors',
            hide: hideApplicantsCol
                ? true
                : isColHidden('interestedInstructors'),
            filterable: !isPipeline,
            valueFormatter: (value) => {
                return value?.length || 0;
            },
            display: 'flex',
            renderCell: (param) => {
                const { row } = param;
                return (
                    <Box
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            // if (!row?.matchedInstructors?.length) return;
                            // SetInstructorsToShow(row?.matchedInstructors || []);
                            // SetIsInstructorsDialogOpened(true);
                            handleApplicantsCountClick(e, row);
                        }}
                    >
                        <Typography
                            style={{
                                width: '100%',
                                paddingLeft: '2rem',
                                cursor: 'pointer',
                            }}
                        >
                            {row?.interestedIntstructorIds?.length || 0}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'applicantNames',
            headerName: 'Applicants Name',
            width: 200,
            sortable: true,
            description: 'Name of interested smes',
            hide: hideApplicantsCol ? true : isColHidden('applicantNames'),
            filterable: !isPipeline,
            valueFormatter: (value) => {
                return getApplicantsNames(value).valueFormatter;
            },
            display: 'flex',
            renderCell: (param) => {
                const { row } = param;
                return (
                    <Tooltip
                        placement="top-start"
                        title={
                            getApplicantsNames(row?.applicantNames)
                                .valueFormatter
                        }
                    >
                        <Typography>
                            {getApplicantsNames(row?.applicantNames).cellValue}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: 'identifiedSmes',
            headerName: fieldToHeader['identifiedSmes'],
            width: 200,
            sortable: false,
            hide: hideShortlistedCol ? true : isColHidden('identifiedSmes'),
            filterable: false,
            valueFormatter: (value) => {
                return value?.length > 0
                    ? value
                          .map((sme) => {
                              return (
                                  sme.firstName +
                                  (sme?.lastName ? ' ' + sme.lastName : '')
                              );
                          })
                          .join(', ')
                    : 'N/A';
            },
            display: 'flex',
            renderCell: (param) => {
                // console.log(param.value);
                return (
                    <ClientRequirementsSMECell
                        param={param}
                        handleSMEClick={handleSMEClick}
                        classes={classes}
                        starweaverAdmin={starweaverAdmin}
                        column="identifiedSmes"
                    />
                );
            },
        },
        {
            field: 'shortListedSmes',
            headerName: fieldToHeader['shortListedSmes'],
            width: 200,
            sortable: false,
            hide: hideShortlistedCol ? true : isColHidden('shortListedSmes'),
            filterable: false,
            valueFormatter: (value) => {
                return value?.length > 0
                    ? value
                          .map((sme) => {
                              return (
                                  sme.firstName +
                                  (sme?.lastName ? ' ' + sme.lastName : '')
                              );
                          })
                          .join(', ')
                    : 'N/A';
            },
            display: 'flex',
            renderCell: (param) => {
                // console.log(param.value);
                return (
                    <ClientRequirementsSMECell
                        param={param}
                        handleSMEClick={handleSMEClick}
                        classes={classes}
                        starweaverAdmin={starweaverAdmin}
                        column="shortListedSmes"
                    />
                );
            },
        },
        {
            field: 'smes',
            headerName: fieldToHeader['smes'],
            width: 200,
            sortable: false,
            hide: hideAssignedCol ? true : isColHidden('smes'),
            filterable: !hideAssignedCol,
            valueFormatter: (value) => {
                return value?.length > 0
                    ? value
                          .map((sme) => {
                              return (
                                  sme.firstName +
                                  (sme?.lastName ? ' ' + sme.lastName : '')
                              );
                          })
                          .join(', ')
                    : 'N/A';
            },
            display: 'flex',
            renderCell: (param) => {
                // console.log(param.value);
                return (
                    <ClientRequirementsSMECell
                        param={param}
                        handleSMEClick={handleSMEClick}
                        classes={classes}
                        starweaverAdmin={starweaverAdmin}
                        column="smes"
                    />
                );
            },
        },
        ...roleList.map((role) => {
            return {
                field: role.field,
                headerName: fieldToHeader[role.field],
                width: 200,
                sortable: false,
                hide: false,
                filterable: !hideAssignedCol,
                valueFormatter: (value) => {
                    return value?.length > 0
                        ? value
                              .map((sme) => {
                                  return (
                                      sme.firstName +
                                      (sme?.lastName ? ' ' + sme.lastName : '')
                                  );
                              })
                              .join(', ')
                        : 'N/A';
                },
                display: 'flex',
                renderCell: (param) => {
                    // console.log(param.value);
                    return (
                        <ClientRequirementsSMECell
                            param={param}
                            handleSMEClick={handleSMEClick2}
                            classes={classes}
                            starweaverAdmin={starweaverAdmin}
                            column={`${role.field}`}
                        />
                    );
                },
            };
        }),
        // {
        //     field: 'shortlistedInstructors',
        //     headerName: '# Shortlisted',
        //     width: 180,
        //     sortable: true,
        //     description: 'Total number of interested instructors',
        //     hide: hideColByPath(true),
        //     filterable: false,
        //     display : "flex", renderCell: (param) => {
        //         return (
        //             <Typography style={{ width: '100%', paddingLeft: '2rem' }}>
        //                 0
        //             </Typography>
        //         );
        //     },
        // },

        /* {
            field: 'collaborators',
            headerName: fieldToHeader['collaborators'],
            width: 200,
            sortable: false,
            hide: hideCollaborators ? true : isColHidden('collaborators'),
            valueFormatter: (value) => {
                return value?.length > 0
                    ? value
                          .map((sme) => {
                              return (
                                  sme.firstName +
                                  (sme?.lastName ? ' ' + sme.lastName : '')
                              );
                          })
                          .join(', ')
                    : 'N/A';
            },
            display : "flex", renderCell: (param) => {
                // console.log(param.value);
                return (
                    <ClientRequirementsSMECell
                        param={param}
                        handleSMEClick={handleSMEClick2}
                        classes={classes}
                        starweaverAdmin={starweaverAdmin}
                        column="collaborators"
                    />
                );
            },
        }, */
        // {
        //     field: 'addedCollaborators',
        //     headerName: fieldToHeader['addedCollaborators'],
        //     width: 200,
        //     sortable: false,
        //     // hide: hideCollaborators ? true : isColHidden('addedCollaborators'),
        //     hide: true,
        //     valueFormatter: (value) => {
        //         return value?.length > 0
        //             ? value
        //                   .map((sme) => {
        //                       return (
        //                           sme.firstName +
        //                           (sme?.lastName ? ' ' + sme.lastName : '')
        //                       );
        //                   })
        //                   .join(', ')
        //             : 'N/A';
        //     },
        //     display : "flex", renderCell: (param) => {
        //         // console.log(param.value);
        //         return (
        //             <ClientRequirementsSMECell
        //                 param={param}
        //                 handleSMEClick={handleSMEClick2}
        //                 classes={classes}
        //                 starweaverAdmin={starweaverAdmin}
        //                 column="addedCollaborators"
        //             />
        //         );
        //     },
        // },
        // {
        //     field: 'shortListedSmes',
        //     headerName: 'Shortlisted',
        //     width: 250,
        //     sortable: false,
        //     hide: hideShortlistedCol ? true : isColHidden('shortListedSmes'),
        //     filterable: false,
        //     valueFormatter: ({ row }) => {
        //         return row?.shortListedSmes?.length > 0
        //             ? row.shortListedSmes
        //                   .map((sme) => {
        //                       return (
        //                           sme.firstName +
        //                           (sme?.lastName ? ' ' + sme.lastName : '')
        //                       );
        //                   })
        //                   .join(', ')
        //             : 'N/A';
        //     },
        //     display : "flex", renderCell: (param) => {
        //         // console.log(param.value);
        //         return (
        //             <ClientRequirementsSMECell
        //                 param={param}
        //                 handleSMEClick={handleSMEClick}
        //                 classes={classes}
        //                 starweaverAdmin={starweaverAdmin}
        //                 showShortListedSmes={true}
        //             />
        //         );
        //     },
        // },
        // {
        //     field: 'smes',
        //     headerName: 'Assigned',
        //     width: 250,
        //     sortable: false,
        //     hide: hideAssignedCol ? true : isColHidden('smes'),
        //     filterable: !hideAssignedCol,
        //     valueFormatter: ({ row }) => {
        //         return row?.smes?.length > 0
        //             ? row.smes
        //                   .map((sme) => {
        //                       return (
        //                           sme.firstName +
        //                           (sme?.lastName ? ' ' + sme.lastName : '')
        //                       );
        //                   })
        //                   .join(', ')
        //             : 'N/A';
        //     },
        //     display : "flex", renderCell: (param) => {
        //         // console.log(param.value);
        //         return (
        //             <ClientRequirementsSMECell
        //                 param={param}
        //                 handleSMEClick={handleSMEClick}
        //                 classes={classes}
        //                 starweaverAdmin={starweaverAdmin}
        //                 showShortListedSmes={false}
        //             />
        //         );
        //     },
        // },
        /* {
            field: 'shortListedSmes2',
            headerName: fieldToHeader['shortListedSmes2'],
            width: 200,
            sortable: false,
            hide:
                hideShortlistedCol || !experimantal
                    ? true
                    : isColHidden('shortListedSmes2'),
            filterable: false, // !hideShortlistedCol,
            display: 'flex',
            renderCell: (param) => {
                // console.log(param.value);
                return (
                    <ClientRequirementsSMECell
                        param={param}
                        handleSMEClick={handleSMEClick2}
                        classes={classes}
                        starweaverAdmin={starweaverAdmin}
                        column="shortListedSmes2"
                    />
                );
            },
        },
        {
            field: 'smes2',
            headerName: fieldToHeader['smes2'],
            width: 200,
            sortable: false,
            hide:
                hideAssignedCol || !experimantal ? true : isColHidden('smes2'),
            filterable: false,
            display: 'flex',
            renderCell: (param) => {
                // console.log(param.value);
                return (
                    <ClientRequirementsSMECell
                        param={param}
                        handleSMEClick={handleSMEClick2}
                        classes={classes}
                        starweaverAdmin={starweaverAdmin}
                        column="smes2"
                    />
                );
            },
        }, */
        // {
        //     field: 'attachments',
        //     headerName: 'Attachments',
        //     width: 180,
        //     sortable: true,
        //     description: 'Attachments',
        //     hide: true,
        //     filterable: false,
        //     display : "flex", renderCell: ({ row }) => {
        //         return (
        //             <AdminAttachement
        //                 row={row}
        //                 isAdmin={isAdmin}
        //                 isInstructor={isInstructor}
        //                 client={client}
        //                 user={userDetails}
        //             />
        //         );
        //     },
        // },
        {
            field: 'instructorBudget',
            headerName: 'Terms & Conditions',
            filterable: false,
            valueFormatter: (value) => {
                return value;
            },
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <Box width={'100%'}>
                        {row?.instructorBudget &&
                        row.instructorBudget?.length !== 0 ? (
                            <Tooltip
                                placement="top-start"
                                title={
                                    <span style={{ fontSize: '14px' }}>
                                        {row.instructorBudget}
                                    </span>
                                }
                            >
                                <Typography className={classes.truncatedText}>
                                    {row.instructorBudget}
                                </Typography>
                            </Tooltip>
                        ) : (
                            'N/A'
                        )}
                    </Box>
                );
            },
            disableColumnMenu: true,
            sortable: false,
            width: 200,
            hide: !isInstructor ? true : isColHidden('instructorBudget'),
        },
        {
            field: 'minBudget',
            headerName: 'Budget Range',
            filterable: false,
            valueFormatter: (value, row) => {
                // console.log(row);
                return value && row.maxBudget
                    ? `$${value} to $${row.maxBudget}`
                    : 'N/A';
            },
            width: 150,
            hide: isInstructor ? true : isColHidden('minBudget'),
            display: 'flex',
        },

        {
            field: 'status',
            headerName: 'Status',
            filterable: false,
            valueFormatter: (value) => {
                return statusDisplay[value];
            },
            width: 120,
            hide: true,
            display: 'flex',
        },
        // {
        //     field: 'discussions',
        //     headerName: 'Discussions',
        //     width: 150,
        //     hide:
        //         Object.keys(client).length === 0 && !isAdmin
        //             ? true
        //             : isColHidden('discussions'),
        //     filterable: false,
        //     display : "flex", renderCell: ({ row }) => {
        //         return (
        //             <CommentsButton
        //                 courseId={`om-${row.id}`}
        //                 courseSlug={''}
        //                 courseName={row.title}
        //             />
        //         );
        //     },
        // },
        {
            field: 'urgency',
            headerName: 'Urgency',
            valueFormatter: (value) => {
                return urgencyDisplay[value] ?? 'N/A';
            },
            width: 120,
            hide: hideUrgency ? true : isColHidden('urgency'),
            filterable: !isPipeline,
            display: 'flex',
        },
        {
            field: 'domain',
            headerName: 'Domain',
            valueFormatter: (value) => {
                const foundDomain = domainOptions.filter(
                    (option) => option.value === value
                );
                if (foundDomain.length > 0) {
                    return foundDomain[0].label;
                } else {
                    return value;
                }
            },
            renderCell: ({ row }) => {
                let { domain } = row;
                const foundDomain = domainOptions.filter(
                    (option) => option.value === domain
                );
                if (foundDomain.length > 0) {
                    domain = foundDomain[0].label;
                }
                if (!domain) {
                    domain = 'N/A';
                }
                return (
                    <Box
                        height={'100%'}
                        width="100%"
                        display="flex"
                        alignItems="center"
                    >
                        <Tooltip title={domain} placement="top-start">
                            <Typography className={classes.truncatedText}>
                                {domain}
                            </Typography>
                        </Tooltip>
                    </Box>
                );
            },
            width: 120,
            hide: isColHidden('domain'),
        },
        {
            field: 'topics',
            headerName: 'Topics',
            filterable: false,
            valueFormatter: (value, row) => {
                return echoTags(row);
            },
            display: 'flex',
            renderCell: (params) => {
                return (
                    <Box sx={{ width: '100%' }}>
                        <Tooltip
                            placement="top-start"
                            title={echoTags(params.row)}
                        >
                            <Typography className={classes.truncatedText}>
                                {echoTags(params.row)}
                            </Typography>
                        </Tooltip>
                    </Box>
                );
            },
            width: 200,
            hide: isColHidden('topics'),
        },
        {
            field: 'templateDetails.name',
            headerName: 'Template',
            filterable: true,
            valueFormatter: (_, row) => {
                return row?.templateDetails?.name ?? 'N/A';
            },
            display: 'flex',
            width: 200,
            hide: false,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ width: '100%' }}>
                        <Tooltip
                            placement="top-start"
                            title={row?.templateDetails?.name ?? 'N/A'}
                        >
                            <Typography className={classes.truncatedText}>
                                {row?.templateDetails?.name ?? 'N/A'}
                            </Typography>
                        </Tooltip>
                    </Box>
                );
            },
        },
        {
            field: 'contractNumber',
            headerName: 'Contract No.',
            width: 150,
            valueFormatter: (value, row) => {
                return echoContractNumber(row).text;
            },
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <Box px={2}>
                        {echoContractNumber(row).showTooltip ? (
                            <Tooltip
                                placement="top-start"
                                title={echoContractNumber(row).text}
                            >
                                <Typography>
                                    {echoContractNumber(row).text}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Typography>
                                {echoContractNumber(row).text}
                            </Typography>
                        )}
                    </Box>
                );
            },
            sortable: false,
            hide: hideContractNumber ? true : isColHidden('contractNumber'),
        },
        {
            field: 'workOrders',
            headerName: 'Work Orders',
            width: 180,
            valueFormatter: (value, row) => {
                return echoWorkOrders(row).text;
            },
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <Box width={'100%'}>
                        {echoWorkOrders(row).showTooltip ? (
                            <Tooltip
                                placement="top-start"
                                title={echoWorkOrders(row).text}
                            >
                                <Typography className={classes.truncatedText}>
                                    {echoWorkOrders(row).text}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Typography className={classes.truncatedText}>
                                {echoWorkOrders(row).text}
                            </Typography>
                        )}
                    </Box>
                );
            },
            sortable: false,
            hide: hideWorkOrders ? true : isColHidden('workOrders'),
        },
        {
            field: 'tags',
            headerName: 'Tags',
            width: 180,
            valueFormatter: (value, row) => {
                return echoSavedTags(row);
            },
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <Box width={'100%'}>
                        <Tooltip
                            placement="top-start"
                            title={echoSavedTags(row)}
                        >
                            <Typography
                                variant="body2"
                                className={classes.truncatedText}
                            >
                                {echoSavedTags(row)}
                            </Typography>
                        </Tooltip>
                    </Box>
                );
            },
            sortable: false,
            filterable: !isPipeline,
            hide: !isAdmin ? true : isColHidden('tags'),
        },
        ...(showPipelines ? [] : [getCreatedByCol('Created By')]),
        {
            field: 'createdAt',
            headerName: 'Created At',
            filterable: false,
            valueFormatter: (value) => {
                return moment(value).format('DD-MMM-YYYY');
            },
            width: 120,
            hide: isColHidden('createdAt'),
            display: 'flex',
        },
        {
            field: 'completedOn',
            headerName: 'Completed On',
            width: 180,
            hide: !location.pathname.includes('completed')
                ? true
                : isColHidden('completedOn'),
            filterable: false,
            valueFormatter: (value, row) => {
                return moment(row.updatedAt).format('DD-MMM-YYYY');
            },
            display: 'flex',
        },
        {
            field: 'updatedAt',
            headerName: 'Updated At',
            hide: location.pathname.includes('completed')
                ? true
                : isColHidden('updatedAt'),
            filterable: false,
            valueFormatter: (value) => {
                return moment(value).format('DD-MMM-YYYY');
            },
            width: 120,
            display: 'flex',
        },
        // {
        //     field: 'createdAt',
        //     headerName: 'Date',
        //     valueFormatter: (value) => {
        //         return value;
        //     },
        //     width: 200,
        // },
    ];

    const handlePageSizeChange = (pageSize) => {
        setPage(0);
        // setPageSize(pageSize);
        setLimit(pageSize);
    };

    const onPageChange = (page) => {
        setPage(page);
    };

    // const rowMouseEnter = (params) => {
    //     setOnMouseEnterRowId(params?.id);
    // };

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleClick2 = (event) => {
    //     setAnchorEl2(event.currentTarget);
    // };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
        handleClose2();
    };

    const handleDelete = () => {
        setShowDeleteConfirmationDialog(true);
    };

    /* PREVIEW HANDLER FUNCTION */
    const handlePreview = (data) => {
        setPreviewData(data);
        setShowPreview(true);
    };

    /* HANDLE STOP PREVIEW */
    const handleStopPreview = () => {
        setShowPreview(false);
    };

    const handleCloseConfirm = () => {
        setShowConfirm(false);
    };

    const toggleMenu = () => {
        setShowMenu((prev) => !prev);
    };

    const handleOnConfirmInterest = async () => {
        try {
            setIsLoading(true);
            handleCloseConfirm();
            const { data, status } = await axios.post(
                '/api/client-requirements/interests',
                {
                    userId: addInterestBody?.userId,
                    isInterested: addInterestBody?.isInterested,
                    requirementId: addInterestBody?.requirementId,
                    usertime: new Date(Date.now()),
                }
            );

            if (status === 200) {
                setAlert({
                    show: true,
                    message: data.message,
                    severity: severity.SUCCESS,
                });

                await fetchData(false);

                // setRows((row) => {
                //     return row.map((r) => {
                //         if (r._id === addInterestBody?.requirementId) {
                //             return {
                //                 ...r,
                //                 isInterested: addInterestBody?.isInterested,
                //             };
                //         } else {
                //             return { ...r };
                //         }
                //     });
                // });

                setAddInterestBody({
                    isInterested: false,
                    requirementId: '',
                    user: '',
                });
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    /* HANDLE CLONE REQUIRMENT FUNCTION */
    const handleClone = async () => {
        try {
            const {
                status,
                data,
            } = await axios.post(
                '/api/client-requirements/clone-requirements',
                { requirementIds: selectedRow, isPipeline }
            );

            if (status === 201) {
                setAlert({
                    message: data.message,
                    show: true,
                    severity: severity.SUCCESS,
                });

                await fetchData();
                // setRows((prevRow) => {
                //     return [...data.requirements, ...prevRow];
                // });
            }
        } catch (e) {
            console.log('e: ', e);
        }
    };

    const changeStatus = async (status, ids) => {
        try {
            const requirementIds = ids ? ids : [...selectedRow];
            const axiosRes = await axios.post(
                '/api/client-requirements/change-status',
                { requirementIds, status }
            );
            if (axiosRes.status === 200) {
                setAlert({
                    message: axiosRes.data.message,
                    severity: severity.SUCCESS,
                    show: true,
                });
                handleClose();
                setShowMenu(false);
                setShowUpdateMenu(false);
                setSelectedRow([]);
                await fetchData();
            }
        } catch (e) {
            if (e?.response?.status === 422) {
                setAlert({
                    message: e.response.data?.message,
                    severity: severity.ERROR,
                    show: true,
                });
            } else {
                setAlert({
                    message: 'Some error occurred',
                    severity: severity.ERROR,
                    show: true,
                });
            }
            handleClose();
            setShowMenu(false);
            setShowUpdateMenu(false);
            setSelectedRow([]);
        }
    };

    const handleViewDataTable = () => {
        setShowCreateOptions(false);
    };

    /* const handleMouseEnter = () => {
        setShowUpdateMenu(true);
    };

    const handleMouseLeave = () => {
        setShowUpdateMenu(false);
    }; */

    const removeFilters = () => {
        setFilterModel({ model: [], logicalConnectivity: 'and' });
        // setFilters({ columnField: '', operatorValue: '', value: '' });
    };

    // EXPORT FUNCTION
    const handleExport = () => {
        let requiredRows;
        // DATA TO BE EXPORTED
        if (selectedRow.length > 0) {
            requiredRows = rows.filter((row) => selectedRow.includes(row._id));
        } else {
            requiredRows = rows;
        }

        const data = requiredRows?.map((row) => {
            return {
                Title: row.title,
                '% Completed': row.percentageCompleted,
                Client: row?.clientId?.client_name
                    ? row.clientId.client_name
                    : 'N/A',
                Type: row?.type
                    ? row.type === 'ON-AIR'
                        ? 'Live Stream'
                        : printFormattedType(row.type)
                    : 'N/A',
                Duration: `${row?.hours}hr(s) - ${row?.minutes}min(s)`,
                'Delivery Date': row?.expectedEndDate
                    ? moment(row.expectedEndDate).format('DD-MMM-YYYY')
                    : 'N/A',
                '# Matches': row?.numOfMatchedInstructors
                    ? row?.numOfMatchedInstructors
                    : 'N/A',
                '# Applicants': row?.interestedIntstructorIds?.length || 0,
                'Applicants Name': getApplicantsNames(row?.applicantNames)
                    .valueFormatter,
                /* 'Lead Collaborators':
                    row?.collaborators?.length > 0
                        ? row.collaborators
                              .map((collaborator) => {
                                  return (
                                      collaborator.firstName +
                                      (collaborator?.lastName
                                          ? ' ' + collaborator.lastName
                                          : '')
                                  );
                              })
                              .join(', ')
                        : 'N/A', */
                'Added Collaborators':
                    row?.addedCollaborators?.length > 0
                        ? row.addedCollaborators
                              .map((collaborator) => {
                                  return (
                                      collaborator.firstName +
                                      (collaborator?.lastName
                                          ? ' ' + collaborator.lastName
                                          : '')
                                  );
                              })
                              .join(', ')
                        : 'N/A',
                Identified:
                    row?.identifiedSmes?.length > 0
                        ? row.identifiedSmes
                              .map((sme) => {
                                  return (
                                      sme.firstName +
                                      (sme?.lastName ? ' ' + sme.lastName : '')
                                  );
                              })
                              .join(', ')
                        : 'N/A',
                Shortlisted:
                    row?.shortListedSmes?.length > 0
                        ? row.shortListedSmes
                              .map((sme) => {
                                  return (
                                      sme.firstName +
                                      (sme?.lastName ? ' ' + sme.lastName : '')
                                  );
                              })
                              .join(', ')
                        : 'N/A',
                Assigned:
                    row?.smes?.length > 0
                        ? row.smes
                              .map((sme) => {
                                  return (
                                      sme.firstName +
                                      (sme?.lastName ? ' ' + sme.lastName : '')
                                  );
                              })
                              .join(', ')
                        : 'N/A',
                'Terms & Conditions': row?.instructorBudget
                    ? row?.instructorBudget
                    : 'N/A',
                'Budget Range': `$${row?.minBudget ? row?.minBudget : 0} to $${
                    row?.maxBudget ? row.maxBudget : 0
                }`,
                Status: row.status,
                Urgency: row?.urgency ? row.urgency : 'N/A',
                Domain: row?.domain ? row.domain : 'N/A',
                Topics: echoTags(row),
                'Contract No.': row?.contractNumber
                    ? row.contractNumber
                    : 'N/A',
                'Work Orders':
                    row?.workOrders?.length > 0
                        ? row.workOrders.map((o) => o.workOrder).join('; ')
                        : 'N/A',
                Tags: echoSavedTags(row),
                'Created At': moment(row.createdAt).format('DD-MMM-YYYY'),
                'Updated At': moment(row.updatedAt).format('DD-MMM-YYYY'),
            };
        });

        const keys = Object.keys(data[0]);

        for (const col of columns) {
            const headerName = col.headerName;
            if (col.field === 'smes2' || col.field === 'shortListedSmes2')
                continue;

            for (const row of data) {
                if (col.hide && keys.includes(headerName)) {
                    delete row[headerName];
                }
            }
        }

        // CREATE WORKSHEET
        const workSheet = XLSX.utils.json_to_sheet(data);

        // CREATE WORK-BOOK
        const workBook = XLSX.utils.book_new();

        // ADD BOOK TO THE SHEET
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Requirements');

        // CREATE NEW BUFFER
        // let buffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });

        // CREATE A NEW BINARY STRING
        XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

        // DOWNLOAD THE FILE
        XLSX.writeFile(workBook, 'Requirements.xlsx');
    };

    const downloadOutlines = async () => {
        if (selectedRow.length === 0) {
            return setAlert({
                show: true,
                message: 'No requirements selected',
                severity: severity.INFO,
            });
        }

        try {
            const requirementIds = selectedRow;
            const payload = { requirementIds };
            const apiURL = '/api/client-requirements/download-outlines';
            setDownloadingOutlines(true);
            const response = await axios.post(apiURL, payload, {
                responseType: 'blob',
            });
            const blob = new Blob([response.data], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'outlines.zip';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (e) {
            console.error(e);
        } finally {
            setDownloadingOutlines(false);
            toggleMenu();
            setSelectedRow([]);
        }
    };

    const handleGenerateOutlines = async () => {
        try {
            if (selectedRow.length === 0) return;
            await axios.post(
                '/api/client-requirements/bulk-generate-outlines',
                { requirementIds: selectedRow }
            );
            setAlert({
                show: true,
                message: 'Generating outlines',
                severity: severity.SUCCESS,
            });
        } catch (e) {
            if (e?.response?.status === 400 || e?.response?.status === 422) {
                setAlert({
                    show: true,
                    message: e?.response?.data?.message,
                    severity: severity.ERROR,
                });
            } else {
                setAlert({
                    show: true,
                    message: 'Internal server error',
                    severity: severity.ERROR,
                });
            }
        } finally {
            setSelectedRow([]);
            toggleMenu();
        }
    };

    const handleGenerateMatches = async () => {
        try {
            if (selectedRow.length === 0) return;
            await axios.post(
                '/api/client-requirements/bulk-generate-instructor-matches',
                { requirementIds: selectedRow }
            );
            setAlert({
                show: true,
                message:
                    'Generating matches. It will take a few minutes to complete.',
                severity: severity.SUCCESS,
            });
        } catch (e) {
            if (e?.response?.status === 400 || e?.response?.status === 422) {
                setAlert({
                    show: true,
                    message: e?.response?.data?.message,
                    severity: severity.ERROR,
                });
            } else {
                setAlert({
                    show: true,
                    message: 'Internal server error',
                    severity: severity.ERROR,
                });
            }
        } finally {
            setSelectedRow([]);
            toggleMenu();
        }
    };

    const handleColVisibilityChange = (params) => {
        setColumnVisibilityModel(params);
    };

    const handleCloseOutline = () => {
        setOpenOutline(false);
    };

    /* if (isLoading || isUserLoading)
        return (
            <Box className={classes.root}>
                <Loading />
            </Box>
        ); */

    // show filter handler
    const handleShowFilter = () => {
        setShowFilter(true);
        // toggleMenu();
    };

    const handleShowCommentDialog = () => {
        const bulkCommentData = [];
        const selectedRowSet = new Set(selectedRow);
        const commonSmesMap = new Map(); // assigned smes only
        for (const requirement of allRequirements) {
            const requirementId = requirement._id;
            if (selectedRowSet.has(requirementId)) {
                const commentDataObj = {
                    courseName: requirement.title,
                    courseId: `om-${requirementId}`,
                    courseSlug: '',
                };
                bulkCommentData.push(commentDataObj);
                // find and store smes
                if (requirement.smes?.length > 0) {
                    for (const sme of requirement.smes) {
                        const smeEmail = sme.email;
                        const smeData = {
                            _id: sme._id,
                            firstName: sme.firstName,
                            lastName: sme.lastName,
                            email: smeEmail,
                        };
                        if (commonSmesMap.has(smeEmail)) {
                            const value = commonSmesMap.get(smeEmail);
                            value.occurance += 1;
                            commonSmesMap.set(smeEmail, value);
                        } else {
                            commonSmesMap.set(smeEmail, {
                                smeData,
                                occurance: 1,
                            });
                        }
                    }
                }
            }
        }
        const smes = [];
        for (const [_, smeObj] of commonSmesMap) {
            const { smeData, occurance } = smeObj;
            if (occurance === selectedRow.length) {
                smes.push(smeData);
            }
        }
        setBulkCommentPayload(() => ({
            courseDataList: bulkCommentData,
            smes,
        }));
        setShowCommentDialog(true);
        toggleMenu();
    };

    const handleHideCommentDialog = () => {
        setBulkCommentPayload(() => ({
            courseDataList: [],
            smes: [],
        }));
        setShowCommentDialog(false);
    };

    const handleBulkComment = async ({
        comment,
        userName,
        emails,
        visibility,
        selectedUsers,
        clientId,
    }) => {
        try {
            const api = '/api/content/bulk-comment/';
            setAlert({
                message: 'Adding comment. Please wait',
                severity: severity.INFO,
                show: true,
            });
            const payload = {
                courseDataList: bulkCommentPayload.courseDataList,
                commentText: comment,
                userName,
                mentions: emails,
                visibility,
                selectedUsers,
                clientId,
            };
            await axios.post(api, payload);
            setAlert({
                message: 'Successfully added comment',
                severity: severity.SUCCESS,
                show: true,
            });
        } catch (e) {
            console.log(e);
        } finally {
            handleHideCommentDialog();
            setSelectedRow([]);
        }
    };

    if (showCreateOptions)
        return (
            <SelectCards
                cards={types(createOnAirRequirementPath, isPipeline)}
                viewDataTable={handleViewDataTable}
                isPipeline={isPipeline}
            />
        );

    return (
        <Box className={classes.root}>
            <ThirdPartyCourseSearchDialog
                isOpen={requirementToLinkWithThirdPartyCourse}
                setIsOpen={setRequirementToLinkWithThirdPartyCourse}
                handleItemSelect={handleThirdPartyCourseSelect}
            />
            {/* PREVIEW DIALOG */}
            {/* <PreviewDialog
                previewData={previewData}
                handleStopPreview={handleStopPreview}
                showPreview={showPreview}
            /> */}

            <OutlineDialog
                openOutline={openOutline}
                handleOutlineClose={handleCloseOutline}
                outlineContent={outlineContent}
            />

            {/* CONFIRM DIALOG */}
            <ConfirmDialog
                onClose={handleCloseConfirm}
                open={showConfirm}
                onConfirm={handleOnConfirmInterest}
                title="Are you sure you're not interested in this requirement?"
            />

            {/* CONFIRMATION DIALOG FOR DELETE REQUIREMENT */}
            <DeleteConfirmation
                setShowDeleteConfirmationDialog={
                    setShowDeleteConfirmationDialog
                }
                selectedRow={selectedRow}
                setRows={setRows}
                setAlert={setAlert}
                handleClose={handleClose}
                showDeleteConfirmationDialog={showDeleteConfirmationDialog}
                fetchData={fetchData}
            />

            {/* Share modal */}
            <ShareModal
                openModal={openShareModal}
                setOpenModal={setOpenShareModal}
                title={shareModalTitle}
                id={shareModalId}
            />

            {downloadingOutlines && <LoadingSkeleton />}

            {showPageLoader && <PageLoader open={showPageLoader} />}

            {/* Custom filter modal */}
            {showFilter && (
                <TableFilterModal
                    open={showFilter}
                    setOpen={setShowFilter}
                    columns={columns}
                    currentFilters={filterModel}
                    getFilteredData={setFilterModel}
                />
            )}

            {showCommentDialog && (
                <BulkCommentDialog
                    handleClose={handleHideCommentDialog}
                    open={showCommentDialog}
                    onComment={handleBulkComment}
                    user={userDetails}
                    smes={bulkCommentPayload.smes}
                />
            )}

            {!hideActions && (
                <Box className={classes.controls}>
                    {/* <Button
                        variant="outlined"
                        onClick={removeFilters}
                        disabled={filters.value.length === 0}
                    >
                        Clear Filter
                    </Button> */}

                    {/* {!isInstructor && ( */}
                    {showFilterBtn && (
                        <div>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<FilterListIcon />}
                                onClick={handleShowFilter}
                                disabled={isLoading}
                            >
                                Filter
                            </Button>

                            {filterModel.model.length > 0 && (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    startIcon={<CloseIcon />}
                                    className={classes.clearFilterBtn}
                                    onClick={removeFilters}
                                >
                                    Clear filter
                                </Button>
                            )}
                        </div>
                    )}
                    {/* )} */}

                    {showCreateNewBtn && (
                        <Box className={classes.actionsBtn}>
                            {/* <Actions actions={actions} /> */}
                            <Button
                                onClick={() => {
                                    dispatch(storeChoosenTemplate({}));
                                    setShowCreateOptions(true);
                                    window.scrollTo(0, 100);
                                }}
                                color="secondary"
                                variant="outlined"
                                disabled={isLoading}
                            >
                                Create New
                            </Button>
                        </Box>
                    )}

                    {showActionBtn && (
                        <Box
                            className={classes.actionButtonContainer}
                            style={{
                                marginLeft:
                                    isInstructor && !isPipeline ? 'auto' : 0,
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<LibraryBooksIcon />}
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={toggleMenu}
                                disabled={isLoading}
                            >
                                Actions
                            </Button>

                            {/* Menu And Menu Items */}
                            <Box
                                ref={menuRef}
                                style={{ display: showMenu ? 'block' : 'none' }}
                                className={classes.menuContainer}
                            >
                                <ul className="menu">
                                    {showDeleteOption && (
                                        <li
                                            className={`menu-item ${
                                                selectedRow?.length === 0 &&
                                                'disabled'
                                            }`}
                                            onClick={handleDelete}
                                        >
                                            Delete
                                        </li>
                                    )}

                                    {showCloneOption && (
                                        <li
                                            className={`menu-item ${
                                                selectedRow?.length === 0 &&
                                                'disabled'
                                            }`}
                                            onClick={handleClone}
                                        >
                                            Clone
                                        </li>
                                    )}

                                    {showUpdateMenuForClient && !isInstructor && (
                                        <li
                                            className={`menu-item ${
                                                selectedRow?.length === 0 &&
                                                'disabled'
                                            }`}
                                            onClick={() => {
                                                setBulkUpdateModalState({
                                                    isOpen: true,
                                                    ids: selectedRow,
                                                });
                                            }}
                                        >
                                            Update
                                        </li>
                                    )}

                                    {!isInstructor && (
                                        <li
                                            className={`menu-item submenu-container`}
                                            onMouseLeave={hideImportMenu}
                                            onMouseEnter={unHideImportMenu}
                                        >
                                            Import
                                            <ArrowRightRounded size="small" />
                                            <ul
                                                className={`submenu ${
                                                    showImportMenu
                                                        ? 'visible'
                                                        : 'hidden'
                                                }`}
                                            >
                                                <>
                                                    <li className="menu-item">
                                                        <ClientRequirementsBulkUpload
                                                            disabled={
                                                                !showImportMenu
                                                            }
                                                            userDetails={
                                                                userDetails
                                                            }
                                                        />
                                                    </li>

                                                    {/* {starweaverAdmin && (
                                                        <li className="menu-item">
                                                            <ClientRequirementsBulkUploadCourseraBatch
                                                                disabled={
                                                                    !showImportMenu
                                                                }
                                                                userDetails={
                                                                    userDetails
                                                                }
                                                            />
                                                        </li>
                                                    )} */}
                                                    {/* {starweaverAdmin && (
                                                        <li className="menu-item">
                                                            <ClientRequirementsBulkUploadCoursera
                                                                disabled={
                                                                    !showImportMenu
                                                                }
                                                                userDetails={
                                                                    userDetails
                                                                }
                                                            />
                                                        </li>
                                                    )} */}
                                                    {starweaverAdmin && (
                                                        <li className="menu-item">
                                                            <ClientRequirementsBulkUploadLinkedIn
                                                                disabled={
                                                                    !showImportMenu
                                                                }
                                                                userDetails={
                                                                    userDetails
                                                                }
                                                            />
                                                        </li>
                                                    )}
                                                </>
                                            </ul>
                                        </li>
                                    )}

                                    {starweaverAdmin && !isInstructor && (
                                        <li
                                            className={`menu-item ${
                                                selectedRow?.length === 0 &&
                                                'disabled'
                                            }`}
                                            onClick={handleShowCommentDialog}
                                        >
                                            Add Comment
                                        </li>
                                    )}

                                    {/* TABLE EXPORT MENU ITEM */}
                                    {starweaverAdmin && !isInstructor && (
                                        <li
                                            className={`menu-item submenu-container`}
                                            onMouseLeave={hideExportMenu}
                                            onMouseEnter={unHideExportMenu}
                                        >
                                            Export
                                            <ArrowRightRounded size="small" />
                                            <ul
                                                className={`submenu ${
                                                    showExportMenu
                                                        ? 'visible'
                                                        : 'hidden'
                                                }`}
                                            >
                                                <li
                                                    className="menu-item"
                                                    onClick={handleExport}
                                                >
                                                    Requirements (csv)
                                                </li>

                                                <li
                                                    className={`menu-item ${
                                                        selectedRow?.length ===
                                                            0 && 'disabled'
                                                    }`}
                                                    onClick={downloadOutlines}
                                                >
                                                    Outlines (word)
                                                </li>
                                            </ul>
                                        </li>
                                    )}

                                    {calenders.length && !isInstructor ? (
                                        <li
                                            className={`menu-item submenu-container`}
                                            onMouseLeave={() => {
                                                setShowCalendarMenu(false);
                                            }}
                                            onMouseEnter={() => {
                                                setShowCalendarMenu(true);
                                            }}
                                        >
                                            Calendar
                                            <ArrowRightRounded size="small" />
                                            <ul
                                                className={`submenu ${
                                                    showCalendarMenu
                                                        ? 'visible'
                                                        : 'hidden'
                                                }`}
                                            >
                                                {calenders.map(
                                                    ({ label, link }) => (
                                                        <li
                                                            className="menu-item"
                                                            onClick={() => {
                                                                window.open(
                                                                    link,
                                                                    '_blank'
                                                                );
                                                            }}
                                                            key={link}
                                                        >
                                                            {label}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </li>
                                    ) : null}

                                    {starweaverAdmin && !isInstructor && (
                                        <li
                                            className={`menu-item ${
                                                selectedRow?.length === 0 &&
                                                'disabled'
                                            }`}
                                            onClick={handleGenerateOutlines}
                                        >
                                            Generate outlines
                                        </li>
                                    )}

                                    {starweaverAdmin && !isInstructor && (
                                        <li
                                            className={`menu-item ${
                                                selectedRow?.length === 0 &&
                                                'disabled'
                                            }`}
                                            onClick={handleGenerateMatches}
                                        >
                                            Generate matches
                                        </li>
                                    )}
                                </ul>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}

            <RequirementSummaryDrawer
                isInstructor={isInstructor}
                isPipeline={isPipeline}
            />

            {ratingModal.open && (
                <RatingDialog
                    open={ratingModal.open}
                    expertUserId={ratingModal.expertUserId}
                    setOpen={setRatingModal}
                />
            )}
            {expertSummary.showSummary && (
                <ExpertSummaryDrawer
                    setRatingModal={setRatingModal}
                    setApplicantsInstructorModal={setApplicantsInstructorModal}
                />
            )}

            <Paper elevation={2} sx={{ padding: '1rem' }}>
                {/* --- PIPELINE SWITCH --- */}
                {showPipelineSwitch && (
                    <Box mb={1}>
                        <Tooltip title="Switch to pipeline">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showPipelines}
                                        onChange={() =>
                                            setShowPipelines((prev) => !prev)
                                        }
                                        size="small"
                                        disabled={isLoading}
                                        color="secondary"
                                    />
                                }
                                label="Switch to pipeline"
                                labelPlacement="start"
                                sx={{ m: 0 }}
                            />
                        </Tooltip>
                    </Box>
                )}

                <WithStickyHeader>
                    <DataGridPremium
                        autoHeight
                        rows={rows}
                        columns={columns}
                        pinnedColumns={pinnedColumns}
                        onPinnedColumnsChange={(updatedPinnedColumns) => {
                            if (isLargeScreen) {
                                setPinnedColumns(updatedPinnedColumns);
                            } else {
                                alert(
                                    'Pinning is not available on small screens.'
                                );
                            }
                        }}
                        columnVisibilityModel={columnVisibilityModel}
                        rowHeight={80}
                        rowCount={rowCount}
                        paginationModel={{ pageSize: limit, page: page }}
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        onPaginationModelChange={({ page, pageSize }) => {
                            setPage(page);
                            setLimit(pageSize);
                        }}
                        pagination
                        paginationMode="server"
                        disableRowSelectionOnClick
                        // disableColumnMenu={true}
                        disableColumnFilter={true}
                        hideFooterRowCount
                        rowSelectionModel={selectedRow}
                        onRowSelectionModelChange={(param) => {
                            // console.log(param);
                            // setSelectedRow(param.selectionModel);
                            setSelectedRow(param);
                        }}
                        // components={{
                        //     ...(!isInstructor ? { Toolbar: ToolBar } : {}),
                        //     NoRowsOverlay: NoRowsOverlay,
                        //     LoadingOverlay: LoadingSkeleton,
                        // }}

                        slots={{
                            toolbar: CustomToolbar,
                            // columnHeaders: (props) => (
                            //     <GridColumnHeaders
                            //         {...props}
                            //         style={{
                            //             position: 'sticky',
                            //             top: 75,
                            //             backgroundColor: 'background.paper',
                            //             zIndex: 4,
                            //         }}
                            //     />
                            // ),
                        }}
                        initialState={{
                            density: 'compact',
                        }}
                        checkboxSelection={showCheckboxSelection}
                        // filterMode="server"
                        // onFilterModelChange={onFilterChange}
                        onColumnVisibilityModelChange={
                            handleColVisibilityChange
                        }
                        loading={isLoading}
                        getRowClassName={(params) => {
                            return isInstructor && params.row?.isInterested
                                ? classes.interestedReq
                                : classes.white;
                        }}
                        getRowHeight={() => 'auto'}
                        // disableColumnReorder
                        // disableColumnResize
                        sx={DATAGRIDSX}
                    />
                </WithStickyHeader>
            </Paper>
            <ConfirmDialog
                open={onConfirm}
                onClose={onConfirmDialogClose}
                onConfirm={onConfirm}
            />

            <ClientRequirementCreateFormSampleOutlineInstructors
                open={isInstructorsDialogOpened}
                onClose={onCloseInstructorsDialog}
                instructors={instructorsToShow}
            />
            {/* INSTRUCTOR MATCHING DATA MODAL */}
            {matchingInstructorModal.isOpen && (
                <InstructorMatchingModal
                    matchingInstructorModal={matchingInstructorModal}
                    setMatchingInstructorModal={setMatchingInstructorModal}
                    setAllRequirements={setAllRequirements}
                    fetchData={fetchData}
                    setPipileadsData={setPipileadsData}
                    setShowContactInfoDialog={setShowContactInfoDialog}
                    setOpenShareModal={setOpenLinkShareModal}
                />
            )}

            {openLinkShareModal && (
                <ShareModal
                    openModal={openLinkShareModal}
                    setOpenModal={setOpenLinkShareModal}
                    title={matchingInstructorModal.title}
                    id={matchingInstructorModal.id}
                    setMatchingInstructorModal={setMatchingInstructorModal}
                />
            )}

            {applicantsInstructorModal.isOpen && (
                <InstructorApplicantsModal
                    applicantsInstructorModal={applicantsInstructorModal}
                    setApplicantsInstructorModal={setApplicantsInstructorModal}
                    setAllRequirements={setAllRequirements}
                    fetchData={fetchData}
                />
            )}

            {showContactInfoDialog && (
                <ContactInfoDialog
                    open={showContactInfoDialog}
                    onClose={() => {
                        setShowContactInfoDialog(false);
                        setPipileadsData(null);
                        setMatchingInstructorModal((prev) => ({
                            ...prev,
                            isOpen: true,
                        }));
                    }}
                    pipileadsData={pipileadsData}
                />
            )}

            {/* INSTRUCTOR MATCHING DATA MODAL */}
            {smeModal.isOpen && (
                <SMEModal
                    smeModal={smeModal}
                    setSmeModal={setSmeModal}
                    setAllRequirements={setAllRequirements}
                    changeStatus={changeStatus}
                    fetchData={fetchData}
                />
            )}

            {smeModal2.isOpen && (
                <SMEModal2
                    smeModal={smeModal2}
                    setSmeModal={setSmeModal2}
                    setAllRequirements={setAllRequirements}
                    changeStatus={changeStatus}
                />
            )}

            {bulkUpdateModalState.isOpen && (
                <ClientRequirementsBulkUpdateModal
                    bulkUpdateModalState={bulkUpdateModalState}
                    setBulkUpdateModalState={setBulkUpdateModalState}
                    fetchData={fetchData}
                />
            )}
        </Box>
    );
};

const CustomToolbar = () => {
    const [roleView] = useRecoilState(roleViewState);

    return (
        <GridToolbarContainer>
            {roleView.selectedView === 'admin' && <GridToolbarColumnsButton />}
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
};

const types = (createOnAirRequirementPath, isPipeline = false) => [
    {
        icon: <LaptopChromebookIcon style={{ fontSize: '2rem' }} />,
        text: 'On Demand (Up to 4 hours)',
        info:
            'Provide students access to course content on-demand, and organize it as you wish.',
        // link: '/profile/instructor-dashboard/content/create/access-denied',
        link: isPipeline
            ? '/profile/instructor-dashboard/pipelines/create/on-demand'
            : '/admin/client-requirements/create/on-demand',
    },
    {
        icon: <ContactsIcon style={{ fontSize: '2rem' }} />,
        // text: 'Hackathons/Long-Form Events',
        text: 'Live Long Form Content / Hackathons',
        info:
            'Schedule, promote and deliver engaging long-form training programs, live labs and/or hackathons. Charge separately or add them to your subscription plans.',
        // link: '/profile/instructor-dashboard/content/create/access-denied',
        link: isPipeline
            ? '/profile/instructor-dashboard/pipelines/create/long-form'
            : '/admin/client-requirements/create/long-form',
    },
    {
        icon: (
            <LiveTvIcon
                style={{ fontSize: '2rem' }}
                color={isPipeline ? 'disabled' : 'inherit'}
            />
        ),
        text: 'Live Stream',
        info: 'Engage your students with live content when and as you want.',
        link: createOnAirRequirementPath,
        disabled: isPipeline,
    },
    {
        icon: <NoteIcon style={{ fontSize: '2rem' }} color="disabled" />,
        text: 'Assessment/Test',
        info:
            'Build your own assessments, quizzes and tests, or curate them from our library of existing content.',
        link: isPipeline
            ? '/profile/instructor-dashboard/pipelines/create/access-denied'
            : '/profile/instructor-dashboard/content/create/access-denied',
        // isExternal: true,
        // isNewTab: true,
        disabled: true,
    },
    {
        icon: <BuildIcon style={{ fontSize: '2rem' }} color="disabled" />,
        text: 'Labs and Exercises',
        info:
            'Build your own labs and exercises, or curate them from our library of existing content.',
        link: isPipeline
            ? '/profile/instructor-dashboard/pipelines/create/access-denied'
            : '/profile/instructor-dashboard/content/create/access-denied',
        disabled: true,
    },
    ...(!isPipeline
        ? [
              {
                  icon: (
                      <FileCopy
                          style={{ fontSize: '2rem' }}
                          color={isPipeline ? 'disabled' : 'inherit'}
                      />
                  ),
                  text: 'Guided Projects',
                  info:
                      'Create short form, hands-on case, lab and other pre-recorded content with side-by-side projects for learners.',
                  // link: '/profile/instructor-dashboard/content/create/access-denied',
                  link: '/admin/client-requirements/create/guided-projects',
                  disabled: isPipeline,
              },
          ]
        : []),
    {
        icon: <MenuBook style={{ fontSize: '2rem' }} color="disabled" />,
        text: 'Coaching & Tutoring',
        info:
            'Get personalized coaching and tutoring to excel in your studies or career. Expert mentors provide tailored guidance for your success.',
        link: isPipeline
            ? '/profile/instructor-dashboard/pipelines/create/access-denied'
            : '/profile/instructor-dashboard/content/create/access-denied',
        disabled: true,
    },
    {
        icon: (
            <SupervisedUserCircleIcon
                style={{ fontSize: '2rem' }}
                color="disabled"
            />
        ),
        text: 'Custom Consulting',
        info:
            'Define one or more projects for hands-on subject matter experts, content providers and others to work on for you: by the hour, by the project or any combination of means.',
        link: isPipeline
            ? '/profile/instructor-dashboard/pipelines/create/access-denied'
            : '/profile/instructor-dashboard/content/create/access-denied',
        disabled: true,
    },
    {
        icon: <Android style={{ fontSize: '2rem' }} color="disabled" />,
        text: 'ChatBot Created',
        info: 'Use our Boostr ChatBot to build your requirement in minutes.',
        link: isPipeline
            ? '/profile/instructor-dashboard/pipelines/create/access-denied'
            : '/profile/instructor-dashboard/content/create/access-denied',
        disabled: true,
    },
];

export default ClientRequirements;

// const ActionButtonContainer = styled.div`
//     position: relative;
// `;

// const MenuContainer = styled.div`
//     background-color: white;
//     box-shadow: 0px 0px 8px 1px gray;
//     border-radius: 0.25rem;
//     min-width: 150px;
//     z-index: 1;
//     position: absolute;
//     top: 105%;
//     right: 0;
//     & > ul {
//         list-style-type: none;
//         padding: 0;
//         margin: 0;
//         font-size: 1.05rem;
//         font-weight: 400;
//         line-height: 1.8em;
//         & .menu-item {
//             padding: 0.25rem 0.5rem;
//             cursor: pointer;
//             border-radius: 0.25rem;
//             &:hover {
//                 background-color: #f0f0f0;
//             }
//         }
//         & .menu-item.disabled {
//             color: gray;
//             pointer-events: none;
//         }
//         & > li.submenu-container {
//             position: relative;
//             display: flex;
//             align-items: center;
//             justify-content: space-between;
//             & > .submenu {
//                 position: absolute;
//                 right: 100%;
//                 top: 0;
//                 background-color: white;
//                 z-index: 1;
//                 padding: 0;
//                 margin: 0;
//                 list-style-type: none;
//                 width: max-content;
//                 box-shadow: 0px 0px 8px 1px gray;
//                 border-radius: 0.25rem;
//             }
//             & > .submenu.hidden {
//                 display: none;
//             }
//             & > .submenu.visible {
//                 display: block;
//             }
//         }
//     }
// `;

const useStyles2 = makeStyles()((theme) => ({
    backdrop: {
        zIndex: '1999',
        color: '#fff',
    },
}));

const LoadingSkeleton = () => {
    const classes = useStyles2();

    return (
        <Box>
            {/* <Typography variant="subtitle2">Loading...</Typography> */}
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="secondary" />
            </Backdrop>
        </Box>
    );
};
