import { useCallback, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        fieldContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            borderBottom: '1px dashed',
            // paddingBottom: theme.spacing(0.5),
            // paddingTop: theme.spacing(0.5),
            borderColor: theme.palette.grey['300'],
        },
        summaryLabel: {
            flexShrink: 0,
        },
        chipContainer: {
            // paddingBottom: theme.spacing(0.5),
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                height: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.grey['400'],
                borderRadius: '1rem',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: theme.palette.grey['200'],
            },
        },
        name: {
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
            },
        },
    };
});

function RequirementSummarySMEList({
    smes = [],
    label,
    handleUserClick = () => console.log(),
    ...props
}) {
    const { classes } = useStyles();

    const getFullName = useCallback((sme) => {
        if (sme?.fullName) return sme.fullName;
        return sme?.firstName + ' ' + sme?.lastName || '';
    }, []);

    const smeNames = useMemo(() => {
        const names = smes.map((sme) => getFullName(sme));
        if (names.length) return names;
        return null;
    }, [getFullName, smes]);

    const handleNameClick = (i) => {
        if (!props.isNameClickable) return;
        const sme = smes[i];
        handleUserClick(sme);
    };

    return (
        <Box className={classes.fieldContainer} {...props}>
            <Typography
                variant="body1"
                fontWeight={500}
                className={classes.summaryLabel}
            >
                {label}:{' '}
            </Typography>
            <Stack
                direction="row"
                spacing={1}
                className={classes.chipContainer}
            >
                {smeNames == null
                    ? 'N/A'
                    : smeNames.map((name, i) => {
                          return (
                              <Name
                                  name={name}
                                  i={i}
                                  n={smeNames.length}
                                  handleNameClick={handleNameClick}
                                  {...props}
                              />
                          );
                      })}
            </Stack>
        </Box>
    );
}

const Name = ({ name, i, n, handleNameClick, ...props }) => {
    const { classes } = useStyles();

    if (i !== n - 1) {
        return (
            <span
                onClick={() => handleNameClick(i)}
                className={props.isNameClickable ? classes.name : ''}
            >
                {name}&#44;&nbsp;
            </span>
        );
    }

    return (
        <span
            onClick={() => handleNameClick(i)}
            className={props.isNameClickable ? classes.name : ''}
        >
            {name}
        </span>
    );
};

export default RequirementSummarySMEList;
