import { Box, Checkbox, MenuItem, Typography, TextField } from '../ui';
import { useMemo, useState } from 'react';
import moment from 'moment';
import { useStyles } from './styles';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userPlanState, chnageUserPlanState } from './recoil';

export const ChangeUserPlan = () => {
    const { classes } = useStyles();
    // recoil state with show current user plan
    const {
        show: initialShow,
        accountType: initialAccountType,
        expirationDate: initialExpirationDate,
    } = useRecoilValue(userPlanState);

    const changePropertyInState = useSetRecoilState(chnageUserPlanState);
    const [show, setShow] = useState(initialShow || false);
    const [accountType, setAccountType] = useState(
        initialAccountType || 'VOYAGER'
    );
    const [expirationDate, setExpirationDate] = useState(
        initialExpirationDate || new Date()
    );

    const handleChangeShow = (value) => {
        setShow(value);
        changePropertyInState({ name: 'show', value });
    };

    const handleChangeAccountType = (e) => {
        changePropertyInState({ name: 'accountType', value: e.target.value });
        setAccountType(e.target.value);
    };

    const handleExpirationDateChange = (e) => {
        changePropertyInState({
            name: 'expirationDate',
            value: e.target.value,
        });
        setExpirationDate(e.target.value);
    };

    const showExpirationDate = useMemo(() => {
        if (accountType === 'VOYAGER' || accountType === 'VOYAGER_PLUS') {
            return false;
        } else {
            return true;
        }
    }, [accountType]);

    return (
        <Box className={classes.fieldContainer}>
            <Box mr={2} display="flex" alignItems="center">
                <Checkbox
                    checked={show}
                    required
                    onChange={(e) => {
                        handleChangeShow(e.target.checked);
                    }}
                />
                <Typography>Change user account type</Typography>
            </Box>
            {show && (
                <Box display="flex" alignItems="center">
                    <Box mr={1} width="150px">
                        <TextField
                            select
                            label="Account type"
                            value={accountType}
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            inputProps={{
                                MenuProps: { disableScrollLock: true },
                            }}
                            onChange={handleChangeAccountType}
                        >
                            {USER_PLAN_OPTIONS.map(({ label, value }) => (
                                <MenuItem value={value}>{label}</MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    {showExpirationDate && (
                        <Box mr={1}>
                            {' '}
                            <TextField
                                fullWidth
                                value={expirationDate}
                                variant="outlined"
                                color="secondary"
                                label="Expiration date"
                                type="date"
                                onChange={handleExpirationDateChange}
                                defaultValue={new Date()}
                                InputProps={{
                                    inputProps: {
                                        min: moment().format('YYYY-MM-DD'),
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export const USER_PLAN_OPTIONS = [
    {
        label: 'Voyager',
        value: 'VOYAGER',
    },
    {
        label: 'Voyager+',
        value: 'VOYAGER_PLUS',
    },
    {
        label: 'Astronaut',
        value: 'ASTRONAUT',
    },
    {
        label: 'Commander',
        value: 'COMMANDER',
    },
];
