import { Box, Card, Typography } from '../../ui';
import { Anchor } from './Anchor';
import { makeStyles } from 'tss-react/mui';
export const AdminCurationCard = ({
    icon,
    link,
    isExternal,
    isNewTab,
    text,
    info,
}) => {
    const { classes } = useStyles();

    return (
        <Anchor
            to={link}
            isExternal={isExternal}
            isNewTab={isNewTab}
            className={classes.link}
        >
            <Card className={classes.card}>
                <Box className={classes.iconContainer}>{icon}</Box>

                <Typography className={classes.type}>{text}</Typography>

                <Typography variant="body2" className={classes.info}>
                    {info}
                </Typography>
            </Card>
        </Anchor>
    );
};

const useStyles = makeStyles()((theme) => ({
    link: {
        margin: 7,
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20,
        width: 240,
        height: 240,
    },
    iconContainer: {
        marginBottom: 10,
    },
    type: {
        fontWeight: 'bold',
        marginBottom: 10,
    },
    info: {
        textAlign: 'center',
        marginBottom: 40,
    },
}));
