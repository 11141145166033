import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { CloseIcon } from '../icons';
import { makeStyles } from 'tss-react/mui';
import { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../app/recoil';
import { useSelector } from 'react-redux';

const useStyles = makeStyles()((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

export default function RemindModal({ open, setShowRemindModal, commentId }) {
    const { classes } = useStyles();
    const [duration, setDuration] = useState('in 1 day');
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const setAlert = useSetRecoilState(alertState);
    const { requirementData } = useSelector(
        (state) => state.requirementSummary
    );

    const handleClose = () => {
        setShowRemindModal(false);
    };

    const handleChange = (e) => {
        setDuration(e.target.value);
    };

    const handleAddReminder = async () => {
        try {
            setLoading(true);
            const requirementId =
                params?.requirementId || requirementData?.requirementId;

            if (!requirementId) {
                throw new Error('Requirement id is missing');
            }

            const payload = {
                commentId,
                duration,
                requirementId,
            };

            await axios.post('/api/content/set-comment-reminder', payload);
            setAlert({
                show: true,
                message: 'Successfully set reminder',
                severity: severity.SUCCESS,
            });
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            handleClose();
        }
    };

    return (
        <Dialog
            open={open}
            scroll={'paper'}
            aria-labelledby="reminder-dialog"
            aria-describedby="reminder dialog"
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle id="reminder-dialog" className={classes.header}>
                Set Reminder
                <IconButton
                    size="small"
                    onClick={handleClose}
                    disabled={loading}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <FormControl fullWidth color="secondary">
                    <InputLabel id="demo-simple-select-label">
                        Remind after
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={duration}
                        label="Remind after"
                        onChange={handleChange}
                    >
                        <MenuItem value={'in 5 minutes'}>5 minutes</MenuItem>
                        <MenuItem value={'in 15 minutes'}>15 minutes</MenuItem>
                        <MenuItem value={'in 1 hour'}>1 Hour</MenuItem>
                        <MenuItem value={'in 3 hours'}>3 Hours</MenuItem>
                        <MenuItem value={'in 1 day'}>1 day</MenuItem>
                        <MenuItem value={'in 2 days'}>2 days</MenuItem>
                        <MenuItem value={'in 3 days'}>3 days</MenuItem>
                        <MenuItem value={'in 1 week'}>1 week</MenuItem>
                        <MenuItem value={'in 2 weeks'}>2 weeks</MenuItem>
                    </Select>
                    <FormHelperText>
                        You will be reminded after {duration} hours about this
                        comment
                    </FormHelperText>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={handleAddReminder}
                    disabled={loading}
                >
                    Add reminder
                </Button>
            </DialogActions>
        </Dialog>
    );
}
